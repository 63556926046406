<template>
    <div class="new-attraction-container" v-show="showForm">
        <h1 class="main-heading">
            Új Hungarikum felvétele
        </h1>
    </div>
</template>

<script>
import axios from "axios";

export default {
    mounted() {
        this.createEmptyHungarikum();
    },

    data() {
        return {
            hungarikumId: null,
            showForm: false,
            isHungarikumSaving: false,
        }
    },

    methods: {
        createEmptyHungarikum() {
            let data = {
                label: "Új Hungarikum"
            };

            axios.post('/api/v1/companies/1/attractions/hungarikum', data)
                .then(response => {
                    if (response.data.isValid) {
                        this.hungarikumId = response.data.resourceId

                        this.$message.success(
                            'A hungarikum sikeresen létrehozva!'
                        );

                        this.$router.push({
                            name: 'neta-basic-hungarikum-data-form',
                            params: { id: this.hungarikumId }
                        });
                    } else {
                        this.$message.error(
                            'Sikertelen létrehozás!'
                        );
                    }

                    this.isHungarikumSaving = false;
                })
                .catch(error => {
                    this.isHungarikumSaving = false;

                    this.$message.error(
                        'Sikertelen létrehozás!'
                    );
                });
        },
    }
}
</script>