<template xmlns="">
    <div class="app-form-container">
        <a-spin :spinning="formDataLoading">
            <a-form :form="form" v-on:submit="submit" layout="vertical">
                <a-form-item label="A Tourinform iroda neve" :selfUpdate="true">
                    <a-textarea
                            :maxLength="25000"
                            v-decorator="[
                            'name',
                            {
                                rules: [
                                    {
                                      required: true,
                                      preserve: true,
                                      message: 'Kérjük töltse ki!'
                                    },
                                    {
                                      max: characterCounts.name.max,
                                      message: 'Maximum ' + characterCounts.name.max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur', 'keyup']
                            }
                        ]"
                            :autoSize="{minRows: 1}"
                    />
                </a-form-item>

                <a-form-item label="Tourinform iroda alapításának/nyitásának éve" :selfUpdate="true">
                    <a-input-number
                            :max="2100"
                            :min="1901"
                            v-decorator="[
                        'openingYear',
                        {
                            validateTrigger: ['blur', 'keyup'],
                            rules: [{
                                required: true,
                                message: 'Kérjük töltse ki!',
                            }]
                        }
                    ]"
                    />
                    év
                </a-form-item>

                <a-form-item
                        :selfUpdate="true"
                        label="A Tourinform iroda településének bemutatása, rövid leírása, kiemelendő értékei, TOP turisztikai vonzerői (kb. 3 sor, max. 500 karakter)"
                >
                    <a-textarea
                            :maxLength="25000"
                            v-decorator="[
                            'description',
                            {
                                rules: [
                                    {
                                      required: true,
                                      preserve: true,
                                      max: characterCounts.description.max,
                                      message: 'Kérjük töltse ki! A leírás hossza maximum ' + characterCounts.description.max + ' karakter lehet.',
                                    }
                                ],
                                validateTrigger: ['blur', 'keyup']
                            }
                        ]"
                            v-on:keyup="characterCount"
                            v-on:change="characterCount"
                            :autoSize="{minRows: 3}"
                    />

                    <div class="character-count">
                        <strong
                                v-bind:class="{
                    'invalid': characterCounts.description.valid === false,
                    'valid': characterCounts.description.valid === true
                  }"
                        >
                            {{ characterCounts.description.current }}
                        </strong>

                        / {{ characterCounts.description.max }}
                    </div>
                </a-form-item>

                <a-form-item label="Irányítószám">
                    <a-select
                            v-decorator="[
                            'officePlace/address/zipCode/id',
                            {
                                rules: [
                                    {
                                    required: true,
                                    whitespace: true,
                                    message: 'Kérjük töltse ki!',
                                    type: 'integer'
                                  },
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
                            placeholder="Kezdjen gépelni"
                            showSearch
                            :showArrow="false"
                            :defaultActiveFirstOption="false"
                            :getPopupContainer="getParentTriggerNode()"
                            :filterOption="false"
                            :style="{width: '100%'}"
                            v-on:search="(value) => zipCodeAutoComplete(value,'officePlace/address/zipCode/id')"
                            v-on:change="(value) => {fillCityOptions(value,'officePlace/address/zipCode/id','officePlace/address/city/id')}"
                            v-on:dropdownVisibleChange="(open) => open && zipCodeAutoComplete(null, 'officePlace/address/zipCode/id')"
                            :notFoundContent="loading['officePlace/address/zipCode/id'] ? undefined : null"
                    >
                        <a-spin v-if="loading['officePlace/address/zipCode/id']" slot="notFoundContent" size="small" />

                        <a-select-option
                                v-for="option in options['officePlace/address/zipCode/id']"
                                :key="option.id"
                                :value="option.id"
                        >
                            {{ option.code }}
                        </a-select-option>
                    </a-select>
                </a-form-item>

                <a-form-item label="Település neve">
                    <a-select
                            v-decorator="[
                            'officePlace/address/city/id',
                            {
                                rules: [
                                    {
                                    required: true,
                                    whitespace: true,
                                    message: 'Kérjük töltse ki!',
                                    type: 'integer'
                                  },
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
                            placeholder="Töltse ki az irányítószám mezőt!"
                            :showArrow="false"
                            :disabled="!options['officePlace/address/city/id'].length"
                            :defaultActiveFirstOption="true"
                            :filterOption="false"
                            :style="{width: '100%'}"
                            :notFoundContent="loading['officePlace/address/zipCode/id'] ? undefined : null"
                    >
                        <a-spin v-if="loading['officePlace/address/zipCode/id']" slot="notFoundContent" size="small" />

                        <a-select-option
                                v-for="option in options['officePlace/address/city/id']"
                                :key="option.id"
                                :value="option.id"
                        >
                            {{ option.name }}
                        </a-select-option>
                    </a-select>
                </a-form-item>

                <a-form-item
                        :selfUpdate="true"
                        label="Közterület (pl.: Váci út, Virág utca, Batthyány tér)"
                >
                    <a-input
                            :maxLength="25000"
                            v-decorator="[
                            'officePlace/address/address',
                            {
                                rules: [
                                    {
                                      required: true,
                                      whitespace: true,
                                      message: 'Kérjük töltse ki!'
                                    },
                                    {
                                      max: characterCounts['officePlace/address/address'].max,
                                      message: 'Maximum ' + characterCounts['officePlace/address/address'].max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
                    >
                    </a-input>
                </a-form-item>

                <a-form-item
                        :selfUpdate="true"
                        label="Közterület angol nyelven (pl.: Váci road, Virág street, Batthyány square)"
                >
                    <a-input
                            :maxLength="25000"
                            v-decorator="[
                            'officePlace/address/addressExtra',
                            {
                                rules: [
                                    {
                                      required: true,
                                      whitespace: true,
                                      message: 'Kérjük töltse ki!'
                                    },
                                    {
                                      max: characterCounts['officePlace/address/addressExtra'].max,
                                      message: 'Maximum ' + characterCounts['officePlace/address/addressExtra'].max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
                    >
                    </a-input>
                </a-form-item>

                <a-form-item :selfUpdate="true" label="Házszám">
                    <a-input
                            :maxLength="25000"
                            v-decorator="[
                            'officePlace/address/addressNumber',
                            {
                                rules: [
                                    {
                                      required: true,
                                      whitespace: true,
                                      message: 'Kérjük töltse ki!'
                                    },
                                    {
                                      max: characterCounts['officePlace/address/addressNumber'].max,
                                      message: 'Maximum ' + characterCounts['officePlace/address/addressNumber'].max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
                    />
                </a-form-item>

                <a-form-item
                        label="A Tourinform iroda pontos postázási címe (pl.: 1234 Település, Pf.: 123)"
                        :selfUpdate="true"
                >
                    <a-textarea
                            :maxLength="25000"
                            v-decorator="[
                            'officePlace/mailingAddress',
                            {
                                rules: [
                                    {
                                      required: true,
                                      preserve: true,
                                      message: 'Kérjük töltse ki!'
                                    },
                                    {
                                      max: characterCounts['officePlace/mailingAddress'].max,
                                      message: 'Maximum ' + characterCounts['officePlace/mailingAddress'].max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur', 'keyup']
                            }
                        ]"
                            :autoSize="{minRows: 1}"
                    />
                </a-form-item>

                <a-form-item :selfUpdate="true">
          <span slot="label">
              A Tourinform iroda GPS koordinátája: szélességi fok

              <a-tooltip :title="coordinateTooltipLatitude">
                  <a-icon type="question-circle-o" />
              </a-tooltip>
          </span>

                    <a-input
                            :maxLength="25000"
                            v-on:change="(event) => formatCoordinate(event.target.value, 'officePlace/address/lat')"
                            v-decorator="[
                            'officePlace/address/lat',
                            {
                                rules: [
                                    {
                                      required: true,
                                      whitespace: true,
                                      pattern: /^4([-+]?)([\d]{1})(((\.)(\d+)))$/g,
                                      message: 'Kérjük adjon meg helyes GPS koordinátákat!',
                                    },
                                    {
                                      type: 'float',
                                      message: 'Kérjük adjon meg helyes GPS koordinátákat!',
                                      transform: (value) => {
                                        value = parseFloat(value);

                                        return isNaN(value) ? null: value;
                                      },
                                      min: 45.72,
                                      max: 48.60
                                    }
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
                            placeholder="Kérjük a koordinátát az alábbi formátumban adja meg: 47.507346"
                    >
                    </a-input>
                </a-form-item>

                <a-form-item :selfUpdate="true">
          <span slot="label">
            A Tourinform iroda GPS koordinátája: hosszúsági fok

            <a-tooltip :title="coordinateTooltipLongitude">
                <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>

                    <a-input
                            :maxLength="25000"
                            v-on:change="(event) => formatCoordinate(event.target.value, 'officePlace/address/long')"
                            v-decorator="[
                            'officePlace/address/long',
                            {
                                rules: [{
                                    required: true,
                                    whitespace: true,
                                    pattern: /^(1|2)(([-+]?)([\d]{1})((\.)(\d+))?)$/g,
                                    message: 'Kérjük adjon meg helyes GPS koordinátákat!'
                                },
                                    {
                                      type: 'float',
                                      message: 'Kérjük adjon meg helyes GPS koordinátákat!',
                                      transform: (value) => {
                                        value = parseFloat(value);

                                        return isNaN(value) ? null: value;
                                      },
                                      min: 16.10,
                                      max: 23
                                    }],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
                            placeholder="Kérjük a koordinátát az alábbi formátumban adja meg: 19.046610"
                    >
                    </a-input>
                </a-form-item>

                <a-form-item label="A Tourinform iroda vonalas telefonszáma (pl.: +36 1 234 5678)" :selfUpdate="true">
                    <a-input
                            :maxLength="25000"
                            :style="{width: '160px'}"
                            v-decorator="[
                                'phone',
                                {
                                    rules: [{
                                        required: !form.getFieldValue('mobilePhone'),
                                        pattern: /^[\/+][36]{1,2}[ ][0-9]{1,2}[ ][0-9]{3}[ ][0-9]{3,4}$/,
                                        message: 'Hibás telefonszám formátum!'
                                    }],
                                   validateTrigger: ['blur']
                                }
                            ]"
                            v-on:change="(event) => formatPhoneNumber(event.target.value, 'phone')"
                            v-on:blur="(event) => form.validateFields()"
                            ref="phone"
                            placeholder="+36 1 234 5678"
                    />
                </a-form-item>

                <a-form-item label="A Tourinform iroda mobiltelefonszáma (pl.: +36 20 123 4567)" :selfUpdate="true">
                    <a-input
                            :maxLength="25000"
                            :style="{width: '160px'}"
                            v-decorator="[
                                'mobilePhone',
                                {
                                    rules: [{
                                        required: !form.getFieldValue('phone'),
                                        pattern: /^[\/+][36]{1,2}[ ][0-9]{1,2}[ ][0-9]{3}[ ][0-9]{3,4}$/,
                                        message: 'Hibás telefonszám formátum!'
                                    }],
                                   validateTrigger: ['blur']
                                }
                            ]"
                            v-on:change="(event) => formatPhoneNumber(event.target.value, 'mobilePhone')"
                            v-on:blur="(event) => form.validateFields()"
                            ref="mobilePhone"
                            placeholder="+36 20 123 4567"
                    />
                </a-form-item>

                <a-form-item :selfUpdate="true">
          <span slot="label">
              A Tourinform iroda e-mail címe
              <a-tooltip
                      title="Kérjük, az e-mail címet iroda@domain.xy formátumban töltse ki!"
              >
                  <a-icon type="question-circle-o" />
              </a-tooltip>
          </span>

                    <a-input
                            type="email"
                            :maxLength="25000"
                            v-decorator="[
                            'email',
                            {
                                rules: [
                                    {
                                      required: true,
                                      whitespace: true,
                                      type: 'email',
                                      message: 'Kérjük a megfelelő formátumban töltse ki!'
                                    },
                                    {
                                      max: characterCounts['email'].max,
                                      message: 'Maximum ' + characterCounts['email'].max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
                            placeholder="Kérjük az e-mail címet beírni!"
                    >
                    </a-input>
                </a-form-item>

                <a-form-item :selfUpdate="true">
          <span slot="label">
              A Tourinform iroda weboldalának címe

              <a-tooltip title="Kérjük a weboldal címét http(s)://www.weboldal.xy formátumban töltse ki!">
                  <a-icon type="question-circle-o" />
              </a-tooltip>
          </span>

                    <a-input
                            :maxLength="25000"
                            v-on:change="(event) => transformEncodedUrl(event.target.value, 'website')"
                            v-decorator="[
                        'website',
                        {
                            rules: [
                                {
                                  required: false,
                                  message: 'Kérjük a megfelelő formátumban töltse ki!',
                                  pattern: /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
                                },
                                {
                                  max: characterCounts['website'].max,
                                  message: 'Maximum ' + characterCounts['website'].max + ' karakter engedélyezett.',
                                }
                            ],
                            validateTrigger: ['blur', 'keyup']
                        }
                    ]"
                    />
                </a-form-item>

                <a-form-item :selfUpdate="true">
          <span slot="label">
              A Tourinform iroda angol weboldalának címe

              <a-tooltip title="Kérjük a weboldal címét http(s)://www.weboldal.xy formátumban töltse ki!">
                  <a-icon type="question-circle-o" />
              </a-tooltip>
          </span>

                    <a-input
                            :maxLength="25000"
                            v-on:change="(event) => transformEncodedUrl(event.target.value, 'websiteExtra')"
                            v-decorator="[
                        'websiteExtra',
                        {
                            rules: [
                                {
                                  required: false,
                                  message: 'Kérjük a megfelelő formátumban töltse ki!',
                                  pattern: /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
                                },
                                {
                                  max: characterCounts['websiteExtra'].max,
                                  message: 'Maximum ' + characterCounts['websiteExtra'].max + ' karakter engedélyezett.',
                                }
                            ],
                            validateTrigger: ['blur', 'keyup']
                        }
                    ]"
                    />
                </a-form-item>

                <a-form-item label="A Tourinform iroda weboldalának nyelvei (több válasz is adható).">
                    <a-select
                            :getPopupContainer="getParentTriggerNode()"
                            mode="multiple"
                            v-decorator="[
                        'websiteLanguages/id',
                        {
                            rules: [],
                            validateTrigger: ['blur']
                        }
                    ]"
                            placeholder="Kérjük válasszon!"
                            :filterOption="filterSelectNames"
                    >
                        <a-select-option
                                v-for="language in options['websiteLanguages/id']"
                                :key="language.id"
                                :value="language.id"
                        >
                            {{ language.name }}
                        </a-select-option>
                    </a-select>
                </a-form-item>

                <a-form-item :selfUpdate="true">
          <span slot="label">
              A Tourinform iroda Facebook oldalának címe

              <a-tooltip
                      title="Kérjük a Facebook linket az alábbi formátumban adja meg: https://www.facebook.com/tourinformiroda"
              >
                  <a-icon type="question-circle-o" />
              </a-tooltip>
          </span>

                    <a-input
                            :maxLength="25000"
                            v-decorator="[
                        'facebook',
                        {
                            rules: [
                                {
                                    required: false,
                                    message: 'Kérjük a megfelelő formátumban töltse ki!',
                                    pattern: /^(http|https):\/\/(?:www.)?facebook.com/g
                                },
                                {
                                  max: characterCounts['facebook'].max,
                                  message: 'Maximum ' + characterCounts['facebook'].max + ' karakter engedélyezett.',
                                }
                            ],
                            validateTrigger: ['blur', 'keyup']
                        }
                    ]"
                    />
                </a-form-item>

                <a-form-item label="A Tourinform iroda munkatársai által beszélt nyelvek (Több válasz is adható).">
                    <a-select
                            :getPopupContainer="getParentTriggerNode()"
                            mode="multiple"
                            v-decorator="[
                        'spokenLanguages/id',
                        {
                            rules: [{
                                required: true,
                                message: 'Kérjük töltse ki!'
                            }],
                            validateTrigger: ['blur']
                        }
                    ]"
                            placeholder="Kérjük válasszon!"
                            :filterOption="filterSelectNames"
                    >
                        <a-select-option
                                v-for="language in options['spokenLanguages/id']"
                                :key="language.id"
                                :value="language.id"
                        >
                            {{ language.name }}
                        </a-select-option>
                    </a-select>
                </a-form-item>

                <a-form-item label="Tourinform iroda típusa (ügyfélfogadási időtartam szerint)">
                    <a-select
                            v-decorator="[
                        'seasonal',
                        {
                            rules: [{
                                required: true,
                                message: 'Kérjük válasszon!'
                            }]
                        }
                    ]"
                            placeholder="Kérjük válasszon!"
                    >
                        <a-select-option :value="1">
                            Szezonálisan nyitva tartó
                        </a-select-option>

                        <a-select-option :value="0">
                            Egész évben nyitva tartó
                        </a-select-option>
                    </a-select>
                </a-form-item>

                <a-form-item
                        v-show="form.getFieldValue('seasonal') === 1"
                        label="Nyitás dátuma"
                >
                    <a-date-picker
                            :disabled-date="openingRangeStartDisabled"
                            v-decorator="[
                        `seasonStartDate`,
                        {
                            rules: [{
                                required: form.getFieldValue('seasonal') === 1,
                                message: 'Kérjük töltse ki!',
                            }],
                        }
                    ]"
                            style="width: 85%"
                            :format="dateFields['seasonStartDate']"
                    >
                    </a-date-picker>
                </a-form-item>

                <a-form-item
                        v-show="form.getFieldValue('seasonal') === 1"
                        label="Zárás dátuma"
                >
                    <a-date-picker
                            :disabled-date="openingRangeEndDisabled"
                            v-decorator="[
                        'seasonEndDate',
                        {
                            rules: [{
                                required: form.getFieldValue('seasonal') === 1,
                                message: 'Kérjük töltse ki!',
                            }],
                        }
                    ]"
                            style="width: 85%"
                            :format="dateFields['seasonEndDate']"
                    >
                    </a-date-picker>
                </a-form-item>

                <h2>
                    <span class="required-asterisk">*</span>
                    A Tourinform iroda nyitva tartása
                </h2>

                <template v-for="day in this.days">
                    <h4 v-if="day.weekdayStart">
                        Hétköznap
                    </h4>

                    <h4 v-if="day.weekendStart">
                        Hétvégén
                    </h4>

                    <a-form-item
                            :label="day.name"
                            :key="day.fieldPrefix"
                    >
                        <a-row>
                            <a-col :md="8">
                                <a-form-item>
                                    <a-time-picker
                                            :disabled="isChecked(day.fieldPrefix + '/closed')"
                                            v-decorator="[
                                    day.fieldPrefix + '/startTime',
                                    {
                                    rules: [
                                        {
                                          required: !isChecked(day.fieldPrefix + '/closed'),
                                          message: 'Kérjük válassza ki a listából a megfelelő értéket VAGY ZÁRVA',
                                        }
                                      ]
                                    }
                                    ]"
                                            style="width: 85%"
                                            :minute-step="15"
                                            :format="dateFields[day.fieldPrefix + '/startTime']"
                                            v-on:change="(time, timeString) => timePickerChange(day.fieldPrefix + '/startTime', time, timeString)"
                                    >
                                    </a-time-picker>

                                    <span>-tól</span>
                                </a-form-item>
                            </a-col>

                            <a-col :md="8">
                                <a-form-item>
                                    <a-time-picker
                                            :disabled="isChecked(day.fieldPrefix + '/closed')"
                                            v-decorator="[
                        day.fieldPrefix + '/endTime',
                          {
                            rules: [
                              {
                                required: !isChecked(day.fieldPrefix + '/closed'),
                                message: 'Kérjük válassza ki a listából a megfelelő értéket VAGY ZÁRVA',
                              }
                            ]
                          }
                        ]"
                                            :minute-step="15"
                                            style="width: 85%"
                                            :format="dateFields[day.fieldPrefix + '/endTime']"
                                            v-on:change="(time, timeString) => timePickerChange(day.fieldPrefix + '/endTime', time, timeString)"
                                    >
                                    </a-time-picker>

                                    <span>-ig</span>
                                </a-form-item>
                            </a-col>

                            <a-col :md="8">
                                <a-form-item>
                                    <a-checkbox
                                            v-decorator="[day.fieldPrefix + '/closed']"
                                            :checked="isChecked(day.fieldPrefix + '/closed')"
                                            v-on:change="() => relevancyCheckboxChange([day.fieldPrefix + '/startTime', day.fieldPrefix + '/endTime'])"
                                    >
                                        ZÁRVA
                                    </a-checkbox>
                                </a-form-item>
                            </a-col>
                        </a-row>
                    </a-form-item>
                </template>

                <a-divider />

                <a-form-item
                        :selfUpdate="true"
                        label="Ha a nyitva tartás hónaponként eltérő kérjük itt jelezze"
                >
                    <a-textarea
                            :maxLength="25000"
                            v-decorator="[
                        'monthlyOpening',
                        {
                            rules: [
                                {
                                required: false,
                                message: 'Kérjük töltse ki!'
                                },
                                {
                                  max: characterCounts['monthlyOpening'].max,
                                  message: 'Maximum ' + characterCounts['monthlyOpening'].max + ' karakter engedélyezett.',
                                }
                            ]
                        }
                    ]"
                            :autoSize="{minRows: 1}"
                    />
                </a-form-item>

                <a-form-item
                        :selfUpdate="true"
                        label="Speciális nyitva tartás (egyéb a fenti nyitva tartási kategóriákba nem besorolható esetek; pl.: 04.17-én technikai okok miatt zárva)"
                >
                    <a-textarea
                            :maxLength="25000"
                            v-decorator="[
                        'specialOpening',
                        {
                            rules: [
                                {
                                required: false,
                                message: 'Kérjük töltse ki!'
                                },
                                {
                                  max: characterCounts['specialOpening'].max,
                                  message: 'Maximum ' + characterCounts['specialOpening'].max + ' karakter engedélyezett.',
                                }
                            ]
                        }
                    ]"
                            :autoSize="{minRows: 1}"
                    />
                </a-form-item>

                <a-form-item label="Az iroda műemléki épületben található?">
                    <a-select
                            v-decorator="[
                        'officePlace/monument',
                        {
                            rules: [{
                                required: true,
                                message: 'Kérjük válasszon!'
                            }]
                        }
                    ]"
                            placeholder="Kérjük válasszon!"
                    >
                        <a-select-option :value="1">
                            Igen
                        </a-select-option>

                        <a-select-option :value="0">
                            Nem
                        </a-select-option>
                    </a-select>
                </a-form-item>

                <a-form-item label="Az iroda vendégterének alapterülete" :selfUpdate="true">
                    <a-input-number
                            :max="9999"
                            v-decorator="[
                        'officePlace/size',
                        {
                            validateTrigger: ['blur', 'keyup'],
                            rules: [{
                                required: true,
                                message: 'Kérjük töltse ki!',
                            }]
                        }
                    ]"
                    />
                    m<sup>2</sup>
                </a-form-item>

                <a-form-item label="Az iroda utolsó felújításának éve" :selfUpdate="true">
                    <a-input-number
                            :max="2100"
                            :min="1901"
                            v-decorator="[
                        'officePlace/lastRenovation',
                        {
                            validateTrigger: ['blur', 'keyup'],
                            rules: [{
                                required: true,
                                message: 'Kérjük töltse ki!',
                            }]
                        }
                    ]"
                    />
                    év
                </a-form-item>

                <a-form-item label="Az iroda fizikai akadálymentesített (kerekesszékkel bejárható)?">
                    <a-select
                            v-decorator="[
                        'officePlace/physicallyAccessible',
                        {
                            rules: [{
                                required: true,
                                message: 'Kérjük töltse ki!'
                            }],
                            validateTrigger: 'blur'
                        }
                    ]"
                            placeholder="Kérjük válasszon!"
                    >
                        <a-select-option :value="1">
                            Igen
                        </a-select-option>

                        <a-select-option :value="0">
                            Nem
                        </a-select-option>
                    </a-select>
                </a-form-item>

                <a-form-item label="Az iroda hallássérültek számára akadálymentesített?">
                    <a-select
                            v-decorator="[
                        'officePlace/deafAccessible',
                        {
                            rules: [{
                                required: true,
                                message: 'Kérjük töltse ki!'
                            }],
                            validateTrigger: 'blur'
                        }
                    ]"
                            placeholder="Kérjük válasszon!"
                    >
                        <a-select-option :value="1">
                            Igen
                        </a-select-option>

                        <a-select-option :value="0">
                            Nem
                        </a-select-option>
                    </a-select>
                </a-form-item>

                <a-form-item label="Az iroda látássérültek számára akadálymentesített?">
                    <a-select
                            v-decorator="[
                        'officePlace/blindAccessible',
                        {
                            rules: [{
                                required: true,
                                message: 'Kérjük töltse ki!'
                            }],
                            validateTrigger: 'blur'
                        }
                    ]"
                            placeholder="Kérjük válasszon!"
                    >
                        <a-select-option :value="1">
                            Igen
                        </a-select-option>

                        <a-select-option :value="0">
                            Nem
                        </a-select-option>
                    </a-select>
                </a-form-item>

                <a-form-item
                        label="A Tourinform iroda környezete akadálymentesített-e? (bejárattól 100 méteres körben)?">
                    <a-select
                            v-decorator="[
                        'officePlace/areaAccessible',
                        {
                            rules: [{
                                required: true,
                                message: 'Kérjük töltse ki!'
                            }],
                            validateTrigger: 'blur'
                        }
                    ]"
                            placeholder="Kérjük válasszon!"
                    >
                        <a-select-option :value="1">
                            Igen
                        </a-select-option>

                        <a-select-option :value="0">
                            Nem
                        </a-select-option>
                    </a-select>
                </a-form-item>

                <a-form-item label="Milyen bankkártya típusokat fogadnak el?">
                    <a-select
                            :disabled="!isChecked('creditCardsAccepted')"
                            :getPopupContainer="getParentTriggerNode()"
                            mode="multiple"
                            :loading="loading['creditCards/id']"
                            v-decorator="['creditCards/id', {
                            rules: [{
                                required: false,
                                message: 'Kérjük töltse ki!'
                            }],
                            validateTrigger: 'blur'
                        }]"
                            placeholder="Kérjük válasszon!"
                            :filterOption="filterSelectNames"
                            v-on:dropdownVisibleChange="(open) => open && loadOptions('creditCards/id')"
                    >
                        <a-select-option
                                v-for="category in options['creditCards/id']"
                                :key="category.id"
                                :value="category.id"
                        >
                            {{ category.label }}
                        </a-select-option>
                    </a-select>

                    <a-checkbox
                            v-decorator="[
                        'creditCardsAccepted',
                        {
                            rules: [{
                                required: false
                            }]
                        }
                    ]"
                            :checked="isChecked('creditCardsAccepted')"
                            v-on:change="() => relevancyCheckboxChange('creditCardsAccepted')"
                    >
                        Elfogadnak bankkártyát
                    </a-checkbox>
                </a-form-item>

                <a-form-item label="Milyen utalványokat, vouchereket fogadnak el?">
                    <a-select
                            :disabled="!isChecked('vouchersAccepted')"
                            :getPopupContainer="getParentTriggerNode()"
                            mode="multiple"
                            :loading="loading['vouchers/id']"
                            v-decorator="['vouchers/id', {
                            rules: [{
                                required: false,
                                message: 'Kérjük töltse ki!'
                            }],
                            validateTrigger: 'blur'
                        }]"
                            placeholder="Kérjük válasszon!"
                            :filterOption="filterSelectNames"
                            v-on:dropdownVisibleChange="(open) => open && loadOptions('vouchers/id')"
                    >
                        <a-select-option
                                v-for="category in options['vouchers/id']"
                                :key="category.id"
                                :value="category.id"
                        >
                            {{ category.label }}
                        </a-select-option>
                    </a-select>

                    <a-checkbox
                            v-decorator="[
                        'vouchersAccepted',
                        {
                            rules: [{
                                required: false
                            }]
                        }
                    ]"
                            :checked="isChecked('vouchersAccepted')"
                            v-on:change="() => relevancyCheckboxChange('vouchersAccepted')"
                    >
                        Elfogadnak utalványokat, vouchereket
                    </a-checkbox>
                </a-form-item>

                <a-form-item label="Milyen idegen fizetőeszközt elfogadnak? (valuta)">
                    <a-select
                            :disabled="!isChecked('foreignCurrencyAccepted')"
                            :getPopupContainer="getParentTriggerNode()"
                            mode="multiple"
                            :loading="loading['foreignCurrencies/id']"
                            v-decorator="['foreignCurrencies/id', {
                            rules: [{
                                required: false,
                                message: 'Kérjük töltse ki!'
                            }],
                            validateTrigger: 'blur'
                        }]"
                            placeholder="Kérjük válasszon!"
                            :filterOption="filterSelectNames"
                            v-on:dropdownVisibleChange="(open) => open && loadOptions('foreignCurrencies/id')"
                    >
                        <a-select-option
                                v-for="category in options['foreignCurrencies/id']"
                                :key="category.id"
                                :value="category.id"
                        >
                            {{ category.label }}
                        </a-select-option>
                    </a-select>

                    <a-checkbox
                            v-decorator="[
                        'foreignCurrencyAccepted',
                        {
                            rules: [{
                                required: false
                            }]
                        }
                    ]"
                            :checked="isChecked('foreignCurrencyAccepted')"
                            v-on:change="() => relevancyCheckboxChange('foreignCurrencyAccepted')"
                    >
                        Elfogadnak idegen fizetőeszközt (valuta)
                    </a-checkbox>
                </a-form-item>

                <a-form-item
                        label="A tourinform iroda szolgáltatásai (Több válasz is adható)."
                >
                    <a-select
                            :getPopupContainer="getParentTriggerNode()"
                            mode="multiple"
                            :loading="loading['services/id']"
                            v-decorator="['services/id', {
                            rules: [{
                                required: true,
                                message: 'Kérjük töltse ki!'
                            }],
                            validateTrigger: 'blur'
                        }]"
                            placeholder="Kérjük válasszon!"
                            :filterOption="filterSelectNames"
                            v-on:dropdownVisibleChange="(open) => open && loadOptions('services/id')"
                    >
                        <a-select-option
                                v-for="service in options['services/id']"
                                :key="service.id"
                                :value="service.id"
                        >
                            {{ service.name }}
                        </a-select-option>
                    </a-select>
                </a-form-item>

                <a-form-item
                        :selfUpdate="true"
                        label="A Tourinform iroda egyéb szolgáltatásai"
                >
                    <a-textarea
                            :maxLength="25000"
                            v-decorator="[
                        'otherServices',
                        {
                            rules: [
                                {
                                required: false,
                                message: 'Kérjük töltse ki!'
                                },
                                {
                                  max: characterCounts['otherServices'].max,
                                  message: 'Maximum ' + characterCounts['otherServices'].max + ' karakter engedélyezett.',
                                }
                            ]
                        }
                    ]"
                            :autoSize="{minRows: 1}"
                    />
                </a-form-item>

                <a-form-item
                        :selfUpdate="true"
                        label="A Tourinform iroda egyéb szolgáltatásai angol nyelven"
                >
                    <a-textarea
                            :maxLength="25000"
                            v-decorator="[
                        'otherServicesExtra',
                        {
                            rules: [
                                {
                                required: false,
                                message: 'Kérjük töltse ki!'
                                },
                                {
                                  max: characterCounts['otherServicesExtra'].max,
                                  message: 'Maximum ' + characterCounts['otherServicesExtra'].max + ' karakter engedélyezett.',
                                }
                            ]
                        }
                    ]"
                            :autoSize="{minRows: 1}"
                    />
                </a-form-item>

                <a-form-item
                        label="Rendelkezik az iroda fix helyszínre telepített Tourinform-ponttal/Infóponttal (pl, épület, pavilon, konténeriroda)?">
                    <a-select
                            v-decorator="[
                        'hasInfopoint',
                        {
                            rules: [{
                                required: true,
                                message: 'Kérjük töltse ki!'
                            }],
                            validateTrigger: 'blur'
                        }
                    ]"
                            placeholder="Kérjük válasszon!"
                    >
                        <a-select-option :value="1">
                            Igen
                        </a-select-option>

                        <a-select-option :value="0">
                            Nem
                        </a-select-option>
                    </a-select>
                </a-form-item>

                <template>
                    <a-form-item
                            label="A Tourinform-pont/Infópont pontos címe (pl.: 1234 Település, Minta utca 56.sz. 7.a.)"
                            :selfUpdate="true"
                            v-show="form.getFieldValue('hasInfopoint')"
                    >
                        <a-textarea
                                :maxLength="25000"
                                readonly="readonly"
                                v-decorator="[
                            'infopointPlace/mailingAddress',
                            {
                                rules: [
                                    {
                                      required: false,
                                      preserve: true,
                                      message: 'Kérjük töltse ki!'
                                    },
                                    {
                                      max: characterCounts['infopointPlace/mailingAddress'].max,
                                      message: 'Maximum ' + characterCounts['infopointPlace/mailingAddress'].max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur', 'keyup']
                            }
                        ]"
                                :autoSize="{minRows: 1}"
                        />
                    </a-form-item>

                    <a-form-item
                            label="A Tourinform-pont/Infópont irányítószáma"
                            v-show="form.getFieldValue('hasInfopoint')"
                    >
                        <a-select
                                v-decorator="[
                            'infopointPlace/address/zipCode/id',
                            {
                                rules: [
                                    {
                                    required: form.getFieldValue('hasInfopoint'),
                                    whitespace: true,
                                    message: 'Kérjük töltse ki!',
                                    type: 'integer'
                                  },
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
                                placeholder="Kezdjen gépelni"
                                showSearch
                                :showArrow="false"
                                :defaultActiveFirstOption="false"
                                :getPopupContainer="getParentTriggerNode()"
                                :filterOption="false"
                                :style="{width: '100%'}"
                                v-on:search="(value) => zipCodeAutoComplete(value,'infopointPlace/address/zipCode/id')"
                                v-on:change="(value) => {fillCityOptions(value,'infopointPlace/address/zipCode/id','infopointPlace/address/city/id')}"
                                v-on:dropdownVisibleChange="(open) => open && zipCodeAutoComplete(null, 'infopointPlace/address/zipCode/id')"
                                :notFoundContent="loading['infopointPlace/address/zipCode/id'] ? undefined : null"
                        >
                            <a-spin v-if="loading['infopointPlace/address/zipCode/id']" slot="notFoundContent"
                                    size="small" />

                            <a-select-option
                                    v-for="option in options['infopointPlace/address/zipCode/id']"
                                    :key="option.id"
                                    :value="option.id"
                            >
                                {{ option.code }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item label="A Tourinform-pont/Infópont településének neve"
                                 v-show="form.getFieldValue('hasInfopoint')"
                    >
                        <a-select
                                v-decorator="[
                            'infopointPlace/address/city/id',
                            {
                                rules: [
                                    {
                                    required: form.getFieldValue('hasInfopoint'),
                                    whitespace: true,
                                    message: 'Kérjük töltse ki!',
                                    type: 'integer'
                                  },
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
                                placeholder="Töltse ki az irányítószám mezőt!"
                                :showArrow="false"
                                :disabled="!options['infopointPlace/address/city/id'].length"
                                :defaultActiveFirstOption="true"
                                :filterOption="false"
                                :style="{width: '100%'}"
                                :notFoundContent="loading['infopointPlace/address/zipCode/id'] ? undefined : null"
                        >
                            <a-spin v-if="loading['infopointPlace/address/zipCode/id']" slot="notFoundContent"
                                    size="small" />

                            <a-select-option
                                    v-for="option in options['infopointPlace/address/city/id']"
                                    :key="option.id"
                                    :value="option.id"
                            >
                                {{ option.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item
                            :selfUpdate="true"
                            label="A Tourinform-pont/Infópont közterületének neve (pl.: Váci út, Virág utca, Batthyány tér)"
                            v-show="form.getFieldValue('hasInfopoint')"
                    >
                        <a-input
                                :maxLength="25000"
                                v-decorator="[
                            'infopointPlace/address/address',
                            {
                                rules: [
                                    {
                                      required: form.getFieldValue('hasInfopoint'),
                                      whitespace: true,
                                      message: 'Kérjük töltse ki!'
                                    },
                                    {
                                      max: characterCounts['infopointPlace/address/address'].max,
                                      message: 'Maximum ' + characterCounts['infopointPlace/address/address'].max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
                        >
                        </a-input>
                    </a-form-item>

                    <a-form-item
                            :selfUpdate="true"
                            v-show="form.getFieldValue('hasInfopoint')"
                            label="A Tourinform-pont/Infópont közterületének neve angol nyelven (pl.: Váci road, Virág street, Batthyány square)"
                    >
                        <a-input
                                :maxLength="25000"
                                v-decorator="[
                            'infopointPlace/address/addressExtra',
                            {
                                rules: [
                                    {
                                      required: form.getFieldValue('hasInfopoint'),
                                      whitespace: true,
                                      message: 'Kérjük töltse ki!'
                                    },
                                    {
                                      max: characterCounts['infopointPlace/address/addressExtra'].max,
                                      message: 'Maximum ' + characterCounts['infopointPlace/address/addressExtra'].max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
                        >
                        </a-input>
                    </a-form-item>

                    <a-form-item
                            :selfUpdate="true"
                            label="A Tourinform-pont/Infópont házszáma"
                            v-show="form.getFieldValue('hasInfopoint')"
                    >
                        <a-input
                                :maxLength="25000"
                                v-decorator="[
                            'infopointPlace/address/addressNumber',
                            {
                                rules: [
                                    {
                                      required: form.getFieldValue('hasInfopoint'),
                                      whitespace: true,
                                      message: 'Kérjük töltse ki!'
                                    },
                                    {
                                      max: characterCounts['infopointPlace/address/addressNumber'].max,
                                      message: 'Maximum ' + characterCounts['infopointPlace/address/addressNumber'].max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
                        />
                    </a-form-item>

                    <a-form-item :selfUpdate="true" v-show="form.getFieldValue('hasInfopoint')">
          <span slot="label">
              A Tourinform-pont/Infópont GPS koordinátája: szélességi fok

              <a-tooltip :title="coordinateTooltipLatitude">
                  <a-icon type="question-circle-o" />
              </a-tooltip>
          </span>

                        <a-input
                                :maxLength="25000"
                                v-on:change="(event) => formatCoordinate(event.target.value, 'infopointPlace/address/lat')"
                                v-decorator="[
                            'infopointPlace/address/lat',
                            {
                                rules: [{
                                    required: form.getFieldValue('hasInfopoint'),
                                    whitespace: true,
                                    pattern: /^4([-+]?)([\d]{1})(((\.)(\d+)))$/g,
                                    message: 'Kérjük adjon meg helyes GPS koordinátákat!'
                                },
                                    {
                                      type: 'float',
                                      message: 'Kérjük adjon meg helyes GPS koordinátákat!',
                                      transform: (value) => {
                                        value = parseFloat(value);

                                        return isNaN(value) ? null: value;
                                      },
                                      min: 45.72,
                                      max: 48.60
                                    }],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
                                placeholder="Kérjük a koordinátát az alábbi formátumban adja meg: 47.507346"
                        >
                        </a-input>
                    </a-form-item>

                    <a-form-item :selfUpdate="true" v-show="form.getFieldValue('hasInfopoint')">
          <span slot="label">
            A Tourinform-pont/Infópont GPS koordinátája: hosszúsági fok

            <a-tooltip :title="coordinateTooltipLongitude">
                <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>

                        <a-input
                                :maxLength="25000"
                                v-on:change="(event) => formatCoordinate(event.target.value, 'infopointPlace/address/long')"
                                v-decorator="[
                            'infopointPlace/address/long',
                            {
                                rules: [{
                                    required: form.getFieldValue('hasInfopoint'),
                                    whitespace: true,
                                    pattern: /^(1|2)(([-+]?)([\d]{1})((\.)(\d+))?)$/g,
                                    message: 'Kérjük adjon meg helyes GPS koordinátákat!'
                                },
                                    {
                                      type: 'float',
                                      message: 'Kérjük adjon meg helyes GPS koordinátákat!',
                                      transform: (value) => {
                                        value = parseFloat(value);

                                        return isNaN(value) ? null: value;
                                      },
                                      min: 16.10,
                                      max: 23
                                    }],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
                                placeholder="Kérjük a koordinátát az alábbi formátumban adja meg: 19.046610"
                        >
                        </a-input>
                    </a-form-item>

                    <a-form-item
                            label="A Tourinform-pont/Infópont munkatársai által beszélt nyelvek (Több válasz is adható)."
                            v-show="form.getFieldValue('hasInfopoint')">
                        <a-select
                                :getPopupContainer="getParentTriggerNode()"
                                mode="multiple"
                                v-decorator="[
                        'infopointPlace/spokenLanguages/id',
                        {
                            rules: [{
                                required: form.getFieldValue('hasInfopoint'),
                                message: 'Kérjük töltse ki!'
                            }],
                            validateTrigger: ['blur']
                        }
                    ]"
                                placeholder="Kérjük válasszon!"
                                :filterOption="filterSelectNames"
                        >
                            <a-select-option
                                    v-for="language in options['infopointPlace/spokenLanguages/id']"
                                    :key="language.id"
                                    :value="language.id"
                            >
                                {{ language.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item label="Tourinform-pont/Infópont típusa (ügyfélfogadási időtartam szerint)"
                                 v-show="form.getFieldValue('hasInfopoint')">
                        <a-select
                                v-decorator="[
                        'infopointPlace/seasonal',
                        {
                            rules: [{
                                required: true,
                                message: 'Kérjük válasszon!'
                            }]
                        }
                    ]"
                                placeholder="Kérjük válasszon!"
                        >
                            <a-select-option :value="1">
                                Szezonálisan nyitva tartó
                            </a-select-option>

                            <a-select-option :value="0">
                                Egész évben nyitva tartó
                            </a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item
                            v-show="form.getFieldValue('hasInfopoint') && form.getFieldValue('infopointPlace/seasonal') === 1"
                            label="Nyitás dátuma"
                    >
                        <a-date-picker
                                :disabled-date="infopointOpeningRangeStartDisabled"
                                v-decorator="[
                        `infopointPlace/seasonStartDate`,
                        {
                            rules: [{
                                required: form.getFieldValue('infopointPlace/seasonal') === 1,
                                message: 'Kérjük töltse ki!',
                            }],
                        }
                    ]"
                                style="width: 85%"
                                :format="dateFields['infopointPlace/seasonStartDate']"
                        >
                        </a-date-picker>
                    </a-form-item>

                    <a-form-item
                            v-show="form.getFieldValue('hasInfopoint') && form.getFieldValue('infopointPlace/seasonal') === 1"
                            label="Zárás dátuma"
                    >
                        <a-date-picker
                                :disabled-date="infopointOpeningRangeEndDisabled"
                                v-decorator="[
                        'infopointPlace/seasonEndDate',
                        {
                            rules: [{
                                required: form.getFieldValue('infopointPlace/seasonal') === 1,
                                message: 'Kérjük töltse ki!',
                            }],
                        }
                    ]"
                                style="width: 85%"
                                :format="dateFields['infopointPlace/seasonEndDate']"
                        >
                        </a-date-picker>
                    </a-form-item>

                    <h2 v-show="form.getFieldValue('hasInfopoint')">
                        <span class="required-asterisk">*</span>
                        A Tourinform-pont/Infópont nyitva tartása
                    </h2>

                    <template v-for="day in this.days">
                        <h4 v-if="day.weekdayStart" v-show="form.getFieldValue('hasInfopoint')">
                            Hétköznap
                        </h4>

                        <h4 v-if="day.weekendStart" v-show="form.getFieldValue('hasInfopoint')">
                            Hétvégén
                        </h4>

                        <a-form-item
                                v-show="form.getFieldValue('hasInfopoint')"
                                :label="day.name"
                                :key="'infopointPlace/' + day.fieldPrefix"
                        >
                            <a-row>
                                <a-col :md="8">
                                    <a-form-item>
                                        <a-time-picker
                                                :disabled="isChecked('infopointPlace/' + day.fieldPrefix + '/closed')"
                                                v-decorator="[
                                    'infopointPlace/' + day.fieldPrefix + '/startTime',
                                    {
                                    initialValue: moment('1970-01-01 00:00:00'),
                                    rules: [
                                        {
                                          required: form.getFieldValue('hasInfopoint') && !isChecked('infopointPlace/' + day.fieldPrefix + '/closed'),
                                          message: 'Kérjük válassza ki a listából a megfelelő értéket VAGY ZÁRVA',
                                        }
                                      ]
                                    }
                                    ]"
                                                style="width: 85%"
                                                :minute-step="15"
                                                :format="dateFields['infopointPlace/' + day.fieldPrefix + '/startTime']"
                                                v-on:change="(time, timeString) => timePickerChange('infopointPlace/' + day.fieldPrefix + '/startTime', time, timeString)"
                                        >
                                        </a-time-picker>

                                        <span>-tól</span>
                                    </a-form-item>
                                </a-col>

                                <a-col :md="8">
                                    <a-form-item>
                                        <a-time-picker
                                                :disabled="isChecked('infopointPlace/' + day.fieldPrefix + '/closed')"
                                                v-decorator="[
                        'infopointPlace/' + day.fieldPrefix + '/endTime',
                          {
                            initialValue: moment('1970-01-01 00:00:00'),
                            rules: [
                              {
                                required: form.getFieldValue('hasInfopoint') && !isChecked('infopointPlace/' + day.fieldPrefix + '/closed'),
                                message: 'Kérjük válassza ki a listából a megfelelő értéket VAGY ZÁRVA',
                              }
                            ]
                          }
                        ]"
                                                :minute-step="15"
                                                style="width: 85%"
                                                :format="dateFields['infopointPlace/' + day.fieldPrefix + '/endTime']"
                                                v-on:change="(time, timeString) => timePickerChange('infopointPlace/' + day.fieldPrefix + '/endTime', time, timeString)"
                                        >
                                        </a-time-picker>

                                        <span>-ig</span>
                                    </a-form-item>
                                </a-col>

                                <a-col :md="8">
                                    <a-form-item>
                                        <a-checkbox
                                                v-decorator="['infopointPlace/' + day.fieldPrefix + '/closed']"
                                                :checked="isChecked('infopointPlace/' + day.fieldPrefix + '/closed')"
                                                v-on:change="() => relevancyCheckboxChange(['infopointPlace/' + day.fieldPrefix + '/startTime', 'infopointPlace/' + day.fieldPrefix + '/endTime'])"
                                        >
                                            ZÁRVA
                                        </a-checkbox>
                                    </a-form-item>
                                </a-col>
                            </a-row>
                        </a-form-item>
                    </template>

                    <a-divider />

                    <a-form-item
                            v-show="form.getFieldValue('hasInfopoint')"
                            :selfUpdate="true"
                            label="Ha a nyitva tartás hónaponként eltérő kérjük itt jelezze"
                    >
                        <a-textarea
                                :maxLength="25000"
                                v-decorator="[
                        'infopointPlace/monthlyOpening',
                        {
                            rules: [
                                {
                                required: false,
                                message: 'Kérjük töltse ki!'
                                },
                                {
                                  max: characterCounts['infopointPlace/monthlyOpening'].max,
                                  message: 'Maximum ' + characterCounts['infopointPlace/monthlyOpening'].max + ' karakter engedélyezett.',
                                }
                            ]
                        }
                    ]"
                                :autoSize="{minRows: 1}"
                        />
                    </a-form-item>

                    <a-form-item
                            v-show="form.getFieldValue('hasInfopoint')"
                            :selfUpdate="true"
                            label="Speciális nyitva tartás (egyéb a fenti nyitva tartási kategóriákba nem besorolható esetek; pl.: 04.17-én technikai okok miatt zárva)"
                    >
                        <a-textarea
                                :maxLength="25000"
                                v-decorator="[
                        'infopointPlace/specialOpening',
                        {
                            rules: [
                                {
                                required: false,
                                message: 'Kérjük töltse ki!'
                                },
                                {
                                  max: characterCounts['infopointPlace/specialOpening'].max,
                                  message: 'Maximum ' + characterCounts['infopointPlace/specialOpening'].max + ' karakter engedélyezett.',
                                }
                            ]
                        }
                    ]"
                                :autoSize="{minRows: 1}"
                        />
                    </a-form-item>

                    <a-form-item label="A Tourinform-pont/Infópont műemléki épületben található?"
                                 v-show="form.getFieldValue('hasInfopoint')">
                        <a-select
                                v-decorator="[
                        'infopointPlace/monument',
                        {
                            rules: [{
                                required: form.getFieldValue('hasInfopoint'),
                                message: 'Kérjük válasszon!'
                            }]
                        }
                    ]"
                                placeholder="Kérjük válasszon!"
                        >
                            <a-select-option :value="1">
                                Igen
                            </a-select-option>

                            <a-select-option :value="0">
                                Nem
                            </a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item label="A Tourinform-pont/Infópont vendégterének alapterülete" :selfUpdate="true"
                                 v-show="form.getFieldValue('hasInfopoint')">
                        <a-input-number
                                :max="9999"
                                v-decorator="[
                        'infopointPlace/size',
                        {
                            validateTrigger: ['blur', 'keyup'],
                            rules: [{
                                required: form.getFieldValue('hasInfopoint'),
                                message: 'Kérjük töltse ki!',
                            }]
                        }
                    ]"
                        />
                        m<sup>2</sup>
                    </a-form-item>

                    <a-form-item label="A Tourinform-pont/Infópont utolsó felújításának éve" :selfUpdate="true"
                                 v-show="form.getFieldValue('hasInfopoint')">
                        <a-input-number
                                :max="2100"
                                :min="1901"
                                v-decorator="[
                        'infopointPlace/lastRenovation',
                        {
                            validateTrigger: ['blur', 'keyup'],
                            rules: [{
                                required: form.getFieldValue('hasInfopoint'),
                                message: 'Kérjük töltse ki!',
                            }]
                        }
                    ]"
                        />
                        év
                    </a-form-item>

                    <a-form-item
                            label="A Tourinform-pont/Infópont fizikai akadálymentesített (kerekesszékkel bejárható)?"
                            v-show="form.getFieldValue('hasInfopoint')">
                        <a-select
                                v-decorator="[
                        'infopointPlace/physicallyAccessible',
                        {
                            rules: [{
                                required: form.getFieldValue('hasInfopoint'),
                                message: 'Kérjük töltse ki!'
                            }],
                            validateTrigger: 'blur'
                        }
                    ]"
                                placeholder="Kérjük válasszon!"
                        >
                            <a-select-option :value="1">
                                Igen
                            </a-select-option>

                            <a-select-option :value="0">
                                Nem
                            </a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item label="A Tourinform-pont/Infópont hallássérültek számára akadálymentesített?"
                                 v-show="form.getFieldValue('hasInfopoint')">
                        <a-select
                                v-decorator="[
                        'infopointPlace/deafAccessible',
                        {
                            rules: [{
                                required: form.getFieldValue('hasInfopoint'),
                                message: 'Kérjük töltse ki!'
                            }],
                            validateTrigger: 'blur'
                        }
                    ]"
                                placeholder="Kérjük válasszon!"
                        >
                            <a-select-option :value="1">
                                Igen
                            </a-select-option>

                            <a-select-option :value="0">
                                Nem
                            </a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item label="A Tourinform-pont/Infópont látássérültek számára akadálymentesített?"
                                 v-show="form.getFieldValue('hasInfopoint')">
                        <a-select
                                v-decorator="[
                        'infopointPlace/blindAccessible',
                        {
                            rules: [{
                                required: form.getFieldValue('hasInfopoint'),
                                message: 'Kérjük töltse ki!'
                            }],
                            validateTrigger: 'blur'
                        }
                    ]"
                                placeholder="Kérjük válasszon!"
                        >
                            <a-select-option :value="1">
                                Igen
                            </a-select-option>

                            <a-select-option :value="0">
                                Nem
                            </a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item
                            label="A Tourinform-pont/Infópont környezete akadálymentesített-e? (bejárattól 100 méteres körben)?"
                            v-show="form.getFieldValue('hasInfopoint')">
                        <a-select
                                v-decorator="[
                        'infopointPlace/areaAccessible',
                        {
                            rules: [{
                                required: form.getFieldValue('hasInfopoint'),
                                message: 'Kérjük töltse ki!'
                            }],
                            validateTrigger: 'blur'
                        }
                    ]"
                                placeholder="Kérjük válasszon!"
                        >
                            <a-select-option :value="1">
                                Igen
                            </a-select-option>

                            <a-select-option :value="0">
                                Nem
                            </a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item
                            label="A Tourinform-pont/Infópont szolgáltatásai (Több válasz is adható)."
                            v-show="form.getFieldValue('hasInfopoint')"
                    >
                        <a-select
                                :getPopupContainer="getParentTriggerNode()"
                                mode="multiple"
                                :loading="loading['infopointPlace/services/id']"
                                v-decorator="['infopointPlace/services/id', {
                            rules: [{
                                required: form.getFieldValue('hasInfopoint'),
                                message: 'Kérjük töltse ki!'
                            }],
                            validateTrigger: 'blur'
                        }]"
                                placeholder="Kérjük válasszon!"
                                :filterOption="filterSelectNames"
                                v-on:dropdownVisibleChange="(open) => open && loadOptions('infopointPlace/services/id')"
                        >
                            <a-select-option
                                    v-for="service in options['infopointPlace/services/id']"
                                    :key="service.id"
                                    :value="service.id"
                            >
                                {{ service.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item label="A Tourinform-pontban/Infópontban milyen bankkártya típusokat fogadnak el?"
                                 v-show="form.getFieldValue('hasInfopoint')">
                        <a-select
                                :disabled="!isChecked('infopointPlace/creditCardsAccepted')"
                                :getPopupContainer="getParentTriggerNode()"
                                mode="multiple"
                                :loading="loading['infopointPlace/creditCards/id']"
                                v-decorator="['infopointPlace/creditCards/id', {
                            rules: [{
                                required: false,
                                message: 'Kérjük töltse ki!'
                            }],
                            validateTrigger: 'blur'
                        }]"
                                placeholder="Kérjük válasszon!"
                                :filterOption="filterSelectNames"
                                v-on:dropdownVisibleChange="(open) => open && loadOptions('infopointPlace/creditCards/id')"
                        >
                            <a-select-option
                                    v-for="category in options['infopointPlace/creditCards/id']"
                                    :key="category.id"
                                    :value="category.id"
                            >
                                {{ category.label }}
                            </a-select-option>
                        </a-select>

                        <a-checkbox
                                v-decorator="[
                        'infopointPlace/creditCardsAccepted',
                        {
                            rules: [{
                                required: false
                            }]
                        }
                    ]"
                                :checked="isChecked('infopointPlace/creditCardsAccepted')"
                                v-on:change="() => relevancyCheckboxChange('infopointPlace/creditCardsAccepted')"
                        >
                            A Tourinform-pontban/Infópontban elfogadnak bankkártyát
                        </a-checkbox>
                    </a-form-item>

                    <a-form-item label="A Tourinform-pontban/Infópontban milyen utalványokat, vouchereket fogadnak el?"
                                 v-show="form.getFieldValue('hasInfopoint')">
                        <a-select
                                :disabled="!isChecked('infopointPlace/vouchersAccepted')"
                                :getPopupContainer="getParentTriggerNode()"
                                mode="multiple"
                                :loading="loading['infopointPlace/vouchers/id']"
                                v-decorator="['infopointPlace/vouchers/id', {
                            rules: [{
                                required: false,
                                message: 'Kérjük töltse ki!'
                            }],
                            validateTrigger: 'blur'
                        }]"
                                placeholder="Kérjük válasszon!"
                                :filterOption="filterSelectNames"
                                v-on:dropdownVisibleChange="(open) => open && loadOptions('infopointPlace/vouchers/id')"
                        >
                            <a-select-option
                                    v-for="category in options['infopointPlace/vouchers/id']"
                                    :key="category.id"
                                    :value="category.id"
                            >
                                {{ category.label }}
                            </a-select-option>
                        </a-select>

                        <a-checkbox
                                v-decorator="[
                        'infopointPlace/vouchersAccepted',
                        {
                            rules: [{
                                required: false
                            }]
                        }
                    ]"
                                :checked="isChecked('infopointPlace/vouchersAccepted')"
                                v-on:change="() => relevancyCheckboxChange('infopointPlace/vouchersAccepted')"
                        >
                            A Tourinform-pontban/Infópontban elfogadnak utalványokat, vouchereket
                        </a-checkbox>
                    </a-form-item>

                    <!--          <a-form-item label="A Tourinform-pontban/Infópontban milyen idegen fizetőeszközt elfogadnak? (valuta)">-->
                    <!--            <a-select-->
                    <!--                :disabled="!isChecked('infopointPlace/foreignCurrencyAccepted')"-->
                    <!--                :getPopupContainer="getParentTriggerNode()"-->
                    <!--                mode="multiple"-->
                    <!--                :loading="loading['infopointPlace/foreignCurrencies/id']"-->
                    <!--                v-decorator="['infopointPlace/foreignCurrencies/id', {-->
                    <!--                            rules: [{-->
                    <!--                                required: false,-->
                    <!--                                message: 'Kérjük töltse ki!'-->
                    <!--                            }],-->
                    <!--                            validateTrigger: 'blur'-->
                    <!--                        }]"-->
                    <!--                placeholder="Kérjük válasszon!"-->
                    <!--                :filterOption="filterSelectNames"-->
                    <!--                v-on:dropdownVisibleChange="(open) => open && loadOptions('infopointPlace/foreignCurrencies/id')"-->
                    <!--            >-->
                    <!--              <a-select-option-->
                    <!--                  v-for="category in options['infopointPlace/foreignCurrencies/id']"-->
                    <!--                  :key="category.id"-->
                    <!--                  :value="category.id"-->
                    <!--              >-->
                    <!--                {{ category.label }}-->
                    <!--              </a-select-option>-->
                    <!--            </a-select>-->

                    <!--            <a-checkbox-->
                    <!--                v-decorator="[-->
                    <!--                        'infopointPlace/foreignCurrencyAccepted',-->
                    <!--                        {-->
                    <!--                            rules: [{-->
                    <!--                                required: false-->
                    <!--                            }]-->
                    <!--                        }-->
                    <!--                    ]"-->
                    <!--                :checked="isChecked('infopointPlace/foreignCurrencyAccepted')"-->
                    <!--                v-on:change="() => relevancyCheckboxChange('infopointPlace/foreignCurrencyAccepted')"-->
                    <!--            >-->
                    <!--              A Tourinform-pontban/Infópontban elfogadnak idegen fizetőeszközt (valuta)-->
                    <!--            </a-checkbox>-->
                    <!--          </a-form-item>-->
                </template>

                <a-form-item>
                    <a-row>
                        <a-col :xs="24" :md="12">
                            <a-form-item
                                    label="Irodavezető neve"
                                    :selfUpdate="true"
                            >
                                <a-input
                                        :maxLength="25000"
                                        v-decorator="[
                                  'leader/name',
                                  {
                                      validateTrigger: ['keyup', 'blur'],
                                      rules: [{
                                          required: true,
                                          whitespace: false,
                                          message: 'Kérjük töltse ki a nevet is!'
                                      }]
                                  }
                              ]"
                                >
                                </a-input>
                            </a-form-item>
                        </a-col>

                        <a-col :xs="24" :md="12">
                            <a-form-item
                                    label="Munkakezdés éve a Tourinform irodában"
                                    :selfUpdate="true"
                            >
                                <a-input-number
                                        :max="9999"
                                        :min="0"
                                        v-decorator="[
                                    'leader/startYear',
                                    {
                                        validateTrigger: ['keyup','blur'],
                                        rules: [{
                                            required: true,
                                            transform: (value) => {
                                              value = parseInt(value);

                                              return isNaN(value) ? null : value;
                                            },
                                            type: 'integer',
                                            whitespace: true,
                                            message: 'Kérjük töltse ki az évszámot is!'
                                        }]
                                    }
                                  ]"
                                >
                                </a-input-number>
                                év
                            </a-form-item>
                        </a-col>
                    </a-row>

                    <div
                            v-for="(employee, index) in employees"
                            :key="'employee-' + index"
                    >
                        <a-row>
                            <a-col :xs="24" :md="12">
                                <a-form-item
                                        :selfUpdate="true"
                                        label="Munkatárs neve"
                                >
                                    <a-input
                                            :maxLength="25000"
                                            v-on:change="(event) => updateEmployeeName(index, event.target.value)"
                                            v-decorator="[
                                  'employees/' + index + '/name',
                                  {
                                      validateTrigger: ['keyup', 'blur'],
                                      rules: [{
                                          required: true,
                                          whitespace: false,
                                          message: 'Kérjük töltse ki!'
                                      }]
                                  }
                              ]"
                                    >
                                    </a-input>
                                </a-form-item>
                            </a-col>

                            <a-col :xs="24" :md="12">
                                <a-form-item
                                        :selfUpdate="true"
                                        label="Munkakezdés éve a Tourinform irodában"
                                >
                                    <a-input-number
                                            v-on:change="(value) => updateEmployeeStartYear(index, value)"
                                            :max="9999"
                                            :min="0"
                                            v-decorator="[
                                    'employees/' + index + '/startYear',
                                    {
                                        validateTrigger: ['keyup','blur'],
                                        rules: [{
                                            required: true,
                                            transform: (value) => {
                                              value = parseInt(value);

                                              return isNaN(value) ? null : value;
                                            },
                                            type: 'integer',
                                            whitespace: true,
                                            message: 'Kérjük töltse ki az évszámot is!'
                                        }]
                                    }
                                  ]"
                                    >
                                    </a-input-number>
                                    év

                                    <a-icon
                                            v-if="employees.length > 0"
                                            class="dynamic-delete-button"
                                            type="delete"
                                            theme="filled"
                                            :disabled="employees.length === 0"
                                            v-on:click="() => removeEmployee(index)"
                                    />
                                </a-form-item>
                            </a-col>
                        </a-row>
                    </div>

                    <a-form-item>
                        <a-button
                                type="dashed"
                                style="width: 100%"
                                v-on:click="addEmployee"
                        >
                            <a-icon type="plus" />

                            Munkatárs hozzáadása
                        </a-button>
                    </a-form-item>
                </a-form-item>

                <a-form-item label="Fenntartó szervezet/cég hivatalos teljes neve (pl.: Cégneve Nonprofit Kft.)"
                             :selfUpdate="true">
                    <a-input
                            :maxLength="25000"
                            v-decorator="['organizationName',
                            {
                                rules: [
                                    {
                                      required: true,
                                      preserve: true,
                                      message: 'Kérjük töltse ki!'
                                    },
                                    {
                                      max: characterCounts['organizationName'].max,
                                      message: 'Maximum ' + characterCounts['organizationName'].max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur', 'keyup']
                            }
                        ]"
                            :autoSize="{minRows: 1}"
                    />
                </a-form-item>

                <a-form-item
                        label="Fenntartó szervezet/cég székhelyének pontos címe (pl.: 1234 Település, Minta utca 56.sz. 7.a.)"
                        :selfUpdate="true">
                    <a-input
                            :maxLength="25000"
                            v-decorator="['organizationAddress',
                            {
                                rules: [
                                    {
                                      required: true,
                                      preserve: true,
                                      message: 'Kérjük töltse ki!'
                                    },
                                    {
                                      max: characterCounts['organizationAddress'].max,
                                      message: 'Maximum ' + characterCounts['organizationAddress'].max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur', 'keyup']
                            }
                        ]"
                            :autoSize="{minRows: 1}"
                    />
                </a-form-item>

                <a-form-item label="Fenntartó szervezet/cég adószáma (pl.: 12345678-1-11)"
                             :selfUpdate="true">
                    <a-input
                            :maxLength="25000"
                            v-decorator="['organizationTaxId',
                            {
                                rules: [{
                                    required: true,
                                    whitespace: true,
                                    pattern: /^[0-9]{1,8}-[0-9]{1}-[0-9]{2}$/g,
                                    message: 'Kérjük, a megfelelő formátumban töltse ki!',
                                    max: 255
                                }],
                                validateTrigger: ['blur', 'keyup']
                            }
                        ]"
                            :autoSize="{minRows: 1}"
                    />
                </a-form-item>

                <a-form-item :selfUpdate="true">
          <span slot="label">
            Fenntartó szervezet/cég cégjegyzékszáma/nyilvántartási száma/törzskönyvi azonosítója &nbsp;
              <a-tooltip>
                <div slot="title">
                    <div>Formátum példák:</div>
                    <div>Cégjegyzékszám: 01-09-562739</div>
                    <div>Nyilvántartási szám: 00003-0012</div>
                    <div>Törzskönyvi azonosító: 307802</div>
                </div>

                <a-icon type="question-circle-o" />
              </a-tooltip>
          </span>

                    <a-input
                            :maxLength="25000"
                            v-decorator="['organizationCompanyId',
                            {
                                rules: [
                                    {
                                      required: true,
                                      whitespace: true,
                                      pattern: /[0-9-]/g,
                                      message: 'Kérjük, a megfelelő formátumban töltse ki!',
                                    },
                                    {
                                      max: characterCounts['organizationCompanyId'].max,
                                      message: 'Maximum ' + characterCounts['organizationCompanyId'].max + ' karakter engedélyezett.',
                                    }

                                ],
                                validateTrigger: ['blur', 'keyup']
                            }
                        ]"
                            :autoSize="{minRows: 1}"
                    />
                </a-form-item>

                <a-form-item
                        label="Fenntartó szervezet/cég pontos postázási címe (pl.: 1234 Település, Pf.: 123)"
                        :selfUpdate="true">
                    <a-input
                            :maxLength="25000"
                            v-decorator="['organizationMailingAddress',
                            {
                                rules: [
                                    {
                                      required: true,
                                      preserve: true,
                                      message: 'Kérjük töltse ki!'
                                    },
                                    {
                                      max: characterCounts['organizationMailingAddress'].max,
                                      message: 'Maximum ' + characterCounts['organizationMailingAddress'].max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur', 'keyup']
                            }
                        ]"
                            :autoSize="{minRows: 1}"
                    />
                </a-form-item>

                <a-form-item
                        label="Fenntartó szervezet/cég képviselőjének titulusa (pl.: elnök, ügyvezető igazgató, polgármester)"
                        :selfUpdate="true">
                    <a-input
                            :maxLength="25000"
                            v-decorator="['organizationLeaderTitle',
                            {
                                rules: [
                                    {
                                      required: true,
                                      preserve: true,
                                      message: 'Kérjük töltse ki!'
                                    },
                                    {
                                      max: characterCounts['organizationLeaderTitle'].max,
                                      message: 'Maximum ' + characterCounts['organizationLeaderTitle'].max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur', 'keyup']
                            }
                        ]"
                            :autoSize="{minRows: 1}"
                    />
                </a-form-item>

                <a-form-item
                        label="Fenntartó szervezet/cég képviselőjének neve"
                        :selfUpdate="true">
                    <a-input
                            :maxLength="25000"
                            v-decorator="['organizationLeaderName',
                            {
                                rules: [
                                    {
                                      required: true,
                                      preserve: true,
                                      message: 'Kérjük töltse ki!'
                                    },
                                    {
                                      max: characterCounts['organizationLeaderName'].max,
                                      message: 'Maximum ' + characterCounts['organizationLeaderName'].max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur', 'keyup']
                            }
                        ]"
                            :autoSize="{minRows: 1}"
                    />
                </a-form-item>

                <a-form-item label="Fenntartó szervezet/cég képviselőjének telefonszáma (pl.: +36 1 234 5678)"
                             :selfUpdate="true">
                    <a-input
                            :maxLength="25000"
                            :style="{width: '160px'}"
                            v-decorator="[
                                'organizationLeaderPhone',
                                {
                                    rules: [{
                                        required: true,
                                        pattern: /^[\/+][36]{1,2}[ ][0-9]{1,2}[ ][0-9]{3}[ ][0-9]{3,4}$/,
                                        message: 'Hibás telefonszám formátum!'
                                    }],
                                   validateTrigger: ['blur']
                                }
                            ]"
                            v-on:change="(event) => formatPhoneNumber(event.target.value, 'organizationLeaderPhone')"
                            ref="organizationLeaderPhone"
                            placeholder="+36 1 234 5678"
                    />
                </a-form-item>

                <a-form-item :selfUpdate="true">
          <span slot="label">
              Fenntartó szervezet/cég képviselőjének e-mail címe

              <a-tooltip
                      title="Kérjük, az e-mail címet iroda@domain.xy formátumban töltse ki!"
              >
                  <a-icon type="question-circle-o" />
              </a-tooltip>
          </span>

                    <a-input
                            type="email"
                            :maxLength="25000"
                            v-decorator="[
                            'organizationLeaderEmail',
                            {
                                rules: [
                                    {
                                      required: true,
                                      whitespace: true,
                                      type: 'email',
                                      message: 'Kérjük a megfelelő formátumban töltse ki!'
                                    },
                                    {
                                      max: characterCounts['organizationLeaderEmail'].max,
                                      message: 'Maximum ' + characterCounts['organizationLeaderEmail'].max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
                    >
                    </a-input>
                </a-form-item>

                <a-form-item>
                    <div class="gallery-form-item">
                        <a-input :hidden="true"
                                 v-decorator="[
                            'mainPicture/id',
                            {
                                rules: [{
                                    required: false,
                                    message: 'Kérjük Válassza ki a főképet.'
                                }]
                            }
                        ]"
                        />

                        <strong>Kiválasztott főkép:</strong>

                        <div class="selected-picture-holder" v-if="form.getFieldValue('mainPicture/id')">
                            <div class="picture-selected">
                                <img alt="Kiválasztott főkép"
                                     class="selected-thumbnail"
                                     :src="getImageUrl(form.getFieldValue('mainPicture/id'))"
                                />

                                <a-tooltip title="Törlés">
                                    <a-button v-on:click="unSetMainPicture">
                                        <a-icon type="delete" />

                                        Főkép kiválasztásának törlése
                                    </a-button>
                                </a-tooltip>
                            </div>
                        </div>

                        <div v-else>
                            <span>Nincs kép kiválasztva</span>
                        </div>
                    </div>
                </a-form-item>

                <a-form-item>
                    <a-button type="primary" html-type="submit" :loading="submitLoading">
                        Mentés
                    </a-button>

                    <!--          <a-button type="secondary" html-type="button" v-on:click="resetForm" v-if="hasChanged()">-->
                    <!--            <a-icon-->
                    <!--                type="undo"-->
                    <!--                theme="outlined"-->
                    <!--            />-->

                    <!--            Aktuális módosítások törlése-->
                    <!--          </a-button>-->

                    <a-alert
                            :style="{marginTop: '10px'}"
                            v-if="submitResult.show"
                            :message="submitResult.message"
                            :type="submitResult.type"
                            showIcon
                            closable
                            :afterClose="() => submitResult.show = false"
                    />
                </a-form-item>

                <a-form-item class="gallery-upload" v-if="office && office.gallery">
                    <h2>
                        Galéria
                    </h2>

                    <p>
                        A galériában a változtatások automatikusan mentésre kerülnek az űrlaptól függetlenül.
                    </p>

                    <OfficeGalleryComponent
                            v-on:pictureSelected="setMainPicture"
                            v-on:pictureDeleted="pictureDeleted"
                            v-on:pictureUploaded="pictureUploaded"
                            v-on:pictureMoved="pictureUploaded"
                            :officeId="getOfficeId()"
                            :selectedImageId="form.getFieldValue('mainPicture/id')"
                            :images="office.gallery.images"
                    />
                </a-form-item>

                <div class="teka-container">
                    <h2>
                        TÉKA
                    </h2>

                    <iframe :src="tekaUrl"></iframe>
                </div>
            </a-form>
        </a-spin>
    </div>
</template>

<script>
import OfficeGalleryComponent from "./OfficeGalleryComponent.vue";
import { EditFormComponentMixin } from "@/mixins/EditFormComponentMixin";
import axios from "axios";
import { SET_LANGUAGES, SET_OFFICE } from "@/mutation_types";
import { mapState } from "vuex";
import { config } from "@/config";

export default {
    mixins: [EditFormComponentMixin],

    components: {
        OfficeGalleryComponent
    },

    data() {
        return {
            office: {},

            coordinateTooltipLatitude: 'Kérjük a koordinátát az alábbi formátumban adja meg: 47.507346 Kérjük annak a helynek a GPS koordinátáit beírni, ahol a vonzerő pontosan található VAGY annak a helynek a koordinátáit, ahol a vonzerőhöz legközelebb biztonságosan lehet parkolni (pl. egy tó esetében) /Segítségül: a google.maps honlapon: 1. jobb egér gombbal kattintás a kívánt helyre, 2. "mi található itt?" lehetőség kiválasztása a lenyíló menüből, 3. az oldal alján megjelenik a kártya a koordinátákkal (alsó sorban) - ezt az adatsor kérjük ide beírni.',
            coordinateTooltipLongitude: 'Kérjük a koordinátát az alábbi formátumban adja meg: 19.046610! Kérjük annak a helynek a GPS koordinátáit beírni, ahol a vonzerő pontosan található VAGY annak a helynek a koordinátáit, ahol a vonzerőhöz legközelebb biztonságosan lehet parkolni (pl. egy tó esetében) /Segítségül: a google.maps honlapon: 1. jobb egér gombbal kattintás a kívánt helyre, 2. "mi található itt?" lehetőség kiválasztása a lenyíló menüből, 3. az oldal alján megjelenik a kártya a koordinátákkal (alsó sorban) - ezt az adatsor kérjük ide beírni.',

            formId: 'OFFICE',

            formFields: {
                'name': '',
                'openingYear': null,
                'description': '',
                'officePlace/address/zipCode/id': null,
                'officePlace/address/city/id': null,
                'officePlace/address/address': '',
                'officePlace/address/addressExtra': '',
                'officePlace/address/addressNumber': '',
                'officePlace/mailingAddress': '',
                'officePlace/address/lat': null,
                'officePlace/address/long': null,
                'phone': '',
                'mobilePhone': '',
                'email': '',
                'website': '',
                'websiteExtra': '',
                'websiteLanguages/id': [],
                'facebook': '',
                'spokenLanguages/id': [],
                'seasonal': 0,
                'seasonStartDate': '',
                'seasonEndDate': '',
                'mondayOpening/startTime': null,
                'mondayOpening/endTime': null,
                'mondayOpening/closed': 0,
                'mondayOpening/day': 'monday',
                'tuesdayOpening/startTime': null,
                'tuesdayOpening/endTime': null,
                'tuesdayOpening/closed': 0,
                'tuesdayOpening/day': 'tuesday',
                'wednesdayOpening/startTime': null,
                'wednesdayOpening/endTime': null,
                'wednesdayOpening/closed': 0,
                'wednesdayOpening/day': 'wednesday',
                'thursdayOpening/startTime': null,
                'thursdayOpening/endTime': null,
                'thursdayOpening/closed': 0,
                'thursdayOpening/day': 'thursday',
                'fridayOpening/startTime': null,
                'fridayOpening/endTime': null,
                'fridayOpening/closed': 0,
                'fridayOpening/day': 'friday',
                'saturdayOpening/startTime': null,
                'saturdayOpening/endTime': null,
                'saturdayOpening/closed': 0,
                'saturdayOpening/day': 'saturday',
                'sundayOpening/startTime': null,
                'sundayOpening/endTime': null,
                'sundayOpening/closed': 0,
                'sundayOpening/day': 'sunday',
                'monthlyOpening': '',
                'specialOpening': '',
                'officePlace/monument': 0,
                'officePlace/size': null,
                'officePlace/lastRenovation': null,
                'officePlace/physicallyAccessible': 0,
                'officePlace/deafAccessible': 0,
                'officePlace/blindAccessible': 0,
                'officePlace/areaAccessible': 0,
                'officePlace/type': 'office',
                'hasInfopoint': 0,
                'infopointPlace/address/zipCode/id': null,
                'infopointPlace/address/city/id': null,
                'infopointPlace/address/address': '',
                'infopointPlace/address/addressExtra': '',
                'infopointPlace/address/addressNotRelevant': 0,
                'infopointPlace/address/addressType': '',
                'infopointPlace/address/addressTypeNotRelevant': 0,
                'infopointPlace/address/addressNumber': '',
                'infopointPlace/address/addressNumberNotRelevant': 0,
                'infopointPlace/address/lat': null,
                'infopointPlace/address/long': null,
                'infopointPlace/mailingAddress': '',
                'infopointPlace/monument': 0,
                'infopointPlace/size': null,
                'infopointPlace/lastRenovation': null,
                'infopointPlace/physicallyAccessible': 0,
                'infopointPlace/deafAccessible': 0,
                'infopointPlace/blindAccessible': 0,
                'infopointPlace/areaAccessible': 0,
                'infopointPlace/type': 'infopoint',
                'infopointPlace/seasonal': 0,
                'infopointPlace/seasonStartDate': '',
                'infopointPlace/seasonEndDate': '',
                'infopointPlace/mondayOpening/startTime': null,
                'infopointPlace/mondayOpening/endTime': null,
                'infopointPlace/mondayOpening/closed': 0,
                'infopointPlace/mondayOpening/day': 'monday',
                'infopointPlace/tuesdayOpening/startTime': null,
                'infopointPlace/tuesdayOpening/endTime': null,
                'infopointPlace/tuesdayOpening/closed': 0,
                'infopointPlace/tuesdayOpening/day': 'tuesday',
                'infopointPlace/wednesdayOpening/startTime': null,
                'infopointPlace/wednesdayOpening/endTime': null,
                'infopointPlace/wednesdayOpening/closed': 0,
                'infopointPlace/wednesdayOpening/day': 'wednesday',
                'infopointPlace/thursdayOpening/startTime': null,
                'infopointPlace/thursdayOpening/endTime': null,
                'infopointPlace/thursdayOpening/closed': 0,
                'infopointPlace/thursdayOpening/day': 'thursday',
                'infopointPlace/fridayOpening/startTime': null,
                'infopointPlace/fridayOpening/endTime': null,
                'infopointPlace/fridayOpening/closed': 0,
                'infopointPlace/fridayOpening/day': 'friday',
                'infopointPlace/saturdayOpening/startTime': null,
                'infopointPlace/saturdayOpening/endTime': null,
                'infopointPlace/saturdayOpening/closed': 0,
                'infopointPlace/saturdayOpening/day': 'saturday',
                'infopointPlace/sundayOpening/startTime': null,
                'infopointPlace/sundayOpening/endTime': null,
                'infopointPlace/sundayOpening/closed': 0,
                'infopointPlace/sundayOpening/day': 'sunday',
                'infopointPlace/monthlyOpening': '',
                'infopointPlace/specialOpening': '',
                'infopointPlace/spokenLanguages/id': [],
                'infopointPlace/creditCardsAccepted': 0,
                'infopointPlace/creditCards/id': [],
                'infopointPlace/vouchersAccepted': 0,
                'infopointPlace/vouchers/id': [],
                // 'infopointPlace/foreignCurrencyAccepted': 0,
                // 'infopointPlace/foreignCurrencies/id': [],
                'infopointPlace/services/id': [],
                'creditCardsAccepted': 0,
                'creditCards/id': [],
                'vouchersAccepted': 0,
                'vouchers/id': [],
                'foreignCurrencyAccepted': 0,
                'foreignCurrencies/id': [],
                'services/id': [],
                'otherServices': '',
                'otherServicesExtra': '',
                'leader/name': '',
                'leader/startYear': '',
                'leader/type': 'leader',
                'organizationName': '',
                'organizationAddress': '',
                'organizationTaxId': '',
                'organizationCompanyId': '',
                'organizationMailingAddress': '',
                'organizationLeaderTitle': '',
                'organizationLeaderName': '',
                'organizationLeaderPhone': '',
                'organizationLeaderEmail': '',
                'mainPicture/id': null
            },

            options: {
                'officePlace/address/zipCode/id': [],
                'officePlace/address/city/id': [],
                'infopointPlace/address/zipCode/id': [],
                'infopointPlace/address/city/id': [],
                'websiteLanguages/id': [],
                'creditCards/id': [],
                'spokenLanguages/id': [],
                'vouchers/id': [],
                'foreignCurrencies/id': [],
                'services/id': [],
                'infopointPlace/creditCards/id': [],
                'infopointPlace/spokenLanguages/id': [],
                'infopointPlace/vouchers/id': [],
                // 'infopointPlace/foreignCurrencies/id': [],
                'infopointPlace/services/id': []
            },

            ignoredFields: [
                'officePlace/address/city/name',
            ],

            dateFields: {
                'seasonStartDate': 'YYYY.MM.DD',
                'seasonEndDate': 'YYYY.MM.DD',
                'mondayOpening/startTime': 'HH:mm',
                'mondayOpening/endTime': 'HH:mm',
                'tuesdayOpening/startTime': 'HH:mm',
                'tuesdayOpening/endTime': 'HH:mm',
                'wednesdayOpening/startTime': 'HH:mm',
                'wednesdayOpening/endTime': 'HH:mm',
                'thursdayOpening/startTime': 'HH:mm',
                'thursdayOpening/endTime': 'HH:mm',
                'fridayOpening/startTime': 'HH:mm',
                'fridayOpening/endTime': 'HH:mm',
                'saturdayOpening/startTime': 'HH:mm',
                'saturdayOpening/endTime': 'HH:mm',
                'sundayOpening/startTime': 'HH:mm',
                'sundayOpening/endTime': 'HH:mm',
                'infopointPlace/seasonStartDate': 'YYYY.MM.DD',
                'infopointPlace/seasonEndDate': 'YYYY.MM.DD',
                'infopointPlace/mondayOpening/startTime': 'HH:mm',
                'infopointPlace/mondayOpening/endTime': 'HH:mm',
                'infopointPlace/tuesdayOpening/startTime': 'HH:mm',
                'infopointPlace/tuesdayOpening/endTime': 'HH:mm',
                'infopointPlace/wednesdayOpening/startTime': 'HH:mm',
                'infopointPlace/wednesdayOpening/endTime': 'HH:mm',
                'infopointPlace/thursdayOpening/startTime': 'HH:mm',
                'infopointPlace/thursdayOpening/endTime': 'HH:mm',
                'infopointPlace/fridayOpening/startTime': 'HH:mm',
                'infopointPlace/fridayOpening/endTime': 'HH:mm',
                'infopointPlace/saturdayOpening/startTime': 'HH:mm',
                'infopointPlace/saturdayOpening/endTime': 'HH:mm',
                'infopointPlace/sundayOpening/startTime': 'HH:mm',
                'infopointPlace/sundayOpening/endTime': 'HH:mm',
            },

            casts: {
                'seasonal': 'int',
                'mondayOpening/closed': 'int',
                'tuesdayOpening/closed': 'int',
                'wednesdayOpening/closed': 'int',
                'thursdayOpening/closed': 'int',
                'fridayOpening/closed': 'int',
                'saturdayOpening/closed': 'int',
                'sundayOpening/closed': 'int',
                'officePlace/monument': 'int',
                'officePlace/address/lat': '?float',
                'officePlace/address/long': '?float',
                'officePlace/physicallyAccessible': 'int',
                'officePlace/deafAccessible': 'int',
                'officePlace/blindAccessible': 'int',
                'officePlace/areaAccessible': 'int',
                'hasInfopoint': 'int',
                'infopointPlace/monument': 'int',
                'infopointPlace/address/lat': '?float',
                'infopointPlace/address/long': '?float',
                'infopointPlace/address/addressNotRelevant': 'int',
                'infopointPlace/address/addressTypeNotRelevant': 'int',
                'infopointPlace/address/addressNumberNotRelevant': 'int',
                'infopointPlace/physicallyAccessible': 'int',
                'infopointPlace/deafAccessible': 'int',
                'infopointPlace/blindAccessible': 'int',
                'infopointPlace/areaAccessible': 'int',
                'infopointPlace/seasonal': 'int',
                'infopointPlace/mondayOpening/closed': 'int',
                'infopointPlace/tuesdayOpening/closed': 'int',
                'infopointPlace/wednesdayOpening/closed': 'int',
                'infopointPlace/thursdayOpening/closed': 'int',
                'infopointPlace/fridayOpening/closed': 'int',
                'infopointPlace/saturdayOpening/closed': 'int',
                'infopointPlace/sundayOpening/closed': 'int',
                'creditCardsAccepted': 'int',
                'vouchersAccepted': 'int',
                'foreignCurrencyAccepted': 'int',
                'leader/startYear': '?int',
            },

            characterCounts: {
                'name': {
                    max: 200
                },
                'email': {
                    max: 200
                },
                'website': {
                    max: 200
                },
                'websiteExtra': {
                    max: 200
                },
                'facebook': {
                    max: 200
                },
                'description': {
                    valid: null,
                    current: 0,
                    max: 500,
                },
                'specialOpening': {
                    max: 255
                },
                'monthlyOpening': {
                    max: 255
                },
                'officePlace/address/address': {
                    max: 200
                },
                'officePlace/address/addressExtra': {
                    max: 200
                },
                'officePlace/address/addressNumber': {
                    max: 50
                },
                'officePlace/mailingAddress': {
                    max: 500
                },
                'infopointPlace/address/address': {
                    max: 200
                },
                'infopointPlace/address/addressExtra': {
                    max: 200
                },
                'infopointPlace/address/addressNumber': {
                    max: 50
                },
                'infopointPlace/mailingAddress': {
                    max: 500
                },
                'infopointPlace/specialOpening': {
                    max: 255
                },
                'infopointPlace/monthlyOpening': {
                    max: 255
                },
                'organizationName': {
                    max: 200
                },
                'organizationAddress': {
                    max: 200
                },
                'organizationCompanyId': {
                    max: 255
                },
                'organizationMailingAddress': {
                    max: 500
                },
                'organizationLeaderName': {
                    max: 200
                },
                'organizationLeaderEmail': {
                    max: 200
                },
                'organizationLeaderTitle': {
                    max: 200
                },
                'otherServices': {
                    max: 255
                },
                'otherServicesExtra': {
                    max: 255
                }
            },

            employees: [],

            days: [
                {
                    name: 'Hétfő',
                    fieldPrefix: 'mondayOpening',
                    weekdayStart: true
                },
                {
                    name: 'Kedd',
                    fieldPrefix: 'tuesdayOpening'
                },
                {
                    name: 'Szerda',
                    fieldPrefix: 'wednesdayOpening'
                },
                {
                    name: 'Csütörtök',
                    fieldPrefix: 'thursdayOpening'
                },
                {
                    name: 'Péntek',
                    fieldPrefix: 'fridayOpening'
                },
                {
                    name: 'Szombat',
                    fieldPrefix: 'saturdayOpening',
                    weekendStart: true
                },
                {
                    name: 'Vasárnap',
                    fieldPrefix: 'sundayOpening'
                }
            ]
        }
    },

    computed: {
        ...mapState({
            user: state => state.user
        }),

        languages() {
            return this.$store.getters.getLanguages;
        },

        apiKey() {
            return this.$store.getters.getTekaApiKey(true);
        },

        tekaUrl() {
            let baseUrl = 'https://teka.csodasmagyarorszag.hu/embeddedpages/Content/Index';

            if(config.development) {
                baseUrl = 'https://teka-teszt.csodasmagyarorszag.hu/embeddedpages/Content/Index';
            }

            let entityType = 'http%3A%2F%2Fmtu.gov.hu%2Fapi%2Frdf%2Fapi%2Fmtu%2Fneta%23Tourinform_office';

            return baseUrl
                + '?apiKey=' + this.apiKey
                + '&externalEntityId=' + this.office.id
                + '&externalEntityType=' + entityType
                + '&externalEntityName=' + this.office.name
                + '&externalSystem=NETA'
                + '&externalUser=' + this.user.userName
                + '&preferredContentType=ImageGallery';
        }
    },

    methods: {
        /**
         * @return {*}
         */
        fetchData() {
            return new Promise((resolve) => {
                axios.get(
                    this.getApiUrl(
                        this.getOfficeId()
                    ) + '&envelope=true&languages=1'
                ).then((response) => {
                    this.$store.commit({
                        type: SET_LANGUAGES,
                        languages: response.data.meta.languages
                    });

                    this.office = response.data.content;

                    resolve(this.office);
                }).catch(() => {
                    this.$message.error(
                        'Az iroda adatainak betöltése sikertelen!'
                    );
                });
            });
        },

        /**
         * @return {int}
         */
        getOfficeId() {
            let userOffice = this.$store.state.user.office;

            if (!userOffice.id) {
                return null;
            }

            return userOffice.id;
        },

        /**
         * Handle save success
         */
        submitSuccess() {
            this.changes = 1;
            this.changedLocales = {};
            this.resetDraft();

            this.setSubmitLoading(false);

            window.scrollTo({ top: 0, behavior: 'smooth' });

            this.$message.success({ content: 'Az iroda mentése sikeres!', key: 'ATTRACTION_SUBMIT' });
        },

        submitError() {
            this.setSubmitLoading(false);

            this.$message.error({ content: 'Az iroda mentése sikertelen!', key: 'ATTRACTION_SUBMIT' });
        },

        /**
         * Draft model
         *
         * @return {string}
         */
        getModel() {
            return 'office';
        },

        /**
         * Draft model id
         *
         * @return {string}
         */
        getModelId() {
            return this.office ? this.office.id : null;
        },

        /**
         * Save office
         *
         * @param {object} data
         * @param {string} locale
         */
        save(data, locale) {
            let officeId = this.getOfficeId();

            this.$store.commit({
                type: SET_OFFICE,
                office: {
                    id: officeId,
                    name: data.name,
                    phone: data.phone
                }
            });

            return new Promise((resolve, reject) => {
                let promises = [];
                let employeesApiUrl = '/api/v1/companies/1/me/attractions/offices/' + officeId + '/employees?locale=' + locale;

                promises.push(
                    axios.patch(
                        this.getApiUrl(officeId, locale),
                        data
                    )
                );

                axios.delete(
                    employeesApiUrl + '&office/id=' + officeId + '&filter[type]=employee',
                    data
                ).then(() => {
                    if (this.employees.length) {
                        promises.push(
                            axios.post(
                                employeesApiUrl + '&batch=true',
                                this.employees
                            )
                        );
                    }
                }).catch(() => {
                    this.$message.error('A munkatársak szerkesztése sikertelen!');
                });

                Promise.all(promises)
                    .then((responses) => {
                            resolve(responses[0]);
                        }
                    ).catch(() => {
                        this.$message.error('Az iroda mentése sikertelen!');
                    }
                );
            });
        },

        /**
         * @param {integer} officeId
         * @param {string} locale
         */
        getApiUrl(officeId, locale) {
            if (!locale) {
                locale = this.$store.state.attraction.locale;
            }

            return '/api/v1/companies/1/me/attractions/offices/' + officeId + '?locale=' + locale;
        },

        /**
         * @return {Promise<[]>}
         */
        populateOptions() {
            let promises = [];

            // Fill languages
            this.options['websiteLanguages/id'] = this.languages;
            this.options['spokenLanguages/id'] = this.languages;
            this.options['infopointPlace/spokenLanguages/id'] = this.languages;

            if (this.office.officePlace && this.office.officePlace.address && this.office.officePlace.address.zipCode) {
                this.options['officePlace/address/zipCode/id'] = [this.office.officePlace.address.zipCode];
                this.options['officePlace/address/city/id'] = this.office.officePlace.address.zipCode.cities;
            }

            if (this.office.infopointPlace && this.office.infopointPlace.address && this.office.infopointPlace.address.zipCode) {
                this.options['infopointPlace/address/zipCode/id'] = [this.office.infopointPlace.address.zipCode];
                this.options['infopointPlace/address/city/id'] = this.office.infopointPlace.address.zipCode.cities;
            }

            if (this.office.creditCards) {
                this.options['creditCards/id'] = this.office.creditCards;
            }

            if (this.office.vouchers) {
                this.options['vouchers/id'] = this.office.vouchers;
            }

            if (this.office.foreignCurrencies) {
                this.options['foreignCurrencies/id'] = this.office.foreignCurrencies;
            }

            if (this.office.services) {
                this.options['services/id'] = this.office.services;
            }

            if (this.office.infopointPlace && this.office.infopointPlace.creditCards) {
                this.options['infopointPlace/creditCards/id'] = this.office.infopointPlace.creditCards;
            }

            if (this.office.infopointPlace && this.office.infopointPlace.vouchers) {
                this.options['infopointPlace/vouchers/id'] = this.office.infopointPlace.vouchers;
            }

            if (this.office.infopointPlace && this.office.infopointPlace.foreignCurrencies) {
                this.options['infopointPlace/foreignCurrencies/id'] = this.office.infopointPlace.foreignCurrencies;
            }

            if (this.office.infopointPlace && this.office.infopointPlace.services) {
                this.options['infopointPlace/services/id'] = this.office.infopointPlace.services;
            }

            this.employees = this.office.employees;

            if (this.employees.length) {
                this.refreshEmployeesFields();
            }

            return Promise.all(promises);
        },

        /**
         * @param currentDate
         * @return {boolean}
         */
        openingRangeEndDisabled(currentDate) {
            const startDate = this.form.getFieldValue('seasonStartDate');

            return startDate ? currentDate < startDate : false;
        },

        /**
         * @param currentDate
         * @return {boolean}
         */
        openingRangeStartDisabled(currentDate) {
            const endDate = this.form.getFieldValue('seasonEndDate');

            return endDate ? currentDate > endDate : false;
        },

        /**
         * @param currentDate
         * @return {boolean}
         */
        infopointOpeningRangeEndDisabled(currentDate) {
            const startDate = this.form.getFieldValue('infopointPlace/seasonStartDate');

            return startDate ? currentDate < startDate : false;
        },

        /**
         * @param currentDate
         * @return {boolean}
         */
        infopointOpeningRangeStartDisabled(currentDate) {
            const endDate = this.form.getFieldValue('infopointPlace/seasonEndDate');

            return endDate ? currentDate > endDate : false;
        },

        /**
         * @param {integer} index
         */
        removeEmployee(index) {
            this.employees.splice(index, 1);

            this.refreshEmployeesFields();
            this.saveDraft();
        },

        refreshEmployeesFields() {
            let populateData = {};

            this.employees.forEach((employee, index) => {
                populateData['employees/' + index + '/name'] = employee.name;
                populateData['employees/' + index + '/startYear'] = employee.startYear;
            });

            this.$nextTick(() => {
                this.form.setFieldsValue(populateData);
                this.form.validateFields(Object.keys(populateData));
            });
        },

        /**
         * @param {{}} data
         */
        setState(data) {
            this.$super(EditFormComponentMixin).setState(data);

            this.employees = data.employees;

            this.refreshEmployeesFields();
        },

        /**
         * @return {{}}
         */
        getState() {
            let state = this.$super(EditFormComponentMixin).getState();

            state.employees = this.employees;

            return state;
        },

        /**
         */
        addEmployee() {
            this.employees.push({
                'name': '',
                'startYear': null,
                'type': 'employee'
            });

            this.refreshEmployeesFields();
            this.saveDraft();
        },

        /**
         * @param {integer} index
         * @param {string} value
         */
        updateEmployeeName(index, value) {
            this.employees[index].name = value;

            this.refreshEmployeesFields();
        },

        /**
         * @param {integer} index
         * @param {string} value
         */
        updateEmployeeStartYear(index, value) {
            value = parseInt(value);

            if (isNaN(value)) {
                value = null;
            }

            this.employees[index].startYear = value;

            this.refreshEmployeesFields();
        },

        /**
         * Image view URL
         *
         * @param {int} imageId
         * @return {string}
         */
        getImageUrl(imageId) {
            return '/api/v1/companies/1/images/' + imageId + '/view';
        },

        /**
         * Set main picture
         *
         * @param {int} imageId
         */
        setMainPicture(imageId) {
            this.form.setFieldsValue({
                'mainPicture/id': imageId
            });

            this.$message.success('A főkép kiválasztása sikeres!');
        },

        /**
         * Check if the deleted picture was the main picture
         *
         * @param {int} imageId
         */
        pictureDeleted(imageId) {
            if (this.form.getFieldValue('mainPicture/id') == imageId) {
                this.unSetMainPicture();
            }

            this.office.gallery.images = this.office.gallery.images.filter((image) => {
                return image.id != imageId
            });
        },

        /**
         * Refresh images
         *
         * @param {int} imageId
         */
        pictureUploaded(imageId) {
            let currentMainPictureId = this.form.getFieldValue('mainPicture/id');

            this.fetchData().then(() => {
                this.$nextTick(() => {
                    this.form.setFieldsValue({
                        'mainPicture/id': currentMainPictureId
                    });
                });
            });
        },

        /**
         * Un-set main picture
         */
        unSetMainPicture() {
            this.form.setFieldsValue({
                'mainPicture/id': null
            });

            this.$message.success('A főkép kiválasztása törölve!');
        },
    },

    watch: {
        selectedRole(newRole, oldRole) {
            switch (newRole) {
                case 'ROLE_OFFICE_SPECIALIST':
                case 'ROLE_OFFICE_ADMIN':
                    this.$router.push({ name: 'neta-list' });

                    break;
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.dynamic-delete-button {
  margin-left: 10px;
}

.gallery-form-item {
  margin-bottom: 24px;
}

.selected-picture-holder {
  img.selected-thumbnail {
    display: block;
    max-width: 300px;
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

.teka-container {
  padding-bottom: 30px;

  iframe {
    width: 100%;
    height: calc(100vh - 200px);
    border: 0;
  }
}
</style>