<template>
  <div>
    <a-row
        class="forgotten-password-content-row"
    >
      <a-col :xs="1" :sm="4" :md="7" />
      <a-col :xs="22" :sm="16" :md="10">
        <div class="forgotten-password-card-container">
          <a-form
              v-show="!success"
              id="forgotten-password-component-form"
              :form="form"
              class="forgotten-password-form"
              v-on:submit="submit"
          >
            <h1>
              Jelszó változtatás igénylése
            </h1>

            <a-form-item>
              <a-input
                  type="email"
                  v-decorator="[
                    'email',
                    {
                        rules: [{
                            type: 'email',
                            required: true,
                            message: 'Kérjük írja be az e-mail címét!'
                        }]
                    }
                ]"
                  placeholder="E-mail cím"
              >
                <a-icon
                    slot="prefix"
                    type="mail"
                    style="color: rgba(0, 0, 0, 0.5)"
                />
              </a-input>
            </a-form-item>

            <a-form-item>
              <a-button
                  :loading="loading"
                  type="primary"
                  html-type="submit"
                  class="forgotten-password-form-button"
              >
                Jelszó változtatás kérése
              </a-button>
            </a-form-item>
          </a-form>

          <div class="success-page" v-show="success">
            <h1>
              A jelszó változtatási kérelmet megkaptuk!
            </h1>

            <p>
              Kérjük ellenőrizze az e-mail fiókját!<br><br>

              <span class="info-span">
                Információ és technikai segítség:<br>

                <a href="mailto:neta@mtu.gov.hu">neta@mtu.gov.hu</a>
              </span>
            </p>
          </div>

          <div class="back-button-holder">
            <router-link class="back-button" to="/login">
              <a>
                Vissza a bejelentkezéshez
              </a>
            </router-link>
          </div>
        </div>
      </a-col>
      <a-col :xs="1" :sm="4" :md="7" />
    </a-row>
  </div>
</template>

<script>
import LoginCardComponent from '@/components/login/LoginCardComponent.vue';
import axios from "axios";

export default {
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },

  data() {
    return {
      loading: false,
      success: false
    }
  },

  methods: {
    submit(event) {
      event.preventDefault();

      this.form.validateFieldsAndScroll((error, values) => {
        if (!error) {
          this.loading = true;

          axios.post('/api/v1/companies/1/auth/password-reset?locale=hu', {
            email: values.email,
          })
              .then((response) => {
                this.loading = false;

                if (!response.data.isValid) {
                  this.$message.warning(response.data.messages.email);

                  return false;
                }

                this.success = true;
              })
              .catch((error) => {
                this.$message.error('Szerver hiba!');

                this.loading = false;
              })
        }
      })
    }
  },

  components: {
    LoginCardComponent
  },
}
</script>

<style lang="scss" scoped>
.forgotten-password-form {
  text-align: left;
}

.forgotten-password-content-row {
  margin-top: 10vh;
}

.forgotten-password-card-container {
  background-color: white;
  max-width: 600px;
  text-align: center;
  margin: auto;
  padding-top: 48px;
  padding-bottom: 38px;
  padding-right: 30px;
  padding-left: 30px;
}

.back-button-holder {
  margin-top: 35px;
  text-align: left;
}

.forgotten-password-form-button {
  width: 100%
}
</style>
