<template>
  <a-row class="welcome-header">
    <a-col :xs="0" :sm="0" :md="8">
      <div class="header-left">
        <img src="@/assets/images/magyarorszag_logo_color_white_text.png" />
      </div>
    </a-col>
    <a-col :xs="18" :sm="18" :md="8" class="middle-col">
      <HeaderTitleComponent />
    </a-col>
    <a-col :xs="6" :sm="6" :md="8">
      <div class="header-right" style="display: none;">
        <a-icon
            type="info-circle"
            slot="prefix"
        ></a-icon>
        Info
      </div>
    </a-col>
  </a-row>
</template>

<script>
import HeaderTitleComponent from '@/components/shared/header/HeaderTitleComponent.vue';

export default {
  components: {
    HeaderTitleComponent
  }
}
</script>

<style lang="scss" scoped>
.welcome-header {
  .header-left {
    img {
      max-width: 220px;
      height: auto;
    }

    // background-image: url('~@/assets/images/magyarorszag_logo_color_white_text.png');
    // background-position: center;
    // background-repeat: no-repeat;
    // background-attachment: fixed;
    // background-size: contain;
  }


  .header-right {
    float: right;
    font-size: 16px;
    color: white;
    text-transform: uppercase;
  }
}
</style>
