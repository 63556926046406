import { render, staticRenderFns } from "./NetaListEventComponent.vue?vue&type=template&id=20b01878&scoped=true&"
import script from "./NetaListEventComponent.vue?vue&type=script&lang=js&"
export * from "./NetaListEventComponent.vue?vue&type=script&lang=js&"
import style0 from "./NetaListEventComponent.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./NetaListEventComponent.vue?vue&type=style&index=1&id=20b01878&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20b01878",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/neta-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('20b01878')) {
      api.createRecord('20b01878', component.options)
    } else {
      api.reload('20b01878', component.options)
    }
    module.hot.accept("./NetaListEventComponent.vue?vue&type=template&id=20b01878&scoped=true&", function () {
      api.rerender('20b01878', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/neta/neta-list/NetaListEventComponent.vue"
export default component.exports