<template xmlns="">
    <div class="app-form-container">
        <a-spin :spinning="formDataLoading">
            <a-form :form="form" v-on:submit="submit" layout="vertical">
                <a-form-item>
                    <a-button type="primary" html-type="submit" :loading="submitLoading" v-if="showSaveButton">
                        Mentés
                    </a-button>

                    <a-button type="secondary" html-type="button" v-on:click="resetForm" v-if="hasChanged()">
                        <a-icon type="undo"
                                theme="outlined" />

                        Piszkozat törlése
                    </a-button>

                    <a-alert
                            :style="{
                        marginTop: '10px'
                    }"
                            v-if="submitResult.show"
                            :message="submitResult.message"
                            :type="submitResult.type"
                            showIcon
                            closable
                            :afterClose="() => submitResult.show = false"
                    />
                </a-form-item>

                <a-divider orientation="left">
                    HUNGARIKUM ÁLLAPOTA
                </a-divider>

                <a-form-item label="Státusz">
                    <a-radio-group
                            v-decorator="[
                        'status'
                    ]"
                    >
                        <a-radio-button :value="1">{{ statusNames[1]}}</a-radio-button>
                        <a-radio-button :value="0">{{ statusNames[0]}}</a-radio-button>
                    </a-radio-group>
                </a-form-item>

                <a-divider orientation="left">
                    ALAPADATOK
                </a-divider>

                <a-form-item label="Hungarikum neve" :selfUpdate="true">
                    <a-textarea
                        :maxLength="25000"
                        v-decorator="[
                            'label',
                            {
                                rules: [
                                    {
                                      required: true,
                                      preserve: true,
                                      message: 'Kérjük töltse ki!'
                                    },
                                    {
                                      max: characterCounts.label.max,
                                      message: 'Maximum ' + characterCounts.label.max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur', 'keyup']
                            }
                        ]"
                        :autoSize="{minRows: 2}"
                        placeholder="Hungarikum neve"
                    />
                </a-form-item>

                <a-form-item label="Hungarikum leírása" :selfUpdate="true">
                    <a-textarea
                        :maxLength="25000"
                        v-decorator="[
                            'description',
                            {
                                rules: [
                                    {
                                      required: true,
                                      preserve: true,
                                      message: 'Kérjük töltse ki!'
                                    },
                                    {
                                      max: characterCounts.description.max,
                                      message: 'Maximum ' + characterCounts.description.max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur', 'keyup']
                            }
                        ]"
                        :autoSize="{minRows: 6}"
                        placeholder="Hungarikum leírása"
                    />
                </a-form-item>

                <a-form-item label="Kategória">
                    <a-select
                            v-decorator="[
                        'category',
                        {
                            rules: [{
                                required: true,
                                message: 'Kérjük töltse ki!'
                            }],
                            validateTrigger: 'blur'
                        }
                    ]"
                            placeholder="Kérjük válasszon!"
                    >
                        <a-select-option value="Agrár és élelmiszergazdaság">
                            Agrár és élelmiszergazdaság
                        </a-select-option>

                        <a-select-option value="Egészség és életmód">
                            Egészség és életmód
                        </a-select-option>

                        <a-select-option value="Épített környezet">
                            Épített környezet
                        </a-select-option>

                        <a-select-option value="Ipari és műszaki megoldások">
                            Ipari és műszaki megoldások
                        </a-select-option>

                        <a-select-option value="Kulturális örökség">
                            Kulturális örökség
                        </a-select-option>

                        <a-select-option value="Sport">
                            Sport
                        </a-select-option>

                        <a-select-option value="Természeti környezet">
                            Természeti környezet
                        </a-select-option>

                        <a-select-option value="Turizmus és vendéglátás">
                            Turizmus és vendéglátás
                        </a-select-option>
                    </a-select>
                </a-form-item>

                <a-divider orientation="left">
                    ÉRTÉKESÍTÉS
                </a-divider>

                <a-form-item label="Értékesítési hely/hivatalos viszonteladó neve" :selfUpdate="true">
                    <a-input
                            :maxLength="25000"
                            v-decorator="[
                            'posLabel',
                            {
                                rules: [
                                    {
                                      required: false,
                                      preserve: true,
                                      message: 'Kérjük töltse ki!'
                                    },
                                    {
                                      max: characterCounts.posLabel.max,
                                      message: 'Maximum ' + characterCounts.posLabel.max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur', 'keyup']
                            }
                        ]"
                            :autoSize="{minRows: 2}"
                            placeholder="Név"
                    />
                </a-form-item>

                <a-form-item :selfUpdate="true">
                  <span slot="label">
                      Értékesítési hely/hivatalos viszonteladó e-mail címe

                      <a-tooltip title="Kérjük az email címet cimzett@domain.xy formátumban töltse ki!">
                          <a-icon type="question-circle-o" />
                      </a-tooltip>
                  </span>

                    <a-input
                            :maxLength="25000"
                            v-decorator="[
                            'posEmail',
                            {
                                rules: [
                                    {
                                      required: false,
                                      whitespace: true,
                                      type: 'email',
                                      message: 'Kérjük a megfelelő formátumban töltse ki!'
                                    },
                                    {
                                      max: characterCounts.posEmail.max,
                                      message: 'Maximum ' + characterCounts.posEmail.max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur', 'keyup']
                            }
                        ]"
                            :autoSize="{minRows: 2}"
                            placeholder="E-mail cím"
                    />
                </a-form-item>

                <a-form-item :selfUpdate="true">
                  <span slot="label">
                      Értékesítési hely/hivatalos viszonteladó weboldala

                      <a-tooltip title="Kérjük a weboldal címét http(s)://www.weboldal.xy formátumban töltse ki!">
                          <a-icon type="question-circle-o" />
                      </a-tooltip>
                  </span>

                    <a-input
                            :maxLength="25000"
                            v-on:change="(event) => transformEncodedUrl(event.target.value, 'posWebsite')"
                            v-decorator="[
                            'posWebsite',
                            {
                                rules: [
                                  {
                                    required: false,
                                    message: 'Kérjük a megfelelő formátumban töltse ki!',
                                    pattern: /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
                                  },
                                    {
                                      max: characterCounts.posWebsite.max,
                                      message: 'Maximum ' + characterCounts.posWebsite.max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur', 'keyup']
                            }
                        ]"
                            :autoSize="{minRows: 2}"
                            placeholder="Weboldal"
                    />
                </a-form-item>


                <a-divider orientation="left">
                    ELHELYEZKEDÉS
                </a-divider>

                <a-form-item label="Értékelhető">
                    <a-radio-group
                        v-decorator="[
                        'rateable'
                    ]"
                    >
                        <a-radio-button :value="1">Igen</a-radio-button>
                        <a-radio-button :value="0">Nem</a-radio-button>
                    </a-radio-group>
                </a-form-item>

                <a-form-item label="Cimkék">
                    <a-select
                            v-decorator="[
                            'labels/id',
                            {
                                rules: [
                                    {
                                    required: isChecked('rateable'),
                                    message: 'Kérjük töltse ki!',
                                  },
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
                            placeholder="Kezdjen gépelni"
                            showSearch
                            mode="multiple"
                            :showArrow="false"
                            :defaultActiveFirstOption="false"
                            :getPopupContainer="getParentTriggerNode()"
                            :filterOption="false"
                            :style="{width: '100%'}"
                            v-on:search="(value) => labelCitiesAutoComplete(value,'labels/id')"
                            v-on:dropdownVisibleChange="(open) => open && labelCitiesAutoComplete(null, 'labels/id')"
                            :notFoundContent="loading['labels/id'] ? undefined : null"
                    >
                        <a-spin v-if="loading['labels/id']" slot="notFoundContent" size="small" />

                        <a-select-option
                                v-for="option in options['labels/id']"
                                :key="option.id"
                                :value="option.id"
                        >
                            {{ option.name }}
                        </a-select-option>
                    </a-select>
                </a-form-item>


                <a-form-item label="POI elhelyezése" :selfUpdate="true">
                    <a-input
                            :maxLength="25000"
                            v-decorator="[
                            'poiPlace',
                            {
                                rules: [
                                    {
                                      required: isChecked('rateable'),
                                      preserve: true,
                                      message: 'Kérjük töltse ki!'
                                    },
                                    {
                                      max: characterCounts.poiPlace.max,
                                      message: 'Maximum ' + characterCounts.poiPlace.max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur', 'keyup']
                            }
                        ]"
                            :autoSize="{minRows: 2}"
                            placeholder="Elhelyezés"
                    />
                </a-form-item>

                <a-form-item label="Térkép, hely" :selfUpdate="true">
                    <a-input
                            :maxLength="25000"
                            v-decorator="[
                            'addressPlace',
                            {
                                rules: [
                                    {
                                      required: isChecked('rateable'),
                                      preserve: true,
                                      message: 'Kérjük töltse ki!'
                                    },
                                    {
                                      max: characterCounts.addressPlace.max,
                                      message: 'Maximum ' + characterCounts.addressPlace.max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur', 'keyup']
                            }
                        ]"
                            :autoSize="{minRows: 2}"
                            placeholder="Helyszín"
                    />
                </a-form-item>

                <p>
                    Az alábbi sorokban kérjük a hungarikum pontos elhelyezkedésére vonatkozó adatok opcionális
                    megadását.
                </p>

                <a-form-item label="Irányítószám">
                    <a-select
                            v-decorator="[
                            'address/zipCode/id',
                            {
                                rules: [
                                    {
                                    required: false,
                                    whitespace: true,
                                    message: 'Kérjük töltse ki!',
                                    type: 'integer'
                                  },
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
                            placeholder="Kezdjen gépelni"
                            showSearch
                            :showArrow="false"
                            :defaultActiveFirstOption="false"
                            :getPopupContainer="getParentTriggerNode()"
                            :filterOption="false"
                            :style="{width: '100%'}"
                            v-on:search="(value) => zipCodeAutoComplete(value,'address/zipCode/id', true)"
                            v-on:change="(value) => {fillCityOptions(value,'address/zipCode/id','address/city/id')}"
                            v-on:dropdownVisibleChange="(open) => open && zipCodeAutoComplete(null, 'address/zipCode/id')"
                            :notFoundContent="loading['address/zipCode/id'] ? undefined : null"
                    >
                        <a-spin v-if="loading['address/zipCode/id']" slot="notFoundContent" size="small" />

                        <a-select-option
                                v-for="option in options['address/zipCode/id']"
                                :key="option.code"
                                :value="option.id"
                        >
                            {{ option.code }}
                        </a-select-option>
                    </a-select>
                </a-form-item>

                <a-form-item label="Település neve">
                    <a-select
                            v-decorator="[
                            'address/city/id',
                            {
                                rules: [
                                    {
                                    required: false,
                                    whitespace: true,
                                    message: 'Kérjük töltse ki!',
                                    type: 'integer'
                                  },
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
                            placeholder="Töltse ki az irányítószám mezőt!"
                            :showArrow="false"
                            :disabled="!options['address/city/id'].length"
                            :defaultActiveFirstOption="true"
                            :filterOption="false"
                            :style="{width: '100%'}"
                            :notFoundContent="loading['address/zipCode/id'] ? undefined : null"
                    >
                        <a-spin v-if="loading['address/zipCode/id']" slot="notFoundContent" size="small" />

                        <a-select-option
                                v-for="option in options['address/city/id']"
                                :key="option.id"
                                :value="option.id"
                        >
                            {{ option.name }}
                        </a-select-option>
                    </a-select>
                </a-form-item>

                <a-form-item
                        :selfUpdate="true"
                        label="Közterület neve"
                >
                    <a-input
                            :maxLength="25000"
                            :disabled="isChecked('address/addressNotRelevant')"
                            v-decorator="[
                            'address/address',
                            {
                                rules: [
                                    {
                                      required: false && !isChecked('address/addressNotRelevant'),
                                      whitespace: true,
                                      message: 'Kérjük töltse ki!'
                                    },
                                    {
                                      max: characterCounts['address/address'].max,
                                      message: 'Maximum ' + characterCounts['address/address'].max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
                            placeholder="Kérjük beírni (betűkkel)"
                    >
                    </a-input>

                    <a-checkbox
                            v-decorator="[
                        'address/addressNotRelevant',
                        {
                            rules: [{
                                required: false
                            }]
                        }
                    ]"
                            :checked="isChecked('address/addressNotRelevant')"
                            v-on:change="() => relevancyCheckboxChange('address/address')"
                    >
                        Nem releváns
                    </a-checkbox>
                </a-form-item>

                <a-form-item
                        :selfUpdate="true"
                        label="Közterület típusa (utca, tér, út, dűlő stb.)"
                >
                    <a-input
                            :maxLength="25000"
                            :disabled="isChecked('address/addressTypeNotRelevant')"
                            v-decorator="[
                            'address/addressType',
                            {
                                rules: [
                                    {
                                      required: false && !isChecked('address/addressTypeNotRelevant'),
                                      whitespace: true,
                                      message: 'Kérjük töltse ki!'
                                    },
                                    {
                                      max: characterCounts['address/addressType'].max,
                                      message: 'Maximum ' + characterCounts['address/addressType'].max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
                            placeholder="Kérjük beírni (betűkkel)"
                    >
                    </a-input>

                    <a-checkbox
                            v-decorator="[
                        'address/addressTypeNotRelevant',
                        {
                            rules: [{
                                required: false
                            }]
                        }
                    ]"
                            :checked="isChecked('address/addressTypeNotRelevant')"
                            v-on:change="() => relevancyCheckboxChange('address/addressType')"
                    >
                        Nem releváns
                    </a-checkbox>
                </a-form-item>

                <a-form-item>
          <span slot="label">
              Házszám/helyrajzi szám
              <a-tooltip
                      title="Itt a házszámon kívül más is beírható (pl. helyrajzi szám vagy más egyéb, pontos azonosító, ami az adott ingatlan helyét pontosan meghatározza). Ha nincs ilyen pontos azonosító szám a címhez, akkor kérjük a mezőt üresen hagyni."
              >
                  <a-icon type="question-circle-o" />
              </a-tooltip>
          </span>

                    <a-input
                            :maxLength="25000"
                            :disabled="isChecked('address/addressNumberNotRelevant')"
                            v-decorator="[
                            'address/addressNumber',
                            {
                                rules: [
                                    {
                                      required: false && !isChecked('address/addressNumberNotRelevant'),
                                      whitespace: true,
                                      message: 'Kérjük töltse ki!'
                                    },
                                    {
                                      max: characterCounts['address/addressNumber'].max,
                                      message: 'Maximum ' + characterCounts['address/addressNumber'].max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
                            placeholder="Kérjük beírni (számokkal, betűkkel)"
                    />

                    <a-checkbox
                            v-decorator="[
                        'address/addressNumberNotRelevant',
                        {
                            rules: [{
                                required: false
                            }]
                        }
                    ]"
                            :checked="isChecked('address/addressNumberNotRelevant')"
                            v-on:change="() => relevancyCheckboxChange('address/addressNumber')"
                    >
                        Nem releváns
                    </a-checkbox>
                </a-form-item>


                <a-form-item :selfUpdate="true">
          <span slot="label">
              A hungarikum GPS koordinátája: szélességi fok

              <a-tooltip :title="coordinateTooltipLatitude">
                  <a-icon type="question-circle-o" />
              </a-tooltip>
          </span>

                    <a-input
                            :maxLength="25000"
                            v-on:change="(event) => formatCoordinate(event.target.value, 'address/lat')"
                            v-decorator="[
                            'address/lat',
                            {
                                rules: [
                                    {
                                      required: isChecked('rateable'),
                                      whitespace: true,
                                      pattern: /^4([-+]?)([\d]{1})(((\.)(\d+)))$/g,
                                      message: 'A koordináták formátuma helytelen!'
                                    },
                                    {
                                      type: 'float',
                                      message: 'Kérjük adjon meg valós GPS koordinátákat!',
                                      transform: (value) => {
                                        value = parseFloat(value);

                                        return isNaN(value) ? null: value;
                                      },
                                      min: 45.72,
                                      max: 48.60
                                    }
                                    ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
                            placeholder="Kérjük a koordinátát az alábbi formátumban adja meg: 47.507346"
                    >
                    </a-input>
                </a-form-item>

                <a-form-item :selfUpdate="true">
          <span slot="label">
            A hungarikum GPS koordinátája: hosszúsági fok

            <a-tooltip :title="coordinateTooltipLongitude">
                <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>
                    <a-input
                            :maxLength="25000"
                            v-on:change="(event) => formatCoordinate(event.target.value, 'address/long')"
                            v-decorator="[
                            'address/long',
                            {
                                rules: [
                                    {
                                      required: isChecked('rateable'),
                                      whitespace: true,
                                      pattern: /^(1|2)(([-+]?)([\d]{1})((\.)(\d+))?)$/g,
                                      message: 'A koordináták formátuma helytelen!'
                                    },
                                    {
                                      type: 'float',
                                      message: 'Kérjük adjon meg valós GPS koordinátákat!',
                                      transform: (value) => {
                                        value = parseFloat(value);

                                        return isNaN(value) ? null: value;
                                      },
                                      min: 16.10,
                                      max: 23
                                    }],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
                            placeholder="Kérjük a koordinátát az alábbi formátumban adja meg: 19.046610"
                    >
                    </a-input>
                </a-form-item>

                <a-form-item label="Megjegyzés" :selfUpdate="true">
                    <a-textarea
                        :maxLength="25000"
                        v-decorator="[
                            'note',
                            {
                                rules: [
                                    {
                                      required: false,
                                      preserve: true,
                                      message: 'Kérjük töltse ki!'
                                    },
                                    {
                                      max: characterCounts.note.max,
                                      message: 'Maximum ' + characterCounts.note.max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur', 'keyup']
                            }
                        ]"
                        :autoSize="{minRows: 6}"
                        placeholder="Megjegyzés"
                    />
                </a-form-item>

                <a-form-item>
                    <a-button type="primary" html-type="submit" :loading="submitLoading" v-if="showSaveButton">
                        Mentés
                    </a-button>

                    <a-button type="secondary" html-type="button" v-on:click="resetForm" v-if="hasChanged()">
                        <a-icon
                                type="undo"
                                theme="outlined"
                        />

                        Piszkozat törlése
                    </a-button>

                    <a-alert
                            :style="{
                        marginTop: '10px'
                    }"
                            v-if="submitResult.show"
                            :message="submitResult.message"
                            :type="submitResult.type"
                            showIcon
                            closable
                            :afterClose="() => submitResult.show = false"
                    />
                </a-form-item>
            </a-form>
        </a-spin>
    </div>
</template>

<script>
import { EditFormComponentMixin } from "@/mixins/EditFormComponentMixin";
import cloneDeep from 'lodash/cloneDeep';
import axios from "axios";
import store from "@/store";
import { REFRESH_HUNGARIKUM } from "@/action_types";

export default {
    menuName: 'Alapadatok',
    name: 'data-form',
    mixins: [EditFormComponentMixin],
    data() {
        return {
            coordinateTooltipLatitude: 'Kérjük a koordinátát az alábbi formátumban adja meg: 47.507346 Kérjük annak a helynek a GPS koordinátáit beírni, ahol a hungarikum pontosan található VAGY annak a helynek a koordinátáit, ahol a hungarikumhoz legközelebb biztonságosan lehet parkolni (pl. egy tó esetében) /Segítségül: a google.maps honlapon: 1. jobb egér gombbal kattintás a kívánt helyre, 2. "mi található itt?" lehetőség kiválasztása a lenyíló menüből, 3. az oldal alján megjelenik a kártya a koordinátákkal (alsó sorban) - ezt az adatsor kérjük ide beírni.',
            coordinateTooltipLongitude: 'Kérjük a koordinátát az alábbi formátumban adja meg: 19.046610! Kérjük annak a helynek a GPS koordinátáit beírni, ahol a hungarikum pontosan található VAGY annak a helynek a koordinátáit, ahol a hungarikumhoz legközelebb biztonságosan lehet parkolni (pl. egy tó esetében) /Segítségül: a google.maps honlapon: 1. jobb egér gombbal kattintás a kívánt helyre, 2. "mi található itt?" lehetőség kiválasztása a lenyíló menüből, 3. az oldal alján megjelenik a kártya a koordinátákkal (alsó sorban) - ezt az adatsor kérjük ide beírni.',

            formId: 'DATA',

            formFields: {
                'label': '',
                'labels/id': [],
                'address/zipCode/id': null,
                'address/city/id': null,
                'address/address': '',
                'address/addressNotRelevant': 0,
                'address/addressType': '',
                'address/addressTypeNotRelevant': 0,
                'address/addressNumber': '',
                'address/addressNumberNotRelevant': 0,
                'address/lat': '',
                'address/long': '',
                'category': '',
                'description': '',
                'note': '',
                'status': 1,
                'rateable': 1,
                'poiPlace': '',
                'addressPlace': '',
                'posLabel': '',
                'posWebsite': '',
                'posEmail': '',
            },

            casts: {
                'address/addressNotRelevant': 'int',
                'address/addressTypeNotRelevant': 'int',
                'address/addressNumberNotRelevant': 'int',
                'status': 'int',
                'rateable': 'int',
                'address/lat': '?float',
                'address/long': '?float',
            },

            options: {
                'labels/id': [],
                'address/zipCode/id': [],
                'address/city/id': [],
            },

            translatedFormFields: {},

            ignoredFields: [],

            characterCounts: {
                'label': {
                    max: 200
                },
                'description': {
                    max: 500
                },
                'note': {
                    max: 500
                },
                'poiPlace': {
                    max: 200
                },
                'addressPlace': {
                    max: 200
                },
                'posLabel': {
                    max: 200
                },
                'posEmail': {
                    max: 200
                },
                'posWebsite': {
                    max: 200
                },
                'address/address': {
                    max: 200
                },
                'address/addressType': {
                    max: 50
                },
                'address/addressNumber': {
                    max: 50
                },
            },

            modals: {}
        }
    },
    computed: {
        statusNames() {
            return this.$store.state.hungarikum.statusNames;
        },
    },
    methods: {
        getModel() {
            return 'hungarikum'
        },

        getModelId() {
            return this.getHungarikum().id;
        },

        /**
         * @return {*}
         */
        fetchData() {
            return new Promise((resolve) => {
                resolve(this.getHungarikum());
            });
        },

        /**
         * @returns {object}
         */
        getHungarikum() {
            return cloneDeep(this.$store.getters.getCurrentHungarikum);
        },

        /**
         * Save hungarikum (also, with locale specific fields)
         *
         * @param {object} data
         * @param {string} locale
         */
        save(data, locale) {
            return axios.patch(
                this.getApiUrl(this.getHungarikum().id, locale),
                data
            );
        },

        submitSuccess() {
            this.changes = 1;
            this.changedLocales = {};
            this.resetDraft();

            this.$message.success({ content: 'Sikeres mentés!', key: 'ATTRACTION_SUBMIT', duration: 5 });

            let hungarikum = this.getHungarikum();

            store.dispatch({
                type: REFRESH_HUNGARIKUM,
                hungarikumId: hungarikum.id,
            }).then(() => {
                this.setSubmitLoading(false);

                window.scrollTo({ top: 0, behavior: 'smooth' });
            }).catch(() => {
                this.$message.error(
                    'Az adatok frissítése sikertelen!'
                );
            });
        },

        /**
         * @param {integer} hungarikumId
         * @param {string} locale
         */
        getApiUrl(hungarikumId, locale) {
            if (!locale) {
                locale = this.$store.state.attraction.locale;
            }

            return '/api/v1/companies/1/attractions/hungarikum/' + hungarikumId + '?locale=' + locale;
        },

        /**
         * @return {Promise<[]>}
         */
        populateOptions() {
            let promises = [];

            if (this.getHungarikum().labels.length) {
                this.options['labels/id'] = this.getHungarikum().labels;
            }

            // Fill the current zip code to the options
            if (this.getHungarikum().address && this.getHungarikum().address.zipCode) {
                this.options['address/zipCode/id'] = [this.getHungarikum().address.zipCode];
                this.options['address/city/id'] = this.getHungarikum().address.zipCode.cities;
            }

            return Promise.all(promises);
        },


        /**
         * @param {string} field
         * @param {string|null} filterField
         * @param filterValue
         */
        loadOptions(field, filterField, filterValue) {
            this.loading[field] = true;

            let attraction = this.getAttraction();
            let endpoint = '/api/v1/companies/1/';
            let params = {
                locale: this.$store.state.attraction.locale,
                order: 'weight ASC',
                limit: 100
            };

            return new Promise((resolve, reject) => {
                this.$nextTick(() => {
                    let clearField = true;

                    if (filterValue) {
                        clearField = false;
                    }

                    if (filterField) {
                        filterValue = this.form.getFieldValue(filterField);
                    }

                    switch (field) {
                        case 'labels/id':
                            endpoint += 'attractions/categories/all';
                            params['parent/group/name'] = 'city';
                            params.order = 'name ASC';
                            params.limit = 200;

                            if (clearField) {
                                this.form.setFieldsValue({
                                    'labels/id': []
                                });
                            }

                            break;
                    }

                    if (filterField && !filterValue) {
                        resolve();

                        return;
                    }

                    axios.get(endpoint, {
                        params: params
                    }).then(response => {
                        if (!isEqual(this.options[field], response.data)) {
                            this.options[field] = response.data;
                        }

                        this.loading[field] = false;

                        /**
                         * Check singular options
                         */
                        let fieldValue = this.form.getFieldValue(field);

                        if (fieldValue && isArray(fieldValue)) {
                            this.checkSingular(this.form.getFieldValue(field), field);
                        }

                        this.saveDraft();

                        resolve();
                    }).catch(() => {
                        this.$message.error(
                            'Sikertelen opció betöltés!'
                        );
                    });
                });
            });
        },
    }
}
</script>

<style>
button + button {
    margin-left: 10px;
}

.dynamic-delete-button {
    margin-left: 10px;
}
</style>
