import Vue from "vue";

// Used to access parent functions of mixins in components
Vue.prototype.$super = function (options) {
    return new Proxy(options, {
        get: (options, name) => {
            if (options.methods && name in options.methods) {
                return options.methods[name].bind(this);
            }
        },
    });
};