<template xmlns="">
  <div class="app-form-container">
    <a-spin :spinning="formDataLoading">
      <a-form :form="form" v-on:submit="submit" layout="vertical">
        <a-form-item>
          <a-button type="primary" html-type="submit" :loading="submitLoading" v-if="showSaveButton && !isMobileReadonly">
            Mentés
          </a-button>

          <a-button type="secondary" html-type="button" v-on:click="resetForm" v-if="hasChanged()">
            <a-icon type="undo"
                    theme="outlined" />

            Piszkozat törlése
          </a-button>

          <span v-if="currentTab" class="last-modification">
            Utolsó mentés: <strong>{{ moment(currentTab.modificationTime).format(timeStampFormat).toString() }}</strong> &nbsp;<span v-if="currentTab.user">({{ currentTab.user.userName }})</span>
          </span>

          <a-alert
              :style="{
                        marginTop: '10px'
                    }"
              v-if="submitResult.show"
              :message="submitResult.message"
              :type="submitResult.type"
              showIcon
              closable
              :afterClose="() => submitResult.show = false"
          />
        </a-form-item>

        <a-divider orientation="left">
          A ESEMÉNYHEZ KAPCSOLÓDÓ FIZETÉSI ÉS FOGLALÁSI LEHETŐSÉGEK
        </a-divider>

        <a-form-item label="Az eseménynél van belépőjegy értékesítés?">
          <a-select
              :disabled="isMobileReadonly"
              :getPopupContainer="getParentTriggerNode()"
              :loading="loading['payment/ticketSales/id']"
              v-decorator="['payment/ticketSales/id', {
                            rules: [{
                                required: isNotMobile,
                                message: 'Kérjük töltse ki!'
                            }],
                            validateTrigger: 'blur'
                        }]"
              placeholder="Kérjük válasszon!"
              :filterOption="filterSelectNames"
              v-on:dropdownVisibleChange="(open) => open && loadOptions('payment/ticketSales/id')"
              v-on:change="() => relevancyCheckboxChange(['payment/creditCards/id','payment/vouchers/id'])"
          >
            <a-select-option
                v-for="category in options['payment/ticketSales/id']"
                :key="category.name"
                :value="category.id"
            >
              {{ category.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-divider orientation="left">
          HELYI BELÉPŐJEGY ÉRTÉKESÍTÉS
        </a-divider>

        <a-form-item label="Milyen bankkártya típusokat fogadnak el?">
          <a-select
              :disabled="isChecked('payment/noCreditCards') || isFree('payment/ticketSales/id') || isMobileReadonly"
              :getPopupContainer="getParentTriggerNode()"
              mode="multiple"
              :loading="loading['payment/creditCards/id']"
              v-decorator="['payment/creditCards/id', {
                            rules: [{
                                required: !isChecked('payment/noCreditCards') && !isFree('payment/ticketSales/id') && isNotMobile,
                                message: 'Kérjük töltse ki!'
                            }],
                            validateTrigger: 'blur'
                        }]"
              placeholder="Kérjük válasszon!"
              :filterOption="filterSelectNames"
              v-on:dropdownVisibleChange="(open) => open && loadOptions('payment/creditCards/id')"
          >
            <a-select-option
                v-for="category in options['payment/creditCards/id']"
                :key="category.label"
                :value="category.id"
            >
              {{ category.label }}
            </a-select-option>
          </a-select>

          <a-checkbox
              :disabled="isFree('payment/ticketSales/id') || isMobileReadonly"
              v-decorator="[
                        'payment/noCreditCards',
                        {
                            rules: [{
                                required: false
                            }]
                        }
                    ]"
              :checked="isChecked('payment/noCreditCards')"
              v-on:change="() => relevancyCheckboxChange('payment/creditCards/id')"
          >
            Nem fogadnak el bankkártyát
          </a-checkbox>
        </a-form-item>

        <a-form-item label="Milyen utalvány/voucher típusokat fogadnak el?">
          <a-select
              :disabled="isChecked('payment/noVouchers') || isFree('payment/ticketSales/id') || isMobileReadonly"
              :getPopupContainer="getParentTriggerNode()"
              mode="multiple"
              :loading="loading['payment/vouchers/id']"
              v-decorator="['payment/vouchers/id', {
                            rules: [{
                                required: !isChecked('payment/noVouchers') && !isFree('payment/ticketSales/id') && isNotMobile,
                                message: 'Kérjük töltse ki!'
                            }],
                            validateTrigger: 'blur'
                        }]"
              placeholder="Kérjük válasszon!"
              :filterOption="filterSelectNames"
              v-on:dropdownVisibleChange="(open) => open && loadOptions('payment/vouchers/id')"
          >
            <a-select-option
                v-for="category in options['payment/vouchers/id']"
                :key="category.label"
                :value="category.id"
            >
              {{ category.label }}
            </a-select-option>
          </a-select>

          <a-checkbox
              :disabled="isFree('payment/ticketSales/id') || isMobileReadonly"
              v-decorator="[
                        'payment/noVouchers',
                        {
                            rules: [{
                                required: false
                            }]
                        }
                    ]"
              :checked="isChecked('payment/noVouchers')"
              v-on:change="() => relevancyCheckboxChange('payment/vouchers/id')"
          >
            Nem fogadnak el utalványt/vouchert
          </a-checkbox>
        </a-form-item>

        <a-divider orientation="left">
          ONLINE BELÉPŐJEGY ÉRTÉKESÍTÉS
        </a-divider>

        <a-form-item label="Online belépőjegy/szolgáltatás vásárlás lehetséges?">
          <a-select
              :disabled="isFree('payment/ticketSales/id') || isMobileReadonly"
              v-decorator="[
                        'payment/onlineSales',
                        {
                            rules: [{
                                required: false,
                                message: 'Kérjük töltse ki!'
                            }]
                        }
                    ]"
              placeholder="Kérjük válasszon!"
          >
            <a-select-option :value="1">Igen</a-select-option>
            <a-select-option :value="0">Nem</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item v-show="form.getFieldValue('payment/onlineSales') === 1">
          <span slot="label">
              Ha IGEN, kérjük adja meg a jegyértékesítés weboldalának elérhetőségét!

              <a-tooltip title="Kérjük a weboldal címét http(s)://www.weboldal.xy formátumban töltse ki!">
                  <a-icon type="question-circle-o" />
              </a-tooltip>
          </span>

          <a-input
              :maxLength="25000"
              :disabled="isFree('payment/ticketSales/id') || isMobileReadonly"
              v-on:change="(event) => transformEncodedUrl(event.target.value, 'payment/onlineSalesWebsite')"
              v-decorator="[
                        'payment/onlineSalesWebsite',
                          {
                              rules: [
                                  {
                                    required: false,
                                    message: 'Kérjük a megfelelő formátumban töltse ki!',
                                    pattern: /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
                                  },
                                  {
                                    max: characterCounts['payment/onlineSalesWebsite'].max,
                                    message: 'Maximum ' + characterCounts['payment/onlineSalesWebsite'].max + ' karakter engedélyezett.',
                                  }
                              ],
                              validateTrigger: ['blur', 'keyup']
                          }
                    ]"
              autosize
          />
        </a-form-item>

        <a-form-item>
          <a-button type="primary" html-type="submit" :loading="submitLoading" v-if="showSaveButton && !isMobileReadonly">
            Mentés
          </a-button>

          <a-button type="secondary" html-type="button" v-on:click="resetForm" v-if="hasChanged()">
            <a-icon
                type="undo"
                theme="outlined"
            />

            Piszkozat törlése
          </a-button>

          <span v-if="currentTab" class="last-modification">
            Utolsó mentés: <strong>{{ moment(currentTab.modificationTime).format(timeStampFormat).toString() }}</strong> &nbsp;<span v-if="currentTab.user">({{ currentTab.user.userName }})</span>
          </span>

          <a-alert
              :style="{
                        marginTop: '10px'
                    }"
              v-if="submitResult.show"
              :message="submitResult.message"
              :type="submitResult.type"
              showIcon
              closable
              :afterClose="() => submitResult.show = false"
          />
        </a-form-item>
      </a-form>
    </a-spin>
  </div>
</template>

<script>
import { EditFormComponentMixin } from "@/mixins/EditFormComponentMixin";

export default {
  menuName: 'Fizetési foglalási lehetőségek',
  name: 'payment-reservation-form',
  mixins: [EditFormComponentMixin],

  data() {
    return {
      formId: 'PAYMENT_RESERVATION',

      formFields: {
        'payment/ticketSales/id': [],
        'payment/creditCards/id': [],
        'payment/noCreditCards': 0,
        'payment/vouchers/id': [],
        'payment/noVouchers': 0,
        'payment/onlineSales': 0,
        'payment/onlineSalesWebsite': ''
      },

      casts: {
        'payment/noCreditCards': 'int',
        'payment/noVouchers': 'int',
        'payment/onlineSales': 'int',
      },

      options: {
        'payment/ticketSales/id': [],
        'payment/creditCards/id': [],
        'payment/vouchers/id': [],
      },

      characterCounts: {
        'payment/onlineSalesWebsite': {
          max: 200
        },
      }
    }
  },

  methods: {
    /**
     * @param {string} fieldName
     *
     * @return {boolean}
     */
    isFree(fieldName) {
      let value = this.form.getFieldValue(fieldName);
      let option = this.getOptionByPrimaryKey(fieldName, value);

      if (!option) {
        return false;
      }

      return !!option.free;
    },

    /**
     * @return {Promise<[]>}
     */
    populateOptions() {
      let promises = [];

      if (this.getAttraction().payment) {
        // Fill options from the current attraction data
        if (this.getAttraction().payment.ticketSales) {
          this.options['payment/ticketSales/id'] = [this.getAttraction().payment.ticketSales];
        }

        if (this.getAttraction().payment.creditCards) {
          this.options['payment/creditCards/id'] = this.getAttraction().payment.creditCards;
        }

        if (this.getAttraction().payment.vouchers) {
          this.options['payment/vouchers/id'] = this.getAttraction().payment.vouchers;
        }
      }

      return Promise.all(promises);
    },
  }
}
</script>

<style>
</style>
