<template>
  <a-form
      id="login-component-form"
      :form="form"
      class="login-form"
      v-on:submit="submit"
  >
    <a-form-item>
      <a-input
          v-decorator="[
                    'userName',
                    {
                        rules: [{
                            required: true,
                            message: 'Kérjük írja be a felhasználónevét!'
                        }]
                    }
                ]"
          placeholder="Felhasználónév"
      >
        <a-icon
            slot="prefix"
            type="user"
            style="color: rgba(0, 0, 0, 0.5)"
        />
      </a-input>
    </a-form-item>

    <a-form-item>
      <a-input
          v-decorator="[
                    'password',
                    {
                        rules: [{
                            required: true,
                            message: 'Kérjük írja be a jelszavát!'
                        }]
                    }
                ]"
          type="password"
          placeholder="Jelszó"
      >
        <a-icon
            slot="prefix"
            type="lock"
            style="color: rgba(0, 0, 0, 0.5)"
        />
      </a-input>
    </a-form-item>

    <a-form-item>
      <a-checkbox
          class="remember-button"
          v-decorator="[
                    'rememberMe',
                    {
                        valuePropName: 'checked',
                        initialValue: true
                    }
                ]"

      >
        Emlékezzen rám
      </a-checkbox>

      <router-link class="forgot-button" to="/forgotten-password">
        <a>
          Elfelejtett jelszó
        </a>
      </router-link>

      <a-button
          :loading="loading"
          type="primary"
          html-type="submit"
          class="login-form-button"
      >
        Bejelentkezés
      </a-button>
    </a-form-item>
  </a-form>
</template>

<script>
import { USER_LOGIN } from '@/action_types.js';
import axios from 'axios';

export default {
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },

  data() {
    return {
      loading: false
    }
  },

  methods: {
    submit(event) {
      event.preventDefault();

      this.form.validateFieldsAndScroll((error, values) => {
        if (!error) {
          this.loading = true;

          axios.post('/api/v1/companies/1/auth/login?locale=hu', {
            userName: values.userName,
            password: values.password
          })
              .then((response) => {
                this.loading = false;

                if (!response.data.isValid) {
                  this.$message.warning(response.data.messages.password);

                  return;
                }

                const userData = response.data.user;

                this.$store.dispatch({
                  type: USER_LOGIN,
                  userName: userData.userName,
                  office: userData.office,
                  email: userData.email,
                  roles: userData.roles,
                  rememberMe: values.rememberMe
                });

                this.$router.replace('/neta');
              })
              .catch((error) => {
                this.$message.error('Szerver hiba!');

                this.loading = false;
              })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.login-form {
  text-align: left;
}

.forgot-button {
  float: right;
}

.remember-button {
  float: left;
}

.login-form-button {
  width: 100%
}
</style>
