var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-form-container" },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.formDataLoading } },
        [
          _c(
            "a-form",
            {
              attrs: { form: _vm.form, layout: "vertical" },
              on: { submit: _vm.submit }
            },
            [
              _c(
                "a-form-item",
                [
                  _vm.showSaveButton && !_vm.isMobileReadonly
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            "html-type": "submit",
                            loading: _vm.submitLoading
                          }
                        },
                        [
                          _vm._v(
                            "\n                    Mentés\n                "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.hasChanged()
                    ? _c(
                        "a-button",
                        {
                          attrs: { type: "secondary", "html-type": "button" },
                          on: { click: _vm.resetForm }
                        },
                        [
                          _c("a-icon", {
                            attrs: { type: "undo", theme: "outlined" }
                          }),
                          _vm._v(
                            "\n\n                    Piszkozat törlése\n                "
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.currentTab
                    ? _c("span", { staticClass: "last-modification" }, [
                        _vm._v("\n        Utolsó mentés: "),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm
                                .moment(_vm.currentTab.modificationTime)
                                .format(_vm.timeStampFormat)
                                .toString()
                            )
                          )
                        ]),
                        _vm._v("  "),
                        _vm.currentTab.user
                          ? _c("span", [
                              _vm._v(
                                "(" + _vm._s(_vm.currentTab.user.userName) + ")"
                              )
                            ])
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm.submitResult.show
                    ? _c("a-alert", {
                        style: {
                          marginTop: "10px"
                        },
                        attrs: {
                          message: _vm.submitResult.message,
                          type: _vm.submitResult.type,
                          showIcon: "",
                          closable: "",
                          afterClose: function() {
                            return (_vm.submitResult.show = false)
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { selfUpdate: true } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v(
                        "\n          A létesítményhez legközelebbi tömegközlekedési állomás / megállóhely távolsága méterben\n\n          "
                      ),
                      _c(
                        "a-tooltip",
                        {
                          attrs: {
                            title:
                              "A lent megadottak közüli legközelebbi gyalogos távolsága méterben megadva."
                          }
                        },
                        [
                          _c("a-icon", { attrs: { type: "question-circle-o" } })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-input-number", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "travelling/closestTrainStationDistance",
                          {
                            rules: [
                              {
                                required: false,
                                whitespace: true,
                                type: "integer",
                                message: "Kérjük töltse ki!",
                                transform: function(value) {
                                  value = parseInt(value)

                                  return isNaN(value) ? null : value
                                }
                              }
                            ],
                            validateTrigger: ["blur", "change"]
                          }
                        ],
                        expression:
                          "[\n                    'travelling/closestTrainStationDistance',\n                    {\n                        rules: [{\n                            required: false,\n                            whitespace: true,\n                            type: 'integer',\n                            message: 'Kérjük töltse ki!',\n                            transform: (value) => {\n                              value = parseInt(value);\n\n                              return isNaN(value) ? null: value;\n                            },\n                        }],\n                        validateTrigger: ['blur', 'change'],\n                    }\n                ]"
                      }
                    ],
                    attrs: { disabled: _vm.isMobileReadonly, min: 0 }
                  }),
                  _vm._v("\n                m\n            ")
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { selfUpdate: true } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v(
                        "\n          Legközelebbi vasútállomás / vasúti megálló távolsága\n\n          "
                      ),
                      _c(
                        "a-tooltip",
                        {
                          attrs: {
                            title: "Gyalogos távolság méterben megadva."
                          }
                        },
                        [
                          _c("a-icon", { attrs: { type: "question-circle-o" } })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-input-number", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "travelling/closestStopDistance",
                          {
                            rules: [
                              {
                                required: false,
                                whitespace: true,
                                type: "integer",
                                message: "Kérjük töltse ki!",
                                transform: function(value) {
                                  value = parseInt(value)

                                  return isNaN(value) ? null : value
                                }
                              }
                            ],
                            validateTrigger: ["blur", "change"]
                          }
                        ],
                        expression:
                          "[\n                    'travelling/closestStopDistance',\n                    {\n                        rules: [{\n                            required: false,\n                            whitespace: true,\n                            type: 'integer',\n                            message: 'Kérjük töltse ki!',\n                            transform: (value) => {\n                              value = parseInt(value);\n\n                              return isNaN(value) ? null: value;\n                            },\n                        }],\n                        validateTrigger: ['blur', 'change'],\n                    }\n                ]"
                      }
                    ],
                    attrs: { disabled: _vm.isMobileReadonly, min: 0 }
                  }),
                  _vm._v("\n                m\n            ")
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { selfUpdate: true } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v(
                        "\n          Legközelebbi buszállomás / buszmegálló távolsága\n\n          "
                      ),
                      _c(
                        "a-tooltip",
                        {
                          attrs: {
                            title:
                              "Gyalogos távolság méterben megadva. Helyi, helyközi busz és trolibusz is ehhez a mezőhöz tartozik."
                          }
                        },
                        [
                          _c("a-icon", { attrs: { type: "question-circle-o" } })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-input-number", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "travelling/closestBusStationDistance",
                          {
                            rules: [
                              {
                                required: false,
                                whitespace: true,
                                type: "integer",
                                message: "Kérjük töltse ki!",
                                transform: function(value) {
                                  value = parseInt(value)

                                  return isNaN(value) ? null : value
                                }
                              }
                            ],
                            validateTrigger: ["blur", "change"]
                          }
                        ],
                        expression:
                          "[\n                    'travelling/closestBusStationDistance',\n                    {\n                        rules: [{\n                            required: false,\n                            whitespace: true,\n                            type: 'integer',\n                            message: 'Kérjük töltse ki!',\n                            transform: (value) => {\n                              value = parseInt(value);\n\n                              return isNaN(value) ? null: value;\n                            },\n                        }],\n                        validateTrigger: ['blur', 'change'],\n                    }\n                ]"
                      }
                    ],
                    attrs: { disabled: _vm.isMobileReadonly, min: 0 }
                  }),
                  _vm._v("\n                m\n            ")
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { selfUpdate: true } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v(
                        "\n          Legközelebbi működő repülőtér településének neve\n\n          "
                      ),
                      _c(
                        "a-tooltip",
                        {
                          attrs: {
                            title:
                              "Személyforgalom bonyolítására alkalmas, működő, engedéllyel rendelkező közforgalmi repülőtér, amely külföldi repülőtér is lehet."
                          }
                        },
                        [
                          _c("a-icon", { attrs: { type: "question-circle-o" } })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-textarea", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "travelling/closestAirportSettlement",
                          {
                            rules: [
                              {
                                required: false,
                                message: "Kérjük töltse ki!"
                              },
                              {
                                max:
                                  _vm.characterCounts[
                                    "travelling/closestAirportSettlement"
                                  ].max,
                                message:
                                  "Maximum " +
                                  _vm.characterCounts[
                                    "travelling/closestAirportSettlement"
                                  ].max +
                                  " karakter engedélyezett."
                              }
                            ]
                          }
                        ],
                        expression:
                          "[\n                    'travelling/closestAirportSettlement',\n                    {\n                        rules: [\n                            {\n                              required: false,\n                              message: 'Kérjük töltse ki!',\n                            },\n                            {\n                              max: characterCounts['travelling/closestAirportSettlement'].max,\n                              message: 'Maximum ' + characterCounts['travelling/closestAirportSettlement'].max + ' karakter engedélyezett.',\n                            }\n                          ]\n                    }\n                ]"
                      }
                    ],
                    attrs: {
                      disabled: _vm.isMobileReadonly,
                      maxLength: 25000,
                      autoSize: ""
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { selfUpdate: true } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v(
                        "\n          Legközelebbi működő repülőtér távolsága\n\n          "
                      ),
                      _c(
                        "a-tooltip",
                        {
                          attrs: {
                            title: "Autós távolság kilométerben megadva."
                          }
                        },
                        [
                          _c("a-icon", { attrs: { type: "question-circle-o" } })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-input-number", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "travelling/closestAirportDistance",
                          {
                            rules: [
                              {
                                required: false,
                                whitespace: true,
                                type: "integer",
                                message: "Kérjük töltse ki!",
                                transform: function(value) {
                                  value = parseInt(value)

                                  return isNaN(value) ? null : value
                                }
                              }
                            ],
                            validateTrigger: ["blur", "change"]
                          }
                        ],
                        expression:
                          "[\n                    'travelling/closestAirportDistance',\n                    {\n                        rules: [{\n                            required: false,\n                            whitespace: true,\n                            type: 'integer',\n                            message: 'Kérjük töltse ki!',\n                            transform: (value) => {\n                              value = parseInt(value);\n\n                              return isNaN(value) ? null: value;\n                            },\n                        }],\n                        validateTrigger: ['blur', 'change'],\n                    }\n                ]"
                      }
                    ],
                    attrs: { disabled: _vm.isMobileReadonly, min: 0 }
                  }),
                  _vm._v("\n                km\n            ")
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { selfUpdate: true } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v(
                        "\n                    200 m-en belüli egyéb tömegközlekedési kapcsolat\n\n                    "
                      ),
                      _c(
                        "a-tooltip",
                        {
                          attrs: {
                            title:
                              'Kérjük felsorolásszerűen megadni!\n                                  (Az előzőekhez nem sorolható egyéb közlekedési eszköz közül az adott vonzerőhöz legközelebb eső megállóhely távolsága méterben /pl. villamos, metró, hév, …/. Amennyiben ilyen nincs, kérjük a mezőbe „nincs" megjegyzést beírni.)'
                          }
                        },
                        [
                          _c("a-icon", { attrs: { type: "question-circle-o" } })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-textarea", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "travelling/otherPublicTransport",
                          {
                            rules: [
                              {
                                required: false,
                                message: "Kérjük töltse ki!"
                              },
                              {
                                max:
                                  _vm.characterCounts[
                                    "travelling/otherPublicTransport"
                                  ].max,
                                message:
                                  "Maximum " +
                                  _vm.characterCounts[
                                    "travelling/otherPublicTransport"
                                  ].max +
                                  " karakter engedélyezett."
                              }
                            ]
                          }
                        ],
                        expression:
                          "[\n                    'travelling/otherPublicTransport',\n                    {\n                        rules: [\n                            {\n                              required: false,\n                              message: 'Kérjük töltse ki!',\n                            },\n                            {\n                              max: characterCounts['travelling/otherPublicTransport'].max,\n                              message: 'Maximum ' + characterCounts['travelling/otherPublicTransport'].max + ' karakter engedélyezett.',\n                            }\n                          ]\n                    }\n                ]"
                      }
                    ],
                    attrs: {
                      disabled: _vm.isMobileReadonly,
                      maxLength: 25000,
                      placeholder: "Kérjük felsorolásszerűen megadni!",
                      autoSize: ""
                    }
                  })
                ],
                1
              ),
              _c("p", [
                _vm._v(
                  "\n                200 m-en belüli tömegközlekedési kapcsolat rendszeressége (járat mennyisége)\n            "
                )
              ]),
              _c(
                "a-form-item",
                { attrs: { selfUpdate: true } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v(
                        "\n                    A vonzerő számára szezont jelentő időszakban\n\n                    "
                      ),
                      _c(
                        "a-tooltip",
                        {
                          attrs: {
                            title:
                              "(db járat/nap számszerűen. Több egyéb közlekedési forma esetén a legsűrűbben járó adatát kérjük megadni. Pontos adat hiányában becsült érték is elegendő. Amennyiben nincs 200 m-en belüli tömegközlekedési kapcsolat, kérjük a mezőt üresen hagyni.)"
                          }
                        },
                        [
                          _c("a-icon", { attrs: { type: "question-circle-o" } })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-input-number", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "travelling/dailyServicesInSeason",
                          {
                            rules: [
                              {
                                required: false,
                                whitespace: true,
                                type: "integer",
                                message: "Kérjük töltse ki!",
                                transform: function(value) {
                                  value = parseInt(value)

                                  return isNaN(value) ? null : value
                                }
                              }
                            ],
                            validateTrigger: ["blur", "change"]
                          }
                        ],
                        expression:
                          "[\n                    'travelling/dailyServicesInSeason',\n                    {\n                        rules: [{\n                            required: false,\n                            whitespace: true,\n                            type: 'integer',\n                            message: 'Kérjük töltse ki!',\n                            transform: (value) => {\n                              value = parseInt(value);\n\n                              return isNaN(value) ? null: value;\n                            },\n                        }],\n                        validateTrigger: ['blur', 'change'],\n                    }\n                ]"
                      }
                    ],
                    attrs: { disabled: _vm.isMobileReadonly, min: 0 }
                  }),
                  _vm._v("\n                db járat/nap\n            ")
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { selfUpdate: true } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v(
                        "\n                    A vonzerő számára szezont jelentő időszakon kívül\n\n                    "
                      ),
                      _c(
                        "a-tooltip",
                        {
                          attrs: {
                            title:
                              "(db járat/nap számszerűen. Több egyéb közlekedési forma esetén a legsűrűbben járó adatát kérjük megadni. Amennyiben nincs 200 m-en belüli tömegközlekedési kapcsolat, kérjük a mezőt üresen hagyni.)"
                          }
                        },
                        [
                          _c("a-icon", { attrs: { type: "question-circle-o" } })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-input-number", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "travelling/dailyServicesNotInSeason",
                          {
                            rules: [
                              {
                                required: false,
                                whitespace: true,
                                type: "integer",
                                message: "Kérjük töltse ki!",
                                transform: function(value) {
                                  value = parseInt(value)

                                  return isNaN(value) ? null : value
                                }
                              }
                            ],
                            validateTrigger: ["blur", "change"]
                          }
                        ],
                        expression:
                          "[\n                    'travelling/dailyServicesNotInSeason',\n                    {\n                        rules: [{\n                            required: false,\n                            whitespace: true,\n                            type: 'integer',\n                            message: 'Kérjük töltse ki!',\n                            transform: (value) => {\n                              value = parseInt(value);\n\n                              return isNaN(value) ? null: value;\n                            },\n                        }],\n                        validateTrigger: ['blur', 'change'],\n                    }\n                ]"
                      }
                    ],
                    attrs: { disabled: _vm.isMobileReadonly, min: 0 }
                  }),
                  _vm._v("\n                db járat/nap\n            ")
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    selfUpdate: true,
                    label:
                      "Kerékpárúton / kerékpársávon a létesítmény megközelíthető?"
                  }
                },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "travelling/bicycleRoadAccess",
                            {
                              rules: [
                                {
                                  required: false,
                                  message: "Kérjük töltse ki!"
                                }
                              ],
                              validateTrigger: "blur"
                            }
                          ],
                          expression:
                            "[\n                    'travelling/bicycleRoadAccess',\n                    {\n                        rules: [{\n                            required: false,\n                            message: 'Kérjük töltse ki!'\n                        }],\n                        validateTrigger: 'blur'\n                    }\n                ]"
                        }
                      ],
                      attrs: {
                        disabled: _vm.isMobileReadonly,
                        placeholder: "Kérjük válasszon!"
                      }
                    },
                    [
                      _c("a-select-option", { attrs: { value: 1 } }, [
                        _vm._v(
                          "\n                        Igen\n                    "
                        )
                      ]),
                      _c("a-select-option", { attrs: { value: 0 } }, [
                        _vm._v(
                          "\n                        Nem\n                    "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    selfUpdate: true,
                    label:
                      "Kerékpárúton / kerékpársávon közvetlenül a bejáratig megközelíthető?"
                  }
                },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "travelling/directBicycleRoadAccess",
                            {
                              rules: [
                                {
                                  required: false,
                                  message: "Kérjük töltse ki!"
                                }
                              ],
                              validateTrigger: "blur"
                            }
                          ],
                          expression:
                            "[\n                    'travelling/directBicycleRoadAccess',\n                    {\n                        rules: [{\n                            required: false,\n                            message: 'Kérjük töltse ki!'\n                        }],\n                        validateTrigger: 'blur'\n                    }\n                ]"
                        }
                      ],
                      attrs: {
                        disabled: _vm.isMobileReadonly,
                        placeholder: "Kérjük válasszon!"
                      }
                    },
                    [
                      _c("a-select-option", { attrs: { value: 1 } }, [
                        _vm._v(
                          "\n                        Igen\n                    "
                        )
                      ]),
                      _c("a-select-option", { attrs: { value: 0 } }, [
                        _vm._v(
                          "\n                        Nem\n                    "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.form.getFieldValue(
                          "travelling/directBicycleRoadAccess"
                        ) === 0,
                      expression:
                        "form.getFieldValue('travelling/directBicycleRoadAccess') === 0"
                    }
                  ],
                  attrs: { selfUpdate: true }
                },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v(
                        "\n                    Ha kerékpárúton / kerékpársávon nem közvetlenül a bejáratig megközelíthető, akkor a legközelebbi kerékpárút távolsága\n\n                    "
                      ),
                      _c(
                        "a-tooltip",
                        {
                          attrs: {
                            title: "(Gyalogos távolság méterben megadva.)"
                          }
                        },
                        [
                          _c("a-icon", { attrs: { type: "question-circle-o" } })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-input-number", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "travelling/distanceFromBicycleRoad",
                          {
                            rules: [
                              {
                                required: false,
                                whitespace: true,
                                type: "integer",
                                message: "Kérjük töltse ki!",
                                transform: function(value) {
                                  value = parseInt(value)

                                  return isNaN(value) ? null : value
                                }
                              }
                            ],
                            validateTrigger: ["blur", "change"]
                          }
                        ],
                        expression:
                          "[\n                    'travelling/distanceFromBicycleRoad',\n                    {\n                        rules: [{\n                            required: false,\n                            whitespace: true,\n                            type: 'integer',\n                            message: 'Kérjük töltse ki!',\n                            transform: (value) => {\n                              value = parseInt(value);\n\n                              return isNaN(value) ? null: value;\n                            },\n                        }],\n                        validateTrigger: ['blur', 'change'],\n                    }\n                ]"
                      }
                    ],
                    attrs: { disabled: _vm.isMobileReadonly, min: 0 }
                  }),
                  _vm._v("\n                m\n            ")
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { selfUpdate: true } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v(
                        "\n          A vonzerő elérhetősége Budapestről, gépkocsival\n\n          "
                      ),
                      _c(
                        "a-tooltip",
                        {
                          attrs: {
                            title: "Autós távolság kilométerben megadva."
                          }
                        },
                        [
                          _c("a-icon", { attrs: { type: "question-circle-o" } })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-input-number", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "travelling/distanceFromCapital",
                          {
                            rules: [
                              {
                                required: false,
                                whitespace: true,
                                type: "integer",
                                message: "Kérjük töltse ki!",
                                transform: function(value) {
                                  value = parseInt(value)

                                  return isNaN(value) ? null : value
                                }
                              }
                            ],
                            validateTrigger: ["blur", "change"]
                          }
                        ],
                        expression:
                          "[\n                    'travelling/distanceFromCapital',\n                    {\n                        rules: [{\n                            required: false,\n                            whitespace: true,\n                            type: 'integer',\n                            message: 'Kérjük töltse ki!',\n                            transform: (value) => {\n                              value = parseInt(value);\n\n                              return isNaN(value) ? null: value;\n                            },\n                        }],\n                        validateTrigger: ['blur', 'change'],\n                    }\n                ]"
                      }
                    ],
                    attrs: { disabled: _vm.isMobileReadonly, min: 0 }
                  }),
                  _vm._v("\n                km\n            ")
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { selfUpdate: true } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v(
                        "\n          A vonzerő elérhetősége Budapestről, gépkocsival\n\n          "
                      ),
                      _c(
                        "a-tooltip",
                        {
                          attrs: { title: "Autós utazási idő percben megadva." }
                        },
                        [
                          _c("a-icon", { attrs: { type: "question-circle-o" } })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-input-number", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "travelling/timeFromCapital",
                          {
                            rules: [
                              {
                                required: false,
                                whitespace: true,
                                type: "integer",
                                message: "Kérjük töltse ki!",
                                transform: function(value) {
                                  value = parseInt(value)

                                  return isNaN(value) ? null : value
                                }
                              }
                            ],
                            validateTrigger: ["blur", "change"]
                          }
                        ],
                        expression:
                          "[\n                    'travelling/timeFromCapital',\n                    {\n                        rules: [{\n                            required: false,\n                            whitespace: true,\n                            type: 'integer',\n                            message: 'Kérjük töltse ki!',\n                            transform: (value) => {\n                              value = parseInt(value);\n\n                              return isNaN(value) ? null: value;\n                            },\n                        }],\n                        validateTrigger: ['blur', 'change'],\n                    }\n                ]"
                      }
                    ],
                    attrs: { disabled: _vm.isMobileReadonly, min: 0 }
                  }),
                  _vm._v("\n                perc\n            ")
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { selfUpdate: true } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v(
                        "\n                    A vonzerőhöz legközelebb (közlekedési távolság szempontjából) eső határállomás neve\n\n                    "
                      ),
                      _c(
                        "a-tooltip",
                        {
                          attrs: {
                            title:
                              '(Közúti határállomás, vagy a településének neve. Az országhatár átlépési pontjainak vizsgálatához szükséges adat, így az "elméleti" (nem Schengen) határállomás is.)'
                          }
                        },
                        [
                          _c("a-icon", { attrs: { type: "question-circle-o" } })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-textarea", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "travelling/closestBorderCrossing",
                          {
                            rules: [
                              {
                                required: false,
                                message: "Kérjük töltse ki!"
                              },
                              {
                                max:
                                  _vm.characterCounts[
                                    "travelling/closestAirportSettlement"
                                  ].max,
                                message:
                                  "Maximum " +
                                  _vm.characterCounts[
                                    "travelling/closestAirportSettlement"
                                  ].max +
                                  " karakter engedélyezett."
                              }
                            ]
                          }
                        ],
                        expression:
                          "[\n                    'travelling/closestBorderCrossing',\n                    {\n                        rules: [\n                            {\n                              required: false,\n                              message: 'Kérjük töltse ki!',\n                            },\n                            {\n                              max: characterCounts['travelling/closestAirportSettlement'].max,\n                              message: 'Maximum ' + characterCounts['travelling/closestAirportSettlement'].max + ' karakter engedélyezett.',\n                            }\n                          ]\n                    }\n                ]"
                      }
                    ],
                    attrs: {
                      disabled: _vm.isMobileReadonly,
                      maxLength: 25000,
                      autoSize: { minRows: 1 }
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { selfUpdate: true } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v(
                        "\n                    A vonzerő elérhetősége a vonzerőhöz legközelebb (közlekedési távolság szempontjából) eső határállomástól\n\n                    "
                      ),
                      _c(
                        "a-tooltip",
                        {
                          attrs: {
                            title: "Autós távolság kilométerben megadva."
                          }
                        },
                        [
                          _c("a-icon", { attrs: { type: "question-circle-o" } })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-input-number", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "travelling/distanceFromClosestBorderCrossing",
                          {
                            rules: [
                              {
                                required: false,
                                whitespace: true,
                                type: "integer",
                                message: "Kérjük töltse ki!",
                                transform: function(value) {
                                  value = parseInt(value)

                                  return isNaN(value) ? null : value
                                }
                              }
                            ],
                            validateTrigger: ["blur", "change"]
                          }
                        ],
                        expression:
                          "[\n                    'travelling/distanceFromClosestBorderCrossing',\n                    {\n                        rules: [{\n                            required: false,\n                            whitespace: true,\n                            type: 'integer',\n                            message: 'Kérjük töltse ki!',\n                            transform: (value) => {\n                              value = parseInt(value);\n\n                              return isNaN(value) ? null: value;\n                            },\n                        }],\n                        validateTrigger: ['blur', 'change'],\n                    }\n                ]"
                      }
                    ],
                    attrs: { disabled: _vm.isMobileReadonly, min: 0 }
                  }),
                  _vm._v("\n                km\n            ")
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { selfUpdate: true } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v(
                        "\n          A vonzerő elérhetősége a vonzerőhöz legközelebb (közlekedési távolság szempontjából) eső határállomástól\n\n          "
                      ),
                      _c(
                        "a-tooltip",
                        {
                          attrs: { title: "Autós utazási idő percben megadva." }
                        },
                        [
                          _c("a-icon", { attrs: { type: "question-circle-o" } })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-input-number", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "travelling/timeFromClosestBorderCrossing",
                          {
                            rules: [
                              {
                                required: false,
                                whitespace: true,
                                type: "integer",
                                message: "Kérjük töltse ki!",
                                transform: function(value) {
                                  value = parseInt(value)

                                  return isNaN(value) ? null : value
                                }
                              }
                            ],
                            validateTrigger: ["blur", "change"]
                          }
                        ],
                        expression:
                          "[\n                    'travelling/timeFromClosestBorderCrossing',\n                    {\n                        rules: [{\n                            required: false,\n                            whitespace: true,\n                            type: 'integer',\n                            message: 'Kérjük töltse ki!',\n                            transform: (value) => {\n                              value = parseInt(value);\n\n                              return isNaN(value) ? null: value;\n                            },\n                        }],\n                        validateTrigger: ['blur', 'change'],\n                    }\n                ]"
                      }
                    ],
                    attrs: { disabled: _vm.isMobileReadonly, min: 0 }
                  }),
                  _vm._v("\n                perc\n            ")
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { selfUpdate: true } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v("\n          Megjegyzés\n\n          "),
                      _c(
                        "a-tooltip",
                        {
                          attrs: {
                            title:
                              "Kérjük ezt a cellát akkor töltse ki, ha a témához kapcsolódóan egyéb megjegyzése van."
                          }
                        },
                        [
                          _c("a-icon", { attrs: { type: "question-circle-o" } })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-textarea", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "travelling/comment",
                          {
                            rules: [
                              {
                                required: false,
                                message: "Kérjük töltse ki!"
                              }
                            ]
                          }
                        ],
                        expression:
                          "[\n                    'travelling/comment',\n                    {\n                        rules: [{\n                            required: false,\n                            message: 'Kérjük töltse ki!'\n                        }]\n                    }\n                ]"
                      }
                    ],
                    attrs: {
                      disabled: _vm.isMobileReadonly,
                      maxLength: 25000,
                      autoSize: { minRows: 2 }
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                [
                  _vm.showSaveButton && !_vm.isMobileReadonly
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            "html-type": "submit",
                            loading: _vm.submitLoading
                          }
                        },
                        [
                          _vm._v(
                            "\n                    Mentés\n                "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.hasChanged()
                    ? _c(
                        "a-button",
                        {
                          attrs: { type: "secondary", "html-type": "button" },
                          on: { click: _vm.resetForm }
                        },
                        [
                          _c("a-icon", {
                            attrs: { type: "undo", theme: "outlined" }
                          }),
                          _vm._v(
                            "\n\n                    Piszkozat törlése\n                "
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.currentTab
                    ? _c("span", { staticClass: "last-modification" }, [
                        _vm._v("\n        Utolsó mentés: "),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm
                                .moment(_vm.currentTab.modificationTime)
                                .format(_vm.timeStampFormat)
                                .toString()
                            )
                          )
                        ]),
                        _vm._v("  "),
                        _vm.currentTab.user
                          ? _c("span", [
                              _vm._v(
                                "(" + _vm._s(_vm.currentTab.user.userName) + ")"
                              )
                            ])
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm.submitResult.show
                    ? _c("a-alert", {
                        style: {
                          marginTop: "10px"
                        },
                        attrs: {
                          message: _vm.submitResult.message,
                          type: _vm.submitResult.type,
                          showIcon: "",
                          closable: "",
                          afterClose: function() {
                            return (_vm.submitResult.show = false)
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }