<template>
    <div>
        <router-link :to="{name: 'neta-list-attraction'}">
            <a-button
                    v-show="!collapsed"
                    class="attraction-back-button"
                    size="large"
                    icon="table"
                    type="default"
            >Vissza a listához
            </a-button>

            <a-button
                    v-show="collapsed"
                    class="attraction-back-button"
                    size="large"
                    icon="table"
                    type="default"
            />
        </router-link>

        <div class="attraction-data" v-if="attraction" v-show="!collapsed">
            <div class="attraction-name">
                <strong>{{ attraction.name }}</strong>
            </div>

            <div class="attraction-id">
                Azonosító: <strong>{{ attraction.id }}</strong>
            </div>

            <div class="attraction-city" v-if="attraction.address && attraction.address.city">
                Település: <strong>{{ attraction.address.city.name }}</strong>
            </div>

            <div class="attraction-status">
                Státusz: <strong>{{ statusNames[attraction.status] }}</strong>
            </div>

            <div class="attraction-parent" v-if="multiTobParents.length && !isMobileApp">
                Multi TOB{{ multiTobParents.length > 1 ? 'ok' : '' }}:

                <ul>
                    <li v-for="parentAttraction in multiTobParents">
                        <strong>{{ parentAttraction.name }}</strong>

                        <router-link :to="getMultiTobEditUrl(parentAttraction)">
                            <a-button
                                    size="small"
                                    icon="edit"
                                    type="primary"
                                    class="action-button"
                            >
                            </a-button>
                        </router-link>
                    </li>
                </ul>
            </div>

            <div class="attraction-parent" v-if="eventParents.length">
                Esemény{{ eventParents.length > 1 ? 'ek' : '' }}:

                <ul>
                    <li v-for="parentAttraction in eventParents">
                        <strong>{{ parentAttraction.name }}</strong>

                        <router-link :to="getEventEditUrl(parentAttraction)">
                            <a-button
                                    size="small"
                                    icon="edit"
                                    type="primary"
                                    class="action-button"
                            >
                            </a-button>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>

        <a-menu
                mode="inline"
                theme="light"
                :style="{height: '100%',maxWidth: '300px'}"
                :defaultSelectedKeys="selectedMenuItem()"
                :defaultOpenKeys="['neta-basic-attraction-data']"
        >

            <!--      <a-sub-menu key="neta-basic-data" v-if="currentType === 'basic'">-->
            <!--      <span slot="title">-->
            <!--          <a-icon type="snippets" />-->
            <!--          <span>Alapadatok</span>-->
            <!--      </span>-->

            <template
                v-for="subForm in getCategoryRoutes('neta-basic-attraction-data')">
                <a-menu-item v-if="!attraction.mobileOnly || subForm.name != 'neta-basic-attraction-attraction-location-form'"
                             :key="subForm.name">
                    <router-link :to="{name: subForm.name}">
                        <a-icon
                                v-if="typeof formIdMap[subForm.path] !== 'undefined' && loading[formIdMap[subForm.path]][attraction.id]"
                                type="sync"
                                :style="{color: 'black'}"
                                :spin="true"
                        />

                        <a-icon
                                v-else-if="typeof formIdMap[subForm.path] !== 'undefined' && drafts[formIdMap[subForm.path]][attraction.id]"
                                type="edit"
                                :style="{color: 'orange'}"
                        />

                        <a-icon
                                v-else-if="getTabIcon(subForm.path)"
                                :type="getTabIcon(subForm.path).icon"
                                :style="{color: getTabIcon(subForm.path).color}"
                        />

                        <span>
                  {{ subForm.meta.menuName }}
                </span>
                    </router-link>
                </a-menu-item>
            </template>
            <!--      </a-sub-menu>-->
        </a-menu>
    </div>
</template>

<script>
import invert from 'lodash/invert';

export default {
    data() {
        return {
            formsMap: {
                ATTRACTION_DATA: 'attraction-data-form',
                DESCRIPTION: 'description-form',
                ACCESSIBILITY: 'accessibility-form',
                FOREIGN_LANGUAGES: 'foreign-languages-form',
                ATTRACTION_LOCATION: 'attraction-location-form',
                OPENING: 'opening-form',
                PAYMENT_RESERVATION: 'payment-reservation-form',
                PRICES: 'prices-form'
            }
        }
    },

    props: {
        collapsed: {
            type: Boolean,
            default: false
        },

        statusNames: {
            type: Object,
            required: true
        }
    },

    computed: {
        /**
         * @returns {Object}
         */
        attraction() {
            return this.$store.state.attraction.current;
        },

        isMobileApp() {
            return this.$store.getters.hasRoleAvailable(['ROLE_MOBILE_APP']);
        },

        multiTobParents() {
            const parentAttractions = this.attraction.parentAttractions;
            const multiTobParents = [];

            parentAttractions.forEach((attraction) => {
                if (attraction.multi && attraction.status !== 6) {
                    multiTobParents.push(attraction);
                }
            });

            return multiTobParents;
        },

        eventParents() {
            const parentAttractions = this.attraction.parentAttractions;
            const eventParents = [];

            parentAttractions.forEach((attraction) => {
                if (attraction.group.groupType === 'event' && attraction.status !== 6) {
                    eventParents.push(attraction);
                }
            });

            return eventParents;
        },

        /**
         * @returns {Boolean}
         */
        loading() {
            return this.$store.state.forms.loading.attraction;
        },

        /**
         * @returns {Object}
         */
        drafts() {
            return this.$store.state.forms.drafts.attraction;
        },

        /**
         * @returns {Object}
         */
        formIdMap() {
            return invert(this.formsMap);
        },

        /**
         * @returns {string}
         */
        currentType() {
            return this.$route.matched.find(route => route.meta.attractionType).meta.attractionType;
        },

        /**
         * @returns {boolean}
         */
        pricesVisible() {
            return this.$store.state.pricesVisible;
        },

        /**
         * @returns {{}}
         */
        formTabState() {
            let tabStatuses = {};

            for (let tabCode in this.formsMap) {
                tabStatuses[this.formsMap[tabCode]] = 0;
            }

            const tabs = this.$store.state.attraction.current.tabs;

            tabs.forEach((tab) => {
                if (typeof this.formsMap[tab.code] === 'undefined') {
                    return;
                }

                tabStatuses[this.formsMap[tab.code]] = tab.status;
            });

            return tabStatuses;
        }
    },

    methods: {
        /**
         * @returns {[]}
         */
        getCategoryRoutes(categoryName) {
            const netaDetailsRoutes = () => {
                const netaRoutes = this.$router.options.routes.find(item => {
                    return item.name === 'neta';
                });

                return netaRoutes.children.find(item => {
                    return item.name === 'neta-attraction-details';
                });
            };

            const category = netaDetailsRoutes().children.find(item => {
                return item.name === categoryName;
            });

            if (category) {
                return category.children.filter(item => {
                    if (item.name === 'neta-basic-attraction-prices-form') {
                        return this.pricesVisible;
                    }

                    return true;
                });
            }

            return [];
        },

        /**
         * @returns [{string}]
         */
        selectedMenuItem() {
            return [this.$route.name];
        },

        /**
         *
         * @param {object} attraction
         * @returns {{name: string, params: {id}}}
         */
        getMultiTobEditUrl(attraction) {
            return {
                name: 'neta-basic-multi-attraction-attraction-data-form',
                params: {
                    id: attraction.id
                }
            }
        },

        /**
         *
         * @param {object} attraction
         * @returns {{name: string, params: {id}}}
         */
        getEventEditUrl(attraction) {
            return {
                name: 'neta-basic-event-attraction-data-form',
                params: {
                    id: attraction.id
                }
            }
        },

        /**
         * @param {string} tabName
         * @returns {{color: string, icon: string}|null}
         */
        getTabIcon(tabName) {
            switch (tabName) {
                case 'teka':
                    if (!this.attraction.countTekaEntities) {
                        return {
                            color: '#FFD300',
                            icon: 'question-circle'
                        };
                    } else {
                        return {
                            color: 'green',
                            icon: 'check-square'
                        };
                    }

                default:
                    const status = this.formTabState[tabName];

                    if (status === undefined) {
                        return null
                    }

                    switch (status) {
                        case 1: // Waiting for approval
                            return {
                                color: '#FFD300',
                                icon: 'question-circle'
                            };

                        case 2: // Approved
                            return {
                                color: 'green',
                                icon: 'check-circle'
                            };

                        case 3: // Ready to publish
                            if(this.attraction.mobileOnly) {
                                return {
                                    color: 'green',
                                    icon: 'tablet'
                                }
                            }

                            return {
                                color: 'green',
                                icon: 'check-square'
                            };
                    }

                    // New
                    return {
                        color: 'grey',
                        icon: 'minus-circle'
                    };
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.attraction-back-button {
  margin: 15px;
}

.attraction-data {
  width: 300px;
  padding: 15px;
  background: white;
  word-wrap: break-word;
  overflow: hidden;
}

.attraction-parent {
  a {
    margin-left: 5px;
  }
}

.empty-circle-icon {
  width: 15px;
  height: 15px;
  display: inline-block;
  border: 3px solid white;
  margin-right: 8px;
  border-radius: 50%;
  margin-bottom: -2px;
  box-shadow: 0 0 2px 0 black, inset 0 0 2px black;
}
</style>
