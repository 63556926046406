import DescriptionFormComponent from '@/components/neta/neta-multi-attraction/basic-data/forms/description-form/DescriptionFormComponent.vue';

import AttractionDataFormComponent
    from '@/components/neta/neta-multi-attraction/basic-data/forms/attraction-data-form/AttractionDataFormComponent.vue';

let usedComponents = [
    AttractionDataFormComponent,
    DescriptionFormComponent
];

const routePrefix = 'neta-basic-multi-attraction';

export default {
    routePrefix,
    usedComponents
};