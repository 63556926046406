import DescriptionFormComponent from '@/components/neta/neta-multi-event/basic-data/forms/description-form/DescriptionFormComponent.vue';

import AttractionDataFormComponent
    from '@/components/neta/neta-multi-event/basic-data/forms/attraction-data-form/AttractionDataFormComponent.vue';
import TekaComponent from "@/components/neta/neta-multi-event/basic-data/forms/teka/TekaComponent.vue";

let usedComponents = [
    AttractionDataFormComponent,
    DescriptionFormComponent,
    TekaComponent
];

const routePrefix = 'neta-basic-multi-event';

export default {
    routePrefix,
    usedComponents
};