import Vue from "vue";
import Cleave from "cleave.js";

// Cleave is used for phone number formatting
require('cleave.js/dist/addons/cleave-phone.hu');

Vue.directive('cleave', {
    inserted: (element, binding) => {
        element.cleave = new Cleave(element, binding.value || {})
    },
    update: (el) => {
        const event = new Event('input', { bubbles: true });
        setTimeout(function () {
            el.value = el.cleave.properties.result
            el.dispatchEvent(event)
        }, 100);
    }
});