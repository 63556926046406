import { render, staticRenderFns } from "./DescriptionFormComponent.vue?vue&type=template&id=3d8e6607&xmlns=true&"
import script from "./DescriptionFormComponent.vue?vue&type=script&lang=js&"
export * from "./DescriptionFormComponent.vue?vue&type=script&lang=js&"
import style0 from "./DescriptionFormComponent.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/neta-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3d8e6607')) {
      api.createRecord('3d8e6607', component.options)
    } else {
      api.reload('3d8e6607', component.options)
    }
    module.hot.accept("./DescriptionFormComponent.vue?vue&type=template&id=3d8e6607&xmlns=true&", function () {
      api.rerender('3d8e6607', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/neta/neta-multi-attraction/basic-data/forms/description-form/DescriptionFormComponent.vue"
export default component.exports