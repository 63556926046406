import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './plugins/ant-design-vue.js';
import './plugins/cleave.js';
import './plugins/super.js';

import { httpSetup } from '@/http/httpSetup.js';
import { RESTORE_LOCAL_STATE } from '@/./action_types';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://3f47dc922166415cb0582eb87033e160@sentry.wponline.hu/4',
        integrations: [new Integrations.Vue({ Vue, attachProps: true })],
        release: process.env.VUE_APP_VERSION,
        environment: process.env.NODE_ENV,
        autoSessionTracking: false,
        // beforeSend(event, hint) {
        //     // Check if it is an exception, and if so, show the report dialog
        //     if (event.exception) {
        //         Sentry.showReportDialog({
        //             eventId: event.event_id,
        //             user: {
        //                 name: store.state.user.userName,
        //                 email: store.state.user.email
        //             },
        //             title: 'Váratlan hiba történt!',
        //             subtitle: 'A fejlesztők már megkapták a részletes hibanaplót!',
        //             subtitle2: 'Amennyiben szeretné segíteni a probléma mielőbbi megoldását, kérem írja le a hiba körülményeit!',
        //             labelName: 'Felhasználó név',
        //             labelEmail: 'E-mail cím',
        //             labelComments: 'Mi történt a hiba megjelenése előtt?',
        //             labelClose: 'Bezárás',
        //             labelSubmit: 'Küldés',
        //             successMessage: 'Köszönjük, a hiba leírását megkaptuk!',
        //             errorFormEntry: 'Néhány mezőt hibásan töltött ki, kérem ellenőrizze!',
        //             errorGeneric: 'Egy ismeretlen hiba akadályozza a jelentés beküldését.. Kérem próbálja meg később!',
        //         });
        //     }
        //     return event;
        // },
    });
}

httpSetup();

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App),
    beforeCreate() {
        this.$store.dispatch(RESTORE_LOCAL_STATE);
    },
}).$mount('#app')
