import Vue from 'vue'
import Router from 'vue-router'

import { routes as homeRoutes } from '@/routes/home-routes/routes.js';
import { routes as netaRoutes } from '@/routes/neta-routes/routes.js';

import { beforeEach, afterEach } from '@/routes/guards.js';

import NotFoundView from '@/views/error-views/NotFoundView.vue';
import AuthErrorView from '@/views/error-views/AuthErrorView.vue';

Vue.use(Router);

let scrollTop = null;
const router = new Router({
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }

        if (from.name.match(/^neta-list/)) {
            if (to.name.match(/^neta-basic/)) {
                scrollTop = window.scrollY;
            }
        }

        if (scrollTop !== null) {
            if (to.name.match(/^neta-list/)) {
                if (from.name.match(/^neta-basic/)) {
                    let restored = { y: scrollTop };

                    scrollTop = null;

                    return restored;
                }
            }
        }

        return { y: 0 }
    },
    routes: [
        homeRoutes,
        netaRoutes,
        {
            path: '/auth-error',
            name: 'auth-error',
            component: AuthErrorView,
            props: true
        },
        {
            path: '*',
            name: 'notfound',
            component: NotFoundView
        }
    ]
})

router.beforeEach(beforeEach);
router.afterEach(afterEach);

export default router;
