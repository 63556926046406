<template xmlns="">
    <div class="teka-container">
        <iframe :src="tekaUrl"></iframe>
    </div>
</template>

<script>
import { mapState } from "vuex";
import cloneDeep from 'lodash/cloneDeep';
import { config } from "@/config";

export default {
    menuName: 'TÉKA',
    name: 'teka',

    computed: {
        ...mapState({
            user: state => state.user
        }),

        apiKey() {
            return this.$store.getters.getTekaApiKey();
        },

        tekaUrl() {
            let attraction = this.getAttraction();

            let baseUrl = 'https://teka.csodasmagyarorszag.hu/embeddedpages/Content/Index';

            if(config.development) {
                baseUrl = 'https://teka-teszt.csodasmagyarorszag.hu/embeddedpages/Content/Index';
            }

            let entityType = 'http%3A%2F%2Fmtu.gov.hu%2Fapi%2Frdf%2Fapi%2Fmtu%2Fneta%23Attraction';

            return baseUrl
                + '?apiKey=' + this.apiKey
                + '&externalEntityId=' + attraction.uid
                + '&externalEntityType=' + entityType
                + '&externalEntityName=' + attraction.name
                + '&externalSystem=NETA'
                + '&externalUser=' + this.user.userName
                + '&externalRole=' + this.user.selectedRole
                + '&preferredContentType=ImageGallery';
        }
    },

    data() {
        return {}
    },

    methods: {
        /**
         * @returns {object}
         */
        getAttraction() {
            return cloneDeep(this.$store.getters.getCurrentAttraction);
        },
    }
}
</script>

<style lang="scss">
.teka-container {
  padding-bottom: 30px;

  iframe {
    width: 100%;
    height: calc(100vh - 200px);
    border: 0;
  }
}
</style>
