import { render, staticRenderFns } from "./NetaMultiAttractionBasicDataComponent.vue?vue&type=template&id=02e5075e&scoped=true&"
import script from "./NetaMultiAttractionBasicDataComponent.vue?vue&type=script&lang=js&"
export * from "./NetaMultiAttractionBasicDataComponent.vue?vue&type=script&lang=js&"
import style0 from "./NetaMultiAttractionBasicDataComponent.vue?vue&type=style&index=0&lang=css&"
import style1 from "./NetaMultiAttractionBasicDataComponent.vue?vue&type=style&index=1&id=02e5075e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02e5075e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/neta-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('02e5075e')) {
      api.createRecord('02e5075e', component.options)
    } else {
      api.reload('02e5075e', component.options)
    }
    module.hot.accept("./NetaMultiAttractionBasicDataComponent.vue?vue&type=template&id=02e5075e&scoped=true&", function () {
      api.rerender('02e5075e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/neta/neta-multi-attraction/basic-data/NetaMultiAttractionBasicDataComponent.vue"
export default component.exports