import NetaHomeContentComponent from '@/components/neta/NetaHomeContentComponent.vue';
import NetaNewAttractionComponent
    from '@/components/neta/neta-new/NetaNewAttractionComponent.vue';
import NetaNewEventComponent
    from '@/components/neta/neta-new/NetaNewEventComponent.vue';
import NetaNewMultiAttractionComponent
    from '@/components/neta/neta-new/NetaNewMultiAttractionComponent.vue';
import NetaNewView from '@/views/app-views/NetaNewView.vue';
import NetaListView from '@/views/app-views/NetaListView.vue';
import NetaListAttractionComponent from "@/components/neta/neta-list/NetaListAttractionComponent";
import NetaListEventComponent from "@/components/neta/neta-list/NetaListEventComponent";
import NetaListMultiAttractionComponent from "@/components/neta/neta-list/NetaListMultiAttractionComponent";

import NetaAttractionDetailsView from '@/views/app-views/NetaAttractionDetailsView.vue';
import NetaEventDetailsView from '@/views/app-views/NetaEventDetailsView.vue';
import NetaMultiAttractionDetailsView from '@/views/app-views/NetaMultiAttractionDetailsView.vue';

import NetaAttractionBasicDataComponent
    from '@/components/neta/neta-attraction/basic-data/NetaAttractionBasicDataComponent.vue';
import NetaEventBasicDataComponent from '@/components/neta/neta-event/basic-data/NetaEventBasicDataComponent.vue';
import NetaMultiAttractionBasicDataComponent
    from '@/components/neta/neta-multi-attraction/basic-data/NetaMultiAttractionBasicDataComponent.vue';
import NetaNewMultiEventComponent from "@/components/neta/neta-new/NetaNewMultiEventComponent.vue";

import OfficeContentComponent from '@/components/neta/office/OfficeContentComponent.vue';
import TmrExportContentComponent from '@/components/neta/tmr-export/TmrExportContentComponent.vue';
import SpecialistExportContentComponent from '@/components/neta/specialist-export/SpecialistExportContentComponent.vue';
import OfficeExportContentComponent from '@/components/neta/office/OfficeExportContentComponent.vue';

import AttractionBasicDataFormComponents from '@/components/neta/neta-attraction/basic-data/FormComponents.js';
import EventBasicDataFormComponents from '@/components/neta/neta-event/basic-data/FormComponents.js';
import MultiAttractionBasicDataFormComponents
    from '@/components/neta/neta-multi-attraction/basic-data/FormComponents.js';

import isEmpty from 'lodash/isEmpty';
import store from '../../store';
import NetaListMultiEventComponent from "@/components/neta/neta-list/NetaListMultiEventComponent.vue";
import NetaMultiEventDetailsView from "@/views/app-views/NetaMultiEventDetailsView.vue";
import MultiEventBasicDataFormComponents from '@/components/neta/neta-multi-event/basic-data/FormComponents.js';
import NetaMultiEventBasicDataComponent
    from "@/components/neta/neta-multi-event/basic-data/NetaMultiEventBasicDataComponent.vue";
import NetaHungarikumBasicDataComponent
    from "@/components/neta/neta-hungarikum/basic-data/NetaHungarikumBasicDataComponent.vue";
import NetaHungarikumBasicDataFormComponents from '@/components/neta/neta-hungarikum/basic-data/FormComponents.js';
import NetaListHungarikumComponent from "@/components/neta/neta-list/NetaListHungarikumComponent.vue";
import NetaHungarikumDetailsView from "@/views/app-views/NetaHungarikumDetailsView.vue";
import NetaNewHungarikumComponent from "@/components/neta/neta-new/NetaNewHungarikumComponent.vue";

const generateFormChildRoutes = (routePrefix, usedFormComponentNames) => {
    return usedFormComponentNames.map(component => {
        return {
            path: `${component.name}`,
            name: `${routePrefix}-${component.name}`,
            meta: {
                menuName: component.menuName
            },
            component
        }
    })
};

export const routes = {
    path: '/neta',
    name: 'neta',
    component: () => import(/* webpackChunkName: "neta" */ '@/views/app-views/AppBaseView.vue'),
    meta: {
        requiresLogin: true
    },
    redirect: { name: 'neta-list' },
    children: [
        {
            path: 'home',
            name: 'neta-home',
            component: NetaHomeContentComponent,
            redirect: { name: 'neta-list' }
        },
        {
            path: 'new',
            name: 'neta-new',
            component: NetaNewView,
            redirect: { name: 'neta-new-attraction' },
            children: [
                {
                    path: 'attraction',
                    name: 'neta-new-attraction',
                    component: NetaNewAttractionComponent,
                },
                {
                    path: 'event',
                    name: 'neta-new-event',
                    component: NetaNewEventComponent,
                },
                {
                    path: 'multi-attraction',
                    name: 'neta-new-multi-attraction',
                    component: NetaNewMultiAttractionComponent,
                },
                {
                    path: 'multi-event',
                    name: 'neta-new-multi-event',
                    component: NetaNewMultiEventComponent,
                },
                // {
                //     path: 'hungarikum',
                //     name: 'neta-new-hungarikum',
                //     component: NetaNewHungarikumComponent,
                // },
            ]
        },
        {
            path: 'list',
            name: 'neta-list',
            component: NetaListView,
            children: [
                {
                    path: 'attraction',
                    name: 'neta-list-attraction',
                    component: NetaListAttractionComponent,
                    beforeEnter: (to, from, next) => {
                        if (store.getters.isRoleHungarikum) {
                            next({ name: 'neta-list-hungarikum' })

                            return;
                        }

                        let lastListQueryParams = store.state.lastListQueryParams.attraction;

                        if (isEmpty(to.query) && !isEmpty(lastListQueryParams)) {
                            next({
                                name: 'neta-list-attraction',
                                query: lastListQueryParams
                            });
                        } else {
                            next();
                        }
                    }
                },
                {
                    path: 'event',
                    name: 'neta-list-event',
                    component: NetaListEventComponent,
                    beforeEnter: (to, from, next) => {
                        if (store.getters.isRoleHungarikum) {
                            next({ name: 'neta-list-hungarikum' })

                            return;
                        }

                        let lastListQueryParams = store.state.lastListQueryParams.event;

                        if (isEmpty(to.query) && !isEmpty(lastListQueryParams)) {
                            next({
                                name: 'neta-list-event',
                                query: lastListQueryParams
                            });
                        } else {
                            next();
                        }
                    }
                },
                {
                    path: 'multi-attraction',
                    name: 'neta-list-multi-attraction',
                    component: NetaListMultiAttractionComponent,
                    beforeEnter: (to, from, next) => {
                        if (store.getters.isRoleHungarikum) {
                            next({ name: 'neta-list-hungarikum' })

                            return;
                        }

                        let lastListQueryParams = store.state.lastListQueryParams.multiAttraction;

                        if (isEmpty(to.query) && !isEmpty(lastListQueryParams)) {
                            next({
                                name: 'neta-list-multi-attraction',
                                query: lastListQueryParams
                            });
                        } else {
                            next();
                        }
                    }
                },
                {
                    path: 'multi-event',
                    name: 'neta-list-multi-event',
                    component: NetaListMultiEventComponent,
                    beforeEnter: (to, from, next) => {
                        if (store.getters.isRoleHungarikum) {
                            next({ name: 'neta-list-hungarikum' })

                            return;
                        }

                        let lastListQueryParams = store.state.lastListQueryParams.multiEvent;

                        if (isEmpty(to.query) && !isEmpty(lastListQueryParams)) {
                            next({
                                name: 'neta-list-multi-vent',
                                query: lastListQueryParams
                            });
                        } else {
                            next();
                        }
                    }
                },
                // {
                //     path: 'hungarikum',
                //     name: 'neta-list-hungarikum',
                //     component: NetaListHungarikumComponent,
                //     beforeEnter: (to, from, next) => {
                //         let lastListQueryParams = store.state.lastListQueryParams.hungarikum;
                //
                //         if (isEmpty(to.query) && !isEmpty(lastListQueryParams)) {
                //             next({
                //                 name: 'neta-list-hungarikum',
                //                 query: lastListQueryParams
                //             });
                //         } else {
                //             next();
                //         }
                //     }
                // },
            ],

            beforeEnter: (to, from, next) => {
                if (to.name !== 'neta-list') {
                    next();

                    return;
                }

                let lastListQueryParams;

                switch (store.state.lastGroupType) {
                    case 'event':
                        lastListQueryParams = store.state.lastListQueryParams.event;

                        if (isEmpty(to.query) && !isEmpty(lastListQueryParams)) {
                            next({
                                name: 'neta-list-event',
                                query: lastListQueryParams
                            });
                        } else {
                            next({
                                name: 'neta-list-event'
                            });
                        }

                        break;

                    case 'multiAttraction':
                        lastListQueryParams = store.state.lastListQueryParams.multiAttraction;

                        if (isEmpty(to.query) && !isEmpty(lastListQueryParams)) {
                            next({
                                name: 'neta-list-multi-attraction',
                                query: lastListQueryParams
                            });
                        } else {
                            next({
                                name: 'neta-list-multi-attraction'
                            });
                        }

                        break;

                    case 'multiEvent':
                        lastListQueryParams = store.state.lastListQueryParams.multiEvent;

                        if (isEmpty(to.query) && !isEmpty(lastListQueryParams)) {
                            next({
                                name: 'neta-list-multi-event',
                                query: lastListQueryParams
                            });
                        } else {
                            next({
                                name: 'neta-list-multi-event'
                            });
                        }

                        break;

                    case 'hungarikum':
                        lastListQueryParams = store.state.lastListQueryParams.hungarikum;

                        if (isEmpty(to.query) && !isEmpty(lastListQueryParams)) {
                            next({
                                name: 'neta-list-hungarikum',
                                query: lastListQueryParams
                            });
                        } else {
                            next({
                                name: 'neta-list-hungarikum'
                            });
                        }

                        break;

                    case 'attraction':
                    default:
                        lastListQueryParams = store.state.lastListQueryParams.attraction;

                        if (isEmpty(to.query) && !isEmpty(lastListQueryParams)) {
                            next({
                                name: 'neta-list-attraction',
                                query: lastListQueryParams
                            });
                        } else {
                            next({
                                name: 'neta-list-attraction'
                            });
                        }

                        break;
                }
            }
        },
        {
            path: 'office',
            name: 'office',
            component: OfficeContentComponent,
            beforeEnter: (to, from, next) => {
                if (store.getters.isRoleAdmin && !store.getters.isRoleViewer) {
                    next({ name: 'neta-home' })
                } else {
                    next();
                }
            }
        },
        {
            path: 'tmr-export',
            name: 'tmr-export',
            component: TmrExportContentComponent,
            beforeEnter: (to, from, next) => {
                if (!store.getters.isRoleAdmin && !store.getters.isRoleViewer) {
                    next({ name: 'neta-home' })
                } else {
                    next();
                }
            }
        },
        {
            path: 'specialist-export',
            name: 'specialist-export',
            component: SpecialistExportContentComponent,
            beforeEnter: (to, from, next) => {
                if (!store.getters.isRoleAdmin && !store.getters.isRoleViewer) {
                    next({ name: 'neta-home' })
                } else {
                    next();
                }
            }
        },
        {
            path: 'office-export',
            name: 'office-export',
            component: OfficeExportContentComponent,
            beforeEnter: (to, from, next) => {
                if (!store.getters.isRoleAdmin && !store.getters.isRoleViewer) {
                    next({ name: 'neta-home' })
                } else {
                    next();
                }
            }
        },
        {
            path: 'attractions/:id',
            name: 'neta-attraction-details',
            component: NetaAttractionDetailsView,

            redirect: { name: 'neta-basic-attraction-data' },
            children: [
                {
                    path: 'basic',
                    name: 'neta-basic-attraction-data',
                    component: NetaAttractionBasicDataComponent,
                    meta: {
                        menuName: 'Alapadatok',
                        attractionType: 'basic'
                    },
                    children: [
                        ...generateFormChildRoutes(
                            AttractionBasicDataFormComponents.routePrefix
                            , AttractionBasicDataFormComponents.usedComponents
                        ),
                    ]
                },
            ]
        },
        {
            path: 'events/:id',
            name: 'neta-event-details',
            component: NetaEventDetailsView,
            redirect: { name: 'neta-basic-event-data' },
            children: [
                {
                    path: 'basic',
                    name: 'neta-basic-event-data',
                    component: NetaEventBasicDataComponent,
                    meta: {
                        menuName: 'Alapadatok',
                        attractionType: 'basic'
                    },
                    children: [
                        ...generateFormChildRoutes(
                            EventBasicDataFormComponents.routePrefix
                            , EventBasicDataFormComponents.usedComponents
                        ),
                    ]
                },
            ]
        },
        {
            path: 'multi-attractions/:id',
            name: 'neta-multi-attraction-details',
            component: NetaMultiAttractionDetailsView,
            redirect: { name: 'neta-basic-multi-attraction-data' },
            children: [
                {
                    path: 'basic',
                    name: 'neta-basic-multi-attraction-data',
                    component: NetaMultiAttractionBasicDataComponent,
                    meta: {
                        menuName: 'Alapadatok',
                        attractionType: 'basic'
                    },
                    children: [
                        ...generateFormChildRoutes(
                            MultiAttractionBasicDataFormComponents.routePrefix
                            , MultiAttractionBasicDataFormComponents.usedComponents
                        ),
                    ]
                },
            ]
        },
        {
            path: 'multi-events/:id',
            name: 'neta-multi-event-details',
            component: NetaMultiEventDetailsView,
            redirect: { name: 'neta-basic-multi-event-data' },
            children: [
                {
                    path: 'basic',
                    name: 'neta-basic-multi-event-data',
                    component: NetaMultiEventBasicDataComponent,
                    meta: {
                        menuName: 'Alapadatok',
                        attractionType: 'basic'
                    },
                    children: [
                        ...generateFormChildRoutes(
                            MultiEventBasicDataFormComponents.routePrefix
                            , MultiEventBasicDataFormComponents.usedComponents
                        ),
                    ]
                },
            ]
        },
        {
            path: 'hungarikum/:id',
            name: 'neta-hungarikum-details',
            component: NetaHungarikumDetailsView,
            redirect: { name: 'neta-basic-hungarikum-data' },
            children: [
                {
                    path: 'basic',
                    name: 'neta-basic-hungarikum-data',
                    component: NetaHungarikumBasicDataComponent,
                    meta: {
                        menuName: 'Alapadatok',
                        attractionType: 'basic'
                    },
                    children: [
                        ...generateFormChildRoutes(
                            NetaHungarikumBasicDataFormComponents.routePrefix
                            , NetaHungarikumBasicDataFormComponents.usedComponents
                        ),
                    ]
                },
            ]
        }
    ]
};