<template>
    <div class="new-attraction-container" v-show="showForm">
        <h1 class="main-heading">
            Új Vonzerő felvétele
        </h1>

        <a-divider />

        <a-steps :current="current" :status="stepStatus">
            <a-step
                    v-for="(item, index) in steps"
                    :key="item.title"
                    :title="item.title"
            >
                <a-icon
                        v-if="index === 2 && isAttractionSaving"
                        slot="icon"
                        type='loading'
                />
            </a-step>
        </a-steps>

        <a-form
                class="new-attraction-form"
                :form="form"
                layout="vertical"
        >
            <template v-if="current === 0">
                <h2>
                    Kötelező adatok megadása a vonzerőhöz.
                </h2>

                <a-form-item label="Vonzerő neve">
                    <a-textarea
                            :maxLength="25000"
                            v-decorator="[
                            'name',
                            {
                                rules: [
                                    {
                                      required: true,
                                      preserve: true,
                                      message: 'Kérjük töltse ki!'
                                    },
                                    {
                                      max: characterCounts.name.max,
                                      message: 'Maximum ' + characterCounts.name.max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur', 'keyup']
                            }
                        ]"
                            :autoSize="{minRows: 2}"
                            placeholder="Vonzerő neve"
                    />
                </a-form-item>

                <a-form-item
                        label="A vonzerő/létesítmény általános bemutatása, rövid leírása, kiemelendő értékei, története, fontosabb célcsoportjai stb."
                >
                    <Wysiwyg
                            :height="100"
                            v-decorator="[
                            'shortDescription',
                            {
                                rules: [
                                    {
                                      required: true,
                                      preserve: true,
                                      transform: (value) => {
                                        return stripTags(value);
                                      },
                                      min: characterCounts.shortDescription.min,
                                      max: characterCounts.shortDescription.max,
                                      message: 'Kérjük töltse ki! A leírás hosszának ' + characterCounts.shortDescription.min
                                      + ' és ' + characterCounts.shortDescription.max + ' között kell lennie.',
                                    }
                                ],
                                validateTrigger: ['blur', 'keyup']
                            }
                        ]"
                            v-on:keyup="characterCount"
                            v-on:change="characterCount"
                            placeholder="A vonzerő/létesítmény általános bemutatása, rövid leírása, kiemelendő értékei, története, fontosabb célcsoportjai stb."
                    />

                    <div class="character-count">
                        <strong
                                v-bind:class="{
                    'invalid': characterCounts.shortDescription.valid === false,
                    'valid': characterCounts.shortDescription.valid === true
                  }"
                        >
                            {{ characterCounts.shortDescription.current }}
                        </strong>

                        / {{ characterCounts.shortDescription.min }} - {{ characterCounts.shortDescription.max }}
                    </div>
                </a-form-item>

                <a-form-item label="Irányítószám">
                    <a-select
                            v-decorator="[
                            'address/zipCode/id',
                            {
                                rules: [
                                    {
                                    required: true,
                                    whitespace: true,
                                    message: 'Kérjük töltse ki!',
                                    type: 'integer'
                                  },
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
                            placeholder="Kezdjen gépelni"
                            showSearch
                            :showArrow="false"
                            :defaultActiveFirstOption="false"
                            :filterOption="false"
                            :style="{width: '100%'}"
                            v-on:search="zipCodeAutoComplete"
                            v-on:change="zipCodeChange"
                            v-on:dropdownVisibleChange="zipCodeAutoComplete"
                            :notFoundContent="zipCodeOptionsLoading ? undefined : null"
                    >
                        <a-spin v-if="zipCodeOptionsLoading" slot="notFoundContent" size="small" />

                        <a-select-option
                                v-for="option in zipCodeOptions"
                                :key="option.id"
                                :value="option.id"
                        >
                            {{ option.code }}
                        </a-select-option>
                    </a-select>
                </a-form-item>

                <a-form-item label="Település neve">
                    <a-select
                            v-decorator="[
                            'address/city/id',
                            {
                                rules: [
                                    {
                                    required: true,
                                    whitespace: true,
                                    message: 'Kérjük töltse ki!',
                                    type: 'integer'
                                  },
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
                            placeholder="Töltse ki az irányítószám mezőt!"
                            :showArrow="false"
                            :disabled="!cityOptions.length"
                            :defaultActiveFirstOption="true"
                            :filterOption="false"
                            :style="{width: '100%'}"
                            v-on:change="cityChange"
                            :notFoundContent="zipCodeOptionsLoading ? undefined : null"
                    >
                        <a-spin v-if="zipCodeOptionsLoading" slot="notFoundContent" size="small" />

                        <a-select-option
                                v-for="option in cityOptions"
                                :key="option.id"
                                :value="option.id"
                        >
                            {{ option.name }}
                        </a-select-option>
                    </a-select>
                </a-form-item>

                <a-form-item
                        label="Közterület neve"
                >
                    <a-input
                            :maxLength="25000"
                            :disabled="isChecked('address/addressNotRelevant')"
                            v-decorator="[
                            'address/address',
                            {
                                rules: [
                                    {
                                      required: !isChecked('address/addressNotRelevant'),
                                      whitespace: true,
                                      message: 'Kérjük töltse ki!'
                                    },
                                    {
                                      max: characterCounts['address/address'].max,
                                      message: 'Maximum ' + characterCounts['address/address'].max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
                            placeholder="Kérjük beírni (betűkkel)"
                    >
                    </a-input>

                    <a-checkbox
                            v-decorator="[
                        'address/addressNotRelevant',
                        {
                            rules: [{
                                required: false
                            }]
                        }
                    ]"
                            :checked="isChecked('address/addressNotRelevant')"
                            v-on:change="(event) => relevancyCheckboxChange(event, 'address/address')"
                    >
                        Nem releváns
                    </a-checkbox>
                </a-form-item>

                <a-form-item
                        label="Közterület típusa (utca, tér, út, dűlő stb.)"
                >
                    <a-input
                            :maxLength="25000"
                            :disabled="isChecked('address/addressTypeNotRelevant')"
                            v-decorator="[
                            'address/addressType',
                            {
                                rules: [
                                    {
                                      required: !isChecked('address/addressTypeNotRelevant'),
                                      whitespace: true,
                                      message: 'Kérjük töltse ki!'
                                    },
                                    {
                                      max: characterCounts['address/addressType'].max,
                                      message: 'Maximum ' + characterCounts['address/addressType'].max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
                            placeholder="Kérjük beírni (betűkkel)"
                    >
                    </a-input>

                    <a-checkbox
                            v-decorator="[
                        'address/addressTypeNotRelevant',
                        {
                            rules: [{
                                required: false
                            }]
                        }
                    ]"
                            :checked="isChecked('address/addressTypeNotRelevant')"
                            v-on:change="(event) => relevancyCheckboxChange(event, 'address/addressType')"
                    >
                        Nem releváns
                    </a-checkbox>
                </a-form-item>

                <a-form-item>
          <span slot="label">
              Házszám/helyrajzi szám
              <a-tooltip
                      title="Itt a házszámon kívül más is beírható (pl. helyrajzi szám vagy más egyéb, pontos azonosító, ami az adott ingatlan helyét pontosan meghatározza). Ha nincs ilyen pontos azonosító szám a címhez, akkor kérjük a mezőt üresen hagyni."
              >
                  <a-icon type="question-circle-o" />
              </a-tooltip>
          </span>

                    <a-input
                            :maxLength="25000"
                            :disabled="isChecked('address/addressNumberNotRelevant')"
                            v-decorator="[
                            'address/addressNumber',
                            {
                                rules: [
                                    {
                                      required: !isChecked('address/addressNumberNotRelevant'),
                                      whitespace: true,
                                      message: 'Kérjük töltse ki!'
                                    },
                                    {
                                      max: characterCounts['address/addressNumber'].max,
                                      message: 'Maximum ' + characterCounts['address/addressNumber'].max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
                            placeholder="Kérjük beírni (számokkal, betűkkel)"
                    >
                    </a-input>
                    <a-checkbox
                            v-decorator="[
                        'address/addressNumberNotRelevant',
                        {
                            rules: [{
                                required: false
                            }]
                        }
                    ]"
                            :checked="isChecked('address/addressNumberNotRelevant')"
                            v-on:change="(event) => relevancyCheckboxChange(event, 'address/addressNumber')"
                    >
                        Nem releváns
                    </a-checkbox>
                </a-form-item>

                <a-form-item>
          <span slot="label">
              A vonzerőt üzemeltető szervezet központi e-mail címe &nbsp;
              <a-tooltip
                      title="Amennyiben nem rendelkezik e-mail címmel az objektum, úgy kérjük, adja meg a Tourinform iroda központi e-mail címét!"
              >
                  <a-icon type="question-circle-o" />
              </a-tooltip>
          </span>

                    <a-input
                            type="email"
                            :maxLength="25000"
                            v-decorator="[
                            'centralContact/email',
                            {
                                rules: [
                                    {
                                      required: true,
                                      whitespace: true,
                                      type: 'email',
                                      message: 'Kérjük a megfelelő formátumban töltse ki!'
                                    },
                                    {
                                      max: characterCounts['centralContact/email'].max,
                                      message: 'Maximum ' + characterCounts['centralContact/email'].max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
                            placeholder="Kérjük az e-mail címet beírni!"
                    >
                    </a-input>
                </a-form-item>

                <a-form-item>
          <span slot="label">
              A vonzerő GPS koordinátája: szélességi fok

              <a-tooltip :title="coordinateTooltipLatitude">
                  <a-icon type="question-circle-o" />
              </a-tooltip>
          </span>

                    <a-input
                            :maxLength="25000"
                            v-on:change="(event) => formatCoordinate(event.target.value, 'address/lat')"
                            v-decorator="[
                            'address/lat',
                            {
                                rules: [
                                    {
                                      required: true,
                                      whitespace: true,
                                      pattern: /^4([-+]?)([\d]{1})(((\.)(\d+)))$/g,
                                      message: 'A koordináták formátuma helytelen!'
                                    },
                                    {
                                      type: 'float',
                                      message: 'Kérjük adjon meg valós GPS koordinátákat!',
                                      transform: (value) => {
                                        value = parseFloat(value);

                                        return isNaN(value) ? null: value;
                                      },
                                      min: 45.72,
                                      max: 48.60
                                    }
                                    ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
                            placeholder="Kérjük a koordinátát az alábbi formátumban adja meg: 47.507346"
                    >
                    </a-input>
                </a-form-item>

                <a-form-item>
          <span slot="label">
            A vonzerő GPS koordinátája: hosszúsági fok

            <a-tooltip :title="coordinateTooltipLongitude">
                <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>

                    <a-input
                            :maxLength="25000"
                            v-on:change="(event) => formatCoordinate(event.target.value, 'address/long')"
                            v-decorator="[
                            'address/long',
                            {
                                rules: [
                                    {
                                      required: true,
                                      whitespace: true,
                                      pattern: /^(1|2)(([-+]?)([\d]{1})((\.)(\d+))?)$/g,
                                      message: 'A koordináták formátuma helytelen!'
                                    },
                                    {
                                      type: 'float',
                                      message: 'Kérjük adjon meg valós GPS koordinátákat!',
                                      transform: (value) => {
                                        value = parseFloat(value);

                                        return isNaN(value) ? null: value;
                                      },
                                      min: 16.10,
                                      max: 23
                                    }],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
                            placeholder="Kérjük a koordinátát az alábbi formátumban adja meg: 19.046610"
                    >
                    </a-input>
                </a-form-item>

                <a-divider />

                <div class="steps-navigation-buttons">
                    <a-button
                            type="primary"
                            v-on:click="stepToCategories"
                    >
                        Tovább

                        <a-icon type="right" />
                    </a-button>
                </div>
            </template>

            <template v-if="current === 1">
                <h2>
                    Kategória kiválasztása
                </h2>

                <a-form-item label="Kategória">
                    <a-radio-group
                            v-decorator="['group/id',{
                rules:
                [
                  {
                    required: true,
                    preserve: true,
                    message: 'Kérjük válasszon!',
                    type: 'integer'
                  }
                ],
                    validateTrigger: 'blur'
                }
              ]"
                    >
                        <a-radio-button
                                v-for="category in categories"
                                :key="category.id"
                                :value="category.id"
                        >
                            {{ category.label }}

                            <a-icon :type="category.icon" />
                        </a-radio-button>
                    </a-radio-group>
                </a-form-item>

                <a-divider />

                <div class="steps-navigation-buttons">
                    <a-button-group>
                        <a-button
                                type="primary"
                                v-on:click="stepBackward"
                        >
                            Vissza

                            <a-icon type="left" />
                        </a-button>

                        <a-button
                                type="primary"
                                v-on:click="stepForward($event, ['group/id'])"
                        >
                            Tovább

                            <a-icon type="right" />
                        </a-button>
                    </a-button-group>
                </div>
            </template>

            <template v-if="current === 2">
                <h2>
                    Véglegesítés
                </h2>

                <div class="attraction-details-holder">
                    <a-divider orientation="left">
                        Vonzerő neve:
                    </a-divider>

                    <p>
                        {{ formFields.name }}
                    </p>

                    <template v-if="formFields['address/city/name']">
                        <a-divider orientation="left">
                            Vonzerő települése:
                        </a-divider>

                        <p>
                            {{ formFields['address/city/name'] }}
                        </p>
                    </template>

                    <template v-if="groupElement">
                        <a-divider orientation="left">
                            Vonzerő kategóriája:
                        </a-divider>

                        <p>
                            {{ groupElement.label }}

                            <a-icon :type="groupElement.icon" />
                        </p>
                    </template>
                </div>

                <a-divider />

                <div class="steps-navigation-buttons">
                    <a-button
                            type="primary"
                            v-on:click="stepBackward"
                    >
                        Vissza

                        <a-icon type="left" />
                    </a-button>

                    <a-button
                            type="primary"
                            v-on:click="stepFinal"
                            :loading="isAttractionSaving"
                    >
                        Mentés és tovább

                        <a-icon type="right" />
                    </a-button>
                </div>
            </template>

            <template v-if="current === 3">
                <h2>
                    Vonzerő sikeresen felvéve
                </h2>

                <a-divider />

                <div class="steps-navigation-buttons">
                    <router-link :to="{name: 'neta-list'}">
                        <a-button type="primary">
                            Vissza a listához

                            <a-icon type="table" />
                        </a-button>
                    </router-link>

                    <router-link :to="{name: 'neta-basic-attraction-attraction-data-form', params: {id: attractionId}}">
                        <a-button type="primary">
                            Tovább a szerkesztésre

                            <a-icon type="edit" />
                        </a-button>
                    </router-link>
                </div>
            </template>
        </a-form>
    </div>
</template>

<script>
import debounce from 'lodash.debounce';
import axios from "axios";
import Wysiwyg from '@/components/shared/Wysiwyg.vue';
import { mapState } from "vuex";

export default {
    created() {
        let component = this;

        this.form = this.$form.createForm(this, {
            onValuesChange(_, values) {
                let currentValues = component.formFields;

                component.formFields = { ...currentValues, ...values };
            },
        });
    },

    mounted() {
        this.loadCategories();
        this.createEmptyAttraction();
    },

    components: { Wysiwyg },

    data() {
        return {
            showForm: false,
            current: 0,
            steps: [
                {
                    title: 'Alapadatok',
                },
                {
                    title: 'Kategóriák',
                },
                {
                    title: 'Véglegesítés',
                },
                {
                    title: 'Sikeres felvitel'
                }
            ],

            formFields: {
                'name': '',
                'shortDescription': '',
                'address/zipCode/id': null,
                'address/city/name': '',
                'address/address': '',
                'address/addressNotRelevant': 0,
                'address/addressType': '',
                'address/addressTypeNotRelevant': 0,
                'address/addressNumber': '',
                'address/addressNumberNotRelevant': 0,
                'address/lat': '',
                'address/long': '',
                'centralContact/type': 'central',
                'centralContact/email': '',
                'group/id': null,
                'office/id': null
            },

            casts: {
                'address/addressNotRelevant': 'int',
                'address/addressTypeNotRelevant': 'int',
                'address/addressNumberNotRelevant': 'int'
            },

            ignoredFields: [
                'address/city/name'
            ],

            characterCounts: {
                'name': {
                    valid: null,
                    current: 0,
                    max: 200
                },
                'shortDescription': {
                    valid: null,
                    current: 0,
                    min: 200,
                    max: 255,
                },
                'address/address': {
                    max: 200
                },
                'address/addressType': {
                    max: 50
                },
                'address/addressNumber': {
                    max: 50
                },
                'centralContact/email': {
                    max: 200
                },
            },

            coordinateTooltipLatitude: 'Kérjük a koordinátát az alábbi formátumban adja meg: 47.507346 Kérjük annak a helynek a GPS koordinátáit beírni, ahol a vonzerő pontosan található VAGY annak a helynek a koordinátáit, ahol a vonzerőhöz legközelebb biztonságosan lehet parkolni (pl. egy tó esetében) /Segítségül: a google.maps honlapon: 1. jobb egér gombbal kattintás a kívánt helyre, 2. "mi található itt?" lehetőség kiválasztása a lenyíló menüből, 3. az oldal alján megjelenik a kártya a koordinátákkal (alsó sorban) - ezt az adatsor kérjük ide beírni.',
            coordinateTooltipLongitude: 'Kérjük a koordinátát az alábbi formátumban adja meg: 19.046610! Kérjük annak a helynek a GPS koordinátáit beírni, ahol a vonzerő pontosan található VAGY annak a helynek a koordinátáit, ahol a vonzerőhöz legközelebb biztonságosan lehet parkolni (pl. egy tó esetében) /Segítségül: a google.maps honlapon: 1. jobb egér gombbal kattintás a kívánt helyre, 2. "mi található itt?" lehetőség kiválasztása a lenyíló menüből, 3. az oldal alján megjelenik a kártya a koordinátákkal (alsó sorban) - ezt az adatsor kérjük ide beírni.',

            attractionId: '',

            zipCodeOptionsLoading: false,
            zipCodeOptions: [],
            cityOptions: [],

            categories: [],

            isAttractionSaving: false,
            stepStatus: 'process'
        }
    },

    computed: {
        ...mapState({
            selectedRole: state => state.user.selectedRole
        }),

        groupElement() {
            return this.categories
                ? this.categories.find(element => element.id === this.formFields['group/id'])
                : null;
        },

        isOfficeSpecialist() {
            return this.$store.getters.hasRoleAvailable(['ROLE_OFFICE_SPECIALIST']);
        },

        isOfficeEmployee() {
            return this.$store.getters.hasRoleAvailable(['ROLE_OFFICE_EMPLOYEE', 'ROLE_OFFICE_LEADER'])
        },

        isMobileApp() {
            return this.$store.getters.hasRoleAvailable(['ROLE_MOBILE_APP']);
        },
    },

    methods: {
        /**
         * @return {{selectedRole: {string}}}
         */
        getMetaData() {
            return {
                selectedRole: this.selectedRole,
            };
        },

        /**
         * @return {int}
         */
        getOfficeId() {
            let userOffice = this.$store.state.user.office;

            if (!userOffice.id) {
                return null;
            }

            return userOffice.id;
        },

        createEmptyAttraction() {
            let locale = this.$store.state.attraction.locale;

            let data = {
                "name": "Új Vonzerő",
                "office/id": this.getOfficeId(),
                "group/groupType": "attraction",
                "meta": this.getMetaData(),
                "webPublished": this.isMobileApp ? 0 : 1,
                "mobilePublished": this.isMobileApp ? 1 : 0
            };

            axios.post('/api/v1/companies/1/me/attractions?locale=' + locale, data)
                .then(response => {
                    if (response.data.isValid) {
                        this.attractionId = response.data.resourceId

                        this.$message.success(
                            'A vonzerő sikeresen létrehozva!'
                        );

                        this.$router.push({
                            name: 'neta-basic-attraction-attraction-data-form',
                            params: { id: this.attractionId }
                        });
                    } else {
                        this.$message.error(
                            'Sikertelen létrehozás!'
                        );
                    }

                    this.isAttractionSaving = false;
                })
                .catch(error => {
                    this.isAttractionSaving = false;

                    this.$message.error(
                        'Sikertelen létrehozás!'
                    );
                });
        },

        loadCategories() {
            axios.get('/api/v1/companies/1/attractions/groups', {
                params: {
                    locale: this.$store.state.attraction.locale,
                    groupType: 'attraction',
                    order: 'weight ASC',
                    limit: 25
                }
            }).then(response => {
                this.categories = response.data;
            }).catch(() => {
                this.$message.error(
                    'Sikertelen opció betöltés!'
                );
            });
        },

        stripTags(text) {
            var dom = new DOMParser().parseFromString(text, 'text/html');

            return dom.body.textContent;
        },

        /**
         * Set fields value from internal prop
         */
        populateFormFields() {
            const populateData = {};

            Object.keys(this.form.getFieldsValue()).forEach(fieldName => {
                populateData[fieldName] = this.formFields[fieldName] || null;
            });

            this.form.setFieldsValue(populateData)
        },

        /**
         * Step forward
         */
        stepForward(event, fieldsToValidate) {
            this.validateCheck(fieldsToValidate)
                .then(valid => {
                    this.current++;

                    this.$nextTick(() => {
                        this.populateFormFields();
                    });
                })
                .catch(invalid => {
                    this.$message.warning(
                        'Az űrlap hibákat tartalmaz!'
                    );
                });
        },

        /**
         * Show categories step
         */
        stepToCategories() {
            const currentFormFields = Object.keys(this.form.getFieldsValue());

            this.validateCheck(currentFormFields)
                .then(valid => {
                    this.current++;

                    this.$nextTick(() => {
                        this.populateFormFields();
                    });
                })
                .catch(invalid => {
                    this.$message.warning(
                        'Az űrlap hibákat tartalmaz!'
                    );
                });
        },

        /**
         * Step back
         */
        stepBackward(event) {
            event.preventDefault();

            this.current--;

            this.$nextTick(() => {
                this.populateFormFields();
            });
        },

        /**
         * Create attraction
         */
        stepFinal(event) {
            event.preventDefault();

            let data = { ...this.formFields };
            let locale = this.$store.state.attraction.locale;

            this.ignoredFields.forEach((ignoredField) => {
                delete data[ignoredField];
            });

            for (let fieldName in data) {
                let value = data[fieldName];
                let allowNull = /^\?/.test(this.casts[fieldName]);

                switch (this.casts[fieldName]) {
                    case 'float':
                    case '?float':
                        if (value === true) {
                            value = 1;
                        } else {
                            value = parseFloat(value);
                        }

                        if (isNaN(value)) {
                            value = allowNull ? null : 0;
                        }

                        break;

                    case 'int':
                    case '?int':
                    case 'integer':
                    case '?integer':
                        if (value === true) {
                            value = 1;
                        } else {
                            value = parseInt(value);
                        }

                        if (isNaN(value)) {
                            value = allowNull ? null : 0;
                        }
                }

                data[fieldName] = value;
            }

            this.isAttractionSaving = true;

            axios.post('/api/v1/companies/1/me/attractions?locale=' + locale, data)
                .then(response => {
                    if (response.data.isValid) {
                        this.attractionId = response.data.resourceId

                        this.current++;

                        this.$message.success(
                            'A vonzerő sikeresen létrehozva!'
                        );
                    } else {
                        this.stepStatus = 'error';

                        this.$message.error(
                            'Sikertelen létrehozás!'
                        );
                    }

                    this.isAttractionSaving = false;
                })
                .catch(error => {
                    this.isAttractionSaving = false;

                    this.stepStatus = 'error';

                    this.$message.error(
                        'Sikertelen létrehozás!'
                    );
                });
        },

        /**
         * @return bool
         */
        validateCheck(fieldKeys) {
            if (fieldKeys.length < 0) {
                return true;
            }

            return new Promise((resolve, reject) => {
                this.form.validateFieldsAndScroll(
                    fieldKeys,
                    (errors, values) => {
                        if (errors) {
                            reject(false);
                        } else {
                            const formValues = {
                                ...values
                            };

                            this.formFields = {
                                ...this.formFields,
                                ...formValues
                            };

                            resolve(true);
                        }
                    });
            });
        },

        /**
         * Auto-complete zip code list from API
         *
         * @param {string} code
         *
         * Throttled: 250ms when code is not empty
         */
        zipCodeAutoComplete: function (code) {
            var self = this;

            let apiUrl = '/api/v1/companies/1/me/attractions/zipcode';

            if (this.isOfficeEmployee) {
                apiUrl += '/by-office';
            } else if (this.isOfficeSpecialist) {
                apiUrl += '/by-areas';
            }

            debounce(function (code) {
                self.zipCodeOptions = [];
                self.zipCodeOptionsLoading = true;

                axios.get(apiUrl, {
                    params: {
                        locale: self.$store.state.attraction.locale,
                        code: (true !== code && code) ? code + '*' : null,
                        order: 'code ASC',
                        limit: 200
                    }
                }).then(response => {
                    self.zipCodeOptions = response.data;
                    self.zipCodeOptionsLoading = false;
                });
            }, code !== true ? 250 : 0)(code)
        },

        /**
         * Load city name from zip code data and fill form field
         *
         * @param {int} zipCodeId
         */
        zipCodeChange: function (zipCodeId) {
            this.zipCodeOptions.forEach((zipCode) => {

                if (zipCode.id === parseInt(zipCodeId)) {
                    this.cityOptions = zipCode.cities;

                    if (zipCode.cities.length) {
                        this.$nextTick(() => {
                            this.formFields['office/id'] = zipCode.cities[0].office.id;
                            this.formFields['address/city/name'] = zipCode.cities[0].name;

                            this.form.setFieldsValue({
                                'address/city/id': zipCode.cities[0].id
                            });
                        });
                    }
                }
            });
        },

        /**
         * Load city name from zip code data and fill form field
         *
         * @param {int} cityId
         */
        cityChange: function (cityId) {
            this.$nextTick(() => {
                this.cityOptions.forEach((city) => {
                    if (city.id === parseInt(cityId)) {
                        this.formFields['office/id'] = city.office.id;
                        this.formFields['address/city/name'] = city.name;
                    }
                });
            });
        },

        /**
         * @param value
         * @param key
         */
        formatCoordinate(value, key) {
            if (value === null) {
                value = '';
            }

            this.$nextTick(() => {
                this.form.setFieldsValue({
                    [key]: value.replace(/[^0-9\. ]/g, '')
                        .replace(/\./g, (match, nth, string) => string.indexOf(match) === nth ? match : '')
                        .replace(/(?<!,)\s/g, '')
                })
            })
        },

        /**
         * @param value
         * @param key
         */
        formatNumber(value, key) {
            if (value === null) {
                value = '';
            }

            this.$nextTick(() => {
                this.form.setFieldsValue({
                    [key]: value.replace(/[^0-9]/g, '')
                })
            })
        },

        /**
         * @param {Event} event
         */
        characterCount(event) {
            let valid = true;
            let field = event.target.id;
            let length = this.stripTags(event.target.value).length;

            if (typeof this.characterCounts[field].min !== 'undefined') {
                valid = valid && length >= this.characterCounts[field].min;
            } else if (length === 0) {
                valid = null;
            }

            if (typeof this.characterCounts[field].max !== 'undefined') {
                valid = valid && length <= this.characterCounts[field].max;
            }

            this.characterCounts[field].current = length;
            this.characterCounts[field].valid = valid;
        },

        /**
         * @param {string} fieldName
         *
         * @return {boolean}
         */
        isChecked(fieldName) {
            return !!this.form.getFieldValue(fieldName);
        },

        /**
         * @param event
         * @param fieldName
         */
        relevancyCheckboxChange(event, fieldName) {
            this.$nextTick(() => {
                this.form.validateFields([fieldName]);
            })
        },

        /**
         * @return {function(*): *}
         */
        getParentTriggerNode() {
            return triggerNode => triggerNode.parentNode
        },
    }
}
</script>

<style lang="scss">
.character-count {
  text-align: right;
  font-size: 0.7rem;

  .invalid {
    color: #F5222D;
  }

  .valid {
    color: #5BC7AA;
  }
}

.new-attraction-container {
  max-width: 800px;
  margin: auto;
  padding: 30px;


  .new-attraction-form {
    margin-top: 30px;

    .ant-radio-button-wrapper {
      margin: 8px;
    }
  }

  .steps-navigation-buttons {
    button {
      margin-right: 15px;
      margin-bottom: 5px;
    }
  }

  .attraction-details-holder {
    p {
      margin-left: 8px;
    }
  }
}
</style>
