<template>
  <div>
    <router-link :to="{name: 'neta-list-multi-event'}">
      <a-button
          v-show="!collapsed"
          class="attraction-back-button"
          size="large"
          icon="table"
          type="default"
      >Vissza a listához</a-button>

      <a-button
          v-show="collapsed"
          class="attraction-back-button"
          size="large"
          icon="table"
          type="default"
      />
    </router-link>

    <div class="attraction-data" v-if="attraction" v-show="!collapsed">
      <div class="attraction-name">
        <strong>{{ attraction.name }}</strong>
      </div>

      <div class="attraction-id">
        Azonosító: <strong>{{ attraction.id }}</strong>
      </div>

      <div class="attraction-city" v-if="attraction.address && attraction.address.city">
        Település: <strong>{{ attraction.address.city.name }}</strong>
      </div>

      <div class="attraction-status">
        Státusz: <strong>{{ statusNames[attraction.status] }}</strong>
      </div>

      <div class="attraction-related" v-if="relatedAttractions.length">
        Kapcsolódó vonzerő{{ relatedAttractions.length > 1 ? 'k': ''}}:

        <ul>
          <li v-for="relatedAttraction in relatedAttractions">
            <strong>{{ relatedAttraction.name }}</strong>

            <router-link :to="getAttractionEditUrl(relatedAttraction)">
              <a-button
                  size="small"
                  icon="edit"
                  type="primary"
                  class="action-button"
              >
              </a-button>
            </router-link>
          </li>
        </ul>
      </div>

      <div class="attraction-related" v-if="relatedEvents.length">
        Kapcsolódó esemény{{ relatedEvents.length > 1 ? 'ek': ''}}:

        <ul>
          <li v-for="relatedAttraction in relatedEvents">
            <strong>{{ relatedAttraction.name }}</strong>

            <router-link :to="getEventEditUrl(relatedAttraction)">
              <a-button
                  size="small"
                  icon="edit"
                  type="primary"
                  class="action-button"
              >
              </a-button>
            </router-link>
          </li>
        </ul>
      </div>
    </div>

    <a-menu
        mode="inline"
        theme="light"
        :style="{height: '100%',maxWidth: '300px'}"
        :defaultSelectedKeys="selectedMenuItem()"
        :defaultOpenKeys="['neta-basic-multi-event-data']"
    >

      <!--      <a-sub-menu key="neta-basic-data" v-if="currentType === 'basic'">-->
      <!--      <span slot="title">-->
      <!--          <a-icon type="snippets" />-->
      <!--          <span>Alapadatok</span>-->
      <!--      </span>-->

      <a-menu-item
          v-for="subForm in getCategoryRoutes('neta-basic-multi-event-data')"
          :key="subForm.name"
      >
        <router-link :to="{name: subForm.name}">
          <a-icon
              v-if="typeof formIdMap[subForm.path] !== 'undefined' && loading[formIdMap[subForm.path]][attraction.id]"
              type="sync"
              :style="{color: 'black'}"
              :spin="true"
          />

          <a-icon
              v-else-if="typeof formIdMap[subForm.path] !== 'undefined' && drafts[formIdMap[subForm.path]][attraction.id]"
              type="edit"
              :style="{color: 'orange'}"
          />

          <a-icon
              v-else-if="getTabIcon(subForm.path)"
              :type="getTabIcon(subForm.path).icon"
              :style="{color: getTabIcon(subForm.path).color}"
          />

          <span>
              {{ subForm.meta.menuName }}
            </span>
        </router-link>
      </a-menu-item>
      <!--      </a-sub-menu>-->
    </a-menu>
  </div>
</template>

<script>
import invert from 'lodash/invert';

export default {
  data() {
    return {
      formsMap: {
        ATTRACTION_DATA: 'attraction-data-form',
        DESCRIPTION: 'description-form'
      }
    }
  },

  props: {
    collapsed: {
      type: Boolean,
      default: false
    },

    statusNames: {
      type: Object,
      required: true
    }
  },

  computed: {
    /**
     * @returns {Object}
     */
    attraction() {
      return this.$store.state.attraction.current;
    },

    relatedAttractions() {
      const relatedAttractions = this.attraction.relatedAttractions;
      const related = [];

      relatedAttractions.forEach((attraction) => {
        if (attraction.group.groupType === 'attraction' && attraction.status !== 6) {
          related.push(attraction);
        }
      });

      return related;
    },

    relatedEvents() {
      const relatedAttractions = this.attraction.relatedAttractions;
      const related = [];

      relatedAttractions.forEach((attraction) => {
        if (attraction.group.groupType === 'event' && attraction.status !== 6) {
          related.push(attraction);
        }
      });

      return related;
    },

    /**
     * @returns {Boolean}
     */
    loading() {
      return this.$store.state.forms.loading.attraction;
    },

    /**
     * @returns {Object}
     */
    drafts() {
      return this.$store.state.forms.drafts.attraction;
    },

    /**
     * @returns {Object}
     */
    formIdMap() {
      return invert(this.formsMap);
    },

    /**
     * @returns {string}
     */
    currentType() {
      return this.$route.matched.find(route => route.meta.attractionType).meta.attractionType;
    },

    /**
     * @returns {boolean}
     */
    pricesVisible() {
      return this.$store.state.pricesVisible;
    },

    /**
     * @returns {{}}
     */
    formTabState() {
      let tabStatuses = {};

      for (let tabCode in this.formsMap) {
        tabStatuses[this.formsMap[tabCode]] = 0;
      }

      const tabs = this.$store.state.attraction.current.tabs;

      tabs.forEach((tab) => {
        if (typeof this.formsMap[tab.code] === 'undefined') {
          return;
        }

        tabStatuses[this.formsMap[tab.code]] = tab.status;
      });

      return tabStatuses;
    }
  },

  methods: {
    /**
     * @returns {[]}
     */
    getCategoryRoutes(categoryName) {
      const netaDetailsRoutes = () => {
        const netaRoutes = this.$router.options.routes.find(item => {
          return item.name === 'neta';
        });

        return netaRoutes.children.find(item => {
          return item.name === 'neta-multi-event-details';
        });
      };

      const category = netaDetailsRoutes().children.find(item => {
        return item.name === categoryName;
      });

      if (category) {
        return category.children.filter(item => {
          return true;
        });
      }

      return [];
    },

    /**
     * @param attraction
     * @returns {string}
     */
    getAttractionEditUrl(attraction) {
      return {
        name: 'neta-basic-attraction-attraction-data-form',
        params: {
          id: attraction.id
        }
      }
    },

    /**
     *
     * @param {object} attraction
     * @returns {{name: string, params: {id}}}
     */
    getEventEditUrl(attraction) {
      return {
        name: 'neta-basic-event-attraction-data-form',
        params: {
          id: attraction.id
        }
      }
    },

    /**
     * @returns [{string}]
     */
    selectedMenuItem() {
      return [this.$route.name];
    },

    /**
     * @param {string} tabName
     * @returns {{color: string, icon: string}|null}
     */
    getTabIcon(tabName) {
      switch (tabName) {
          case 'teka':
          if (!this.attraction.countTekaEntities) {
              return {
                  color: '#FFD300',
                  icon: 'question-circle'
              };
          } else {
              return {
                  color: 'green',
                  icon: 'check-square'
              };
          }

        default:
          const status = this.formTabState[tabName];

          if (status === undefined) {
            return null
          }

          switch (status) {
            case 1: // Waiting for approval
              return {
                color: '#ffd300',
                icon: 'question-circle'
              };

            case 2: // Approved
              return {
                color: 'green',
                icon: 'check-circle'
              };

            case 3: // Ready to publish
              return {
                color: 'green',
                icon: 'check-square'
              };
          }

          // New
          return {
            color: 'grey',
            icon: 'minus-circle'
          };
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.attraction-back-button {
  margin: 15px;
}

.attraction-data {
  width: 300px;
  padding: 15px;
  background: white;
  word-wrap: break-word;
  overflow: hidden;
}


.attraction-related {
  a {
    margin-left: 5px;
  }
}

.empty-circle-icon {
  width: 15px;
  height: 15px;
  display: inline-block;
  border: 3px solid white;
  margin-right: 8px;
  border-radius: 50%;
  margin-bottom: -2px;
  box-shadow: 0 0 2px 0 black, inset 0 0 2px black;
}
</style>
