import HomeView from '@/views/HomeView.vue';
import WelcomeContentComponent from '@/components/welcome/WelcomeContentComponent.vue';
import LoginContentComponent from '@/components/login/LoginContentComponent.vue';
import ForgottenPasswordComponent from "@/components/login/ForgottenPasswordComponent";
import PasswordResetComponent from "@/components/login/PasswordResetComponent";

export const routes = {
    path: '/',
    name: 'home',
    component: HomeView,
    redirect: { name: 'welcome' },
    children: [
        {
            path: 'welcome',
            name: 'welcome',
            component: WelcomeContentComponent
        },
        {
            path: 'login',
            name: 'login',
            component: LoginContentComponent,
        },
        {
            path: 'forgotten-password',
            name: 'forgotten-password',
            component: ForgottenPasswordComponent,
        },
        {
            path: 'password-change/:hash',
            name: 'password-reset',
            component: PasswordResetComponent,
        }
    ]
};