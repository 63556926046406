import {
    SET_CURRENT_HUNGARIKUM
} from "@/mutation_types";
import { REFRESH_HUNGARIKUM } from "@/action_types";
import axios from "axios";

const state = {
    current: null,
    languages: [],
    sidebarCollapsed: false,
    statusNames: {
        '0': 'Inaktív (rejtett)',
        '1': 'Aktív (publikált)',
    },
};

const getters = {
    /**
     * @param state
     * @return {object}
     */
    getCurrentHungarikum(state) {
        return state.current;
    },
};


const mutations = {
    [SET_CURRENT_HUNGARIKUM](state, payload) {
        state.current = payload.hungarikum;
    },
};

const actions = {
    [REFRESH_HUNGARIKUM]({ commit }, payload) {
        return axios.get(`/api/v1/companies/1/attractions/hungarikum/${payload.hungarikumId}`, {
            params: {
                envelope: true
            }
        }).then(response => {
            commit({
                type: SET_CURRENT_HUNGARIKUM,
                hungarikum: response.data.content
            });
        });
    },
};

export default {
    state,
    getters,
    mutations,
    actions
}