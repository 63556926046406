var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-form-container" },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.formDataLoading } },
        [
          _c(
            "a-form",
            {
              attrs: { form: _vm.form, layout: "vertical" },
              on: { submit: _vm.submit }
            },
            [
              _c(
                "a-form-item",
                [
                  _vm.showSaveButton
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            "html-type": "submit",
                            loading: _vm.submitLoading
                          }
                        },
                        [_vm._v("\n          Mentés\n        ")]
                      )
                    : _vm._e(),
                  _vm.hasChanged()
                    ? _c(
                        "a-button",
                        {
                          attrs: { type: "secondary", "html-type": "button" },
                          on: { click: _vm.resetForm }
                        },
                        [
                          _c("a-icon", {
                            attrs: { type: "undo", theme: "outlined" }
                          }),
                          _vm._v("\n\n          Piszkozat törlése\n        ")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.currentTab
                    ? _c("span", { staticClass: "last-modification" }, [
                        _vm._v("\n          Utolsó mentés: "),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm
                                .moment(_vm.currentTab.modificationTime)
                                .format(_vm.timeStampFormat)
                                .toString()
                            )
                          )
                        ]),
                        _vm._v("  "),
                        _vm.currentTab.user
                          ? _c("span", [
                              _vm._v(
                                "(" + _vm._s(_vm.currentTab.user.userName) + ")"
                              )
                            ])
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm.submitResult.show
                    ? _c("a-alert", {
                        style: {
                          marginTop: "10px"
                        },
                        attrs: {
                          message: _vm.submitResult.message,
                          type: _vm.submitResult.type,
                          showIcon: "",
                          closable: "",
                          afterClose: function() {
                            return (_vm.submitResult.show = false)
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c("a-divider", { attrs: { orientation: "left" } }, [
                _vm._v("\n        VONZERŐ KÖZZÉTÉTELE\n      ")
              ]),
              _vm.isNotMobile
                ? _c(
                    "a-form-item",
                    { attrs: { label: "Megjelenítés weben" } },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "webPublished",
                                {
                                  rules: [
                                    {
                                      required: false,
                                      message: "Kérjük töltse ki!"
                                    }
                                  ]
                                }
                              ],
                              expression:
                                "[\n                      'webPublished',\n                      {\n                          rules: [{\n                              required: false,\n                              message: 'Kérjük töltse ki!'\n                          }]\n                      }\n                  ]"
                            }
                          ],
                          attrs: {
                            disabled: _vm.isMobileReadonly,
                            placeholder: "Kérjük válasszon!"
                          }
                        },
                        [
                          _c("a-select-option", { attrs: { value: 1 } }, [
                            _vm._v("Igen")
                          ]),
                          _c("a-select-option", { attrs: { value: 0 } }, [
                            _vm._v("Nem")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-item",
                { attrs: { label: "Megjelenítés mobilon" } },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "mobilePublished",
                            {
                              rules: [
                                {
                                  required: false,
                                  message: "Kérjük töltse ki!"
                                }
                              ]
                            }
                          ],
                          expression:
                            "[\n                      'mobilePublished',\n                      {\n                          rules: [{\n                              required: false,\n                              message: 'Kérjük töltse ki!'\n                          }]\n                      }\n                  ]"
                        }
                      ],
                      attrs: { placeholder: "Kérjük válasszon!" }
                    },
                    [
                      _c("a-select-option", { attrs: { value: 1 } }, [
                        _vm._v("Igen")
                      ]),
                      _c("a-select-option", { attrs: { value: 0 } }, [
                        _vm._v("Nem")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c("a-divider", { attrs: { orientation: "left" } }, [
                _vm._v("\n        VONZERŐ NEVE\n      ")
              ]),
              _c(
                "a-form-item",
                { attrs: { label: "Vonzerő neve", selfUpdate: true } },
                [
                  _c("a-textarea", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "name",
                          {
                            rules: [
                              {
                                required: true,
                                preserve: true,
                                message: "Kérjük töltse ki!"
                              },
                              {
                                max: _vm.characterCounts.name.max,
                                message:
                                  "Maximum " +
                                  _vm.characterCounts.name.max +
                                  " karakter engedélyezett."
                              }
                            ],
                            validateTrigger: ["blur", "keyup"]
                          }
                        ],
                        expression:
                          "[\n                          'name',\n                          {\n                              rules: [\n                                  {\n                                    required: true,\n                                    preserve: true,\n                                    message: 'Kérjük töltse ki!'\n                                  },\n                                  {\n                                    max: characterCounts.name.max,\n                                    message: 'Maximum ' + characterCounts.name.max + ' karakter engedélyezett.',\n                                  }\n                              ],\n                              validateTrigger: ['blur', 'keyup']\n                          }\n                      ]"
                      }
                    ],
                    attrs: {
                      maxLength: 25000,
                      disabled: _vm.isMobileReadonly,
                      autoSize: { minRows: 2 },
                      placeholder: "Vonzerő neve"
                    }
                  })
                ],
                1
              ),
              _vm._l(_vm.translatedFormFields["name"], function(value, locale) {
                return _c(
                  "div",
                  { key: "name-" + locale },
                  [
                    value !== null
                      ? _c("a-row", [
                          _c(
                            "div",
                            [
                              _c(
                                "a-form-item",
                                { attrs: { selfUpdate: true } },
                                [
                                  _c(
                                    "div",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [
                                      _vm._v(
                                        "\n                Nyelv\n              "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a-select",
                                    {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value: [
                                            _vm.getTranslatedLocaleName(
                                              "name",
                                              locale
                                            ),
                                            {
                                              initialValue: locale,
                                              rules: [
                                                {
                                                  required: false
                                                }
                                              ]
                                            }
                                          ],
                                          expression:
                                            "[\n                              getTranslatedLocaleName('name', locale),\n                              {\n                                  initialValue: locale,\n                                  rules: [{\n                                      required: false,\n                                  }]\n                              }\n                          ]"
                                        }
                                      ],
                                      ref: _vm.getTranslatedLocaleName(
                                        "name",
                                        locale
                                      ),
                                      refInFor: true,
                                      staticStyle: { width: "90%" },
                                      attrs: {
                                        getPopupContainer: _vm.getParentTriggerNode(),
                                        disabled: _vm.isTranslationEditDisabled,
                                        filterOption: _vm.filterSelectNames
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.updateTranslatedFormField(
                                            "name"
                                          )
                                        }
                                      }
                                    },
                                    _vm._l(_vm.languages, function(language) {
                                      return _c(
                                        "a-select-option",
                                        {
                                          key: language.name,
                                          attrs: {
                                            value: language.locale,
                                            disabled: !_vm.isLanguageAvailable(
                                              "name",
                                              language.locale
                                            )
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                language.name.toLowerCase()
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    }),
                                    1
                                  ),
                                  _vm.isAdmin
                                    ? _c(
                                        "a-tooltip",
                                        { attrs: { title: "Mező törlése" } },
                                        [
                                          _c("a-icon", {
                                            staticClass:
                                              "dynamic-delete-button",
                                            attrs: {
                                              type: "delete",
                                              theme: "filled"
                                            },
                                            on: {
                                              click: function() {
                                                return _vm.deleteTranslatedFormField(
                                                  "name",
                                                  locale
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "a-form-item",
                                { attrs: { selfUpdate: true } },
                                [
                                  _c(
                                    "span",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [
                                      _vm._v(
                                        "\n                Vonzerő neve " +
                                          _vm._s(_vm.getLanguageName(locale)) +
                                          " nyelven\n              "
                                      )
                                    ]
                                  ),
                                  _c("a-textarea", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          _vm.getTranslatedFieldName(
                                            "name",
                                            locale
                                          ),
                                          {
                                            initialValue: value,
                                            rules: [
                                              {
                                                required: false,
                                                preserve: true,
                                                message: "Kérjük töltse ki!"
                                              },
                                              {
                                                max:
                                                  _vm.characterCounts[
                                                    _vm.getTranslatedFieldName(
                                                      "name",
                                                      locale
                                                    )
                                                  ].max,
                                                message:
                                                  "Maximum " +
                                                  _vm.characterCounts[
                                                    _vm.getTranslatedFieldName(
                                                      "name",
                                                      locale
                                                    )
                                                  ].max +
                                                  " karakter engedélyezett."
                                              }
                                            ],
                                            validateTrigger: ["blur", "keyup"]
                                          }
                                        ],
                                        expression:
                                          "[\n                                  getTranslatedFieldName('name', locale),\n                                  {\n                                    initialValue: value,\n                                    rules: [\n                                        {\n                                          required: false,\n                                          preserve: true,\n                                          message: 'Kérjük töltse ki!'\n                                        },\n                                        {\n                                          max: characterCounts[getTranslatedFieldName('name', locale)].max,\n                                          message: 'Maximum ' + characterCounts[getTranslatedFieldName('name', locale)].max + ' karakter engedélyezett.',\n                                        }\n                                    ],\n                                    validateTrigger: ['blur', 'keyup']\n                                }\n                              ]"
                                      }
                                    ],
                                    ref: _vm.getTranslatedFieldName(
                                      "name",
                                      locale
                                    ),
                                    refInFor: true,
                                    attrs: {
                                      maxLength: 25000,
                                      disabled: false,
                                      readOnly: _vm.isTranslationEditDisabled,
                                      autoSize: { minRows: 2 }
                                    },
                                    on: {
                                      blur: function($event) {
                                        return _vm.updateTranslatedFormField(
                                          "name"
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                )
              }),
              _c(
                "a-form-item",
                [
                  _c(
                    "a-button",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        type: "dashed",
                        disabled:
                          _vm.isOfficeEmployee ||
                          !_vm.getFirstAvailableLanguage("name") ||
                          _vm.isMobileReadonly
                      },
                      on: {
                        click: function($event) {
                          return _vm.addTranslatedFormField("name")
                        }
                      }
                    },
                    [
                      _c("a-icon", { attrs: { type: "plus" } }),
                      _vm._v(
                        "\n\n          Idegennyelvű név hozzáadása\n        "
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "Kategória" } },
                [
                  _c(
                    "a-radio-group",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "group/id",
                            {
                              rules: [
                                {
                                  required: true,
                                  preserve: true,
                                  message: "Kérjük válasszon!",
                                  type: "integer"
                                }
                              ],
                              validateTrigger: ["blur", "change"]
                            }
                          ],
                          expression:
                            "['group/id', {\n              rules:\n              [\n                {\n                  required: true,\n                  preserve: true,\n                  message: 'Kérjük válasszon!',\n                  type: 'integer'\n                }\n              ],\n                  validateTrigger: ['blur', 'change']\n              }\n            ]"
                        }
                      ],
                      on: {
                        change: function($event) {
                          return _vm.loadOptions("category/id", "group/id")
                        }
                      }
                    },
                    _vm._l(_vm.options["group/id"], function(category) {
                      return _c(
                        "a-radio-button",
                        {
                          key: category.id,
                          attrs: {
                            value: category.id,
                            disabled: _vm.isMobileReadonly
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(category.label) +
                              "\n\n            "
                          ),
                          _c("a-icon", { attrs: { type: category.icon } })
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "Alkategória" } },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "category/id",
                            {
                              rules: [
                                {
                                  required: _vm.isNotMobile,
                                  message: "Kérjük töltse ki!"
                                }
                              ],
                              validateTrigger: ["blur", "change"]
                            }
                          ],
                          expression:
                            "['category/id', {\n                          rules: [{\n                              required: isNotMobile,\n                              message: 'Kérjük töltse ki!'\n                          }],\n                          validateTrigger: ['blur', 'change']\n                      }]"
                        }
                      ],
                      attrs: {
                        getPopupContainer: _vm.getParentTriggerNode(),
                        loading: _vm.loading["category/id"],
                        placeholder: "Kérjük válasszon!",
                        disabled:
                          !_vm.form.getFieldValue("group/id") ||
                          _vm.isMobileReadonly,
                        filterOption: _vm.filterSelectNames
                      },
                      on: {
                        change: function($event) {
                          return _vm.loadOptions("labels/id", "category/id")
                        }
                      }
                    },
                    _vm._l(_vm.options["category/id"], function(category) {
                      return _c(
                        "a-select-option",
                        { key: category.name, attrs: { value: category.id } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(category.name) +
                              "\n          "
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "Címke" } },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "labels/id",
                            {
                              rules: [
                                {
                                  required: _vm.isNotMobile,
                                  message: "Kérjük töltse ki!"
                                }
                              ],
                              validateTrigger: ["blur", "change"]
                            }
                          ],
                          expression:
                            "['labels/id', {\n                          rules: [{\n                              required: isNotMobile,\n                              message: 'Kérjük töltse ki!'\n                          }],\n                          validateTrigger: ['blur', 'change']\n                      }]"
                        }
                      ],
                      attrs: {
                        getPopupContainer: _vm.getParentTriggerNode(),
                        loading: _vm.loading["labels/id"],
                        mode: "multiple",
                        placeholder: "Kérjük válasszon!",
                        disabled:
                          !_vm.form.getFieldValue("category/id") ||
                          _vm.isMobileReadonly,
                        filterOption: _vm.filterSelectNames
                      },
                      on: {
                        change: function(values) {
                          return _vm.checkSingular(values, "labels/id")
                        }
                      }
                    },
                    _vm._l(_vm.options["labels/id"], function(category) {
                      return _c(
                        "a-select-option",
                        {
                          key: category.name,
                          attrs: {
                            value: category.id,
                            disabled:
                              !category.singular &&
                              _vm.singularMode["labels/id"]
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(category.name) +
                              "\n          "
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "Kiemelt desztináció címke" } },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "featuredDestinations/id",
                            {
                              rules: [
                                {
                                  required: false,
                                  message: "Kérjük töltse ki!"
                                }
                              ],
                              validateTrigger: ["blur", "change"]
                            }
                          ],
                          expression:
                            "['featuredDestinations/id', {\n                          rules: [{\n                              required: false,\n                              message: 'Kérjük töltse ki!'\n                          }],\n                          validateTrigger: ['blur', 'change']\n                      }]"
                        }
                      ],
                      attrs: {
                        getPopupContainer: _vm.getParentTriggerNode(),
                        loading: _vm.loading["featuredDestinations/id"],
                        mode: "multiple",
                        disabled: _vm.isMobileReadonly,
                        placeholder: "Kérjük válasszon!",
                        filterOption: _vm.filterSelectNames
                      },
                      on: {
                        dropdownVisibleChange: function(open) {
                          return (
                            open && _vm.loadOptions("featuredDestinations/id")
                          )
                        },
                        change: function(values) {
                          return _vm.checkSingular(
                            values,
                            "featuredDestinations/id"
                          )
                        }
                      }
                    },
                    _vm._l(_vm.options["featuredDestinations/id"], function(
                      category
                    ) {
                      return _c(
                        "a-select-option",
                        {
                          key: category.name,
                          attrs: {
                            value: category.id,
                            disabled:
                              !category.singular &&
                              _vm.singularMode["featuredDestinations/id"]
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(category.name) +
                              "\n          "
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c("h4", [_vm._v("Látogató címkék")]),
              _c(
                "a-form-item",
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v(
                        "\n            Kiemelt célcsoportok\n\n            "
                      ),
                      _c("a-icon", {
                        attrs: { type: "question-circle-o" },
                        on: {
                          click: function() {
                            return (_vm.modals.targetAudience = true)
                          }
                        }
                      }),
                      _c(
                        "a-modal",
                        {
                          attrs: {
                            mask: false,
                            maskClosable: true,
                            footer: null,
                            title:
                              "Melyik kiemelt célcsoportoknak lehet releváns a vonzerő?"
                          },
                          on: {
                            ok: function() {
                              return (_vm.modals.targetAudience = false)
                            }
                          },
                          model: {
                            value: _vm.modals.targetAudience,
                            callback: function($$v) {
                              _vm.$set(_vm.modals, "targetAudience", $$v)
                            },
                            expression: " modals.targetAudience"
                          }
                        },
                        [
                          _c("p", [
                            _c("strong", [_vm._v("Értéktudatos fiatalok:")]),
                            _c("span", [
                              _vm._v(
                                " 25-40 év közöttiek, gyakran baráti társasággal érkeznek. Érdeklődnek a művészet, a kultúra és a divat iránt, továbbá jellemző rájuk a kíváncsiság, élménykeresők, nyitottak az újdonságra. Rendszeresen utaznak, érdeklődnek a minőségi éjszakai élet iránt, igénylik az igényes gasztronómiát és a minőségi street foodot, valamint hajlamosak a vásárlásra."
                              )
                            ])
                          ]),
                          _c("p", [
                            _c("strong", [_vm._v("Kozmopolita utazók:")]),
                            _c("span", [
                              _vm._v(
                                " 30-55 év közöttiek. Kevésbé szeretnek alkalmazkodni, minőségi értékajánlatra vágynak. Nyitottak lehetnek a day-tripekre, extra élményekre (pl. privát vacsora), illetve szívesen látogatnak fine dining éttermeket. Érdeklődnek a koncertek, a kiállítások, a galériák, a fine dining, a borok, a kávéházak iránt, továbbá érdekelheti őket a privát guide használata, és jellemzően jelentős értékben vásárolnak."
                              )
                            ])
                          ]),
                          _c("p", [
                            _c("strong", [_vm._v("Élménykereső családosok:")]),
                            _c("span", [
                              _vm._v(
                                " Szülő(k), legalább egy gyerekkel. Érdeklődnek a múzeumok, az élményfürdők, a kirándulások, a programok és a kiállítások iránt. Fontos szempont számukra a biztonság, valamint értékelik a zöld területeket, parkokat."
                              )
                            ])
                          ]),
                          _c("p", [
                            _c("strong", [_vm._v("Aktív szeniorok:")]),
                            _c("span", [
                              _vm._v(
                                " Jellemzően feltöltődésre vágyó nyugdíjasok, akik nyitottak városnézésre és a gyógyfürdőkre. A kulturális turizmus, városlátogatások, aktív időtöltés, bor és gasztronómia élvezete mind fontos motivációt jelenthetnek számukra. Kiemelt szerepe van az egészségturizmus nyújtotta lehetőségeknek és az akadálymentes turisztikai kínálatnak."
                              )
                            ])
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "targetGroups/id",
                            {
                              rules: [
                                {
                                  required: _vm.isNotMobile,
                                  message: "Kérjük töltse ki!"
                                }
                              ],
                              validateTrigger: ["blur", "change"]
                            }
                          ],
                          expression:
                            "['targetGroups/id', {\n                          rules: [{\n                              required: isNotMobile,\n                              message: 'Kérjük töltse ki!'\n                          }],\n                          validateTrigger: ['blur', 'change']\n                      }]"
                        }
                      ],
                      attrs: {
                        getPopupContainer: _vm.getParentTriggerNode(),
                        loading: _vm.loading["targetGroups/id"],
                        mode: "multiple",
                        placeholder: "Kérjük válasszon!",
                        disabled: _vm.isMobileReadonly,
                        filterOption: _vm.filterSelectNames
                      },
                      on: {
                        dropdownVisibleChange: function(open) {
                          return open && _vm.loadOptions("targetGroups/id")
                        },
                        change: function(values) {
                          return _vm.checkSingular(values, "targetGroups/id")
                        }
                      }
                    },
                    _vm._l(_vm.options["targetGroups/id"], function(category) {
                      return _c(
                        "a-select-option",
                        {
                          key: category.name,
                          attrs: {
                            value: category.id,
                            disabled:
                              !category.singular &&
                              _vm.singularMode["targetGroups/id"]
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(category.name) +
                              "\n          "
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "Utazás típusa" } },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "journeyTypes/id",
                            {
                              rules: [
                                {
                                  required: _vm.isNotMobile,
                                  message: "Kérjük töltse ki!"
                                }
                              ],
                              validateTrigger: ["blur", "change"]
                            }
                          ],
                          expression:
                            "['journeyTypes/id', {\n                          rules: [{\n                              required: isNotMobile,\n                              message: 'Kérjük töltse ki!'\n                          }],\n                          validateTrigger: ['blur', 'change']\n                      }]"
                        }
                      ],
                      attrs: {
                        getPopupContainer: _vm.getParentTriggerNode(),
                        loading: _vm.loading["journeyTypes/id"],
                        disabled: _vm.isMobileReadonly,
                        mode: "multiple",
                        placeholder: "Kérjük válasszon!",
                        filterOption: _vm.filterSelectNames
                      },
                      on: {
                        dropdownVisibleChange: function(open) {
                          return open && _vm.loadOptions("journeyTypes/id")
                        },
                        change: function(values) {
                          return _vm.checkSingular(values, "journeyTypes/id")
                        }
                      }
                    },
                    _vm._l(_vm.options["journeyTypes/id"], function(category) {
                      return _c(
                        "a-select-option",
                        {
                          key: category.name,
                          attrs: {
                            value: category.id,
                            disabled:
                              !category.singular &&
                              _vm.singularMode["journeyTypes/id"]
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(category.name) +
                              "\n          "
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "Helyszín jellege" } },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "siteNatures/id",
                            {
                              rules: [
                                {
                                  required: _vm.isNotMobile,
                                  message: "Kérjük töltse ki!"
                                }
                              ],
                              validateTrigger: ["blur", "change"]
                            }
                          ],
                          expression:
                            "['siteNatures/id', {\n                          rules: [{\n                              required: isNotMobile,\n                              message: 'Kérjük töltse ki!'\n                          }],\n                          validateTrigger: ['blur', 'change']\n                      }]"
                        }
                      ],
                      attrs: {
                        getPopupContainer: _vm.getParentTriggerNode(),
                        loading: _vm.loading["siteNatures/id"],
                        disabled: _vm.isMobileReadonly,
                        mode: "multiple",
                        placeholder: "Kérjük válasszon!",
                        filterOption: _vm.filterSelectNames
                      },
                      on: {
                        dropdownVisibleChange: function(open) {
                          return open && _vm.loadOptions("siteNatures/id")
                        },
                        change: function(values) {
                          return _vm.checkSingular(values, "siteNatures/id")
                        }
                      }
                    },
                    _vm._l(_vm.options["siteNatures/id"], function(category) {
                      return _c(
                        "a-select-option",
                        {
                          key: category.name,
                          attrs: {
                            value: category.id,
                            disabled:
                              !category.singular &&
                              _vm.singularMode["siteNatures/id"]
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(category.name) +
                              "\n          "
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "Szezonalitás" } },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "seasonalities/id",
                            {
                              rules: [
                                {
                                  required: _vm.isNotMobile,
                                  message: "Kérjük töltse ki!"
                                }
                              ],
                              validateTrigger: "blur"
                            }
                          ],
                          expression:
                            "['seasonalities/id', {\n                          rules: [{\n                              required: isNotMobile,\n                              message: 'Kérjük töltse ki!'\n                          }],\n                          validateTrigger: 'blur'\n                      }]"
                        }
                      ],
                      attrs: {
                        getPopupContainer: _vm.getParentTriggerNode(),
                        loading: _vm.loading["seasonalities/id"],
                        disabled: _vm.isMobileReadonly,
                        mode: "multiple",
                        placeholder: "Kérjük válasszon!",
                        filterOption: _vm.filterSelectNames
                      },
                      on: {
                        dropdownVisibleChange: function(open) {
                          return open && _vm.loadOptions("seasonalities/id")
                        },
                        change: function(values) {
                          return _vm.checkSingular(values, "seasonalities/id")
                        }
                      }
                    },
                    _vm._l(_vm.options["seasonalities/id"], function(category) {
                      return _c(
                        "a-select-option",
                        {
                          key: category.name,
                          attrs: {
                            value: category.id,
                            disabled:
                              !category.singular &&
                              _vm.singularMode["seasonalities/id"]
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(category.name) +
                              "\n          "
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "Hozzáférhetőség" } },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "accessibilities/id",
                            {
                              rules: [
                                {
                                  required: _vm.isNotMobile,
                                  message: "Kérjük töltse ki!"
                                }
                              ],
                              validateTrigger: "blur"
                            }
                          ],
                          expression:
                            "['accessibilities/id', {\n                          rules: [{\n                              required: isNotMobile,\n                              message: 'Kérjük töltse ki!'\n                          }],\n                          validateTrigger: 'blur'\n                      }]"
                        }
                      ],
                      attrs: {
                        getPopupContainer: _vm.getParentTriggerNode(),
                        loading: _vm.loading["accessibilities/id"],
                        disabled: _vm.isMobileReadonly,
                        mode: "multiple",
                        placeholder: "Kérjük válasszon!",
                        filterOption: _vm.filterSelectNames
                      },
                      on: {
                        dropdownVisibleChange: function(open) {
                          return open && _vm.loadOptions("accessibilities/id")
                        },
                        change: function(values) {
                          return _vm.checkSingular(values, "accessibilities/id")
                        }
                      }
                    },
                    _vm._l(_vm.options["accessibilities/id"], function(
                      category
                    ) {
                      return _c(
                        "a-select-option",
                        {
                          key: category.name,
                          attrs: {
                            value: category.id,
                            disabled:
                              !category.singular &&
                              _vm.singularMode["accessibilities/id"]
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(category.name) +
                              "\n          "
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _vm.isNotMobile
                ? [
                    _c("a-divider", { attrs: { orientation: "left" } }, [
                      _vm._v("\n          VONZERŐ MINŐSÍTÉSE\n        ")
                    ]),
                    _c(
                      "a-form-item",
                      [
                        _c(
                          "span",
                          { attrs: { slot: "label" }, slot: "label" },
                          [
                            _vm._v(
                              "\n              Vonzerő minősítése\n\n              "
                            ),
                            _c("a-icon", {
                              attrs: { type: "question-circle-o" },
                              on: {
                                click: function() {
                                  return (_vm.modals.classification = true)
                                }
                              }
                            }),
                            _c(
                              "a-modal",
                              {
                                attrs: {
                                  mask: false,
                                  maskClosable: true,
                                  footer: null
                                },
                                on: {
                                  ok: function() {
                                    return (_vm.modals.classification = false)
                                  }
                                },
                                model: {
                                  value: _vm.modals.classification,
                                  callback: function($$v) {
                                    _vm.$set(_vm.modals, "classification", $$v)
                                  },
                                  expression: "modals.classification"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { attrs: { slot: "title" }, slot: "title" },
                                  [
                                    _vm._v(
                                      "\n                      Kritériumrendszer a vonzerők minősítéséhez "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\n                      (5-7 értékeket csak MTÜ admin felhasználók rendelhetnek a vonzerőkhöz.)\n                  "
                                    )
                                  ]
                                ),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      "Lokális (potenciális, inaktív) (1) "
                                    )
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      " Olyan vonzerő, mely attrakcióvá fejleszthető. Turisztikai érdeklődésre számot tarthat, de hiányzik a bemutatás infrastruktúrája. Lehet egy természeti érték, de akár egy templom, egy várrom stb. Ide soroljuk azokat is, melyek turisztikailag azért nem értelmezhetőek, mert zárva vannak, nem működnek. "
                                    )
                                  ])
                                ]),
                                _c("p", [
                                  _c("strong", [_vm._v("Lokális (2) ")]),
                                  _c("span", [
                                    _vm._v(
                                      " Alkalmas turisták fogadására, nem csak helyi lakosok érdeklődését szolgálja ki, de látogatói jellemzően 20-30 km-es körből érkeznek. Kifejezetten értelmezhető egy turisztikai célú utazás során, van történelmi, kulturális, természeti mondanivalója. Kiegészítő attrakciója a településnek, vendégéjszakát kevésbé generál."
                                    )
                                  ])
                                ]),
                                _c("p", [
                                  _c("strong", [_vm._v("Regionális (3) ")]),
                                  _c("span", [
                                    _vm._v(
                                      " 100 km-en belülről vonzza a látogatói többségét, látogatók száma minimum 20 ezer fő/év. Ismert, népszerű vonzerő, attrakció. Utazási döntést befolyásol, de szükséges több turisztikai vonzerő együttes jelenléte az utazáshoz. Jellemzően belföldi vendégek látogatják, generálhat vendégéjszakát, van helyettesítő terméke, hasonló profilú, látogatószámú attrakció megtalálható az országban több helyen. Tematikus utak ide sorolhatóak. Önállóan nem generál vendégéjszakát."
                                    )
                                  ])
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v("Országos/regionális (4) ")
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      " 100-150 kilométerről látható, jellemzően belföldi vendégek látogatják, látogatóforgalma minimum 100 ezer fő/év. Határmenti elhelyezkedés esetén érezhető az érdeklődés a szomszédos ország(ok)ból. Utazási döntést generál, több vendégéjszaka eltöltésére ösztönöz, desztinációs márkát épít."
                                    )
                                  ])
                                ]),
                                _c("p", [
                                  _c("strong", [_vm._v("Országos (5)  ")]),
                                  _c("span", [
                                    _vm._v(
                                      ' A belföldi utazók többségének "listáján" szerepel, országosan ismert, látogatóforgalma minimum 100 ezer fő/év, országimázs építésre alkalmas, frekventált üdülőterületen található, önálló utazási döntésre alkalmas, jellemzően belföldi vendégek látogatják, az ország másik feléből is képes vendéget vonzani.'
                                    )
                                  ])
                                ]),
                                _c("p", [
                                  _c("strong", [_vm._v("Nemzetközi (6)  ")]),
                                  _c("span", [
                                    _vm._v(
                                      " Utazási döntést befolyásol nemzetközi piacról, látogatóforgalma minimum 200 ezer fő/év, jellemzően egész évben látogathatóak, de bekerülhet magas látogatóforgalmú, szezonális nyitva tartású attrakció is (pl. strandok), nemzetközi színvonal, alkalmas országmárka építésre, minimum 30% külföldi vendég, vendégéjszakát generál, több napos látogatásra ösztönöz."
                                    )
                                  ])
                                ]),
                                _c("p", [
                                  _c("strong", [_vm._v("Nemzetközi + (7)  ")]),
                                  _c("span", [
                                    _vm._v(
                                      " kifejezetten az ország turisztikai márkázására alkalmas nemzetközi színvonalú és látogatottságú vonzerő. Számuk behatárolt, az ország első 50 nemzetközi viszonylatban fontos TOB-ja."
                                    )
                                  ])
                                ])
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "a-select",
                          {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "classification",
                                  {
                                    rules: [
                                      {
                                        required: true,
                                        message: "Kérjük töltse ki!"
                                      }
                                    ]
                                  }
                                ],
                                expression:
                                  "[\n                        'classification',\n                        {\n                            rules: [{\n                                required: true,\n                                message: 'Kérjük töltse ki!'\n                            }]\n                        }\n                    ]"
                              }
                            ],
                            attrs: {
                              disabled:
                                (!_vm.isAdmin &&
                                  _vm.form.getFieldValue("classification") >
                                    4) ||
                                _vm.isMobileReadonly,
                              placeholder: "Kérjük válasszon!"
                            }
                          },
                          _vm._l(7, function(number) {
                            return _c(
                              "a-select-option",
                              {
                                key: number,
                                attrs: {
                                  value: number,
                                  disabled: !_vm.isAdmin && number > 4
                                }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(number) +
                                    "\n            "
                                )
                              ]
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _c("a-divider", { attrs: { orientation: "left" } }, [
                _vm._v("\n        VONZERŐ ELHELYEZKEDÉSE\n      ")
              ]),
              _c("p", [
                _vm._v(
                  "\n        Az alábbi sorokban kérjük a vonzerő pontos elhelyezkedésére vonatkozó adatok megadását.\n      "
                )
              ]),
              _c(
                "a-form-item",
                { attrs: { label: "Irányítószám" } },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "address/zipCode/id",
                            {
                              rules: [
                                {
                                  required: true,
                                  whitespace: true,
                                  message: "Kérjük töltse ki!",
                                  type: "integer"
                                }
                              ],
                              validateTrigger: ["blur", "keyup"]
                            }
                          ],
                          expression:
                            "[\n                          'address/zipCode/id',\n                          {\n                              rules: [\n                                  {\n                                  required: true,\n                                  whitespace: true,\n                                  message: 'Kérjük töltse ki!',\n                                  type: 'integer'\n                                },\n                              ],\n                              validateTrigger: ['blur','keyup']\n                          }\n                      ]"
                        }
                      ],
                      style: { width: "100%" },
                      attrs: {
                        placeholder: "Kezdjen gépelni",
                        disabled: _vm.isMobileReadonly,
                        showSearch: "",
                        showArrow: false,
                        defaultActiveFirstOption: false,
                        getPopupContainer: _vm.getParentTriggerNode(),
                        filterOption: false,
                        notFoundContent: _vm.loading["address/zipCode/id"]
                          ? undefined
                          : null
                      },
                      on: {
                        search: function(value) {
                          return _vm.zipCodeAutoComplete(
                            value,
                            "address/zipCode/id"
                          )
                        },
                        change: function(value) {
                          _vm.fillCityOptions(
                            value,
                            "address/zipCode/id",
                            "address/city/id"
                          )
                        },
                        dropdownVisibleChange: function(open) {
                          return (
                            open &&
                            _vm.zipCodeAutoComplete(null, "address/zipCode/id")
                          )
                        }
                      }
                    },
                    [
                      _vm.loading["address/zipCode/id"]
                        ? _c("a-spin", {
                            attrs: { slot: "notFoundContent", size: "small" },
                            slot: "notFoundContent"
                          })
                        : _vm._e(),
                      _vm._l(_vm.options["address/zipCode/id"], function(
                        option
                      ) {
                        return _c(
                          "a-select-option",
                          { key: option.code, attrs: { value: option.id } },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(option.code) +
                                "\n          "
                            )
                          ]
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "Település neve" } },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "address/city/id",
                            {
                              rules: [
                                {
                                  required: true,
                                  whitespace: true,
                                  message: "Kérjük töltse ki!",
                                  type: "integer"
                                }
                              ],
                              validateTrigger: ["blur", "keyup"]
                            }
                          ],
                          expression:
                            "[\n                          'address/city/id',\n                          {\n                              rules: [\n                                  {\n                                  required: true,\n                                  whitespace: true,\n                                  message: 'Kérjük töltse ki!',\n                                  type: 'integer'\n                                },\n                              ],\n                              validateTrigger: ['blur','keyup']\n                          }\n                      ]"
                        }
                      ],
                      style: { width: "100%" },
                      attrs: {
                        placeholder: "Töltse ki az irányítószám mezőt!",
                        showArrow: false,
                        disabled:
                          !_vm.options["address/city/id"].length ||
                          _vm.isMobileReadonly,
                        defaultActiveFirstOption: true,
                        filterOption: false,
                        notFoundContent: _vm.loading["address/zipCode/id"]
                          ? undefined
                          : null
                      }
                    },
                    [
                      _vm.loading["address/zipCode/id"]
                        ? _c("a-spin", {
                            attrs: { slot: "notFoundContent", size: "small" },
                            slot: "notFoundContent"
                          })
                        : _vm._e(),
                      _vm._l(_vm.options["address/city/id"], function(option) {
                        return _c(
                          "a-select-option",
                          { key: option.id, attrs: { value: option.id } },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(option.name) +
                                "\n          "
                            )
                          ]
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _vm.isNotMobile
                ? [
                    _c(
                      "a-form-item",
                      { attrs: { selfUpdate: true, label: "Közterület neve" } },
                      [
                        _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "address/address",
                                {
                                  rules: [
                                    {
                                      required: !_vm.isChecked(
                                        "address/addressNotRelevant"
                                      ),
                                      whitespace: true,
                                      message: "Kérjük töltse ki!"
                                    },
                                    {
                                      max:
                                        _vm.characterCounts["address/address"]
                                          .max,
                                      message:
                                        "Maximum " +
                                        _vm.characterCounts["address/address"]
                                          .max +
                                        " karakter engedélyezett."
                                    }
                                  ],
                                  validateTrigger: ["blur", "keyup"]
                                }
                              ],
                              expression:
                                "[\n                            'address/address',\n                            {\n                                rules: [\n                                    {\n                                      required: !isChecked('address/addressNotRelevant'),\n                                      whitespace: true,\n                                      message: 'Kérjük töltse ki!'\n                                    },\n                                    {\n                                      max: characterCounts['address/address'].max,\n                                      message: 'Maximum ' + characterCounts['address/address'].max + ' karakter engedélyezett.',\n                                    }\n                                ],\n                                validateTrigger: ['blur','keyup']\n                            }\n                        ]"
                            }
                          ],
                          attrs: {
                            maxLength: 25000,
                            disabled:
                              _vm.isChecked("address/addressNotRelevant") ||
                              _vm.isMobileReadonly,
                            placeholder: "Kérjük beírni (betűkkel)"
                          }
                        }),
                        _c(
                          "a-checkbox",
                          {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "address/addressNotRelevant",
                                  {
                                    rules: [
                                      {
                                        required: false
                                      }
                                    ]
                                  }
                                ],
                                expression:
                                  "[\n                        'address/addressNotRelevant',\n                        {\n                            rules: [{\n                                required: false\n                            }]\n                        }\n                    ]"
                              }
                            ],
                            attrs: {
                              checked: _vm.isChecked(
                                "address/addressNotRelevant"
                              ),
                              disabled: _vm.isMobileReadonly
                            },
                            on: {
                              change: function() {
                                return _vm.relevancyCheckboxChange(
                                  "address/address"
                                )
                              }
                            }
                          },
                          [_vm._v("\n            Nem releváns\n          ")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "a-form-item",
                      {
                        attrs: {
                          selfUpdate: true,
                          label: "Közterület típusa (utca, tér, út, dűlő stb.)"
                        }
                      },
                      [
                        _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "address/addressType",
                                {
                                  rules: [
                                    {
                                      required: !_vm.isChecked(
                                        "address/addressTypeNotRelevant"
                                      ),
                                      whitespace: true,
                                      message: "Kérjük töltse ki!"
                                    },
                                    {
                                      max:
                                        _vm.characterCounts[
                                          "address/addressType"
                                        ].max,
                                      message:
                                        "Maximum " +
                                        _vm.characterCounts[
                                          "address/addressType"
                                        ].max +
                                        " karakter engedélyezett."
                                    }
                                  ],
                                  validateTrigger: ["blur", "keyup"]
                                }
                              ],
                              expression:
                                "[\n                            'address/addressType',\n                            {\n                                rules: [\n                                    {\n                                      required: !isChecked('address/addressTypeNotRelevant'),\n                                      whitespace: true,\n                                      message: 'Kérjük töltse ki!'\n                                    },\n                                    {\n                                      max: characterCounts['address/addressType'].max,\n                                      message: 'Maximum ' + characterCounts['address/addressType'].max + ' karakter engedélyezett.',\n                                    }\n                                ],\n                                validateTrigger: ['blur','keyup']\n                            }\n                        ]"
                            }
                          ],
                          attrs: {
                            maxLength: 25000,
                            disabled:
                              _vm.isChecked("address/addressTypeNotRelevant") ||
                              _vm.isMobileReadonly,
                            placeholder: "Kérjük beírni (betűkkel)"
                          }
                        }),
                        _c(
                          "a-checkbox",
                          {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "address/addressTypeNotRelevant",
                                  {
                                    rules: [
                                      {
                                        required: false
                                      }
                                    ]
                                  }
                                ],
                                expression:
                                  "[\n                        'address/addressTypeNotRelevant',\n                        {\n                            rules: [{\n                                required: false\n                            }]\n                        }\n                    ]"
                              }
                            ],
                            attrs: {
                              disabled: _vm.isMobileReadonly,
                              checked: _vm.isChecked(
                                "address/addressTypeNotRelevant"
                              )
                            },
                            on: {
                              change: function() {
                                return _vm.relevancyCheckboxChange(
                                  "address/addressType"
                                )
                              }
                            }
                          },
                          [_vm._v("\n            Nem releváns\n          ")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "a-form-item",
                      [
                        _c(
                          "span",
                          { attrs: { slot: "label" }, slot: "label" },
                          [
                            _vm._v(
                              "\n              Házszám/helyrajzi szám\n              "
                            ),
                            _c(
                              "a-tooltip",
                              {
                                attrs: {
                                  title:
                                    "Itt a házszámon kívül más is beírható (pl. helyrajzi szám vagy más egyéb, pontos azonosító, ami az adott ingatlan helyét pontosan meghatározza). Ha nincs ilyen pontos azonosító szám a címhez, akkor kérjük a mezőt üresen hagyni."
                                }
                              },
                              [
                                _c("a-icon", {
                                  attrs: { type: "question-circle-o" }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "address/addressNumber",
                                {
                                  rules: [
                                    {
                                      required: !_vm.isChecked(
                                        "address/addressNumberNotRelevant"
                                      ),
                                      whitespace: true,
                                      message: "Kérjük töltse ki!"
                                    },
                                    {
                                      max:
                                        _vm.characterCounts[
                                          "address/addressNumber"
                                        ].max,
                                      message:
                                        "Maximum " +
                                        _vm.characterCounts[
                                          "address/addressNumber"
                                        ].max +
                                        " karakter engedélyezett."
                                    }
                                  ],
                                  validateTrigger: ["blur", "keyup"]
                                }
                              ],
                              expression:
                                "[\n                            'address/addressNumber',\n                            {\n                                rules: [\n                                    {\n                                      required: !isChecked('address/addressNumberNotRelevant'),\n                                      whitespace: true,\n                                      message: 'Kérjük töltse ki!'\n                                    },\n                                    {\n                                      max: characterCounts['address/addressNumber'].max,\n                                      message: 'Maximum ' + characterCounts['address/addressNumber'].max + ' karakter engedélyezett.',\n                                    }\n                                ],\n                                validateTrigger: ['blur','keyup']\n                            }\n                        ]"
                            }
                          ],
                          attrs: {
                            maxLength: 25000,
                            disabled:
                              _vm.isChecked(
                                "address/addressNumberNotRelevant"
                              ) || _vm.isMobileReadonly,
                            placeholder: "Kérjük beírni (számokkal, betűkkel)"
                          }
                        }),
                        _c(
                          "a-checkbox",
                          {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "address/addressNumberNotRelevant",
                                  {
                                    rules: [
                                      {
                                        required: false
                                      }
                                    ]
                                  }
                                ],
                                expression:
                                  "[\n                        'address/addressNumberNotRelevant',\n                        {\n                            rules: [{\n                                required: false\n                            }]\n                        }\n                    ]"
                              }
                            ],
                            attrs: {
                              disabled: _vm.isMobileReadonly,
                              checked: _vm.isChecked(
                                "address/addressNumberNotRelevant"
                              )
                            },
                            on: {
                              change: function() {
                                return _vm.relevancyCheckboxChange(
                                  "address/addressNumber"
                                )
                              }
                            }
                          },
                          [_vm._v("\n            Nem releváns\n          ")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "a-form-item",
                      { attrs: { label: "Postázási cím", selfUpdate: true } },
                      [
                        _c("a-textarea", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "mailingAddress",
                                {
                                  rules: [
                                    {
                                      required: true,
                                      preserve: true,
                                      message: "Kérjük töltse ki!"
                                    },
                                    {
                                      max:
                                        _vm.characterCounts.mailingAddress.max,
                                      message:
                                        "Maximum " +
                                        _vm.characterCounts.mailingAddress.max +
                                        " karakter engedélyezett."
                                    }
                                  ],
                                  validateTrigger: ["blur", "keyup"]
                                }
                              ],
                              expression:
                                "[\n                            'mailingAddress',\n                            {\n                                rules: [\n                                    {\n                                      required: true,\n                                      preserve: true,\n                                      message: 'Kérjük töltse ki!'\n                                    },\n                                    {\n                                      max: characterCounts.mailingAddress.max,\n                                      message: 'Maximum ' + characterCounts.mailingAddress.max + ' karakter engedélyezett.',\n                                    }\n                                ],\n                                validateTrigger: ['blur', 'keyup']\n                            }\n                        ]"
                            }
                          ],
                          attrs: {
                            maxLength: 25000,
                            disabled: _vm.isMobileReadonly,
                            autoSize: { minRows: 1 }
                          }
                        })
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _c(
                "a-form-item",
                { attrs: { selfUpdate: true } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v(
                        "\n            A vonzerő GPS koordinátája: szélességi fok\n\n            "
                      ),
                      _c(
                        "a-tooltip",
                        { attrs: { title: _vm.coordinateTooltipLatitude } },
                        [
                          _c("a-icon", { attrs: { type: "question-circle-o" } })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "address/lat",
                          {
                            rules: [
                              {
                                required: _vm.isNotMobile,
                                whitespace: true,
                                pattern: /^4([-+]?)([\d]{1})(((\.)(\d+)))$/g,
                                message: "A koordináták formátuma helytelen!"
                              },
                              {
                                type: "float",
                                message:
                                  "Kérjük adjon meg valós GPS koordinátákat!",
                                transform: function(value) {
                                  value = parseFloat(value)

                                  return isNaN(value) ? null : value
                                },
                                min: 45.72,
                                max: 48.6
                              }
                            ],
                            validateTrigger: ["blur", "keyup"]
                          }
                        ],
                        expression:
                          "[\n                          'address/lat',\n                          {\n                              rules: [\n                                  {\n                                    required: isNotMobile,\n                                    whitespace: true,\n                                    pattern: /^4([-+]?)([\\d]{1})(((\\.)(\\d+)))$/g,\n                                    message: 'A koordináták formátuma helytelen!'\n                                  },\n                                  {\n                                    type: 'float',\n                                    message: 'Kérjük adjon meg valós GPS koordinátákat!',\n                                    transform: (value) => {\n                                      value = parseFloat(value);\n\n                                      return isNaN(value) ? null: value;\n                                    },\n                                    min: 45.72,\n                                    max: 48.60\n                                  }\n                                  ],\n                              validateTrigger: ['blur','keyup']\n                          }\n                      ]"
                      }
                    ],
                    attrs: {
                      maxLength: 25000,
                      disabled: _vm.isMobileReadonly,
                      placeholder:
                        "Kérjük a koordinátát az alábbi formátumban adja meg: 47.507346"
                    },
                    on: {
                      change: function(event) {
                        return _vm.formatCoordinate(
                          event.target.value,
                          "address/lat"
                        )
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { selfUpdate: true } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v(
                        "\n          A vonzerő GPS koordinátája: hosszúsági fok\n\n          "
                      ),
                      _c(
                        "a-tooltip",
                        { attrs: { title: _vm.coordinateTooltipLongitude } },
                        [
                          _c("a-icon", { attrs: { type: "question-circle-o" } })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "address/long",
                          {
                            rules: [
                              {
                                required: _vm.isNotMobile,
                                whitespace: true,
                                pattern: /^(1|2)(([-+]?)([\d]{1})((\.)(\d+))?)$/g,
                                message: "A koordináták formátuma helytelen!"
                              },
                              {
                                type: "float",
                                message:
                                  "Kérjük adjon meg valós GPS koordinátákat!",
                                transform: function(value) {
                                  value = parseFloat(value)

                                  return isNaN(value) ? null : value
                                },
                                min: 16.1,
                                max: 23
                              }
                            ],
                            validateTrigger: ["blur", "keyup"]
                          }
                        ],
                        expression:
                          "[\n                          'address/long',\n                          {\n                              rules: [\n                                  {\n                                    required: isNotMobile,\n                                    whitespace: true,\n                                    pattern: /^(1|2)(([-+]?)([\\d]{1})((\\.)(\\d+))?)$/g,\n                                    message: 'A koordináták formátuma helytelen!'\n                                  },\n                                  {\n                                    type: 'float',\n                                    message: 'Kérjük adjon meg valós GPS koordinátákat!',\n                                    transform: (value) => {\n                                      value = parseFloat(value);\n\n                                      return isNaN(value) ? null: value;\n                                    },\n                                    min: 16.10,\n                                    max: 23\n                                  }],\n                              validateTrigger: ['blur','keyup']\n                          }\n                      ]"
                      }
                    ],
                    attrs: {
                      maxLength: 25000,
                      disabled: _vm.isMobileReadonly,
                      placeholder:
                        "Kérjük a koordinátát az alábbi formátumban adja meg: 19.046610"
                    },
                    on: {
                      change: function(event) {
                        return _vm.formatCoordinate(
                          event.target.value,
                          "address/long"
                        )
                      }
                    }
                  })
                ],
                1
              ),
              _c("a-divider", { attrs: { orientation: "left" } }, [
                _vm._v("\n        A VONZERŐ HONLAPJA\n      ")
              ]),
              _c(
                "a-form-item",
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v(
                        "\n            Vonzerő honlapjának elérhetősége\n\n            "
                      ),
                      _c(
                        "a-tooltip",
                        {
                          attrs: {
                            title:
                              "Kérjük a weboldal címét http(s)://www.weboldal.xy formátumban töltse ki!"
                          }
                        },
                        [
                          _c("a-icon", { attrs: { type: "question-circle-o" } })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "website/url",
                          {
                            rules: [
                              {
                                required:
                                  !_vm.isChecked("website/notRelevant") &&
                                  _vm.isNotMobile,
                                message:
                                  "Kérjük a megfelelő formátumban töltse ki!",
                                pattern: _vm.isChecked("website/notRelevant")
                                  ? undefined
                                  : /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
                              }
                            ],
                            validateTrigger: ["blur", "keyup"]
                          }
                        ],
                        expression:
                          "[\n                      'website/url',\n                      {\n                          rules: [\n                                  {\n                                    required: !isChecked('website/notRelevant') && isNotMobile,\n                                    message: 'Kérjük a megfelelő formátumban töltse ki!',\n                                    pattern: isChecked('website/notRelevant') ? undefined : /^https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)/\n                                  }\n                                ],\n                          validateTrigger: ['blur', 'keyup']\n                      }\n                  ]"
                      }
                    ],
                    attrs: {
                      maxLength: 25000,
                      disabled:
                        _vm.isChecked("website/notRelevant") ||
                        _vm.isMobileReadonly
                    },
                    on: {
                      change: function(event) {
                        return _vm.transformEncodedUrl(
                          event.target.value,
                          "website/url"
                        )
                      }
                    }
                  }),
                  _c(
                    "a-checkbox",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "website/notRelevant",
                            {
                              rules: [
                                {
                                  required: false
                                }
                              ]
                            }
                          ],
                          expression:
                            "[\n                      'website/notRelevant',\n                      {\n                          rules: [{\n                              required: false\n                          }]\n                      }\n                  ]"
                        }
                      ],
                      attrs: {
                        disabled: _vm.isMobileReadonly,
                        checked: _vm.isChecked("website/notRelevant")
                      },
                      on: {
                        change: function() {
                          return _vm.relevancyCheckboxChange("website/url")
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          Nem rendelkezik a vonzerő weboldallal\n        "
                      )
                    ]
                  )
                ],
                1
              ),
              _vm.isNotMobile
                ? [
                    _c(
                      "a-form-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isChecked("website/notRelevant"),
                            expression: "!isChecked('website/notRelevant')"
                          }
                        ],
                        attrs: { label: "A vonzerő honlapjának idegen nyelvei" }
                      },
                      [
                        _c(
                          "a-select",
                          {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "website/languages/id",
                                  {
                                    rules: [],
                                    validateTrigger: ["blur"]
                                  }
                                ],
                                expression:
                                  "[\n                        'website/languages/id',\n                        {\n                            rules: [],\n                            validateTrigger: ['blur']\n                        }\n                    ]"
                              }
                            ],
                            attrs: {
                              getPopupContainer: _vm.getParentTriggerNode(),
                              mode: "multiple",
                              placeholder: "Kérjük válasszon!",
                              filterOption: _vm.filterSelectNames,
                              disabled: _vm.isMobileReadonly
                            }
                          },
                          _vm._l(_vm.options["website/languages/id"], function(
                            language
                          ) {
                            return _c(
                              "a-select-option",
                              {
                                key: language.name,
                                attrs: { value: language.id }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(language.name) +
                                    "\n            "
                                )
                              ]
                            )
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "a-form-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isChecked("website/notRelevant"),
                            expression: "!isChecked('website/notRelevant')"
                          }
                        ],
                        attrs: { selfUpdate: true }
                      },
                      [
                        _c(
                          "span",
                          { attrs: { slot: "label" }, slot: "label" },
                          [
                            _vm._v(
                              "\n              Egyéb nyelvek\n              "
                            ),
                            _c(
                              "a-tooltip",
                              {
                                attrs: {
                                  title:
                                    "Ha a felsoroltakon kívül egyéb nyelven is elérhető a honlap, kérjük az egyéb nyelveket itt felsorolni"
                                }
                              },
                              [
                                _c("a-icon", {
                                  attrs: { type: "question-circle-o" }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("a-textarea", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "website/comment",
                                {
                                  rules: []
                                }
                              ],
                              expression:
                                "['website/comment',\n                        {\n                            rules: []\n                        }\n                    ]"
                            }
                          ],
                          attrs: {
                            maxLength: 25000,
                            disabled: _vm.isMobileReadonly,
                            autoSize: { minRows: 2 }
                          }
                        })
                      ],
                      1
                    ),
                    _c("a-divider", { attrs: { orientation: "left" } }, [
                      _vm._v(
                        "\n          SAJÁT KÖZÖSSÉGI MÉDIA OLDALAK\n        "
                      )
                    ]),
                    _c("h4", [_vm._v("Facebook oldal")]),
                    _c(
                      "a-form-item",
                      [
                        _c(
                          "span",
                          { attrs: { slot: "label" }, slot: "label" },
                          [
                            _vm._v(
                              "\n              Facebook oldal elérhetősége\n\n              "
                            ),
                            _c(
                              "a-tooltip",
                              {
                                attrs: {
                                  title:
                                    "Kérjük a Facebook linket az alábbi formátumban adja meg: https://www.facebook.com/parlament.hu"
                                }
                              },
                              [
                                _c("a-icon", {
                                  attrs: { type: "question-circle-o" }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "facebook/url",
                                {
                                  rules: [
                                    {
                                      required: !_vm.isChecked(
                                        "facebook/notRelevant"
                                      ),
                                      message:
                                        "Kérjük a megfelelő formátumban töltse ki!",
                                      pattern: /^(http|https):\/\/(?:www.)?facebook.com/g
                                    }
                                  ],
                                  validateTrigger: ["blur", "keyup"]
                                }
                              ],
                              expression:
                                "[\n                        'facebook/url',\n                        {\n                            rules: [{\n                                required: !isChecked('facebook/notRelevant'),\n                                message: 'Kérjük a megfelelő formátumban töltse ki!',\n                                pattern: /^(http|https):\\/\\/(?:www.)?facebook.com/g\n                            }],\n                            validateTrigger: ['blur', 'keyup']\n                        }\n                    ]"
                            }
                          ],
                          attrs: {
                            maxLength: 25000,
                            disabled:
                              _vm.isChecked("facebook/notRelevant") ||
                              _vm.isMobileReadonly
                          },
                          on: {
                            change: function(event) {
                              return _vm.transformEncodedUrl(
                                event.target.value,
                                "facebook/url"
                              )
                            }
                          }
                        }),
                        _c(
                          "a-checkbox",
                          {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "facebook/notRelevant",
                                  {
                                    rules: [
                                      {
                                        required: false
                                      }
                                    ]
                                  }
                                ],
                                expression:
                                  "[\n                        'facebook/notRelevant',\n                        {\n                            rules: [{\n                                required: false\n                            }]\n                        }\n                    ]"
                              }
                            ],
                            attrs: {
                              checked: _vm.isChecked("facebook/notRelevant"),
                              disabled: _vm.isMobileReadonly
                            },
                            on: {
                              change: function() {
                                return _vm.relevancyCheckboxChange(
                                  "facebook/url"
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            Nem rendelkezik a vonzerő Facebook oldallal\n          "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c("h4", [_vm._v("Instagram oldal")]),
                    _c(
                      "a-form-item",
                      [
                        _c(
                          "span",
                          { attrs: { slot: "label" }, slot: "label" },
                          [
                            _vm._v(
                              "\n              Instagram oldal elérhetősége\n\n              "
                            ),
                            _c(
                              "a-tooltip",
                              {
                                attrs: {
                                  title:
                                    "Kérjük a Instagram linket az alábbi formátumban adja meg: https://www.instagram.com/parlament.hu"
                                }
                              },
                              [
                                _c("a-icon", {
                                  attrs: { type: "question-circle-o" }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "instagram/url",
                                {
                                  rules: [
                                    {
                                      required: !_vm.isChecked(
                                        "instagram/notRelevant"
                                      ),
                                      message:
                                        "Kérjük a megfelelő formátumban töltse ki!",
                                      pattern: /^(http|https):\/\/(?:www.)?instagram.com/g
                                    }
                                  ],
                                  validateTrigger: ["blur", "keyup"]
                                }
                              ],
                              expression:
                                "[\n                        'instagram/url',\n                        {\n                            rules: [{\n                                required: !isChecked('instagram/notRelevant'),\n                                message: 'Kérjük a megfelelő formátumban töltse ki!',\n                                pattern: /^(http|https):\\/\\/(?:www.)?instagram.com/g,\n                            }],\n                            validateTrigger: ['blur', 'keyup']\n                        }\n                    ]"
                            }
                          ],
                          attrs: {
                            maxLength: 25000,
                            disabled:
                              _vm.isChecked("instagram/notRelevant") ||
                              _vm.isMobileReadonly
                          },
                          on: {
                            change: function(event) {
                              return _vm.transformEncodedUrl(
                                event.target.value,
                                "instagram/url"
                              )
                            }
                          }
                        }),
                        _c(
                          "a-checkbox",
                          {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "instagram/notRelevant",
                                  {
                                    rules: [
                                      {
                                        required: false
                                      }
                                    ]
                                  }
                                ],
                                expression:
                                  "[\n                        'instagram/notRelevant',\n                        {\n                            rules: [{\n                                required: false\n                            }]\n                        }\n                    ]"
                              }
                            ],
                            attrs: {
                              disabled: _vm.isMobileReadonly,
                              checked: _vm.isChecked("instagram/notRelevant")
                            },
                            on: {
                              change: function() {
                                return _vm.relevancyCheckboxChange(
                                  "instagram/url"
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            Nem rendelkezik a vonzerő Instagram oldallal\n          "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c("a-divider", { attrs: { orientation: "left" } }, [
                      _vm._v("\n          MEGNYITÁS/ÁTADÁS ÉVE\n        ")
                    ]),
                    _c(
                      "a-form-item",
                      {
                        attrs: {
                          label: "Megnyitás/Átadás éve",
                          selfUpdate: true
                        }
                      },
                      [
                        _c("a-input-number", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "openingYear",
                                {
                                  validateTrigger: ["blur", "keyup"],
                                  rules: [
                                    {
                                      required: false,
                                      whitespace: true,
                                      type: "integer",
                                      message: "Kérjük töltse ki!",
                                      transform: function(value) {
                                        value = parseInt(value)

                                        return isNaN(value) ? null : value
                                      }
                                    }
                                  ]
                                }
                              ],
                              expression:
                                "[\n                        'openingYear',\n                        {\n                            validateTrigger: ['blur', 'keyup'],\n                            rules: [{\n                                required: false,\n                                whitespace: true,\n                                type: 'integer',\n                                message: 'Kérjük töltse ki!',\n                                transform: (value) => {\n                                  value = parseInt(value);\n\n                                  return isNaN(value) ? null: value;\n                                },\n                            }]\n                        }\n                    ]"
                            }
                          ],
                          attrs: {
                            max: 9999,
                            min: 0,
                            disabled: _vm.isMobileReadonly
                          }
                        }),
                        _vm._v("\n          év\n        ")
                      ],
                      1
                    ),
                    _c("a-divider", { attrs: { orientation: "left" } }, [
                      _vm._v(
                        "\n          A VONZERŐT ÜZEMELTETŐ SZERVEZET\n        "
                      )
                    ]),
                    _c(
                      "a-form-item",
                      {
                        attrs: {
                          label:
                            "A vonzerőt üzemeltető szervezet hivatalos neve",
                          selfUpdate: true
                        }
                      },
                      [
                        _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "organizationAddress/name",
                                {
                                  rules: [
                                    {
                                      required: false,
                                      preserve: true,
                                      message: "Kérjük töltse ki!"
                                    },
                                    {
                                      max:
                                        _vm.characterCounts[
                                          "organizationAddress/name"
                                        ].max,
                                      message:
                                        "Maximum " +
                                        _vm.characterCounts[
                                          "organizationAddress/name"
                                        ].max +
                                        " karakter engedélyezett."
                                    }
                                  ],
                                  validateTrigger: ["blur", "keyup"]
                                }
                              ],
                              expression:
                                "['organizationAddress/name',\n                            {\n                                rules: [\n                                    {\n                                      required: false,\n                                      preserve: true,\n                                      message: 'Kérjük töltse ki!'\n                                    },\n                                    {\n                                      max: characterCounts['organizationAddress/name'].max,\n                                      message: 'Maximum ' + characterCounts['organizationAddress/name'].max + ' karakter engedélyezett.',\n                                    }\n                                ],\n                                validateTrigger: ['blur', 'keyup']\n                            }\n                        ]"
                            }
                          ],
                          attrs: {
                            maxLength: 25000,
                            disabled: _vm.isMobileReadonly,
                            autoSize: { minRows: 2 }
                          }
                        })
                      ],
                      1
                    ),
                    _c("h4", [
                      _vm._v(
                        "A vonzerőt üzemeltető szervezet hivatalos elérhetősége"
                      )
                    ]),
                    _c(
                      "a-form-item",
                      { attrs: { label: "Irányítószám" } },
                      [
                        _c(
                          "a-select",
                          {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "organizationAddress/zipCode/id",
                                  {
                                    rules: [
                                      {
                                        required: false,
                                        whitespace: true,
                                        message: "Kérjük töltse ki!",
                                        type: "integer"
                                      }
                                    ],
                                    validateTrigger: ["blur", "keyup"]
                                  }
                                ],
                                expression:
                                  "[\n                            'organizationAddress/zipCode/id',\n                            {\n                                rules: [\n                                    {\n                                    required: false,\n                                    whitespace: true,\n                                    message: 'Kérjük töltse ki!',\n                                    type: 'integer'\n                                  },\n                                ],\n                                validateTrigger: ['blur','keyup']\n                            }\n                        ]"
                              }
                            ],
                            style: { width: "100%" },
                            attrs: {
                              placeholder: "Kezdjen gépelni",
                              showSearch: "",
                              showArrow: false,
                              defaultActiveFirstOption: false,
                              getPopupContainer: _vm.getParentTriggerNode(),
                              filterOption: false,
                              disabled: _vm.isMobileReadonly,
                              notFoundContent: _vm.loading[
                                "organizationAddress/zipCode/id"
                              ]
                                ? undefined
                                : null
                            },
                            on: {
                              search: function(value) {
                                return _vm.zipCodeAutoComplete(
                                  value,
                                  "organizationAddress/zipCode/id"
                                )
                              },
                              change: function(value) {
                                _vm.fillCityOptions(
                                  value,
                                  "organizationAddress/zipCode/id",
                                  "organizationAddress/city/id"
                                )
                              },
                              dropdownVisibleChange: function(open) {
                                return (
                                  open &&
                                  _vm.zipCodeAutoComplete(
                                    null,
                                    "organizationAddress/zipCode/id"
                                  )
                                )
                              }
                            }
                          },
                          [
                            _vm.loading["organizationAddress/zipCode/id"]
                              ? _c("a-spin", {
                                  attrs: {
                                    slot: "notFoundContent",
                                    size: "small"
                                  },
                                  slot: "notFoundContent"
                                })
                              : _vm._e(),
                            _vm._l(
                              _vm.options["organizationAddress/zipCode/id"],
                              function(option) {
                                return _c(
                                  "a-select-option",
                                  {
                                    key: option.code,
                                    attrs: { value: option.id }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(option.code) +
                                        "\n            "
                                    )
                                  ]
                                )
                              }
                            )
                          ],
                          2
                        )
                      ],
                      1
                    ),
                    _c(
                      "a-form-item",
                      { attrs: { label: "Település neve" } },
                      [
                        _c(
                          "a-select",
                          {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "organizationAddress/city/id",
                                  {
                                    rules: [
                                      {
                                        required: false,
                                        whitespace: true,
                                        message: "Kérjük töltse ki!",
                                        type: "integer"
                                      }
                                    ],
                                    validateTrigger: ["blur", "keyup"]
                                  }
                                ],
                                expression:
                                  "[\n                            'organizationAddress/city/id',\n                            {\n                                rules: [\n                                    {\n                                    required: false,\n                                    whitespace: true,\n                                    message: 'Kérjük töltse ki!',\n                                    type: 'integer'\n                                  },\n                                ],\n                                validateTrigger: ['blur','keyup']\n                            }\n                        ]"
                              }
                            ],
                            style: { width: "100%" },
                            attrs: {
                              placeholder: "Töltse ki az irányítószám mezőt!",
                              showArrow: false,
                              disabled:
                                !_vm.options["organizationAddress/city/id"]
                                  .length || _vm.isMobileReadonly,
                              defaultActiveFirstOption: true,
                              filterOption: false,
                              notFoundContent: _vm.loading[
                                "organizationAddress/zipCode/id"
                              ]
                                ? undefined
                                : null
                            }
                          },
                          [
                            _vm.loading["organizationAddress/zipCode/id"]
                              ? _c("a-spin", {
                                  attrs: {
                                    slot: "notFoundContent",
                                    size: "small"
                                  },
                                  slot: "notFoundContent"
                                })
                              : _vm._e(),
                            _vm._l(
                              _vm.options["organizationAddress/city/id"],
                              function(option) {
                                return _c(
                                  "a-select-option",
                                  {
                                    key: option.id,
                                    attrs: { value: option.id }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(option.name) +
                                        "\n            "
                                    )
                                  ]
                                )
                              }
                            )
                          ],
                          2
                        )
                      ],
                      1
                    ),
                    _c(
                      "a-form-item",
                      { attrs: { selfUpdate: true, label: "Közterület neve" } },
                      [
                        _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "organizationAddress/address",
                                {
                                  rules: [
                                    {
                                      required: false,
                                      whitespace: true,
                                      message: "Kérjük töltse ki!"
                                    },
                                    {
                                      max:
                                        _vm.characterCounts[
                                          "organizationAddress/address"
                                        ].max,
                                      message:
                                        "Maximum " +
                                        _vm.characterCounts[
                                          "organizationAddress/address"
                                        ].max +
                                        " karakter engedélyezett."
                                    }
                                  ],
                                  validateTrigger: ["blur", "keyup"]
                                }
                              ],
                              expression:
                                "[\n                            'organizationAddress/address',\n                            {\n                                rules: [\n                                    {\n                                      required: false,\n                                      whitespace: true,\n                                      message: 'Kérjük töltse ki!'\n                                    },\n                                    {\n                                      max: characterCounts['organizationAddress/address'].max,\n                                      message: 'Maximum ' + characterCounts['organizationAddress/address'].max + ' karakter engedélyezett.',\n                                    }\n                                ],\n                                validateTrigger: ['blur','keyup']\n                            }\n                        ]"
                            }
                          ],
                          attrs: {
                            maxLength: 25000,
                            disabled: _vm.isMobileReadonly,
                            placeholder: "Kérjük beírni (betűkkel)"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-form-item",
                      {
                        attrs: {
                          selfUpdate: true,
                          label: "Közterület típusa (utca, tér, út, dűlő stb.)"
                        }
                      },
                      [
                        _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "organizationAddress/addressType",
                                {
                                  rules: [
                                    {
                                      required: false,
                                      whitespace: true,
                                      message: "Kérjük töltse ki!"
                                    },
                                    {
                                      max:
                                        _vm.characterCounts[
                                          "organizationAddress/addressType"
                                        ].max,
                                      message:
                                        "Maximum " +
                                        _vm.characterCounts[
                                          "organizationAddress/addressType"
                                        ].max +
                                        " karakter engedélyezett."
                                    }
                                  ],
                                  validateTrigger: ["blur", "keyup"]
                                }
                              ],
                              expression:
                                "[\n                            'organizationAddress/addressType',\n                            {\n                                rules: [\n                                    {\n                                      required: false,\n                                      whitespace: true,\n                                      message: 'Kérjük töltse ki!'\n                                    },\n                                    {\n                                      max: characterCounts['organizationAddress/addressType'].max,\n                                      message: 'Maximum ' + characterCounts['organizationAddress/addressType'].max + ' karakter engedélyezett.',\n                                    }\n                                ],\n                                validateTrigger: ['blur','keyup']\n                            }\n                        ]"
                            }
                          ],
                          attrs: {
                            maxLength: 25000,
                            disabled: _vm.isMobileReadonly,
                            placeholder: "Kérjük beírni (betűkkel)"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-form-item",
                      [
                        _c(
                          "span",
                          { attrs: { slot: "label" }, slot: "label" },
                          [
                            _vm._v(
                              "\n              Házszám/helyrajzi szám\n              "
                            ),
                            _c(
                              "a-tooltip",
                              {
                                attrs: {
                                  title:
                                    "Itt a házszámon kívül más is beírható (pl. helyrajzi szám vagy más egyéb, pontos azonosító, ami az adott ingatlan helyét pontosan meghatározza). Ha nincs ilyen pontos azonosító szám a címhez, akkor kérjük a mezőt üresen hagyni."
                                }
                              },
                              [
                                _c("a-icon", {
                                  attrs: { type: "question-circle-o" }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "organizationAddress/addressNumber",
                                {
                                  rules: [
                                    {
                                      required: false,
                                      whitespace: true,
                                      message: "Kérjük töltse ki!"
                                    },
                                    {
                                      max:
                                        _vm.characterCounts[
                                          "organizationAddress/addressNumber"
                                        ].max,
                                      message:
                                        "Maximum " +
                                        _vm.characterCounts[
                                          "organizationAddress/addressNumber"
                                        ].max +
                                        " karakter engedélyezett."
                                    }
                                  ],
                                  validateTrigger: ["blur", "keyup"]
                                }
                              ],
                              expression:
                                "[\n                            'organizationAddress/addressNumber',\n                            {\n                                rules: [\n                                    {\n                                      required: false,\n                                      whitespace: true,\n                                      message: 'Kérjük töltse ki!'\n                                    },\n                                    {\n                                      max: characterCounts['organizationAddress/addressNumber'].max,\n                                      message: 'Maximum ' + characterCounts['organizationAddress/addressNumber'].max + ' karakter engedélyezett.',\n                                    }\n                                ],\n                                validateTrigger: ['blur','keyup']\n                            }\n                        ]"
                            }
                          ],
                          attrs: {
                            maxLength: 25000,
                            disabled: _vm.isMobileReadonly,
                            placeholder: "Kérjük beírni (számokkal, betűkkel)"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-form-item",
                      { attrs: { selfUpdate: true } },
                      [
                        _c(
                          "span",
                          { attrs: { slot: "label" }, slot: "label" },
                          [
                            _vm._v(
                              "\n              A vonzerőt üzemeltető szervezet központi telefonszáma (pl.: +36 1 234 5678)\n              "
                            ),
                            _c(
                              "a-tooltip",
                              {
                                attrs: {
                                  title:
                                    "Amennyiben nem rendelkezik telefonszámmal az objektum, úgy kérjük, adja meg a Tourinform iroda központi telefonszámát!"
                                }
                              },
                              [
                                _c("a-icon", {
                                  attrs: { type: "question-circle-o" }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "centralContact/phone",
                                {
                                  rules: [
                                    {
                                      required: true,
                                      pattern: /^[\/+][36]{1,2}[ ][0-9]{1,2}[ ][0-9]{3}[ ][0-9]{3,4}$/,
                                      message: "Hibás telefonszám formátum!"
                                    }
                                  ],
                                  validateTrigger: ["blur"]
                                }
                              ],
                              expression:
                                "[\n                                'centralContact/phone',\n                                {\n                                    rules: [{\n                                        required: true,\n                                        pattern: /^[\\/+][36]{1,2}[ ][0-9]{1,2}[ ][0-9]{3}[ ][0-9]{3,4}$/,\n                                        message: 'Hibás telefonszám formátum!'\n                                    }],\n                                   validateTrigger: ['blur']\n                                }\n                            ]"
                            }
                          ],
                          ref: "centralContact/phone",
                          style: { width: "160px" },
                          attrs: {
                            maxLength: 25000,
                            disabled: _vm.isMobileReadonly,
                            placeholder: "+36 1 234 5678"
                          },
                          on: {
                            change: function(event) {
                              return _vm.formatPhoneNumber(
                                event.target.value,
                                "centralContact/phone"
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-form-item",
                      { attrs: { selfUpdate: true } },
                      [
                        _c(
                          "span",
                          { attrs: { slot: "label" }, slot: "label" },
                          [
                            _vm._v(
                              "\n              A vonzerőt üzemeltető szervezet központi e-mail címe\n              "
                            ),
                            _c(
                              "a-tooltip",
                              {
                                attrs: {
                                  title:
                                    "Amennyiben nem rendelkezik e-mail címmel az objektum, úgy kérjük, adja meg a Tourinform iroda központi e-mail címét! Kérjük az e-mail címet iroda@domain.xy formátumban töltse ki!"
                                }
                              },
                              [
                                _c("a-icon", {
                                  attrs: { type: "question-circle-o" }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "centralContact/email",
                                {
                                  rules: [
                                    {
                                      required: true,
                                      whitespace: true,
                                      type: "email",
                                      message:
                                        "Kérjük a megfelelő formátumban töltse ki!"
                                    },
                                    {
                                      max:
                                        _vm.characterCounts[
                                          "centralContact/email"
                                        ].max,
                                      message:
                                        "Maximum " +
                                        _vm.characterCounts[
                                          "centralContact/email"
                                        ].max +
                                        " karakter engedélyezett."
                                    }
                                  ],
                                  validateTrigger: ["blur", "keyup"]
                                }
                              ],
                              expression:
                                "[\n                            'centralContact/email',\n                            {\n                                rules: [\n                                    {\n                                      required: true,\n                                      whitespace: true,\n                                      type: 'email',\n                                      message: 'Kérjük a megfelelő formátumban töltse ki!'\n                                    },\n                                    {\n                                      max: characterCounts['centralContact/email'].max,\n                                      message: 'Maximum ' + characterCounts['centralContact/email'].max + ' karakter engedélyezett.',\n                                    }\n                                ],\n                                validateTrigger: ['blur','keyup']\n                            }\n                        ]"
                            }
                          ],
                          attrs: {
                            type: "email",
                            maxLength: 25000,
                            disabled: _vm.isMobileReadonly,
                            placeholder: "Kérjük az e-mail címet beírni!"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-form-item",
                      {
                        attrs: {
                          selfUpdate: true,
                          label:
                            "A vonzerőt üzemeltető szervezet (első számú) vezetőjének neve"
                        }
                      },
                      [
                        _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "leaderContact/name",
                                {
                                  rules: [
                                    {
                                      required: false,
                                      whitespace: true,
                                      message: "Kérjük töltse ki!"
                                    },
                                    {
                                      max:
                                        _vm.characterCounts[
                                          "leaderContact/name"
                                        ].max,
                                      message:
                                        "Maximum " +
                                        _vm.characterCounts[
                                          "leaderContact/name"
                                        ].max +
                                        " karakter engedélyezett."
                                    }
                                  ],
                                  validateTrigger: ["blur", "keyup"]
                                }
                              ],
                              expression:
                                "[\n                            'leaderContact/name',\n                            {\n                                rules: [\n                                    {\n                                      required: false,\n                                      whitespace: true,\n                                      message: 'Kérjük töltse ki!'\n                                    },\n                                    {\n                                      max: characterCounts['leaderContact/name'].max,\n                                      message: 'Maximum ' + characterCounts['leaderContact/name'].max + ' karakter engedélyezett.',\n                                    }\n                                ],\n                                validateTrigger: ['blur','keyup']\n                            }\n                        ]"
                            }
                          ],
                          attrs: {
                            maxLength: 25000,
                            disabled: _vm.isMobileReadonly
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-form-item",
                      {
                        attrs: {
                          selfUpdate: true,
                          label:
                            "A vonzerőt üzemeltető szervezet (első számú) vezetőjének beosztása"
                        }
                      },
                      [
                        _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "leaderContact/title",
                                {
                                  rules: [
                                    {
                                      required: false,
                                      whitespace: true,
                                      message: "Kérjük töltse ki!"
                                    },
                                    {
                                      max:
                                        _vm.characterCounts[
                                          "leaderContact/title"
                                        ].max,
                                      message:
                                        "Maximum " +
                                        _vm.characterCounts[
                                          "leaderContact/title"
                                        ].max +
                                        " karakter engedélyezett."
                                    }
                                  ],
                                  validateTrigger: ["blur", "keyup"]
                                }
                              ],
                              expression:
                                "[\n                            'leaderContact/title',\n                            {\n                                rules: [\n                                    {\n                                      required: false,\n                                      whitespace: true,\n                                      message: 'Kérjük töltse ki!'\n                                    },\n                                    {\n                                      max: characterCounts['leaderContact/title'].max,\n                                      message: 'Maximum ' + characterCounts['leaderContact/title'].max + ' karakter engedélyezett.',\n                                    }\n                                ],\n                                validateTrigger: ['blur','keyup']\n                            }\n                        ]"
                            }
                          ],
                          attrs: {
                            maxLength: 25000,
                            disabled: _vm.isMobileReadonly
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-form-item",
                      {
                        attrs: {
                          selfUpdate: true,
                          label:
                            "A vonzerőt üzemeltető szervezet (első számú) vezetőjének telefonszáma"
                        }
                      },
                      [
                        _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "leaderContact/phone",
                                {
                                  rules: [
                                    {
                                      required: false,
                                      pattern: /^[\/+][36]{1,2}[ ][0-9]{1,2}[ ][0-9]{3}[ ][0-9]{3,4}$/,
                                      message: "Hibás telefonszám formátum!"
                                    }
                                  ],
                                  validateTrigger: ["blur"]
                                }
                              ],
                              expression:
                                "[\n                                'leaderContact/phone',\n                                {\n                                    rules: [{\n                                        required: false,\n                                        pattern: /^[\\/+][36]{1,2}[ ][0-9]{1,2}[ ][0-9]{3}[ ][0-9]{3,4}$/,\n                                        message: 'Hibás telefonszám formátum!'\n                                    }],\n                                   validateTrigger: ['blur']\n                                }\n                            ]"
                            }
                          ],
                          ref: "leaderContact/phone",
                          style: { width: "160px" },
                          attrs: {
                            maxLength: 25000,
                            disabled: _vm.isMobileReadonly,
                            placeholder: "+36 1 234 5678"
                          },
                          on: {
                            change: function(event) {
                              return _vm.formatPhoneNumber(
                                event.target.value,
                                "leaderContact/phone"
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-form-item",
                      {
                        attrs: {
                          selfUpdate: true,
                          label:
                            "Kapcsolattartó neve (az Adattárhoz kapcsolódóan, ha eltér a vezetőtől)"
                        }
                      },
                      [
                        _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "generalContact/name",
                                {
                                  rules: [
                                    {
                                      required: false,
                                      whitespace: true,
                                      message: "Kérjük töltse ki!"
                                    },
                                    {
                                      max:
                                        _vm.characterCounts[
                                          "generalContact/name"
                                        ].max,
                                      message:
                                        "Maximum " +
                                        _vm.characterCounts[
                                          "generalContact/name"
                                        ].max +
                                        " karakter engedélyezett."
                                    }
                                  ],
                                  validateTrigger: ["blur", "keyup"]
                                }
                              ],
                              expression:
                                "[\n                            'generalContact/name',\n                            {\n                                rules: [\n                                    {\n                                      required: false,\n                                      whitespace: true,\n                                      message: 'Kérjük töltse ki!'\n                                    },\n                                    {\n                                      max: characterCounts['generalContact/name'].max,\n                                      message: 'Maximum ' + characterCounts['generalContact/name'].max + ' karakter engedélyezett.',\n                                    }\n                                ],\n                                validateTrigger: ['blur','keyup']\n                            }\n                        ]"
                            }
                          ],
                          attrs: {
                            maxLength: 25000,
                            disabled: _vm.isMobileReadonly
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-form-item",
                      {
                        attrs: {
                          selfUpdate: true,
                          label: "Kapcsolattartó beosztása"
                        }
                      },
                      [
                        _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "generalContact/title",
                                {
                                  rules: [
                                    {
                                      required: false,
                                      whitespace: true,
                                      message: "Kérjük töltse ki!"
                                    },
                                    {
                                      max:
                                        _vm.characterCounts[
                                          "generalContact/title"
                                        ].max,
                                      message:
                                        "Maximum " +
                                        _vm.characterCounts[
                                          "generalContact/title"
                                        ].max +
                                        " karakter engedélyezett."
                                    }
                                  ],
                                  validateTrigger: ["blur", "keyup"]
                                }
                              ],
                              expression:
                                "[\n                            'generalContact/title',\n                            {\n                                rules: [\n                                    {\n                                      required: false,\n                                      whitespace: true,\n                                      message: 'Kérjük töltse ki!'\n                                    },\n                                    {\n                                      max: characterCounts['generalContact/title'].max,\n                                      message: 'Maximum ' + characterCounts['generalContact/title'].max + ' karakter engedélyezett.',\n                                    }\n                                ],\n                                validateTrigger: ['blur','keyup']\n                            }\n                        ]"
                            }
                          ],
                          attrs: {
                            maxLength: 25000,
                            disabled: _vm.isMobileReadonly
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-form-item",
                      {
                        attrs: {
                          selfUpdate: true,
                          label:
                            "Kapcsolattartó telefonos elérhetősége (pl.: +36 1 234 5678)"
                        }
                      },
                      [
                        _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "generalContact/phone",
                                {
                                  rules: [
                                    {
                                      required: false,
                                      pattern: /^[\/+][36]{1,2}[ ][0-9]{1,2}[ ][0-9]{3}[ ][0-9]{3,4}$/,
                                      message: "Hibás telefonszám formátum!"
                                    }
                                  ],
                                  validateTrigger: ["blur"]
                                }
                              ],
                              expression:
                                "[\n                                'generalContact/phone',\n                                {\n                                    rules: [{\n                                        required: false,\n                                        pattern: /^[\\/+][36]{1,2}[ ][0-9]{1,2}[ ][0-9]{3}[ ][0-9]{3,4}$/,\n                                        message: 'Hibás telefonszám formátum!'\n                                    }],\n                                   validateTrigger: ['blur']\n                                }\n                            ]"
                            }
                          ],
                          ref: "generalContact/phone",
                          style: { width: "160px" },
                          attrs: {
                            maxLength: 25000,
                            disabled: _vm.isMobileReadonly,
                            placeholder: "+36 1 234 5678"
                          },
                          on: {
                            keyup: function(event) {
                              return _vm.formatPhoneNumber(
                                event.target.value,
                                "generalContact/phone"
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-form-item",
                      { attrs: { selfUpdate: true } },
                      [
                        _c(
                          "span",
                          { attrs: { slot: "label" }, slot: "label" },
                          [
                            _vm._v(
                              "\n              Kapcsolattartó e-mail címe\n              "
                            ),
                            _c(
                              "a-tooltip",
                              {
                                attrs: {
                                  title:
                                    "Kérjük az e-mail címet iroda@domain.xy formátumban töltse ki!"
                                }
                              },
                              [
                                _c("a-icon", {
                                  attrs: { type: "question-circle-o" }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "generalContact/email",
                                {
                                  rules: [
                                    {
                                      required: false,
                                      whitespace: true,
                                      type: "email",
                                      message:
                                        "Kérjük a megfelelő formátumban töltse ki!"
                                    },
                                    {
                                      max:
                                        _vm.characterCounts[
                                          "centralContact/email"
                                        ].max,
                                      message:
                                        "Maximum " +
                                        _vm.characterCounts[
                                          "centralContact/email"
                                        ].max +
                                        " karakter engedélyezett."
                                    }
                                  ],
                                  validateTrigger: ["blur", "keyup"]
                                }
                              ],
                              expression:
                                "[\n                'generalContact/email',\n                {\n                    rules: [\n                        {\n                          required: false,\n                          whitespace: true,\n                          type: 'email',\n                          message: 'Kérjük a megfelelő formátumban töltse ki!'\n                        },\n                        {\n                          max: characterCounts['centralContact/email'].max,\n                          message: 'Maximum ' + characterCounts['centralContact/email'].max + ' karakter engedélyezett.',\n                        }\n                    ],\n                    validateTrigger: ['blur','keyup']\n                }\n            ]"
                            }
                          ],
                          attrs: {
                            type: "email",
                            disabled: _vm.isMobileReadonly,
                            maxLength: 25000
                          }
                        })
                      ],
                      1
                    ),
                    _c("a-divider"),
                    _c(
                      "a-form-item",
                      { attrs: { selfUpdate: true } },
                      [
                        _c(
                          "span",
                          { attrs: { slot: "label" }, slot: "label" },
                          [
                            _vm._v(
                              "\n            NTAK regisztrációs szám\n            "
                            ),
                            _c(
                              "a-tooltip",
                              {
                                attrs: {
                                  title:
                                    "A regisztrációs szám 10 karakter hosszú, amelyből az első 2 karakter betű (prefix az attrakció típusától függően), a maradék 8 karakter szám. Példa: AB12345678"
                                }
                              },
                              [
                                _c("a-icon", {
                                  attrs: { type: "question-circle-o" }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "ntakRegistrationNumber",
                                {
                                  rules: [
                                    {
                                      required: false,
                                      pattern: /^[a-zA-Z]{2}[0-9]{8}$/,
                                      message:
                                        "Hibás regisztrációs szám formátum!"
                                    }
                                  ],
                                  validateTrigger: ["blur", "keyup"]
                                }
                              ],
                              expression:
                                "[\n                    'ntakRegistrationNumber',\n                    {\n                        rules: [{\n                            required: false,\n                            pattern: /^[a-zA-Z]{2}[0-9]{8}$/,\n                            message: 'Hibás regisztrációs szám formátum!'\n                        }],\n                        validateTrigger: ['blur','keyup']\n                    }\n                ]"
                            }
                          ],
                          attrs: {
                            maxLength: 25000,
                            disabled: _vm.isMobileReadonly
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-form-item",
                      { attrs: { selfUpdate: true } },
                      [
                        _c(
                          "span",
                          { attrs: { slot: "label" }, slot: "label" },
                          [
                            _vm._v(
                              "\n            Szolgáltató adószáma\n            "
                            ),
                            _c(
                              "a-tooltip",
                              {
                                attrs: {
                                  title:
                                    "A szolgáltató az NTAK-ban regisztráló attrakció üzemeltetője. Kérjük az adószám tagjait kötőjellel elválasztva írja be. Példa: 12345678-1-12"
                                }
                              },
                              [
                                _c("a-icon", {
                                  attrs: { type: "question-circle-o" }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "serviceProviderVatNumber",
                                {
                                  rules: [
                                    {
                                      required: false,
                                      pattern: /^[0-9]{8}-[0-9]-[0-9]{2}$/,
                                      message: "Hibás adószám formátum!"
                                    }
                                  ],
                                  validateTrigger: ["blur", "keyup"]
                                }
                              ],
                              expression:
                                "[\n                    'serviceProviderVatNumber',\n                    {\n                        rules: [{\n                            required: false,\n                            pattern: /^[0-9]{8}-[0-9]-[0-9]{2}$/,\n                            message: 'Hibás adószám formátum!'\n                        }],\n                        validateTrigger: ['blur','keyup']\n                    }\n                ]"
                            }
                          ],
                          attrs: {
                            maxLength: 25000,
                            disabled: _vm.isMobileReadonly
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-form-item",
                      { attrs: { selfUpdate: true } },
                      [
                        _c(
                          "span",
                          { attrs: { slot: "label" }, slot: "label" },
                          [
                            _vm._v(
                              "\n              Megjegyzés\n              "
                            ),
                            _c(
                              "a-tooltip",
                              {
                                attrs: {
                                  title:
                                    "Kérjük ezt a cellát akkor töltse ki, ha a témához kapcsolódóan egyéb megjegyzése van."
                                }
                              },
                              [
                                _c("a-icon", {
                                  attrs: { type: "question-circle-o" }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("a-textarea", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "comment",
                                {
                                  rules: [
                                    {
                                      required: false,
                                      message: "Kérjük töltse ki!"
                                    }
                                  ]
                                }
                              ],
                              expression:
                                "[\n                        'comment',\n                        {\n                            rules: [{\n                                required: false,\n                                message: 'Kérjük töltse ki!'\n                            }]\n                        }\n                    ]"
                            }
                          ],
                          attrs: {
                            maxLength: 25000,
                            disabled: _vm.isMobileReadonly,
                            autoSize: { minRows: 2 }
                          }
                        })
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _c(
                "a-form-item",
                [
                  _vm.showSaveButton
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            "html-type": "submit",
                            loading: _vm.submitLoading
                          }
                        },
                        [_vm._v("\n          Mentés\n        ")]
                      )
                    : _vm._e(),
                  _vm.hasChanged()
                    ? _c(
                        "a-button",
                        {
                          attrs: { type: "secondary", "html-type": "button" },
                          on: { click: _vm.resetForm }
                        },
                        [
                          _c("a-icon", {
                            attrs: { type: "undo", theme: "outlined" }
                          }),
                          _vm._v("\n\n          Piszkozat törlése\n        ")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.currentTab
                    ? _c("span", { staticClass: "last-modification" }, [
                        _vm._v("\n          Utolsó mentés: "),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm
                                .moment(_vm.currentTab.modificationTime)
                                .format(_vm.timeStampFormat)
                                .toString()
                            )
                          )
                        ]),
                        _vm._v("  "),
                        _vm.currentTab.user
                          ? _c("span", [
                              _vm._v(
                                "(" + _vm._s(_vm.currentTab.user.userName) + ")"
                              )
                            ])
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm.submitResult.show
                    ? _c("a-alert", {
                        style: {
                          marginTop: "10px"
                        },
                        attrs: {
                          message: _vm.submitResult.message,
                          type: _vm.submitResult.type,
                          showIcon: "",
                          closable: "",
                          afterClose: function() {
                            return (_vm.submitResult.show = false)
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }