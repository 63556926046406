<template xmlns="">
  <div class="app-form-container">
    <a-spin :spinning="formDataLoading">
      <a-form :form="form" v-on:submit="submit" layout="vertical">
        <a-form-item>
          <a-button type="primary" html-type="submit" :loading="submitLoading" v-if="showSaveButton">
            Mentés
          </a-button>

          <a-button type="secondary" html-type="button" v-on:click="resetForm" v-if="hasChanged()">
            <a-icon type="undo"
                    theme="outlined" />

            Piszkozat törlése
          </a-button>

          <span v-if="currentTab" class="last-modification">
            Utolsó mentés: <strong>{{ moment(currentTab.modificationTime).format(timeStampFormat).toString() }}</strong> &nbsp;<span v-if="currentTab.user">({{ currentTab.user.userName }})</span>
          </span>

          <a-alert
              :style="{
                        marginTop: '10px'
                    }"
              v-if="submitResult.show"
              :message="submitResult.message"
              :type="submitResult.type"
              showIcon
              closable
              :afterClose="() => submitResult.show = false"
          />
        </a-form-item>

        <a-divider orientation="left">
          KAPCSOLÓDÓ VONZERŐK
        </a-divider>

        <a-form-item label="Kapcsolódó vonzerők">
          <a-select
              :getPopupContainer="getParentTriggerNode()"
              v-decorator="[
                            'relatedAttractions/id',
                            {
                                rules: [
                                    {
                                    required: true,
                                    message: 'Kérjük töltse ki!'
                                  },
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
              placeholder="Kezdjen gépelni"
              mode="multiple"
              showSearch
              :showArrow="false"
              :defaultActiveFirstOption="false"
              :filterOption="false"
              :style="{width: '100%'}"
              :loading="loading['relatedAttractions/id']"
              v-on:search="relatedAttractionsAutoComplete"
              v-on:dropdownVisibleChange="relatedAttractionsAutoComplete"
              :notFoundContent="loading['relatedAttractions/id'] ? undefined : null"
          >
            <a-spin v-if="loading['relatedAttractions/id']" slot="notFoundContent" size="small" />

            <a-select-option
                v-for="option in options['relatedAttractions/id']"
                :key="option.id"
                :value="option.id"
            >
              {{ option.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-divider orientation="left">
          VONZERŐ NEVE
        </a-divider>

        <a-form-item label="Vonzerő neve" :selfUpdate="true">
          <a-textarea
              :maxLength="25000"
              v-decorator="[
                            'name',
                            {
                                rules: [
                                    {
                                      required: true,
                                      preserve: true,
                                      message: 'Kérjük töltse ki!'
                                    },
                                    {
                                      max: characterCounts.name.max,
                                      message: 'Maximum ' + characterCounts.name.max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur', 'keyup']
                            }
                        ]"
              :autoSize="{minRows: 2}"
              placeholder="Vonzerő neve"
          />
        </a-form-item>

        <div v-for="(value, locale) in translatedFormFields['name']" :key="'name-' + locale">
          <a-row v-if="value !== null">
            <div>
              <a-form-item :selfUpdate="true">
                <div slot="label">
                  Nyelv
                </div>

                <a-select
                    style="width: 90%"
                    :getPopupContainer="getParentTriggerNode()"
                    v-on:change="updateTranslatedFormField('name')"
                    :ref="getTranslatedLocaleName('name', locale)"
                    :disabled="isTranslationEditDisabled"
                    :filterOption="filterSelectNames"
                    v-decorator="[
                                getTranslatedLocaleName('name', locale),
                                {
                                    initialValue: locale,
                                    rules: [{
                                        required: false,
                                    }]
                                }
                            ]"
                >
                  <a-select-option
                      v-for="language in languages"
                      :key="language.name"
                      :value="language.locale"
                      :disabled="!isLanguageAvailable('name', language.locale)"
                  >
                    {{ language.name.toLowerCase() }}
                  </a-select-option>
                </a-select>

                <a-tooltip title="Mező törlése" v-if="isAdmin">
                  <a-icon
                      class="dynamic-delete-button"
                      type="delete"
                      theme="filled"
                      v-on:click="() => deleteTranslatedFormField('name', locale)"
                  />
                </a-tooltip>
              </a-form-item>
            </div>

            <div>
              <a-form-item :selfUpdate="true">
                <span slot="label">
                  Vonzerő neve {{ getLanguageName(locale) }} nyelven
                </span>

                <a-textarea
                    :maxLength="25000"
                    :disabled="false"
                    v-on:blur="updateTranslatedFormField('name')"
                    :ref="getTranslatedFieldName('name', locale)"
                    :readOnly="isTranslationEditDisabled"
                    v-decorator="[
                                    getTranslatedFieldName('name', locale),
                                    {
                                      initialValue: value,
                                      rules: [
                                          {
                                            required: false,
                                            preserve: true,
                                            message: 'Kérjük töltse ki!'
                                          },
                                          {
                                            max: characterCounts[getTranslatedFieldName('name', locale)].max,
                                            message: 'Maximum ' + characterCounts[getTranslatedFieldName('name', locale)].max + ' karakter engedélyezett.',
                                          }
                                      ],
                                      validateTrigger: ['blur', 'keyup']
                                  }
                                ]"
                    :autoSize="{minRows: 2}"
                />
              </a-form-item>
            </div>
          </a-row>
        </div>

        <a-form-item>
          <a-button
              type="dashed"
              style="width: 100%"
              :disabled="isOfficeEmployee || !getFirstAvailableLanguage('name')"
              v-on:click="addTranslatedFormField('name')"
          >
            <a-icon type="plus" />

            Idegennyelvű név hozzáadása
          </a-button>
        </a-form-item>

        <a-form-item label="Kategória">
          <a-radio-group
              v-on:change="loadOptions('category/id', 'group/id')"
              v-decorator="['group/id', {
                rules:
                [
                  {
                    required: true,
                    preserve: true,
                    message: 'Kérjük válasszon!',
                    type: 'integer'
                  }
                ],
                    validateTrigger: ['blur', 'change']
                }
              ]"
          >
            <a-radio-button
                v-for="category in options['group/id']"
                :key="category.id"
                :value="category.id"
            >
              {{ category.label }}

              <a-icon :type="category.icon" />
            </a-radio-button>
          </a-radio-group>
        </a-form-item>

        <a-form-item label="Alkategória">
          <a-select
              :getPopupContainer="getParentTriggerNode()"
              v-on:change="loadOptions('labels/id', 'category/id')"
              :loading="loading['category/id']"
              v-decorator="['category/id', {
                            rules: [{
                                required: true,
                                message: 'Kérjük töltse ki!'
                            }],
                            validateTrigger: ['blur', 'change']
                        }]"
              placeholder="Kérjük válasszon!"
              :disabled="!form.getFieldValue('group/id')"
              :filterOption="filterSelectNames"
          >
            <a-select-option
                v-for="category in options['category/id']"
                :key="category.name"
                :value="category.id"
            >
              {{ category.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="Címke">
          <a-select
              :getPopupContainer="getParentTriggerNode()"
              :loading="loading['labels/id']"
              mode="multiple"
              v-decorator="['labels/id', {
                            rules: [{
                                required: true,
                                message: 'Kérjük töltse ki!'
                            }],
                            validateTrigger: ['blur', 'change']
                        }]"
              placeholder="Kérjük válasszon!"
              :disabled="!form.getFieldValue('category/id')"
              :filterOption="filterSelectNames"
              v-on:change="(values) => checkSingular(values, 'labels/id')"
          >
            <a-select-option
                v-for="category in options['labels/id']"
                :key="category.name"
                :value="category.id"
                :disabled="!category.singular && singularMode['labels/id']"
            >
              {{ category.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="Kiemelt desztináció címke">
          <a-select
              :getPopupContainer="getParentTriggerNode()"
              :loading="loading['featuredDestinations/id']"
              mode="multiple"
              v-decorator="['featuredDestinations/id', {
                            rules: [{
                                required: false,
                                message: 'Kérjük töltse ki!'
                            }],
                            validateTrigger: ['blur', 'change']
                        }]"
              placeholder="Kérjük válasszon!"
              :filterOption="filterSelectNames"
              v-on:dropdownVisibleChange="(open) => open && loadOptions('featuredDestinations/id')"
              v-on:change="(values) => checkSingular(values, 'featuredDestinations/id')"
          >
            <a-select-option
                v-for="category in options['featuredDestinations/id']"
                :key="category.name"
                :value="category.id"
                :disabled="!category.singular && singularMode['featuredDestinations/id']"
            >
              {{ category.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <h4>Látogató címkék</h4>

        <a-form-item>
          <span slot="label">
              Kiemelt célcsoportok

              <a-icon v-on:click="() => modals.targetAudience = true" type="question-circle-o" />

              <a-modal
                  :mask="false"
                  :maskClosable="true"
                  :footer="null"
                  v-model=" modals.targetAudience" title="Melyik kiemelt célcsoportoknak lehet releváns a vonzerő?"
                  v-on:ok="() => modals.targetAudience = false">
                  <p>
                      <strong>Értéktudatos fiatalok:</strong><span> 25-40 év közöttiek, gyakran baráti társasággal érkeznek. Érdeklődnek a művészet, a kultúra és a divat iránt, továbbá jellemző rájuk a kíváncsiság, élménykeresők, nyitottak az újdonságra. Rendszeresen utaznak, érdeklődnek a minőségi éjszakai élet iránt, igénylik az igényes gasztronómiát és a minőségi street foodot, valamint hajlamosak a vásárlásra.</span>
                  </p>
                  <p>
                      <strong>Kozmopolita utazók:</strong><span> 30-55 év közöttiek. Kevésbé szeretnek alkalmazkodni, minőségi értékajánlatra vágynak. Nyitottak lehetnek a day-tripekre, extra élményekre (pl. privát vacsora), illetve szívesen látogatnak fine dining éttermeket. Érdeklődnek a koncertek, a kiállítások, a galériák, a fine dining, a borok, a kávéházak iránt, továbbá érdekelheti őket a privát guide használata, és jellemzően jelentős értékben vásárolnak.</span>
                  </p>
                  <p>
                      <strong>Élménykereső családosok:</strong><span> Szülő(k), legalább egy gyerekkel. Érdeklődnek a múzeumok, az élményfürdők, a kirándulások, a programok és a kiállítások iránt. Fontos szempont számukra a biztonság, valamint értékelik a zöld területeket, parkokat.</span>
                  </p>
                  <p>
                      <strong>Aktív szeniorok:</strong><span> Jellemzően feltöltődésre vágyó nyugdíjasok, akik nyitottak városnézésre és a gyógyfürdőkre. A kulturális turizmus, városlátogatások, aktív időtöltés, bor és gasztronómia élvezete mind fontos motivációt jelenthetnek számukra. Kiemelt szerepe van az egészségturizmus nyújtotta lehetőségeknek és az akadálymentes turisztikai kínálatnak.</span>
                  </p>
              </a-modal>
          </span>

          <a-select
              :getPopupContainer="getParentTriggerNode()"
              :loading="loading['targetGroups/id']"
              mode="multiple"
              v-decorator="['targetGroups/id', {
                            rules: [{
                                required: true,
                                message: 'Kérjük töltse ki!'
                            }],
                            validateTrigger: ['blur', 'change']
                        }]"
              placeholder="Kérjük válasszon!"
              :filterOption="filterSelectNames"
              v-on:dropdownVisibleChange="(open) => open && loadOptions('targetGroups/id')"
              v-on:change="(values) => checkSingular(values, 'targetGroups/id')"
          >
            <a-select-option
                v-for="category in options['targetGroups/id']"
                :key="category.name"
                :value="category.id"
                :disabled="!category.singular && singularMode['targetGroups/id']"
            >
              {{ category.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="Utazás típusa">
          <a-select
              :getPopupContainer="getParentTriggerNode()"
              :loading="loading['journeyTypes/id']"
              mode="multiple"
              v-decorator="['journeyTypes/id', {
                            rules: [{
                                required: true,
                                message: 'Kérjük töltse ki!'
                            }],
                            validateTrigger: ['blur', 'change']
                        }]"
              placeholder="Kérjük válasszon!"
              :filterOption="filterSelectNames"
              v-on:dropdownVisibleChange="(open) => open && loadOptions('journeyTypes/id')"
              v-on:change="(values) => checkSingular(values, 'journeyTypes/id')"
          >
            <a-select-option
                v-for="category in options['journeyTypes/id']"
                :key="category.name"
                :value="category.id"
                :disabled="!category.singular && singularMode['journeyTypes/id']"
            >
              {{ category.name }}
            </a-select-option>
          </a-select>
        </a-form-item>


        <a-form-item label="Helyszín jellege">
          <a-select
              :getPopupContainer="getParentTriggerNode()"
              :loading="loading['siteNatures/id']"
              mode="multiple"
              v-decorator="['siteNatures/id', {
                            rules: [{
                                required: true,
                                message: 'Kérjük töltse ki!'
                            }],
                            validateTrigger: ['blur', 'change']
                        }]"
              placeholder="Kérjük válasszon!"
              :filterOption="filterSelectNames"
              v-on:dropdownVisibleChange="(open) => open && loadOptions('siteNatures/id')"
              v-on:change="(values) => checkSingular(values, 'siteNatures/id')"
          >
            <a-select-option
                v-for="category in options['siteNatures/id']"
                :key="category.name"
                :value="category.id"
                :disabled="!category.singular && singularMode['siteNatures/id']"
            >
              {{ category.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="Szezonalitás">
          <a-select
              :getPopupContainer="getParentTriggerNode()"
              :loading="loading['seasonalities/id']"
              mode="multiple"
              v-decorator="['seasonalities/id', {
                            rules: [{
                                required: true,
                                message: 'Kérjük töltse ki!'
                            }],
                            validateTrigger: 'blur'
                        }]"
              placeholder="Kérjük válasszon!"
              :filterOption="filterSelectNames"
              v-on:dropdownVisibleChange="(open) => open && loadOptions('seasonalities/id')"
              v-on:change="(values) => checkSingular(values, 'seasonalities/id')"
          >
            <a-select-option
                v-for="category in options['seasonalities/id']"
                :key="category.name"
                :value="category.id"
                :disabled="!category.singular && singularMode['seasonalities/id']"
            >
              {{ category.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="Hozzáférhetőség">
          <a-select
              :getPopupContainer="getParentTriggerNode()"
              :loading="loading['accessibilities/id']"
              mode="multiple"
              v-decorator="['accessibilities/id', {
                            rules: [{
                                required: true,
                                message: 'Kérjük töltse ki!'
                            }],
                            validateTrigger: 'blur'
                        }]"
              placeholder="Kérjük válasszon!"
              :filterOption="filterSelectNames"
              v-on:dropdownVisibleChange="(open) => open && loadOptions('accessibilities/id')"
              v-on:change="(values) => checkSingular(values, 'accessibilities/id')"
          >
            <a-select-option
                v-for="category in options['accessibilities/id']"
                :key="category.name"
                :value="category.id"
                :disabled="!category.singular && singularMode['accessibilities/id']"
            >
              {{ category.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-divider orientation="left">
          VONZERŐ MINŐSÍTÉSE
        </a-divider>

        <a-form-item>
          <span slot="label">
              Vonzerő minősítése

              <a-icon v-on:click="() => modals.classification = true" type="question-circle-o" />

              <a-modal
                  :mask="false"
                  :maskClosable="true"
                  :footer="null"
                  v-model="modals.classification" v-on:ok="() => modals.classification = false">
                  <div slot="title">
                      Kritériumrendszer a vonzerők minősítéséhez <br>
                      (5-7 értékeket csak MTÜ admin felhasználók rendelhetnek a vonzerőkhöz.)
                  </div>

                  <p>
                      <strong>Lokális (potenciális, inaktív) (1) </strong><span> Olyan vonzerő, mely attrakcióvá fejleszthető. Turisztikai érdeklődésre számot tarthat, de hiányzik a bemutatás infrastruktúrája. Lehet egy természeti érték, de akár egy templom, egy várrom stb. Ide soroljuk azokat is, melyek turisztikailag azért nem értelmezhetőek, mert zárva vannak, nem működnek. </span>
                  </p>
                  <p>
                      <strong>Lokális (2) </strong><span> Alkalmas turisták fogadására, nem csak helyi lakosok érdeklődését szolgálja ki, de látogatói jellemzően 20-30 km-es körből érkeznek. Kifejezetten értelmezhető egy turisztikai célú utazás során, van történelmi, kulturális, természeti mondanivalója. Kiegészítő attrakciója a településnek, vendégéjszakát kevésbé generál.</span>
                  </p>
                  <p>
                      <strong>Regionális (3) </strong><span> 100 km-en belülről vonzza a látogatói többségét, látogatók száma minimum 20 ezer fő/év. Ismert, népszerű vonzerő, attrakció. Utazási döntést befolyásol, de szükséges több turisztikai vonzerő együttes jelenléte az utazáshoz. Jellemzően belföldi vendégek látogatják, generálhat vendégéjszakát, van helyettesítő terméke, hasonló profilú, látogatószámú attrakció megtalálható az országban több helyen. Tematikus utak ide sorolhatóak. Önállóan nem generál vendégéjszakát.</span>
                  </p>
                  <p>
                      <strong>Országos/regionális (4) </strong><span> 100-150 kilométerről látható, jellemzően belföldi vendégek látogatják, látogatóforgalma minimum 100 ezer fő/év. Határmenti elhelyezkedés esetén érezhető az érdeklődés a szomszédos ország(ok)ból. Utazási döntést generál, több vendégéjszaka eltöltésére ösztönöz, desztinációs márkát épít.</span>
                  </p>
                  <p>
                      <strong>Országos (5)  </strong><span> A belföldi utazók többségének "listáján" szerepel, országosan ismert, látogatóforgalma minimum 100 ezer fő/év, országimázs építésre alkalmas, frekventált üdülőterületen található, önálló utazási döntésre alkalmas, jellemzően belföldi vendégek látogatják, az ország másik feléből is képes vendéget vonzani.</span>
                  </p>
                  <p>
                      <strong>Nemzetközi (6)  </strong><span> Utazási döntést befolyásol nemzetközi piacról, látogatóforgalma minimum 200 ezer fő/év, jellemzően egész évben látogathatóak, de bekerülhet magas látogatóforgalmú, szezonális nyitva tartású attrakció is (pl. strandok), nemzetközi színvonal, alkalmas országmárka építésre, minimum 30% külföldi vendég, vendégéjszakát generál, több napos látogatásra ösztönöz.</span>
                  </p>
                  <p>
                      <strong>Nemzetközi + (7)  </strong><span> kifejezetten az ország turisztikai márkázására alkalmas nemzetközi színvonalú és látogatottságú vonzerő. Számuk behatárolt, az ország első 50 nemzetközi viszonylatban fontos TOB-ja.</span>
                  </p>
              </a-modal>
          </span>

          <a-select
              :disabled="!isAdmin && form.getFieldValue('classification') > 4"
              placeholder="Kérjük válasszon!"
              v-decorator="[
                        'classification',
                        {
                            rules: [{
                                required: true,
                                message: 'Kérjük töltse ki!'
                            }]
                        }
                    ]"
          >
            <a-select-option
                v-for="number in 7"
                :key="number"
                :value="number"
                :disabled="!isAdmin && number > 4"
            >
              {{ number }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-divider orientation="left">
          VONZERŐ ELHELYEZKEDÉSE
        </a-divider>

        <p>
          Az alábbi sorokban kérjük a vonzerő pontos elhelyezkedésére vonatkozó adatok megadását.
        </p>

        <a-form-item label="Irányítószám">
          <a-select
              v-decorator="[
                            'address/zipCode/id',
                            {
                                rules: [
                                    {
                                    required: true,
                                    whitespace: true,
                                    message: 'Kérjük töltse ki!',
                                    type: 'integer'
                                  },
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
              placeholder="Kezdjen gépelni"
              showSearch
              :showArrow="false"
              :defaultActiveFirstOption="false"
              :getPopupContainer="getParentTriggerNode()"
              :filterOption="false"
              :style="{width: '100%'}"
              v-on:search="(value) => zipCodeAutoComplete(value,'address/zipCode/id')"
              v-on:change="(value) => {fillCityOptions(value,'address/zipCode/id','address/city/id')}"
              v-on:dropdownVisibleChange="(open) => open && zipCodeAutoComplete(null, 'address/zipCode/id')"
              :notFoundContent="loading['address/zipCode/id'] ? undefined : null"
          >
            <a-spin v-if="loading['address/zipCode/id']" slot="notFoundContent" size="small" />

            <a-select-option
                v-for="option in options['address/zipCode/id']"
                :key="option.code"
                :value="option.id"
            >
              {{ option.code }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="Település neve">
          <a-select
              v-decorator="[
                            'address/city/id',
                            {
                                rules: [
                                    {
                                    required: true,
                                    whitespace: true,
                                    message: 'Kérjük töltse ki!',
                                    type: 'integer'
                                  },
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
              placeholder="Töltse ki az irányítószám mezőt!"
              :showArrow="false"
              :disabled="!options['address/city/id'].length"
              :defaultActiveFirstOption="true"
              :filterOption="false"
              :style="{width: '100%'}"
              :notFoundContent="loading['address/zipCode/id'] ? undefined : null"
          >
            <a-spin v-if="loading['address/zipCode/id']" slot="notFoundContent" size="small" />

            <a-select-option
                v-for="option in options['address/city/id']"
                :key="option.id"
                :value="option.id"
            >
              {{ option.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <!--        <a-form-item-->
        <!--            :selfUpdate="true"-->
        <!--            label="Közterület neve"-->
        <!--        >-->
        <!--          <a-input-->
        <!--              :maxLength="25000"-->
        <!--              :disabled="isChecked('address/addressNotRelevant')"-->
        <!--              v-decorator="[-->
        <!--                            'address/address',-->
        <!--                            {-->
        <!--                                rules: [-->
        <!--                                    {-->
        <!--                                      required: !isChecked('address/addressNotRelevant'),-->
        <!--                                      whitespace: true,-->
        <!--                                      message: 'Kérjük töltse ki!'-->
        <!--                                    },-->
        <!--                                    {-->
        <!--                                      max: characterCounts['address/address'].max,-->
        <!--                                      message: 'Maximum ' + characterCounts['address/address'].max + ' karakter engedélyezett.',-->
        <!--                                    }-->
        <!--                                ],-->
        <!--                                validateTrigger: ['blur','keyup']-->
        <!--                            }-->
        <!--                        ]"-->
        <!--              placeholder="Kérjük beírni (betűkkel)"-->
        <!--          >-->
        <!--          </a-input>-->

        <!--          <a-checkbox-->
        <!--              v-decorator="[-->
        <!--                        'address/addressNotRelevant',-->
        <!--                        {-->
        <!--                            rules: [{-->
        <!--                                required: false-->
        <!--                            }]-->
        <!--                        }-->
        <!--                    ]"-->
        <!--              :checked="isChecked('address/addressNotRelevant')"-->
        <!--              v-on:change="() => relevancyCheckboxChange('address/address')"-->
        <!--          >-->
        <!--            Nem releváns-->
        <!--          </a-checkbox>-->
        <!--        </a-form-item>-->

        <!--        <a-form-item-->
        <!--            :selfUpdate="true"-->
        <!--            label="Közterület típusa (utca, tér, út, dűlő stb.)"-->
        <!--        >-->
        <!--          <a-input-->
        <!--              :maxLength="25000"-->
        <!--              :disabled="isChecked('address/addressTypeNotRelevant')"-->
        <!--              v-decorator="[-->
        <!--                            'address/addressType',-->
        <!--                            {-->
        <!--                                rules: [-->
        <!--                                    {-->
        <!--                                      required: !isChecked('address/addressTypeNotRelevant'),-->
        <!--                                      whitespace: true,-->
        <!--                                      message: 'Kérjük töltse ki!'-->
        <!--                                    },-->
        <!--                                    {-->
        <!--                                      max: characterCounts['address/addressType'].max,-->
        <!--                                      message: 'Maximum ' + characterCounts['address/addressType'].max + ' karakter engedélyezett.',-->
        <!--                                    }-->
        <!--                                ],-->
        <!--                                validateTrigger: ['blur','keyup']-->
        <!--                            }-->
        <!--                        ]"-->
        <!--              placeholder="Kérjük beírni (betűkkel)"-->
        <!--          >-->
        <!--          </a-input>-->

        <!--          <a-checkbox-->
        <!--              v-decorator="[-->
        <!--                        'address/addressTypeNotRelevant',-->
        <!--                        {-->
        <!--                            rules: [{-->
        <!--                                required: false-->
        <!--                            }]-->
        <!--                        }-->
        <!--                    ]"-->
        <!--              :checked="isChecked('address/addressTypeNotRelevant')"-->
        <!--              v-on:change="() => relevancyCheckboxChange('address/addressType')"-->
        <!--          >-->
        <!--            Nem releváns-->
        <!--          </a-checkbox>-->
        <!--        </a-form-item>-->

        <!--        <a-form-item>-->
        <!--          <span slot="label">-->
        <!--              Házszám/helyrajzi szám-->
        <!--              <a-tooltip-->
        <!--                  title="Itt a házszámon kívül más is beírható (pl. helyrajzi szám vagy más egyéb, pontos azonosító, ami az adott ingatlan helyét pontosan meghatározza). Ha nincs ilyen pontos azonosító szám a címhez, akkor kérjük a mezőt üresen hagyni."-->
        <!--              >-->
        <!--                  <a-icon type="question-circle-o" />-->
        <!--              </a-tooltip>-->
        <!--          </span>-->

        <!--          <a-input-->
        <!--              :maxLength="25000"-->
        <!--              :disabled="isChecked('address/addressNumberNotRelevant')"-->
        <!--              v-decorator="[-->
        <!--                            'address/addressNumber',-->
        <!--                            {-->
        <!--                                rules: [-->
        <!--                                    {-->
        <!--                                      required: !isChecked('address/addressNumberNotRelevant'),-->
        <!--                                      whitespace: true,-->
        <!--                                      message: 'Kérjük töltse ki!'-->
        <!--                                    },-->
        <!--                                    {-->
        <!--                                      max: characterCounts['address/addressNumber'].max,-->
        <!--                                      message: 'Maximum ' + characterCounts['address/addressNumber'].max + ' karakter engedélyezett.',-->
        <!--                                    }-->
        <!--                                ],-->
        <!--                                validateTrigger: ['blur','keyup']-->
        <!--                            }-->
        <!--                        ]"-->
        <!--              placeholder="Kérjük beírni (számokkal, betűkkel)"-->
        <!--          />-->

        <!--          <a-checkbox-->
        <!--              v-decorator="[-->
        <!--                        'address/addressNumberNotRelevant',-->
        <!--                        {-->
        <!--                            rules: [{-->
        <!--                                required: false-->
        <!--                            }]-->
        <!--                        }-->
        <!--                    ]"-->
        <!--              :checked="isChecked('address/addressNumberNotRelevant')"-->
        <!--              v-on:change="() => relevancyCheckboxChange('address/addressNumber')"-->
        <!--          >-->
        <!--            Nem releváns-->
        <!--          </a-checkbox>-->
        <!--        </a-form-item>-->

        <!--        <a-form-item-->
        <!--            label="Postázási cím"-->
        <!--            :selfUpdate="true"-->
        <!--        >-->
        <!--          <a-textarea-->
        <!--              :maxLength="25000"-->
        <!--              v-decorator="[-->
        <!--                            'mailingAddress',-->
        <!--                            {-->
        <!--                                rules: [-->
        <!--                                    {-->
        <!--                                      required: true,-->
        <!--                                      preserve: true,-->
        <!--                                      message: 'Kérjük töltse ki!'-->
        <!--                                    },-->
        <!--                                    {-->
        <!--                                      max: characterCounts.mailingAddress.max,-->
        <!--                                      message: 'Maximum ' + characterCounts.mailingAddress.max + ' karakter engedélyezett.',-->
        <!--                                    }-->
        <!--                                ],-->
        <!--                                validateTrigger: ['blur', 'keyup']-->
        <!--                            }-->
        <!--                        ]"-->
        <!--              :autoSize="{minRows: 1}"-->
        <!--          />-->
        <!--        </a-form-item>-->

        <!--        <a-form-item :selfUpdate="true">-->
        <!--          <span slot="label">-->
        <!--              A vonzerő GPS koordinátája: szélességi fok-->

        <!--              <a-tooltip :title="coordinateTooltipLatitude">-->
        <!--                  <a-icon type="question-circle-o" />-->
        <!--              </a-tooltip>-->
        <!--          </span>-->

        <!--          <a-input-->
        <!--              :maxLength="25000"-->
        <!--              v-on:change="(event) => formatCoordinate(event.target.value, 'address/lat')"-->
        <!--              v-decorator="[-->
        <!--                            'address/lat',-->
        <!--                            {-->
        <!--                                rules: [-->
        <!--                                    {-->
        <!--                                      required: true,-->
        <!--                                      whitespace: true,-->
        <!--                                      pattern: /^4([-+]?)([\d]{1})(((\.)(\d+)))$/g,-->
        <!--                                      message: 'A koordináták formátuma helytelen!'-->
        <!--                                    },-->
        <!--                                    {-->
        <!--                                      type: 'float',-->
        <!--                                      message: 'Kérjük adjon meg valós GPS koordinátákat!',-->
        <!--                                      transform: (value) => {-->
        <!--                                        value = parseFloat(value);-->

        <!--                                        return isNaN(value) ? null: value;-->
        <!--                                      },-->
        <!--                                      min: 45.72,-->
        <!--                                      max: 48.60-->
        <!--                                    }-->
        <!--                                    ],-->
        <!--                                validateTrigger: ['blur','keyup']-->
        <!--                            }-->
        <!--                        ]"-->
        <!--              placeholder="Kérjük a koordinátát az alábbi formátumban adja meg: 47.507346"-->
        <!--          >-->
        <!--          </a-input>-->
        <!--        </a-form-item>-->

        <!--        <a-form-item :selfUpdate="true">-->
        <!--          <span slot="label">-->
        <!--            A vonzerő GPS koordinátája: hosszúsági fok-->

        <!--            <a-tooltip :title="coordinateTooltipLongitude">-->
        <!--                <a-icon type="question-circle-o" />-->
        <!--            </a-tooltip>-->
        <!--          </span>-->

        <!--          <a-input-->
        <!--              :maxLength="25000"-->
        <!--              v-on:change="(event) => formatCoordinate(event.target.value, 'address/long')"-->
        <!--              v-decorator="[-->
        <!--                            'address/long',-->
        <!--                            {-->
        <!--                                rules: [-->
        <!--                                    {-->
        <!--                                      required: true,-->
        <!--                                      whitespace: true,-->
        <!--                                      pattern: /^(1|2)(([-+]?)([\d]{1})((\.)(\d+))?)$/g,-->
        <!--                                      message: 'A koordináták formátuma helytelen!'-->
        <!--                                    },-->
        <!--                                    {-->
        <!--                                      type: 'float',-->
        <!--                                      message: 'Kérjük adjon meg valós GPS koordinátákat!',-->
        <!--                                      transform: (value) => {-->
        <!--                                        value = parseFloat(value);-->

        <!--                                        return isNaN(value) ? null: value;-->
        <!--                                      },-->
        <!--                                      min: 16.10,-->
        <!--                                      max: 23-->
        <!--                                    }],-->
        <!--                                validateTrigger: ['blur','keyup']-->
        <!--                            }-->
        <!--                        ]"-->
        <!--              placeholder="Kérjük a koordinátát az alábbi formátumban adja meg: 19.046610"-->
        <!--          >-->
        <!--          </a-input>-->
        <!--        </a-form-item>-->

        <a-divider orientation="left">
          A VONZERŐ HONLAPJA
        </a-divider>

        <a-form-item>
          <span slot="label">
              Vonzerő honlapjának elérhetősége

              <a-tooltip title="Kérjük a weboldal címét http(s)://www.weboldal.xy formátumban töltse ki!">
                  <a-icon type="question-circle-o" />
              </a-tooltip>
          </span>

          <a-input
              :maxLength="25000"
              :disabled="isChecked('website/notRelevant')"
              v-on:change="(event) => transformEncodedUrl(event.target.value, 'website/url')"
              v-decorator="[
                        'website/url',
                        {
                            rules: [
                                    {
                                      required: !isChecked('website/notRelevant'),
                                      message: 'Kérjük a megfelelő formátumban töltse ki!',
                                      pattern: isChecked('website/notRelevant') ? undefined : /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
                                    }
                                  ],
                            validateTrigger: ['blur', 'keyup']
                        }
                    ]"
          />

          <a-checkbox
              v-decorator="[
                        'website/notRelevant',
                        {
                            rules: [{
                                required: false
                            }]
                        }
                    ]"
              :checked="isChecked('website/notRelevant')"
              v-on:change="() => relevancyCheckboxChange('website/url')"
          >
            Nem rendelkezik a vonzerő weboldallal
          </a-checkbox>
        </a-form-item>

        <!--        <a-form-item-->
        <!--            label="A vonzerő honlapjának idegen nyelvei"-->
        <!--            v-show="!isChecked('website/notRelevant')"-->
        <!--        >-->
        <!--          <a-select-->
        <!--              :getPopupContainer="getParentTriggerNode()"-->
        <!--              mode="multiple"-->
        <!--              v-decorator="[-->
        <!--                        'website/languages/id',-->
        <!--                        {-->
        <!--                            rules: [],-->
        <!--                            validateTrigger: ['blur']-->
        <!--                        }-->
        <!--                    ]"-->
        <!--              placeholder="Kérjük válasszon!"-->
        <!--              :filterOption="filterSelectNames"-->
        <!--          >-->
        <!--            <a-select-option-->
        <!--                v-for="language in options['website/languages/id']"-->
        <!--                :key="language.name"-->
        <!--                :value="language.id"-->
        <!--            >-->
        <!--              {{ language.name }}-->
        <!--            </a-select-option>-->
        <!--          </a-select>-->
        <!--        </a-form-item>-->

        <!--        <a-form-item v-show="!isChecked('website/notRelevant')" :selfUpdate="true">-->
        <!--          <span slot="label">-->
        <!--              Egyéb nyelvek-->
        <!--              <a-tooltip-->
        <!--                  title="Ha a felsoroltakon kívül egyéb nyelven is elérhető a honlap, kérjük az egyéb nyelveket itt felsorolni"-->
        <!--              >-->
        <!--                  <a-icon type="question-circle-o" />-->
        <!--              </a-tooltip>-->
        <!--          </span>-->

        <!--          <a-textarea-->
        <!--              :maxLength="25000"-->
        <!--              v-decorator="['website/comment',-->
        <!--                        {-->
        <!--                            rules: []-->
        <!--                        }-->
        <!--                    ]"-->
        <!--              :autoSize="{minRows: 2}"-->
        <!--          />-->
        <!--        </a-form-item>-->

        <!--        <a-divider orientation="left">-->
        <!--          SAJÁT KÖZÖSSÉGI MÉDIA OLDALAK-->
        <!--        </a-divider>-->

        <!--        <h4>Facebook oldal</h4>-->

        <!--        <a-form-item>-->
        <!--          <span slot="label">-->
        <!--              Facebook oldal elérhetősége-->

        <!--              <a-tooltip-->
        <!--                  title="Kérjük a Facebook linket az alábbi formátumban adja meg: https://www.facebook.com/parlament.hu"-->
        <!--              >-->
        <!--                  <a-icon type="question-circle-o" />-->
        <!--              </a-tooltip>-->
        <!--          </span>-->

        <!--          <a-input-->
        <!--              :maxLength="25000"-->
        <!--              :disabled="isChecked('facebook/notRelevant')"-->
        <!--              v-on:change="(event) => transformEncodedUrl(event.target.value, 'facebook/url')"-->
        <!--              v-decorator="[-->
        <!--                        'facebook/url',-->
        <!--                        {-->
        <!--                            rules: [{-->
        <!--                                required: !isChecked('facebook/notRelevant'),-->
        <!--                                message: 'Kérjük a megfelelő formátumban töltse ki!',-->
        <!--                                pattern: /^(http|https):\/\/(?:www.)?facebook.com/g-->
        <!--                            }],-->
        <!--                            validateTrigger: ['blur', 'keyup']-->
        <!--                        }-->
        <!--                    ]"-->
        <!--          />-->

        <!--          <a-checkbox-->
        <!--              v-decorator="[-->
        <!--                        'facebook/notRelevant',-->
        <!--                        {-->
        <!--                            rules: [{-->
        <!--                                required: false-->
        <!--                            }]-->
        <!--                        }-->
        <!--                    ]"-->
        <!--              :checked="isChecked('facebook/notRelevant')"-->
        <!--              v-on:change="() => relevancyCheckboxChange('facebook/url')"-->
        <!--          >-->
        <!--            Nem rendelkezik a vonzerő Facebook oldallal-->
        <!--          </a-checkbox>-->
        <!--        </a-form-item>-->

        <!--        <h4>Instagram oldal</h4>-->

        <!--        <a-form-item>-->
        <!--          <span slot="label">-->
        <!--              Instagram oldal elérhetősége-->

        <!--              <a-tooltip-->
        <!--                  title="Kérjük a Instagram linket az alábbi formátumban adja meg: https://www.instagram.com/parlament.hu"-->
        <!--              >-->
        <!--                  <a-icon type="question-circle-o" />-->
        <!--              </a-tooltip>-->
        <!--          </span>-->

        <!--          <a-input-->
        <!--              :maxLength="25000"-->
        <!--              :disabled="isChecked('instagram/notRelevant')"-->
        <!--              v-on:change="(event) => transformEncodedUrl(event.target.value, 'instagram/url')"-->
        <!--              v-decorator="[-->
        <!--                        'instagram/url',-->
        <!--                        {-->
        <!--                            rules: [{-->
        <!--                                required: !isChecked('instagram/notRelevant'),-->
        <!--                                message: 'Kérjük a megfelelő formátumban töltse ki!',-->
        <!--                                pattern: /^(http|https):\/\/(?:www.)?instagram.com/g,-->
        <!--                            }],-->
        <!--                            validateTrigger: ['blur', 'keyup']-->
        <!--                        }-->
        <!--                    ]"-->
        <!--          />-->

        <!--          <a-checkbox-->
        <!--              v-decorator="[-->
        <!--                        'instagram/notRelevant',-->
        <!--                        {-->
        <!--                            rules: [{-->
        <!--                                required: false-->
        <!--                            }]-->
        <!--                        }-->
        <!--                    ]"-->
        <!--              :checked="isChecked('instagram/notRelevant')"-->
        <!--              v-on:change="() => relevancyCheckboxChange('instagram/url')"-->
        <!--          >-->
        <!--            Nem rendelkezik a vonzerő Instagram oldallal-->
        <!--          </a-checkbox>-->
        <!--        </a-form-item>-->

        <!--        <a-divider orientation="left">-->
        <!--          MEGNYITÁS/ÁTADÁS ÉVE-->
        <!--        </a-divider>-->

        <!--        <a-form-item label="Megnyitás/Átadás éve" :selfUpdate="true">-->
        <!--          <a-input-number-->
        <!--              :max="9999"-->
        <!--              :min="0"-->
        <!--              v-decorator="[-->
        <!--                        'openingYear',-->
        <!--                        {-->
        <!--                            validateTrigger: ['blur', 'keyup'],-->
        <!--                            rules: [{-->
        <!--                                required: false,-->
        <!--                                whitespace: true,-->
        <!--                                type: 'integer',-->
        <!--                                message: 'Kérjük töltse ki!',-->
        <!--                                transform: (value) => {-->
        <!--                                  value = parseInt(value);-->

        <!--                                  return isNaN(value) ? null: value;-->
        <!--                                },-->
        <!--                            }]-->
        <!--                        }-->
        <!--                    ]"-->
        <!--          />-->
        <!--          év-->
        <!--        </a-form-item>-->

        <a-divider orientation="left">
          A VONZERŐT ÜZEMELTETŐ SZERVEZET
        </a-divider>

        <a-form-item label="A vonzerőt üzemeltető szervezet hivatalos neve" :selfUpdate="true">
          <a-input
              :maxLength="25000"
              v-decorator="['organizationAddress/name',
                            {
                                rules: [
                                    {
                                      required: false,
                                      preserve: true,
                                      message: 'Kérjük töltse ki!'
                                    },
                                    {
                                      max: characterCounts['organizationAddress/name'].max,
                                      message: 'Maximum ' + characterCounts['organizationAddress/name'].max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur', 'keyup']
                            }
                        ]"
              :autoSize="{minRows: 2}"
          />
        </a-form-item>

        <h4>A vonzerőt üzemeltető szervezet hivatalos elérhetősége</h4>

        <!--        <a-form-item label="Irányítószám">-->
        <!--          <a-select-->
        <!--              v-decorator="[-->
        <!--                            'organizationAddress/zipCode/id',-->
        <!--                            {-->
        <!--                                rules: [-->
        <!--                                    {-->
        <!--                                    required: false,-->
        <!--                                    whitespace: true,-->
        <!--                                    message: 'Kérjük töltse ki!',-->
        <!--                                    type: 'integer'-->
        <!--                                  },-->
        <!--                                ],-->
        <!--                                validateTrigger: ['blur','keyup']-->
        <!--                            }-->
        <!--                        ]"-->
        <!--              placeholder="Kezdjen gépelni"-->
        <!--              showSearch-->
        <!--              :showArrow="false"-->
        <!--              :defaultActiveFirstOption="false"-->
        <!--              :getPopupContainer="getParentTriggerNode()"-->
        <!--              :filterOption="false"-->
        <!--              :style="{width: '100%'}"-->
        <!--              v-on:search="(value) => zipCodeAutoComplete(value,'organizationAddress/zipCode/id')"-->
        <!--              v-on:change="(value) => {fillCityOptions(value,'organizationAddress/zipCode/id','organizationAddress/city/id')}"-->
        <!--              v-on:dropdownVisibleChange="(open) => open && zipCodeAutoComplete(null, 'organizationAddress/zipCode/id')"-->
        <!--              :notFoundContent="loading['organizationAddress/zipCode/id'] ? undefined : null"-->
        <!--          >-->
        <!--            <a-spin v-if="loading['organizationAddress/zipCode/id']" slot="notFoundContent" size="small" />-->

        <!--            <a-select-option-->
        <!--                v-for="option in options['organizationAddress/zipCode/id']"-->
        <!--                :key="option.code"-->
        <!--                :value="option.id"-->
        <!--            >-->
        <!--              {{ option.code }}-->
        <!--            </a-select-option>-->
        <!--          </a-select>-->
        <!--        </a-form-item>-->

        <!--        <a-form-item label="Település neve">-->
        <!--          <a-select-->
        <!--              v-decorator="[-->
        <!--                            'organizationAddress/city/id',-->
        <!--                            {-->
        <!--                                rules: [-->
        <!--                                    {-->
        <!--                                    required: false,-->
        <!--                                    whitespace: true,-->
        <!--                                    message: 'Kérjük töltse ki!',-->
        <!--                                    type: 'integer'-->
        <!--                                  },-->
        <!--                                ],-->
        <!--                                validateTrigger: ['blur','keyup']-->
        <!--                            }-->
        <!--                        ]"-->
        <!--              placeholder="Töltse ki az irányítószám mezőt!"-->
        <!--              :showArrow="false"-->
        <!--              :disabled="!options['organizationAddress/city/id'].length"-->
        <!--              :defaultActiveFirstOption="true"-->
        <!--              :filterOption="false"-->
        <!--              :style="{width: '100%'}"-->
        <!--              :notFoundContent="loading['organizationAddress/zipCode/id'] ? undefined : null"-->
        <!--          >-->
        <!--            <a-spin v-if="loading['organizationAddress/zipCode/id']" slot="notFoundContent" size="small" />-->

        <!--            <a-select-option-->
        <!--                v-for="option in options['organizationAddress/city/id']"-->
        <!--                :key="option.id"-->
        <!--                :value="option.id"-->
        <!--            >-->
        <!--              {{ option.name }}-->
        <!--            </a-select-option>-->
        <!--          </a-select>-->
        <!--        </a-form-item>-->

        <!--        <a-form-item-->
        <!--            :selfUpdate="true"-->
        <!--            label="Közterület neve"-->
        <!--        >-->
        <!--          <a-input-->
        <!--              :maxLength="25000"-->
        <!--              v-decorator="[-->
        <!--                            'organizationAddress/address',-->
        <!--                            {-->
        <!--                                rules: [-->
        <!--                                    {-->
        <!--                                      required: false,-->
        <!--                                      whitespace: true,-->
        <!--                                      message: 'Kérjük töltse ki!'-->
        <!--                                    },-->
        <!--                                    {-->
        <!--                                      max: characterCounts['organizationAddress/address'].max,-->
        <!--                                      message: 'Maximum ' + characterCounts['organizationAddress/address'].max + ' karakter engedélyezett.',-->
        <!--                                    }-->
        <!--                                ],-->
        <!--                                validateTrigger: ['blur','keyup']-->
        <!--                            }-->
        <!--                        ]"-->
        <!--              placeholder="Kérjük beírni (betűkkel)"-->
        <!--          >-->
        <!--          </a-input>-->
        <!--        </a-form-item>-->

        <!--        <a-form-item-->
        <!--            :selfUpdate="true"-->
        <!--            label="Közterület típusa (utca, tér, út, dűlő stb.)"-->
        <!--        >-->
        <!--          <a-input-->
        <!--              :maxLength="25000"-->
        <!--              v-decorator="[-->
        <!--                            'organizationAddress/addressType',-->
        <!--                            {-->
        <!--                                rules: [-->
        <!--                                    {-->
        <!--                                      required: false,-->
        <!--                                      whitespace: true,-->
        <!--                                      message: 'Kérjük töltse ki!'-->
        <!--                                    },-->
        <!--                                    {-->
        <!--                                      max: characterCounts['organizationAddress/addressType'].max,-->
        <!--                                      message: 'Maximum ' + characterCounts['organizationAddress/addressType'].max + ' karakter engedélyezett.',-->
        <!--                                    }-->
        <!--                                ],-->
        <!--                                validateTrigger: ['blur','keyup']-->
        <!--                            }-->
        <!--                        ]"-->
        <!--              placeholder="Kérjük beírni (betűkkel)"-->
        <!--          >-->
        <!--          </a-input>-->
        <!--        </a-form-item>-->

        <!--        <a-form-item>-->
        <!--          <span slot="label">-->
        <!--              Házszám/helyrajzi szám-->
        <!--              <a-tooltip-->
        <!--                  title="Itt a házszámon kívül más is beírható (pl. helyrajzi szám vagy más egyéb, pontos azonosító, ami az adott ingatlan helyét pontosan meghatározza). Ha nincs ilyen pontos azonosító szám a címhez, akkor kérjük a mezőt üresen hagyni."-->
        <!--              >-->
        <!--                  <a-icon type="question-circle-o" />-->
        <!--              </a-tooltip>-->
        <!--          </span>-->

        <!--          <a-input-->
        <!--              :maxLength="25000"-->
        <!--              v-decorator="[-->
        <!--                            'organizationAddress/addressNumber',-->
        <!--                            {-->
        <!--                                rules: [-->
        <!--                                    {-->
        <!--                                      required: false,-->
        <!--                                      whitespace: true,-->
        <!--                                      message: 'Kérjük töltse ki!'-->
        <!--                                    },-->
        <!--                                    {-->
        <!--                                      max: characterCounts['organizationAddress/addressNumber'].max,-->
        <!--                                      message: 'Maximum ' + characterCounts['organizationAddress/addressNumber'].max + ' karakter engedélyezett.',-->
        <!--                                    }-->
        <!--                                ],-->
        <!--                                validateTrigger: ['blur','keyup']-->
        <!--                            }-->
        <!--                        ]"-->
        <!--              placeholder="Kérjük beírni (számokkal, betűkkel)"-->
        <!--          />-->
        <!--        </a-form-item>-->

        <a-form-item :selfUpdate="true">
          <span slot="label">
              A vonzerőt üzemeltető szervezet központi telefonszáma (pl.: +36 1 234 5678)
              <a-tooltip
                  title="Amennyiben nem rendelkezik telefonszámmal az objektum, úgy kérjük, adja meg a Tourinform iroda központi telefonszámát!"
              >
                  <a-icon type="question-circle-o" />
              </a-tooltip>
          </span>

          <a-input
              :maxLength="25000"
              :disabled="isChecked('centralContact/phoneNotRelevant')"
              :style="{width: '160px'}"
              v-decorator="[
                                'centralContact/phone',
                                {
                                    rules: [{
                                        required: !isChecked('centralContact/phoneNotRelevant'),
                                        pattern: /^[\/+][36]{1,2}[ ][0-9]{1,2}[ ][0-9]{3}[ ][0-9]{3,4}$/,
                                        message: 'Hibás telefonszám formátum!'
                                    }],
                                   validateTrigger: ['blur']
                                }
                            ]"
              v-on:change="(event) => formatPhoneNumber(event.target.value, 'centralContact/phone')"
              ref="centralContact/phone"
              placeholder="+36 1 234 5678"
          />

          <br />

          <a-checkbox
              v-decorator="[
                            'centralContact/phoneNotRelevant',
                            {
                                rules: [{
                                    required: false
                                }]
                            }
                        ]"
              :checked="isChecked('centralContact/phoneNotRelevant')"
              v-on:change="() => form.validateFields() && relevancyCheckboxChange('centralContact/phone')"
          >
            Nem releváns
          </a-checkbox>
        </a-form-item>

        <a-form-item :selfUpdate="true">
          <span slot="label">
              A vonzerőt üzemeltető szervezet központi e-mail címe
              <a-tooltip
                  title="Amennyiben nem rendelkezik e-mail címmel az objektum, úgy kérjük, adja meg a Tourinform iroda központi e-mail címét! Kérjük az e-mail címet iroda@domain.xy formátumban töltse ki!"
              >
                  <a-icon type="question-circle-o" />
              </a-tooltip>
          </span>

          <a-input
              type="email"
              :maxLength="25000"
              :disabled="isChecked('centralContact/emailNotRelevant')"
              v-decorator="[
                            'centralContact/email',
                            {
                                rules: [
                                    {
                                      required: !isChecked('centralContact/emailNotRelevant'),
                                      whitespace: true,
                                      type: 'email',
                                      message: 'Kérjük a megfelelő formátumban töltse ki!'
                                    },
                                    {
                                      max: characterCounts['centralContact/email'].max,
                                      message: 'Maximum ' + characterCounts['centralContact/email'].max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
              placeholder="Kérjük az e-mail címet beírni!"
          >
          </a-input>

          <a-checkbox
              v-decorator="[
                            'centralContact/emailNotRelevant',
                            {
                                rules: [{
                                    required: false
                                }]
                            }
                        ]"
              :checked="isChecked('centralContact/emailNotRelevant')"
              v-on:change="() => form.validateFields() && relevancyCheckboxChange('centralContact/email')"
          >
            Nem releváns
          </a-checkbox>
        </a-form-item>

        <!--        <a-form-item-->
        <!--            :selfUpdate="true"-->
        <!--            label="A vonzerőt üzemeltető szervezet (első számú) vezetőjének neve"-->
        <!--        >-->
        <!--          <a-input-->
        <!--              :maxLength="25000"-->
        <!--              v-decorator="[-->
        <!--                            'leaderContact/name',-->
        <!--                            {-->
        <!--                                rules: [-->
        <!--                                    {-->
        <!--                                      required: false,-->
        <!--                                      whitespace: true,-->
        <!--                                      message: 'Kérjük töltse ki!'-->
        <!--                                    },-->
        <!--                                    {-->
        <!--                                      max: characterCounts['leaderContact/name'].max,-->
        <!--                                      message: 'Maximum ' + characterCounts['leaderContact/name'].max + ' karakter engedélyezett.',-->
        <!--                                    }-->
        <!--                                ],-->
        <!--                                validateTrigger: ['blur','keyup']-->
        <!--                            }-->
        <!--                        ]"-->
        <!--          />-->
        <!--        </a-form-item>-->

        <!--        <a-form-item-->
        <!--            :selfUpdate="true"-->
        <!--            label="A vonzerőt üzemeltető szervezet (első számú) vezetőjének beosztása"-->
        <!--        >-->
        <!--          <a-input-->
        <!--              :maxLength="25000"-->
        <!--              v-decorator="[-->
        <!--                            'leaderContact/title',-->
        <!--                            {-->
        <!--                                rules: [-->
        <!--                                    {-->
        <!--                                      required: false,-->
        <!--                                      whitespace: true,-->
        <!--                                      message: 'Kérjük töltse ki!'-->
        <!--                                    },-->
        <!--                                    {-->
        <!--                                      max: characterCounts['leaderContact/title'].max,-->
        <!--                                      message: 'Maximum ' + characterCounts['leaderContact/title'].max + ' karakter engedélyezett.',-->
        <!--                                    }-->
        <!--                                ],-->
        <!--                                validateTrigger: ['blur','keyup']-->
        <!--                            }-->
        <!--                        ]"-->
        <!--          />-->
        <!--        </a-form-item>-->

        <!--        <a-form-item-->
        <!--            :selfUpdate="true"-->
        <!--            label="A vonzerőt üzemeltető szervezet (első számú) vezetőjének telefonszáma"-->
        <!--        >-->
        <!--          <a-input-->
        <!--              :maxLength="25000"-->
        <!--              :style="{width: '160px'}"-->
        <!--              v-decorator="[-->
        <!--                                'leaderContact/phone',-->
        <!--                                {-->
        <!--                                    rules: [{-->
        <!--                                        required: false,-->
        <!--                                        pattern: /^[\/+][36]{1,2}[ ][0-9]{1,2}[ ][0-9]{3}[ ][0-9]{3,4}$/,-->
        <!--                                        message: 'Hibás telefonszám formátum!'-->
        <!--                                    }],-->
        <!--                                   validateTrigger: ['blur']-->
        <!--                                }-->
        <!--                            ]"-->
        <!--              v-on:change="(event) => formatPhoneNumber(event.target.value, 'leaderContact/phone')"-->
        <!--              ref="leaderContact/phone"-->
        <!--              placeholder="+36 1 234 5678"-->
        <!--          />-->
        <!--        </a-form-item>-->


        <!--        <a-form-item-->
        <!--            :selfUpdate="true"-->
        <!--            label="Kapcsolattartó neve (az Adattárhoz kapcsolódóan, ha eltér a vezetőtől)"-->
        <!--        >-->
        <!--          <a-input-->
        <!--              :maxLength="25000"-->
        <!--              v-decorator="[-->
        <!--                            'generalContact/name',-->
        <!--                            {-->
        <!--                                rules: [-->
        <!--                                    {-->
        <!--                                      required: false,-->
        <!--                                      whitespace: true,-->
        <!--                                      message: 'Kérjük töltse ki!'-->
        <!--                                    },-->
        <!--                                    {-->
        <!--                                      max: characterCounts['generalContact/name'].max,-->
        <!--                                      message: 'Maximum ' + characterCounts['generalContact/name'].max + ' karakter engedélyezett.',-->
        <!--                                    }-->
        <!--                                ],-->
        <!--                                validateTrigger: ['blur','keyup']-->
        <!--                            }-->
        <!--                        ]"-->
        <!--          />-->
        <!--        </a-form-item>-->

        <!--        <a-form-item-->
        <!--            :selfUpdate="true"-->
        <!--            label="Kapcsolattartó beosztása"-->
        <!--        >-->
        <!--          <a-input-->
        <!--              :maxLength="25000"-->
        <!--              v-decorator="[-->
        <!--                            'generalContact/title',-->
        <!--                            {-->
        <!--                                rules: [-->
        <!--                                    {-->
        <!--                                      required: false,-->
        <!--                                      whitespace: true,-->
        <!--                                      message: 'Kérjük töltse ki!'-->
        <!--                                    },-->
        <!--                                    {-->
        <!--                                      max: characterCounts['generalContact/title'].max,-->
        <!--                                      message: 'Maximum ' + characterCounts['generalContact/title'].max + ' karakter engedélyezett.',-->
        <!--                                    }-->
        <!--                                ],-->
        <!--                                validateTrigger: ['blur','keyup']-->
        <!--                            }-->
        <!--                        ]"-->
        <!--          />-->
        <!--        </a-form-item>-->

        <!--        <a-form-item-->
        <!--            :selfUpdate="true"-->
        <!--            label="Kapcsolattartó telefonos elérhetősége (pl.: +36 1 234 5678)"-->
        <!--        >-->
        <!--          <a-input-->
        <!--              :maxLength="25000"-->
        <!--              :style="{width: '160px'}"-->
        <!--              v-decorator="[-->
        <!--                                'generalContact/phone',-->
        <!--                                {-->
        <!--                                    rules: [{-->
        <!--                                        required: false,-->
        <!--                                        pattern: /^[\/+][36]{1,2}[ ][0-9]{1,2}[ ][0-9]{3}[ ][0-9]{3,4}$/,-->
        <!--                                        message: 'Hibás telefonszám formátum!'-->
        <!--                                    }],-->
        <!--                                   validateTrigger: ['blur']-->
        <!--                                }-->
        <!--                            ]"-->
        <!--              v-on:keyup="(event) => formatPhoneNumber(event.target.value, 'generalContact/phone')"-->
        <!--              ref="generalContact/phone"-->
        <!--              placeholder="+36 1 234 5678"-->
        <!--          />-->
        <!--        </a-form-item>-->

        <!--        <a-form-item :selfUpdate="true">-->
        <!--          <span slot="label">-->
        <!--              Kapcsolattartó e-mail címe-->
        <!--              <a-tooltip-->
        <!--                  title="Kérjük az e-mail címet iroda@domain.xy formátumban töltse ki!"-->
        <!--              >-->
        <!--                  <a-icon type="question-circle-o" />-->
        <!--              </a-tooltip>-->
        <!--          </span>-->

        <!--          <a-input-->
        <!--              :maxLength="25000"-->
        <!--              v-decorator="[-->
        <!--                            'generalContact/email',-->
        <!--                            {-->
        <!--                                rules: [-->
        <!--                                    {-->
        <!--                                      required: false,-->
        <!--                                      whitespace: true,-->
        <!--                                      type: 'email',-->
        <!--                                      message: 'Kérjük a megfelelő formátumban töltse ki!'-->
        <!--                                    },-->
        <!--                                    {-->
        <!--                                      max: characterCounts['centralContact/email'].max,-->
        <!--                                      message: 'Maximum ' + characterCounts['centralContact/email'].max + ' karakter engedélyezett.',-->
        <!--                                    }-->
        <!--                                ],-->
        <!--                                validateTrigger: ['blur','keyup']-->
        <!--                            }-->
        <!--                        ]"-->
        <!--          >-->
        <!--          </a-input>-->
        <!--        </a-form-item>-->

        <!--        <a-divider />-->

        <!--        <a-form-item :selfUpdate="true">-->
        <!--          <span slot="label">-->
        <!--              Megjegyzés-->
        <!--              <a-tooltip-->
        <!--                  title="Kérjük ezt a cellát akkor töltse ki, ha a témához kapcsolódóan egyéb megjegyzése van."-->
        <!--              >-->
        <!--                  <a-icon type="question-circle-o" />-->
        <!--              </a-tooltip>-->
        <!--          </span>-->

        <!--          <a-textarea-->
        <!--              :maxLength="25000"-->
        <!--              v-decorator="[-->
        <!--                        'comment',-->
        <!--                        {-->
        <!--                            rules: [{-->
        <!--                                required: false,-->
        <!--                                message: 'Kérjük töltse ki!'-->
        <!--                            }]-->
        <!--                        }-->
        <!--                    ]"-->
        <!--              :autoSize="{minRows: 2}"-->
        <!--          />-->
        <!--        </a-form-item>-->

        <a-form-item>
          <a-button type="primary" html-type="submit" :loading="submitLoading" v-if="showSaveButton">
            Mentés
          </a-button>

          <a-button type="secondary" html-type="button" v-on:click="resetForm" v-if="hasChanged()">
            <a-icon
                type="undo"
                theme="outlined"
            />

            Piszkozat törlése
          </a-button>

          <span v-if="currentTab" class="last-modification">
            Utolsó mentés: <strong>{{ moment(currentTab.modificationTime).format(timeStampFormat).toString() }}</strong> &nbsp;<span v-if="currentTab.user">({{ currentTab.user.userName }})</span>
          </span>

          <a-alert
              :style="{
                        marginTop: '10px'
                    }"
              v-if="submitResult.show"
              :message="submitResult.message"
              :type="submitResult.type"
              showIcon
              closable
              :afterClose="() => submitResult.show = false"
          />
        </a-form-item>
      </a-form>
    </a-spin>
  </div>
</template>

<script>
import { EditFormComponentMixin } from "@/mixins/EditFormComponentMixin";
import debounce from "lodash.debounce";
import axios from "axios";

export default {
  menuName: 'Vonzerőre vonatkozó adatok',
  name: 'attraction-data-form',
  mixins: [EditFormComponentMixin],
  data() {
    return {
      coordinateTooltipLatitude: 'Kérjük a koordinátát az alábbi formátumban adja meg: 47.507346 Kérjük annak a helynek a GPS koordinátáit beírni, ahol a vonzerő pontosan található VAGY annak a helynek a koordinátáit, ahol a vonzerőhöz legközelebb biztonságosan lehet parkolni (pl. egy tó esetében) /Segítségül: a google.maps honlapon: 1. jobb egér gombbal kattintás a kívánt helyre, 2. "mi található itt?" lehetőség kiválasztása a lenyíló menüből, 3. az oldal alján megjelenik a kártya a koordinátákkal (alsó sorban) - ezt az adatsor kérjük ide beírni.',
      coordinateTooltipLongitude: 'Kérjük a koordinátát az alábbi formátumban adja meg: 19.046610! Kérjük annak a helynek a GPS koordinátáit beírni, ahol a vonzerő pontosan található VAGY annak a helynek a koordinátáit, ahol a vonzerőhöz legközelebb biztonságosan lehet parkolni (pl. egy tó esetében) /Segítségül: a google.maps honlapon: 1. jobb egér gombbal kattintás a kívánt helyre, 2. "mi található itt?" lehetőség kiválasztása a lenyíló menüből, 3. az oldal alján megjelenik a kártya a koordinátákkal (alsó sorban) - ezt az adatsor kérjük ide beírni.',

      formId: 'ATTRACTION_DATA',

      formFields: {
        'name': '',
        'group/id': null,
        'category/id': null,
        'labels/id': [],
        'relatedAttractions/id': [],
        'featuredDestinations/id': [],
        'targetGroups/id': [],
        'journeyTypes/id': [],
        'seasonalities/id': [],
        'siteNatures/id': [],
        'accessibilities/id': [],
        'classification': null,
        'address/zipCode/id': null,
        'address/city/id': null,
        'address/address': '',
        'address/addressNotRelevant': 0,
        'address/addressType': '',
        'address/addressTypeNotRelevant': 1,
        'address/addressNumber': '',
        'address/addressNumberNotRelevant': 1,
        'mailingAddress': '',
        'address/lat': '',
        'address/long': '',
        'website/url': '',
        'website/notRelevant': 0,
        'website/type': 'web',
        'website/languages/id': [],
        'website/comment': '',
        'facebook/url': '',
        'facebook/notRelevant': 0,
        'facebook/type': 'facebook',
        'instagram/url': '',
        'instagram/notRelevant': 0,
        'instagram/type': 'instagram',
        'openingYear': null,
        'organizationAddress/name': '',
        'organizationAddress/zipCode/id': null,
        'organizationAddress/city/id': null,
        'organizationAddress/address': '',
        'organizationAddress/addressType': '',
        'organizationAddress/addressNumber': '',
        'organizationAddress/addressNumberNotRelevant': 1,
        'centralContact/phone': '',
        'centralContact/email': '',
        'centralContact/phoneNotRelevant': 0,
        'centralContact/emailNotRelevant': 0,
        'centralContact/type': 'central',
        'leaderContact/name': '',
        'leaderContact/title': '',
        'leaderContact/phone': '',
        'leaderContact/type': 'leader',
        'generalContact/name': '',
        'generalContact/title': '',
        'generalContact/email': '',
        'generalContact/phone': '',
        'generalContact/type': 'general',
        'comment': ''
      },

      casts: {
        'address/addressNotRelevant': 'int',
        'address/addressTypeNotRelevant': 'int',
        'address/addressNumberNotRelevant': 'int',
        'address/lat': '?float',
        'address/long': '?float',
        'website/notRelevant': 'int',
        'facebook/notRelevant': 'int',
        'instagram/notRelevant': 'int',
        'organizationAddress/addressNumberNotRelevant': 'int',
        'openingYear': 'int',
        'centralContact/phoneNotRelevant': 'int',
        'centralContact/emailNotRelevant': 'int',
      },

      options: {
        'group/id': [],
        'category/id': [],
        'labels/id': [],
        'relatedAttractions/id': [],
        'featuredDestinations/id': [],
        'targetGroups/id': [],
        'journeyTypes/id': [],
        'seasonalities/id': [],
        'siteNatures/id': [],
        'accessibilities/id': [],
        'address/zipCode/id': [],
        'address/city/id': [],
        'website/languages/id': [],
        'organizationAddress/zipCode/id': [],
        'organizationAddress/city/id': [],
      },

      translatedFormFields: {
        name: {}
      },

      ignoredFields: [],

      relatedAttractionsAutoCompleteCancel: undefined,

      characterCounts: {
        'name': {
          max: 200
        },
        'address/address': {
          max: 200
        },
        'address/addressType': {
          max: 50
        },
        'address/addressNumber': {
          max: 50
        },
        'mailingAddress': {
          max: 500
        },
        'organizationAddress/name': {
          max: 200
        },
        'organizationAddress/address': {
          max: 200
        },
        'organizationAddress/addressType': {
          max: 50
        },
        'organizationAddress/addressNumber': {
          max: 50
        },
        'centralContact/email': {
          max: 200
        },
        'leaderContact/name': {
          max: 200
        },
        'leaderContact/title': {
          max: 200
        },
        'generalContact/name': {
          max: 200
        },
        'generalContact/title': {
          max: 200
        },
        'generalContact/email': {
          max: 200
        },
      },

      modals: {
        targetAudience: false,
        classification: false,
      }
    }
  },

  methods: {
    /**
     * @return {Promise<[]>}
     */
    populateOptions() {
      let promises = [];

      // Load main groups
      promises.push(this.loadOptions('group/id', null, 1));

      // Load categories
      promises.push(this.loadOptions('category/id'));

      // Load labels by category
      promises.push(this.loadOptions('labels/id'));

      // Fill options from the current attraction data
      this.options['relatedAttractions/id'] = this.getAttraction().relatedAttractions;
      this.options['featuredDestinations/id'] = this.getAttraction().featuredDestinations;
      this.options['targetGroups/id'] = this.getAttraction().targetGroups;
      this.options['journeyTypes/id'] = this.getAttraction().journeyTypes;
      this.options['seasonalities/id'] = this.getAttraction().seasonalities;
      this.options['siteNatures/id'] = this.getAttraction().siteNatures;
      this.options['accessibilities/id'] = this.getAttraction().accessibilities;

      // Fill the current zip code to the options
      if (this.getAttraction().address && this.getAttraction().address.zipCode) {
        this.options['address/zipCode/id'] = [this.getAttraction().address.zipCode];
        this.options['address/city/id'] = this.getAttraction().address.zipCode.cities;
      }

      if (this.getAttraction().organizationAddress && this.getAttraction().organizationAddress.zipCode) {
        this.options['organizationAddress/zipCode/id'] = [this.getAttraction().organizationAddress.zipCode];
        this.options['organizationAddress/city/id'] = this.getAttraction().organizationAddress.zipCode.cities;
      }

      // Fill foreign languages
      this.options['website/languages/id'] = this.languages;

      return Promise.all(promises);
    },

    /**
     * Auto-complete related attractions from API
     *
     * @param {string} name
     *
     * Throttled: 250ms when code is not empty
     */
    relatedAttractionsAutoComplete: function (name) {
      var self = this;

      let apiUrl = '/api/v1/companies/1/me/attractions';

      if (this.isOfficeEmployee) {
        apiUrl += '/by-office';
      } else if (this.isOfficeSpecialist) {
        apiUrl += '/by-areas';
      }

      debounce(function (name) {
        self.options['relatedAttractions/id'] = [];
        self.loading['relatedAttractions/id'] = true;

        if (self.relatedAttractionsAutoCompleteCancel) {
          self.relatedAttractionsAutoCompleteCancel();
        }

        axios.get(apiUrl, {
          cancelToken: new axios.CancelToken(function executor(cancel) {
            self.relatedAttractionsAutoCompleteCancel = cancel;
          }),
          params: {
            fields: 'id,name',
            name: (true !== name && name) ? '*' + name + '*' : null,
            locale: self.$store.state.attraction.locale,
            order: 'name ASC',
            limit: 200,
            multi: 0,
            'group/groupType': 'attraction'
          }
        }).then(response => {
          self.options['relatedAttractions/id'] = response.data;
          self.loading['relatedAttractions/id'] = false;
        });
      }, name !== true ? 250 : 0)(name)
    },
  }
}
</script>

<style>
button + button {
  margin-left: 10px;
}

.dynamic-delete-button {
  margin-left: 10px;
}
</style>
