<template>
  <div class="home">
    <a-layout :style="{ minHeight: '100vh' }">
      <a-layout-header>
        <HomeHeaderComponent />
      </a-layout-header>

      <a-layout-content>
        <router-view />
      </a-layout-content>
    </a-layout>
  </div>
</template>

<script>
import HomeHeaderComponent from '@/components/home/HomeHeaderComponent.vue';

export default {
  name: 'home',
  components: {
    HomeHeaderComponent
  }
}
</script>

<style lang="scss" scoped>
.ant-layout {
  background-image: url('~@/assets/images/NTAK_Home_bg.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.ant-layout-header {
  background: transparent;

  @include xs {
    padding: 0 36px;
  }
}

</style>
