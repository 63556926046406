<template xmlns="">
  <div class="app-form-container">
    <a-spin :spinning="formDataLoading">
      <a-form :form="form" v-on:submit="submit" layout="vertical">
        <a-form-item>
          <a-button type="primary" html-type="submit" :loading="submitLoading" v-if="showSaveButton && !isMobileReadonly">
            Mentés
          </a-button>

          <a-button type="secondary" html-type="button" v-on:click="resetForm" v-if="hasChanged()">
            <a-icon type="undo"
                    theme="outlined" />

            Piszkozat törlése
          </a-button>

          <span v-if="currentTab" class="last-modification">
            Utolsó mentés: <strong>{{ moment(currentTab.modificationTime).format(timeStampFormat).toString() }}</strong> &nbsp;<span v-if="currentTab.user">({{ currentTab.user.userName }})</span>
          </span>

          <a-alert
              :style="{
                        marginTop: '10px'
                    }"
              v-if="submitResult.show"
              :message="submitResult.message"
              :type="submitResult.type"
              showIcon
              closable
              :afterClose="() => submitResult.show = false"
          />
        </a-form-item>

        <template>
          <a-form-item
              v-for="(price, index) in prices"
              :key="'prices-' + index"
              :required="false"
          >
            <span v-if="index === 0" slot="label">
                Jegyárak
                <a-tooltip
                    title='Az A oszlopban levő meghatározásokat kérjük felülírni. (pl."típus1" helyett "felnőtt" vagy "diák" stb.)'
                >
                    <a-icon type="question-circle-o" />
                </a-tooltip>
            </span>

            <a-row>
              <a-col :span="16">
                <a-form-item
                    :selfUpdate="true"
                    :help="'Kérjük adja meg a jegy típúsát!'"
                >
                  <a-input
                      :disabled="isMobileReadonly"
                      :maxLength="25000"
                      v-on:change="(event) => updateName(index, event.target.value)"
                      v-decorator="[
                                  'prices/' + index + '/name',
                                  {
                                      validateTrigger: ['keyup', 'blur'],
                                      rules: [{
                                          required: prices[index].price,
                                          whitespace: false,
                                          message: 'Kérjük töltse ki, vagy törölje a sort.'
                                      }]
                                  }
                              ]"
                  >
                  </a-input>
                </a-form-item>
              </a-col>

              <a-col :span="8">
                <a-form-item
                    :selfUpdate="true"
                    :help="'Kérjük a jegyárat beírni forintban (csak számot írjon a mezőbe)'"
                >
                  <a-input-number
                      :disabled="isMobileReadonly"
                      v-on:change="(value) => updatePrice(index, value)"
                      :precision="0"
                      :min="1"
                      :step="1"
                      v-decorator="[
                                    'prices/' + index + '/price',
                                    {
                                        validateTrigger: ['keyup','blur'],
                                        rules: [{
                                            required: prices[index].name.length > 0,
                                            whitespace: true,
                                            transform: (value) => {
                                              value = parseInt(value);

                                              return isNaN(value) ? null : value;
                                            },
                                            type: 'integer',
                                            message: 'Kérjük töltse ki, vagy törölje a sort.'
                                        }]
                                    }
                                  ]"
                  >
                  </a-input-number>
                  Ft

                  <a-icon
                      v-if="prices.length > 1"
                      class="dynamic-delete-button"
                      type="delete"
                      theme="filled"
                      :disabled="isMobileReadonly || prices.length === 1"
                      v-on:click="() => removePrice(index)"
                  />
                </a-form-item>

              </a-col>
            </a-row>
          </a-form-item>

          <a-form-item
          >
            <a-button
                :disabled="isMobileReadonly"
                type="dashed"
                style="width: 100%"
                v-on:click="addPrice"
            >
              <a-icon type="plus" />

              Jegytípus hozzáadása
            </a-button>
          </a-form-item>
        </template>

        <a-divider />

        <a-form-item
            :selfUpdate="true"
        >
        <span slot="label">
              Megjegyzés
              <a-tooltip
                  title="Kérjük ezt a cellát akkor töltse ki, ha a témához kapcsolódóan egyéb megjegyzése van."
              >
                  <a-icon type="question-circle-o" />
              </a-tooltip>
          </span>

          <a-textarea
              :maxLength="25000"
              :disabled="isMobileReadonly"
              v-decorator="[
                        'pricesComment',
                        {
                            rules: [{
                                required: false,
                                message: 'Kérjük töltse ki!'
                            }]
                        }
                    ]"
              :autoSize="{minRows: 2}"
          />
        </a-form-item>

        <a-form-item>
          <a-button type="primary" html-type="submit" :loading="submitLoading" v-if="showSaveButton && !isMobileReadonly">
            Mentés
          </a-button>

          <a-button type="secondary" html-type="button" v-on:click="resetForm" v-if="hasChanged()">
            <a-icon
                type="undo"
                theme="outlined"
            />

            Piszkozat törlése
          </a-button>

          <span v-if="currentTab" class="last-modification">
            Utolsó mentés: <strong>{{ moment(currentTab.modificationTime).format(timeStampFormat).toString() }}</strong> &nbsp;<span v-if="currentTab.user">({{ currentTab.user.userName }})</span>
          </span>

          <a-alert
              :style="{
                        marginTop: '10px'
                    }"
              v-if="submitResult.show"
              :message="submitResult.message"
              :type="submitResult.type"
              showIcon
              closable
              :afterClose="() => submitResult.show = false"
          />
        </a-form-item>
      </a-form>
    </a-spin>
  </div>
</template>

<script>
import { EditFormComponentMixin } from "@/mixins/EditFormComponentMixin";
import axios from "axios";

export default {
  menuName: 'Árak',
  name: 'prices-form',
  mixins: [EditFormComponentMixin],

  data() {
    return {
      formId: 'PRICES',

      formFields: {
        pricesComment: ''
      },

      prices: [],

      ignoredFields: [],

      characterCounts: {
        'prices/name': {
          max: 200,
        },
      },
    }
  },

  methods: {
    /**
     * @return {boolean}
     */
    isPaymentEnabled() {
      let payment = this.getAttraction().payment;

      if (!payment || !payment.ticketSales) {
        return false;
      }

      return !payment.ticketSales.free;
    },

    /**
     * @param {{}} data
     */
    setState(data) {
      console.debug('Set state', data);

      this.$super(EditFormComponentMixin).setState(data);

      this.prices = data.prices;

      this.$nextTick(() => {
        this.refreshPriceFields();
        this.populateFormFields();
      });
    },

    /**
     * @return {{}}
     */
    getState() {
      let state = this.$super(EditFormComponentMixin).getState();

      state.prices = this.prices;

      console.debug('Get state', state);

      return state;
    },

    /**
     */
    addPrice() {
      this.prices.push({
        'name': '',
        'price': null
      });

      this.refreshPriceFields();
      this.populateFormFields();

      this.saveDraft();
    },

    /**
     * Save attraction (also, with locale specific fields)
     *
     * @param {object} data
     * @param {string} locale
     */
    save(data, locale) {
      return new Promise((resolve, reject) => {
        let attractionId = this.getAttraction().id;
        let promises = [];
        let pricesApiUrl = '/api/v1/companies/1/me/attractions/' + attractionId + '/prices?locale=' + locale;

        promises.push(
            axios.patch(
                this.getApiUrl(attractionId, locale),
                data
            )
        );

        axios.delete(
            pricesApiUrl + '&attraction/id=' + attractionId,
            data
        ).then(() => {
          if (this.prices.length) {
            promises.push(
                axios.post(
                    pricesApiUrl + '&batch=true',
                    this.prices
                )
            );
          }
        });

        Promise.all(promises).then((responses) => {
          resolve(responses[0]);
        });
      });
    },

    /**
     * @param {integer} index
     */
    removePrice(index) {
      this.prices.splice(index, 1);

      this.refreshPriceFields();
      this.saveDraft();
    },

    refreshPriceFields() {
      let populateData = {};

      this.prices.forEach((price, index) => {
        populateData['prices/' + index + '/name'] = price.name;
        populateData['prices/' + index + '/price'] = price.price;
      });

      this.$nextTick(() => {
        this.form.setFieldsValue(populateData);
        this.form.validateFields(Object.keys(populateData));
      });
    },

    /**
     * @param {integer} index
     * @param {string} value
     */
    updateName(index, value) {
      this.prices[index].name = value;

      this.refreshPriceFields();
    },

    /**
     * @param {integer} index
     * @param {integer} value
     */
    updatePrice(index, value) {
      this.prices[index].price = value;

      this.refreshPriceFields();
    },

    /**
     * Function for child form to fill the options
     *
     * @return {Promise<unknown>}
     */
    populateOptions() {
      this.prices = this.getAttraction().prices;

      console.debug('Populating prices options', this.prices);

      if (this.prices.length) {
        this.refreshPriceFields();
      } else {
        this.addPrice();
      }

      return new Promise((resolve => {
        resolve();
      }));
    },
  }
}
</script>

<style>
</style>
