import axios from 'axios';

import {
    USER_LOGOUT
} from '@/action_types.js';

import router from '@/router'
import store from '@/store.js'
import { Message } from "ant-design-vue";
import LocalStorage from '@/helpers/localstorage';
import { SET_BACKEND_VERSION } from '@/mutation_types';

export const httpSetup = () => {

    if (LocalStorage.getJwt()) {
        axios.defaults.headers.common['Authorization'] = LocalStorage.getJwt();
    }

    axios.interceptors.request.use(function (config) {
            config.headers.Authorization = LocalStorage.getJwt();

            return config;
        }, function (error) {
            return Promise.reject(error);
        }
    )

    axios.interceptors.response.use(function (response) {
            if (response.config.responseType !== 'blob') {
                if (response.data.token) {
                    const token = response.data.token;

                    LocalStorage.setJwt(token);
                }

                if (response.data.meta && response.data.meta.backendVersion) {
                    store.commit({
                        type: SET_BACKEND_VERSION,
                        backendVersion: response.data.meta.backendVersion
                    })
                }
            }
            return response;
        }, function (error) {
            if (error.response && error.response.status === 403) {
                store.dispatch(USER_LOGOUT);
                router.replace({
                    name: 'login'
                })

                Message.error('Lejárt a munkamenete, kérem jelentkezzen be újra!');
            }

            return Promise.reject(error);
        }
    )
}