<template>
    <a-layout ref="layout-container" :style="{height: 'calc(100vh - 64px)'}">
        <a-layout-header class="layout-content-sub-header" v-if="hungarikum" v-show="collapsed">
            <div class="attraction-data">
        <span class="attraction-name">
          <strong>{{ hungarikum.label }}</strong>
        </span>

                <span class="attraction-id">
          Azonosító: <strong>{{ hungarikum.id }}</strong>
        </span>

                <span class="attraction-city" v-if="hungarikum.address && hungarikum.address.city">
                Település: <strong>{{ hungarikum.address.city.name }}</strong>
                </span>

                <span class="attraction-status">
          Státusz: <strong>{{ statusNames[hungarikum.status] }}</strong>
        </span>
            </div>
        </a-layout-header>

        <a-layout-sider :width="300" :style="{background: 'transparent'}" :collapsible="true" v-model="collapsed">
            <NetaHungarikumDetailsMenuComponent
                    class="attraction-menu-holder"
                    :collapsed="collapsed"
                    :status-names="statusNames" />
        </a-layout-sider>

        <a-layout-content ref="layout-content-container" class="app-details-content">
            <transition name="slide-fade-vertical" mode="out-in">
                <router-view ref="app-layout-content" />
            </transition>
        </a-layout-content>

        <a-button
                v-show="scrolled"
                @click="scrollTop"
                class="scroll-to-top"
                size="large"
                icon="up"
                type="primary"
        />
    </a-layout>
</template>

<script>
import NetaHungarikumDetailsMenuComponent
    from '@/components/neta/neta-hungarikum/NetaHungarikumDetailsMenuComponent.vue';
import store from '@/store.js';
import { REFRESH_HUNGARIKUM } from "@/action_types";
import { Message } from "ant-design-vue";

const checkHungarikum = (to, from, next) => {
    let storedHungarikum = store.state.hungarikum.current;


    if (storedHungarikum && storedHungarikum.id == to.params.id) {
        next();

        return;
    }

    store.dispatch({
        type: REFRESH_HUNGARIKUM,
        hungarikumId: to.params.id,
    }).then(() => {
        next();
    }).catch(() => {
        next({
            name: 'neta-home'
        });

        Message.error('A hungarikum nem elérhető!');
    })
}

export default {
    components: {
        NetaHungarikumDetailsMenuComponent
    },

    data() {
        return {
            scrolled: false
        }
    },

    mounted() {
        window.addEventListener('scroll', this.onScroll);

        if (this.smallScreen) {
            this.collapsed = true;
        }
    },

    beforeDestroy() {
        window.removeEventListener('scroll', this.onScroll)
    },

    computed: {
        hungarikum() {
            return this.$store.state.hungarikum.current;
        },

        smallScreen() {
            return window.innerWidth <= 768;
        },

        statusNames() {
            return this.$store.state.hungarikum.statusNames;
        },

        collapsed: {
            get() {
                return this.$store.state.hungarikum.sidebarCollapsed;
            },

            set(collapsed) {
                this.$store.state.hungarikum.sidebarCollapsed = collapsed;
            },
        }
    },

    beforeRouteEnter(to, from, next) {
        checkHungarikum(to, from, next);
    },

    beforeRouteUpdate(to, from, next) {
        this.$refs['layout-content-container'].$el.scrollTop = 0;

        checkHungarikum(to, from, next);
    },

    methods: {
        scrollTop() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },

        onScroll(e) {
            this.scrolled = window.scrollY > 0;
        },
    }
}
</script>


<style lang="scss" scoped>
.scroll-to-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
}

.layout-content-sub-header {
  background: transparent;
  width: 100%;
  height: auto;
  line-height: 1;
  padding: 15px;

  .attraction-data {
    max-width: calc(100% - 150px);

    span {
      display: inline-block;
      margin: 5px 30px 5px 0;

      &.attraction-id,
      &.attraction-status {
        white-space: nowrap;
      }
    }
  }
}

.attraction-menu-holder {
  position: sticky;
  top: 0;
}

.attraction-parent {
  a {
    margin-left: 5px;
  }
}

.app-details-content {
  max-width: calc(100% - 300px);
}

@include md {
  .ant-layout-sider {
    position: fixed;
    bottom: 0;
    background: white;
    z-index: 100;
  }

  .app-details-content {
    max-width: calc(100% - 80px);
    margin-left: 80px;
  }
}

.ant-layout {
  flex-wrap: wrap;
  overscroll-behavior-y: contain;
}

.ant-layout-content {
  padding: 15px;
}

.slide-fade-vertical-enter-active {
  transition: all 0.2s ease-in-out;
}

.slide-fade-vertical-leave-active {
  transition: all 0.2s ease-in-out;
}

.slide-fade-vertical-enter {
  transform: translateY(-50vw);
  opacity: 0
}

.slide-fade-vertical-enter-to {
  transform: translateY(0);
  opacity: 1
}

.slide-fade-vertical-leave-to {
  transform: translateY(-50vw);
  opacity: 0;
}
</style>

<style lang="scss">
label .anticon {
  margin-left: 5px;
}

.last-modification {
  margin-left: 5px;
}
</style>