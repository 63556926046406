var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-form-container" },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.formDataLoading } },
        [
          _c(
            "a-form",
            {
              attrs: { form: _vm.form, layout: "vertical" },
              on: { submit: _vm.submit }
            },
            [
              _c(
                "a-form-item",
                [
                  _vm.showSaveButton && !_vm.isMobileReadonly
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            "html-type": "submit",
                            loading: _vm.submitLoading
                          }
                        },
                        [_vm._v("\n          Mentés\n        ")]
                      )
                    : _vm._e(),
                  _vm.hasChanged()
                    ? _c(
                        "a-button",
                        {
                          attrs: { type: "secondary", "html-type": "button" },
                          on: { click: _vm.resetForm }
                        },
                        [
                          _c("a-icon", {
                            attrs: { type: "undo", theme: "outlined" }
                          }),
                          _vm._v("\n\n          Piszkozat törlése\n        ")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.currentTab
                    ? _c("span", { staticClass: "last-modification" }, [
                        _vm._v("\n          Utolsó mentés: "),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm
                                .moment(_vm.currentTab.modificationTime)
                                .format(_vm.timeStampFormat)
                                .toString()
                            )
                          )
                        ]),
                        _vm._v("  "),
                        _vm.currentTab.user
                          ? _c("span", [
                              _vm._v(
                                "(" + _vm._s(_vm.currentTab.user.userName) + ")"
                              )
                            ])
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm.submitResult.show
                    ? _c("a-alert", {
                        style: {
                          marginTop: "10px"
                        },
                        attrs: {
                          message: _vm.submitResult.message,
                          type: _vm.submitResult.type,
                          showIcon: "",
                          closable: "",
                          afterClose: function() {
                            return (_vm.submitResult.show = false)
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c("a-divider", { attrs: { orientation: "left" } }, [
                _vm._v(
                  "\n        A VONZERŐ AKADÁLYMENTESSÉGÉRE VONATKOZÓ ADATOK\n      "
                )
              ]),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label:
                      "Fizikai akadálymentesítés (kerekesszékkel bejárható)"
                  }
                },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "accessibility/physically",
                            {
                              rules: [
                                {
                                  required: _vm.isNotMobile,
                                  message: "Kérjük töltse ki!"
                                }
                              ],
                              validateTrigger: "blur"
                            }
                          ],
                          expression:
                            "[\n                      'accessibility/physically',\n                      {\n                          rules: [{\n                              required: isNotMobile,\n                              message: 'Kérjük töltse ki!'\n                          }],\n                          validateTrigger: 'blur'\n                      }\n                  ]"
                        }
                      ],
                      attrs: {
                        placeholder: "Kérjük válasszon!",
                        disabled: _vm.isMobileReadonly
                      }
                    },
                    [
                      _c("a-select-option", { attrs: { value: 1 } }, [
                        _vm._v("\n            Igen\n          ")
                      ]),
                      _c("a-select-option", { attrs: { value: 0 } }, [
                        _vm._v("\n            Nem\n          ")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: { label: "Hallássérültek számára akadálymentesített" }
                },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "accessibility/deaf",
                            {
                              rules: [
                                {
                                  required: _vm.isNotMobile,
                                  message: "Kérjük töltse ki!"
                                }
                              ],
                              validateTrigger: "blur"
                            }
                          ],
                          expression:
                            "[\n                      'accessibility/deaf',\n                      {\n                          rules: [{\n                              required: isNotMobile,\n                              message: 'Kérjük töltse ki!'\n                          }],\n                          validateTrigger: 'blur'\n                      }\n                  ]"
                        }
                      ],
                      attrs: {
                        placeholder: "Kérjük válasszon!",
                        disabled: _vm.isMobileReadonly
                      }
                    },
                    [
                      _c("a-select-option", { attrs: { value: 1 } }, [
                        _vm._v("\n            Igen\n          ")
                      ]),
                      _c("a-select-option", { attrs: { value: 0 } }, [
                        _vm._v("\n            Nem\n          ")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: { label: "Látássérültek számára akadálymentesített" }
                },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "accessibility/blind",
                            {
                              rules: [
                                {
                                  required: _vm.isNotMobile,
                                  message: "Kérjük töltse ki!"
                                }
                              ],
                              validateTrigger: "blur"
                            }
                          ],
                          expression:
                            "[\n                      'accessibility/blind',\n                      {\n                          rules: [{\n                              required: isNotMobile,\n                              message: 'Kérjük töltse ki!'\n                          }],\n                          validateTrigger: 'blur'\n                      }\n                  ]"
                        }
                      ],
                      attrs: {
                        placeholder: "Kérjük válasszon!",
                        disabled: _vm.isMobileReadonly
                      }
                    },
                    [
                      _c("a-select-option", { attrs: { value: 1 } }, [
                        _vm._v("\n            Igen\n          ")
                      ]),
                      _c("a-select-option", { attrs: { value: 0 } }, [
                        _vm._v("\n            Nem\n          ")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "Lift rendelkezésre áll-e?" } },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "accessibility/elevator",
                            {
                              rules: [
                                {
                                  required: false,
                                  message: "Kérjük töltse ki!"
                                }
                              ]
                            }
                          ],
                          expression:
                            "[\n                      'accessibility/elevator',\n                      {\n                          rules: [{\n                              required: false,\n                              message: 'Kérjük töltse ki!'\n                          }]\n                      }\n                  ]"
                        }
                      ],
                      attrs: {
                        placeholder: "Kérjük válasszon!",
                        disabled: _vm.isMobileReadonly
                      }
                    },
                    [
                      _c("a-select-option", { attrs: { value: 1 } }, [
                        _vm._v("\n            Igen\n          ")
                      ]),
                      _c("a-select-option", { attrs: { value: 0 } }, [
                        _vm._v("\n            Nem\n          ")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.form.getFieldValue("accessibility/elevator") === 1,
                      expression:
                        "form.getFieldValue('accessibility/elevator') === 1"
                    }
                  ],
                  attrs: {
                    selfUpdate: true,
                    label: "Ha IGEN, akkor liftek száma"
                  }
                },
                [
                  _c("a-input-number", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "accessibility/elevatorCount",
                          {
                            validateTrigger: ["blur", "change"],
                            preserve: true,
                            rules: [
                              {
                                required: false,
                                type: "integer",
                                whitespace: true,
                                transform: function(value) {
                                  value = parseInt(value)

                                  return isNaN(value) ? null : value
                                },
                                message: "Kérjük töltse ki!"
                              }
                            ]
                          }
                        ],
                        expression:
                          "[\n                      `accessibility/elevatorCount`,\n                      {\n                          validateTrigger: ['blur', 'change'],\n                          preserve: true,\n                          rules: [{\n                              required: false,\n                              type: 'integer',\n                              whitespace: true,\n                              transform: (value) => {\n                                value = parseInt(value);\n\n                                return isNaN(value) ? null : value;\n                              },\n                              message: 'Kérjük töltse ki!'\n                          }]\n\n                      }\n                  ]"
                      }
                    ],
                    attrs: { min: 0, disabled: _vm.isMobileReadonly }
                  }),
                  _vm._v("\n        db\n      ")
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label:
                      "A vonzerő környezete akadálymentesített-e? (bejárattól 100 méteres körben)"
                  }
                },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "accessibility/area",
                            {
                              rules: [
                                {
                                  required: false,
                                  message: "Kérjük töltse ki!"
                                }
                              ]
                            }
                          ],
                          expression:
                            "[\n                      'accessibility/area',\n                      {\n                          rules: [{\n                              required: false,\n                              message: 'Kérjük töltse ki!'\n                          }]\n                      }\n                  ]"
                        }
                      ],
                      attrs: {
                        placeholder: "Kérjük válasszon!",
                        disabled: _vm.isMobileReadonly
                      }
                    },
                    [
                      _c("a-select-option", { attrs: { value: 1 } }, [
                        _vm._v("\n            Igen\n          ")
                      ]),
                      _c("a-select-option", { attrs: { value: 0 } }, [
                        _vm._v("\n            Nem\n          ")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: "Kísérő személyzet igény esetén áll-e rendelkezésre?"
                  }
                },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "accessibility/supportStaff",
                            {
                              rules: [
                                {
                                  required: false,
                                  message: "Kérjük töltse ki!"
                                }
                              ]
                            }
                          ],
                          expression:
                            "[\n                      'accessibility/supportStaff',\n                      {\n                          rules: [{\n                              required: false,\n                              message: 'Kérjük töltse ki!'\n                          }]\n                      }\n                  ]"
                        }
                      ],
                      attrs: {
                        placeholder: "Kérjük válasszon!",
                        disabled: _vm.isMobileReadonly
                      }
                    },
                    [
                      _c("a-select-option", { attrs: { value: "free" } }, [
                        _vm._v(
                          "\n            Igen (téritésmentesen)\n          "
                        )
                      ]),
                      _c("a-select-option", { attrs: { value: "fee" } }, [
                        _vm._v(
                          "\n            Igen (térítés ellenében)\n          "
                        )
                      ]),
                      _c("a-select-option", { attrs: { value: "no" } }, [
                        _vm._v("\n            Nem\n          ")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.form.getFieldValue("accessibility/supportStaff") ===
                          "free" ||
                        _vm.form.getFieldValue("accessibility/supportStaff") ===
                          "fee",
                      expression:
                        "form.getFieldValue('accessibility/supportStaff') === 'free' || form.getFieldValue('accessibility/supportStaff') === 'fee'"
                    }
                  ],
                  attrs: {
                    label:
                      "Ha ennek igénye előre bejelentendő, akkor milyen elérhetőségen?"
                  }
                },
                [
                  _c("a-textarea", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "accessibility/supportStaffContact",
                          {
                            rules: [
                              {
                                required: false,
                                message: "Kérjük töltse ki!"
                              },
                              {
                                max:
                                  _vm.characterCounts[
                                    "accessibility/supportStaffContact"
                                  ].max,
                                message:
                                  "Maximum " +
                                  _vm.characterCounts[
                                    "accessibility/supportStaffContact"
                                  ].max +
                                  " karakter engedélyezett."
                              }
                            ]
                          }
                        ],
                        expression:
                          "[\n                      'accessibility/supportStaffContact',\n                      {\n                          rules: [\n                              {\n                                required: false,\n                                message: 'Kérjük töltse ki!',\n                              },\n                              {\n                                max: characterCounts['accessibility/supportStaffContact'].max,\n                                message: 'Maximum ' + characterCounts['accessibility/supportStaffContact'].max + ' karakter engedélyezett.',\n                              }\n                            ]\n                      }\n                  ]"
                      }
                    ],
                    attrs: {
                      maxLength: 25000,
                      disabled: _vm.isMobileReadonly,
                      autoSize: ""
                    }
                  })
                ],
                1
              ),
              _c("a-divider"),
              _c(
                "a-form-item",
                { attrs: { selfUpdate: true } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v("\n            Megjegyzés\n\n            "),
                      _c(
                        "a-tooltip",
                        {
                          attrs: {
                            title:
                              "Kérjük ezt a cellát akkor töltse ki, ha a témához kapcsolódóan egyéb megjegyzése van."
                          }
                        },
                        [
                          _c("a-icon", { attrs: { type: "question-circle-o" } })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-textarea", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "accessibility/comment",
                          {
                            rules: [
                              {
                                required: false,
                                message: "Kérjük töltse ki!"
                              }
                            ]
                          }
                        ],
                        expression:
                          "[\n                      'accessibility/comment',\n                      {\n                          rules: [{\n                              required: false,\n                              message: 'Kérjük töltse ki!'\n                          }]\n                      }\n                  ]"
                      }
                    ],
                    attrs: {
                      disabled: _vm.isMobileReadonly,
                      maxLength: 25000,
                      autoSize: {
                        minRows: 2
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                [
                  _vm.showSaveButton && !_vm.isMobileReadonly
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            "html-type": "submit",
                            loading: _vm.submitLoading
                          }
                        },
                        [_vm._v("\n          Mentés\n        ")]
                      )
                    : _vm._e(),
                  _vm.hasChanged()
                    ? _c(
                        "a-button",
                        {
                          attrs: { type: "secondary", "html-type": "button" },
                          on: { click: _vm.resetForm }
                        },
                        [
                          _c("a-icon", {
                            attrs: { type: "undo", theme: "outlined" }
                          }),
                          _vm._v("\n\n          Piszkozat törlése\n        ")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.currentTab
                    ? _c("span", { staticClass: "last-modification" }, [
                        _vm._v("\n          Utolsó mentés: "),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm
                                .moment(_vm.currentTab.modificationTime)
                                .format(_vm.timeStampFormat)
                                .toString()
                            )
                          )
                        ]),
                        _vm._v("  "),
                        _vm.currentTab.user
                          ? _c("span", [
                              _vm._v(
                                "(" + _vm._s(_vm.currentTab.user.userName) + ")"
                              )
                            ])
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm.submitResult.show
                    ? _c("a-alert", {
                        style: {
                          marginTop: "10px"
                        },
                        attrs: {
                          message: _vm.submitResult.message,
                          type: _vm.submitResult.type,
                          showIcon: "",
                          closable: "",
                          afterClose: function() {
                            return (_vm.submitResult.show = false)
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }