var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    { staticClass: "not-found-row" },
    [
      _c("a-col", { attrs: { xs: 2, sm: 4, md: 6, lg: 8 } }),
      _c(
        "a-col",
        { attrs: { xs: 20, sm: 16, md: 12, lg: 8 } },
        [
          _c("a-icon", {
            style: {
              fontSize: "88px"
            },
            attrs: {
              type: "close-circle",
              theme: "twoTone",
              twoToneColor: "red"
            }
          }),
          _c("h1", [
            _vm._v("\n            " + _vm._s(_vm.errorCode) + "\n        ")
          ]),
          _c("h3", [_vm._v("\n            Jogosultság szükséges\n        ")]),
          _c(
            "router-link",
            { attrs: { to: "/login", replace: "" } },
            [
              _c("a-button", {
                attrs: { type: "primary", ghost: "", icon: "login" }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("a-col", { attrs: { xs: 2, sm: 4, md: 6, lg: 8 } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }