<template>
  <div>
    <a-row
        class="password-reset-content-row"
    >
      <a-col :xs="1" :sm="4" :md="7" />
      <a-col :xs="22" :sm="16" :md="10">
        <div class="password-reset-card-container">
          <a-form
              v-show="!success"
              id="password-reset-component-form"
              :form="form"
              class="password-reset-form"
              v-on:submit="submit"
          >
            <h1>
              Új jelszó megadása
            </h1>

            <a-form-item>
              <a-input
                  v-decorator="[
                    'password',
                    {
                        rules: [
                            // {
                            //   required: true,
                            //   message: 'Kérjük írja be az új jelszót!'
                            // },
                            {
                              pattern: /(^(?=.*[A-ZÁÉÖÜŐÚÍ]).{1,}$)/g,
                              message: 'A jelszónak legalább egy nagy betűt kell tartalmaznia!'
                            },
                            {
                              pattern: /(^(?=.*[a-záéöüőúí]).{1,}$)/,
                              message: 'A jelszónak legalább egy kis betűt kell tartalmaznia!'
                            },
                            {
                              pattern: passwordExtraPattern,
                              message: 'A jelszónak legalább egy számot vagy írásjelet kell tartalmaznia!'
                            },
                            {
                              min: 8,
                              message: 'A jelszónak legalább 8 karakterből kell állnia!'
                            }
                        ],
                        validateTrigger: ['blur', 'keyup']
                    }
                ]"
                  type="password"
                  placeholder="Új jelszó megadása"
              >
                <a-icon
                    slot="prefix"
                    type="lock"
                    style="color: rgba(0, 0, 0, 0.5)"
                />
              </a-input>
            </a-form-item>

            <a-form-item>
              <a-input
                  v-decorator="[
                    'passwordControl',
                    {
                        rules: [
                            {
                              required: true,
                              message: 'Kérjük ide is írja be ide új jelszót!'
                            },
                            {
                              validator: passwordControlValidator
                            }
                        ]
                    }
                ]"
                  type="password"
                  placeholder="Új jelszó megerősítése"
              >
                <a-icon
                    slot="prefix"
                    type="lock"
                    style="color: rgba(0, 0, 0, 0.5)"
                />
              </a-input>
            </a-form-item>

            <a-form-item>
              <a-button
                  :loading="loading"
                  type="primary"
                  html-type="submit"
                  class="password-reset-form-button"
              >
                Új jelszó megadása
              </a-button>
            </a-form-item>
          </a-form>

          <div class="success-page" v-show="success">
            <h1>
              Sikeres jelszó változtatás!
            </h1>

            <p>
              <router-link class="back-button" to="/login">
                <a-button type="primary">
                  Tovább a bejelentkezéshez
                </a-button>
              </router-link>
              <br><br>

              <span class="info-span">
                Információ és technikai segítség:<br>

                <a href="mailto:neta@mtu.gov.hu">neta@mtu.gov.hu</a>
              </span>
            </p>
          </div>

          <div class="back-button-holder">
            <router-link class="back-button" to="/login" v-show="!success">
              <a>
                Vissza a bejelentkezéshez
              </a>
            </router-link>
          </div>
        </div>
      </a-col>
      <a-col :xs="1" :sm="4" :md="7" />
    </a-row>
  </div>
</template>

<script>
import LoginCardComponent from '@/components/login/LoginCardComponent.vue';
import axios from "axios";

export default {
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },

  data() {
    return {
      passwordExtraPattern: /(^(?=.*[\`\!\"\?\$\?\%\^\&\*\(\)\_\-\+\=\{\[\}\]\:\;\@\\\'\~\#\|\<\,\>\.\?\/]|.*[0-9]).{1,}$)/,
      loading: false,
      success: false
    }
  },

  methods: {
    passwordControlValidator(rule, value, callback) {
      const form = this.form;
      if (value && value !== form.getFieldValue('password')) {
        callback('A beírt jelszavak nem egyeznek!');
      } else {
        callback();
      }
    },

    submit(event) {
      event.preventDefault();

      this.form.validateFieldsAndScroll((error, values) => {
        if (!error) {
          this.loading = true;

          axios.post('/api/v1/companies/1/auth/password-change?locale=hu', {
            password: values.password,
            hash: this.$route.params.hash
          })
              .then((response) => {
                this.loading = false;

                if (!response.data.isValid) {
                  this.$message.warning(response.data.messages.email);

                  return false;
                }

                this.success = true;
              })
              .catch((error) => {
                this.$message.error('Szerver hiba!');

                this.loading = false;
              })
        }
      })
    }
  },

  components: {
    LoginCardComponent
  },
}
</script>

<style lang="scss" scoped>
.password-reset-form {
  text-align: left;
}

.password-reset-content-row {
  margin-top: 10vh;
}

.password-reset-card-container {
  background-color: white;
  max-width: 600px;
  text-align: center;
  margin: auto;
  padding-top: 48px;
  padding-bottom: 38px;
  padding-right: 30px;
  padding-left: 30px;
}

.back-button-holder {
  margin-top: 35px;
  text-align: left;
}

.password-reset-form-button {
  width: 100%
}
</style>
