<template>
    <div>
        <router-link :to="{name: 'neta-list-hungarikum'}">
            <a-button
                    v-show="!collapsed"
                    class="attraction-back-button"
                    size="large"
                    icon="table"
                    type="default"
            >Vissza a listához
            </a-button>

            <a-button
                    v-show="collapsed"
                    class="attraction-back-button"
                    size="large"
                    icon="table"
                    type="default"
            />
        </router-link>

        <div class="attraction-data" v-if="hungarikum" v-show="!collapsed">
            <div class="attraction-name">
                <strong>{{ hungarikum.label }}</strong>
            </div>

            <div class="attraction-id">
                Azonosító: <strong>{{ hungarikum.id }}</strong>
            </div>

            <div class="attraction-city" v-if="hungarikum.address && hungarikum.address.city">
                Település: <strong>{{ hungarikum.address.city.name }}</strong>
            </div>

            <div class="attraction-status">
                Státusz: <strong>{{ statusNames[hungarikum.status] }}</strong>
            </div>
        </div>

        <a-menu
                mode="inline"
                theme="light"
                :style="{height: '100%',maxWidth: '300px'}"
                :defaultSelectedKeys="selectedMenuItem()"
                :defaultOpenKeys="['neta-basic-hungarikum-data']"
        >

            <a-menu-item
                    v-for="subForm in getCategoryRoutes('neta-basic-hungarikum-data')"
                    :key="subForm.name"
            >
                <router-link :to="{name: subForm.name}">
                    <a-icon
                            v-if="typeof formIdMap[subForm.path] !== 'undefined' && loading[formIdMap[subForm.path]][hungarikum.id]"
                            type="sync"
                            :style="{color: 'black'}"
                            :spin="true"
                    />

                    <a-icon
                            v-else-if="typeof formIdMap[subForm.path] !== 'undefined' && drafts[formIdMap[subForm.path]][hungarikum.id]"
                            type="edit"
                            :style="{color: 'orange'}"
                    />

                    <a-icon
                            v-else-if="getTabIcon(subForm.path)"
                            :type="getTabIcon(subForm.path).icon"
                            :style="{color: getTabIcon(subForm.path).color}"
                    />

                    <span>
              {{ subForm.meta.menuName }}
            </span>
                </router-link>
            </a-menu-item>
            <!--      </a-sub-menu>-->
        </a-menu>
    </div>
</template>

<script>
import invert from 'lodash/invert';

export default {
    data() {
        return {
            formsMap: {
                DATA: 'data-form',
            }
        }
    },

    props: {
        collapsed: {
            type: Boolean,
            default: false
        },

        statusNames: {
            type: Object,
            required: true
        }
    },

    computed: {
        /**
         * @returns {Object}
         */
        hungarikum() {
            return this.$store.state.hungarikum.current;
        },

        /**
         * @returns {Boolean}
         */
        loading() {
            return this.$store.state.forms.loading.hungarikum;
        },

        /**
         * @returns {Object}
         */
        drafts() {
            return this.$store.state.forms.drafts.hungarikum;
        },

        /**
         * @returns {Object}
         */
        formIdMap() {
            return invert(this.formsMap);
        },

        /**
         * @returns {boolean}
         */
        pricesVisible() {
            return this.$store.state.pricesVisible;
        },
    },

    methods: {
        /**
         * @returns {[]}
         */
        getCategoryRoutes(categoryName) {
            const netaDetailsRoutes = () => {
                const netaRoutes = this.$router.options.routes.find(item => {
                    return item.name === 'neta';
                });

                return netaRoutes.children.find(item => {
                    return item.name === 'neta-hungarikum-details';
                });
            };

            const category = netaDetailsRoutes().children.find(item => {
                return item.name === categoryName;
            });

            if (category) {
                return category.children;
            }

            return [];
        },

        /**
         * @returns [{string}]
         */
        selectedMenuItem() {
            return [this.$route.name];
        },

        /**
         * @param {string} tabName
         * @returns {{color: string, icon: string}|null}
         */
        getTabIcon(tabName) {
            return {
                color: 'grey',
                icon: 'minus-circle'
            };
        }
    },
}
</script>

<style lang="scss" scoped>
.attraction-back-button {
  margin: 15px;
}

.attraction-data {
  width: 300px;
  padding: 15px;
  background: white;
  word-wrap: break-word;
  overflow: hidden;
}

.attraction-parent {
  a {
    margin-left: 5px;
  }
}

.empty-circle-icon {
  width: 15px;
  height: 15px;
  display: inline-block;
  border: 3px solid white;
  margin-right: 8px;
  border-radius: 50%;
  margin-bottom: -2px;
  box-shadow: 0 0 2px 0 black, inset 0 0 2px black;
}
</style>
