<template>
    <div class="specialist-export-container">
        <h1 class="specialist-export-title">
            Specialista Export
        </h1>
        <div class="tourinform-form-container">
            <a-form
                    :form="form"
                    layout="vertical"
                    v-on:submit="submit"
            >
                <a-form-item
                        label="Időintervallum"
                >
                    <a-range-picker
                            :separator="''"
                            :format="dateFormat"
                            v-decorator="[
          'creationTime',
            {
                rules: [{
                    required: true,
                    message: 'Kérem válasszon'
                }],
            }
          ]"
                    >
                    </a-range-picker>
                </a-form-item>

                <a-form-item label="Entitás típusok">
                    <a-select
                            mode="multiple"
                            v-decorator="[
                            'groupTypes',
                            {
                                rules: [
                                    {
                                    required: !isChecked('globalEntities'),
                                    whitespace: true,
                                    message: 'Kérjük töltse ki!',
                                    type: 'array'
                                  },
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
                            placeholder="Kezdjen gépelni"
                            showSearch
                            :disabled="isChecked('globalEntities')"
                            :showArrow="false"
                            :defaultActiveFirstOption="false"
                            :filterOption="false"
                            :style="{width: '100%'}"
                    >
                        <a-select-option
                                key="attraction"
                                value="attraction"
                        >
                            Attrakciók
                        </a-select-option>

                        <a-select-option
                                key="event"
                                value="event"
                        >
                            Események
                        </a-select-option>

                        <a-select-option
                                key="multi-attraction"
                                value="multi-attraction"
                        >
                            Multi-TOBok
                        </a-select-option>

                        <a-select-option
                                key="multi-event"
                                value="multi-event"
                        >
                            Multi-Események
                        </a-select-option>
                    </a-select>

                    <a-checkbox
                            v-decorator="['globalEntities']"
                            v-on:change="(event) => exportTypeChange('id')"
                            :default-checked="true"
                    >
                        Összes típus
                    </a-checkbox>
                </a-form-item>

                <a-form-item label="Specialista">
                    <a-select
                            mode="multiple"
                            v-decorator="[
                            'users/id',
                            {
                                rules: [
                                    {
                                    required: !isChecked('globalExport'),
                                    whitespace: true,
                                    message: 'Kérjük töltse ki!',
                                    type: 'array'
                                  },
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
                            placeholder="Kezdjen gépelni"
                            showSearch
                            :disabled="isChecked('globalExport')"
                            :showArrow="false"
                            :defaultActiveFirstOption="false"
                            :filterOption="false"
                            :style="{width: '100%'}"
                            v-on:search="specialistAutoComplete"
                            v-on:dropdownVisibleChange="specialistAutoComplete"
                            :notFoundContent="specialistOptionsLoading ? undefined : null"
                    >
                        <a-spin v-if="specialistOptionsLoading" slot="notFoundContent" size="small" />

                        <a-select-option
                                v-for="option in specialistOptions"
                                :key="option.id"
                                :value="option.id"
                        >
                            {{ option.name }}
                        </a-select-option>
                    </a-select>

                    <a-checkbox
                            v-decorator="['globalExport']"
                            v-on:change="(event) => exportTypeChange('id')"
                    >
                        Összes specialista
                    </a-checkbox>
                </a-form-item>

                <a-form-item>
                    <a-button
                            type="primary"
                            html-type="submit"
                            :loading="submitLoading"
                    >
                        Export letöltése
                    </a-button>
                </a-form-item>
            </a-form>
        </div>
    </div>
</template>

<script>
import debounce from 'lodash.debounce';
import axios from "axios";

export default {
    beforeCreate() {
        this.form = this.$form.createForm(this);

        this.$nextTick(() => {
            this.form.setFieldsValue({ 'globalEntities': true });
        });
    },

    data() {
        return {
            dateFormat: 'YYYY.MM.DD',

            submitLoading: false,

            specialistOptions: [],
            specialistOptionsLoading: false
        }
    },

    methods: {
        submit(event) {
            event.preventDefault();

            this.form.validateFieldsAndScroll(
                (errors, values) => {
                    if (errors) {
                        this.$message.warning(
                            'Kérem töltse ki a hiányzó mezőket!'
                        );

                        return false;
                    }

                    this.submitLoading = true;

                    this.$message.loading({
                        content: 'Specialista export készítése',
                        key: 'SPECIALIST-EXPORT',
                        duration: 0
                    });

                    let filters = {};
                    let rangeStart = null;
                    let rangeEnd = null;

                    if (values.creationTime) {
                        let creationTimeRange = values.creationTime;

                        rangeStart = creationTimeRange[0].clone();
                        rangeEnd = creationTimeRange[1].clone();

                        filters['creationTime'] = '(>= ' + rangeStart.format('YYYY-MM-DD') + ',< ' + rangeEnd.add(1, 'days').format('YYYY-MM-DD') + ')';
                    }

                    axios.get(
                        '/api/v1/companies/1/me/attractions/log/export/office-specialist',
                        {
                            headers: {
                                accept: '*/*'
                            },
                            responseType: 'blob',
                            params: {
                                startDate: rangeStart ? rangeStart.format('YYYY-MM-DD') : undefined,
                                endDate: rangeEnd ? rangeEnd.format('YYYY-MM-DD') : undefined,
                                filter: filters,
                                'users/id': values.globalExport ? null : values['users/id'],
                                groupTypes: values.globalEntities ? null : values.groupTypes
                            }
                        }
                    ).then((response) => {
                        this.submitLoading = false;

                        this.$message.success({ content: 'A specialista export elkészült!', key: 'SPECIALIST-EXPORT' });

                        let fileName = 'Specialista-Export.xlsx';

                        if (typeof response.headers['content-disposition'] !== 'undefined') {
                            let matches = response.headers['content-disposition'].match(/filename="(.+)"/);

                            if (matches[1]) {
                                fileName = matches[1];
                            }
                        }

                        this.downloadBlob(
                            response.data,
                            fileName,
                            'application/xlsx'
                        );
                    }).catch(() => {
                        this.submitLoading = false;

                        this.$message.error({ content: 'Hiba a specialista export közben!', key: 'SPECIALIST-EXPORT' });
                    });
                }
            );
        },

        /**
         * @param {blob} data
         * @param {string} fileName
         * @param {string} type
         */
        downloadBlob(data, fileName, type) {
            const blob = new Blob([data], { type: 'type' });
            const link = document.createElement('a');

            link.href = window.URL.createObjectURL(blob);
            link.setAttribute('download', fileName);

            document.body.appendChild(link);

            link.click();
            link.remove();
        },

        /**
         * @param {string} fieldName
         *
         * @return {boolean}
         */
        isChecked(fieldName) {
            return !!this.form.getFieldValue(fieldName);
        },

        /**
         * @param fieldName
         */
        exportTypeChange(fieldName) {
            this.$nextTick(() => {
                if (this.isChecked(fieldName)) {
                    let populateData = {};

                    populateData[fieldName] = null;

                    this.form.setFieldsValue(populateData);
                }

                this.form.validateFields(typeof fieldName === 'object' ? fieldName : [fieldName]);
            })
        },

        /**
         * Auto-complete office list from API
         *
         * @param {string} name
         *
         * Throttled: 250ms when code is not empty
         */
        specialistAutoComplete: function (name) {
            var self = this;

            let apiUrl = '/api/v1/companies/1/attractions/specialists';

            debounce(function (name) {
                self.specialistOptions = [];
                self.specialistOptionsLoading = true;

                axios.get(apiUrl, {
                    params: {
                        locale: self.$store.state.attraction.locale,
                        name: (true !== name && name) ? '*' + name + '*' : null,
                        fields: 'id,name',
                        order: 'name ASC',
                        limit: 200
                    }
                }).then(response => {
                    self.specialistOptions = response.data;
                    self.specialistOptionsLoading = false;
                });
            }, name !== true ? 250 : 0)(name)
        },
    }
}

</script>

<style lang="scss" scoped>
.specialist-export-container {
  padding: 25px;
  max-width: 600px;
  margin: auto;
}
</style>