<template>
  <div>
    <transition name="slide-fade" mode="out-in">
      <div>
        <router-view ref="app-form-route-component" />
      </div>
    </transition>
  </div>
</template>
<script>

export default {
  beforeRouteUpdate(to, from, next) {
    this.$parent.$el.scrollTop = 0;

    next();
  }
}
</script>

<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all 0.2s ease-in-out;
}

.slide-fade-leave-active {
  transition: all 0.2s ease-in-out;
}

.slide-fade-enter {
  transform: translateX(-50vw);
  opacity: 0
}

.slide-fade-enter-to {
  transform: translateX(0);
  opacity: 1
}

.slide-fade-leave-to {
  transform: translateX(-50vw);
  opacity: 0;
}
</style>
