import {
    SET_USER,
    SET_OFFICE,
    SET_LOGGEDIN_STATUS,
    SET_SELECTED_ROLE, CLEAR_LAST_LIST_QUERY_PARAMS
} from '@/mutation_types.js';
import {config} from '@/config.js';

import {
    USER_LOGIN,
    USER_LOGOUT
} from '@/action_types.js';

import LocalStorage from '@/helpers/localstorage.js';
import * as Sentry from "@sentry/vue";

const state = {
    loggedIn: false,
    userName: '',
    email: '',
    office: {
        id: null,
        address: '',
        name: '',
        phone: ''
    },
    rememberMe: false,
    roles: [],
    selectedRole: null
};

const getters = {
    /**
     * @param state
     * @returns {boolean}
     */
    isLoggedIn(state) {
        return state.loggedIn;
    },

    /**
     * @param state
     * @param getters
     * @returns {*}
     */
    isRoleAdmin(state, getters) {
        return getters.hasRoleAvailable(['ROLE_MTU_ADMIN']);
    },

    /**
     * @param state
     * @param getters
     * @returns {*}
     */
    isRoleViewer(state, getters) {
        return getters.hasRoleAvailable(['ROLE_MTU_VIEWER']);
    },

    /**
     * @param state
     * @param getters
     * @returns {*}
     */
    isRoleHungarikum(state, getters) {
        return getters.hasRoleAvailable(['ROLE_HUNGARIKUM']);
    },

    /**
     *
     * @param state
     * @returns {function(*=): boolean}
     */
    hasRole: (state) => (role) => {
        // return state.roles.indexOf(role) > -1;
        return role === state.selectedRole;
    },

    /**
     * @param state
     * @param getters
     * @returns {function(*): boolean}
     */
    hasRoleAvailable: (state, getters) => (roleArray) => {
        return !!roleArray.some(role => getters.hasRole(role));
    },

    getTekaApiKey: (state) => (isOffice = false) => {
        const isAdmin = state.selectedRole === 'ROLE_MTU_ADMIN';
        const isOfficeSpecialist = state.selectedRole === 'ROLE_OFFICE_SPECIALIST';
        const isOfficeLeader = state.selectedRole === 'ROLE_OFFICE_LEADER';
        const isOfficeEmployee = state.selectedRole === 'ROLE_OFFICE_EMPLOYEE';
        const isMobileApp = state.selectedRole === 'ROLE_MOBILE_APP';

        if (isOffice) {
            if (config.development) {
                if (isOfficeLeader) {
                    return "111e3fa6-c72d-460e-8fd5-227fbe6e9ed5"; // Tartalompublikáló
                }

                if (isOfficeEmployee) {
                    return "2855a07f-08e6-4145-a89e-375c06d1496f"; // Tartalomszerkesztő
                }
            } else {
                if (isOfficeLeader) {
                    return "efdbc875-2e1e-49cf-8dbe-b9a42baad069"; // Tartalompublikáló
                }

                if (isOfficeEmployee) {
                    return "2855a07f-08e6-4145-a89e-375c06d1496f"; // Tartalomszerkesztő
                }
            }
        } else {
            if (config.development) {
                if (isAdmin || isOfficeSpecialist) {
                    return "111e3fa6-c72d-460e-8fd5-227fbe6e9ed5"; // Tartalompublikáló
                }

                if (isOfficeLeader) {
                    return "ecae0917-6311-410e-a27b-29bceee95689"; // Tartalomszerkesztő
                }

                if (isOfficeEmployee) {
                    return "a31619fe-120f-40fc-b769-161bec524a42" // Tartalomfeltöltő
                }

                if(isMobileApp) {
                    return "531bbac7-0a78-4c37-bc97-648261b69412";
                }
            } else {
                if (isAdmin || isOfficeSpecialist) {
                    return "efdbc875-2e1e-49cf-8dbe-b9a42baad069"; // Tartalompublikáló
                }

                if (isOfficeLeader) {
                    return "2855a07f-08e6-4145-a89e-375c06d1496f"; // Tartalomszerkesztő
                }

                if (isOfficeEmployee) {
                    return "991a7080-3f13-4829-9fbf-a343854aeed1" // Tartalomfeltöltő
                }

                if(isMobileApp) {
                    return "7e6af8fd-e34a-40cf-8dc8-4c1184608a85";
                }
            }
        }

        return undefined;
    },
};

const mutations = {
    [SET_USER](state, payload) {
        state.userName = payload.userName;
        state.email = payload.email;
        state.office = payload.office ? payload.office : {
            id: null,
            address: '',
            name: '',
            phone: ''
        };
        state.roles = payload.roles;

        if (!state.roles.length)
            // No roles available
            return;

        if (payload.selectedRole && state.roles.indexOf(payload.selectedRole) !== -1) {
            // Set desired role
            state.selectedRole = payload.selectedRole;

            return;
        }

        if (state.selectedRole && state.roles.indexOf(state.selectedRole) !== -1) {
            // Already has a selected role which is still valid

            return;
        }

        state.selectedRole = state.roles[0];

        Sentry.setUser({
            username: state.userName + ' (' + state.selectedRole + ')',
            email: state.email
        });
    },

    [SET_SELECTED_ROLE](state, payload) {
        if (!state.roles.length)
            // No roles available
            return;

        if (payload.selectedRole && state.roles.indexOf(payload.selectedRole) !== -1) {
            // Set desired role
            state.selectedRole = payload.selectedRole;

            let userData = LocalStorage.getUserData();

            userData.selectedRole = state.selectedRole;

            LocalStorage.setUserData(userData);

            Sentry.setUser({
                username: state.userName + ' (' + state.selectedRole + ')',
                email: state.email
            });
        }
    },

    [SET_OFFICE](state, payload) {
        state.office = payload.office;

        if (state.rememberMe) {
            let userData = LocalStorage.getUserData();

            userData.office = state.office;

            LocalStorage.setUserData(userData);
        }
    },

    [SET_LOGGEDIN_STATUS](state, payload) {
        state.loggedIn = payload.loggedInStatus;
        state.rememberMe = payload.rememberMe;
    }
};

const actions = {
    [USER_LOGIN]({ commit }, payload) {
        commit({
            type: SET_USER,
            userName: payload.userName,
            email: payload.email,
            office: payload.office,
            roles: payload.roles,
            selectedRole: payload.selectedRole
        });

        commit({
            type: SET_LOGGEDIN_STATUS,
            loggedInStatus: true,
            rememberMe: payload.rememberMe
        });

        if (payload.rememberMe) {
            LocalStorage.setUserData({
                userName: payload.userName,
                email: payload.email,
                office: payload.office,
                roles: payload.roles,
                selectedRole: payload.roles.length ? payload.roles[0] : null
            });
        }
    },
    async [USER_LOGOUT]({ commit }) {
        commit({
            type: SET_USER,
            userName: '',
            email: '',
            office: {
                id: null,
                address: '',
                name: '',
                phone: ''
            },
            roles: [],
            selectedRole: null
        });

        commit({
            type: SET_LOGGEDIN_STATUS,
            loggedInStatus: false,
            rememberMe: false
        });

        commit({
            type: CLEAR_LAST_LIST_QUERY_PARAMS,
            groupType: 'attraction',
        });

        commit({
            type: CLEAR_LAST_LIST_QUERY_PARAMS,
            groupType: 'event',
        });

        LocalStorage.deleteUserData();
        LocalStorage.deleteJwt();
    },
};


export default {
    state,
    getters,
    mutations,
    actions
}