<template xmlns="">
  <div class="app-form-container">
    <a-spin :spinning="formDataLoading">
      <a-form :form="form" v-on:submit="submit" layout="vertical">
        <a-form-item>
          <a-button type="primary" html-type="submit" :loading="submitLoading" v-if="showSaveButton && !isMobileReadonly">
            Mentés
          </a-button>

          <a-button type="secondary" html-type="button" v-on:click="resetForm" v-if="hasChanged()">
            <a-icon type="undo"
                    theme="outlined" />

            Piszkozat törlése
          </a-button>

          <span v-if="currentTab" class="last-modification">
            Utolsó mentés: <strong>{{ moment(currentTab.modificationTime).format(timeStampFormat).toString() }}</strong> &nbsp;<span v-if="currentTab.user">({{ currentTab.user.userName }})</span>
          </span>

          <a-alert
              :style="{
                        marginTop: '10px'
                    }"
              v-if="submitResult.show"
              :message="submitResult.message"
              :type="submitResult.type"
              showIcon
              closable
              :afterClose="() => submitResult.show = false"
          />
        </a-form-item>

        <a-divider orientation="left">
          A VONZERŐ NYITA TARTÁSÁRA VONATKOZÓ ADATOK
        </a-divider>

        <a-form-item label="Látogatható?">
          <a-radio-group
              :disabled="isMobileReadonly"
              v-decorator="[
                        'opening/status',
                        {
                            rules: [{

                                required: isNotMobile,
                                preserve: true,
                                message: 'Kérjük válasszon!',
                            }]
                        }
                    ]"
          >
            <a-radio-button value="open">Megadott nyitva tartás szerint nyitva</a-radio-button>
            <a-radio-button value="closed_indefinitely">Határozatlan ideig zárva</a-radio-button>
            <a-radio-button value="closed_definitely">Határozott ideig zárva</a-radio-button>
          </a-radio-group>
        </a-form-item>

        <a-form-item
                :selfUpdate="true"
                v-show="form.getFieldValue('opening/status') === 'closed_indefinitely'"
                label="Inaktivitás indoklása"
        >
            <a-textarea
                    :disabled="isMobileReadonly"
                    :maxLength="25000"
                    v-decorator="[
                          'opening/statusComment',
                          {
                              rules: [{
                                  required: form.getFieldValue('opening/status') === 'closed_indefinitely',
                                  message: 'Kérjük töltse ki!'
                              }],
                              validateTrigger: ['blur', 'keyup']
                          }
                      ]"
                    :autoSize="{minRows: 2}"
            />
        </a-form-item>

        <a-form-item label="A látogatáshoz kötelező előzetesen időpontot foglalni?">
          <a-select
              :disabled="isMobileReadonly"
              v-decorator="[
                        'payment/appointments',
                        {
                            rules: [{
                                required: false,
                                message: 'Kérjük töltse ki!'
                            }]
                        }
                    ]"
              placeholder="Kérjük válasszon!"
          >
            <a-select-option :value="1">Igen</a-select-option>
            <a-select-option :value="0">Nem</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
            v-show="form.getFieldValue('opening/status') === 'closed_definitely'"
            label="Tervezett újranyitás dátuma"
        >
          <a-date-picker
              :disabled="isMobileReadonly"
              v-decorator="[
                        'opening/reopenDate',
                        {
                            rules: [{
                                required: form.getFieldValue('opening/status') === 'closed_definitely',
                                message: 'Kérjük töltse ki!'
                            }],
                        }
                    ]"
              style="width: 85%"
              :format="dateFields['opening/reopenDate']"
          />
        </a-form-item>

<!--        <a-form-item label="Egész évben nyitva tart vagy csak szezonálisan van nyitva?">-->
<!--          <a-select-->
<!--              v-decorator="['opening/seasonality']"-->
<!--              placeholder="Kérjük válasszon!"-->
<!--          >-->
<!--            <a-select-option :value="0">-->
<!--              Egész évben nyitva tart-->
<!--            </a-select-option>-->

<!--            <a-select-option :value="1">-->
<!--              Csak szezonálisan van nyitva-->
<!--            </a-select-option>-->
<!--          </a-select>-->
<!--        </a-form-item>-->

<!--        <a-form-item-->
<!--            v-show="form.getFieldValue('opening/seasonality') === 1"-->
<!--            label="Nyitás dátuma"-->
<!--        >-->
<!--          <a-date-picker-->
<!--              :disabled-date="openingRangeStartDisabled"-->
<!--              v-decorator="[-->
<!--                        `opening/seasonalityStartDate`,-->
<!--                        {-->
<!--                            rules: [{-->
<!--                                required: form.getFieldValue('opening/seasonality') === 1,-->
<!--                                message: 'Kérjük töltse ki!',-->
<!--                            }],-->
<!--                        }-->
<!--                    ]"-->
<!--              style="width: 85%"-->
<!--              :format="dateFields['opening/seasonalityStartDate']"-->
<!--          >-->
<!--          </a-date-picker>-->
<!--        </a-form-item>-->

<!--        <a-form-item-->
<!--            v-show="form.getFieldValue('opening/seasonality') === 1"-->
<!--            label="Zárás dátuma"-->
<!--        >-->
<!--          <a-date-picker-->
<!--              :disabled-date="openingRangeEndDisabled"-->
<!--              v-decorator="[-->
<!--                        'opening/seasonalityEndDate',-->
<!--                        {-->
<!--                            rules: [{-->
<!--                                required: form.getFieldValue('opening/seasonality') === 1,-->
<!--                                message: 'Kérjük töltse ki!',-->
<!--                            }],-->
<!--                        }-->
<!--                    ]"-->
<!--              style="width: 85%"-->
<!--              :format="dateFields['opening/seasonalityEndDate']"-->
<!--          >-->
<!--          </a-date-picker>-->
<!--        </a-form-item>-->

        <a-form-item
            :selfUpdate="true"
            label="Ha 12 hónapon keresztül havonta eltér a nyitva tartás, akkor kérjük, itt jelezze."
        >
          <a-textarea
              :disabled="isMobileReadonly"
              :maxLength="25000"
              v-decorator="[
                        'opening/seasonalityComment',
                        {
                            rules: [{
                                required: false,
                                message: 'Kérjük töltse ki!'
                            }]
                        }
                    ]"
              :autoSize="{minRows: 2}"
          />
        </a-form-item>

        <div>
            <a-divider orientation="left">
                FREKVENTÁLT SZEZON NYITVA TARTÁSÁRA VONATKOZÓ ADATOK
            </a-divider>

            <p>
                Az adott vonzerő nyitva tartásának adatai a frekventált szezonban.
            </p>
        </div>

        <a-form-item
                label="Frekventált szezon kezdete"
        >
            <a-date-picker
                    :disabled="isMobileReadonly"
                    :disabled-date="openingRangeStartDisabled"
                    v-decorator="[
                    `frequentedOpening/seasonalityStartDate`,
                    {
                        rules: [{
                            required: false,
                            message: 'Kérjük töltse ki!',
                        }],
                    }
                ]"
                    style="width: 85%"
                    :format="dateFields['frequentedOpening/seasonalityStartDate']"
            >
            </a-date-picker>
        </a-form-item>

        <a-form-item
                label="Frekventált szezon vége"
        >
            <a-date-picker
                    :disabled="isMobileReadonly"
                    :disabled-date="openingRangeEndDisabled"
                    v-decorator="[
                    'frequentedOpening/seasonalityEndDate',
                    {
                        rules: [{
                            required: false,
                            message: 'Kérjük töltse ki!',
                        }],
                    }
                ]"
                    style="width: 85%"
                    :format="dateFields['frequentedOpening/seasonalityEndDate']"
            >
            </a-date-picker>
        </a-form-item>

        <a-form-item>
            <a-checkbox
                    :disabled="isMobileReadonly"
                    v-decorator="['frequentedOpening/alwaysOpen']"
                    :checked="isChecked('frequentedOpening/alwaysOpen')"
            >
                24/7 nyitva tart
            </a-checkbox>
        </a-form-item>

        <template v-for="day in this.frequentedDays">
            <h4 v-if="day.weekdayStart">
                Hétköznap
            </h4>

            <h4 v-if="day.weekendStart">
                Hétvégén
            </h4>

            <a-form-item
                    :label="day.name"
                    :key="day.fieldPrefix"
            >
                <a-row>
                    <a-col :md="7">
                        <a-form-item>
                            <a-time-picker
                                    :getPopupContainer="getParentTriggerNode()"
                                    :disabled="isMobileReadonly || isChecked(day.fieldPrefix + '/closed') || isChecked('frequentedOpening/alwaysOpen')"
                                    v-decorator="[
                                  day.fieldPrefix + '/startTime',
                                  {
                                  rules: [
                                      {
                                        required: false,
                                        message: 'Kérjük válassza ki a listából a megfelelő értéket VAGY ZÁRVA',
                                      }
                                    ]
                                  }
                                  ]"
                                    style="width: 85%"
                                    :minute-step="15"
                                    :format="dateFields[day.fieldPrefix + '/startTime']"
                                    v-on:change="(time, timeString) => timePickerChange(day.fieldPrefix + '/startTime', time, timeString)"
                            >
                            </a-time-picker>

                            <span>-tól</span>
                        </a-form-item>
                    </a-col>

                    <a-col :md="7">
                        <a-form-item>
                            <a-time-picker
                                    :getPopupContainer="getParentTriggerNode()"
                                    :disabled="isMobileReadonly || isChecked(day.fieldPrefix + '/closed') || isChecked('frequentedOpening/alwaysOpen')"
                                    v-decorator="[
                      day.fieldPrefix + '/endTime',
                        {
                          rules: [
                            {
                              required: false,
                              message: 'Kérjük válassza ki a listából a megfelelő értéket VAGY ZÁRVA',
                            }
                          ]
                        }
                      ]"
                                    style="width: 85%"
                                    :minute-step="15"
                                    :format="dateFields[day.fieldPrefix + '/endTime']"
                                    v-on:change="(time, timeString) => timePickerChange(day.fieldPrefix + '/endTime', time, timeString)"
                            >
                            </a-time-picker>

                            <span>-ig</span>
                        </a-form-item>
                    </a-col>

                    <a-col :md="5">
                        <a-form-item>
                            <a-checkbox
                                    :disabled="isMobileReadonly || isChecked(day.fieldPrefix + '/closed') || isChecked('frequentedOpening/alwaysOpen')"
                                    v-decorator="[day.fieldPrefix + '/lunchBreak']"
                                    :checked="isChecked(day.fieldPrefix + '/lunchBreak')"
                            >
                                Ebédszünet
                            </a-checkbox>
                        </a-form-item>
                    </a-col>

                    <a-col :md="5">
                        <a-form-item>
                            <a-checkbox
                                    :disabled="isMobileReadonly || isChecked('frequentedOpening/alwaysOpen')"
                                    v-decorator="[day.fieldPrefix + '/closed']"
                                    :checked="isChecked(day.fieldPrefix + '/closed')"
                            >
                                Zárva
                            </a-checkbox>
                        </a-form-item>
                    </a-col>
                </a-row>
            </a-form-item>
        </template>

      <div>
          <a-divider orientation="left">
              SZEZONON KÍVÜLI NYITVA TARTÁSRA VONATKOZÓ ADATOK
          </a-divider>

          <p>
              Az adott vonzerő szezonon kívüli nyitva tartásának adatai.
          </p>
      </div>

        <a-form-item>
            <a-checkbox
                    v-decorator="['opening/alwaysOpen']"
                    :checked="isChecked('opening/alwaysOpen')"
                    :disabled="isMobileReadonly || isChecked('opening/alwaysClosed')"
            >
                24/7 nyitva tart
            </a-checkbox>

            <a-checkbox
                    v-decorator="['opening/alwaysClosed']"
                    :checked="isChecked('opening/alwaysClosed')"
                    :disabled="isMobileReadonly || isChecked('opening/alwaysOpen')"
            >
                Zárva tart
            </a-checkbox>
        </a-form-item>

        <template v-for="day in this.days">
            <h4 v-if="day.weekdayStart">
                Hétköznap
            </h4>

            <h4 v-if="day.weekendStart">
                Hétvégén
            </h4>

            <a-form-item
                    :label="day.name"
                    :key="day.fieldPrefix"
            >
                <a-row>
                    <a-col :md="7">
                        <a-form-item>
                            <a-time-picker
                                    :getPopupContainer="getParentTriggerNode()"
                                    :disabled="isMobileReadonly || isChecked(day.fieldPrefix + '/closed') || isChecked('opening/alwaysOpen') || isChecked('opening/alwaysClosed')"
                                    v-decorator="[
                                  day.fieldPrefix + '/startTime',
                                  {
                                  rules: [
                                      {
                                        required: false,
                                        message: 'Kérjük válassza ki a listából a megfelelő értéket VAGY ZÁRVA',
                                      }
                                    ]
                                  }
                                  ]"
                                    style="width: 85%"
                                    :minute-step="15"
                                    :format="dateFields[day.fieldPrefix + '/startTime']"
                                    v-on:change="(time, timeString) => timePickerChange(day.fieldPrefix + '/startTime', time, timeString)"
                            >
                            </a-time-picker>

                            <span>-tól</span>
                        </a-form-item>
                    </a-col>

                    <a-col :md="7">
                        <a-form-item>
                            <a-time-picker
                                    :getPopupContainer="getParentTriggerNode()"
                                    :disabled="isMobileReadonly || isChecked(day.fieldPrefix + '/closed') || isChecked('opening/alwaysOpen') || isChecked('opening/alwaysClosed')"
                                    v-decorator="[
                      day.fieldPrefix + '/endTime',
                        {
                          rules: [
                            {
                              required: false,
                              message: 'Kérjük válassza ki a listából a megfelelő értéket VAGY ZÁRVA',
                            }
                          ]
                        }
                      ]"
                                    style="width: 85%"
                                    :minute-step="15"
                                    :format="dateFields[day.fieldPrefix + '/endTime']"
                                    v-on:change="(time, timeString) => timePickerChange(day.fieldPrefix + '/endTime', time, timeString)"
                            >
                            </a-time-picker>

                            <span>-ig</span>
                        </a-form-item>
                    </a-col>

                    <a-col :md="5">
                        <a-form-item>
                            <a-checkbox
                                    :disabled="isMobileReadonly || isChecked(day.fieldPrefix + '/closed') || isChecked('opening/alwaysOpen') || isChecked('opening/alwaysClosed')"
                                    v-decorator="[day.fieldPrefix + '/lunchBreak']"
                                    :checked="isChecked(day.fieldPrefix + '/lunchBreak')"
                            >
                                Ebédszünet
                            </a-checkbox>
                        </a-form-item>
                    </a-col>

                    <a-col :md="5">
                        <a-form-item>
                            <a-checkbox
                                    :disabled="isMobileReadonly || isChecked('opening/alwaysOpen') || isChecked('opening/alwaysClosed')"
                                    v-decorator="[day.fieldPrefix + '/closed']"
                                    :checked="isChecked(day.fieldPrefix + '/closed') || isChecked('opening/alwaysClosed')"
                            >
                                Zárva
                            </a-checkbox>
                        </a-form-item>
                    </a-col>
                </a-row>
            </a-form-item>
        </template>

        <a-divider />

        <a-form-item
            :selfUpdate="true"
            label="Speciális nyitva tartás (téli/nyári, éjszakai stb.; egyéb a fenti nyitvatartási kategóriákba nem besorolható esetek)"
        >
          <a-textarea
              :maxLength="25000"
              :disabled="isMobileReadonly"
              v-decorator="[
                        'opening/specialComment',
                        {
                            rules: [{
                                required: false,
                                message: 'Kérjük töltse ki!'
                            }]
                        }
                    ]"
              :autoSize="{minRows: 2}"
          />
        </a-form-item>

        <a-divider />

        <a-form-item :selfUpdate="true" v-if="isNotMobile">
          <span slot="label">
              Megjegyzés

              <a-tooltip title="Kérjük ezt a cellát akkor töltse ki, ha a témához kapcsolódóan egyéb megjegyzése van.">
                  <a-icon type="question-circle-o" />
              </a-tooltip>
          </span>

          <a-textarea
              :maxLength="25000"
              :disabled="isMobileReadonly"
              v-decorator="[
                        'opening/comment',
                        {
                            rules: [{
                                required: false,
                                message: 'Kérjük töltse ki!'
                            }]
                        }
                    ]"
              :autoSize="{minRows: 2}"
          />
        </a-form-item>

        <a-divider />

        <a-divider orientation="left">
          VONZERŐNÉL ELTÖLTÖTT IDŐ
        </a-divider>

        <a-form-item label="Átlagosan mennyi időt töltenek el a látogatók a vonzerőnél?">
          <a-time-picker
              :getPopupContainer="getParentTriggerNode()"
              :disabled="isMobileReadonly"
              v-decorator="[
                  'opening/spentTime',
                    {
                      initialValue: moment('1970-01-01 00:00:00'),
                      rules: [
                        {
                          required: isNotMobile,
                          message: 'Kérjük válasszon!',
                        },
                        {
                          validator: spentTimeValidator,
                          message: 'Kérjük válasszon!'
                        }
                      ]
                    }
                  ]"
              :allowClear="false"
              :minute-step="15"
              :disabledHours="spentHoursDisabled"
              :hideDisabledOptions="true"
              style="width: 150px"
              format="H [óra] mm [perc]"
              v-on:change="(time, timeString) => timePickerChange('opening/spentTime', time, timeString)"
          >
          </a-time-picker>
        </a-form-item>

        <a-form-item>
          <a-button type="primary" html-type="submit" :loading="submitLoading" v-if="showSaveButton && !isMobileReadonly">
            Mentés
          </a-button>

          <a-button type="secondary" html-type="button" v-on:click="resetForm" v-if="hasChanged()">
            <a-icon
                type="undo"
                theme="outlined"
            />

            Piszkozat törlése
          </a-button>

          <span v-if="currentTab" class="last-modification">
            Utolsó mentés: <strong>{{ moment(currentTab.modificationTime).format(timeStampFormat).toString() }}</strong> &nbsp;<span v-if="currentTab.user">({{ currentTab.user.userName }})</span>
          </span>

          <a-alert
              :style="{
                        marginTop: '10px'
                    }"
              v-if="submitResult.show"
              :message="submitResult.message"
              :type="submitResult.type"
              showIcon
              closable
              :afterClose="() => submitResult.show = false"
          />
        </a-form-item>
      </a-form>
    </a-spin>
  </div>
</template>

<script>
import { EditFormComponentMixin } from "@/mixins/EditFormComponentMixin";

export default {
  menuName: 'Nyitva tartás',
  name: 'opening-form',
  mixins: [EditFormComponentMixin],

  data() {
    return {
      formId: 'OPENING',

      formFields: {
        'opening/status': 'open',
        'opening/statusComment': '',
        'opening/reopenDate': null,
        'opening/seasonality': 0,
        'opening/frequented': 0,
        'opening/seasonalityComment': '',
        'opening/monday/day': 'monday',
        'opening/monday/startTime': null,
        'opening/monday/endTime': null,
        'opening/monday/closed': 0,
        'opening/monday/lunchBreak': 0,
        'opening/tuesday/day': 'tuesday',
        'opening/tuesday/startTime': null,
        'opening/tuesday/endTime': null,
        'opening/tuesday/closed': 0,
        'opening/tuesday/lunchBreak': 0,
        'opening/wednesday/day': 'wednesday',
        'opening/wednesday/startTime': null,
        'opening/wednesday/endTime': null,
        'opening/wednesday/closed': 0,
        'opening/wednesday/lunchBreak': 0,
        'opening/thursday/day': 'thursday',
        'opening/thursday/startTime': null,
        'opening/thursday/endTime': null,
        'opening/thursday/closed': 0,
        'opening/thursday/lunchBreak': 0,
        'opening/friday/day': 'friday',
        'opening/friday/startTime': null,
        'opening/friday/endTime': null,
        'opening/friday/closed': 0,
        'opening/friday/lunchBreak': 0,
        'opening/saturday/day': 'saturday',
        'opening/saturday/startTime': null,
        'opening/saturday/endTime': null,
        'opening/saturday/closed': 0,
        'opening/saturday/lunchBreak': 0,
        'opening/sunday/day': 'sunday',
        'opening/sunday/startTime': null,
        'opening/sunday/endTime': null,
        'opening/sunday/closed': 0,
        'opening/sunday/lunchBreak': 0,
        'frequentedOpening/seasonality': 1,
        'frequentedOpening/frequented': 1,
        'frequentedOpening/seasonalityStartDate': null,
        'frequentedOpening/seasonalityEndDate': null,
        'frequentedOpening/monday/day': 'monday',
        'frequentedOpening/monday/startTime': null,
        'frequentedOpening/monday/endTime': null,
        'frequentedOpening/monday/closed': 0,
        'frequentedOpening/monday/lunchBreak': 0,
        'frequentedOpening/tuesday/day': 'tuesday',
        'frequentedOpening/tuesday/startTime': null,
        'frequentedOpening/tuesday/endTime': null,
        'frequentedOpening/tuesday/closed': 0,
        'frequentedOpening/tuesday/lunchBreak': 0,
        'frequentedOpening/wednesday/day': 'wednesday',
        'frequentedOpening/wednesday/startTime': null,
        'frequentedOpening/wednesday/endTime': null,
        'frequentedOpening/wednesday/closed': 0,
        'frequentedOpening/wednesday/lunchBreak': 0,
        'frequentedOpening/thursday/day': 'thursday',
        'frequentedOpening/thursday/startTime': null,
        'frequentedOpening/thursday/endTime': null,
        'frequentedOpening/thursday/closed': 0,
        'frequentedOpening/thursday/lunchBreak': 0,
        'frequentedOpening/friday/day': 'friday',
        'frequentedOpening/friday/startTime': null,
        'frequentedOpening/friday/endTime': null,
        'frequentedOpening/friday/closed': 0,
        'frequentedOpening/friday/lunchBreak': 0,
        'frequentedOpening/saturday/day': 'saturday',
        'frequentedOpening/saturday/startTime': null,
        'frequentedOpening/saturday/endTime': null,
        'frequentedOpening/saturday/closed': 0,
        'frequentedOpening/saturday/lunchBreak': 0,
        'frequentedOpening/sunday/day': 'sunday',
        'frequentedOpening/sunday/startTime': null,
        'frequentedOpening/sunday/endTime': null,
        'frequentedOpening/sunday/closed': 0,
        'frequentedOpening/sunday/lunchBreak': 0,
        'frequentedOpening/alwaysOpen': 0,
        'opening/alwaysOpen': 0,
        'opening/alwaysClosed': 0,
        'opening/specialComment': '',
        'opening/comment': '',
        'opening/spentTime': '00:00',
        'payment/appointments': 0,
      },

      casts: {
        'opening/alwaysOpen': 'int',
        'opening/alwaysClosed': 'int',
        'opening/monday/closed': 'int',
        'opening/tuesday/closed': 'int',
        'opening/wednesday/closed': 'int',
        'opening/thursday/closed': 'int',
        'opening/friday/closed': 'int',
        'opening/saturday/closed': 'int',
        'opening/sunday/closed': 'int',
        'payment/appointments': 'int',
        'opening/monday/lunchBreak': 'int',
        'opening/tuesday/lunchBreak': 'int',
        'opening/wednesday/lunchBreak': 'int',
        'opening/thursday/lunchBreak': 'int',
        'opening/friday/lunchBreak': 'int',
        'opening/saturday/lunchBreak': 'int',
        'opening/sunday/lunchBreak': 'int',
        'frequentedOpening/alwaysOpen': 'int',
        'frequentedOpening/seasonality': 'int',
        'frequentedOpening/monday/closed': 'int',
        'frequentedOpening/tuesday/closed': 'int',
        'frequentedOpening/wednesday/closed': 'int',
        'frequentedOpening/thursday/closed': 'int',
        'frequentedOpening/friday/closed': 'int',
        'frequentedOpening/saturday/closed': 'int',
        'frequentedOpening/sunday/closed': 'int',
        'frequentedOpening/monday/lunchBreak': 'int',
        'frequentedOpening/tuesday/lunchBreak': 'int',
        'frequentedOpening/wednesday/lunchBreak': 'int',
        'frequentedOpening/thursday/lunchBreak': 'int',
        'frequentedOpening/friday/lunchBreak': 'int',
        'frequentedOpening/saturday/lunchBreak': 'int',
        'frequentedOpening/sunday/lunchBreak': 'int'
      },

      dateFields: {
        'opening/reopenDate': 'YYYY.MM.DD',
        'opening/monday/startTime': 'HH:mm',
        'opening/monday/endTime': 'HH:mm',
        'opening/tuesday/startTime': 'HH:mm',
        'opening/tuesday/endTime': 'HH:mm',
        'opening/wednesday/startTime': 'HH:mm',
        'opening/wednesday/endTime': 'HH:mm',
        'opening/thursday/startTime': 'HH:mm',
        'opening/thursday/endTime': 'HH:mm',
        'opening/friday/startTime': 'HH:mm',
        'opening/friday/endTime': 'HH:mm',
        'opening/saturday/startTime': 'HH:mm',
        'opening/saturday/endTime': 'HH:mm',
        'opening/sunday/startTime': 'HH:mm',
        'opening/sunday/endTime': 'HH:mm',
        'frequentedOpening/monday/startTime': 'HH:mm',
        'frequentedOpening/monday/endTime': 'HH:mm',
        'frequentedOpening/tuesday/startTime': 'HH:mm',
        'frequentedOpening/tuesday/endTime': 'HH:mm',
        'frequentedOpening/wednesday/startTime': 'HH:mm',
        'frequentedOpening/wednesday/endTime': 'HH:mm',
        'frequentedOpening/thursday/startTime': 'HH:mm',
        'frequentedOpening/thursday/endTime': 'HH:mm',
        'frequentedOpening/friday/startTime': 'HH:mm',
        'frequentedOpening/friday/endTime': 'HH:mm',
        'frequentedOpening/saturday/startTime': 'HH:mm',
        'frequentedOpening/saturday/endTime': 'HH:mm',
        'frequentedOpening/sunday/startTime': 'HH:mm',
        'frequentedOpening/sunday/endTime': 'HH:mm',
        'frequentedOpening/seasonalityStartDate': 'YYYY.MM.DD',
        'frequentedOpening/seasonalityEndDate': 'YYYY.MM.DD',
        'opening/spentTime': 'HH:mm',
      },

      days: [
        {
          name: 'Hétfő',
          fieldPrefix: 'opening/monday',
          weekdayStart: true
        },
        {
          name: 'Kedd',
          fieldPrefix: 'opening/tuesday'
        },
        {
          name: 'Szerda',
          fieldPrefix: 'opening/wednesday'
        },
        {
          name: 'Csütörtök',
          fieldPrefix: 'opening/thursday'
        },
        {
          name: 'Péntek',
          fieldPrefix: 'opening/friday'
        },
        {
          name: 'Szombat',
          fieldPrefix: 'opening/saturday',
          weekendStart: true
        },
        {
          name: 'Vasárnap',
          fieldPrefix: 'opening/sunday'
        }
      ],

      frequentedDays: [
        {
          name: 'Hétfő',
          fieldPrefix: 'frequentedOpening/monday',
          weekdayStart: true
        },
        {
          name: 'Kedd',
          fieldPrefix: 'frequentedOpening/tuesday'
        },
        {
          name: 'Szerda',
          fieldPrefix: 'frequentedOpening/wednesday'
        },
        {
          name: 'Csütörtök',
          fieldPrefix: 'frequentedOpening/thursday'
        },
        {
          name: 'Péntek',
          fieldPrefix: 'frequentedOpening/friday'
        },
        {
          name: 'Szombat',
          fieldPrefix: 'frequentedOpening/saturday',
          weekendStart: true
        },
        {
          name: 'Vasárnap',
          fieldPrefix: 'frequentedOpening/sunday'
        }
      ]
    }
  },

  methods: {
    /**
     * @param currentDate
     * @return {boolean}
     */
    openingRangeEndDisabled(currentDate) {
      const startDate = this.form.getFieldValue('frequentedOpening/seasonalityStartDate');

      return startDate ? currentDate < startDate : false;
    },

    /**
     * @param currentDate
     * @return {boolean}
     */
    openingRangeStartDisabled(currentDate) {
      const endDate = this.form.getFieldValue('frequentedOpening/seasonalityEndDate');

      return endDate ? currentDate > endDate : false;
    },

    /**
     * @return {Array}
     */
    spentHoursDisabled() {
      return Array.from(Array(24).keys())
          .filter((hour) => {
                return hour > 12;
              }
          );
    },

    /**
     * Do not allow 0:00
     *
     * @param fieldOptions
     * @param {Moment} moment
     */
    spentTimeValidator(fieldOptions, moment) {
      if(!this.isNotMobile) {
        return true;
      }

      return moment.hours() !== 0 || moment.minutes() !== 0;
    }
  }
}
</script>

<style>
</style>
