var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        { staticClass: "forgotten-password-content-row" },
        [
          _c("a-col", { attrs: { xs: 1, sm: 4, md: 7 } }),
          _c("a-col", { attrs: { xs: 22, sm: 16, md: 10 } }, [
            _c(
              "div",
              { staticClass: "forgotten-password-card-container" },
              [
                _c(
                  "a-form",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.success,
                        expression: "!success"
                      }
                    ],
                    staticClass: "forgotten-password-form",
                    attrs: {
                      id: "forgotten-password-component-form",
                      form: _vm.form
                    },
                    on: { submit: _vm.submit }
                  },
                  [
                    _c("h1", [
                      _vm._v(
                        "\n            Jelszó változtatás igénylése\n          "
                      )
                    ]),
                    _c(
                      "a-form-item",
                      [
                        _c(
                          "a-input",
                          {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "email",
                                  {
                                    rules: [
                                      {
                                        type: "email",
                                        required: true,
                                        message:
                                          "Kérjük írja be az e-mail címét!"
                                      }
                                    ]
                                  }
                                ],
                                expression:
                                  "[\n                  'email',\n                  {\n                      rules: [{\n                          type: 'email',\n                          required: true,\n                          message: 'Kérjük írja be az e-mail címét!'\n                      }]\n                  }\n              ]"
                              }
                            ],
                            attrs: { type: "email", placeholder: "E-mail cím" }
                          },
                          [
                            _c("a-icon", {
                              staticStyle: { color: "rgba(0, 0, 0, 0.5)" },
                              attrs: { slot: "prefix", type: "mail" },
                              slot: "prefix"
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "a-form-item",
                      [
                        _c(
                          "a-button",
                          {
                            staticClass: "forgotten-password-form-button",
                            attrs: {
                              loading: _vm.loading,
                              type: "primary",
                              "html-type": "submit"
                            }
                          },
                          [
                            _vm._v(
                              "\n              Jelszó változtatás kérése\n            "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.success,
                        expression: "success"
                      }
                    ],
                    staticClass: "success-page"
                  },
                  [
                    _c("h1", [
                      _vm._v(
                        "\n            A jelszó változtatási kérelmet megkaptuk!\n          "
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        "\n            Kérjük ellenőrizze az e-mail fiókját!"
                      ),
                      _c("br"),
                      _c("br"),
                      _c("span", { staticClass: "info-span" }, [
                        _vm._v(
                          "\n              Információ és technikai segítség:"
                        ),
                        _c("br"),
                        _c("a", { attrs: { href: "mailto:neta@mtu.gov.hu" } }, [
                          _vm._v("neta@mtu.gov.hu")
                        ])
                      ])
                    ])
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "back-button-holder" },
                  [
                    _c(
                      "router-link",
                      { staticClass: "back-button", attrs: { to: "/login" } },
                      [
                        _c("a", [
                          _vm._v(
                            "\n              Vissza a bejelentkezéshez\n            "
                          )
                        ])
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("a-col", { attrs: { xs: 1, sm: 4, md: 7 } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }