import {
    SET_CURRENT_ATTRACTION,
    SET_CURRENT_ATTRACTION_TRANSLATIONS,
    SET_LANGUAGES,
    SET_PRICES_VISIBLE
} from "@/mutation_types";
import { REFRESH_ATTRACTION, USER_LOGIN } from "@/action_types";
import axios from "axios";
import store from "@/store";

const state = {
    current: null,
    currentTranslations: {},
    languages: [],
    locale: 'hu-HU',
    statusNames: {
        '0': 'Új',
        '1': 'Jóváhagyásra vár',
        '2': 'Jóváhagyva',
        '3': 'Publikálásra vár',
        '4': 'Publikálva',
        '5': 'Publikálás visszavonására vár',
        '7': 'Publikálás alatt',
    },
    sidebarCollapsed: false
};

const getters = {
    /**
     * @param state
     * @return {object}
     */
    getCurrentAttraction(state) {
        return state.current;
    },

    /**
     * @param state
     * @returns {*[]}
     */
    getLanguages(state) {
        let languages = [];

        state.languages.forEach((language) => {
            language.id = parseInt(language.id);
            language.companyId = parseInt(language.companyId);

            languages.push(language);
        });

        return languages;
    },

    /**
     * @param state
     * @returns {*[]}
     */
    getForeignLanguages(state) {
        let foreignLanguages = [];

        state.languages.forEach((language) => {
            if (language.locale !== state.locale) {
                language.id = parseInt(language.id);
                language.companyId = parseInt(language.companyId);

                foreignLanguages.push(language);
            }
        });

        return foreignLanguages;
    }
};


const mutations = {
    [SET_CURRENT_ATTRACTION](state, payload) {
        state.current = payload.attraction;
    },

    [SET_CURRENT_ATTRACTION_TRANSLATIONS](state, payload) {
        state.currentTranslations = payload.translations;
    },

    [SET_LANGUAGES](state, payload) {
        state.languages = payload.languages;
    }
};

const actions = {
    [REFRESH_ATTRACTION]({ commit }, payload) {
        return axios.get(`/api/v1/companies/1/me/attractions/${payload.attractionId}`, {
            params: {
                locale: store.state.attraction.locale,
                envelope: true,
                translations: ['name', 'shortDescription', 'mediumDescription', 'mobileDescription', 'longDescription'].join(','),
                languages: true,
                filter: {
                    'group/groupType': payload.groupType ? payload.groupType : 'attraction'
                }
            }
        }).then(response => {
            let pricesVisible = false;

            if (response.data.content.payment && response.data.content.payment.ticketSales) {
                pricesVisible = !response.data.content.payment.ticketSales.free;
            }

            commit({
                type: SET_PRICES_VISIBLE,
                pricesVisible: pricesVisible
            });

            commit({
                type: SET_CURRENT_ATTRACTION,
                attraction: response.data.content
            });

            commit({
                type: SET_CURRENT_ATTRACTION_TRANSLATIONS,
                translations: response.data.meta.translations
            });

            commit({
                type: SET_LANGUAGES,
                languages: response.data.meta.languages
            });
        });
    },
};

export default {
    state,
    getters,
    mutations,
    actions
}