<template xmlns="">
  <div class="app-form-container">
    <a-spin :spinning="formDataLoading">
      <a-form :form="form" v-on:submit="submit" layout="vertical">
        <a-form-item>
          <a-button type="primary" html-type="submit" :loading="submitLoading" v-if="showSaveButton">
            Mentés
          </a-button>

          <a-button type="secondary" html-type="button" v-on:click="resetForm" v-if="hasChanged()">
            <a-icon type="undo"
                    theme="outlined" />

            Piszkozat törlése
          </a-button>

          <span v-if="currentTab" class="last-modification">
            Utolsó mentés: <strong>{{ moment(currentTab.modificationTime).format(timeStampFormat).toString() }}</strong> &nbsp;<span v-if="currentTab.user">({{ currentTab.user.userName }})</span>
          </span>

          <a-alert
              :style="{
                        marginTop: '10px'
                    }"
              v-if="submitResult.show"
              :message="submitResult.message"
              :type="submitResult.type"
              showIcon
              closable
              :afterClose="() => submitResult.show = false"
          />
        </a-form-item>

        <a-divider orientation="left">
            ESEMÉNY KÖZZÉTÉTELE
        </a-divider>

        <a-form-item v-if="isNotMobile" label="Megjelenítés weben">
            <a-select
                    :disabled="isMobileReadonly"
                    v-decorator="[
                      'webPublished',
                      {
                          rules: [{
                              required: false,
                              message: 'Kérjük töltse ki!'
                          }]
                      }
                  ]"
                    placeholder="Kérjük válasszon!"
            >
                <a-select-option :value="1">Igen</a-select-option>
                <a-select-option :value="0">Nem</a-select-option>
            </a-select>
        </a-form-item>

        <a-form-item label="Megjelenítés mobilon">
            <a-select
                    v-decorator="[
                      'mobilePublished',
                      {
                          rules: [{
                              required: false,
                              message: 'Kérjük töltse ki!'
                          }]
                      }
                  ]"
                    placeholder="Kérjük válasszon!"
            >
                <a-select-option :value="1">Igen</a-select-option>
                <a-select-option :value="0">Nem</a-select-option>
            </a-select>
        </a-form-item>

        <a-divider orientation="left">
          ESEMÉNY NEVE
        </a-divider>

        <a-form-item label="Esemény neve" :selfUpdate="true">
          <a-textarea
              :maxLength="25000"
              :disabled="isMobileReadonly"
              v-decorator="[
                            'name',
                            {
                                rules: [
                                    {
                                      required: true,
                                      preserve: true,
                                      message: 'Kérjük töltse ki!'
                                    },
                                    {
                                      max: characterCounts.name.max,
                                      message: 'Maximum ' + characterCounts.name.max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur', 'keyup']
                            }
                        ]"
              :autoSize="{minRows: 2}"
              placeholder="Esemény neve"
          />
        </a-form-item>

        <div v-for="(value, locale) in translatedFormFields['name']" :key="'name-' + locale">
          <a-row v-if="value !== null">
            <div>
              <a-form-item :selfUpdate="true">
                <div slot="label">
                  Nyelv
                </div>

                <a-select
                    style="width: 90%"
                    :getPopupContainer="getParentTriggerNode()"
                    v-on:change="updateTranslatedFormField('name')"
                    :ref="getTranslatedLocaleName('name', locale)"
                    :disabled="isTranslationEditDisabled"
                    :filterOption="filterSelectNames"
                    v-decorator="[
                                getTranslatedLocaleName('name', locale),
                                {
                                    initialValue: locale,
                                    rules: [{
                                        required: false,
                                    }]
                                }
                            ]"
                >
                  <a-select-option
                      v-for="language in languages"
                      :key="language.name"
                      :value="language.locale"
                      :disabled="!isLanguageAvailable('name', language.locale)"
                  >
                    {{ language.name.toLowerCase() }}
                  </a-select-option>
                </a-select>

                <a-tooltip title="Mező törlése" v-if="isAdmin">
                  <a-icon
                      class="dynamic-delete-button"
                      type="delete"
                      theme="filled"
                      v-on:click="() => deleteTranslatedFormField('name', locale)"
                  />
                </a-tooltip>
              </a-form-item>
            </div>

            <div>
              <a-form-item :selfUpdate="true">
                <span slot="label">
                  Esemény neve {{ getLanguageName(locale) }} nyelven
                </span>

                <a-textarea
                    :maxLength="25000"
                    :disabled="false"
                    v-on:blur="updateTranslatedFormField('name')"
                    :ref="getTranslatedFieldName('name', locale)"
                    :readOnly="isTranslationEditDisabled"
                    v-decorator="[
                                    getTranslatedFieldName('name', locale),
                                    {
                                      initialValue: value,
                                      rules: [
                                          {
                                            required: false,
                                            preserve: true,
                                            message: 'Kérjük töltse ki!'
                                          },
                                          {
                                            max: characterCounts[getTranslatedFieldName('name', locale)].max,
                                            message: 'Maximum ' + characterCounts[getTranslatedFieldName('name', locale)].max + ' karakter engedélyezett.',
                                          }
                                      ],
                                      validateTrigger: ['blur', 'keyup']
                                  }
                                ]"
                    :autoSize="{minRows: 2}"
                />
              </a-form-item>
            </div>
          </a-row>
        </div>

        <a-form-item>
          <a-button
              type="dashed"
              style="width: 100%"
              :disabled="isOfficeEmployee || !getFirstAvailableLanguage('name') || isMobileReadonly"
              v-on:click="addTranslatedFormField('name')"
          >
            <a-icon type="plus" />

            Idegennyelvű név hozzáadása
          </a-button>
        </a-form-item>

        <h4>Kapcsolódó vonzerő</h4>

        <a-form-item label="Az esemény helyszíne szerepel a NETA vonzerők között?">
          <a-select
              v-decorator="[
                        'hasRelatedAttractions',
                        {
                            rules: [{
                                required: true,
                                message: 'Kérjük töltse ki!'
                            }],
                            validateTrigger: 'blur'
                        }
                    ]"
              v-on:change="(value) => {toggleRelatedAttraction(value)}"
              placeholder="Kérjük válasszon!"
              :disabled="isMobileReadonly"
          >
            <a-select-option :value="0">
              Nem
            </a-select-option>

            <a-select-option :value="1">
              Igen
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
            v-show="form.getFieldValue('hasRelatedAttractions') === 1"
            label="NETA vonzerők"
        >
          <a-select
              v-decorator="[
                            'relatedAttractions/id',
                            {
                                rules: [
                                    {
                                    required: form.getFieldValue('hasRelatedAttractions') === 1 && isNotMobile,
                                    message: 'Kérjük töltse ki!'
                                  },
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
              placeholder="Kezdjen gépelni"
              showSearch
              :disabled="isMobileReadonly"
              mode="multiple"
              :showArrow="false"
              :defaultActiveFirstOption="false"
              :getPopupContainer="getParentTriggerNode()"
              :filterOption="false"
              :style="{width: '100%'}"
              v-on:search="(value) => throttledAttractionAutoComplete(value, 'relatedAttractions/id')"
              v-on:change="(value) => {fillAttractionDetails(value, 'relatedAttractions/id')}"
              v-on:dropdownVisibleChange="(open) => open && attractionAutoComplete(null, 'relatedAttractions/id')"
              :notFoundContent="loading['relatedAttractions/id'] ? undefined : null"
          >
            <a-spin v-if="loading['relatedAttractions/id']" slot="notFoundContent" size="small" />

            <a-select-option
                v-for="option in options['relatedAttractions/id']"
                :key="option.name"
                :value="option.id"
            >
              {{ option.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="Eseményhelyszín" :selfUpdate="true">
            <a-textarea
                    :maxLength="25000"
                    :disabled="isMobileReadonly"
                    v-decorator="[
                          'placeName',
                          {
                              rules: [
                                  {
                                    required: false,
                                    preserve: true,
                                    message: 'Kérjük töltse ki!'
                                  },
                                  {
                                    max: characterCounts.placeName.max,
                                    message: 'Maximum ' + characterCounts.placeName.max + ' karakter engedélyezett.',
                                  }
                              ],
                              validateTrigger: ['blur', 'keyup']
                          }
                      ]"
                    :autoSize="{minRows: 2}"
                    placeholder="Az esemény helyszínének neve"
            />
        </a-form-item>

        <a-divider />

        <a-form-item label="Kategória">
          <a-radio-group
              :disabled="isMobileReadonly"
              v-on:change="loadOptions('category/id', 'group/id')"
              v-decorator="['group/id', {
                rules:
                [
                  {
                    required: true,
                    preserve: true,
                    message: 'Kérjük válasszon!',
                    type: 'integer'
                  }
                ],
                    validateTrigger: ['blur', 'change']
                }
              ]"
          >
            <a-radio-button
                v-for="category in options['group/id']"
                :key="category.id"
                :value="category.id"
            >
              {{ category.label }}

              <a-icon :type="category.icon" />
            </a-radio-button>
          </a-radio-group>
        </a-form-item>

        <a-form-item label="Alkategória">
          <a-select
              :getPopupContainer="getParentTriggerNode()"
              v-on:change="loadOptions('labels/id', 'category/id')"
              :loading="loading['category/id']"
              v-decorator="['category/id', {
                            rules: [{
                                required: true,
                                message: 'Kérjük töltse ki!'
                            }],
                            validateTrigger: ['blur', 'change']
                        }]"
              placeholder="Kérjük válasszon!"
              :disabled="!form.getFieldValue('group/id') || isMobileReadonly"
              :filterOption="filterSelectNames"
          >
            <a-select-option
                v-for="category in options['category/id']"
                :key="category.name"
                :value="category.id"
            >
              {{ category.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="Címke">
          <a-select
              :getPopupContainer="getParentTriggerNode()"
              :loading="loading['labels/id']"
              mode="multiple"
              v-decorator="['labels/id', {
                            rules: [{
                                required: true,
                                message: 'Kérjük töltse ki!'
                            }],
                            validateTrigger: ['blur', 'change']
                        }]"
              placeholder="Kérjük válasszon!"
              :disabled="!form.getFieldValue('category/id') || isMobileReadonly"
              :filterOption="filterSelectNames"
              v-on:change="(values) => checkSingular(values, 'labels/id')"
          >
            <a-select-option
                v-for="category in options['labels/id']"
                :key="category.name"
                :value="category.id"
                :disabled="!category.singular && singularMode['labels/id']"
            >
              {{ category.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="Kiemelt desztináció címke">
          <a-select
              :disabled="isMobileReadonly"
              :getPopupContainer="getParentTriggerNode()"
              :loading="loading['featuredDestinations/id']"
              mode="multiple"
              v-decorator="['featuredDestinations/id', {
                            rules: [{
                                required: false,
                                message: 'Kérjük töltse ki!'
                            }],
                            validateTrigger: ['blur', 'change']
                        }]"
              placeholder="Kérjük válasszon!"
              :filterOption="filterSelectNames"
              v-on:dropdownVisibleChange="(open) => open && loadOptions('featuredDestinations/id')"
              v-on:change="(values) => checkSingular(values, 'featuredDestinations/id')"
          >
            <a-select-option
                v-for="category in options['featuredDestinations/id']"
                :key="category.name"
                :value="category.id"
                :disabled="!category.singular && singularMode['featuredDestinations/id']"
            >
              {{ category.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <h4>Látogató címkék</h4>

        <a-form-item>
          <span slot="label">
              Kiemelt célcsoportok

              <a-icon v-on:click="() => modals.targetAudience = true" type="question-circle-o" />

              <a-modal
                  :mask="false"
                  :maskClosable="true"
                  :footer="null"
                  v-model=" modals.targetAudience" title="Melyik kiemelt célcsoportoknak lehet releváns az esemény?"
                  v-on:ok="() => modals.targetAudience = false">
                  <p>
                      <strong>Értéktudatos fiatalok:</strong><span> 25-40 év közöttiek, gyakran baráti társasággal érkeznek. Érdeklődnek a művészet, a kultúra és a divat iránt, továbbá jellemző rájuk a kíváncsiság, élménykeresők, nyitottak az újdonságra. Rendszeresen utaznak, érdeklődnek a minőségi éjszakai élet iránt, igénylik az igényes gasztronómiát és a minőségi street foodot, valamint hajlamosak a vásárlásra.</span>
                  </p>
                  <p>
                      <strong>Kozmopolita utazók:</strong><span> 30-55 év közöttiek. Kevésbé szeretnek alkalmazkodni, minőségi értékajánlatra vágynak. Nyitottak lehetnek a day-tripekre, extra élményekre (pl. privát vacsora), illetve szívesen látogatnak fine dining éttermeket. Érdeklődnek a koncertek, a kiállítások, a galériák, a fine dining, a borok, a kávéházak iránt, továbbá érdekelheti őket a privát guide használata, és jellemzően jelentős értékben vásárolnak.</span>
                  </p>
                  <p>
                      <strong>Élménykereső családosok:</strong><span> Szülő(k), legalább egy gyerekkel. Érdeklődnek a múzeumok, az élményfürdők, a kirándulások, a programok és a kiállítások iránt. Fontos szempont számukra a biztonság, valamint értékelik a zöld területeket, parkokat.</span>
                  </p>
                  <p>
                      <strong>Aktív szeniorok:</strong><span> Jellemzően feltöltődésre vágyó nyugdíjasok, akik nyitottak városnézésre és a gyógyfürdőkre. A kulturális turizmus, városlátogatások, aktív időtöltés, bor és gasztronómia élvezete mind fontos motivációt jelenthetnek számukra. Kiemelt szerepe van az egészségturizmus nyújtotta lehetőségeknek és az akadálymentes turisztikai kínálatnak.</span>
                  </p>
              </a-modal>
          </span>

          <a-select
              :getPopupContainer="getParentTriggerNode()"
              :loading="loading['targetGroups/id']"
              mode="multiple"
              :disabled="isMobileReadonly"
              v-decorator="['targetGroups/id', {
                            rules: [{
                                required: true,
                                message: 'Kérjük töltse ki!'
                            }],
                            validateTrigger: ['blur', 'change']
                        }]"
              placeholder="Kérjük válasszon!"
              :filterOption="filterSelectNames"
              v-on:dropdownVisibleChange="(open) => open && loadOptions('targetGroups/id')"
              v-on:change="(values) => checkSingular(values, 'targetGroups/id')"
          >
            <a-select-option
                v-for="category in options['targetGroups/id']"
                :key="category.name"
                :value="category.id"
                :disabled="!category.singular && singularMode['targetGroups/id']"
            >
              {{ category.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="Utazás típusa">
          <a-select
              :getPopupContainer="getParentTriggerNode()"
              :loading="loading['journeyTypes/id']"
              mode="multiple"
              :disabled="isMobileReadonly"
              v-decorator="['journeyTypes/id', {
                            rules: [{
                                required: true,
                                message: 'Kérjük töltse ki!'
                            }],
                            validateTrigger: ['blur', 'change']
                        }]"
              placeholder="Kérjük válasszon!"
              :filterOption="filterSelectNames"
              v-on:dropdownVisibleChange="(open) => open && loadOptions('journeyTypes/id')"
              v-on:change="(values) => checkSingular(values, 'journeyTypes/id')"
          >
            <a-select-option
                v-for="category in options['journeyTypes/id']"
                :key="category.name"
                :value="category.id"
                :disabled="!category.singular && singularMode['journeyTypes/id']"
            >
              {{ category.name }}
            </a-select-option>
          </a-select>
        </a-form-item>


        <a-form-item label="Helyszín jellege">
          <a-select
              :getPopupContainer="getParentTriggerNode()"
              :loading="loading['siteNatures/id']"
              mode="multiple"
              :disabled="isMobileReadonly"
              v-decorator="['siteNatures/id', {
                            rules: [{
                                required: true,
                                message: 'Kérjük töltse ki!'
                            }],
                            validateTrigger: ['blur', 'change']
                        }]"
              placeholder="Kérjük válasszon!"
              :filterOption="filterSelectNames"
              v-on:dropdownVisibleChange="(open) => open && loadOptions('siteNatures/id')"
              v-on:change="(values) => checkSingular(values, 'siteNatures/id')"
          >
            <a-select-option
                v-for="category in options['siteNatures/id']"
                :key="category.name"
                :value="category.id"
                :disabled="!category.singular && singularMode['siteNatures/id']"
            >
              {{ category.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="Szezonalitás">
          <a-select
              :disabled="isMobileReadonly"
              :getPopupContainer="getParentTriggerNode()"
              :loading="loading['seasonalities/id']"
              mode="multiple"
              v-decorator="['seasonalities/id', {
                            rules: [{
                                required: true,
                                message: 'Kérjük töltse ki!'
                            }],
                            validateTrigger: 'blur'
                        }]"
              placeholder="Kérjük válasszon!"
              :filterOption="filterSelectNames"
              v-on:dropdownVisibleChange="(open) => open && loadOptions('seasonalities/id')"
              v-on:change="(values) => checkSingular(values, 'seasonalities/id')"
          >
            <a-select-option
                v-for="category in options['seasonalities/id']"
                :key="category.name"
                :value="category.id"
                :disabled="!category.singular && singularMode['seasonalities/id']"
            >
              {{ category.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="Hozzáférhetőség">
          <a-select
              :getPopupContainer="getParentTriggerNode()"
              :loading="loading['accessibilities/id']"
              mode="multiple"
              :disabled="isMobileReadonly"
              v-decorator="['accessibilities/id', {
                            rules: [{
                                required: true,
                                message: 'Kérjük töltse ki!'
                            }],
                            validateTrigger: 'blur'
                        }]"
              placeholder="Kérjük válasszon!"
              :filterOption="filterSelectNames"
              v-on:dropdownVisibleChange="(open) => open && loadOptions('accessibilities/id')"
              v-on:change="(values) => checkSingular(values, 'accessibilities/id')"
          >
            <a-select-option
                v-for="category in options['accessibilities/id']"
                :key="category.name"
                :value="category.id"
                :disabled="!category.singular && singularMode['accessibilities/id']"
            >
              {{ category.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <template v-if="isNotMobile">
          <a-divider orientation="left" >
            ESEMÉNY MINŐSÍTÉSE
          </a-divider>

          <a-form-item>
            <span slot="label">
                Esemény minősítése

                <a-icon v-on:click="() => modals.classification = true" type="question-circle-o" />

                <a-modal
                    :mask="false"
                    :maskClosable="true"
                    :footer="null"
                    v-model="modals.classification" v-on:ok="() => modals.classification = false">
                    <div slot="title">
                        Kritériumrendszer az események minősítéséhez <br>
                        (5-7 értékeket csak MTÜ admin felhasználók rendelhetnek az eseményekhez.)
                    </div>

                    <p><strong>A NETA-ban csak a kifejezetten turisztikai relevanciával* bíró események gyűjthetők!</strong></p>

                    <p>Az események minősítési kategóriái:</p>

                    <p>
                        <strong>Lokális (potenciális, inaktív) (1) </strong><span> Olyan esemény, mely attrakcióvá fejleszthető. Turisztikai érdeklődésre számot tarthat, de hiányzik a bemutatás infrastruktúrája. <i>(Példa: a helyi művelődési ház programjai; gasztroest; megnyitó; sajtótájékoztató; könyvbemutató; helyi sportesemények stb.)</i> </span>
                    </p>
                    <p>
                      <strong>Lokális (2) </strong><span> Az esemény látogatói jellemzően 20-30 km-es körből érkeznek. Kifejezetten értelmezhető egy turisztikai célú utazás során, van történelmi, kulturális, gasztronómiai, természeti mondanivalója. Kiegészítő attrakciója a településnek, vendégéjszakát kevésbé generál. <i>(Példa: falunap; városnap; kisebb gasztronómiai rendezvények, pl. Balatonlellei Borhét; nagyobb színházak előadásai stb.)</i></span>
                    </p>
                    <p>
                        <strong>Regionális (3) </strong><span> 100 km-en belülről vonzza a látogatói többségét, látogatók száma minimum 5 ezer fő. Ismert, népszerű esemény, amely utazási döntést befolyásol, de szükséges több turisztikai vonzerő együttes jelenléte az utazáshoz. Jellemzően belföldi vendégek látogatják, generálhat vendégéjszakát, van helyettesítő terméke, hasonló profilú, látogatószámú esemény megtalálható az országban több helyen.  <i>(Példa: kisebb fesztiválok, pl. Paloznaki Jazzpiknik, Tiszavirág Fesztivál; gasztonómiai események, pl. Debreceni Bor- és Jazznapok, Balatonfüredi Borhetek stb.)</i></span>
                    </p>
                    <p>
                        <strong>Országos/regionális (4) </strong><span> 100-150 km-ről látható, jellemzően belföldi vendégek látogatják, látogatóforgalma minimum 50 ezer fő. Határmenti elhelyezkedés esetén érezhető az érdeklődés a szomszédos ország(ok)ból. Utazási döntést generál, több vendégéjszaka eltöltésére ösztönöz, desztinációs márkát épít. <i>(Példa: népi hagyományok köré épülő attrakciók, pl. Hollókő, Busójárás; közepes méretű és mérsékeltebb látogatószámú fesztiválok, pl. STRAND, Bánkitó Fesztivál, Sóstó Feszt, Rockmaraton; színházi előadások, pl. Szentendrei Teátrum, Szegedi Szabadtéri Játékok stb.)</i></span>
                    </p>
                    <p>
                        <strong>Országos (5)  </strong><span> A belföldi utazók többségének "listáján" szerepel, országosan ismert, látogatóforgalma minimum 100 ezer fő. Az esemény országimázs építésre alkalmas, frekventált üdülőterületen található, önálló utazási döntésre alkalmas, jellemzően belföldi vendégek látogatják, az ország másik feléből is képes vendéget vonzani. Egy koherens és markáns koncepció alapján megfogalmazott egyedi, kivételes esemény. <i>(Példa: nagyobb méretű, inkább hazai látogatókat vonzó zenei és gasztonómiai fesztiválok, pl: EFOTT, SZIN, Campus, Gourmet Fesztivál, Budapest Borfesztivál; megyei szintű sportesemények, bajnokságok stb.)</i></span>
                    </p>
                    <p>
                        <strong>Nemzetközi (6)  </strong><span> Utazási döntést befolyásol nemzetközi piacról, látogatóforgalma minimum 200 ezer fő. Az esemény nemzetközi színvonalú, alkalmas országmárka építésre, minimum 30% külföldi látogatót vonz, vendégéjszakát generál, több napos látogatásra ösztönöz. <i>(Példa: külföldi TOP előadó által bemutatott koncertek a Papp László Sportarénában; kiemelt, nemzetközi érdeklődést is generáló kulturális események a MÜPA, a Margitszigeti Szabadtéri Színpad vagy a Magyar Állami Operaház szervezésében; konferenciák, kongresszusok, pl. Planet 2021; Forma1 stb.)</i></span>
                    </p>
                    <p>
                        <strong>Nemzetközi + (7)  </strong><span> Kifejezetten az ország turisztikai márkázására alkalmas nemzetközi színvonalú és látogatottságú esemény. Számuk behatárolt, az ország első 50 nemzetközi viszonylatban fontos eseménye. A közönség nagysága, a programokra és promócióra szánt költségvetése számottevő. <i>(Példa: Sziget; Balaton Sound; nemzetközi sportesemények, versenyek, pl. FINA, UEFA; konferenciák; kongresszusok, pl. Nemzetközi Eucharisztikus Kongresszus stb.)</i></span>
                    </p>

                    <br />

                    <p><i>*Az alábbi eseményeket nem tekintjük turisztikailag relevánsnak:</i></p>

                    <ul>
                      <li>helyi művelődési házak programjai</li>
                      <li>turisztikai érdeklődést nem generáló események</li>
                      <li>állandó kiállítások</li>
                      <li>különböző ünnepekhez köthető, kisebb látogatószámú rendezvények (Mikulás-nap, beszédek, felvonulások stb, de például a nagyobb érdeklődést generáló Mikulás-napi futás, Adventi vásár, stb. rögzíthető)</li>
                      <li>foglalkozások (táncórák, kézműveskedés stb.)</li>
                      <li>színházi előadásokat nem szükséges rögzíteni, de a nagyobb premierek feltölthetők</li>
                      <li>könyvklubok eseményei, felolvasások stb.</li>
                      <li>iskolai programok (versenyek, foglalkozások, ünnepek stb.)</li>
                      <li>sajtótájékoztatók</li>
                    </ul>

                </a-modal>
            </span>

            <a-select
                :disabled="!isAdmin && form.getFieldValue('classification') > 4 || isMobileReadonly"
                placeholder="Kérjük válasszon!"
                v-decorator="[
                          'classification',
                          {
                              rules: [{
                                  required: true,
                                  message: 'Kérjük töltse ki!'
                              }]
                          }
                      ]"
            >
              <a-select-option
                  v-for="number in 7"
                  :key="number"
                  :value="number"
                  :disabled="!isAdmin && number > 4"
              >
                {{ number }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item
              :selfUpdate="true"
              label="Becsült látogatószám az esemény teljes időtartamára vonatkozóan (fő)"
          >
            <a-input-number
                :disabled="isMobileReadonly"
                :min="0"
                :max="9999999"
                v-decorator="[
                          `visitorCount`,
                          {
                              validateTrigger: ['blur', 'change', 'keyup'],
                              preserve: true,
                              rules: [{
                                  required: false,
                                  type: 'integer',
                                  whitespace: true,
                                  transform: (value) => {
                                    value = parseInt(value);

                                    return isNaN(value) ? null : value;
                                  },
                                  message: 'Kérjük töltse ki!'
                              }]

                          }
                      ]"
            >
            </a-input-number>
            látogató
          </a-form-item>
        </template>

        <a-divider orientation="left">
          ESEMÉNY IDŐPONTJA
        </a-divider>

        <a-form-item label="Nyitás dátuma">
          <a-date-picker
              :disabled="isMobileReadonly"
              :disabled-date="openingRangeStartDisabled"
              v-decorator="[
                        `opening/seasonalityStartDate`,
                        {
                            rules: [{
                                required: true,
                                message: 'Kérjük töltse ki!',
                            }],
                        }
                    ]"
              style="width: 85%"
              :format="dateFields['opening/seasonalityStartDate']"
          >
          </a-date-picker>
        </a-form-item>

        <a-form-item label="Nyitás időpontja">
          <a-time-picker
              :disabled="isMobileReadonly"
              :getPopupContainer="getParentTriggerNode()"
              v-decorator="[
                       'opening/seasonalityStartTime',
                          {
                            rules: [
                              {
                                required: true,
                                message: 'Kérjük válassza ki a listából a megfelelő értéket!',
                              }
                            ]
                          }
                        ]"
              style="width: 85%"
              :minute-step="15"
              :format="dateFields['opening/seasonalityStartTime']"
              v-on:change="(time, timeString) => timePickerChange('opening/seasonalityStartTime', time, timeString)"
          />
        </a-form-item>

        <a-form-item label="Zárás dátuma">
          <a-date-picker
              :disabled="isMobileReadonly"
              :disabled-date="openingRangeEndDisabled"
              v-decorator="[
                        'opening/seasonalityEndDate',
                        {
                            rules: [{
                                required: true,
                                message: 'Kérjük töltse ki!',
                            }],
                        }
                    ]"
              style="width: 85%"
              :format="dateFields['opening/seasonalityEndDate']"
          >
          </a-date-picker>
        </a-form-item>

        <a-form-item label="Zárás időpontja">
          <a-time-picker
              :disabled="isMobileReadonly"
              :getPopupContainer="getParentTriggerNode()"
              v-decorator="[
                       'opening/seasonalityEndTime',
                          {
                            rules: [
                              {
                                required: true,
                                message: 'Kérjük válassza ki a listából a megfelelő értéket!',
                              }
                            ]
                          }
                        ]"
              style="width: 85%"
              :minute-step="15"
              :format="dateFields['opening/seasonalityEndTime']"
              v-on:change="(time, timeString) => timePickerChange('opening/seasonalityEndTime', time, timeString)"
          />
        </a-form-item>

        <a-divider orientation="left">
          ESEMÉNY ELHELYEZKEDÉSE
        </a-divider>

        <p>
          Az alábbi sorokban kérjük az esemény pontos elhelyezkedésére vonatkozó adatok megadását.
        </p>

        <a-form-item label="Irányítószám">
          <a-select
              :disabled="isMobileReadonly"
              v-decorator="[
                            'address/zipCode/id',
                            {
                                rules: [
                                    {
                                    required: true,
                                    whitespace: true,
                                    message: 'Kérjük töltse ki!',
                                    type: 'integer'
                                  },
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
              placeholder="Kezdjen gépelni"
              showSearch
              :showArrow="false"
              :defaultActiveFirstOption="false"
              :getPopupContainer="getParentTriggerNode()"
              :filterOption="false"
              :style="{width: '100%'}"
              v-on:search="(value) => zipCodeAutoComplete(value,'address/zipCode/id')"
              v-on:change="(value) => {fillCityOptions(value,'address/zipCode/id','address/city/id')}"
              v-on:dropdownVisibleChange="(open) => open && zipCodeAutoComplete(null, 'address/zipCode/id')"
              :notFoundContent="loading['address/zipCode/id'] ? undefined : null"
          >
            <a-spin v-if="loading['address/zipCode/id']" slot="notFoundContent" size="small" />

            <a-select-option
                v-for="option in options['address/zipCode/id']"
                :key="option.code"
                :value="option.id"
            >
              {{ option.code }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="Település neve">
          <a-select
              v-decorator="[
                            'address/city/id',
                            {
                                rules: [
                                    {
                                    required: true,
                                    whitespace: true,
                                    message: 'Kérjük töltse ki!',
                                    type: 'integer'
                                  },
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
              placeholder="Töltse ki az irányítószám mezőt!"
              :showArrow="false"
              :disabled="!options['address/city/id'].length || isMobileReadonly"
              :defaultActiveFirstOption="true"
              :filterOption="false"
              :style="{width: '100%'}"
              :notFoundContent="loading['address/zipCode/id'] ? undefined : null"
          >
            <a-spin v-if="loading['address/zipCode/id']" slot="notFoundContent" size="small" />

            <a-select-option
                v-for="option in options['address/city/id']"
                :key="option.id"
                :value="option.id"
            >
              {{ option.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
            :selfUpdate="true"
            label="Közterület neve"
        >
          <a-input
              :maxLength="25000"
              :disabled="isChecked('address/addressNotRelevant') || isMobileReadonly"
              v-decorator="[
                            'address/address',
                            {
                                rules: [
                                    {
                                      required: !isChecked('address/addressNotRelevant'),
                                      whitespace: true,
                                      message: 'Kérjük töltse ki!'
                                    },
                                    {
                                      max: characterCounts['address/address'].max,
                                      message: 'Maximum ' + characterCounts['address/address'].max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
              placeholder="Kérjük beírni (betűkkel)"
          >
          </a-input>

          <a-checkbox
              :disabled="isMobileReadonly"
              v-decorator="[
                        'address/addressNotRelevant',
                        {
                            rules: [{
                                required: false
                            }]
                        }
                    ]"
              :checked="isChecked('address/addressNotRelevant')"
              v-on:change="() => relevancyCheckboxChange('address/address')"
          >
            Nem releváns
          </a-checkbox>
        </a-form-item>

        <a-form-item
            :selfUpdate="true"
            label="Közterület típusa (utca, tér, út, dűlő stb.)"
        >
          <a-input
              :maxLength="25000"
              :disabled="isChecked('address/addressTypeNotRelevant') || isMobileReadonly"
              v-decorator="[
                            'address/addressType',
                            {
                                rules: [
                                    {
                                      required: !isChecked('address/addressTypeNotRelevant'),
                                      whitespace: true,
                                      message: 'Kérjük töltse ki!'
                                    },
                                    {
                                      max: characterCounts['address/addressType'].max,
                                      message: 'Maximum ' + characterCounts['address/addressType'].max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
              placeholder="Kérjük beírni (betűkkel)"
          >
          </a-input>

          <a-checkbox
              :disabled="isMobileReadonly"
              v-decorator="[
                        'address/addressTypeNotRelevant',
                        {
                            rules: [{
                                required: false
                            }]
                        }
                    ]"
              :checked="isChecked('address/addressTypeNotRelevant')"
              v-on:change="() => relevancyCheckboxChange('address/addressType')"
          >
            Nem releváns
          </a-checkbox>
        </a-form-item>

        <a-form-item>
          <span slot="label">
              Házszám/helyrajzi szám
              <a-tooltip
                  title="Itt a házszámon kívül más is beírható (pl. helyrajzi szám vagy más egyéb, pontos azonosító, ami az adott ingatlan helyét pontosan meghatározza). Ha nincs ilyen pontos azonosító szám a címhez, akkor kérjük a mezőt üresen hagyni."
              >
                  <a-icon type="question-circle-o" />
              </a-tooltip>
          </span>

          <a-input
              :maxLength="25000"
              :disabled="isChecked('address/addressNumberNotRelevant') || isMobileReadonly"
              v-decorator="[
                            'address/addressNumber',
                            {
                                rules: [
                                    {
                                      required: !isChecked('address/addressNumberNotRelevant'),
                                      whitespace: true,
                                      message: 'Kérjük töltse ki!'
                                    },
                                    {
                                      max: characterCounts['address/addressNumber'].max,
                                      message: 'Maximum ' + characterCounts['address/addressNumber'].max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
              placeholder="Kérjük beírni (számokkal, betűkkel)"
          />

          <a-checkbox
              :disabled="isMobileReadonly"
              v-decorator="[
                        'address/addressNumberNotRelevant',
                        {
                            rules: [{
                                required: false
                            }]
                        }
                    ]"
              :checked="isChecked('address/addressNumberNotRelevant')"
              v-on:change="() => relevancyCheckboxChange('address/addressNumber')"
          >
            Nem releváns
          </a-checkbox>
        </a-form-item>

        <a-form-item :selfUpdate="true">
          <span slot="label">
              Az esemény GPS koordinátája: szélességi fok

              <a-tooltip :title="coordinateTooltipLatitude">
                  <a-icon type="question-circle-o" />
              </a-tooltip>
          </span>

          <a-input
              :disabled="isMobileReadonly"
              :maxLength="25000"
              v-on:change="(event) => formatCoordinate(event.target.value, 'address/lat')"
              v-decorator="[
                            'address/lat',
                            {
                                rules: [
                                    {
                                      required: true,
                                      whitespace: true,
                                      pattern: /^4([-+]?)([\d]{1})(((\.)(\d+)))$/g,
                                      message: 'A koordináták formátuma helytelen!'
                                    },
                                    {
                                      type: 'float',
                                      message: 'Kérjük adjon meg valós GPS koordinátákat!',
                                      transform: (value) => {
                                        value = parseFloat(value);

                                        return isNaN(value) ? null: value;
                                      },
                                      min: 45.72,
                                      max: 48.60
                                    }
                                    ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
              placeholder="Kérjük a koordinátát az alábbi formátumban adja meg: 47.507346"
          >
          </a-input>
        </a-form-item>

        <a-form-item :selfUpdate="true">
          <span slot="label">
            Az esemény GPS koordinátája: hosszúsági fok

            <a-tooltip :title="coordinateTooltipLongitude">
                <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>

          <a-input
              :disabled="isMobileReadonly"
              :maxLength="25000"
              v-on:change="(event) => formatCoordinate(event.target.value, 'address/long')"
              v-decorator="[
                            'address/long',
                            {
                                rules: [
                                    {
                                      required: true,
                                      whitespace: true,
                                      pattern: /^(1|2)(([-+]?)([\d]{1})((\.)(\d+))?)$/g,
                                      message: 'A koordináták formátuma helytelen!'
                                    },
                                    {
                                      type: 'float',
                                      message: 'Kérjük adjon meg valós GPS koordinátákat!',
                                      transform: (value) => {
                                        value = parseFloat(value);

                                        return isNaN(value) ? null: value;
                                      },
                                      min: 16.10,
                                      max: 23
                                    }],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
              placeholder="Kérjük a koordinátát az alábbi formátumban adja meg: 19.046610"
          >
          </a-input>
        </a-form-item>

        <a-divider orientation="left">
          AZ ESEMÉNY HONLAPJA
        </a-divider>

        <a-form-item>
          <span slot="label">
              Esemény honlapjának elérhetősége

              <a-tooltip title="Kérjük a weboldal címét http(s)://www.weboldal.xy formátumban töltse ki!">
                  <a-icon type="question-circle-o" />
              </a-tooltip>
          </span>

          <a-input
              :maxLength="25000"
              :disabled="isChecked('website/notRelevant') || isMobileReadonly"
              v-on:change="(event) => transformEncodedUrl(event.target.value, 'website/url')"
              v-decorator="[
                        'website/url',
                        {
                            rules: [
                                    {
                                      required: !isChecked('website/notRelevant'),
                                      message: 'Kérjük a megfelelő formátumban töltse ki!',
                                      pattern: isChecked('website/notRelevant') ? undefined : /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
                                    }
                                  ],
                            validateTrigger: ['blur', 'keyup']
                        }
                    ]"
          />

          <a-checkbox
              :disabled="isMobileReadonly"
              v-decorator="[
                        'website/notRelevant',
                        {
                            rules: [{
                                required: false
                            }]
                        }
                    ]"
              :checked="isChecked('website/notRelevant')"
              v-on:change="() => relevancyCheckboxChange('website/url')"
          >
            Nem rendelkezik az esemény weboldallal
          </a-checkbox>
        </a-form-item>

        <a-form-item
            label="Az esemény honlapjának idegen nyelvei"
            v-show="!isChecked('website/notRelevant')"
        >
          <a-select
              :disabled="isMobileReadonly"
              :getPopupContainer="getParentTriggerNode()"
              mode="multiple"
              v-decorator="[
                        'website/languages/id',
                        {
                            rules: [],
                            validateTrigger: ['blur']
                        }
                    ]"
              placeholder="Kérjük válasszon!"
              :filterOption="filterSelectNames"
          >
            <a-select-option
                v-for="language in options['website/languages/id']"
                :key="language.name"
                :value="language.id"
            >
              {{ language.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item v-show="!isChecked('website/notRelevant')" :selfUpdate="true">
          <span slot="label">
              Egyéb nyelvek
              <a-tooltip
                  title="Ha a felsoroltakon kívül egyéb nyelven is elérhető a honlap, kérjük az egyéb nyelveket itt felsorolni"
              >
                  <a-icon type="question-circle-o" />
              </a-tooltip>
          </span>

          <a-textarea
              :disabled="isMobileReadonly"
              :maxLength="25000"
              v-decorator="['website/comment',
                        {
                            rules: []
                        }
                    ]"
              :autoSize="{minRows: 2}"
          />
        </a-form-item>

        <a-form-item :selfUpdate="true">
          <span slot="label">
              Videó elérhetősége

              <a-tooltip title="Kérjük add meg az eseményt bemutató videó elérhetőségét. Az esemény adatlapjára kerül kivezetésre. A hivatkozás YouTube-ról lehetséges, például: https://www.youtube.com/watch?v=e_ofRmWZkdM">
                  <a-icon type="question-circle-o" />
              </a-tooltip>
          </span>

          <a-textarea
                  :disabled="isMobileReadonly"
                  :maxLength="25000"
                  v-decorator="[
                    'videoLink',
                    {
                        rules: [
                            {
                              required: false,
                              preserve: true,
                              whitespace: true,
                              pattern: /(.*?)(^|\/|v=)([a-z0-9_-]{11})(.*)?/gim,
                              message: 'Helytelen formátum!'
                            },
                            {
                              max: characterCounts.videoLink.max,
                              message: 'Maximum ' + characterCounts.videoLink.max + ' karakter engedélyezett.',
                            }
                        ],
                        validateTrigger: ['blur', 'keyup']
                    }
                ]"
                  :autoSize="{minRows: 2}"
                  placeholder="A hivatkozás YouTube-ról lehetséges, például: https://www.youtube.com/watch?v=e_ofRmWZkdM"
          />
        </a-form-item>

        <a-divider orientation="left">
          SAJÁT KÖZÖSSÉGI MÉDIA OLDALAK
        </a-divider>

        <h4>Facebook oldal</h4>

        <a-form-item>
          <span slot="label">
              Facebook oldal elérhetősége

              <a-tooltip
                  title="Kérjük a Facebook linket az alábbi formátumban adja meg: https://www.facebook.com/parlament.hu"
              >
                  <a-icon type="question-circle-o" />
              </a-tooltip>
          </span>

          <a-input
              :maxLength="25000"
              :disabled="isChecked('facebook/notRelevant') || isMobileReadonly"
              v-on:change="(event) => transformEncodedUrl(event.target.value, 'facebook/url')"
              v-decorator="[
                        'facebook/url',
                        {
                            rules: [{
                                required: !isChecked('facebook/notRelevant') && isNotMobile,
                                message: 'Kérjük a megfelelő formátumban töltse ki!',
                                pattern: /^(http|https):\/\/(?:www.)?facebook.com/g
                            }],
                            validateTrigger: ['blur', 'keyup']
                        }
                    ]"
          />

          <a-checkbox
              :disabled="isMobileReadonly"
              v-decorator="[
                        'facebook/notRelevant',
                        {
                            rules: [{
                                required: false
                            }]
                        }
                    ]"
              :checked="isChecked('facebook/notRelevant')"
              v-on:change="() => relevancyCheckboxChange('facebook/url')"
          >
            Nem rendelkezik az esemény Facebook oldallal
          </a-checkbox>
        </a-form-item>

        <h4>Instagram oldal</h4>

        <a-form-item>
          <span slot="label">
              Instagram oldal elérhetősége

              <a-tooltip
                  title="Kérjük a Instagram linket az alábbi formátumban adja meg: https://www.instagram.com/parlament.hu"
              >
                  <a-icon type="question-circle-o" />
              </a-tooltip>
          </span>

          <a-input
              :maxLength="25000"
              :disabled="isChecked('instagram/notRelevant') || isMobileReadonly"
              v-on:change="(event) => transformEncodedUrl(event.target.value, 'instagram/url')"
              v-decorator="[
                        'instagram/url',
                        {
                            rules: [{
                                required: !isChecked('instagram/notRelevant') && isNotMobile,
                                message: 'Kérjük a megfelelő formátumban töltse ki!',
                                pattern: /^(http|https):\/\/(?:www.)?instagram.com/g,
                            }],
                            validateTrigger: ['blur', 'keyup']
                        }
                    ]"
          />

          <a-checkbox
              :disabled="isMobileReadonly"
              v-decorator="[
                        'instagram/notRelevant',
                        {
                            rules: [{
                                required: false
                            }]
                        }
                    ]"
              :checked="isChecked('instagram/notRelevant')"
              v-on:change="() => relevancyCheckboxChange('instagram/url')"
          >
            Nem rendelkezik az esemény Instagram oldallal
          </a-checkbox>
        </a-form-item>

        <a-divider orientation="left">
          AZ ESEMÉNY SZERVEZŐJE
        </a-divider>

        <a-form-item label="Az esemény szervezőjének hivatalos neve" :selfUpdate="true">
          <a-input
              :disabled="isMobileReadonly"
              :maxLength="25000"
              v-decorator="['organizationAddress/name',
                            {
                                rules: [
                                    {
                                      required: false,
                                      preserve: true,
                                      message: 'Kérjük töltse ki!'
                                    },
                                    {
                                      max: characterCounts['organizationAddress/name'].max,
                                      message: 'Maximum ' + characterCounts['organizationAddress/name'].max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur', 'keyup']
                            }
                        ]"
              :autoSize="{minRows: 2}"
          />
        </a-form-item>

        <h4>Az esemény szervezőjének hivatalos elérhetősége</h4>

        <a-form-item label="Irányítószám">
          <a-select
              :disabled="isMobileReadonly"
              v-decorator="[
                            'organizationAddress/zipCode/id',
                            {
                                rules: [
                                    {
                                    required: false,
                                    whitespace: true,
                                    message: 'Kérjük töltse ki!',
                                    type: 'integer'
                                  },
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
              placeholder="Kezdjen gépelni"
              showSearch
              :showArrow="false"
              :defaultActiveFirstOption="false"
              :getPopupContainer="getParentTriggerNode()"
              :filterOption="false"
              :style="{width: '100%'}"
              v-on:search="(value) => zipCodeAutoComplete(value,'organizationAddress/zipCode/id')"
              v-on:change="(value) => {fillCityOptions(value,'organizationAddress/zipCode/id','organizationAddress/city/id')}"
              v-on:dropdownVisibleChange="(open) => open && zipCodeAutoComplete(null, 'organizationAddress/zipCode/id')"
              :notFoundContent="loading['organizationAddress/zipCode/id'] ? undefined : null"
          >
            <a-spin v-if="loading['organizationAddress/zipCode/id']" slot="notFoundContent" size="small" />

            <a-select-option
                v-for="option in options['organizationAddress/zipCode/id']"
                :key="option.code"
                :value="option.id"
            >
              {{ option.code }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="Település neve">
          <a-select
              v-decorator="[
                            'organizationAddress/city/id',
                            {
                                rules: [
                                    {
                                    required: false,
                                    whitespace: true,
                                    message: 'Kérjük töltse ki!',
                                    type: 'integer'
                                  },
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
              placeholder="Töltse ki az irányítószám mezőt!"
              :showArrow="false"
              :disabled="!options['organizationAddress/city/id'].length || isMobileReadonly"
              :defaultActiveFirstOption="true"
              :filterOption="false"
              :style="{width: '100%'}"
              :notFoundContent="loading['organizationAddress/zipCode/id'] ? undefined : null"
          >
            <a-spin v-if="loading['organizationAddress/zipCode/id']" slot="notFoundContent" size="small" />

            <a-select-option
                v-for="option in options['organizationAddress/city/id']"
                :key="option.id"
                :value="option.id"
            >
              {{ option.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
            :selfUpdate="true"
            label="Közterület neve"
        >
          <a-input
              :disabled="isMobileReadonly"
              :maxLength="25000"
              v-decorator="[
                            'organizationAddress/address',
                            {
                                rules: [
                                    {
                                      required: false,
                                      whitespace: true,
                                      message: 'Kérjük töltse ki!'
                                    },
                                    {
                                      max: characterCounts['organizationAddress/address'].max,
                                      message: 'Maximum ' + characterCounts['organizationAddress/address'].max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
              placeholder="Kérjük beírni (betűkkel)"
          >
          </a-input>
        </a-form-item>

        <a-form-item
            :selfUpdate="true"
            label="Közterület típusa (utca, tér, út, dűlő stb.)"
        >
          <a-input
              :disabled="isMobileReadonly"
              :maxLength="25000"
              v-decorator="[
                            'organizationAddress/addressType',
                            {
                                rules: [
                                    {
                                      required: false,
                                      whitespace: true,
                                      message: 'Kérjük töltse ki!'
                                    },
                                    {
                                      max: characterCounts['organizationAddress/addressType'].max,
                                      message: 'Maximum ' + characterCounts['organizationAddress/addressType'].max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
              placeholder="Kérjük beírni (betűkkel)"
          >
          </a-input>
        </a-form-item>

        <a-form-item>
          <span slot="label">
              Házszám/helyrajzi szám
              <a-tooltip
                  title="Itt a házszámon kívül más is beírható (pl. helyrajzi szám vagy más egyéb, pontos azonosító, ami az adott ingatlan helyét pontosan meghatározza). Ha nincs ilyen pontos azonosító szám a címhez, akkor kérjük a mezőt üresen hagyni."
              >
                  <a-icon type="question-circle-o" />
              </a-tooltip>
          </span>

          <a-input
              :disabled="isMobileReadonly"
              :maxLength="25000"
              v-decorator="[
                            'organizationAddress/addressNumber',
                            {
                                rules: [
                                    {
                                      required: false,
                                      whitespace: true,
                                      message: 'Kérjük töltse ki!'
                                    },
                                    {
                                      max: characterCounts['organizationAddress/addressNumber'].max,
                                      message: 'Maximum ' + characterCounts['organizationAddress/addressNumber'].max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
              placeholder="Kérjük beírni (számokkal, betűkkel)"
          />
        </a-form-item>

        <a-form-item :selfUpdate="true">
          <span slot="label">
              Az esemény szervezőjének központi telefonszáma (pl.: +36 1 234 5678)
              <a-tooltip
                  title="Amennyiben nem rendelkezik telefonszámmal az esemény szervezője, úgy kérjük, adja meg a Tourinform iroda központi telefonszámát!"
              >
                  <a-icon type="question-circle-o" />
              </a-tooltip>
          </span>

          <a-input
              :disabled="isMobileReadonly"
              :maxLength="25000"
              :style="{width: '160px'}"
              v-decorator="[
                                'centralContact/phone',
                                {
                                    rules: [{
                                        required: isNotMobile,
                                        pattern: /^[\/+][36]{1,2}[ ][0-9]{1,2}[ ][0-9]{3}[ ][0-9]{3,4}$/,
                                        message: 'Hibás telefonszám formátum!'
                                    }],
                                   validateTrigger: ['blur']
                                }
                            ]"
              v-on:change="(event) => formatPhoneNumber(event.target.value, 'centralContact/phone')"
              ref="centralContact/phone"
              placeholder="+36 1 234 5678"
          />
        </a-form-item>

        <a-form-item :selfUpdate="true">
          <span slot="label">
              Az esemény szervezőjének központi e-mail címe
              <a-tooltip
                  title="Amennyiben nem rendelkezik e-mail címmel az esemény szervezője, úgy kérjük, adja meg a Tourinform iroda központi e-mail címét! Kérjük az e-mail címet iroda@domain.xy formátumban töltse ki!"
              >
                  <a-icon type="question-circle-o" />
              </a-tooltip>
          </span>

          <a-input
              :disabled="isMobileReadonly"
              type="email"
              :maxLength="25000"
              v-decorator="[
                            'centralContact/email',
                            {
                                rules: [
                                    {
                                      required: isNotMobile,
                                      whitespace: true,
                                      type: 'email',
                                      message: 'Kérjük a megfelelő formátumban töltse ki!'
                                    },
                                    {
                                      max: characterCounts['centralContact/email'].max,
                                      message: 'Maximum ' + characterCounts['centralContact/email'].max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
              placeholder="Kérjük az e-mail címet beírni!"
          >
          </a-input>
        </a-form-item>

        <a-form-item
            :selfUpdate="true"
            label="Az eseményt szervező (első számú) vezetőjének neve"
        >
          <a-input
              :disabled="isMobileReadonly"
              :maxLength="25000"
              v-decorator="[
                            'leaderContact/name',
                            {
                                rules: [
                                    {
                                      required: false,
                                      whitespace: true,
                                      message: 'Kérjük töltse ki!'
                                    },
                                    {
                                      max: characterCounts['leaderContact/name'].max,
                                      message: 'Maximum ' + characterCounts['leaderContact/name'].max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
          />
        </a-form-item>

        <a-form-item
            :selfUpdate="true"
            label="Az eseményt szervező (első számú) vezetőjének beosztása"
        >
          <a-input
              :disabled="isMobileReadonly"
              :maxLength="25000"
              v-decorator="[
                            'leaderContact/title',
                            {
                                rules: [
                                    {
                                      required: false,
                                      whitespace: true,
                                      message: 'Kérjük töltse ki!'
                                    },
                                    {
                                      max: characterCounts['leaderContact/title'].max,
                                      message: 'Maximum ' + characterCounts['leaderContact/title'].max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
          />
        </a-form-item>

        <a-form-item
            :selfUpdate="true"
            label="Az eseményt szervező (első számú) vezetőjének telefonszáma"
        >
          <a-input
              :disabled="isMobileReadonly"
              :maxLength="25000"
              :style="{width: '160px'}"
              v-decorator="[
                                'leaderContact/phone',
                                {
                                    rules: [{
                                        required: false,
                                        pattern: /^[\/+][36]{1,2}[ ][0-9]{1,2}[ ][0-9]{3}[ ][0-9]{3,4}$/,
                                        message: 'Hibás telefonszám formátum!'
                                    }],
                                   validateTrigger: ['blur']
                                }
                            ]"
              v-on:change="(event) => formatPhoneNumber(event.target.value, 'leaderContact/phone')"
              ref="leaderContact/phone"
              placeholder="+36 1 234 5678"
          />
        </a-form-item>


        <a-form-item
            :selfUpdate="true"
            label="Kapcsolattartó neve (az Adattárhoz kapcsolódóan, ha eltér a vezetőtől)"
        >
          <a-input
              :disabled="isMobileReadonly"
              :maxLength="25000"
              v-decorator="[
                            'generalContact/name',
                            {
                                rules: [
                                    {
                                      required: false,
                                      whitespace: true,
                                      message: 'Kérjük töltse ki!'
                                    },
                                    {
                                      max: characterCounts['generalContact/name'].max,
                                      message: 'Maximum ' + characterCounts['generalContact/name'].max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
          />
        </a-form-item>

        <a-form-item
            :selfUpdate="true"
            label="Kapcsolattartó beosztása"
        >
          <a-input
              :disabled="isMobileReadonly"
              :maxLength="25000"
              v-decorator="[
                            'generalContact/title',
                            {
                                rules: [
                                    {
                                      required: false,
                                      whitespace: true,
                                      message: 'Kérjük töltse ki!'
                                    },
                                    {
                                      max: characterCounts['generalContact/title'].max,
                                      message: 'Maximum ' + characterCounts['generalContact/title'].max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
          />
        </a-form-item>

        <a-form-item
            :selfUpdate="true"
            label="Kapcsolattartó telefonos elérhetősége (pl.: +36 1 234 5678)"
        >
          <a-input
              :disabled="isMobileReadonly"
              :maxLength="25000"
              :style="{width: '160px'}"
              v-decorator="[
                                'generalContact/phone',
                                {
                                    rules: [{
                                        required: false,
                                        pattern: /^[\/+][36]{1,2}[ ][0-9]{1,2}[ ][0-9]{3}[ ][0-9]{3,4}$/,
                                        message: 'Hibás telefonszám formátum!'
                                    }],
                                   validateTrigger: ['blur']
                                }
                            ]"
              v-on:keyup="(event) => formatPhoneNumber(event.target.value, 'generalContact/phone')"
              ref="generalContact/phone"
              placeholder="+36 1 234 5678"
          />
        </a-form-item>

        <a-form-item :selfUpdate="true">
          <span slot="label">
              Kapcsolattartó e-mail címe
              <a-tooltip
                  title="Kérjük az e-mail címet iroda@domain.xy formátumban töltse ki!"
              >
                  <a-icon type="question-circle-o" />
              </a-tooltip>
          </span>

          <a-input
              :disabled="isMobileReadonly"
              type="email"
              :maxLength="25000"
              v-decorator="[
                            'generalContact/email',
                            {
                                rules: [
                                    {
                                      required: false,
                                      whitespace: true,
                                      type: 'email',
                                      message: 'Kérjük a megfelelő formátumban töltse ki!'
                                    },
                                    {
                                      max: characterCounts['centralContact/email'].max,
                                      message: 'Maximum ' + characterCounts['centralContact/email'].max + ' karakter engedélyezett.',
                                    }
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
          >
          </a-input>
        </a-form-item>

        <a-divider />

        <a-form-item :selfUpdate="true">
          <span slot="label">
              Megjegyzés
              <a-tooltip
                  title="Kérjük ezt a cellát akkor töltse ki, ha a témához kapcsolódóan egyéb megjegyzése van."
              >
                  <a-icon type="question-circle-o" />
              </a-tooltip>
          </span>

          <a-textarea
              :disabled="isMobileReadonly"
              :maxLength="25000"
              v-decorator="[
                        'comment',
                        {
                            rules: [{
                                required: false,
                                message: 'Kérjük töltse ki!'
                            }]
                        }
                    ]"
              :autoSize="{minRows: 2}"
          />
        </a-form-item>

        <a-form-item>
          <a-button type="primary" html-type="submit" :loading="submitLoading" v-if="showSaveButton">
            Mentés
          </a-button>

          <a-button type="secondary" html-type="button" v-on:click="resetForm" v-if="hasChanged()">
            <a-icon
                type="undo"
                theme="outlined"
            />

            Piszkozat törlése
          </a-button>

          <span v-if="currentTab" class="last-modification">
            Utolsó mentés: <strong>{{ moment(currentTab.modificationTime).format(timeStampFormat).toString() }}</strong> &nbsp;<span v-if="currentTab.user">({{ currentTab.user.userName }})</span>
          </span>

          <a-alert
              :style="{
                        marginTop: '10px'
                    }"
              v-if="submitResult.show"
              :message="submitResult.message"
              :type="submitResult.type"
              showIcon
              closable
              :afterClose="() => submitResult.show = false"
          />
        </a-form-item>
      </a-form>
    </a-spin>
  </div>
</template>

<script>
import { EditFormComponentMixin } from "@/mixins/EditFormComponentMixin";
import debounce from "lodash.debounce";
import axios from "axios";

export default {
  menuName: 'Eseményre vonatkozó adatok',
  name: 'attraction-data-form',
  mixins: [EditFormComponentMixin],
  data() {
    return {
      coordinateTooltipLatitude: 'Kérjük a koordinátát az alábbi formátumban adja meg: 47.507346 Kérjük annak a helynek a GPS koordinátáit beírni, ahol az esemény pontosan található VAGY annak a helynek a koordinátáit, ahol az eseményhez legközelebb biztonságosan lehet parkolni, belépni, vagy belépőt vásárolni /Segítségül: a google.maps honlapon: 1. jobb egér gombbal kattintás a kívánt helyre, 2. "mi található itt?" lehetőség kiválasztása a lenyíló menüből, 3. az oldal alján megjelenik a kártya a koordinátákkal (alsó sorban) - ezt az adatsor kérjük ide beírni.',
      coordinateTooltipLongitude: 'Kérjük a koordinátát az alábbi formátumban adja meg: 19.046610! Kérjük annak a helynek a GPS koordinátáit beírni, ahol az esemény pontosan található VAGY annak a helynek a koordinátáit, ahol az eseményhez legközelebb biztonságosan lehet parkolni, belépni, vagy belépőt vásárolni /Segítségül: a google.maps honlapon: 1. jobb egér gombbal kattintás a kívánt helyre, 2. "mi található itt?" lehetőség kiválasztása a lenyíló menüből, 3. az oldal alján megjelenik a kártya a koordinátákkal (alsó sorban) - ezt az adatsor kérjük ide beírni.',

      formId: 'ATTRACTION_DATA',

      formFields: {
        'name': '',
        'placeName': null,
        'videoLink': null,
        'hasRelatedAttractions': 0,
        'relatedAttractions/id': [],
        'group/id': null,
        'category/id': null,
        'labels/id': [],
        'featuredDestinations/id': [],
        'targetGroups/id': [],
        'journeyTypes/id': [],
        'seasonalities/id': [],
        'siteNatures/id': [],
        'accessibilities/id': [],
        'classification': null,
        'visitorCount': null,
        'opening/seasonality': 1,
        'opening/seasonalityStartDate': null,
        'opening/seasonalityEndDate': null,
        'opening/seasonalityStartTime': null,
        'opening/seasonalityEndTime': null,
        'address/zipCode/id': null,
        'address/city/id': null,
        'address/address': '',
        'address/addressNotRelevant': 0,
        'address/addressType': '',
        'address/addressTypeNotRelevant': 0,
        'address/addressNumber': '',
        'address/addressNumberNotRelevant': 0,
        'address/lat': '',
        'address/long': '',
        'website/url': '',
        'website/notRelevant': 0,
        'website/type': 'web',
        'website/languages/id': [],
        'website/comment': '',
        'facebook/url': '',
        'facebook/notRelevant': 0,
        'facebook/type': 'facebook',
        'instagram/url': '',
        'instagram/notRelevant': 0,
        'instagram/type': 'instagram',
        'openingYear': null,
        'organizationAddress/name': '',
        'organizationAddress/zipCode/id': null,
        'organizationAddress/city/id': null,
        'organizationAddress/address': '',
        'organizationAddress/addressType': '',
        'organizationAddress/addressNumber': '',
        'organizationAddress/addressNumberNotRelevant': 0,
        'centralContact/phone': '',
        'centralContact/email': '',
        'centralContact/type': 'central',
        'leaderContact/name': '',
        'leaderContact/title': '',
        'leaderContact/phone': '',
        'leaderContact/type': 'leader',
        'generalContact/name': '',
        'generalContact/title': '',
        'generalContact/email': '',
        'generalContact/phone': '',
        'generalContact/type': 'general',
        'comment': '',
        'webPublished': 0,
        'mobilePublished': 0
      },

      casts: {
        'address/addressNotRelevant': 'int',
        'address/addressTypeNotRelevant': 'int',
        'address/addressNumberNotRelevant': 'int',
        'address/lat': '?float',
        'address/long': '?float',
        'website/notRelevant': 'int',
        'facebook/notRelevant': 'int',
        'instagram/notRelevant': 'int',
        'organizationAddress/addressNumberNotRelevant': 'int',
        'openingYear': 'int',
        'webPublished': 'int',
        'mobilePublished': 'int'
      },

      options: {
        'relatedAttractions/id': [],
        'group/id': [],
        'category/id': [],
        'labels/id': [],
        'featuredDestinations/id': [],
        'targetGroups/id': [],
        'journeyTypes/id': [],
        'seasonalities/id': [],
        'siteNatures/id': [],
        'accessibilities/id': [],
        'address/zipCode/id': [],
        'address/city/id': [],
        'website/languages/id': [],
        'organizationAddress/zipCode/id': [],
        'organizationAddress/city/id': [],
      },

      translatedFormFields: {
        name: {}
      },

      ignoredFields: [
        'hasRelatedAttractions'
      ],

      characterCounts: {
        'name': {
          max: 200
        },
        'placeName': {
          max: 200
        },
        'videoLink': {
          max: 200
        },
        'address/address': {
          max: 200
        },
        'address/addressType': {
          max: 50
        },
        'address/addressNumber': {
          max: 50
        },
        'organizationAddress/name': {
          max: 200
        },
        'organizationAddress/address': {
          max: 200
        },
        'organizationAddress/addressType': {
          max: 50
        },
        'organizationAddress/addressNumber': {
          max: 50
        },
        'centralContact/email': {
          max: 200
        },
        'leaderContact/name': {
          max: 200
        },
        'leaderContact/title': {
          max: 200
        },
        'generalContact/name': {
          max: 200
        },
        'generalContact/title': {
          max: 200
        },
        'generalContact/email': {
          max: 200
        },
      },

      modals: {
        targetAudience: false,
        classification: false,
      },

      dateFields: {
        'opening/seasonalityStartDate': 'YYYY.MM.DD',
        'opening/seasonalityEndDate': 'YYYY.MM.DD',
        'opening/seasonalityStartTime': 'HH:mm',
        'opening/seasonalityEndTime': 'HH:mm'
      },

      lastAttractionSearchName: null
    }
  },

  methods: {
    /**
     * Auto-complete attraction list from API
     *
     * @param {string} name
     * @param {string} attractionField
     *
     * Throttled: 250ms when code is not empty
     */
    throttledAttractionAutoComplete: debounce(function (name, attractionField) {
      this.attractionAutoComplete(name, attractionField);
    }, 250),

    /**
     * Auto-complete attraction list from API
     *
     * @param {string} name
     * @param {string} attractionField
     *
     */
    attractionAutoComplete: function (name, attractionField) {
      let endpoint;

      switch (attractionField) {
        case 'relatedAttractions/id':
        default:
          endpoint = '/api/v1/companies/1/me/attractions';

          if (this.isOfficeEmployee) {
            endpoint += '/by-office';
          } else if (this.isOfficeSpecialist) {
            endpoint += '/by-areas';
          }

          break;
      }

      this.options[attractionField] = [];
      this.loading[attractionField] = true;

      axios.get(endpoint, {
        params: {
          locale: this.$store.state.attraction.locale,
          filter: {
            'name': (null !== name && name) ? ('*' + name + '*') : null,
            'group/groupType': 'attraction'
          },
          order: 'name ASC',
          fields: [
            'id',
            'name',
            'address/zipCode/id',
            'address/zipCode/code',
            'address/city/id',
            'address/city/name',
            'address/address',
            'address/addressType',
            'address/addressNumber',
            'address/addressNumberNotRelevant',
            'address/lat',
            'address/long',
          ].join(','),
          limit: 25
        }
      }).then(response => {
        this.options[attractionField] = response.data;
        this.loading[attractionField] = false;
      }).catch(() => {
        this.$message.error(
            'A vonzerők lekérdezés sikertelen!'
        );
      });
    },

    /**
     * @param {boolean} state
     */
    toggleRelatedAttraction: function (state) {
      if (!state) {
        this.$nextTick(() => {
          this.form.setFieldsValue({
            'relatedAttractions/id': []
          });
        });
      }
    },

    /**
     *
     * Load attraction details
     *
     * @param {int} relatedAttractionIds
     * @param {string} attractionField
     */
    fillAttractionDetails: function (relatedAttractionIds, attractionField) {
      if (typeof relatedAttractionIds != 'object' || typeof relatedAttractionIds[0] === 'undefined') {
        return;
      }

      if (relatedAttractionIds.length !== 1) {
        return;
      }

      this.options[attractionField].forEach((attraction) => {
        if (attraction.id === parseInt(relatedAttractionIds)) {
          let fillFields = [
            'address/zipCode/id',
            'address/city/id',
            'address/city/name',
            'address/address',
            'address/addressNotRelevant',
            'address/addressType',
            'address/addressTypeNotRelevant',
            'address/addressNumber',
            'address/addressNumberNotRelevant',
            'address/lat',
            'address/long',
          ];

          let populateData = {};

          fillFields.forEach((field) => {
            let value = this.findValue(field, attraction);

            if (value) {
              populateData[field] = value;
            }
          });

          this.options['address/zipCode/id'] = [this.findValue('address/zipCode', attraction)];
          this.options['address/city/id'] = [this.findValue('address/city', attraction)];

          this.$nextTick(() => {
            this.form.setFieldsValue(populateData);
          });
        }
      });
    },

    /**
     * @param currentDate
     * @return {boolean}
     */
    openingRangeEndDisabled(currentDate) {
      const startDate = this.form.getFieldValue('opening/seasonalityStartDate');

      return startDate ? currentDate < startDate : false;
    },

    /**
     * @param currentDate
     * @return {boolean}
     */
    openingRangeStartDisabled(currentDate) {
      const endDate = this.form.getFieldValue('opening/seasonalityEndDate');

      return endDate ? currentDate > endDate : false;
    },

    /**
     * @return {Promise<[]>}
     */
    populateOptions() {
      let promises = [];

      if (this.formFields['relatedAttractions/id'].length > 0) {
        this.formFields['hasRelatedAttractions'] = 1;

        this.options['relatedAttractions/id'] = this.getAttraction().relatedAttractions;
      }

      // Load main groups
      promises.push(this.loadOptions('group/id', null, 1));

      if (this.formFields['group/id']) {
        // Load category by group
        promises.push(this.loadOptions('category/id', null, this.formFields['group/id']));
      }

      if (this.formFields['category/id']) {
        // Load labels by category
        promises.push(this.loadOptions('labels/id', null, this.formFields['category/id']));
      }

      // Fill options from the current attraction data
      this.options['featuredDestinations/id'] = this.getAttraction().featuredDestinations;
      this.options['targetGroups/id'] = this.getAttraction().targetGroups;
      this.options['journeyTypes/id'] = this.getAttraction().journeyTypes;
      this.options['seasonalities/id'] = this.getAttraction().seasonalities;
      this.options['siteNatures/id'] = this.getAttraction().siteNatures;
      this.options['accessibilities/id'] = this.getAttraction().accessibilities;

      // Fill the current zip code to the options
      if (this.getAttraction().address && this.getAttraction().address.zipCode) {
        this.options['address/zipCode/id'] = [this.getAttraction().address.zipCode];
        this.options['address/city/id'] = this.getAttraction().address.zipCode.cities;
      }

      if (this.getAttraction().organizationAddress && this.getAttraction().organizationAddress.zipCode) {
        this.options['organizationAddress/zipCode/id'] = [this.getAttraction().organizationAddress.zipCode];
        this.options['organizationAddress/city/id'] = this.getAttraction().organizationAddress.zipCode.cities;
      }

      // Fill foreign languages
      this.options['website/languages/id'] = this.languages;

      return Promise.all(promises);
    },
  }
}
</script>

<style scoped>
.ant-input-number {
  width: 120px;
}
</style>

<style>
button + button {
  margin-left: 10px;
}

.dynamic-delete-button {
  margin-left: 10px;
}
</style>
