var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-layout",
    { ref: "layout-container", style: { height: "calc(100vh - 64px)" } },
    [
      _vm.hungarikum
        ? _c(
            "a-layout-header",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.collapsed,
                  expression: "collapsed"
                }
              ],
              staticClass: "layout-content-sub-header"
            },
            [
              _c("div", { staticClass: "attraction-data" }, [
                _c("span", { staticClass: "attraction-name" }, [
                  _c("strong", [_vm._v(_vm._s(_vm.hungarikum.label))])
                ]),
                _c("span", { staticClass: "attraction-id" }, [
                  _vm._v("\n      Azonosító: "),
                  _c("strong", [_vm._v(_vm._s(_vm.hungarikum.id))])
                ]),
                _vm.hungarikum.address && _vm.hungarikum.address.city
                  ? _c("span", { staticClass: "attraction-city" }, [
                      _vm._v("\n            Település: "),
                      _c("strong", [
                        _vm._v(_vm._s(_vm.hungarikum.address.city.name))
                      ])
                    ])
                  : _vm._e(),
                _c("span", { staticClass: "attraction-status" }, [
                  _vm._v("\n      Státusz: "),
                  _c("strong", [
                    _vm._v(_vm._s(_vm.statusNames[_vm.hungarikum.status]))
                  ])
                ])
              ])
            ]
          )
        : _vm._e(),
      _c(
        "a-layout-sider",
        {
          style: { background: "transparent" },
          attrs: { width: 300, collapsible: true },
          model: {
            value: _vm.collapsed,
            callback: function($$v) {
              _vm.collapsed = $$v
            },
            expression: "collapsed"
          }
        },
        [
          _c("NetaHungarikumDetailsMenuComponent", {
            staticClass: "attraction-menu-holder",
            attrs: { collapsed: _vm.collapsed, "status-names": _vm.statusNames }
          })
        ],
        1
      ),
      _c(
        "a-layout-content",
        { ref: "layout-content-container", staticClass: "app-details-content" },
        [
          _c(
            "transition",
            { attrs: { name: "slide-fade-vertical", mode: "out-in" } },
            [_c("router-view", { ref: "app-layout-content" })],
            1
          )
        ],
        1
      ),
      _c("a-button", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.scrolled,
            expression: "scrolled"
          }
        ],
        staticClass: "scroll-to-top",
        attrs: { size: "large", icon: "up", type: "primary" },
        on: { click: _vm.scrollTop }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }