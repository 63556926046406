import {
    SET_NAVIGATION_PROGRESS_STATE
} from '@/mutation_types.js';

const state = {
    navigationInProgress: false
}

const getters = {
    /**
     * @param state
     * @returns {boolean}
     */
    getNavigationInProgress(state) {
        return state.navigationInProgress;
    }
}

const mutations = {
    [SET_NAVIGATION_PROGRESS_STATE](state, payload) {
        state.navigationInProgress = payload.navigationProgress;
    }
}

const actions = {}

export default {
    state,
    getters,
    mutations,
    actions
}