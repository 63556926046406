<template>
  <a-config-provider :locale="hu_HU">
    <div id="app">
      <router-view></router-view>
    </div>
  </a-config-provider>
</template>

<script>
import hu_HU from 'ant-design-vue/lib/locale-provider/hu_HU'

export default {
  data() {
    return {
      hu_HU
    }
  },

}

</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,500,700&subset=latin-ext');


//  GLOBAL RULES
body {
  font-family: 'Montserrat', sans-serif;
}

h1 {
  @media screen and (max-width: 800px) {
    font-size: 18px;
  }
}

h2 {
  @media screen and (max-width: 800px) {
    font-size: 17px;
  }
}

.ant-table-filter-dropdown-btns {
  a {
    font-size: 12px
  }
}

@media screen and (max-width: 800px) {
  .ant-divider-horizontal.ant-divider-with-text[class],
  .ant-divider-horizontal.ant-divider-with-text-left[class],
  .ant-divider-horizontal.ant-divider-with-text-right[class] {
    white-space: normal;
    text-align: left;
    font-size: 13px;
    display: block;
    height: auto;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.ant-form-item-label[class] {
  white-space: normal;
}

.app-form-container {
  max-width: 800px;
}

.required-asterisk {
  font-family: SimSun;
  color: $secondary-red;
}

.ant-calendar-picker.datatable-range-picker {
  span.ant-calendar-picker-input {
    height: 100%;

    span.ant-calendar-range-picker-separator {
      display: none;
    }

    input.ant-calendar-range-picker-input {
      width: 100%;
      text-align: left;
      display: block;
    }

  }
}

::-webkit-scrollbar {
  width: 16px;
  height: 8px;
  background: #EEE
}

::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: #CCC;
}

.ant-calendar-picker,
.ant-time-picker {
  max-width: 340px;
}

.sentry-error-embed {
  #id_name, #id_email, #id_comments {
    &::placeholder {
      color: #FBFBFC;
    }
  }

  .form-submit {
    .powered-by {
      display: none;
    }

    .btn {
      background-color: #38BA9B !important;
      border-color: #38BA9B !important;
    }
  }
}

</style>
