import Vue from 'vue'
import { DELETE_DRAFT, SAVE_DRAFT, SET_LOADING } from "@/mutation_types";

const state = {
    drafts: {
        attraction: {
            ATTRACTION_DATA: {},
            DESCRIPTION: {},
            ACCESSIBILITY: {},
            FOREIGN_LANGUAGES: {},
            ATTRACTION_LOCATION: {},
            OPENING: {},
            PAYMENT_RESERVATION: {},
            PRICES: {},
        },
        office: {
            OFFICE: {}
        },
        hungarikum: {
            DATA: {}
        }
    },

    loading: {
        attraction: {
            ATTRACTION_DATA: {},
            DESCRIPTION: {},
            ACCESSIBILITY: {},
            FOREIGN_LANGUAGES: {},
            ATTRACTION_LOCATION: {},
            OPENING: {},
            PAYMENT_RESERVATION: {},
            PRICES: {},
        },
        office: {
            OFFICE: {}
        },
        hungarikum: {
            DATA: {}
        }
    }
};

const getters = {
    /**
     * @param state
     * @returns {function(*): *}
     */
    getDraft: (state) => (model, id, formId) => {
        return state.drafts[model][formId][id];
    },

    /**
     * @param state
     * @returns {function(*): *}
     */
    isLoading: (state) => (model, id, formId) => {
        return state.loading[model][formId][id];
    },
};


const mutations = {
    [SET_LOADING](state, payload) {
        Vue.set(state.loading[payload.model][payload.formId], payload.id, !!payload.loading);
    },

    [SAVE_DRAFT](state, payload) {
        Vue.set(state.drafts[payload.model][payload.formId], payload.id, payload.data);
    },

    [DELETE_DRAFT](state, payload) {
        Vue.set(state.drafts[payload.model][payload.formId], payload.id, null);
    },
};

const actions = {};

export default {
    state,
    getters,
    mutations,
    actions
}