<template xmlns="">
  <div class="app-form-container">
    <a-spin :spinning="formDataLoading">
      <a-form :form="form" v-on:submit="submit" layout="vertical">
        <a-form-item>
          <a-button type="primary" html-type="submit" :loading="submitLoading" v-if="showSaveButton && !isMobileReadonly">
            Mentés
          </a-button>

          <a-button type="secondary" html-type="button" v-on:click="resetForm" v-if="hasChanged()">
            <a-icon type="undo"
                    theme="outlined" />

            Piszkozat törlése
          </a-button>

          <span v-if="currentTab" class="last-modification">
            Utolsó mentés: <strong>{{ moment(currentTab.modificationTime).format(timeStampFormat).toString() }}</strong> &nbsp;<span v-if="currentTab.user">({{ currentTab.user.userName }})</span>
          </span>

          <a-alert
              :style="{
                        marginTop: '10px'
                    }"
              v-if="submitResult.show"
              :message="submitResult.message"
              :type="submitResult.type"
              showIcon
              closable
              :afterClose="() => submitResult.show = false"
          />
        </a-form-item>

        <a-divider orientation="left">
          BESZÉLT NYELVEK, NYELVISMERET
        </a-divider>

        <template
            v-for="type in types"
        >
          <a-form-item
              :key="type.fieldPrefix + '-form-item'"
          >
            <span slot="label">
                {{ type.label }}

                <a-tooltip title="Ha nem releváns a kérdés, kérjük ne válasszon ki nyelvet.">
                    <a-icon type="question-circle-o" />
                </a-tooltip>
            </span>

            <a-form-item label="Nyelvek">
              <a-select
                  :getPopupContainer="getParentTriggerNode()"
                  mode="multiple"
                  :disabled="isMobileReadonly || isChecked(type.fieldPrefix + '/languagesNotRelevant')"
                  v-decorator="[
                                    type.fieldPrefix + '/languages/id',
                                    {
                                        rules: [{
                                            required: isNotMobile && type.required && !isChecked(type.fieldPrefix + '/languagesNotRelevant'),
                                            message: 'Kérjük töltse ki!'
                                        }]
                                    }
                                ]"
                  :filterOption="filterSelectNames"
                  placeholder="Kérjük válasszon!"
              >
                <a-select-option
                    v-for="language in languages"
                    :key="language.name"
                    :value="language.id"
                >
                  {{ language.name.toLowerCase() }}
                </a-select-option>
              </a-select>

              <a-checkbox
                  :disabled="isMobileReadonly"
                  v-show="type.notRelevant"
                  v-decorator="[
                                    type.fieldPrefix + '/languagesNotRelevant',
                                    {
                                        rules: [{
                                            required: false,
                                            message: 'Kérjük töltse ki!',
                                        }]
                                    }
                                ]"
                  :checked="isChecked(type.fieldPrefix + '/languagesNotRelevant')"
                  v-on:change="(event) => relevancyCheckboxChange(type.fieldPrefix + '/languages/id')"
              >
                Nem beszélnek idegen nyelven
              </a-checkbox>
            </a-form-item>

            <a-form-item v-if="isNotMobile"
                :selfUpdate="true"
                label="Ha egyéb nyelv is van, kérjük ide írja"
            >
              <a-textarea
                  :maxLength="25000"
                  :disabled="isMobileReadonly || isChecked(type.fieldPrefix + '/languagesNotRelevant')"
                  v-decorator="[
                                    type.fieldPrefix + '/otherLanguages',
                                    {
                                        rules: [{
                                            required: false,
                                            message: 'Kérjük töltse ki',
                                        }]
                                    }
                                ]"
                  :autoSize="{minRows: 2}"
              />
            </a-form-item>
          </a-form-item>

          <a-divider :key="type.fieldPrefix + '-divider'" />
        </template>

        <a-form-item v-if="isNotMobile" label="Megjegyzés">
          <a-textarea
              :disabled="isMobileReadonly"
              :maxLength="25000"
              v-decorator="[
                        'foreignComment',
                        {
                            rules: [{
                                required: false,
                                message: 'Kérjük töltse ki!'
                            }]
                        }
                    ]"
              :autosize="{minRows: 2}"
          />
        </a-form-item>

        <a-form-item>
          <a-button type="primary" html-type="submit" :loading="submitLoading" v-if="showSaveButton && !isMobileReadonly">
            Mentés
          </a-button>

          <a-button type="secondary" html-type="button" v-on:click="resetForm" v-if="hasChanged()">
            <a-icon
                type="undo"
                theme="outlined"
            />

            Piszkozat törlése
          </a-button>

          <span v-if="currentTab" class="last-modification">
            Utolsó mentés: <strong>{{ moment(currentTab.modificationTime).format(timeStampFormat).toString() }}</strong> &nbsp;<span v-if="currentTab.user">({{ currentTab.user.userName }})</span>
          </span>

          <a-alert
              :style="{
                        marginTop: '10px'
                    }"
              v-if="submitResult.show"
              :message="submitResult.message"
              :type="submitResult.type"
              showIcon
              closable
              :afterClose="() => submitResult.show = false"
          />
        </a-form-item>
      </a-form>
    </a-spin>
  </div>
</template>

<script>
import { EditFormComponentMixin } from "@/mixins/EditFormComponentMixin";

export default {
  menuName: 'Idegen nyelvű szolg., nyelvism.',
  name: 'foreign-languages-form',
  mixins: [EditFormComponentMixin],

  data() {
    return {
      formId: 'FOREIGN_LANGUAGES',

      formFields: {
        'foreignInscriptions/languages/id': [],
        'foreignInscriptions/otherLanguages': '',
        'foreignInscriptions/type': 'inscriptions',
        'foreignSpoken/languages/id': [],
        'foreignSpoken/otherLanguages': '',
        'foreignSpoken/languagesNotRelevant': '',
        'foreignSpoken/type': 'spoken',
        'foreignGuide/languages/id': [],
        'foreignGuide/otherLanguages': '',
        'foreignGuide/type': 'guide',
        'foreignWellness/languages/id': [],
        'foreignWellness/otherLanguages': '',
        'foreignWellness/type': 'wellness',
        'foreignCateringInscriptions/languages/id': [],
        'foreignCateringInscriptions/otherLanguages': '',
        'foreignCateringInscriptions/type': 'cateringInscriptions',
        'foreignCateringSpoken/languages/id': [],
        'foreignCateringSpoken/otherLanguages': '',
        'foreignCateringSpoken/type': 'cateringSpoken',
        'foreignCateringMenu/languages/id': [],
        'foreignCateringMenu/otherLanguages': '',
        'foreignCateringMenu/type': 'cateringMenu',
        'foreignShopInscriptions/languages/id': [],
        'foreignShopInscriptions/otherLanguages': '',
        'foreignShopInscriptions/type': 'shopInscriptions',
        'foreignShopSpoken/languages/id': [],
        'foreignShopSpoken/otherLanguages': '',
        'foreignShopSpoken/type': 'shopSpoken',
        'foreignShopPublications/languages/id': [],
        'foreignShopPublications/otherLanguages': '',
        'foreignShopPublications/type': 'shopPublications',
        'foreignComment': ''
      },

      casts: {
        'foreignSpoken/languagesNotRelevant': 'int',
      },

      types: [
        {
          label: 'Jegypénztárban kihelyezett feliratok és a jegy vásárláskor a látogatókat tájékoztató feliratok nyelvezete',
          fieldPrefix: 'foreignInscriptions',
          required: false
        },
        {
          label: 'Beszélt nyelvek (amin pl. a pénztáros értékesíteni, információt  nyújtani tud)',
          fieldPrefix: 'foreignSpoken',
          required: true,
          notRelevant: true
        },
        {
          label: 'Idegenvezetés / tárlatvezetés nyelvezete',
          fieldPrefix: 'foreignGuide',
          required: false
        },
        {
          label: 'Gyógy- és/vagy wellness szolgáltatás nyelvezete',
          fieldPrefix: 'foreignWellness',
          required: false
        },
        {
          label: 'Ha a vonzerőn belül, illetve annak közvetlen környezetében üzemel vendéglátó egység, akkor az ott található feliratok nyelvezete',
          fieldPrefix: 'foreignCateringInscriptions',
          required: false
        },
        {
          label: 'Ha a vonzerőn belül, illetve annak közvetlen környezetében üzemel vendéglátó egység, akkor a beszélt nyelvek (amin a személyzet értékesíteni tud, információt tud nyújtani)',
          fieldPrefix: 'foreignCateringSpoken',
          required: false
        },
        {
          label: 'Ha a vonzerőn belül, illetve annak közvetlen környezetében üzemel vendéglátó egység, akkor az étlap nyelvezete',
          fieldPrefix: 'foreignCateringMenu',
          required: false
        },
        {
          label: 'Ha a vonzerőn belül, illetve annak közvetlen környezetében üzemel ajándékbolt / shop, akkor feliratok nyelvezete',
          fieldPrefix: 'foreignShopInscriptions',
          required: false
        },
        {
          label: 'Ha a vonzerőn belül, illetve annak közvetlen környezetében üzemel ajándékbolt / shop, akkor a beszélt nyelvek (amin a személyzet értékesíteni tud, információt tud nyújtani)',
          fieldPrefix: 'foreignShopSpoken',
          required: false
        },
        {
          label: 'Ha a vonzerőn belül, illetve annak közvetlen környezetében üzemel ajándékbolt / shop, akkor a megvásárolható kiadványok nyelvezete',
          fieldPrefix: 'foreignShopPublications',
          required: false
        }
      ]
    }
  },

  methods: {}
}
</script>

<style>
</style>
