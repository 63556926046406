<template>
  <div class="tmr-export-container">
    <h1 class="tmr-export-title">
      Iroda Export
    </h1>
    <div class="tourinform-form-container">
      <a-form
          :form="form"
          layout="vertical"
          v-on:submit="submit"
      >

        <a-form-item label="Iroda">
          <a-select
              mode="multiple"
              v-decorator="[
                            'id',
                            {
                                rules: [
                                    {
                                    required: !isChecked('globalExport'),
                                    whitespace: true,
                                    message: 'Kérjük töltse ki!',
                                    type: 'array'
                                  },
                                ],
                                validateTrigger: ['blur','keyup']
                            }
                        ]"
              placeholder="Kezdjen gépelni"
              showSearch
              :disabled="isChecked('globalExport')"
              :showArrow="false"
              :defaultActiveFirstOption="false"
              :filterOption="false"
              :style="{width: '100%'}"
              v-on:search="officeAutoComplete"
              v-on:dropdownVisibleChange="officeAutoComplete"
              :notFoundContent="officeOptionsLoading ? undefined : null"
          >
            <a-spin v-if="officeOptionsLoading" slot="notFoundContent" size="small" />

            <a-select-option
                v-for="option in officeOptions"
                :key="option.id"
                :value="option.id"
            >
              {{ option.name }}
            </a-select-option>
          </a-select>

          <a-checkbox
              v-decorator="['globalExport']"
              v-on:change="(event) => exportTypeChange('id')"
          >
            Összes iroda
          </a-checkbox>
        </a-form-item>

        <a-form-item>
          <a-button
              type="primary"
              html-type="submit"
              :loading="submitLoading"
          >
            Export letöltése
          </a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';
import axios from "axios";

export default {
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },

  data() {
    return {
      submitLoading: false,

      officeOptions: [],
      officeOptionsLoading: false
    }
  },

  methods: {
    submit(event) {
      event.preventDefault();

      this.form.validateFieldsAndScroll(
          (errors, values) => {
            if (errors) {
              this.$message.warning(
                  'Kérem válasszon irodá(ka)t!'
              );

              return false;
            }

            this.submitLoading = true;

            this.$message.loading({ content: 'Iroda export készítése', key: 'OFFICE-EXPORT', duration: 0 });

            let filters = {};

            let apiUrl = '/api/v1/companies/1/attractions/office/export';

            if (values.globalExport) {
              filters = {};
            } else {
              filters = {
                'id': values['id'].join(',')
              };
            }

            axios.get(
                apiUrl,
                {
                  headers: {
                    accept: '*/*'
                  },
                  responseType: 'blob',
                  params: {
                    filter: filters
                  }
                }
            ).then((response) => {
              this.submitLoading = false;

              this.$message.success({ content: 'Az iroda export elkészült!', key: 'OFFICE-EXPORT' });

              let fileName = 'Iroda-Export.xlsx';

              if (typeof response.headers['content-disposition'] !== 'undefined') {
                let matches = response.headers['content-disposition'].match(/filename="(.+)"/);

                if (matches[1]) {
                  fileName = matches[1];
                }
              }

              this.downloadBlob(
                  response.data,
                  fileName,
                  'application/xlsx'
              );
            }).catch(() => {
              this.submitLoading = false;

              this.$message.error({ content: 'Hiba az iroda export közben!', key: 'OFFICE-EXPORT' });
            });
          }
      );
    },

    /**
     * @param {blob} data
     * @param {string} fileName
     * @param {string} type
     */
    downloadBlob(data, fileName, type) {
      const blob = new Blob([data], { type: 'type' });
      const link = document.createElement('a');

      link.href = window.URL.createObjectURL(blob);
      link.setAttribute('download', fileName);

      document.body.appendChild(link);

      link.click();
      link.remove();
    },

    /**
     * @param {string} fieldName
     *
     * @return {boolean}
     */
    isChecked(fieldName) {
      return !!this.form.getFieldValue(fieldName);
    },

    /**
     * @param fieldName
     */
    exportTypeChange(fieldName) {
      this.$nextTick(() => {
        // if (this.isChecked(fieldName)) {
        //   let populateData = {};
        //
        //   populateData[fieldName] = [];
        //
        //   this.form.setFieldsValue(populateData);
        // }

        this.form.validateFields(typeof fieldName === 'object' ? fieldName : [fieldName]);
      })
    },

    /**
     * Auto-complete office list from API
     *
     * @param {string} name
     *
     * Throttled: 250ms when code is not empty
     */
    officeAutoComplete: function (name) {
      var self = this;

      let apiUrl = '/api/v1/companies/1/attractions/office';

      debounce(function (name) {
        self.officeOptions = [];
        self.officeOptionsLoading = true;

        axios.get(apiUrl, {
          params: {
            locale: self.$store.state.attraction.locale,
            name: (true !== name && name) ? '*' + name + '*' : null,
            fields: 'id,name',
            order: 'name ASC',
            limit: 200,
            status: '!= 2'
          }
        }).then(response => {
          self.officeOptions = response.data;
          self.officeOptionsLoading = false;
        });
      }, name !== true ? 250 : 0)(name)
    },
  }
}

</script>

<style lang="scss" scoped>
.tmr-export-container {
  padding: 25px;
  max-width: 600px;
  margin: auto;
}
</style>