var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-form-container" },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.formDataLoading } },
        [
          _c(
            "a-form",
            {
              attrs: { form: _vm.form, layout: "vertical" },
              on: { submit: _vm.submit }
            },
            [
              _c(
                "a-form-item",
                [
                  _vm.showSaveButton && !_vm.isMobileReadonly
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            "html-type": "submit",
                            loading: _vm.submitLoading
                          }
                        },
                        [_vm._v("\n          Mentés\n        ")]
                      )
                    : _vm._e(),
                  _vm.hasChanged()
                    ? _c(
                        "a-button",
                        {
                          attrs: { type: "secondary", "html-type": "button" },
                          on: { click: _vm.resetForm }
                        },
                        [
                          _c("a-icon", {
                            attrs: { type: "undo", theme: "outlined" }
                          }),
                          _vm._v("\n\n          Piszkozat törlése\n        ")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.currentTab
                    ? _c("span", { staticClass: "last-modification" }, [
                        _vm._v("\n          Utolsó mentés: "),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm
                                .moment(_vm.currentTab.modificationTime)
                                .format(_vm.timeStampFormat)
                                .toString()
                            )
                          )
                        ]),
                        _vm._v("  "),
                        _vm.currentTab.user
                          ? _c("span", [
                              _vm._v(
                                "(" + _vm._s(_vm.currentTab.user.userName) + ")"
                              )
                            ])
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm.submitResult.show
                    ? _c("a-alert", {
                        style: {
                          marginTop: "10px"
                        },
                        attrs: {
                          message: _vm.submitResult.message,
                          type: _vm.submitResult.type,
                          showIcon: "",
                          closable: "",
                          afterClose: function() {
                            return (_vm.submitResult.show = false)
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c("a-divider", { attrs: { orientation: "left" } }, [
                _vm._v("\n        BESZÉLT NYELVEK, NYELVISMERET\n      ")
              ]),
              _vm._l(_vm.types, function(type) {
                return [
                  _c(
                    "a-form-item",
                    { key: type.fieldPrefix + "-form-item" },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "label" }, slot: "label" },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(type.label) +
                              "\n\n              "
                          ),
                          _c(
                            "a-tooltip",
                            {
                              attrs: {
                                title:
                                  "Ha nem releváns a kérdés, kérjük ne válasszon ki nyelvet."
                              }
                            },
                            [
                              _c("a-icon", {
                                attrs: { type: "question-circle-o" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "Nyelvek" } },
                        [
                          _c(
                            "a-select",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: [
                                    type.fieldPrefix + "/languages/id",
                                    {
                                      rules: [
                                        {
                                          required:
                                            _vm.isNotMobile &&
                                            type.required &&
                                            !_vm.isChecked(
                                              type.fieldPrefix +
                                                "/languagesNotRelevant"
                                            ),
                                          message: "Kérjük töltse ki!"
                                        }
                                      ]
                                    }
                                  ],
                                  expression:
                                    "[\n                                  type.fieldPrefix + '/languages/id',\n                                  {\n                                      rules: [{\n                                          required: isNotMobile && type.required && !isChecked(type.fieldPrefix + '/languagesNotRelevant'),\n                                          message: 'Kérjük töltse ki!'\n                                      }]\n                                  }\n                              ]"
                                }
                              ],
                              attrs: {
                                getPopupContainer: _vm.getParentTriggerNode(),
                                mode: "multiple",
                                disabled:
                                  _vm.isMobileReadonly ||
                                  _vm.isChecked(
                                    type.fieldPrefix + "/languagesNotRelevant"
                                  ),
                                filterOption: _vm.filterSelectNames,
                                placeholder: "Kérjük válasszon!"
                              }
                            },
                            _vm._l(_vm.languages, function(language) {
                              return _c(
                                "a-select-option",
                                {
                                  key: language.name,
                                  attrs: { value: language.id }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(language.name.toLowerCase()) +
                                      "\n              "
                                  )
                                ]
                              )
                            }),
                            1
                          ),
                          _c(
                            "a-checkbox",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: type.notRelevant,
                                  expression: "type.notRelevant"
                                },
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: [
                                    type.fieldPrefix + "/languagesNotRelevant",
                                    {
                                      rules: [
                                        {
                                          required: false,
                                          message: "Kérjük töltse ki!"
                                        }
                                      ]
                                    }
                                  ],
                                  expression:
                                    "[\n                                  type.fieldPrefix + '/languagesNotRelevant',\n                                  {\n                                      rules: [{\n                                          required: false,\n                                          message: 'Kérjük töltse ki!',\n                                      }]\n                                  }\n                              ]"
                                }
                              ],
                              attrs: {
                                disabled: _vm.isMobileReadonly,
                                checked: _vm.isChecked(
                                  type.fieldPrefix + "/languagesNotRelevant"
                                )
                              },
                              on: {
                                change: function(event) {
                                  return _vm.relevancyCheckboxChange(
                                    type.fieldPrefix + "/languages/id"
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              Nem beszélnek idegen nyelven\n            "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm.isNotMobile
                        ? _c(
                            "a-form-item",
                            {
                              attrs: {
                                selfUpdate: true,
                                label: "Ha egyéb nyelv is van, kérjük ide írja"
                              }
                            },
                            [
                              _c("a-textarea", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      type.fieldPrefix + "/otherLanguages",
                                      {
                                        rules: [
                                          {
                                            required: false,
                                            message: "Kérjük töltse ki"
                                          }
                                        ]
                                      }
                                    ],
                                    expression:
                                      "[\n                                  type.fieldPrefix + '/otherLanguages',\n                                  {\n                                      rules: [{\n                                          required: false,\n                                          message: 'Kérjük töltse ki',\n                                      }]\n                                  }\n                              ]"
                                  }
                                ],
                                attrs: {
                                  maxLength: 25000,
                                  disabled:
                                    _vm.isMobileReadonly ||
                                    _vm.isChecked(
                                      type.fieldPrefix + "/languagesNotRelevant"
                                    ),
                                  autoSize: { minRows: 2 }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("a-divider", { key: type.fieldPrefix + "-divider" })
                ]
              }),
              _vm.isNotMobile
                ? _c(
                    "a-form-item",
                    { attrs: { label: "Megjegyzés" } },
                    [
                      _c("a-textarea", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "foreignComment",
                              {
                                rules: [
                                  {
                                    required: false,
                                    message: "Kérjük töltse ki!"
                                  }
                                ]
                              }
                            ],
                            expression:
                              "[\n                      'foreignComment',\n                      {\n                          rules: [{\n                              required: false,\n                              message: 'Kérjük töltse ki!'\n                          }]\n                      }\n                  ]"
                          }
                        ],
                        attrs: {
                          disabled: _vm.isMobileReadonly,
                          maxLength: 25000,
                          autosize: { minRows: 2 }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-item",
                [
                  _vm.showSaveButton && !_vm.isMobileReadonly
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            "html-type": "submit",
                            loading: _vm.submitLoading
                          }
                        },
                        [_vm._v("\n          Mentés\n        ")]
                      )
                    : _vm._e(),
                  _vm.hasChanged()
                    ? _c(
                        "a-button",
                        {
                          attrs: { type: "secondary", "html-type": "button" },
                          on: { click: _vm.resetForm }
                        },
                        [
                          _c("a-icon", {
                            attrs: { type: "undo", theme: "outlined" }
                          }),
                          _vm._v("\n\n          Piszkozat törlése\n        ")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.currentTab
                    ? _c("span", { staticClass: "last-modification" }, [
                        _vm._v("\n          Utolsó mentés: "),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm
                                .moment(_vm.currentTab.modificationTime)
                                .format(_vm.timeStampFormat)
                                .toString()
                            )
                          )
                        ]),
                        _vm._v("  "),
                        _vm.currentTab.user
                          ? _c("span", [
                              _vm._v(
                                "(" + _vm._s(_vm.currentTab.user.userName) + ")"
                              )
                            ])
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm.submitResult.show
                    ? _c("a-alert", {
                        style: {
                          marginTop: "10px"
                        },
                        attrs: {
                          message: _vm.submitResult.message,
                          type: _vm.submitResult.type,
                          showIcon: "",
                          closable: "",
                          afterClose: function() {
                            return (_vm.submitResult.show = false)
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }