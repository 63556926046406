<template>
  <div class="gallery-upload">
    <a-upload-dragger
        :showUploadList="false"
        :multiple="false"
        :action="apiUrl"
        :customRequest="handleUpload"
        accept="image/*"
    >
      <p class="ant-upload-drag-icon">
        <a-icon type="inbox" />
      </p>

      <p class="ant-upload-text">
        Kattintson vagy húzza ide a képet
      </p>

      <p class="ant-upload-hint">
        (Javasolt minimum képfelbontás: 1920x1080px, minimum fájlméret: 100 KB, maximum fájlméret: 5 MB.)
      </p>
    </a-upload-dragger>

    <div class="uploaded-list" v-if="images.length > 0">
      <div class="uploaded-files-title">
        Feltöltött képek:
      </div>

      <draggable class="draggable-holder" v-on:end="updateWeight">
        <div class="list-elem" v-for="image in images" :key="image.id" :data-id="image.id">
          <div class="list-elem-display">
            <img class="image-thumbnail" :src="getImageViewUrl(image)" />
          </div>

          <div class="list-elem-actions">
            <a-tooltip title="Előnézet">
              <a-icon v-on:click="openPreview(image)" type="eye" />
            </a-tooltip>

            <a-tooltip title="Kiválasztás" v-show="image.id != selectedImageId">
              <a-icon v-on:click="selectMainPicture(image)" type="check-circle" />
            </a-tooltip>

            <a-tooltip title="Törlés">
              <a-icon v-on:click="deleteImage(image)" type="delete" />
            </a-tooltip>
          </div>
        </div>
      </draggable>
    </div>

    <a-modal :visible="previewVisible" :footer="null" @cancel="closePreview">
      <img alt="Előnézet" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import axios from "axios";

export default {
  components: {
    draggable
  },

  props: {
    officeId: {
      type: Number,
      required: true
    },
    selectedImageId: {
      type: [Number, null],
      required: false
    },
    images: {
      type: Array,
      required: true
    },
    maxImages: {
      type: Number,
      default: 5
    }
  },

  computed: {
    apiUrl() {
      return '/api/v1/companies/1/me/attractions/offices/' + this.officeId + '/images';
    }
  },

  data() {
    return {
      previewVisible: false,
      previewImage: null
    }
  },

  methods: {
    getImageViewUrl(image, width) {
      if (!width) {
        width = 640;
      }

      return '/api/v1/companies/1/images/' + image.id + '/view/?width=' + width
    },

    closePreview() {
      this.previewVisible = false;
    },

    openPreview(image) {
      this.previewImage = this.getImageViewUrl(image);

      this.previewVisible = true;
    },

    /**
     * Upload image
     *
     * @param request
     * @return {boolean}
     */
    handleUpload(request) {
      const fileSizeMb = ((request.file.size / 1024) / 1024).toFixed(4);

      if (fileSizeMb > 5) {
        this.$message.warning('Túl nagy fájl, a mérete nagyobb mint 5MB!');

        return false;
      }

      const formData = new FormData();

      formData.append('file', request.file);

      this.$message.loading({ content: 'Kép feltöltése folyamatban..', key: 'IMAGE-UPLOAD', duration: 0 });

      axios.post(request.action, formData)
          .then(response => {
                if (response.data.isValid) {
                  this.$emit('pictureUploaded', response.data.resourceId);

                  this.$message.success({ content: 'Sikeres kép feltöltés!', key: 'IMAGE-UPLOAD' });
                } else {
                  this.$message.error({ content: 'Hiba a kép mentése közben!', key: 'IMAGE-UPLOAD' });
                }
              }
          ).catch(error => {
            this.$message.error({ content: 'Hiba a kép feltöltése közben!', key: 'IMAGE-UPLOAD' });
          }
      );

      return true;
    },

    /**
     * Emit pictureSelected event
     */
    selectMainPicture(image) {
      this.$emit('pictureSelected', image.id);
    },

    /**
     * Emit pictureSelected event
     */
    updateWeight(event) {
      let image = this.images[event.oldIndex];
      let targetImage = this.images[event.newIndex];

      axios.patch(this.apiUrl + '/' + image.id, {
        weight: targetImage.weight
      }).then(response => {
            if (response.data.isValid) {
              this.$emit('pictureMoved', image.id);
            } else {
              this.$message.error({ content: 'Hiba a képek rendezése közben!', key: 'IMAGE-MOVE' });
            }
          }
      ).catch(error => {
            this.$message.error({ content: 'Hiba a képek rendezése közben!', key: 'IMAGE-MOVE' });
          }
      );
    },

    /**
     * Delete image
     *
     * @param image
     */
    deleteImage(image) {
      this.$message.loading({ content: 'Kép törlése folyamatban..', key: 'IMAGE-DELETE', duration: 0 });

      axios.delete(this.apiUrl + '/' + image.id)
          .then(response => {
                if (response.data.isValid) {
                  this.$emit('pictureDeleted', image.id);

                  this.$message.success({ content: 'A kép törlése sikeres!', key: 'IMAGE-DELETE' });
                } else {
                  this.$message.error({ content: 'Hiba a kép törlése közben!', key: 'IMAGE-DELETE' });
                }
              }
          ).catch(error => {
            this.$message.error({ content: 'Hiba a kép törlése közben!', key: 'IMAGE-DELETE' });
          }
      );
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-error-holder {
  .error-message {
    color: red;
    font-weight: bold;
  }
}

.draggable-holder {
  display: flex;
  align-items: baseline;
}

.list-elem {
  display: inline-block;
  min-width: 155px;
  max-width: 155px;
  height: 100%;
  min-height: 100%;
  padding: 5px;
  border: 1px solid #80808054;
  border-radius: 5px;
  margin-right: 10px;

  .list-elem-display {
    display: flex;
    justify-content: center;
    align-items: center;

    .image-thumbnail {
      max-width: 100%;
      max-height: 100%;
      display: block;
    }
  }

  .list-elem-actions {
    margin-top: 5px;
    display: flex;
    justify-content: space-evenly;

    .anticon {
      cursor: pointer;
    }
  }
}

.ant-upload-hint {
  color: grey;
}
</style>