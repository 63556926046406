var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "neta-lists" },
    [
      _c(
        "div",
        { staticClass: "list-content" },
        [_c("keep-alive", [_c("router-view")], 1)],
        1
      ),
      _c("a-button", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.scrolled,
            expression: "scrolled"
          }
        ],
        staticClass: "scroll-to-top",
        attrs: { size: "large", icon: "up", type: "primary" },
        on: { click: _vm.scrollTop }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }