<template>
    <div class="neta-lists">
        <div class="list-menu">
            <a-menu v-model="currentList" mode="horizontal" :style="{background: 'transparent'}" align="center">
                <a-menu-item key="neta-list-attraction" v-if="canAddAttraction || isViewer || isMobileApp">
                    <a-icon type="tag" />
                    Vonzerők
                </a-menu-item>

                <a-menu-item key="neta-list-event" :disabled="false" v-if="canAddAttraction || isViewer || isMobileApp">
                    <a-icon type="calendar" />
                    Események
                </a-menu-item>

                <a-menu-item key="neta-list-multi-attraction" :disabled="false" v-if="canAddAttraction || isViewer">
                    <a-icon type="tags" />
                    Multi TOBok
                </a-menu-item>

                <a-menu-item key="neta-list-multi-event" :disabled="false" v-if="canAddAttraction || isViewer">
                    <a-icon type="calendar" />
                    Multi események
                </a-menu-item>

<!--                <a-menu-item key="neta-list-hungarikum" :disabled="false" v-if="isHungarikum || isAdmin || isViewer">-->
<!--                    <a-icon type="tags" />-->
<!--                    Hungarikumok-->
<!--                </a-menu-item>-->
            </a-menu>
        </div>

        <div class="list-content">
            <router-view />
        </div>
    </div>
</template>

<script>

import store from "@/store";
import { SET_NAVIGATION_PROGRESS_STATE } from "@/mutation_types";

export default {
    name: 'NetaListView',

    beforeRouteUpdate(to, from, next) {
        if (to.name !== 'neta-list') {
            next();

            return;
        }

        store.commit({
            type: SET_NAVIGATION_PROGRESS_STATE,
            navigationProgress: false
        })

        return false;
    },

    computed: {
        canAddAttraction() {
            return this.isAdmin || this.isOfficeEmployee || this.officeSpecialist;
        },

        isAdmin() {
            return this.$store.getters.hasRoleAvailable(['ROLE_MTU_ADMIN']);
        },

        isMobileApp() {
            return this.$store.getters.hasRoleAvailable(['ROLE_MOBILE_APP']);
        },

        isViewer() {
            return this.$store.getters.hasRoleAvailable(['ROLE_MTU_VIEWER']);
        },

        isOfficeEmployee() {
            return this.$store.getters.hasRoleAvailable(['ROLE_OFFICE_EMPLOYEE', 'ROLE_OFFICE_LEADER'])
        },

        isOfficeLeader() {
            return this.$store.getters.hasRoleAvailable(['ROLE_OFFICE_LEADER'])
        },

        isHungarikum() {
            return this.$store.getters.hasRoleAvailable(['ROLE_HUNGARIKUM'])
        },

        officeSpecialist() {
            return this.$store.getters.hasRoleAvailable(['ROLE_OFFICE_SPECIALIST'])
        },

        currentList: {
            get() {
                return [this.$route.name]
            },

            set(routeName) {
                this.$router.push({
                    name: routeName[0]
                });
            },
        }
    }

}
</script>

<style lang="scss" scoped>
.list-menu {
  margin-bottom: 10px;
}
</style>