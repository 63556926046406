var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        { staticClass: "password-reset-content-row" },
        [
          _c("a-col", { attrs: { xs: 1, sm: 4, md: 7 } }),
          _c("a-col", { attrs: { xs: 22, sm: 16, md: 10 } }, [
            _c(
              "div",
              { staticClass: "password-reset-card-container" },
              [
                _c(
                  "a-form",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.success,
                        expression: "!success"
                      }
                    ],
                    staticClass: "password-reset-form",
                    attrs: {
                      id: "password-reset-component-form",
                      form: _vm.form
                    },
                    on: { submit: _vm.submit }
                  },
                  [
                    _c("h1", [
                      _vm._v("\n            Új jelszó megadása\n          ")
                    ]),
                    _c(
                      "a-form-item",
                      [
                        _c(
                          "a-input",
                          {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "password",
                                  {
                                    rules: [
                                      // {
                                      //   required: true,
                                      //   message: 'Kérjük írja be az új jelszót!'
                                      // },
                                      {
                                        pattern: /(^(?=.*[A-ZÁÉÖÜŐÚÍ]).{1,}$)/g,
                                        message:
                                          "A jelszónak legalább egy nagy betűt kell tartalmaznia!"
                                      },
                                      {
                                        pattern: /(^(?=.*[a-záéöüőúí]).{1,}$)/,
                                        message:
                                          "A jelszónak legalább egy kis betűt kell tartalmaznia!"
                                      },
                                      {
                                        pattern: _vm.passwordExtraPattern,
                                        message:
                                          "A jelszónak legalább egy számot vagy írásjelet kell tartalmaznia!"
                                      },
                                      {
                                        min: 8,
                                        message:
                                          "A jelszónak legalább 8 karakterből kell állnia!"
                                      }
                                    ],
                                    validateTrigger: ["blur", "keyup"]
                                  }
                                ],
                                expression:
                                  "[\n                  'password',\n                  {\n                      rules: [\n                          // {\n                          //   required: true,\n                          //   message: 'Kérjük írja be az új jelszót!'\n                          // },\n                          {\n                            pattern: /(^(?=.*[A-ZÁÉÖÜŐÚÍ]).{1,}$)/g,\n                            message: 'A jelszónak legalább egy nagy betűt kell tartalmaznia!'\n                          },\n                          {\n                            pattern: /(^(?=.*[a-záéöüőúí]).{1,}$)/,\n                            message: 'A jelszónak legalább egy kis betűt kell tartalmaznia!'\n                          },\n                          {\n                            pattern: passwordExtraPattern,\n                            message: 'A jelszónak legalább egy számot vagy írásjelet kell tartalmaznia!'\n                          },\n                          {\n                            min: 8,\n                            message: 'A jelszónak legalább 8 karakterből kell állnia!'\n                          }\n                      ],\n                      validateTrigger: ['blur', 'keyup']\n                  }\n              ]"
                              }
                            ],
                            attrs: {
                              type: "password",
                              placeholder: "Új jelszó megadása"
                            }
                          },
                          [
                            _c("a-icon", {
                              staticStyle: { color: "rgba(0, 0, 0, 0.5)" },
                              attrs: { slot: "prefix", type: "lock" },
                              slot: "prefix"
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "a-form-item",
                      [
                        _c(
                          "a-input",
                          {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "passwordControl",
                                  {
                                    rules: [
                                      {
                                        required: true,
                                        message:
                                          "Kérjük ide is írja be ide új jelszót!"
                                      },
                                      {
                                        validator: _vm.passwordControlValidator
                                      }
                                    ]
                                  }
                                ],
                                expression:
                                  "[\n                  'passwordControl',\n                  {\n                      rules: [\n                          {\n                            required: true,\n                            message: 'Kérjük ide is írja be ide új jelszót!'\n                          },\n                          {\n                            validator: passwordControlValidator\n                          }\n                      ]\n                  }\n              ]"
                              }
                            ],
                            attrs: {
                              type: "password",
                              placeholder: "Új jelszó megerősítése"
                            }
                          },
                          [
                            _c("a-icon", {
                              staticStyle: { color: "rgba(0, 0, 0, 0.5)" },
                              attrs: { slot: "prefix", type: "lock" },
                              slot: "prefix"
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "a-form-item",
                      [
                        _c(
                          "a-button",
                          {
                            staticClass: "password-reset-form-button",
                            attrs: {
                              loading: _vm.loading,
                              type: "primary",
                              "html-type": "submit"
                            }
                          },
                          [
                            _vm._v(
                              "\n              Új jelszó megadása\n            "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.success,
                        expression: "success"
                      }
                    ],
                    staticClass: "success-page"
                  },
                  [
                    _c("h1", [
                      _vm._v(
                        "\n            Sikeres jelszó változtatás!\n          "
                      )
                    ]),
                    _c(
                      "p",
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "back-button",
                            attrs: { to: "/login" }
                          },
                          [
                            _c("a-button", { attrs: { type: "primary" } }, [
                              _vm._v(
                                "\n                Tovább a bejelentkezéshez\n              "
                              )
                            ])
                          ],
                          1
                        ),
                        _c("br"),
                        _c("br"),
                        _c("span", { staticClass: "info-span" }, [
                          _vm._v(
                            "\n              Információ és technikai segítség:"
                          ),
                          _c("br"),
                          _c(
                            "a",
                            { attrs: { href: "mailto:neta@mtu.gov.hu" } },
                            [_vm._v("neta@mtu.gov.hu")]
                          )
                        ])
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "back-button-holder" },
                  [
                    _c(
                      "router-link",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.success,
                            expression: "!success"
                          }
                        ],
                        staticClass: "back-button",
                        attrs: { to: "/login" }
                      },
                      [
                        _c("a", [
                          _vm._v(
                            "\n              Vissza a bejelentkezéshez\n            "
                          )
                        ])
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("a-col", { attrs: { xs: 1, sm: 4, md: 7 } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }