import Vue from "vue";
import {
    Layout,
    Button,
    Pagination,
    Menu,
    Row,
    Col,
    Form,
    Input,
    Icon,
    Checkbox,
    Table,
    Popover,
    Tooltip,
    ConfigProvider,
    Divider,
    Select,
    InputNumber,
    TimePicker,
    Dropdown,
    Drawer,
    Alert,
    Spin,
    Steps,
    Radio,
    Card,
    Progress,
    Popconfirm,
    Rate,
    DatePicker,
    Upload,
    Modal,
    Message,
    Tag,
    Switch
} from "ant-design-vue";

Vue.use(Pagination);
Vue.use(Button);
Vue.use(Layout);
Vue.use(Menu);
Vue.use(Row);
Vue.use(Col);
Vue.use(Form);
Vue.use(Input);
Vue.use(Icon);
Vue.use(Checkbox);
Vue.use(Table);
Vue.use(Popover);
Vue.use(Tooltip);
Vue.use(ConfigProvider);
Vue.use(Divider);
Vue.use(Select);
Vue.use(InputNumber);
Vue.use(TimePicker);
Vue.use(Dropdown);
Vue.use(Drawer);
Vue.use(Alert);
Vue.use(Spin);
Vue.use(Steps);
Vue.use(Radio);
Vue.use(Card);
Vue.use(Progress);
Vue.use(Popconfirm);
Vue.use(Rate);
Vue.use(DatePicker);
Vue.use(Upload);
Vue.use(Modal);
Vue.use(Tag);
Vue.use(Switch);

Vue.prototype.$message = Message;