var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        { staticClass: "welcome-content-row" },
        [
          _c("a-col", { attrs: { xs: 1, sm: 4, md: 7 } }),
          _c("a-col", { attrs: { xs: 22, sm: 16, md: 10 } }, [
            _c(
              "div",
              { staticClass: "welcome-card-container" },
              [_c("LoginCardComponent")],
              1
            )
          ]),
          _c("a-col", { attrs: { xs: 1, sm: 4, md: 7 } })
        ],
        1
      ),
      _vm._m(0)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "privacy-policy privacy-policy--extended" },
      [
        _c(
          "a",
          {
            attrs: {
              href: "/api/v1/companies/1/files/64/view",
              target: "_blank",
              title: "Adatkezelési tájékoztató (felhasználó)"
            }
          },
          [_vm._v("\n      Adatkezelési tájékoztató (felhasználó)\n    ")]
        ),
        _c(
          "a",
          {
            attrs: {
              href: "/api/v1/companies/1/files/62/view",
              target: "_blank",
              title: "Adatkezelési tájékoztató (látogató)"
            }
          },
          [_vm._v("\n      Adatkezelési tájékoztató (látogató)\n    ")]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }