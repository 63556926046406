import { render, staticRenderFns } from "./NetaHungarikumBasicDataComponent.vue?vue&type=template&id=39f9cc10&scoped=true&"
import script from "./NetaHungarikumBasicDataComponent.vue?vue&type=script&lang=js&"
export * from "./NetaHungarikumBasicDataComponent.vue?vue&type=script&lang=js&"
import style0 from "./NetaHungarikumBasicDataComponent.vue?vue&type=style&index=0&lang=css&"
import style1 from "./NetaHungarikumBasicDataComponent.vue?vue&type=style&index=1&id=39f9cc10&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39f9cc10",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/neta-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('39f9cc10')) {
      api.createRecord('39f9cc10', component.options)
    } else {
      api.reload('39f9cc10', component.options)
    }
    module.hot.accept("./NetaHungarikumBasicDataComponent.vue?vue&type=template&id=39f9cc10&scoped=true&", function () {
      api.rerender('39f9cc10', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/neta/neta-hungarikum/basic-data/NetaHungarikumBasicDataComponent.vue"
export default component.exports