var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gallery-upload" },
    [
      _c(
        "a-upload-dragger",
        {
          attrs: {
            showUploadList: false,
            multiple: false,
            action: _vm.apiUrl,
            customRequest: _vm.handleUpload,
            accept: "image/*"
          }
        },
        [
          _c(
            "p",
            { staticClass: "ant-upload-drag-icon" },
            [_c("a-icon", { attrs: { type: "inbox" } })],
            1
          ),
          _c("p", { staticClass: "ant-upload-text" }, [
            _vm._v("\n      Kattintson vagy húzza ide a képet\n    ")
          ]),
          _c("p", { staticClass: "ant-upload-hint" }, [
            _vm._v(
              "\n      (Javasolt minimum képfelbontás: 1920x1080px, minimum fájlméret: 100 KB, maximum fájlméret: 5 MB.)\n    "
            )
          ])
        ]
      ),
      _vm.images.length > 0
        ? _c(
            "div",
            { staticClass: "uploaded-list" },
            [
              _c("div", { staticClass: "uploaded-files-title" }, [
                _vm._v("\n      Feltöltött képek:\n    ")
              ]),
              _c(
                "draggable",
                {
                  staticClass: "draggable-holder",
                  on: { end: _vm.updateWeight }
                },
                _vm._l(_vm.images, function(image) {
                  return _c(
                    "div",
                    {
                      key: image.id,
                      staticClass: "list-elem",
                      attrs: { "data-id": image.id }
                    },
                    [
                      _c("div", { staticClass: "list-elem-display" }, [
                        _c("img", {
                          staticClass: "image-thumbnail",
                          attrs: { src: _vm.getImageViewUrl(image) }
                        })
                      ]),
                      _c(
                        "div",
                        { staticClass: "list-elem-actions" },
                        [
                          _c(
                            "a-tooltip",
                            { attrs: { title: "Előnézet" } },
                            [
                              _c("a-icon", {
                                attrs: { type: "eye" },
                                on: {
                                  click: function($event) {
                                    return _vm.openPreview(image)
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-tooltip",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: image.id != _vm.selectedImageId,
                                  expression: "image.id != selectedImageId"
                                }
                              ],
                              attrs: { title: "Kiválasztás" }
                            },
                            [
                              _c("a-icon", {
                                attrs: { type: "check-circle" },
                                on: {
                                  click: function($event) {
                                    return _vm.selectMainPicture(image)
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-tooltip",
                            { attrs: { title: "Törlés" } },
                            [
                              _c("a-icon", {
                                attrs: { type: "delete" },
                                on: {
                                  click: function($event) {
                                    return _vm.deleteImage(image)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                }),
                0
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "a-modal",
        {
          attrs: { visible: _vm.previewVisible, footer: null },
          on: { cancel: _vm.closePreview }
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { alt: "Előnézet", src: _vm.previewImage }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }