var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-form-container" },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.formDataLoading } },
        [
          _c(
            "a-form",
            {
              attrs: { form: _vm.form, layout: "vertical" },
              on: { submit: _vm.submit }
            },
            [
              _c(
                "a-form-item",
                [
                  _vm.showSaveButton
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            "html-type": "submit",
                            loading: _vm.submitLoading
                          }
                        },
                        [_vm._v("\n          Mentés\n        ")]
                      )
                    : _vm._e(),
                  _vm.hasChanged()
                    ? _c(
                        "a-button",
                        {
                          attrs: { type: "secondary", "html-type": "button" },
                          on: { click: _vm.resetForm }
                        },
                        [
                          _c("a-icon", {
                            attrs: { type: "undo", theme: "outlined" }
                          }),
                          _vm._v("\n\n          Piszkozat törlése\n        ")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.currentTab
                    ? _c("span", { staticClass: "last-modification" }, [
                        _vm._v("\n          Utolsó mentés: "),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm
                                .moment(_vm.currentTab.modificationTime)
                                .format(_vm.timeStampFormat)
                                .toString()
                            )
                          )
                        ]),
                        _vm._v("  "),
                        _vm.currentTab.user
                          ? _c("span", [
                              _vm._v(
                                "(" + _vm._s(_vm.currentTab.user.userName) + ")"
                              )
                            ])
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm.submitResult.show
                    ? _c("a-alert", {
                        style: {
                          marginTop: "10px"
                        },
                        attrs: {
                          message: _vm.submitResult.message,
                          type: _vm.submitResult.type,
                          showIcon: "",
                          closable: "",
                          afterClose: function() {
                            return (_vm.submitResult.show = false)
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c("a-divider", { attrs: { orientation: "left" } }, [
                _vm._v("\n        A VONZERŐ BEMUTATÁSA\n      ")
              ]),
              _c("h3", [
                _vm._v(
                  "\n        A vonzerő/létesítmény általános bemutatása, leírása, kiemelendő értékei, története, fontosabb célcsoportjai\n        stb.\n      "
                )
              ]),
              _c(
                "a-form-item",
                {
                  attrs: {
                    selfUpdate: true,
                    label: "Rövid leírás (200-255 karakter)"
                  }
                },
                [
                  _c("Wysiwyg", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "shortDescription",
                          {
                            rules: [
                              {
                                required:
                                  _vm.isNotMobile && !_vm.isMobileReadonly,
                                preserve: true,
                                transform: function(value) {
                                  return _vm.stripTags(value)
                                },
                                min: _vm.characterCounts.shortDescription.min,
                                max: _vm.characterCounts.shortDescription.max,
                                message:
                                  "Kérjük töltse ki! A leírás hosszának " +
                                  _vm.characterCounts.shortDescription.min +
                                  " és " +
                                  _vm.characterCounts.shortDescription.max +
                                  " között kell lennie."
                              }
                            ],
                            validateTrigger: ["blur", "keyup"]
                          }
                        ],
                        expression:
                          "[\n                          'shortDescription',\n                          {\n                              rules: [\n                                  {\n                                    required: isNotMobile && !isMobileReadonly,\n                                    preserve: true,\n                                    transform: (value) => {\n                                      return stripTags(value);\n                                    },\n                                    min: characterCounts.shortDescription.min,\n                                    max: characterCounts.shortDescription.max,\n                                    message: 'Kérjük töltse ki! A leírás hosszának ' + characterCounts.shortDescription.min\n                                    + ' és ' + characterCounts.shortDescription.max + ' között kell lennie.',\n                                  }\n                              ],\n                              validateTrigger: ['blur', 'keyup']\n                          }\n                      ]"
                      }
                    ],
                    ref: "shortDescription",
                    attrs: { height: 100, "read-only": _vm.isMobileReadonly },
                    on: {
                      keyup: _vm.characterCount,
                      change: _vm.characterCount
                    }
                  }),
                  _c("div", { staticClass: "character-count" }, [
                    _c(
                      "strong",
                      {
                        class: {
                          invalid:
                            _vm.characterCounts.shortDescription.valid ===
                            false,
                          valid:
                            _vm.characterCounts.shortDescription.valid === true
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.characterCounts.shortDescription.current
                            ) +
                            "\n          "
                        )
                      ]
                    ),
                    _vm._v(
                      "\n\n          / " +
                        _vm._s(_vm.characterCounts.shortDescription.min) +
                        " - " +
                        _vm._s(_vm.characterCounts.shortDescription.max) +
                        "\n        "
                    )
                  ])
                ],
                1
              ),
              _vm._l(_vm.translatedFormFields["shortDescription"], function(
                value,
                locale
              ) {
                return _c(
                  "div",
                  { key: "shortDescription-" + locale },
                  [
                    value !== null
                      ? _c("a-row", [
                          _c(
                            "div",
                            [
                              _c(
                                "a-form-item",
                                [
                                  _c(
                                    "div",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [
                                      _vm._v(
                                        "\n                Nyelv\n              "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a-select",
                                    {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value: [
                                            _vm.getTranslatedLocaleName(
                                              "shortDescription",
                                              locale
                                            ),
                                            {
                                              initialValue: locale,
                                              rules: [
                                                {
                                                  required: false
                                                }
                                              ]
                                            }
                                          ],
                                          expression:
                                            "[\n                              getTranslatedLocaleName('shortDescription', locale),\n                              {\n                                  initialValue: locale,\n                                  rules: [{\n                                      required: false,\n                                  }]\n                              }\n                          ]"
                                        }
                                      ],
                                      ref: _vm.getTranslatedLocaleName(
                                        "shortDescription",
                                        locale
                                      ),
                                      refInFor: true,
                                      staticStyle: { width: "90%" },
                                      attrs: {
                                        getPopupContainer: _vm.getParentTriggerNode(),
                                        disabled: _vm.isTranslationEditDisabled,
                                        filterOption: _vm.filterSelectNames
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.updateTranslatedFormField(
                                            "shortDescription"
                                          )
                                        }
                                      }
                                    },
                                    _vm._l(_vm.languages, function(language) {
                                      return _c(
                                        "a-select-option",
                                        {
                                          key: language.name,
                                          attrs: {
                                            value: language.locale,
                                            disabled: !_vm.isLanguageAvailable(
                                              "shortDescription",
                                              language.locale
                                            )
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                language.name.toLowerCase()
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    }),
                                    1
                                  ),
                                  _vm.isAdmin
                                    ? _c(
                                        "a-tooltip",
                                        { attrs: { title: "Mező törlése" } },
                                        [
                                          _c("a-icon", {
                                            staticClass:
                                              "dynamic-delete-button",
                                            attrs: {
                                              type: "delete",
                                              theme: "filled"
                                            },
                                            on: {
                                              click: function() {
                                                return _vm.deleteTranslatedFormField(
                                                  "shortDescription",
                                                  locale
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "a-form-item",
                                { attrs: { selfUpdate: true } },
                                [
                                  _c(
                                    "span",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [
                                      _vm._v(
                                        "\n                Rövid leírás " +
                                          _vm._s(_vm.getLanguageName(locale)) +
                                          " nyelven\n              "
                                      )
                                    ]
                                  ),
                                  _c("Wysiwyg", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          _vm.getTranslatedFieldName(
                                            "shortDescription",
                                            locale
                                          ),
                                          {
                                            initialValue: value,
                                            rules: [
                                              {
                                                required: false,
                                                preserve: true,
                                                message: "Kérjük töltse ki!"
                                              },
                                              {
                                                transform: function(value) {
                                                  return _vm.stripTags(value)
                                                },
                                                min: !_vm.isTranslationEditDisabled
                                                  ? _vm.characterCounts[
                                                      _vm.getTranslatedFieldName(
                                                        "shortDescription",
                                                        locale
                                                      )
                                                    ].min
                                                  : undefined,
                                                max: !_vm.isTranslationEditDisabled
                                                  ? _vm.characterCounts[
                                                      _vm.getTranslatedFieldName(
                                                        "shortDescription",
                                                        locale
                                                      )
                                                    ].max
                                                  : undefined,
                                                message:
                                                  "Kérjük töltse ki! A leírás hosszának " +
                                                  _vm.characterCounts[
                                                    _vm.getTranslatedFieldName(
                                                      "shortDescription",
                                                      locale
                                                    )
                                                  ].min +
                                                  " és " +
                                                  _vm.characterCounts[
                                                    _vm.getTranslatedFieldName(
                                                      "shortDescription",
                                                      locale
                                                    )
                                                  ].max +
                                                  " között kell lennie."
                                              }
                                            ],
                                            validateTrigger: ["blur", "keyup"]
                                          }
                                        ],
                                        expression:
                                          "[\n                                  getTranslatedFieldName('shortDescription', locale),\n                                  {\n                                    initialValue: value,\n                                    rules: [\n                                        {\n                                          required: false,\n                                          preserve: true,\n                                          message: 'Kérjük töltse ki!'\n                                        },\n                                        {\n                                          transform: (value) => {\n                                            return stripTags(value);\n                                          },\n                                          min: !isTranslationEditDisabled  ? characterCounts[getTranslatedFieldName('shortDescription', locale)].min : undefined,\n                                          max: !isTranslationEditDisabled ? characterCounts[getTranslatedFieldName('shortDescription', locale)].max : undefined,\n                                          message: 'Kérjük töltse ki! A leírás hosszának ' + characterCounts[getTranslatedFieldName('shortDescription', locale)].min\n                                          + ' és ' + characterCounts[getTranslatedFieldName('shortDescription', locale)].max + ' között kell lennie.',\n                                        }\n                                    ],\n                                    validateTrigger: ['blur', 'keyup']\n                                }\n                              ]"
                                      }
                                    ],
                                    ref: _vm.getTranslatedFieldName(
                                      "shortDescription",
                                      locale
                                    ),
                                    refInFor: true,
                                    attrs: {
                                      maxLength: 25000,
                                      height: 100,
                                      disabled: false,
                                      readOnly: _vm.isTranslationEditDisabled,
                                      autoSize: {
                                        minRows: 3
                                      }
                                    },
                                    on: {
                                      keyup: _vm.characterCount,
                                      change: _vm.characterCount,
                                      blur: function($event) {
                                        return _vm.updateTranslatedFormField(
                                          "shortDescription"
                                        )
                                      }
                                    }
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "character-count" },
                                    [
                                      _c(
                                        "strong",
                                        {
                                          class: {
                                            invalid:
                                              _vm.characterCounts[
                                                _vm.getTranslatedFieldName(
                                                  "shortDescription",
                                                  locale
                                                )
                                              ].valid === false,
                                            valid:
                                              _vm.characterCounts[
                                                _vm.getTranslatedFieldName(
                                                  "shortDescription",
                                                  locale
                                                )
                                              ].valid === true
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.characterCounts[
                                                  _vm.getTranslatedFieldName(
                                                    "shortDescription",
                                                    locale
                                                  )
                                                ].current
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n\n                / " +
                                          _vm._s(
                                            _vm.characterCounts[
                                              _vm.getTranslatedFieldName(
                                                "shortDescription",
                                                locale
                                              )
                                            ].min
                                          ) +
                                          " -\n                " +
                                          _vm._s(
                                            _vm.characterCounts[
                                              _vm.getTranslatedFieldName(
                                                "shortDescription",
                                                locale
                                              )
                                            ].max
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                )
              }),
              _c(
                "a-form-item",
                [
                  _c(
                    "a-button",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        type: "dashed",
                        disabled:
                          _vm.isOfficeEmployee ||
                          !_vm.getFirstAvailableLanguage("shortDescription") ||
                          _vm.isMobileReadonly
                      },
                      on: {
                        click: function($event) {
                          return _vm.addTranslatedFormField("shortDescription")
                        }
                      }
                    },
                    [
                      _c("a-icon", { attrs: { type: "plus" } }),
                      _vm._v(
                        "\n\n          Idegennyelvű rövid leírás hozzáadása\n        "
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    selfUpdate: true,
                    label: "Közepes leírás (700-1000 karakter)"
                  }
                },
                [
                  _c("Wysiwyg", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "mediumDescription",
                          {
                            rules: [
                              {
                                transform: function(value) {
                                  return _vm.stripTags(value)
                                },
                                required: _vm.mediumDescriptionRequired,
                                preserve: true,
                                min: _vm.characterCounts.mediumDescription.min,
                                max: _vm.characterCounts.mediumDescription.max,
                                message:
                                  "Kérjük töltse ki! A leírás hosszának " +
                                  _vm.characterCounts.mediumDescription.min +
                                  " és " +
                                  _vm.characterCounts.mediumDescription.max +
                                  " között kell lennie."
                              }
                            ],
                            validateTrigger: ["blur", "keyup"]
                          }
                        ],
                        expression:
                          "[\n                          'mediumDescription',\n                          {\n                              rules: [\n                                  {\n                                    transform: (value) => {\n                                      return stripTags(value);\n                                    },\n                                    required: mediumDescriptionRequired,\n                                    preserve: true,\n                                    min: characterCounts.mediumDescription.min,\n                                    max: characterCounts.mediumDescription.max,\n                                    message: 'Kérjük töltse ki! A leírás hosszának ' + characterCounts.mediumDescription.min\n                                    + ' és ' + characterCounts.mediumDescription.max + ' között kell lennie.',\n                                  }\n                              ],\n                              validateTrigger: ['blur', 'keyup']\n                          }\n                      ]"
                      }
                    ],
                    ref: "mediumDescription",
                    attrs: {
                      maxLength: 25000,
                      height: 250,
                      "read-only": _vm.isMobileReadonly,
                      autoSize: {
                        minRows: 3
                      }
                    },
                    on: {
                      keyup: _vm.characterCount,
                      change: _vm.characterCount
                    }
                  }),
                  _c("div", { staticClass: "character-count" }, [
                    _c(
                      "strong",
                      {
                        class: {
                          invalid:
                            _vm.characterCounts.mediumDescription.valid ===
                            false,
                          valid:
                            _vm.characterCounts.mediumDescription.valid === true
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.characterCounts.mediumDescription.current
                            ) +
                            "\n          "
                        )
                      ]
                    ),
                    _vm._v(
                      "\n\n          / " +
                        _vm._s(_vm.characterCounts.mediumDescription.min) +
                        " - " +
                        _vm._s(_vm.characterCounts.mediumDescription.max) +
                        "\n        "
                    )
                  ])
                ],
                1
              ),
              _vm._l(_vm.translatedFormFields["mediumDescription"], function(
                value,
                locale
              ) {
                return _c(
                  "div",
                  { key: "mediumDescription-" + locale },
                  [
                    value !== null
                      ? _c("a-row", [
                          _c(
                            "div",
                            [
                              _c(
                                "a-form-item",
                                [
                                  _c(
                                    "div",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [
                                      _vm._v(
                                        "\n                Nyelv\n              "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a-select",
                                    {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value: [
                                            _vm.getTranslatedLocaleName(
                                              "mediumDescription",
                                              locale
                                            ),
                                            {
                                              initialValue: locale,
                                              rules: [
                                                {
                                                  required: false
                                                }
                                              ]
                                            }
                                          ],
                                          expression:
                                            "[\n                              getTranslatedLocaleName('mediumDescription', locale),\n                              {\n                                  initialValue: locale,\n                                  rules: [{\n                                      required: false,\n                                  }]\n                              }\n                          ]"
                                        }
                                      ],
                                      ref: _vm.getTranslatedLocaleName(
                                        "mediumDescription",
                                        locale
                                      ),
                                      refInFor: true,
                                      staticStyle: { width: "90%" },
                                      attrs: {
                                        getPopupContainer: _vm.getParentTriggerNode(),
                                        disabled: _vm.isTranslationEditDisabled
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.updateTranslatedFormField(
                                            "mediumDescription"
                                          )
                                        }
                                      }
                                    },
                                    _vm._l(_vm.languages, function(language) {
                                      return _c(
                                        "a-select-option",
                                        {
                                          key: language.locale,
                                          attrs: {
                                            value: language.locale,
                                            disabled: !_vm.isLanguageAvailable(
                                              "mediumDescription",
                                              language.locale
                                            )
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                language.name.toLowerCase()
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    }),
                                    1
                                  ),
                                  _vm.isAdmin
                                    ? _c(
                                        "a-tooltip",
                                        { attrs: { title: "Mező törlése" } },
                                        [
                                          _c("a-icon", {
                                            staticClass:
                                              "dynamic-delete-button",
                                            attrs: {
                                              type: "delete",
                                              theme: "filled"
                                            },
                                            on: {
                                              click: function() {
                                                return _vm.deleteTranslatedFormField(
                                                  "mediumDescription",
                                                  locale
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "a-form-item",
                                { attrs: { selfUpdate: true } },
                                [
                                  _c(
                                    "span",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [
                                      _vm._v(
                                        "\n                Közepes leírás " +
                                          _vm._s(_vm.getLanguageName(locale)) +
                                          " nyelven\n              "
                                      )
                                    ]
                                  ),
                                  _c("Wysiwyg", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          _vm.getTranslatedFieldName(
                                            "mediumDescription",
                                            locale
                                          ),
                                          {
                                            initialValue: value,
                                            rules: [
                                              {
                                                required: false,
                                                preserve: true,
                                                message: "Kérjük töltse ki!"
                                              },
                                              {
                                                transform: function(value) {
                                                  return _vm.stripTags(value)
                                                },
                                                min: !_vm.isTranslationEditDisabled
                                                  ? _vm.characterCounts[
                                                      _vm.getTranslatedFieldName(
                                                        "mediumDescription",
                                                        locale
                                                      )
                                                    ].min
                                                  : undefined,
                                                max: !_vm.isTranslationEditDisabled
                                                  ? _vm.characterCounts[
                                                      _vm.getTranslatedFieldName(
                                                        "mediumDescription",
                                                        locale
                                                      )
                                                    ].max
                                                  : undefined,
                                                message:
                                                  "Kérjük töltse ki! A leírás hosszának " +
                                                  _vm.characterCounts[
                                                    _vm.getTranslatedFieldName(
                                                      "mediumDescription",
                                                      locale
                                                    )
                                                  ].min +
                                                  " és " +
                                                  _vm.characterCounts[
                                                    _vm.getTranslatedFieldName(
                                                      "mediumDescription",
                                                      locale
                                                    )
                                                  ].max +
                                                  " között kell lennie."
                                              }
                                            ],
                                            validateTrigger: ["blur", "keyup"]
                                          }
                                        ],
                                        expression:
                                          "[\n                                  getTranslatedFieldName('mediumDescription', locale),\n                                  {\n                                    initialValue: value,\n                                    rules: [\n                                        {\n                                          required: false,\n                                          preserve: true,\n                                          message: 'Kérjük töltse ki!'\n                                        },\n                                        {\n                                          transform: (value) => {\n                                            return stripTags(value);\n                                          },\n                                          min: !isTranslationEditDisabled ? characterCounts[getTranslatedFieldName('mediumDescription', locale)].min : undefined,\n                                          max: !isTranslationEditDisabled ? characterCounts[getTranslatedFieldName('mediumDescription', locale)].max : undefined,\n                                          message: 'Kérjük töltse ki! A leírás hosszának ' + characterCounts[getTranslatedFieldName('mediumDescription', locale)].min\n                                          + ' és ' + characterCounts[getTranslatedFieldName('mediumDescription', locale)].max + ' között kell lennie.',\n                                        }\n                                    ],\n                                    validateTrigger: ['blur', 'keyup']\n                                }\n                              ]"
                                      }
                                    ],
                                    ref: _vm.getTranslatedFieldName(
                                      "mediumDescription",
                                      locale
                                    ),
                                    refInFor: true,
                                    attrs: {
                                      maxLength: 25000,
                                      height: 250,
                                      disabled: false,
                                      readOnly: _vm.isTranslationEditDisabled,
                                      autoSize: { minRows: 2 }
                                    },
                                    on: {
                                      keyup: _vm.characterCount,
                                      change: _vm.characterCount,
                                      blur: function($event) {
                                        return _vm.updateTranslatedFormField(
                                          "mediumDescription"
                                        )
                                      }
                                    }
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "character-count" },
                                    [
                                      _c(
                                        "strong",
                                        {
                                          class: {
                                            invalid:
                                              _vm.characterCounts[
                                                _vm.getTranslatedFieldName(
                                                  "mediumDescription",
                                                  locale
                                                )
                                              ].valid === false,
                                            valid:
                                              _vm.characterCounts[
                                                _vm.getTranslatedFieldName(
                                                  "mediumDescription",
                                                  locale
                                                )
                                              ].valid === true
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.characterCounts[
                                                  _vm.getTranslatedFieldName(
                                                    "mediumDescription",
                                                    locale
                                                  )
                                                ].current
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n\n                / " +
                                          _vm._s(
                                            _vm.characterCounts[
                                              _vm.getTranslatedFieldName(
                                                "mediumDescription",
                                                locale
                                              )
                                            ].min
                                          ) +
                                          " -\n                " +
                                          _vm._s(
                                            _vm.characterCounts[
                                              _vm.getTranslatedFieldName(
                                                "mediumDescription",
                                                locale
                                              )
                                            ].max
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                )
              }),
              _c(
                "a-form-item",
                [
                  _c(
                    "a-button",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        type: "dashed",
                        disabled:
                          _vm.isOfficeEmployee ||
                          !_vm.getFirstAvailableLanguage("mediumDescription") ||
                          _vm.isMobileReadonly
                      },
                      on: {
                        click: function($event) {
                          return _vm.addTranslatedFormField("mediumDescription")
                        }
                      }
                    },
                    [
                      _c("a-icon", { attrs: { type: "plus" } }),
                      _vm._v(
                        "\n\n          Idegennyelvű közepes leírás hozzáadása\n        "
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isNotMobile,
                      expression: "isNotMobile"
                    }
                  ],
                  attrs: {
                    selfUpdate: true,
                    label: "Hosszú leírás (1500-4000 karakter)"
                  }
                },
                [
                  _c("Wysiwyg", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "longDescription",
                          {
                            rules: [
                              {
                                transform: function(value) {
                                  return _vm.stripTags(value)
                                },
                                required: _vm.longDescriptionRequired,
                                preserve: true,
                                min: _vm.characterCounts.longDescription.min,
                                max: _vm.characterCounts.longDescription.max,
                                message:
                                  "Kérjük töltse ki! A leírás hosszának " +
                                  _vm.characterCounts.longDescription.min +
                                  " és " +
                                  _vm.characterCounts.longDescription.max +
                                  " között kell lennie."
                              }
                            ],
                            validateTrigger: ["blur", "keyup"]
                          }
                        ],
                        expression:
                          "[\n                          'longDescription',\n                          {\n                              rules: [\n                                  {\n                                    transform: (value) => {\n                                      return stripTags(value);\n                                    },\n                                    required: longDescriptionRequired,\n                                    preserve: true,\n                                    min: characterCounts.longDescription.min,\n                                    max: characterCounts.longDescription.max,\n                                    message: 'Kérjük töltse ki! A leírás hosszának ' + characterCounts.longDescription.min\n                                    + ' és ' + characterCounts.longDescription.max + ' között kell lennie.',\n                                  }\n                              ],\n                              validateTrigger: ['blur', 'keyup']\n                          }\n                      ]"
                      }
                    ],
                    ref: "longDescription",
                    attrs: {
                      maxLength: 25000,
                      height: 900,
                      "read-only": _vm.isMobileReadonly,
                      autoSize: {
                        minRows: 3
                      }
                    },
                    on: {
                      keyup: _vm.characterCount,
                      change: _vm.characterCount
                    }
                  }),
                  _c("div", { staticClass: "character-count" }, [
                    _c(
                      "strong",
                      {
                        class: {
                          invalid:
                            _vm.characterCounts.longDescription.valid === false,
                          valid:
                            _vm.characterCounts.longDescription.valid === true
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.characterCounts.longDescription.current
                            ) +
                            "\n          "
                        )
                      ]
                    ),
                    _vm._v(
                      "\n\n          / " +
                        _vm._s(_vm.characterCounts.longDescription.min) +
                        " - " +
                        _vm._s(_vm.characterCounts.longDescription.max) +
                        "\n        "
                    )
                  ])
                ],
                1
              ),
              _vm._l(_vm.translatedFormFields["longDescription"], function(
                value,
                locale
              ) {
                return _c(
                  "div",
                  { key: "longDescription-" + locale },
                  [
                    value !== null
                      ? _c("a-row", [
                          _c(
                            "div",
                            [
                              _c(
                                "a-form-item",
                                [
                                  _c(
                                    "div",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [
                                      _vm._v(
                                        "\n                Nyelv\n              "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a-select",
                                    {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value: [
                                            _vm.getTranslatedLocaleName(
                                              "longDescription",
                                              locale
                                            ),
                                            {
                                              initialValue: locale,
                                              rules: [
                                                {
                                                  required: false
                                                }
                                              ]
                                            }
                                          ],
                                          expression:
                                            "[\n                              getTranslatedLocaleName('longDescription', locale),\n                              {\n                                  initialValue: locale,\n                                  rules: [{\n                                      required: false,\n                                  }]\n                              }\n                          ]"
                                        }
                                      ],
                                      ref: _vm.getTranslatedLocaleName(
                                        "longDescription",
                                        locale
                                      ),
                                      refInFor: true,
                                      staticStyle: { width: "90%" },
                                      attrs: {
                                        getPopupContainer: _vm.getParentTriggerNode(),
                                        disabled: _vm.isTranslationEditDisabled
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.updateTranslatedFormField(
                                            "longDescription"
                                          )
                                        }
                                      }
                                    },
                                    _vm._l(_vm.languages, function(language) {
                                      return _c(
                                        "a-select-option",
                                        {
                                          key: language.locale,
                                          attrs: {
                                            value: language.locale,
                                            disabled: !_vm.isLanguageAvailable(
                                              "longDescription",
                                              language.locale
                                            )
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                language.name.toLowerCase()
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    }),
                                    1
                                  ),
                                  _vm.isAdmin
                                    ? _c(
                                        "a-tooltip",
                                        { attrs: { title: "Mező törlése" } },
                                        [
                                          _c("a-icon", {
                                            staticClass:
                                              "dynamic-delete-button",
                                            attrs: {
                                              type: "delete",
                                              theme: "filled"
                                            },
                                            on: {
                                              click: function() {
                                                return _vm.deleteTranslatedFormField(
                                                  "longDescription",
                                                  locale
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "a-form-item",
                                { attrs: { selfUpdate: true } },
                                [
                                  _c(
                                    "span",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [
                                      _vm._v(
                                        "\n                Hosszú leírás " +
                                          _vm._s(_vm.getLanguageName(locale)) +
                                          " nyelven\n              "
                                      )
                                    ]
                                  ),
                                  _c("Wysiwyg", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          _vm.getTranslatedFieldName(
                                            "longDescription",
                                            locale
                                          ),
                                          {
                                            initialValue: value,
                                            rules: [
                                              {
                                                required: false,
                                                preserve: true,
                                                message: "Kérjük töltse ki!"
                                              },
                                              {
                                                transform: function(value) {
                                                  return _vm.stripTags(value)
                                                },
                                                min: !_vm.isTranslationEditDisabled
                                                  ? _vm.characterCounts[
                                                      _vm.getTranslatedFieldName(
                                                        "longDescription",
                                                        locale
                                                      )
                                                    ].min
                                                  : undefined,
                                                max: !_vm.isTranslationEditDisabled
                                                  ? _vm.characterCounts[
                                                      _vm.getTranslatedFieldName(
                                                        "longDescription",
                                                        locale
                                                      )
                                                    ].max
                                                  : undefined,
                                                message:
                                                  "Kérjük töltse ki! A leírás hosszának " +
                                                  _vm.characterCounts[
                                                    _vm.getTranslatedFieldName(
                                                      "longDescription",
                                                      locale
                                                    )
                                                  ].min +
                                                  " és " +
                                                  _vm.characterCounts[
                                                    _vm.getTranslatedFieldName(
                                                      "longDescription",
                                                      locale
                                                    )
                                                  ].max +
                                                  " között kell lennie."
                                              }
                                            ],
                                            validateTrigger: ["blur", "keyup"]
                                          }
                                        ],
                                        expression:
                                          "[\n                                  getTranslatedFieldName('longDescription', locale),\n                                  {\n                                    initialValue: value,\n                                    rules: [\n                                        {\n                                          required: false,\n                                          preserve: true,\n                                          message: 'Kérjük töltse ki!'\n                                        },\n                                        {\n                                          transform: (value) => {\n                                            return stripTags(value);\n                                          },\n                                          min: !isTranslationEditDisabled ? characterCounts[getTranslatedFieldName('longDescription', locale)].min : undefined,\n                                          max: !isTranslationEditDisabled ? characterCounts[getTranslatedFieldName('longDescription', locale)].max : undefined,\n                                          message: 'Kérjük töltse ki! A leírás hosszának ' + characterCounts[getTranslatedFieldName('longDescription', locale)].min\n                                          + ' és ' + characterCounts[getTranslatedFieldName('longDescription', locale)].max + ' között kell lennie.',\n                                        }\n                                    ],\n                                    validateTrigger: ['blur', 'keyup']\n                                }\n                              ]"
                                      }
                                    ],
                                    ref: _vm.getTranslatedFieldName(
                                      "longDescription",
                                      locale
                                    ),
                                    refInFor: true,
                                    attrs: {
                                      maxLength: 25000,
                                      height: 900,
                                      disabled: false,
                                      readOnly: _vm.isTranslationEditDisabled,
                                      autoSize: { minRows: 2 }
                                    },
                                    on: {
                                      keyup: _vm.characterCount,
                                      change: _vm.characterCount,
                                      blur: function($event) {
                                        return _vm.updateTranslatedFormField(
                                          "longDescription"
                                        )
                                      }
                                    }
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "character-count" },
                                    [
                                      _c(
                                        "strong",
                                        {
                                          class: {
                                            invalid:
                                              _vm.characterCounts[
                                                _vm.getTranslatedFieldName(
                                                  "longDescription",
                                                  locale
                                                )
                                              ].valid === false,
                                            valid:
                                              _vm.characterCounts[
                                                _vm.getTranslatedFieldName(
                                                  "longDescription",
                                                  locale
                                                )
                                              ].valid === true
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.characterCounts[
                                                  _vm.getTranslatedFieldName(
                                                    "longDescription",
                                                    locale
                                                  )
                                                ].current
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n\n                / " +
                                          _vm._s(
                                            _vm.characterCounts[
                                              _vm.getTranslatedFieldName(
                                                "longDescription",
                                                locale
                                              )
                                            ].min
                                          ) +
                                          " -\n                " +
                                          _vm._s(
                                            _vm.characterCounts[
                                              _vm.getTranslatedFieldName(
                                                "longDescription",
                                                locale
                                              )
                                            ].max
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                )
              }),
              _c(
                "a-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isNotMobile,
                      expression: "isNotMobile"
                    }
                  ]
                },
                [
                  _c(
                    "a-button",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        type: "dashed",
                        disabled:
                          _vm.isOfficeEmployee ||
                          !_vm.getFirstAvailableLanguage("longDescription") ||
                          _vm.isMobileReadonly
                      },
                      on: {
                        click: function($event) {
                          return _vm.addTranslatedFormField("longDescription")
                        }
                      }
                    },
                    [
                      _c("a-icon", { attrs: { type: "plus" } }),
                      _vm._v(
                        "\n\n          Idegennyelvű hosszú leírás hozzáadása\n        "
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    selfUpdate: true,
                    label: "Mobil leírás (700-1000 karakter)"
                  }
                },
                [
                  _c("Wysiwyg", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "mobileDescription",
                          {
                            rules: [
                              {
                                transform: function(value) {
                                  return _vm.stripTags(value)
                                },
                                required:
                                  _vm.isMobileApp || _vm.mobilePublished,
                                preserve: true,
                                min: _vm.characterCounts.mobileDescription.min,
                                max: _vm.characterCounts.mobileDescription.max,
                                message:
                                  "Kérjük töltse ki! A leírás hosszának " +
                                  _vm.characterCounts.mobileDescription.min +
                                  " és " +
                                  _vm.characterCounts.mobileDescription.max +
                                  " között kell lennie."
                              }
                            ],
                            validateTrigger: ["blur", "keyup"]
                          }
                        ],
                        expression:
                          "[\n                        'mobileDescription',\n                        {\n                            rules: [\n                                {\n                                  transform: (value) => {\n                                    return stripTags(value);\n                                  },\n                                  required: isMobileApp || mobilePublished,\n                                  preserve: true,\n                                  min: characterCounts.mobileDescription.min,\n                                  max: characterCounts.mobileDescription.max,\n                                  message: 'Kérjük töltse ki! A leírás hosszának ' + characterCounts.mobileDescription.min\n                                  + ' és ' + characterCounts.mobileDescription.max + ' között kell lennie.',\n                                }\n                            ],\n                            validateTrigger: ['blur', 'keyup']\n                        }\n                    ]"
                      }
                    ],
                    ref: "mobileDescription",
                    attrs: {
                      maxLength: 25000,
                      height: 250,
                      autoSize: {
                        minRows: 3
                      }
                    },
                    on: {
                      keyup: _vm.characterCount,
                      change: _vm.characterCount
                    }
                  }),
                  _c("div", { staticClass: "character-count" }, [
                    _c(
                      "strong",
                      {
                        class: {
                          invalid:
                            _vm.characterCounts.mobileDescription.valid ===
                            false,
                          valid:
                            _vm.characterCounts.mobileDescription.valid === true
                        }
                      },
                      [
                        _vm._v(
                          "\n                  " +
                            _vm._s(
                              _vm.characterCounts.mobileDescription.current
                            ) +
                            "\n              "
                        )
                      ]
                    ),
                    _vm._v(
                      "\n\n              / " +
                        _vm._s(_vm.characterCounts.mobileDescription.min) +
                        " - " +
                        _vm._s(_vm.characterCounts.mobileDescription.max) +
                        "\n          "
                    )
                  ])
                ],
                1
              ),
              _vm._l(_vm.translatedFormFields["mobileDescription"], function(
                value,
                locale
              ) {
                return _c(
                  "div",
                  { key: "mobileDescription-" + locale },
                  [
                    value !== null
                      ? _c("a-row", [
                          _c(
                            "div",
                            [
                              _c(
                                "a-form-item",
                                [
                                  _c(
                                    "div",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [
                                      _vm._v(
                                        "\n                Nyelv\n              "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a-select",
                                    {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value: [
                                            _vm.getTranslatedLocaleName(
                                              "mobileDescription",
                                              locale
                                            ),
                                            {
                                              initialValue: locale,
                                              rules: [
                                                {
                                                  required: false
                                                }
                                              ]
                                            }
                                          ],
                                          expression:
                                            "[\n                              getTranslatedLocaleName('mobileDescription', locale),\n                              {\n                                  initialValue: locale,\n                                  rules: [{\n                                      required: false,\n                                  }]\n                              }\n                          ]"
                                        }
                                      ],
                                      ref: _vm.getTranslatedLocaleName(
                                        "mobileDescription",
                                        locale
                                      ),
                                      refInFor: true,
                                      staticStyle: { width: "90%" },
                                      attrs: {
                                        getPopupContainer: _vm.getParentTriggerNode(),
                                        disabled:
                                          _vm.isTranslationEditDisabled &&
                                          !_vm.isMobileApp
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.updateTranslatedFormField(
                                            "mobileDescription"
                                          )
                                        }
                                      }
                                    },
                                    _vm._l(_vm.languages, function(language) {
                                      return _c(
                                        "a-select-option",
                                        {
                                          key: language.locale,
                                          attrs: {
                                            value: language.locale,
                                            disabled: !_vm.isLanguageAvailable(
                                              "mobileDescription",
                                              language.locale
                                            )
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                language.name.toLowerCase()
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    }),
                                    1
                                  ),
                                  _vm.isAdmin
                                    ? _c(
                                        "a-tooltip",
                                        { attrs: { title: "Mező törlése" } },
                                        [
                                          _c("a-icon", {
                                            staticClass:
                                              "dynamic-delete-button",
                                            attrs: {
                                              type: "delete",
                                              theme: "filled"
                                            },
                                            on: {
                                              click: function() {
                                                return _vm.deleteTranslatedFormField(
                                                  "mobileDescription",
                                                  locale
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "a-form-item",
                                { attrs: { selfUpdate: true } },
                                [
                                  _c(
                                    "span",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [
                                      _vm._v(
                                        "\n                Mobil leírás " +
                                          _vm._s(_vm.getLanguageName(locale)) +
                                          " nyelven\n              "
                                      )
                                    ]
                                  ),
                                  _c("Wysiwyg", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          _vm.getTranslatedFieldName(
                                            "mobileDescription",
                                            locale
                                          ),
                                          {
                                            initialValue: value,
                                            rules: [
                                              {
                                                required: false,
                                                preserve: true,
                                                message: "Kérjük töltse ki!"
                                              },
                                              {
                                                transform: function(value) {
                                                  return _vm.stripTags(value)
                                                },
                                                min: !_vm.isTranslationEditDisabled
                                                  ? _vm.characterCounts[
                                                      _vm.getTranslatedFieldName(
                                                        "mobileDescription",
                                                        locale
                                                      )
                                                    ].min
                                                  : undefined,
                                                max: !_vm.isTranslationEditDisabled
                                                  ? _vm.characterCounts[
                                                      _vm.getTranslatedFieldName(
                                                        "mobileDescription",
                                                        locale
                                                      )
                                                    ].max
                                                  : undefined,
                                                message:
                                                  "Kérjük töltse ki! A leírás hosszának " +
                                                  _vm.characterCounts[
                                                    _vm.getTranslatedFieldName(
                                                      "mobileDescription",
                                                      locale
                                                    )
                                                  ].min +
                                                  " és " +
                                                  _vm.characterCounts[
                                                    _vm.getTranslatedFieldName(
                                                      "mobileDescription",
                                                      locale
                                                    )
                                                  ].max +
                                                  " között kell lennie."
                                              }
                                            ],
                                            validateTrigger: ["blur", "keyup"]
                                          }
                                        ],
                                        expression:
                                          "[\n                                  getTranslatedFieldName('mobileDescription', locale),\n                                  {\n                                    initialValue: value,\n                                    rules: [\n                                        {\n                                          required: false,\n                                          preserve: true,\n                                          message: 'Kérjük töltse ki!'\n                                        },\n                                        {\n                                          transform: (value) => {\n                                            return stripTags(value);\n                                          },\n                                          min: !isTranslationEditDisabled ? characterCounts[getTranslatedFieldName('mobileDescription', locale)].min : undefined,\n                                          max: !isTranslationEditDisabled ? characterCounts[getTranslatedFieldName('mobileDescription', locale)].max : undefined,\n                                          message: 'Kérjük töltse ki! A leírás hosszának ' + characterCounts[getTranslatedFieldName('mobileDescription', locale)].min\n                                          + ' és ' + characterCounts[getTranslatedFieldName('mobileDescription', locale)].max + ' között kell lennie.',\n                                        }\n                                    ],\n                                    validateTrigger: ['blur', 'keyup']\n                                }\n                              ]"
                                      }
                                    ],
                                    ref: _vm.getTranslatedFieldName(
                                      "mobileDescription",
                                      locale
                                    ),
                                    refInFor: true,
                                    attrs: {
                                      maxLength: 25000,
                                      height: 900,
                                      disabled: false,
                                      readOnly:
                                        _vm.isTranslationEditDisabled &&
                                        !_vm.isMobileApp,
                                      autoSize: { minRows: 2 }
                                    },
                                    on: {
                                      keyup: _vm.characterCount,
                                      change: _vm.characterCount,
                                      blur: function($event) {
                                        return _vm.updateTranslatedFormField(
                                          "mobileDescription"
                                        )
                                      }
                                    }
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "character-count" },
                                    [
                                      _c(
                                        "strong",
                                        {
                                          class: {
                                            invalid:
                                              _vm.characterCounts[
                                                _vm.getTranslatedFieldName(
                                                  "mobileDescription",
                                                  locale
                                                )
                                              ].valid === false,
                                            valid:
                                              _vm.characterCounts[
                                                _vm.getTranslatedFieldName(
                                                  "mobileDescription",
                                                  locale
                                                )
                                              ].valid === true
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.characterCounts[
                                                  _vm.getTranslatedFieldName(
                                                    "mobileDescription",
                                                    locale
                                                  )
                                                ].current
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n\n                / " +
                                          _vm._s(
                                            _vm.characterCounts[
                                              _vm.getTranslatedFieldName(
                                                "mobileDescription",
                                                locale
                                              )
                                            ].min
                                          ) +
                                          " -\n                " +
                                          _vm._s(
                                            _vm.characterCounts[
                                              _vm.getTranslatedFieldName(
                                                "mobileDescription",
                                                locale
                                              )
                                            ].max
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                )
              }),
              _c(
                "a-form-item",
                [
                  _c(
                    "a-button",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        type: "dashed",
                        disabled:
                          _vm.isOfficeEmployee ||
                          !_vm.getFirstAvailableLanguage("mobileDescription")
                      },
                      on: {
                        click: function($event) {
                          return _vm.addTranslatedFormField("mobileDescription")
                        }
                      }
                    },
                    [
                      _c("a-icon", { attrs: { type: "plus" } }),
                      _vm._v(
                        "\n\n          Idegennyelvű mobil leírás hozzáadása\n        "
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.isNotMobile
                ? _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: "Régi leírás (csak forrásanyagnak, ha elérhető)"
                      }
                    },
                    [
                      _c("a-textarea", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: ["legacyDescription"],
                            expression: "['legacyDescription']"
                          }
                        ],
                        attrs: {
                          maxLength: 25000,
                          readOnly: true,
                          autoSize: {
                            minRows: 2
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-item",
                [
                  _vm.showSaveButton
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            "html-type": "submit",
                            loading: _vm.submitLoading
                          }
                        },
                        [_vm._v("\n          Mentés\n        ")]
                      )
                    : _vm._e(),
                  _vm.hasChanged()
                    ? _c(
                        "a-button",
                        {
                          attrs: { type: "secondary", "html-type": "button" },
                          on: { click: _vm.resetForm }
                        },
                        [
                          _c("a-icon", {
                            attrs: { type: "undo", theme: "outlined" }
                          }),
                          _vm._v("\n\n          Piszkozat törlése\n        ")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.currentTab
                    ? _c("span", { staticClass: "last-modification" }, [
                        _vm._v("\n          Utolsó mentés: "),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm
                                .moment(_vm.currentTab.modificationTime)
                                .format(_vm.timeStampFormat)
                                .toString()
                            )
                          )
                        ]),
                        _vm._v("  "),
                        _vm.currentTab.user
                          ? _c("span", [
                              _vm._v(
                                "(" + _vm._s(_vm.currentTab.user.userName) + ")"
                              )
                            ])
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm.submitResult.show
                    ? _c("a-alert", {
                        style: {
                          marginTop: "10px"
                        },
                        attrs: {
                          message: _vm.submitResult.message,
                          type: _vm.submitResult.type,
                          showIcon: "",
                          closable: "",
                          afterClose: function() {
                            return (_vm.submitResult.show = false)
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }