var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    { staticClass: "welcome-content-row", attrs: { justify: "center" } },
    [
      _c("a-col", { attrs: { xs: 1, sm: 4, md: 7 } }),
      _c("a-col", { attrs: { xs: 22, sm: 16, md: 10 } }, [
        _c("div", { staticClass: "welcome-card-container" }, [
          _c("h1", [_vm._v("\n        Neta\n      ")]),
          _c("p", [
            _vm._v(
              "\n        Köszöntjük a Nemzeti Turisztikai Adatbázis online felületén."
            ),
            _c("br"),
            _vm._v(
              "\n        Kérjük, hogy belépéshez saját felhasználónevét és jelszavát használja,\n        azt másoknak semmilyen esetben se adja ki!"
            ),
            _c("br"),
            _c("span", { staticClass: "info-span" }, [
              _vm._v(
                "\n          Információ és technikai segítség:\n          "
              ),
              _c("br"),
              _c("a", { attrs: { href: "mailto:neta@mtu.gov.hu" } }, [
                _vm._v("neta@mtu.gov.hu")
              ])
            ])
          ]),
          _c(
            "div",
            { staticClass: "welcome-button-holder" },
            [
              _c(
                "router-link",
                { attrs: { to: "login" } },
                [
                  _c("a-button", { attrs: { type: "primary" } }, [
                    _vm._v("\n            Belépés\n          ")
                  ])
                ],
                1
              )
            ],
            1
          )
        ])
      ]),
      _c("a-col", { attrs: { xs: 1, sm: 4, md: 7 } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }