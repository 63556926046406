var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home" },
    [
      _c(
        "a-layout",
        { style: { minHeight: "100vh" } },
        [
          _c("a-layout-header", [_c("HomeHeaderComponent")], 1),
          _c("a-layout-content", [_c("router-view")], 1)
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }