<template>
    <div>
        <div
                class="list-export-container"
                v-if="!isViewer"
        >
            <h3>Vonzerő adatexport</h3>

            <a-form :form="exportForm" v-on:submit="submitExport">
                <a-form-item label="Vonzerő adatkategória">
                    <a-row>
                        <a-col :span=12>
                            <a-select
                                    v-decorator="['formId',
                                    {
                                      rules: [{
                                          required: true,
                                          message: 'Kérjük, töltse ki!'
                                      }],
                                        validateTrigger: ['blur', 'change']
                                    }
                                ]"
                                    placeholder="Kérjük válasszon!"
                            >
                                <a-select-option
                                        v-for="(label, formId) in formIdList"
                                        :key="formId"
                                        :value="formId"
                                >
                                    {{ label }}
                                </a-select-option>
                            </a-select>
                        </a-col>

                        <a-col :span="6">
                            <a-button
                                    class="export-submit"
                                    type="primary"
                                    html-type="submit"
                                    :loading="exportLoading"
                            >
                                Export letöltése
                            </a-button>
                        </a-col>
                    </a-row>
                </a-form-item>
            </a-form>
            <a-divider />
        </div>

        <a-button
                type="primary"
                icon="rollback"
                :disabled="!hasFilters"
                v-on:click="resetFilters()"
                class="clear-filter-button"
        >
            Szűrők visszaállítása
        </a-button>

        <a-popover
                placement="bottomLeft"
                trigger="click"
                v-model="customizingColumns"
                v-on:click="customizingColumns = !customizingColumns"
        >
            <template slot="title">
                <a-button
                        size="small"
                        icon="rollback"
                        class="user-columns-reset"
                        v-on:click="resetUserColumns"
                >
                    Alaphelyzetbe állítás
                </a-button>
            </template>

            <template slot="content">
                <div class="user-columns">
                    <draggable v-model="userColumns" v-on:end="refreshUserColumns">
                        <transition-group>
                            <div v-for="(column, index) in userColumns"
                                 :key="column.key"
                                 class="user-column"
                                 v-show="!column.system && !userColumns[index].disabled"
                            >
                                <a-switch
                                        size="small"
                                        v-model="userColumns[index].displayed"
                                        v-on:change="refreshUserColumns"
                                        :disabled="userColumns[index].disabled"
                                />

                                {{ column.label }}
                            </div>
                        </transition-group>
                    </draggable>
                </div>
            </template>

            <a-button
                    type="default"
                    icon="table"
                    class="clear-filter-button"
            >
                Oszlopok testreszabása
            </a-button>
        </a-popover>

        <a-table
                :columns="columns"
                :row-key="rowKeyGen"
                :data-source="data"
                :pagination="pagination"
                :loading="loading"
                :scroll="{x: 800}"
                v-on:change="handleChange"
                :locale="locale"
        >
            <div v-for="column in columns"
                 v-if="column.render"
                 :slot="column.slots.title"
                 v-on:click="preventSortClick"
            >
                <span v-html="column.label" />

                <a-input-search
                        v-if="column.customFilter.type === 'input'"
                        :placeholder="column.customFilter.placeholder"
                        v-on:search="search"
                        v-model="filters[column.key]"
                >
                </a-input-search>

                <a-select
                        v-if="column.customFilter.type === 'multiInput'"
                        :placeholder="column.customFilter.placeholder"
                        v-on:change="search"
                        mode="tags"
                        :token-separators="[',']"
                        v-model="filters[column.key]"
                        :style="{ width: '100%' }"
                >
                </a-select>

                <a-range-picker
                        v-if="column.customFilter.type === 'date-range'"
                        class="datatable-range-picker"
                        :separator="''"
                        :format="dateFormat"
                        v-on:change="search"
                        v-model="filters[column.key]"
                ></a-range-picker>
            </div>

            <div
                    slot="modification-time-content"
                    slot-scope="modificationTime"
            >
                {{ moment(modificationTime).format(timeStampFormat).toString() }}
            </div>

            <div
                    slot="category-content"
                    slot-scope="category"
            >
                <a-tag v-if="category">
                    {{ category }}
                </a-tag>
            </div>

            <div
                    slot="labels-content"
                    slot-scope="labels"
            >
                <a-tag
                        v-for="label of labels"
                        :key="label.id"
                >
                    {{ label.name }}
                </a-tag>
            </div>

            <div
                    slot="status-content"
                    slot-scope="status"
            >
                {{ statusNames[status] }}
            </div>

            <div
                slot="published-content"
                slot-scope="published"
            >
                {{ published ? 'Igen' : 'Nem' }}
            </div>

            <div
                slot="web-published-content"
                slot-scope="webPublished"
            >
                {{ webPublished ? 'Igen' : 'Nem' }}
            </div>

            <div
                slot="mobile-published-content"
                slot-scope="mobilePublished"
            >
                {{ mobilePublished ? 'Igen' : 'Nem' }}
            </div>

            <div
                    slot="actions-content"
                    slot-scope="row"
                    :style="{width: '150px'}"
            >
                <div class="actions-top-row">
                    <router-link :to="getUrl(row)">
                        <a-button
                                :icon="isViewer ? 'eye' : 'edit'"
                                type="primary"
                                class="action-button"
                        >
                        </a-button>
                    </router-link>

                    <!-- <a-button
                        icon="check-square"
                        type="primary"
                        v-on:click="rowChecked(record)"
                        class="action-button"
                        :ghost="!record.status.approved"
                    >
                    </a-button> -->

                    <a-popconfirm
                            v-if="areRolesSufficient(['ROLE_MTU_ADMIN']) && !(row.status === 0)"
                            :title="row.published ? 'Biztosan visszavonja a publikálást?' : 'Biztosan publikálja?'"
                            :okText="'Igen'"
                            :cancelText="'Mégse'"
                            :okType="'primary'"
                            v-on:confirm="togglePublication(row)"
                    >
                        <a-button
                                :icon="row.published ? 'unlock' : 'lock'"
                                :disabled="row.status === 0"
                                :loading="attractionPublishLoadingStates[row.uid]"
                                type="ghost"
                                :class="{'action-button': true, 'lock-yellow': !row.published, 'lock-green': row.published}"
                        >
                        </a-button>
                    </a-popconfirm>
                    <!--        </div>-->

                    <!--        <div class="actions-bottom-row">-->
                    <a-popconfirm
                            title="Biztos törli a vonzerőt?"
                            :okText="'Igen'"
                            :cancelText="'Mégse'"
                            :okType="'danger'"
                            v-on:confirm="confirmDelete(row)"
                            v-if="!isViewer"
                    >
                        <a-button
                                icon="delete"
                                type="danger"
                                class="action-button"
                        >
                        </a-button>
                    </a-popconfirm>
                </div>
            </div>
        </a-table>
    </div>
</template>

<script>
import moment from 'moment'
import axios from "axios";
import { mapState } from "vuex";
import { SET_LAST_GROUP_TYPE, SET_LAST_LIST_QUERY_PARAMS, SET_USER_COLUMNS } from "@/mutation_types";
import draggable from 'vuedraggable'
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { REFRESH_ATTRACTION } from "@/action_types";

export default {
    components: {
        draggable,
    },

    beforeCreate() {
        this.exportForm = this.$form.createForm(this);
    },

    beforeRouteUpdate(to, from, next) {
        console.debug('beforeRouteUpdate', to.query);

        this.$store.commit({
            type: SET_LAST_LIST_QUERY_PARAMS,
            groupType: 'multiAttraction',
            lastListQueryParams: { ...to.query }
        });

        next();

        this.fetchData();
    },

    mounted() {
        this.$store.commit({
            type: SET_LAST_GROUP_TYPE,
            groupType: 'multiAttraction'
        });

        this.initUserColumns();
        this.fetchData();
    },

    data() {
        return {
            queryRouteName: 'neta-list-multi-attraction',
            loading: false,
            silent: false,

            exportLoading: false,

            lastRequestParamString: null,

            formIdList: {
                ATTRACTION_DATA: 'Vonzerőre vonatkozó adatok',
                DESCRIPTION: 'Leírások'
            },

            customizingColumns: false,

            attractionPublishLoadingStates: {},

            data: [],

            columns: [],

            baseFilters: {
                'group/groupType': 'attraction',
                'multi': 1
            },

            filteredInfo: {},

            sortedInfo: { ...this.defaultSortedInfo },

            defaultSortedInfo: {
                columnKey: 'name',
                order: 'ascend'
            },

            hasFilters: false,

            hiddenFields: [
                'uid',
                'status'
            ],

            dateFormat: 'YYYY.MM.DD',

            timeStampFormat: 'YYYY.MM.DD HH:mm:ss',

            filters: {},

            possibleValues: {
                'group/label': {},
                'category/name': {},
                'classification': {},
                'office/name': {},
                'labels/name': {}
            },

            pagination: {
                position: 'both',
                current: 1,
                defaultCurrent: 1,
                defaultPageSize: 10,
                pageSize: 10,
                pageSizeOptions: [
                    '10',
                    '20',
                    '30'
                ],
                showSizeChanger: true,
                total: 0
            },

            locale: {
                emptyText: 'A megadott szűrési feltétel(ek) szerint nem található vonzerő'
            },

            userColumns: []
        }
    },

    computed: {
        ...mapState({
            selectedRole: state => state.user.selectedRole,
            currentAttraction: state => state.attraction.current
        }),

        isOfficeEmployee() {
            return this.$store.getters.hasRoleAvailable(['ROLE_OFFICE_EMPLOYEE', 'ROLE_OFFICE_LEADER'])
        },

        statusNames() {
            return this.$store.state.attraction.statusNames;
        },

        isViewer() {
            return this.$store.getters.hasRoleAvailable(['ROLE_MTU_VIEWER']);
        },

        defaultFilteredInfo() {
            return {};
        },

        defaultFilters() {
            return {};
        },

        columnsConfig() {
            return {
                'id': {
                    label: 'Azonosító',
                    customFilter: 'multiInput',
                    width: '140px'
                },
                'name': {
                    label: 'Név',
                    width: '220px'
                },
                'office/name': {
                    disabled: this.isOfficeEmployee,
                    label: 'Iroda',
                    filters: this.possibleValues['office/name'],
                    filterMultiple: true,
                    width: '300px'
                },
                'address/city/name': {
                    label: 'Város',
                    customFilter: 'multiInput',
                    wildcard: true,
                    width: '200px'
                },
                'modificationTime': {
                    label: 'Utolsó módosítás',
                    customFilter: 'date-range',
                    scopedSlots: {
                        customRender: 'modification-time-content'
                    },
                    width: '200px'
                },
                'group/label': {
                    label: 'Kategória',
                    filters: this.possibleValues['group/label'],
                    filterMultiple: true,
                    width: '200px'
                },
                'category/name': {
                    label: 'Alkategória',
                    filters: this.possibleValues['category/name'],
                    filterMultiple: true,
                    scopedSlots: {
                        customRender: 'category-content'
                    },
                    width: '200px'
                },
                'labels/name': {
                    label: 'Címke',
                    dataIndex: 'labels',
                    filters: this.possibleValues['labels/name'],
                    filterMultiple: true,
                    sorter: false,
                    scopedSlots: {
                        customRender: 'labels-content'
                    },
                    width: '200px'
                },
                'classification': {
                    label: 'Minősítés',
                    filters: this.possibleValues['classification'],
                    filterMultiple: true,
                    width: '140px'
                },
                'status': {
                    label: 'Státusz',
                    filters: this.statusNames,
                    filterMultiple: true,
                    scopedSlots: {
                        customRender: 'status-content'
                    },
                    width: '160px'
                },
                'published': {
                    label: 'Publikált',
                    filters: {
                        '0': 'Nem',
                        '1': 'Igen'
                    },
                    filterMultiple: true,
                    scopedSlots: {
                        customRender: 'published-content'
                    },
                    width: '160px'
                },
                'webPublished': {
                    label: 'Web publikált',
                    filters: {
                        '0': 'Nem',
                        '1': 'Igen'
                    },
                    filterMultiple: true,
                    scopedSlots: {
                        customRender: 'web-published-content'
                    },
                    width: '160px'
                },
                'mobilePublished': {
                    label: 'Mobil publikált',
                    filters: {
                        '0': 'Nem',
                        '1': 'Igen'
                    },
                    filterMultiple: true,
                    scopedSlots: {
                        customRender: 'mobile-published-content'
                    },
                    width: '160px'
                },
                'actions': {
                    label: 'Műveletek',
                    dataIndex: null,
                    fixed: 'right',
                    customFilter: false,
                    sorter: false,
                    scopedSlots: {
                        customRender: 'actions-content'
                    },
                    width: '150px',
                    system: true
                }
            };
        },
    },

    watch: {
        selectedRole(newRole, oldRole) {
            this.updateUserColumns();
            this.resetFilters();
            this.fetchData(true);
        },

        currentAttraction: {
            handler(attraction) {
                if (attraction.group.groupType !== 'attraction' || attraction.multi !== 1) {
                    return;
                }

                this.fetchData(true, true);
            },
            deep: true
        }
    },

    methods: {
        submitExport(event) {
            event.preventDefault();

            this.exportForm.validateFieldsAndScroll(
                (errors, values) => {
                    if (errors) {
                        this.$message.warning(
                            'Kérem válasszon adat kategóriát!'
                        );

                        return false;
                    }

                    this.exportLoading = true;

                    this.$message.loading({ content: 'Vonzerő adat export készítése', key: 'EXPORT', duration: 0 });

                    axios.get(
                        this.getApiUrl() + '/export',
                        {
                            headers: {
                                accept: '*/*'
                            },
                            responseType: 'blob',
                            params: {
                                formId: values.formId,
                                filter: { ...this.baseFilters, ...this.getFilters() },
                                locale: this.$store.state.attraction.locale,
                                order: this.getOrder()
                            }
                        }
                    ).then((response) => {
                        this.exportLoading = false;

                        this.$message.success({ content: 'A vonzerő adat export elkészült!', key: 'EXPORT' });

                        let fileName = 'Export_' + values.formId.toLowerCase() + '.xlsx';

                        if (typeof response.headers['content-disposition'] !== 'undefined') {
                            let matches = response.headers['content-disposition'].match(/filename="(.+)"/);

                            if (matches[1]) {
                                fileName = matches[1];
                            }
                        }

                        this.downloadBlob(
                            response.data,
                            fileName,
                            'application/xlsx'
                        );
                    }).catch(() => {
                        this.exportLoading = false;

                        this.$message.error({ content: 'Hiba a vonzerő adat export közben!', key: 'EXPORT' });
                    });
                }
            );
        },

        /**
         * @param {blob} data
         * @param {string} fileName
         * @param {string} type
         */
        downloadBlob(data, fileName, type) {
            const blob = new Blob([data], { type: 'type' });
            const link = document.createElement('a');

            link.href = window.URL.createObjectURL(blob);
            link.setAttribute('download', fileName);

            document.body.appendChild(link);

            link.click();
            link.remove();
        },

        clearFilters() {
            console.debug('Clear filters');

            this.filters = {};
            this.filteredInfo = {};
            this.sortedInfo = { ...this.defaultSortedInfo };
            this.pagination.current = this.pagination.defaultCurrent;
            // this.pagination.pageSize = this.pagination.defaultPageSize;
        },

        resetFilters() {
            console.debug('Reset filters');

            this.clearFilters();

            this.filters = this.defaultFilters;
            this.filteredInfo = this.defaultFilteredInfo;

            this.setQueryParams();
        },

        clearOfficeFilters() {
            delete this.filters['office/name'];
        },

        /**
         * Reset user columns config
         */
        initUserColumns() {
            this.userColumns = cloneDeep(this.$store.state.userColumns.multiAttraction);

            if (!this.userColumns.length) {
                return this.resetUserColumns();
            }

            return this.refreshUserColumns();
        },

        /**
         * Reset user columns config
         */
        resetUserColumns() {
            let columnsConfig = this.columnsConfig;

            this.userColumns = [];

            for (let field in columnsConfig) {
                let columnConfig = columnsConfig[field];

                this.userColumns.push({
                    key: field,
                    label: columnConfig.label,
                    displayed: !columnConfig.disabled,
                    disabled: columnConfig.disabled,
                    system: columnConfig.system
                });
            }

            this.refreshUserColumns();
        },

        updateUserColumns() {
            this.userColumns.forEach((userColumn, index) => {
                let columnConfig = this.columnsConfig[userColumn.key];

                this.userColumns[index].disabled = columnConfig.disabled;
            });

            this.$store.commit({
                type: SET_USER_COLUMNS,
                groupType: 'multiAttraction',
                userColumns: this.userColumns
            });
        },

        /**
         * Update user columns config
         */
        refreshUserColumns() {
            this.updateUserColumns();

            return this.parseQuery(this.$route.query);
        },

        updateColumns() {
            let { sortedInfo, filteredInfo } = this;
            sortedInfo = sortedInfo || {};
            filteredInfo = filteredInfo || {};

            let columnsConfig = this.columnsConfig;

            let columns = [];

            this.userColumns.forEach((userColumn) => {
                let field = userColumn.key;
                let columnConfig = columnsConfig[field];
                let dataIndex = [];

                if (!userColumn.displayed || columnConfig.disabled) {
                    return;
                }

                field.split('/').forEach((fieldPart) => {
                    dataIndex.push(fieldPart);
                });

                let filterConfigData = null;

                if (typeof columnConfig.filters === 'object') {
                    filterConfigData = [];

                    for (let value in columnConfig.filters) {
                        filterConfigData.push({ text: columnConfig.filters[value], value: value });
                    }
                }

                columns.push({
                    title: typeof columnConfig.filters === 'undefined' ? undefined : columnConfig.label,
                    dataIndex: typeof columnConfig.dataIndex === 'undefined' ? dataIndex : columnConfig.dataIndex,
                    key: field,
                    // width: '45%',
                    label: columnConfig.label,
                    slots: {
                        title: field
                    },
                    fixed: columnConfig.fixed,
                    filters: filterConfigData,
                    customFilter: {
                        enabled: typeof columnConfig.filters === 'undefined',
                        type: typeof columnConfig.customFilter === 'undefined' ? 'input' : columnConfig.customFilter,
                        placeholder: 'Szűrő',
                        autocomplete: typeof columnConfig.autocomplete === 'undefined' ? function () {
                        } : columnConfig.autocomplete,
                    },
                    filteredValue: filteredInfo[field] || null,
                    filterMultiple: columnConfig.filterMultiple,
                    render: typeof columnConfig.filters === 'undefined',
                    sorter: typeof columnConfig.sorter === 'undefined' ? true : columnConfig.sorter,
                    sortOrder: sortedInfo.columnKey === field && sortedInfo.order,
                    scopedSlots: typeof columnConfig.scopedSlots === 'undefined' ? {} : columnConfig.scopedSlots,
                    width: columnConfig.width
                });
            });

            this.columns = columns;
        },

        /**
         * @param data
         */
        rowKeyGen(data) {
            return data.uid;
        },

        /**
         * @param pagination
         * @param filters
         * @param sorter
         */
        handleChange(pagination, filters, sorter) {
            console.debug('Handle change');

            this.pagination = pagination;
            this.filteredInfo = filters;
            this.sortedInfo = sorter;

            this.setQueryParams();
        },

        /**
         * Set query params from internal parameters
         */
        setQueryParams() {
            console.debug('Set query params');

            let limit = this.getLimit();

            if (limit === this.pagination.defaultPageSize) {
                limit = undefined;
            }

            let offset = this.getOffset();
            let defaultOffset = (this.pagination.defaultCurrent - 1) * this.pagination.defaultPageSize;

            if (offset === defaultOffset) {
                offset = undefined;
            }

            if (this.silent) {
                this.silent = false;

                return;
            }

            return this.$router.push({
                query: {
                    ...this.getFilters(),
                    limit: limit,
                    offset: offset,
                    order: this.getOrder()
                }
            }).catch((error) => {
                // Not changed
            });
        },

        /**
         * @param value
         */
        search(value) {
            this.pagination.current = this.pagination.defaultCurrent;

            this.setQueryParams();
        },

        /**
         * @param event
         */
        preventSortClick(event) {
            if (event.target.nodeName !== 'SPAN') {
                event.stopPropagation();
            }
        },

        /**
         * Create fields string for query
         *
         * @returns string|null
         */
        getFields() {
            let fields = [];

            this.userColumns.forEach((column) => {
                fields.push(column.key);
            });

            return fields.concat(this.hiddenFields).join(',');
        },

        /**
         * Create order string for query
         *
         * @returns string
         */
        getOrder() {
            if (!this.sortedInfo || typeof this.sortedInfo.columnKey === 'undefined') {
                return undefined;
            }

            let orderString = this.sortedInfo.columnKey;

            switch (this.sortedInfo.order) {
                case 'descend':
                    orderString += ' DESC';

                    break;
                case 'ascend':
                    orderString += ' ASC';

                    break;
                default:
                    return undefined;
            }

            return orderString;
        },

        /**
         * Collect filters for query
         *
         * @returns {{}}
         */
        getFilters(forQuery) {
            let filters = {}

            for (let columnName in this.filters) {
                let columnConfig = this.columnsConfig[columnName];
                let value = this.filters[columnName];

                if (null === value || '' === value) {
                    continue;
                }

                switch (columnConfig.customFilter) {
                    case 'date-range':
                        if (typeof value[0] !== 'object' || typeof value[1] !== 'object') {
                            break;
                        }

                        let rangeStart = value[0].clone();
                        let rangeEnd = value[1].clone();

                        if (forQuery) {
                            filters[columnName] = '(>= ' + rangeStart.format('YYYY-MM-DD') + ',< ' + rangeEnd.add(1, 'days').format('YYYY-MM-DD') + ')';
                        } else {
                            filters[columnName] = '(>= ' + rangeStart.format('YYYY-MM-DD') + ',<= ' + rangeEnd.format('YYYY-MM-DD') + ')';
                        }

                        break;
                    case 'multiInput':
                        let searchValue = [...value];

                        if (columnConfig.wildcard) {
                            for (let i in searchValue) {
                                searchValue[i] = '%' + searchValue[i].replace(/%/g, '') + '%';
                            }
                        }

                        filters[columnName] = searchValue.join(',');
                        break;

                    case 'input':
                    default:
                        filters[columnName] = '%' + value + '%';

                        break;
                }
            }

            for (let columnName in this.filteredInfo) {
                let value = this.filteredInfo[columnName];

                if (null === value || '' === value) {
                    continue;
                }

                if (typeof value === 'object') {
                    filters[columnName] = value.join(',');

                    continue;
                }

                filters[columnName] = '%' + value + '%'
            }

            console.debug('Filters', filters);

            return filters;
        },

        /**
         * @param {string} columnName
         *
         * @return {boolean}
         */
        isColumnDisplayed(columnName) {
            let columnConfig = this.columnsConfig[columnName];

            if (columnConfig.disabled) {
                return false;
            }

            let displayed = true;

            this.userColumns.forEach((userColumn) => {
                if (userColumn.key === columnName) {
                    displayed = userColumn.displayed;
                }
            });

            return displayed;
        },

        /**
         * Parse filters from query
         */
        parseFilters(filters) {
            for (let columnName in filters) {
                let columnConfig = this.columnsConfig[columnName];
                let value = filters[columnName];

                if (null === value || '' === value || !this.isColumnDisplayed(columnName)) {
                    continue;
                }

                if (columnConfig.filters) {
                    if (columnConfig.filterMultiple) {
                        this.filteredInfo[columnName] = value.split(',');
                    } else {
                        this.filteredInfo[columnName] = value;
                    }

                    continue;
                }

                switch (columnConfig.customFilter) {
                    case 'date-range':
                        let matches = value.match(/\(\>\=\s([0-9\-]+)\,\<\=?\s([0-9\-]+)\)/);

                        if (matches) {
                            this.filters[columnName] = [
                                moment(matches[1]),
                                moment(matches[2])
                            ];
                        }

                        break;
                    case 'multiInput':
                        let values = value.split(',');

                        if (columnConfig.wildcard) {
                            for (let i in values) {
                                values[i] = values[i].replace(/%/g, '');
                            }
                        }

                        this.filters[columnName] = values;

                        break;

                    case 'input':
                    default:
                        value = '' + value;

                        this.filters[columnName] = value.replace(/%/g, '');
                }
            }

            console.debug('Parse filters', this.filters);

            this.updateColumns();
        },

        getApiUrl() {
            switch (this.selectedRole) {
                // Roles with areas
                case 'ROLE_MTU_ADMIN':
                case 'ROLE_MTU_VIEWER':
                    return '/api/v1/companies/1/me/attractions';

                case 'ROLE_OFFICE_SPECIALIST':
                    return '/api/v1/companies/1/me/attractions/by-areas';

                // Office based roles
                case 'ROLE_OFFICE_LEADER':
                case 'ROLE_OFFICE_EMPLOYEE':
                default:
                    return '/api/v1/companies/1/me/attractions/by-office';
            }
        },

        /**
         * @return {int}
         */
        getLimit() {
            return this.pagination.pageSize;
        },

        /**
         * @return {int}
         */
        getOffset() {
            return this.pagination.pageSize * (this.pagination.current - 1);
        },

        parseQuery(query) {
            console.debug('Parse query');

            this.clearFilters();

            query = cloneDeep(query);

            if (query.limit) {
                this.pagination.pageSize = Math.min(parseInt(query.limit), 30);
            }

            if (query.offset) {
                this.pagination.current = parseInt((query.offset / this.pagination.pageSize) + 1);
            }

            if (query.order) {
                let orderMatches = query.order.match(/([a-zA-Z\/\_\-]+)\s*(asc|desc)?$/i);

                if (orderMatches && orderMatches[1]) {
                    let orderColumnName = orderMatches[1];
                    let columnConfig = this.columnsConfig[orderColumnName];

                    if (!columnConfig.disabled) {
                        this.sortedInfo.columnKey = orderColumnName;

                        if (orderMatches[2]) {
                            switch (orderMatches[2].toUpperCase()) {
                                case 'DESC':
                                    this.sortedInfo.order = 'descend'

                                    break;

                                case 'ASC':
                                default:
                                    this.sortedInfo.order = 'ascend';

                                    break;
                            }
                        }
                    }
                }
            }

            delete query.limit;
            delete query.offset;
            delete query.order;

            this.parseFilters(query);

            this.hasFilters = this.hasFilterApplied();

            return this.setQueryParams();
        },

        /**
         * @return {boolean}
         */
        hasFilterApplied() {
            return !isEqual(this.filters, this.defaultFilters)
                || !isEqual(this.filteredInfo, this.defaultFilteredInfo)
                || !isEqual(this.sortedInfo, this.defaultSortedInfo);
        },

        /**
         * Load data from API
         */
        fetchData(force, silent) {
            console.debug('Fetch data', force ? '(Force)' : '');

            if (silent) {
                this.silent = true;
            }

            if (this.$route.name === this.queryRouteName) {
                this.parseQuery(this.$route.query);
            }

            let apiUrl = this.getApiUrl();
            let params = {
                envelope: true,
                fields: this.getFields(),
                filter: { ...this.baseFilters, ...this.getFilters(true) },
                locale: this.$store.state.attraction.locale,
                limit: this.getLimit(),
                offset: this.getOffset(),
                order: this.getOrder(),
                possibleValues: Object.keys(this.possibleValues).join(',')
            };

            let requestParamString = JSON.stringify({
                apiUrl: apiUrl,
                params: params
            });

            if (!force && requestParamString === this.lastRequestParamString) {
                return;
            }

            this.loading = true;
            this.attractionPublishLoadingStates = {};

            this.lastRequestParamString = requestParamString;

            return axios.get(
                apiUrl,
                {
                    params: params
                }
            ).then((response) => {
                this.pagination.total = parseInt(response.data.meta.queryRecords);

                for (let field in this.possibleValues) {
                    let values = response.data.meta.possibleValues[field];

                    this.possibleValues[field] = {};

                    values.forEach((valueData) => {
                        if (!valueData.available) {
                            return;
                        }

                        this.possibleValues[field][valueData.value] = valueData.value;
                    });
                }

                this.updateColumns();

                this.data = response.data.content;

                this.loading = false;
            }).catch((error) => {
                this.$message.error('A vonzerők betöltése sikertelen!');

                this.loading = false;
            });
        },

        /**
         * @param row
         * @returns {string}
         */
        getUrl(row) {
            return {
                name: 'neta-basic-multi-attraction-attraction-data-form',
                params: {
                    id: row.id
                }
            }
        },

        /**
         * @param roles
         * @returns boolean
         */
        areRolesSufficient(roles) {
            return this.$store.getters.hasRoleAvailable(roles);
        },

        /**
         * @TODO
         */
        togglePublication(attraction) {
            this.loading = true;
            this.attractionPublishLoadingStates[attraction.uid] = true;

            if (!attraction.published) {
                this.$message.loading({
                    content: attraction.name + ' publikálása..',
                    duration: 0,
                    key: 'TOGGLE_PUBLICATION'
                });

                axios.patch('/api/v1/companies/1/me/attractions/' + attraction.id + '/publish', {
                    meta: {
                        selectedRole: this.selectedRole,
                        formId: null
                    }
                }).then(response => {
                        if (response.data.isValid) {
                            attraction.published = 1;

                            this.$message.success({
                                content: attraction.name + ' sikeresen publikálva!',
                                key: 'TOGGLE_PUBLICATION'
                            });
                        } else {
                            this.$message.error({
                                content: attraction.name + ' publikálása sikertelen!',
                                key: 'TOGGLE_PUBLICATION'
                            });
                        }

                        this.attractionPublishLoadingStates[attraction.uid] = false;

                        this.fetchData(true);

                        this.$store.dispatch({
                            type: REFRESH_ATTRACTION,
                            attractionId: attraction.id,
                            groupType: 'attraction'
                        });
                    }
                ).catch(error => {
                        this.loading = false;
                        this.attractionPublishLoadingStates[attraction.uid] = false;

                        this.$message.error({
                            content: attraction.name + ' publikálása sikertelen!',
                            key: 'TOGGLE_PUBLICATION'
                        });
                    }
                );

            } else {
                this.$message.loading({
                    content: attraction.name + ' publikálásának visszavonása..',
                    duration: 0,
                    key: 'TOGGLE_PUBLICATION'
                });

                axios.patch('/api/v1/companies/1/me/attractions/' + attraction.id + '/unpublish', {
                    meta: {
                        selectedRole: this.selectedRole,
                        formId: null
                    }
                }).then(response => {
                        if (response.data.isValid) {
                            attraction.published = 1;

                            this.$message.success({
                                content: attraction.name + ' publikálása sikeresen visszavonva!',
                                key: 'TOGGLE_PUBLICATION'
                            });
                        } else {
                            this.$message.error({
                                content: attraction.name + ' publikálásának visszavonása sikertelen!',
                                key: 'TOGGLE_PUBLICATION'
                            });
                        }

                        this.attractionPublishLoadingStates[attraction.uid] = false;

                        this.fetchData(true);

                        this.$store.dispatch({
                            type: REFRESH_ATTRACTION,
                            attractionId: attraction.id,
                            groupType: 'attraction'
                        });
                    }
                ).catch(error => {
                        this.loading = false;
                        this.attractionPublishLoadingStates[attraction.uid] = false;

                        this.$message.error({
                            content: attraction.name + ' publikálásának visszavonása sikertelen!',
                            key: 'TOGGLE_PUBLICATION'
                        });
                    }
                );
            }
        },

        /**
         * @param {string} time
         * @returns {moment.Moment}
         */
        moment(time) {
            return moment(time);
        },

        /**
         * @param {object} attraction
         */
        confirmDelete(attraction) {
            axios.delete(
                '/api/v1/companies/1/me/attractions/' + attraction.id
            ).then((response) => {
                if (response.data.isValid) {
                    this.$message.success('A vonzerő törlése sikeres!');

                    this.fetchData(true);
                } else {
                    this.$message.error('Hiba a vonzerő törlése közben!');
                }
            }).catch(() => {
                this.$message.error('A vonzerő törlése sikertelen!');
            });
        }
    }
}
</script>

<style lang="scss">
.ant-table-thead {
  & > tr > th.ant-table-column-sort {
    border-bottom-color: $secondary-green;
  }

  .ant-table-column-sorter {
    .on {
      color: $secondary-green !important;
    }

    i {
      font-size: 16px !important;
    }
  }

  .anticon-filter.ant-dropdown-trigger {
    font-size: 16px !important;
  }
}

.ant-tag {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}
</style>

<style lang="scss" scoped>
.list-export-container {
  padding-left: 20px;

  .export-submit {
    margin-left: 10px;
  }
}

.user-columns {
  .user-column {
    button {
      margin-top: -3px;
      margin-right: 5px;
      cursor: pointer;
    }

    padding: 5px 0;
    cursor: move;

    &:first-child {
      border-top: 0;
    }
  }
}

.user-columns-reset {
  margin: 5px 0;
}

.actions-top-row {
  display: flex;
  margin-bottom: 8px;
}

.action-button {
  margin-left: 8px;
}

.lock-yellow {
  color: $secondary-yellow;
  border-color: $secondary-yellow;
}

.lock-green {
  color: #38BA9B;
  border-color: #38BA9B;
}

button.clear-filter-button {
  margin-left: 16px;
  margin-right: 16px;
}
</style>