var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-form",
    {
      staticClass: "login-form",
      attrs: { id: "login-component-form", form: _vm.form },
      on: { submit: _vm.submit }
    },
    [
      _c(
        "a-form-item",
        [
          _c(
            "a-input",
            {
              directives: [
                {
                  name: "decorator",
                  rawName: "v-decorator",
                  value: [
                    "userName",
                    {
                      rules: [
                        {
                          required: true,
                          message: "Kérjük írja be a felhasználónevét!"
                        }
                      ]
                    }
                  ],
                  expression:
                    "[\n                  'userName',\n                  {\n                      rules: [{\n                          required: true,\n                          message: 'Kérjük írja be a felhasználónevét!'\n                      }]\n                  }\n              ]"
                }
              ],
              attrs: { placeholder: "Felhasználónév" }
            },
            [
              _c("a-icon", {
                staticStyle: { color: "rgba(0, 0, 0, 0.5)" },
                attrs: { slot: "prefix", type: "user" },
                slot: "prefix"
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-form-item",
        [
          _c(
            "a-input",
            {
              directives: [
                {
                  name: "decorator",
                  rawName: "v-decorator",
                  value: [
                    "password",
                    {
                      rules: [
                        {
                          required: true,
                          message: "Kérjük írja be a jelszavát!"
                        }
                      ]
                    }
                  ],
                  expression:
                    "[\n                  'password',\n                  {\n                      rules: [{\n                          required: true,\n                          message: 'Kérjük írja be a jelszavát!'\n                      }]\n                  }\n              ]"
                }
              ],
              attrs: { type: "password", placeholder: "Jelszó" }
            },
            [
              _c("a-icon", {
                staticStyle: { color: "rgba(0, 0, 0, 0.5)" },
                attrs: { slot: "prefix", type: "lock" },
                slot: "prefix"
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-form-item",
        [
          _c(
            "a-checkbox",
            {
              directives: [
                {
                  name: "decorator",
                  rawName: "v-decorator",
                  value: [
                    "rememberMe",
                    {
                      valuePropName: "checked",
                      initialValue: true
                    }
                  ],
                  expression:
                    "[\n                  'rememberMe',\n                  {\n                      valuePropName: 'checked',\n                      initialValue: true\n                  }\n              ]"
                }
              ],
              staticClass: "remember-button"
            },
            [_vm._v("\n      Emlékezzen rám\n    ")]
          ),
          _c(
            "router-link",
            {
              staticClass: "forgot-button",
              attrs: { to: "/forgotten-password" }
            },
            [_c("a", [_vm._v("\n        Elfelejtett jelszó\n      ")])]
          ),
          _c(
            "a-button",
            {
              staticClass: "login-form-button",
              attrs: {
                loading: _vm.loading,
                type: "primary",
                "html-type": "submit"
              }
            },
            [_vm._v("\n      Bejelentkezés\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }