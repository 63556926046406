<template xmlns="">
  <div class="app-form-container">
    <a-spin :spinning="formDataLoading">
      <a-form :form="form" v-on:submit="submit" layout="vertical">
        <a-form-item>
          <a-button type="primary" html-type="submit" :loading="submitLoading" v-if="showSaveButton && !isMobileReadonly">
            Mentés
          </a-button>

          <a-button type="secondary" html-type="button" v-on:click="resetForm" v-if="hasChanged()">
            <a-icon type="undo"
                    theme="outlined" />

            Piszkozat törlése
          </a-button>

          <span v-if="currentTab" class="last-modification">
            Utolsó mentés: <strong>{{ moment(currentTab.modificationTime).format(timeStampFormat).toString() }}</strong> &nbsp;<span v-if="currentTab.user">({{ currentTab.user.userName }})</span>
          </span>

          <a-alert
              :style="{
                        marginTop: '10px'
                    }"
              v-if="submitResult.show"
              :message="submitResult.message"
              :type="submitResult.type"
              showIcon
              closable
              :afterClose="() => submitResult.show = false"
          />
        </a-form-item>

        <a-divider orientation="left">
          AZ ESEMÉNY AKADÁLYMENTESSÉGÉRE VONATKOZÓ ADATOK
        </a-divider>

        <a-form-item label="Fizikai akadálymentesítés (kerekesszékkel bejárható)">
          <a-select
              :disabled="isMobileReadonly"
              v-decorator="[
                        'accessibility/physically',
                        {
                            rules: [{
                                required: isNotMobile,
                                message: 'Kérjük töltse ki!'
                            }],
                            validateTrigger: 'blur'
                        }
                    ]"
              placeholder="Kérjük válasszon!"
          >
            <a-select-option :value="1">
              Igen
            </a-select-option>

            <a-select-option :value="0">
              Nem
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="Hallássérültek számára akadálymentesített">
          <a-select
              :disabled="isMobileReadonly"
              v-decorator="[
                        'accessibility/deaf',
                        {
                            rules: [{
                                required: isNotMobile,
                                message: 'Kérjük töltse ki!'
                            }],
                            validateTrigger: 'blur'
                        }
                    ]"
              placeholder="Kérjük válasszon!"
          >
            <a-select-option :value="1">
              Igen
            </a-select-option>

            <a-select-option :value="0">
              Nem
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="Látássérültek számára akadálymentesített">
          <a-select
              :disabled="isMobileReadonly"
              v-decorator="[
                        'accessibility/blind',
                        {
                            rules: [{
                                required: isNotMobile,
                                message: 'Kérjük töltse ki!'
                            }],
                            validateTrigger: 'blur'
                        }
                    ]"
              placeholder="Kérjük válasszon!"
          >
            <a-select-option :value="1">
              Igen
            </a-select-option>

            <a-select-option :value="0">
              Nem
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="Lift rendelkezésre áll-e?">
          <a-select
              :disabled="isMobileReadonly"
              v-decorator="[
                        'accessibility/elevator',
                        {
                            rules: [{
                                required: false,
                                message: 'Kérjük töltse ki!'
                            }]
                        }
                    ]"
              placeholder="Kérjük válasszon!"
          >
            <a-select-option :value="1">
              Igen
            </a-select-option>

            <a-select-option :value="0">
              Nem
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
            :selfUpdate="true"
            v-show="form.getFieldValue('accessibility/elevator') === 1"
            label="Ha IGEN, akkor liftek száma"
        >
          <a-input-number
              :min="0"
              :disabled="isMobileReadonly"
              v-decorator="[
                        `accessibility/elevatorCount`,
                        {
                            validateTrigger: ['blur', 'change'],
                            preserve: true,
                            rules: [{
                                required: false,
                                type: 'integer',
                                whitespace: true,
                                transform: (value) => {
                                  value = parseInt(value);

                                  return isNaN(value) ? null : value;
                                },
                                message: 'Kérjük töltse ki!'
                            }]

                        }
                    ]"
          >
          </a-input-number>
          db
        </a-form-item>

        <a-form-item label="Az esemény környezete akadálymentesített-e? (bejárattól 100 méteres körben)">
          <a-select
              :disabled="isMobileReadonly"
              v-decorator="[
                        'accessibility/area',
                        {
                            rules: [{
                                required: false,
                                message: 'Kérjük töltse ki!'
                            }]
                        }
                    ]"
              placeholder="Kérjük válasszon!"
          >
            <a-select-option :value="1">
              Igen
            </a-select-option>

            <a-select-option :value="0">
              Nem
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
            label="Kísérő személyzet igény esetén áll-e rendelkezésre?"
        >
          <a-select
              :disabled="isMobileReadonly"
              v-decorator="[
                        'accessibility/supportStaff',
                        {
                            rules: [{
                                required: false,
                                message: 'Kérjük töltse ki!'
                            }]
                        }
                    ]"
              placeholder="Kérjük válasszon!"
          >
            <a-select-option value="free">
              Igen (téritésmentesen)
            </a-select-option>

            <a-select-option value="fee">
              Igen (térítés ellenében)
            </a-select-option>

            <a-select-option value="no">
              Nem
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
            v-show="form.getFieldValue('accessibility/supportStaff') === 'free' || form.getFieldValue('accessibility/supportStaff') === 'fee'"
            label="Ha ennek igénye előre bejelentendő, akkor milyen elérhetőségen?"
        >
          <a-textarea
              :disabled="isMobileReadonly"
              :maxLength="25000"
              v-decorator="[
                        'accessibility/supportStaffContact',
                        {
                            rules: [
                                {
                                  required: false,
                                  message: 'Kérjük töltse ki!',
                                },
                                {
                                  max: characterCounts['accessibility/supportStaffContact'].max,
                                  message: 'Maximum ' + characterCounts['accessibility/supportStaffContact'].max + ' karakter engedélyezett.',
                                }
                              ]
                        }
                    ]"
              autoSize
          />
        </a-form-item>

        <a-divider />

        <template v-if="isNotMobile">
          <a-form-item :selfUpdate="true">
            <span slot="label">
                Megjegyzés

                <a-tooltip
                    title="Kérjük ezt a cellát akkor töltse ki, ha a témához kapcsolódóan egyéb megjegyzése van."
                >
                    <a-icon type="question-circle-o" />
                </a-tooltip>
            </span>

            <a-textarea
                :disabled="isMobileReadonly"
                :maxLength="25000"
                v-decorator="[
                          'accessibility/comment',
                          {
                              rules: [{
                                  required: false,
                                  message: 'Kérjük töltse ki!'
                              }]
                          }
                      ]"
                :autoSize="{
                          minRows: 2
                      }"
            />
          </a-form-item>
        </template>

        <a-form-item>
          <a-button type="primary" html-type="submit" :loading="submitLoading" v-if="showSaveButton && !isMobileReadonly">
            Mentés
          </a-button>

          <a-button type="secondary" html-type="button" v-on:click="resetForm" v-if="hasChanged()">
            <a-icon
                type="undo"
                theme="outlined"
            />

            Piszkozat törlése
          </a-button>

          <span v-if="currentTab" class="last-modification">
            Utolsó mentés: <strong>{{ moment(currentTab.modificationTime).format(timeStampFormat).toString() }}</strong> &nbsp;<span v-if="currentTab.user">({{ currentTab.user.userName }})</span>
          </span>

          <a-alert
              :style="{
                        marginTop: '10px'
                    }"
              v-if="submitResult.show"
              :message="submitResult.message"
              :type="submitResult.type"
              showIcon
              closable
              :afterClose="() => submitResult.show = false"
          />
        </a-form-item>
      </a-form>
    </a-spin>
  </div>
</template>

<script>
import { EditFormComponentMixin } from "@/mixins/EditFormComponentMixin";

export default {
  menuName: 'Akadálymentesség',
  name: 'accessibility-form',
  mixins: [EditFormComponentMixin],

  data() {
    return {
      formId: 'ACCESSIBILITY',

      formFields: {
        'accessibility/physically': 0,
        'accessibility/deaf': 0,
        'accessibility/blind': 0,
        'accessibility/elevator': 0,
        'accessibility/elevatorCount': null,
        'accessibility/area': 0,
        'accessibility/supportStaff': 'no',
        'accessibility/supportStaffContact': '',
        'accessibility/comment': '',
      },

      casts: {
        'accessibility/physically': 'int',
        'accessibility/deaf': 'int',
        'accessibility/blind': 'int',
        'accessibility/elevator': 'int',
        'accessibility/elevatorCount': '?int',
        'accessibility/area': 'int',
      },

      characterCounts: {
        'accessibility/supportStaffContact': {
          max: 500,
        },
      },
    }
  },

  methods: {}
}
</script>

<style>
</style>
