import LocalStorage from '@/helpers/localstorage.js';
import store from '@/store.js';

import {
    USER_LOGIN
} from '@/action_types.js';

import {
    SET_NAVIGATION_PROGRESS_STATE
} from '@/mutation_types.js';

export const beforeEach = (to, from, next) => {

    //start progressbar
    store.commit({
        type: SET_NAVIGATION_PROGRESS_STATE,
        navigationProgress: true
    })

    // guard routes behind login
    if (to.matched.some(record => record.meta.requiresLogin)) {
        if (!store.getters.isLoggedIn) {
            const data = LocalStorage.getUserData();
            if (data) {
                store.dispatch({
                    type: USER_LOGIN,
                    userName: data.userName,
                    office: data.office,
                    email: data.email,
                    roles: data.roles,
                    selectedRole: data.selectedRole
                })
                next()
            } else {
                next({
                    name: 'login'
                })
            }
        } else {
            next()
        }
    } else {
        next();
    }
}


export const afterEach = (to, from) => {
    store.commit({
        type: SET_NAVIGATION_PROGRESS_STATE,
        navigationProgress: false
    })
}