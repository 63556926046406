var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "container" }, [
      !_vm.readOnly
        ? _c("div", { staticClass: "toolbar" }, [
            _c("div", { staticClass: "toolbar-section" }, [
              !_vm.hide.bold
                ? _c(
                    "button",
                    {
                      staticClass: "wysiwyg-button",
                      class: _vm.isBold ? "wysiwyg-button-active" : "",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.bold.apply(null, arguments)
                        }
                      }
                    },
                    [_c("format-bold")],
                    1
                  )
                : _vm._e(),
              !_vm.hide.italic
                ? _c(
                    "button",
                    {
                      staticClass: "wysiwyg-button",
                      class: _vm.isItalic ? "wysiwyg-button-active" : "",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.italize.apply(null, arguments)
                        }
                      }
                    },
                    [_c("format-italic")],
                    1
                  )
                : _vm._e(),
              !_vm.hide.underline
                ? _c(
                    "button",
                    {
                      staticClass: "wysiwyg-button",
                      class: _vm.isUnderlined ? "wysiwyg-button-active" : "",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.underline.apply(null, arguments)
                        }
                      }
                    },
                    [_c("format-underline")],
                    1
                  )
                : _vm._e(),
              !_vm.hide.strikethrough
                ? _c(
                    "button",
                    {
                      staticClass: "wysiwyg-button",
                      class: _vm.isStrikedThrough
                        ? "wysiwyg-button-active"
                        : "",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.strikeThrough.apply(null, arguments)
                        }
                      }
                    },
                    [_c("format-strikethrough")],
                    1
                  )
                : _vm._e(),
              !_vm.hide.heading
                ? _c(
                    "button",
                    {
                      staticClass: "wysiwyg-button",
                      class: _vm.isHeading ? "wysiwyg-button-active" : "",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.showHeadings.apply(null, arguments)
                        }
                      }
                    },
                    [_c("format-header-1")],
                    1
                  )
                : _vm._e()
            ]),
            _c("div", { staticClass: "toolbar-section" }, [
              !_vm.hide.alignLeft
                ? _c(
                    "button",
                    {
                      staticClass: "wysiwyg-button",
                      class: _vm.isAlignedLeft ? "wysiwyg-button-active" : "",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.alignLeft.apply(null, arguments)
                        }
                      }
                    },
                    [_c("format-align-left")],
                    1
                  )
                : _vm._e(),
              !_vm.hide.alignCenter
                ? _c(
                    "button",
                    {
                      staticClass: "wysiwyg-button",
                      class: _vm.isAlignedCenter ? "wysiwyg-button-active" : "",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.alignCenter.apply(null, arguments)
                        }
                      }
                    },
                    [_c("format-align-center")],
                    1
                  )
                : _vm._e(),
              !_vm.hide.alignRight
                ? _c(
                    "button",
                    {
                      staticClass: "wysiwyg-button",
                      class: _vm.isAlignedRight ? "wysiwyg-button-active" : "",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.alignRight.apply(null, arguments)
                        }
                      }
                    },
                    [_c("format-align-right")],
                    1
                  )
                : _vm._e(),
              !_vm.hide.ol
                ? _c(
                    "button",
                    {
                      staticClass: "wysiwyg-button",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.orderedList.apply(null, arguments)
                        }
                      }
                    },
                    [_c("format-list-numbered")],
                    1
                  )
                : _vm._e(),
              !_vm.hide.ul
                ? _c(
                    "button",
                    {
                      staticClass: "wysiwyg-button",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.insertUnorderedList.apply(null, arguments)
                        }
                      }
                    },
                    [_c("format-list-bulleted")],
                    1
                  )
                : _vm._e()
            ]),
            _c("div", { staticClass: "toolbar-section" }, [
              !_vm.hide.url
                ? _c(
                    "button",
                    {
                      staticClass: "wysiwyg-button",
                      class: _vm.showLinkForm ? "wysiwyg-button-active" : "",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.showLinkOptions.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _c("b", { staticStyle: { "font-size": "10px" } }, [
                        _vm._v("URL")
                      ])
                    ]
                  )
                : _vm._e(),
              !_vm.hide.table
                ? _c(
                    "button",
                    {
                      staticClass: "wysiwyg-button",
                      class: _vm.showTableForm ? "wysiwyg-button-active" : "",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.showTableOptions.apply(null, arguments)
                        }
                      }
                    },
                    [_c("table-large")],
                    1
                  )
                : _vm._e(),
              !_vm.hide.indent
                ? _c(
                    "button",
                    {
                      staticClass: "wysiwyg-button",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.indent.apply(null, arguments)
                        }
                      }
                    },
                    [_c("format-indent-increase")],
                    1
                  )
                : _vm._e(),
              !_vm.hide.outdent
                ? _c(
                    "button",
                    {
                      staticClass: "wysiwyg-button",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.outdent.apply(null, arguments)
                        }
                      }
                    },
                    [_c("format-indent-decrease")],
                    1
                  )
                : _vm._e()
            ])
          ])
        : _vm._e(),
      _vm.showHeadingOptions
        ? _c(
            "div",
            { staticClass: "toolbar-options" },
            _vm._l(_vm.hSizes, function(size) {
              return _c(
                "button",
                {
                  key: size,
                  staticClass: "wysiwyg-button wysiwyg-button-loop",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.heading(size)
                    }
                  }
                },
                [_vm._v(_vm._s(size) + "\n      ")]
              )
            }),
            0
          )
        : _vm._e(),
      _vm.showLinkForm
        ? _c("div", { staticClass: "toolbar-options" }, [
            _c(
              "form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.createLink.apply(null, arguments)
                  }
                }
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.linkText,
                      expression: "linkText"
                    }
                  ],
                  attrs: {
                    type: "text",
                    placeholder: "Link szövege",
                    required: ""
                  },
                  domProps: { value: _vm.linkText },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.linkText = $event.target.value
                    }
                  }
                }),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.linkURL,
                      expression: "linkURL"
                    }
                  ],
                  attrs: { type: "text", placeholder: "URL", required: "" },
                  domProps: { value: _vm.linkURL },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.linkURL = $event.target.value
                    }
                  }
                }),
                _c(
                  "button",
                  { staticClass: "wysiwyg-button", attrs: { type: "submit" } },
                  [_vm._v("Beszúrás")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "wysiwyg-button",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.linkText = ""
                        _vm.linkURL = ""
                      }
                    }
                  },
                  [_vm._v("Törlés")]
                )
              ]
            )
          ])
        : _vm._e(),
      _vm.showTableForm
        ? _c("div", { staticClass: "toolbar-options" }, [
            _c(
              "form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.createTable.apply(null, arguments)
                  }
                }
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.rows,
                      expression: "rows"
                    }
                  ],
                  attrs: {
                    min: "0",
                    type: "number",
                    placeholder: "Sorok",
                    required: ""
                  },
                  domProps: { value: _vm.rows },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.rows = $event.target.value
                    }
                  }
                }),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.cols,
                      expression: "cols"
                    }
                  ],
                  attrs: {
                    min: "0",
                    type: "number",
                    placeholder: "Oszlopok",
                    required: ""
                  },
                  domProps: { value: _vm.cols },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.cols = $event.target.value
                    }
                  }
                }),
                _c(
                  "button",
                  { staticClass: "wysiwyg-button", attrs: { type: "submit" } },
                  [_vm._v("Beszúrás")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "wysiwyg-button",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.rows = null
                        _vm.cols = null
                      }
                    }
                  },
                  [_vm._v("Törlés")]
                )
              ]
            ),
            _c("div", { staticClass: "hoverTableCont" }, [
              _c(
                "div",
                {
                  staticClass: "hoverTable",
                  on: {
                    mouseleave: function($event) {
                      return _vm.changeSelectedCell(0, 0)
                    }
                  }
                },
                _vm._l(12, function(row, key) {
                  return _c(
                    "div",
                    { key: key, staticClass: "hoverRow" },
                    _vm._l(12, function(col, key) {
                      return _c("div", {
                        key: key,
                        class: [
                          "hoverCell",
                          { highlightCell: _vm.isHighlighted(row, col) }
                        ],
                        on: {
                          mouseover: function($event) {
                            return _vm.changeSelectedCell(row, col)
                          },
                          click: _vm.setupCreateTable
                        }
                      })
                    }),
                    0
                  )
                }),
                0
              ),
              _c("br"),
              _c("div", { staticClass: "tableDimmensions" }, [
                _vm.noSelectedCell
                  ? _c("em", [_vm._v("Válasszon cellákat felül")])
                  : _c("em", [
                      _vm._v(
                        _vm._s(this.selectedCell.cols) +
                          " x " +
                          _vm._s(this.selectedCell.rows)
                      )
                    ])
              ])
            ])
          ])
        : _vm._e(),
      _c("div", { staticClass: "body", style: { height: _vm.height + "px" } }, [
        _vm.readOnly
          ? _c("div", {
              ref: "editor",
              staticClass: "editor",
              style: { "min-height": _vm.height + "px" }
            })
          : _c("div", {
              ref: "editor",
              staticClass: "editor",
              style: { "min-height": _vm.height + "px" },
              attrs: { contenteditable: "" }
            })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }