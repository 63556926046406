var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.isViewer
        ? _c(
            "div",
            { staticClass: "list-export-container" },
            [
              _c("h3", [_vm._v("Vonzerő adatexport")]),
              _c(
                "a-form",
                {
                  attrs: { form: _vm.exportForm },
                  on: { submit: _vm.submitExport }
                },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "Vonzerő adatkategória" } },
                    [
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "a-select",
                                {
                                  directives: [
                                    {
                                      name: "decorator",
                                      rawName: "v-decorator",
                                      value: [
                                        "formId",
                                        {
                                          rules: [
                                            {
                                              required: true,
                                              message: "Kérjük, töltse ki!"
                                            }
                                          ],
                                          validateTrigger: ["blur", "change"]
                                        }
                                      ],
                                      expression:
                                        "['formId',\n                                {\n                                  rules: [{\n                                      required: true,\n                                      message: 'Kérjük, töltse ki!'\n                                  }],\n                                    validateTrigger: ['blur', 'change']\n                                }\n                            ]"
                                    }
                                  ],
                                  attrs: { placeholder: "Kérjük válasszon!" }
                                },
                                _vm._l(_vm.formIdList, function(label, formId) {
                                  return _c(
                                    "a-select-option",
                                    { key: formId, attrs: { value: formId } },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(label) +
                                          "\n                            "
                                      )
                                    ]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "a-button",
                                {
                                  staticClass: "export-submit",
                                  attrs: {
                                    type: "primary",
                                    "html-type": "submit",
                                    loading: _vm.exportLoading
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                            Export letöltése\n                        "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("a-divider")
            ],
            1
          )
        : _vm._e(),
      _c(
        "a-button",
        {
          staticClass: "clear-filter-button",
          attrs: {
            type: "primary",
            icon: "rollback",
            disabled: !_vm.hasFilters
          },
          on: {
            click: function($event) {
              return _vm.resetFilters()
            }
          }
        },
        [_vm._v("\n        Szűrők visszaállítása\n    ")]
      ),
      _c(
        "a-popover",
        {
          attrs: { placement: "bottomLeft", trigger: "click" },
          on: {
            click: function($event) {
              _vm.customizingColumns = !_vm.customizingColumns
            }
          },
          model: {
            value: _vm.customizingColumns,
            callback: function($$v) {
              _vm.customizingColumns = $$v
            },
            expression: "customizingColumns"
          }
        },
        [
          _c(
            "template",
            { slot: "title" },
            [
              _c(
                "a-button",
                {
                  staticClass: "user-columns-reset",
                  attrs: { size: "small", icon: "rollback" },
                  on: { click: _vm.resetUserColumns }
                },
                [
                  _vm._v(
                    "\n                Alaphelyzetbe állítás\n            "
                  )
                ]
              )
            ],
            1
          ),
          _c("template", { slot: "content" }, [
            _c(
              "div",
              { staticClass: "user-columns" },
              [
                _c(
                  "draggable",
                  {
                    on: { end: _vm.refreshUserColumns },
                    model: {
                      value: _vm.userColumns,
                      callback: function($$v) {
                        _vm.userColumns = $$v
                      },
                      expression: "userColumns"
                    }
                  },
                  [
                    _c(
                      "transition-group",
                      _vm._l(_vm.userColumns, function(column, index) {
                        return _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  !column.system &&
                                  !_vm.userColumns[index].disabled,
                                expression:
                                  "!column.system && !userColumns[index].disabled"
                              }
                            ],
                            key: column.key,
                            staticClass: "user-column"
                          },
                          [
                            _c("a-switch", {
                              attrs: {
                                size: "small",
                                disabled: _vm.userColumns[index].disabled
                              },
                              on: { change: _vm.refreshUserColumns },
                              model: {
                                value: _vm.userColumns[index].displayed,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.userColumns[index],
                                    "displayed",
                                    $$v
                                  )
                                },
                                expression: "userColumns[index].displayed"
                              }
                            }),
                            _vm._v(
                              "\n\n                            " +
                                _vm._s(column.label) +
                                "\n                        "
                            )
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "a-button",
            {
              staticClass: "clear-filter-button",
              attrs: { type: "default", icon: "table" }
            },
            [_vm._v("\n            Oszlopok testreszabása\n        ")]
          )
        ],
        2
      ),
      _c(
        "a-table",
        {
          attrs: {
            columns: _vm.columns,
            "row-key": _vm.rowKeyGen,
            "data-source": _vm.data,
            pagination: _vm.pagination,
            loading: _vm.loading,
            scroll: { x: 800 },
            locale: _vm.locale
          },
          on: { change: _vm.handleChange },
          scopedSlots: _vm._u([
            {
              key: "modification-time-content",
              fn: function(modificationTime) {
                return _c("div", {}, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm
                          .moment(modificationTime)
                          .format(_vm.timeStampFormat)
                          .toString()
                      ) +
                      "\n        "
                  )
                ])
              }
            },
            {
              key: "category-content",
              fn: function(category) {
                return _c(
                  "div",
                  {},
                  [
                    category
                      ? _c("a-tag", [
                          _vm._v(
                            "\n                " +
                              _vm._s(category) +
                              "\n            "
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                )
              }
            },
            {
              key: "labels-content",
              fn: function(labels) {
                return _c(
                  "div",
                  {},
                  _vm._l(labels, function(label) {
                    return _c("a-tag", { key: label.id }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(label.name) +
                          "\n            "
                      )
                    ])
                  }),
                  1
                )
              }
            },
            {
              key: "status-content",
              fn: function(status) {
                return _c("div", {}, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.statusNames[status]) +
                      "\n        "
                  )
                ])
              }
            },
            {
              key: "published-content",
              fn: function(published) {
                return _c("div", {}, [
                  _vm._v(
                    "\n            " +
                      _vm._s(published ? "Igen" : "Nem") +
                      "\n        "
                  )
                ])
              }
            },
            {
              key: "web-published-content",
              fn: function(webPublished) {
                return _c("div", {}, [
                  _vm._v(
                    "\n            " +
                      _vm._s(webPublished ? "Igen" : "Nem") +
                      "\n        "
                  )
                ])
              }
            },
            {
              key: "mobile-published-content",
              fn: function(mobilePublished) {
                return _c("div", {}, [
                  _vm._v(
                    "\n            " +
                      _vm._s(mobilePublished ? "Igen" : "Nem") +
                      "\n        "
                  )
                ])
              }
            },
            {
              key: "actions-content",
              fn: function(row) {
                return _c("div", { style: { width: "150px" } }, [
                  _c(
                    "div",
                    { staticClass: "actions-top-row" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: _vm.getUrl(row) } },
                        [
                          _c("a-button", {
                            staticClass: "action-button",
                            attrs: {
                              icon: _vm.isViewer ? "eye" : "edit",
                              type: "primary"
                            }
                          })
                        ],
                        1
                      ),
                      _vm.areRolesSufficient(["ROLE_MTU_ADMIN"]) &&
                      !(row.status === 0)
                        ? _c(
                            "a-popconfirm",
                            {
                              attrs: {
                                title: row.published
                                  ? "Biztosan visszavonja a publikálást?"
                                  : "Biztosan publikálja?",
                                okText: "Igen",
                                cancelText: "Mégse",
                                okType: "primary"
                              },
                              on: {
                                confirm: function($event) {
                                  return _vm.togglePublication(row)
                                }
                              }
                            },
                            [
                              _c("a-button", {
                                class: {
                                  "action-button": true,
                                  "lock-yellow": !row.published,
                                  "lock-green": row.published
                                },
                                attrs: {
                                  icon: row.published ? "unlock" : "lock",
                                  disabled: row.status === 0,
                                  loading:
                                    _vm.attractionPublishLoadingStates[row.uid],
                                  type: "ghost"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.isViewer
                        ? _c(
                            "a-popconfirm",
                            {
                              attrs: {
                                title: "Biztos törli a vonzerőt?",
                                okText: "Igen",
                                cancelText: "Mégse",
                                okType: "danger"
                              },
                              on: {
                                confirm: function($event) {
                                  return _vm.confirmDelete(row)
                                }
                              }
                            },
                            [
                              _c("a-button", {
                                staticClass: "action-button",
                                attrs: { icon: "delete", type: "danger" }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ])
              }
            }
          ])
        },
        _vm._l(_vm.columns, function(column) {
          return column.render
            ? _c(
                "div",
                {
                  attrs: { slot: column.slots.title },
                  on: { click: _vm.preventSortClick },
                  slot: column.slots.title
                },
                [
                  _c("span", { domProps: { innerHTML: _vm._s(column.label) } }),
                  column.customFilter.type === "input"
                    ? _c("a-input-search", {
                        attrs: { placeholder: column.customFilter.placeholder },
                        on: { search: _vm.search },
                        model: {
                          value: _vm.filters[column.key],
                          callback: function($$v) {
                            _vm.$set(_vm.filters, column.key, $$v)
                          },
                          expression: "filters[column.key]"
                        }
                      })
                    : _vm._e(),
                  column.customFilter.type === "multiInput"
                    ? _c("a-select", {
                        style: { width: "100%" },
                        attrs: {
                          placeholder: column.customFilter.placeholder,
                          mode: "tags",
                          "token-separators": [","]
                        },
                        on: { change: _vm.search },
                        model: {
                          value: _vm.filters[column.key],
                          callback: function($$v) {
                            _vm.$set(_vm.filters, column.key, $$v)
                          },
                          expression: "filters[column.key]"
                        }
                      })
                    : _vm._e(),
                  column.customFilter.type === "date-range"
                    ? _c("a-range-picker", {
                        staticClass: "datatable-range-picker",
                        attrs: { separator: "", format: _vm.dateFormat },
                        on: { change: _vm.search },
                        model: {
                          value: _vm.filters[column.key],
                          callback: function($$v) {
                            _vm.$set(_vm.filters, column.key, $$v)
                          },
                          expression: "filters[column.key]"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }