var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    { staticClass: "welcome-header" },
    [
      _c("a-col", { attrs: { xs: 0, sm: 0, md: 8 } }, [
        _c("div", { staticClass: "header-left" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/images/magyarorszag_logo_color_white_text.png")
            }
          })
        ])
      ]),
      _c(
        "a-col",
        { staticClass: "middle-col", attrs: { xs: 18, sm: 18, md: 8 } },
        [_c("HeaderTitleComponent")],
        1
      ),
      _c("a-col", { attrs: { xs: 6, sm: 6, md: 8 } }, [
        _c(
          "div",
          { staticClass: "header-right", staticStyle: { display: "none" } },
          [
            _c("a-icon", {
              attrs: { slot: "prefix", type: "info-circle" },
              slot: "prefix"
            }),
            _vm._v("\n      Info\n    ")
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }