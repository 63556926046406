var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-form-container" },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.formDataLoading } },
        [
          _c(
            "a-form",
            {
              attrs: { form: _vm.form, layout: "vertical" },
              on: { submit: _vm.submit }
            },
            [
              _c(
                "a-form-item",
                [
                  _vm.showSaveButton && !_vm.isMobileReadonly
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            "html-type": "submit",
                            loading: _vm.submitLoading
                          }
                        },
                        [_vm._v("\n            Mentés\n          ")]
                      )
                    : _vm._e(),
                  _vm.hasChanged()
                    ? _c(
                        "a-button",
                        {
                          attrs: { type: "secondary", "html-type": "button" },
                          on: { click: _vm.resetForm }
                        },
                        [
                          _c("a-icon", {
                            attrs: { type: "undo", theme: "outlined" }
                          }),
                          _vm._v(
                            "\n\n            Piszkozat törlése\n          "
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.currentTab
                    ? _c("span", { staticClass: "last-modification" }, [
                        _vm._v("\n            Utolsó mentés: "),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm
                                .moment(_vm.currentTab.modificationTime)
                                .format(_vm.timeStampFormat)
                                .toString()
                            )
                          )
                        ]),
                        _vm._v("  "),
                        _vm.currentTab.user
                          ? _c("span", [
                              _vm._v(
                                "(" + _vm._s(_vm.currentTab.user.userName) + ")"
                              )
                            ])
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm.submitResult.show
                    ? _c("a-alert", {
                        style: {
                          marginTop: "10px"
                        },
                        attrs: {
                          message: _vm.submitResult.message,
                          type: _vm.submitResult.type,
                          showIcon: "",
                          closable: "",
                          afterClose: function() {
                            return (_vm.submitResult.show = false)
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c("a-divider", { attrs: { orientation: "left" } }, [
                _vm._v(
                  "\n          A VONZERŐ NYITA TARTÁSÁRA VONATKOZÓ ADATOK\n        "
                )
              ]),
              _c(
                "a-form-item",
                { attrs: { label: "Látogatható?" } },
                [
                  _c(
                    "a-radio-group",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "opening/status",
                            {
                              rules: [
                                {
                                  required: _vm.isNotMobile,
                                  preserve: true,
                                  message: "Kérjük válasszon!"
                                }
                              ]
                            }
                          ],
                          expression:
                            "[\n                        'opening/status',\n                        {\n                            rules: [{\n\n                                required: isNotMobile,\n                                preserve: true,\n                                message: 'Kérjük válasszon!',\n                            }]\n                        }\n                    ]"
                        }
                      ],
                      attrs: { disabled: _vm.isMobileReadonly }
                    },
                    [
                      _c("a-radio-button", { attrs: { value: "open" } }, [
                        _vm._v("Megadott nyitva tartás szerint nyitva")
                      ]),
                      _c(
                        "a-radio-button",
                        { attrs: { value: "closed_indefinitely" } },
                        [_vm._v("Határozatlan ideig zárva")]
                      ),
                      _c(
                        "a-radio-button",
                        { attrs: { value: "closed_definitely" } },
                        [_vm._v("Határozott ideig zárva")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.form.getFieldValue("opening/status") ===
                        "closed_indefinitely",
                      expression:
                        "form.getFieldValue('opening/status') === 'closed_indefinitely'"
                    }
                  ],
                  attrs: { selfUpdate: true, label: "Inaktivitás indoklása" }
                },
                [
                  _c("a-textarea", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "opening/statusComment",
                          {
                            rules: [
                              {
                                required:
                                  _vm.form.getFieldValue("opening/status") ===
                                  "closed_indefinitely",
                                message: "Kérjük töltse ki!"
                              }
                            ],
                            validateTrigger: ["blur", "keyup"]
                          }
                        ],
                        expression:
                          "[\n                          'opening/statusComment',\n                          {\n                              rules: [{\n                                  required: form.getFieldValue('opening/status') === 'closed_indefinitely',\n                                  message: 'Kérjük töltse ki!'\n                              }],\n                              validateTrigger: ['blur', 'keyup']\n                          }\n                      ]"
                      }
                    ],
                    attrs: {
                      disabled: _vm.isMobileReadonly,
                      maxLength: 25000,
                      autoSize: { minRows: 2 }
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label:
                      "A látogatáshoz kötelező előzetesen időpontot foglalni?"
                  }
                },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "payment/appointments",
                            {
                              rules: [
                                {
                                  required: false,
                                  message: "Kérjük töltse ki!"
                                }
                              ]
                            }
                          ],
                          expression:
                            "[\n                        'payment/appointments',\n                        {\n                            rules: [{\n                                required: false,\n                                message: 'Kérjük töltse ki!'\n                            }]\n                        }\n                    ]"
                        }
                      ],
                      attrs: {
                        disabled: _vm.isMobileReadonly,
                        placeholder: "Kérjük válasszon!"
                      }
                    },
                    [
                      _c("a-select-option", { attrs: { value: 1 } }, [
                        _vm._v("Igen")
                      ]),
                      _c("a-select-option", { attrs: { value: 0 } }, [
                        _vm._v("Nem")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.form.getFieldValue("opening/status") ===
                        "closed_definitely",
                      expression:
                        "form.getFieldValue('opening/status') === 'closed_definitely'"
                    }
                  ],
                  attrs: { label: "Tervezett újranyitás dátuma" }
                },
                [
                  _c("a-date-picker", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "opening/reopenDate",
                          {
                            rules: [
                              {
                                required:
                                  _vm.form.getFieldValue("opening/status") ===
                                  "closed_definitely",
                                message: "Kérjük töltse ki!"
                              }
                            ]
                          }
                        ],
                        expression:
                          "[\n                        'opening/reopenDate',\n                        {\n                            rules: [{\n                                required: form.getFieldValue('opening/status') === 'closed_definitely',\n                                message: 'Kérjük töltse ki!'\n                            }],\n                        }\n                    ]"
                      }
                    ],
                    staticStyle: { width: "85%" },
                    attrs: {
                      disabled: _vm.isMobileReadonly,
                      format: _vm.dateFields["opening/reopenDate"]
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    selfUpdate: true,
                    label:
                      "Ha 12 hónapon keresztül havonta eltér a nyitva tartás, akkor kérjük, itt jelezze."
                  }
                },
                [
                  _c("a-textarea", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "opening/seasonalityComment",
                          {
                            rules: [
                              {
                                required: false,
                                message: "Kérjük töltse ki!"
                              }
                            ]
                          }
                        ],
                        expression:
                          "[\n                        'opening/seasonalityComment',\n                        {\n                            rules: [{\n                                required: false,\n                                message: 'Kérjük töltse ki!'\n                            }]\n                        }\n                    ]"
                      }
                    ],
                    attrs: {
                      disabled: _vm.isMobileReadonly,
                      maxLength: 25000,
                      autoSize: { minRows: 2 }
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("a-divider", { attrs: { orientation: "left" } }, [
                    _vm._v(
                      "\n                FREKVENTÁLT SZEZON NYITVA TARTÁSÁRA VONATKOZÓ ADATOK\n            "
                    )
                  ]),
                  _c("p", [
                    _vm._v(
                      "\n                Az adott vonzerő nyitva tartásának adatai a frekventált szezonban.\n            "
                    )
                  ])
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "Frekventált szezon kezdete" } },
                [
                  _c("a-date-picker", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "frequentedOpening/seasonalityStartDate",
                          {
                            rules: [
                              {
                                required: false,
                                message: "Kérjük töltse ki!"
                              }
                            ]
                          }
                        ],
                        expression:
                          "[\n                    `frequentedOpening/seasonalityStartDate`,\n                    {\n                        rules: [{\n                            required: false,\n                            message: 'Kérjük töltse ki!',\n                        }],\n                    }\n                ]"
                      }
                    ],
                    staticStyle: { width: "85%" },
                    attrs: {
                      disabled: _vm.isMobileReadonly,
                      "disabled-date": _vm.openingRangeStartDisabled,
                      format:
                        _vm.dateFields["frequentedOpening/seasonalityStartDate"]
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "Frekventált szezon vége" } },
                [
                  _c("a-date-picker", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "frequentedOpening/seasonalityEndDate",
                          {
                            rules: [
                              {
                                required: false,
                                message: "Kérjük töltse ki!"
                              }
                            ]
                          }
                        ],
                        expression:
                          "[\n                    'frequentedOpening/seasonalityEndDate',\n                    {\n                        rules: [{\n                            required: false,\n                            message: 'Kérjük töltse ki!',\n                        }],\n                    }\n                ]"
                      }
                    ],
                    staticStyle: { width: "85%" },
                    attrs: {
                      disabled: _vm.isMobileReadonly,
                      "disabled-date": _vm.openingRangeEndDisabled,
                      format:
                        _vm.dateFields["frequentedOpening/seasonalityEndDate"]
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                [
                  _c(
                    "a-checkbox",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: ["frequentedOpening/alwaysOpen"],
                          expression: "['frequentedOpening/alwaysOpen']"
                        }
                      ],
                      attrs: {
                        disabled: _vm.isMobileReadonly,
                        checked: _vm.isChecked("frequentedOpening/alwaysOpen")
                      }
                    },
                    [_vm._v("\n                24/7 nyitva tart\n            ")]
                  )
                ],
                1
              ),
              _vm._l(this.frequentedDays, function(day) {
                return [
                  day.weekdayStart
                    ? _c("h4", [
                        _vm._v("\n                Hétköznap\n            ")
                      ])
                    : _vm._e(),
                  day.weekendStart
                    ? _c("h4", [
                        _vm._v("\n                Hétvégén\n            ")
                      ])
                    : _vm._e(),
                  _c(
                    "a-form-item",
                    { key: day.fieldPrefix, attrs: { label: day.name } },
                    [
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            { attrs: { md: 7 } },
                            [
                              _c(
                                "a-form-item",
                                [
                                  _c("a-time-picker", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          day.fieldPrefix + "/startTime",
                                          {
                                            rules: [
                                              {
                                                required: false,
                                                message:
                                                  "Kérjük válassza ki a listából a megfelelő értéket VAGY ZÁRVA"
                                              }
                                            ]
                                          }
                                        ],
                                        expression:
                                          "[\n                                  day.fieldPrefix + '/startTime',\n                                  {\n                                  rules: [\n                                      {\n                                        required: false,\n                                        message: 'Kérjük válassza ki a listából a megfelelő értéket VAGY ZÁRVA',\n                                      }\n                                    ]\n                                  }\n                                  ]"
                                      }
                                    ],
                                    staticStyle: { width: "85%" },
                                    attrs: {
                                      getPopupContainer: _vm.getParentTriggerNode(),
                                      disabled:
                                        _vm.isMobileReadonly ||
                                        _vm.isChecked(
                                          day.fieldPrefix + "/closed"
                                        ) ||
                                        _vm.isChecked(
                                          "frequentedOpening/alwaysOpen"
                                        ),
                                      "minute-step": 15,
                                      format:
                                        _vm.dateFields[
                                          day.fieldPrefix + "/startTime"
                                        ]
                                    },
                                    on: {
                                      change: function(time, timeString) {
                                        return _vm.timePickerChange(
                                          day.fieldPrefix + "/startTime",
                                          time,
                                          timeString
                                        )
                                      }
                                    }
                                  }),
                                  _c("span", [_vm._v("-tól")])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { md: 7 } },
                            [
                              _c(
                                "a-form-item",
                                [
                                  _c("a-time-picker", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          day.fieldPrefix + "/endTime",
                                          {
                                            rules: [
                                              {
                                                required: false,
                                                message:
                                                  "Kérjük válassza ki a listából a megfelelő értéket VAGY ZÁRVA"
                                              }
                                            ]
                                          }
                                        ],
                                        expression:
                                          "[\n                      day.fieldPrefix + '/endTime',\n                        {\n                          rules: [\n                            {\n                              required: false,\n                              message: 'Kérjük válassza ki a listából a megfelelő értéket VAGY ZÁRVA',\n                            }\n                          ]\n                        }\n                      ]"
                                      }
                                    ],
                                    staticStyle: { width: "85%" },
                                    attrs: {
                                      getPopupContainer: _vm.getParentTriggerNode(),
                                      disabled:
                                        _vm.isMobileReadonly ||
                                        _vm.isChecked(
                                          day.fieldPrefix + "/closed"
                                        ) ||
                                        _vm.isChecked(
                                          "frequentedOpening/alwaysOpen"
                                        ),
                                      "minute-step": 15,
                                      format:
                                        _vm.dateFields[
                                          day.fieldPrefix + "/endTime"
                                        ]
                                    },
                                    on: {
                                      change: function(time, timeString) {
                                        return _vm.timePickerChange(
                                          day.fieldPrefix + "/endTime",
                                          time,
                                          timeString
                                        )
                                      }
                                    }
                                  }),
                                  _c("span", [_vm._v("-ig")])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { md: 5 } },
                            [
                              _c(
                                "a-form-item",
                                [
                                  _c(
                                    "a-checkbox",
                                    {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value: [
                                            day.fieldPrefix + "/lunchBreak"
                                          ],
                                          expression:
                                            "[day.fieldPrefix + '/lunchBreak']"
                                        }
                                      ],
                                      attrs: {
                                        disabled:
                                          _vm.isMobileReadonly ||
                                          _vm.isChecked(
                                            day.fieldPrefix + "/closed"
                                          ) ||
                                          _vm.isChecked(
                                            "frequentedOpening/alwaysOpen"
                                          ),
                                        checked: _vm.isChecked(
                                          day.fieldPrefix + "/lunchBreak"
                                        )
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                Ebédszünet\n                            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { md: 5 } },
                            [
                              _c(
                                "a-form-item",
                                [
                                  _c(
                                    "a-checkbox",
                                    {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value: [day.fieldPrefix + "/closed"],
                                          expression:
                                            "[day.fieldPrefix + '/closed']"
                                        }
                                      ],
                                      attrs: {
                                        disabled:
                                          _vm.isMobileReadonly ||
                                          _vm.isChecked(
                                            "frequentedOpening/alwaysOpen"
                                          ),
                                        checked: _vm.isChecked(
                                          day.fieldPrefix + "/closed"
                                        )
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                Zárva\n                            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              }),
              _c(
                "div",
                [
                  _c("a-divider", { attrs: { orientation: "left" } }, [
                    _vm._v(
                      "\n              SZEZONON KÍVÜLI NYITVA TARTÁSRA VONATKOZÓ ADATOK\n          "
                    )
                  ]),
                  _c("p", [
                    _vm._v(
                      "\n              Az adott vonzerő szezonon kívüli nyitva tartásának adatai.\n          "
                    )
                  ])
                ],
                1
              ),
              _c(
                "a-form-item",
                [
                  _c(
                    "a-checkbox",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: ["opening/alwaysOpen"],
                          expression: "['opening/alwaysOpen']"
                        }
                      ],
                      attrs: {
                        checked: _vm.isChecked("opening/alwaysOpen"),
                        disabled:
                          _vm.isMobileReadonly ||
                          _vm.isChecked("opening/alwaysClosed")
                      }
                    },
                    [_vm._v("\n                24/7 nyitva tart\n            ")]
                  ),
                  _c(
                    "a-checkbox",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: ["opening/alwaysClosed"],
                          expression: "['opening/alwaysClosed']"
                        }
                      ],
                      attrs: {
                        checked: _vm.isChecked("opening/alwaysClosed"),
                        disabled:
                          _vm.isMobileReadonly ||
                          _vm.isChecked("opening/alwaysOpen")
                      }
                    },
                    [_vm._v("\n                Zárva tart\n            ")]
                  )
                ],
                1
              ),
              _vm._l(this.days, function(day) {
                return [
                  day.weekdayStart
                    ? _c("h4", [
                        _vm._v("\n                Hétköznap\n            ")
                      ])
                    : _vm._e(),
                  day.weekendStart
                    ? _c("h4", [
                        _vm._v("\n                Hétvégén\n            ")
                      ])
                    : _vm._e(),
                  _c(
                    "a-form-item",
                    { key: day.fieldPrefix, attrs: { label: day.name } },
                    [
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            { attrs: { md: 7 } },
                            [
                              _c(
                                "a-form-item",
                                [
                                  _c("a-time-picker", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          day.fieldPrefix + "/startTime",
                                          {
                                            rules: [
                                              {
                                                required: false,
                                                message:
                                                  "Kérjük válassza ki a listából a megfelelő értéket VAGY ZÁRVA"
                                              }
                                            ]
                                          }
                                        ],
                                        expression:
                                          "[\n                                  day.fieldPrefix + '/startTime',\n                                  {\n                                  rules: [\n                                      {\n                                        required: false,\n                                        message: 'Kérjük válassza ki a listából a megfelelő értéket VAGY ZÁRVA',\n                                      }\n                                    ]\n                                  }\n                                  ]"
                                      }
                                    ],
                                    staticStyle: { width: "85%" },
                                    attrs: {
                                      getPopupContainer: _vm.getParentTriggerNode(),
                                      disabled:
                                        _vm.isMobileReadonly ||
                                        _vm.isChecked(
                                          day.fieldPrefix + "/closed"
                                        ) ||
                                        _vm.isChecked("opening/alwaysOpen") ||
                                        _vm.isChecked("opening/alwaysClosed"),
                                      "minute-step": 15,
                                      format:
                                        _vm.dateFields[
                                          day.fieldPrefix + "/startTime"
                                        ]
                                    },
                                    on: {
                                      change: function(time, timeString) {
                                        return _vm.timePickerChange(
                                          day.fieldPrefix + "/startTime",
                                          time,
                                          timeString
                                        )
                                      }
                                    }
                                  }),
                                  _c("span", [_vm._v("-tól")])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { md: 7 } },
                            [
                              _c(
                                "a-form-item",
                                [
                                  _c("a-time-picker", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          day.fieldPrefix + "/endTime",
                                          {
                                            rules: [
                                              {
                                                required: false,
                                                message:
                                                  "Kérjük válassza ki a listából a megfelelő értéket VAGY ZÁRVA"
                                              }
                                            ]
                                          }
                                        ],
                                        expression:
                                          "[\n                      day.fieldPrefix + '/endTime',\n                        {\n                          rules: [\n                            {\n                              required: false,\n                              message: 'Kérjük válassza ki a listából a megfelelő értéket VAGY ZÁRVA',\n                            }\n                          ]\n                        }\n                      ]"
                                      }
                                    ],
                                    staticStyle: { width: "85%" },
                                    attrs: {
                                      getPopupContainer: _vm.getParentTriggerNode(),
                                      disabled:
                                        _vm.isMobileReadonly ||
                                        _vm.isChecked(
                                          day.fieldPrefix + "/closed"
                                        ) ||
                                        _vm.isChecked("opening/alwaysOpen") ||
                                        _vm.isChecked("opening/alwaysClosed"),
                                      "minute-step": 15,
                                      format:
                                        _vm.dateFields[
                                          day.fieldPrefix + "/endTime"
                                        ]
                                    },
                                    on: {
                                      change: function(time, timeString) {
                                        return _vm.timePickerChange(
                                          day.fieldPrefix + "/endTime",
                                          time,
                                          timeString
                                        )
                                      }
                                    }
                                  }),
                                  _c("span", [_vm._v("-ig")])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { md: 5 } },
                            [
                              _c(
                                "a-form-item",
                                [
                                  _c(
                                    "a-checkbox",
                                    {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value: [
                                            day.fieldPrefix + "/lunchBreak"
                                          ],
                                          expression:
                                            "[day.fieldPrefix + '/lunchBreak']"
                                        }
                                      ],
                                      attrs: {
                                        disabled:
                                          _vm.isMobileReadonly ||
                                          _vm.isChecked(
                                            day.fieldPrefix + "/closed"
                                          ) ||
                                          _vm.isChecked("opening/alwaysOpen") ||
                                          _vm.isChecked("opening/alwaysClosed"),
                                        checked: _vm.isChecked(
                                          day.fieldPrefix + "/lunchBreak"
                                        )
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                Ebédszünet\n                            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { md: 5 } },
                            [
                              _c(
                                "a-form-item",
                                [
                                  _c(
                                    "a-checkbox",
                                    {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value: [day.fieldPrefix + "/closed"],
                                          expression:
                                            "[day.fieldPrefix + '/closed']"
                                        }
                                      ],
                                      attrs: {
                                        disabled:
                                          _vm.isMobileReadonly ||
                                          _vm.isChecked("opening/alwaysOpen") ||
                                          _vm.isChecked("opening/alwaysClosed"),
                                        checked:
                                          _vm.isChecked(
                                            day.fieldPrefix + "/closed"
                                          ) ||
                                          _vm.isChecked("opening/alwaysClosed")
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                Zárva\n                            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              }),
              _c("a-divider"),
              _c(
                "a-form-item",
                {
                  attrs: {
                    selfUpdate: true,
                    label:
                      "Speciális nyitva tartás (téli/nyári, éjszakai stb.; egyéb a fenti nyitvatartási kategóriákba nem besorolható esetek)"
                  }
                },
                [
                  _c("a-textarea", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "opening/specialComment",
                          {
                            rules: [
                              {
                                required: false,
                                message: "Kérjük töltse ki!"
                              }
                            ]
                          }
                        ],
                        expression:
                          "[\n                        'opening/specialComment',\n                        {\n                            rules: [{\n                                required: false,\n                                message: 'Kérjük töltse ki!'\n                            }]\n                        }\n                    ]"
                      }
                    ],
                    attrs: {
                      maxLength: 25000,
                      disabled: _vm.isMobileReadonly,
                      autoSize: { minRows: 2 }
                    }
                  })
                ],
                1
              ),
              _c("a-divider"),
              _vm.isNotMobile
                ? _c(
                    "a-form-item",
                    { attrs: { selfUpdate: true } },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "label" }, slot: "label" },
                        [
                          _vm._v(
                            "\n              Megjegyzés\n\n              "
                          ),
                          _c(
                            "a-tooltip",
                            {
                              attrs: {
                                title:
                                  "Kérjük ezt a cellát akkor töltse ki, ha a témához kapcsolódóan egyéb megjegyzése van."
                              }
                            },
                            [
                              _c("a-icon", {
                                attrs: { type: "question-circle-o" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("a-textarea", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "opening/comment",
                              {
                                rules: [
                                  {
                                    required: false,
                                    message: "Kérjük töltse ki!"
                                  }
                                ]
                              }
                            ],
                            expression:
                              "[\n                        'opening/comment',\n                        {\n                            rules: [{\n                                required: false,\n                                message: 'Kérjük töltse ki!'\n                            }]\n                        }\n                    ]"
                          }
                        ],
                        attrs: {
                          maxLength: 25000,
                          disabled: _vm.isMobileReadonly,
                          autoSize: { minRows: 2 }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c("a-divider"),
              _c("a-divider", { attrs: { orientation: "left" } }, [
                _vm._v("\n          VONZERŐNÉL ELTÖLTÖTT IDŐ\n        ")
              ]),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label:
                      "Átlagosan mennyi időt töltenek el a látogatók a vonzerőnél?"
                  }
                },
                [
                  _c("a-time-picker", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "opening/spentTime",
                          {
                            initialValue: _vm.moment("1970-01-01 00:00:00"),
                            rules: [
                              {
                                required: _vm.isNotMobile,
                                message: "Kérjük válasszon!"
                              },
                              {
                                validator: _vm.spentTimeValidator,
                                message: "Kérjük válasszon!"
                              }
                            ]
                          }
                        ],
                        expression:
                          "[\n                  'opening/spentTime',\n                    {\n                      initialValue: moment('1970-01-01 00:00:00'),\n                      rules: [\n                        {\n                          required: isNotMobile,\n                          message: 'Kérjük válasszon!',\n                        },\n                        {\n                          validator: spentTimeValidator,\n                          message: 'Kérjük válasszon!'\n                        }\n                      ]\n                    }\n                  ]"
                      }
                    ],
                    staticStyle: { width: "150px" },
                    attrs: {
                      getPopupContainer: _vm.getParentTriggerNode(),
                      disabled: _vm.isMobileReadonly,
                      allowClear: false,
                      "minute-step": 15,
                      disabledHours: _vm.spentHoursDisabled,
                      hideDisabledOptions: true,
                      format: "H [óra] mm [perc]"
                    },
                    on: {
                      change: function(time, timeString) {
                        return _vm.timePickerChange(
                          "opening/spentTime",
                          time,
                          timeString
                        )
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                [
                  _vm.showSaveButton && !_vm.isMobileReadonly
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            "html-type": "submit",
                            loading: _vm.submitLoading
                          }
                        },
                        [_vm._v("\n            Mentés\n          ")]
                      )
                    : _vm._e(),
                  _vm.hasChanged()
                    ? _c(
                        "a-button",
                        {
                          attrs: { type: "secondary", "html-type": "button" },
                          on: { click: _vm.resetForm }
                        },
                        [
                          _c("a-icon", {
                            attrs: { type: "undo", theme: "outlined" }
                          }),
                          _vm._v(
                            "\n\n            Piszkozat törlése\n          "
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.currentTab
                    ? _c("span", { staticClass: "last-modification" }, [
                        _vm._v("\n            Utolsó mentés: "),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm
                                .moment(_vm.currentTab.modificationTime)
                                .format(_vm.timeStampFormat)
                                .toString()
                            )
                          )
                        ]),
                        _vm._v("  "),
                        _vm.currentTab.user
                          ? _c("span", [
                              _vm._v(
                                "(" + _vm._s(_vm.currentTab.user.userName) + ")"
                              )
                            ])
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm.submitResult.show
                    ? _c("a-alert", {
                        style: {
                          marginTop: "10px"
                        },
                        attrs: {
                          message: _vm.submitResult.message,
                          type: _vm.submitResult.type,
                          showIcon: "",
                          closable: "",
                          afterClose: function() {
                            return (_vm.submitResult.show = false)
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }