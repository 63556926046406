import moment from 'moment';
import axios from "axios";
import isArray from 'lodash/isArray';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import debounce from "lodash.debounce";
import Cleave from 'cleave.js';
import store from "@/store";
import { mapState } from "vuex";
import { REFRESH_ATTRACTION } from "@/action_types";
import { DELETE_DRAFT, SAVE_DRAFT, SET_LOADING } from "@/mutation_types";

export const EditFormComponentMixin = {
    data() {
        return {
            formId: null,
            formDataLoading: false,
            draftTimeout: null,
            changes: 0,
            formFields: {},
            dateFields: [],
            changedLocales: [],
            singularMode: [],
            options: {},
            loading: {},
            translatedFormFields: {},
            ignoredFields: [],
            characterCounts: {},
            submitLoading: false,
            casts: {},
            draftLoading: false,
            dateFormat: 'YYYY.MM.DD',
            timeStampFormat: 'YYYY.MM.DD HH:mm:ss',
            mobileOnly: false,
            mobilePublished: false,
            submitResult: {
                show: false,
                message: '',
                type: 'info'
            },
        }
    },

    computed: {
        ...mapState({
            selectedRole: state => state.user.selectedRole
        }),

        languages() {
            return this.$store.getters.getForeignLanguages;
        },

        isAdmin() {
            return this.$store.getters.hasRoleAvailable(['ROLE_MTU_ADMIN']);
        },

        showSaveButton() {
            return !this.$store.getters.hasRoleAvailable(['ROLE_MTU_VIEWER']);
        },

        isMobileApp() {
            return this.$store.getters.hasRoleAvailable(['ROLE_MOBILE_APP']);
        },

        isMobileReadonly() {
            return this.isMobileApp && !this.mobileOnly;
        },

        isNotMobile() {
            return !this.isMobileApp || !this.mobileOnly;
        },

        isOfficeSpecialist() {
            return this.$store.getters.hasRoleAvailable(['ROLE_OFFICE_SPECIALIST']);
        },

        isOfficeEmployee() {
            return this.$store.getters.hasRoleAvailable(['ROLE_OFFICE_EMPLOYEE', 'ROLE_OFFICE_LEADER'])
        },

        isTranslationEditDisabled() {
            return this.isOfficeEmployee || (this.form.getFieldValue('classification') >= 5 && this.isOfficeSpecialist) || this.isMobileReadonly
        },

        currentTab() {
            let currentTab = null;
            let tabs = this.$store.state.attraction.current.tabs;

            tabs.forEach((tab) => {
                if (tab.code === this.formId) {
                    currentTab = tab;
                }
            });

            return currentTab;
        }
    },

    beforeCreate() {
        this.form = this.$form.createForm(this, {
            onValuesChange: debounce(() => {
                if (this.changes > 0) {
                    this.saveDraft();
                }

                this.changes++;
            })
        });
    },

    mounted() {
        if (!this.loadDraft()) {
            this.formDataLoading = true;

            this.fetchData().then((data) => {
                this.populate(data).then(() => {
                    this.formDataLoading = false;
                    this.changes = 0;
                });
            });
        }
    },

    methods: {
        moment,

        /**
         * @return {*}
         */
        fetchData() {
            return new Promise((resolve) => {
                resolve(this.getAttraction());
            });
        },

        /**
         * Load initial data and reset internal states
         */
        resetForm() {
            console.debug('Resetting form..');

            this.formDataLoading = true;
            this.changedLocales = {};
            this.changes = 0;

            this.resetDraft();

            this.fetchData().then((data) => {
                this.populate(data).then(() => {
                    this.formDataLoading = false;
                    this.changes = 0;

                    this.$message.info('Piszkozat elvetve!')
                });
            });
        },

        /**
         * Load draft
         *
         * @returns {*}
         */
        loadDraft() {
            let data = this.$store.getters.getDraft(
                this.getModel(),
                this.getModelId(),
                this.formId
            );

            console.debug('Checking draft..');

            if (data) {
                this.draftLoading = true;

                console.debug('Draft found', data);

                this.$nextTick(() => {
                    this.setState(data);

                    this.populateFormFields();

                    this.draftLoading = false;

                    console.debug('Draft loading finished');
                });
            }

            return data;
        },

        /**
         * Save draft
         */
        saveDraft() {
            if (!this.formId || this.draftLoading || this.formDataLoading) {
                return;
            }

            this.$nextTick(() => {
                let state = this.getState();

                console.debug('Saving draft', this.formId, state);

                this.$store.commit({
                    type: SAVE_DRAFT,
                    model: this.getModel(),
                    id: this.getModelId(),
                    formId: this.formId,
                    data: state
                });
            });
        },

        /**
         * Draft model
         *
         * @return {string}
         */
        getModel() {
            return 'attraction';
        },

        /**
         * Draft model id
         *
         * @return {string}
         */
        getModelId() {
            return this.getAttraction().id;
        },

        /**
         * @param {{}} data
         */
        setState(data) {
            console.debug('Set state', data);

            this.characterCounts = data.characterCounts;
            this.dateFields = data.dateFields;
            this.translatedFormFields = data.translatedFormFields;
            this.formFields = { ...data.formFields, ...data.data };
            this.singularMode = data.singularMode;
            this.options = data.options;
            this.ignoredFields = data.ignoredFields;
            this.changes = data.changes;
            this.changedLocales = data.changedLocales;
            this.mobileOnly = data.mobileOnly;
        },

        /**
         * @returns {Object}
         */
        getState() {
            return {
                data: this.getRawData(),
                dateFields: this.dateFields,
                characterCounts: this.characterCounts,
                formFields: this.formFields,
                translatedFormFields: this.translatedFormFields,
                options: this.options,
                singularMode: this.singularMode,
                changedLocales: this.changedLocales,
                ignoredFields: this.ignoredFields,
                changes: this.changes,
                mobileOnly: this.mobileOnly
            }
        },

        /**
         * Reset draft
         */
        resetDraft() {
            console.debug('Resetting draft', this.formId);

            this.$store.commit({
                type: DELETE_DRAFT,
                model: this.getModel(),
                id: this.getModelId(),
                formId: this.formId
            });
        },

        /**
         * @returns {object}
         */
        getAttraction() {
            return cloneDeep(this.$store.getters.getCurrentAttraction);
        },

        getRawData() {
            let values = this.form.getFieldsValue();
            let data = { ...this.formFields };

            // Collect attraction fields
            Object.keys(data).forEach(fieldName => {
                if (typeof values[fieldName] === 'undefined') {
                    return;
                }

                data[fieldName] = values[fieldName];
            });

            return data;
        },

        getData() {
            let values = this.form.getFieldsValue();
            let data = { ...this.formFields };

            // Collect attraction fields
            Object.keys(data).forEach(fieldName => {
                if (typeof values[fieldName] === 'undefined') {
                    return;
                }

                let value = values[fieldName];
                let allowNull = /^\?/.test(this.casts[fieldName]);

                switch (this.casts[fieldName]) {
                    case 'float':
                    case '?float':
                        if (value === true) {
                            value = 1;
                        } else {
                            value = parseFloat(value);
                        }

                        if (isNaN(value)) {
                            value = allowNull ? null : 0;
                        }

                        break;

                    case 'int':
                    case '?int':
                    case 'integer':
                    case '?integer':
                        if (value === true) {
                            value = 1;
                        } else {
                            value = parseInt(value);
                        }

                        if (isNaN(value)) {
                            value = allowNull ? null : 0;
                        }
                }

                /**
                 * Date fields must have moment object stored
                 */
                if (typeof this.dateFields[fieldName] !== 'undefined') {
                    value = value ? value.format(this.dateFields[fieldName]) : null;
                }

                data[fieldName] = value;
            });

            this.ignoredFields.forEach((ignoredField) => {
                delete data[ignoredField];
            });

            data.meta = this.getMetaData();

            return data;
        },

        /**
         * @return {{formId: {string}, selectedRole: {string}}}
         */
        getMetaData() {
            return {
                selectedRole: this.selectedRole,
                formId: this.formId
            };
        },

        /**
         * @param {boolean} loading
         */
        setSubmitLoading(loading) {
            this.submitLoading = loading;

            this.$store.commit({
                type: SET_LOADING,
                model: this.getModel(),
                id: this.getModelId(),
                formId: this.formId,
                loading: loading
            });
        },

        /**
         * Validate and save attraction
         */
        submit(event) {
            event.preventDefault();

            this.form.validateFieldsAndScroll(
                (errors, values) => {
                    if (errors) {
                        this.$message.warning(
                            'Az űrlap hibákat tartalmaz!'
                        );

                        return false;
                    }

                    this.setSubmitLoading(true);

                    this.$message.loading({ content: 'Mentés folyamatban..', key: 'ATTRACTION_SUBMIT', duration: 0 });

                    let promises = [];
                    let data = this.getData();

                    if (!this.isTranslationEditDisabled || this.isMobileApp) {
                        // Collect translated fields by locale
                        let fieldsByLocale = {};

                        for (let fieldName in this.translatedFormFields) {
                            for (let locale in this.translatedFormFields[fieldName]) {
                                if (typeof fieldsByLocale[locale] !== 'object') {
                                    fieldsByLocale[locale] = {};
                                }

                                let translation = this.translatedFormFields[fieldName][locale];

                                fieldsByLocale[locale][fieldName] = translation === null ? '' : translation;
                            }
                        }

                        for (let locale in this.changedLocales) {
                            let localeData = fieldsByLocale[locale];

                            localeData.meta = this.getMetaData();

                            promises.push(this.save(localeData, locale));
                        }
                    }

                    Promise.all(promises)
                        .then(responses => {
                            let isValid = true;

                            responses.forEach((response) => {
                                if (!response.data.isValid) {
                                    isValid = false;

                                    return false;
                                }
                            });

                            if (!isValid) {
                                this.submitError();

                                return;
                            }

                            let locale = this.$store.state.attraction.locale;

                            this.save(data, locale)
                                .then(response => {
                                    if (response.data.isValid) {
                                        this.submitSuccess();
                                    } else {
                                        console.log('WTF1');
                                        this.submitError();
                                    }
                                })
                                .catch(error => {
                                        console.log('WTF2', error);
                                        this.submitError();
                                    }
                                );
                        })
                        .catch(error => {
                                console.log('WTF3');
                                this.submitError();
                            }
                        );
                }
            );

            return false;
        },

        submitSuccess() {
            this.changes = 1;
            this.changedLocales = {};
            this.resetDraft();

            this.$message.success({ content: 'Sikeres mentés!', key: 'ATTRACTION_SUBMIT', duration: 5 });

            let attraction = this.getAttraction();

            store.dispatch({
                type: REFRESH_ATTRACTION,
                attractionId: attraction.id,
                groupType: attraction.group.groupType
            }).then(() => {
                this.setSubmitLoading(false);

                window.scrollTo({ top: 0, behavior: 'smooth' });
            }).catch(() => {
                this.$message.error(
                    'Az adatok frissítése sikertelen!'
                );
            });
        },

        submitError() {
            this.setSubmitLoading(false);

            this.$message.error({ content: 'Sikertelen mentés!', key: 'ATTRACTION_SUBMIT' });
        },

        /**
         * Save attraction (also, with locale specific fields)
         *
         * @param {object} data
         * @param {string} locale
         */
        save(data, locale) {
            return axios.patch(
                this.getApiUrl(this.getAttraction().id, locale),
                data
            );
        },

        /**
         * @param {integer} attractionId
         * @param {string} locale
         */
        getApiUrl(attractionId, locale) {
            if (!locale) {
                locale = this.$store.state.attraction.locale;
            }

            return '/api/v1/companies/1/me/attractions/' + attractionId + '?locale=' + locale;
        },

        /**
         * Populate from external source
         *
         * @param {object} externalData
         */
        populate(externalData) {
            console.debug('Populating', JSON.parse(JSON.stringify(externalData)));

            this.mobileOnly = externalData.mobileOnly;
            this.mobilePublished = externalData.mobilePublished;

            return new Promise((resolve, reject) => {
                // Attraction fields
                let internalData = {};

                Object.keys(this.formFields).forEach(fieldName => {
                    let value = this.findValue(
                        fieldName,
                        externalData,
                        this.formFields[fieldName]
                    );

                    if (null === value) {
                        value = this.formFields[fieldName];
                    }

                    /**
                     * Date fields must have moment object stored
                     */
                    if (typeof this.dateFields[fieldName] !== 'undefined') {
                        value = value
                            ? moment(value, this.dateFields[fieldName])
                            : null;
                    }

                    internalData[fieldName] = value;
                });

                this.formFields = internalData;

                // Translations
                const externalTranslations = this.$store.state.attraction.currentTranslations;

                console.debug('External translations', { ...externalTranslations });

                let internalTranslations = {};

                Object.keys(this.translatedFormFields).forEach(fieldName => {
                    internalTranslations[fieldName] = {};

                    let translations = this.findValue(
                        fieldName,
                        externalTranslations,
                        null
                    );

                    for (let locale in translations) {
                        internalTranslations[fieldName][locale] = translations[locale] === '' ? null : translations[locale];
                    }
                });

                this.setTranslatedFormFields(
                    internalTranslations
                );

                // Load options
                this.populateOptions().then(() => {
                    this.populateFormFields();

                    resolve();
                });
            });
        },

        /**
         * Function for child form to fill the options
         *
         * @return {Promise<unknown>}
         */
        populateOptions() {
            return new Promise((resolve => {
                resolve();
            }));
        },

        /**
         * Set fields value from internal data
         */
        populateFormFields() {
            const populateData = {};

            Object.keys(this.form.getFieldsValue()).forEach(fieldName => {
                if (typeof this.formFields[fieldName] === 'undefined') {
                    return;
                }

                populateData[fieldName] = this.formFields[fieldName];
            });

            for (let fieldName in this.characterCounts) {
                if (typeof populateData[fieldName] === 'undefined') {
                    continue;
                }

                this.updateCharacterCount(fieldName, populateData[fieldName] ? this.countCharactersInText(populateData[fieldName]) : 0);
            }

            console.debug('Populate form fields', populateData);

            this.form.setFieldsValue(populateData);
        },

        /**
         * @param {Array} translatedFormFields
         */
        setTranslatedFormFields: function (translatedFormFields) {
            let populateData = {};

            for (let fieldName in translatedFormFields) {
                if (typeof this.characterCounts[fieldName] != 'undefined') {
                    this.languages.forEach((language) => {
                        let translatedFieldName = this.getTranslatedFieldName(fieldName, language.locale);
                        let translation = translatedFormFields[fieldName][language.locale];
                        let length = (typeof translation === 'undefined' || translation === null) ? 0 : this.countCharactersInText(translation);

                        this.characterCounts[translatedFieldName] = { ...this.characterCounts[fieldName] };

                        this.updateCharacterCount(translatedFieldName, length)

                        if (length) {
                            populateData[translatedFieldName] = translation;
                        }
                    });
                }
            }

            console.debug('Setting translated fields', { ...translatedFormFields });

            this.translatedFormFields = translatedFormFields;

            this.$nextTick(() => {
                console.debug('Populating translated fields', { ...populateData });

                this.form.setFieldsValue(populateData);
            });
        },

        /**
         * @param {string} fieldName
         * @param {string} locale
         * @returns {string}
         */
        getTranslatedFieldName(fieldName, locale) {
            return 'translatedFormFields/' + fieldName + '/' + locale + '/content';
        },

        /**
         * @param {string} fieldName
         * @param {string} locale
         * @returns {string}
         */
        getTranslatedLocaleName(fieldName, locale) {
            return 'translatedFormFields/' + fieldName + '/' + locale;
        },

        /**
         * @param {string} name
         * @param {object} data
         * @param defaultValue
         *
         * @returns mixed
         */
        findValue: function (name, data, defaultValue) {
            var self = this;
            var value;

            if (typeof defaultValue != 'undefined') {
                value = defaultValue;
            } else {
                value = null;
            }

            var cData = data;

            if (data && typeof data[name] != 'undefined') {
                value = data[name];
            } else {
                var nameParts = name.split('/');

                for (let i in nameParts) {
                    let index = parseInt(i);
                    let key = nameParts[index];

                    if (cData && typeof cData[key] !== 'undefined') {
                        if (isArray(cData[key])
                            && typeof nameParts[index + 1] !== 'undefined'
                            && typeof nameParts[index + 1] === 'number'
                        ) {
                            value = [];

                            cData[key].forEach((row, rowIndex) => {
                                value.push(self.findValue(nameParts[index + 1], row));
                            });

                            break;
                        } else {
                            value = cData[key];

                            if (typeof cData[key] === 'object') {
                                cData = value;
                            } else {
                                break;
                            }
                        }
                    } else {
                        if (cData && isArray(cData) && cData.length && typeof cData[0][key] !== 'undefined') {
                            value = [];

                            cData.forEach((row) => {
                                value.push(row[key]);
                            });
                        } else {
                            if (typeof defaultValue != 'undefined') {
                                value = defaultValue;
                            } else {
                                value = null;
                            }
                        }

                        break;
                    }
                }
            }

            return value;
        },

        /**
         * @param field
         */
        updateTranslatedFormField(field) {
            this.$nextTick(() => {
                console.debug('Update translated form field', field);

                // Type check
                if (typeof this.translatedFormFields[field] === 'undefined') {
                    this.translatedFormFields[field] = {};
                }

                // Collect translations from elements
                let translations = {};

                let values = this.form.getFieldsValue();
                let regExp = new RegExp('^translatedFormFields\/' + field + '\/([a-zA-Z\-]+)$');

                for (let fieldName in values) {
                    let matches = regExp.test(fieldName);

                    if (!matches) {
                        continue;
                    }

                    let locale = values[fieldName];

                    translations[locale] = values[fieldName + '/content'];

                    this.translatedFormFields[field][locale] = translations[locale];

                    this.changedLocales[locale] = true;
                }

                // Clear previous translations
                for (let locale in this.translatedFormFields[field]) {
                    if (typeof this.translatedFormFields[field][locale] !== 'undefined'
                        && this.translatedFormFields[field][locale] !== null) {
                        this.changedLocales[locale] = true;
                    }

                    if (typeof translations[locale] === 'undefined') {
                        this.translatedFormFields[field][locale] = null;
                    }
                }
            });
        },

        /**
         * @param field
         * @param locale
         */
        deleteTranslatedFormField(field, locale) {
            this.changedLocales[locale] = true;
            this.translatedFormFields[field][locale] = null;

            this.updateCharacterCount(this.getTranslatedFieldName(field, locale), 0);

            this.$nextTick(() => {
                this.form.setFieldsValue({});
            });
        },

        /**
         * Add new row
         */
        addTranslatedFormField(field) {
            console.debug('Add transalted form field', field);

            let firstAvailableLanguage = this.getFirstAvailableLanguage(field);


            if (!firstAvailableLanguage) {
                console.debug('No available langauge found');

                return;
            }

            console.debug('Available language', firstAvailableLanguage);

            this.translatedFormFields[field][firstAvailableLanguage.locale] = '';

            this.$nextTick(() => {
                let refs = this.$refs[this.getTranslatedLocaleName(field, firstAvailableLanguage.locale)];

                if (refs && typeof refs[0] !== 'undefined' && refs[0].$el) {
                    refs[0].$el.scrollIntoView();
                }

                this.form.setFieldsValue({});
            });

            this.updateCharacterCount(this.getTranslatedFieldName(field, firstAvailableLanguage.locale), 0);
        },

        /**
         * @returns {object|null}
         */
        getFirstAvailableLanguage(field) {
            let firstAvailableLanguage = null;

            this.languages.some((language) => {
                if (this.isLanguageAvailable(field, language.locale)) {
                    firstAvailableLanguage = language;

                    return true;
                }
            });

            return firstAvailableLanguage;
        },

        /**
         * @param field
         * @param locale
         * @returns {boolean}
         */
        isLanguageAvailable(field, locale) {
            if (typeof this.translatedFormFields[field] === 'undefined') {
                return false;
            }

            return typeof this.translatedFormFields[field][locale] === 'undefined'
                || this.translatedFormFields[field][locale] === null;
        },

        /**
         * @param locale
         * @returns {string}
         */
        getLanguageName(locale) {
            let name = locale;

            this.languages.some((language) => {
                if (language.locale === locale) {
                    name = language.name.toLowerCase();

                    return true;
                }
            });

            return name;
        },

        /**
         * @param inputValue
         * @param option
         *
         * @returns {boolean}
         */
        filterSelectNames(inputValue, option) {
            let key = option.data.key;

            if (typeof key !== 'string') {
                return false;
            }

            return key.toLowerCase().indexOf(inputValue ? inputValue.toLowerCase() : '') >= 0;
        },

        /**
         * @param values
         * @param fieldName
         * @param primaryKeyField
         *
         * @returns {boolean}
         */
        checkSingular(values, fieldName, primaryKeyField) {
            if (!primaryKeyField) {
                primaryKeyField = 'id';
            }

            let singularOption;

            values.forEach((value) => {
                let option = this.getOptionByPrimaryKey(fieldName, value, primaryKeyField);

                if (option && option.singular) {
                    singularOption = option;

                    return true;
                }
            });

            if (singularOption) {
                this.$nextTick(() => {
                    this.form.setFieldsValue({
                        [fieldName]: [singularOption[primaryKeyField]]
                    });
                });

                this.singularMode[fieldName] = true;

                return true;
            }

            this.singularMode[fieldName] = false;

            return false;
        },

        /**
         * @param {string} fieldName
         * @param {int|string} primaryKey
         * @param {string}primaryKeyField
         * @returns {object|null}
         */
        getOptionByPrimaryKey(fieldName, primaryKey, primaryKeyField) {
            if (!primaryKeyField) {
                primaryKeyField = 'id';
            }

            let foundOption = null;

            this.options[fieldName].forEach((option) => {
                if (option[primaryKeyField] == primaryKey) {
                    foundOption = option;

                    return true;
                }
            });

            return foundOption;
        },

        /**
         * @return {function(*): *}
         */
        getParentTriggerNode() {
            return triggerNode => triggerNode.parentNode
        },

        /**
         * @param {string} field
         * @param {string|null} filterField
         * @param filterValue
         */
        loadOptions(field, filterField, filterValue) {
            this.loading[field] = true;

            let attraction = this.getAttraction();
            let endpoint = '/api/v1/companies/1/';
            let params = {
                locale: this.$store.state.attraction.locale,
                order: 'weight ASC',
                limit: 100
            };

            return new Promise((resolve, reject) => {
                this.$nextTick(() => {
                    let clearField = true;

                    if (filterValue) {
                        clearField = false;
                    }

                    if (filterField) {
                        filterValue = this.form.getFieldValue(filterField);
                    }

                    switch (field) {
                        case 'relatedAttractions/id':
                            endpoint += 'me/attractions';

                            if (this.isOfficeEmployee) {
                                endpoint += '/by-office';
                            } else if (this.isOfficeSpecialist) {
                                endpoint += '/by-areas';
                            }

                            params['id'] = '!=' + attraction.id;
                            params['multi'] = 0;
                            params['group/groupType'] = attraction.group.groupType;
                            params.order = 'name ASC';

                            break;

                        case 'group/id':
                            endpoint += 'attractions/groups';
                            params.groupType = attraction.group.groupType;

                            if (clearField) {
                                this.form.setFieldsValue({
                                    'group/id': null
                                });
                            }

                            break;

                        case 'category/id':
                            endpoint += 'attractions/categories';
                            params['group/groupType'] = attraction.group.groupType;
                            if (filterValue) {
                                params['group/id'] = filterValue;
                                params.order = 'weight ASC';
                            } else {
                                params.order = 'name ASC';
                            }

                            if (clearField) {
                                this.form.setFieldsValue({
                                    'category/id': null,
                                    'labels/id': []
                                });
                            }

                            break;

                        case 'labels/id':
                            if (filterValue) {
                                endpoint += 'attractions/categories/' + filterValue + '/children';
                                params.order = 'weight ASC';
                            } else {
                                endpoint += 'attractions/categories/all';
                                params['parent/group/groupType'] = attraction.group.groupType;
                                params.order = 'name ASC';
                            }

                            params.limit = 200;

                            if (clearField) {
                                this.form.setFieldsValue({
                                    'labels/id': []
                                });
                            }

                            break;

                        case 'featuredDestinations/id':
                            endpoint += 'me/attractions/regions';
                            params.order = 'weight ASC';

                            if (attraction && attraction.address && attraction.address.city) {
                                filterValue = attraction.address.city.id;

                                params['children/city/id'] = filterValue + ',IS NULL';
                            }

                            break;

                        case 'targetGroups/id':
                            endpoint += 'attraction/categories/visitor/target_group/children';
                            params.order = 'weight ASC';

                            break;

                        case 'journeyTypes/id':
                            endpoint += 'attraction/categories/visitor/journey_type/children';
                            params.order = 'weight ASC';

                            break;

                        case 'seasonalities/id':
                            endpoint += 'attraction/categories/visitor/seasonality/children';
                            params.order = 'weight ASC';

                            break;

                        case 'siteNatures/id':
                            endpoint += 'attraction/categories/visitor/site_nature/children';
                            params.order = 'weight ASC';

                            break;

                        case 'accessibilities/id':
                            endpoint += 'attraction/categories/visitor/accessibility/children';
                            params.order = 'weight ASC';

                            break;

                        case 'payment/ticketSales/id':
                            endpoint += 'attraction/categories/visitor/ticket/children';
                            params.order = 'weight ASC';

                            break;

                        case 'creditCards/id':
                        case 'infopointPlace/creditCards/id':
                        case 'payment/creditCards/id':
                            endpoint += 'attraction/cards';
                            params.order = 'label ASC';

                            break;

                        case 'vouchers/id':
                        case 'infopointPlace/vouchers/id':
                        case 'payment/vouchers/id':
                            endpoint += 'attraction/vouchers';
                            params.order = 'label ASC';

                            break;

                        case 'foreignCurrencies/id':
                        case 'infopointPlace/foreignCurrencies/id':
                        case 'payment/foreignCurrencies/id':
                            endpoint += 'attraction/currencies';
                            params.order = 'label ASC';

                            break;

                        case 'payment/reservations/id':
                            endpoint += 'attraction/reservations';
                            params.order = 'label ASC';

                            break;

                        case 'services/id':
                        case 'infopointPlace/services/id':
                            endpoint += 'attraction/services';
                            params.order = 'name ASC';

                            break;
                    }

                    if (filterField && !filterValue) {
                        resolve();

                        return;
                    }

                    axios.get(endpoint, {
                        params: params
                    }).then(response => {
                        if (!isEqual(this.options[field], response.data)) {
                            this.options[field] = response.data;
                        }

                        this.loading[field] = false;

                        /**
                         * Check singular options
                         */
                        let fieldValue = this.form.getFieldValue(field);

                        if (fieldValue && isArray(fieldValue)) {
                            this.checkSingular(this.form.getFieldValue(field), field);
                        }

                        this.saveDraft();

                        resolve();
                    }).catch(() => {
                        this.$message.error(
                            'Sikertelen opció betöltés!'
                        );
                    });
                });
            });
        },


        /**
         * Auto-complete zip code list from API
         *
         * @param {string} code
         * @param {string} zipCodeField
         * @param {boolean} all
         *
         * Throttled: 250ms when code is not empty
         */
        zipCodeAutoComplete: function (code, zipCodeField, all = false) {
            var self = this;

            let endpoint = '/api/v1/companies/1/location/zipcode'

            if (!all) {
                switch (zipCodeField) {
                    case 'address/zipCode/id':
                    case 'officePlace/address/zipCode/id':
                    case 'infopointPlace/address/zipCode/id':
                        endpoint = '/api/v1/companies/1/me/attractions/zipcode';

                        if (this.isOfficeEmployee) {
                            endpoint += '/by-office';
                        } else if (this.isOfficeSpecialist) {
                            endpoint += '/by-areas';
                        }

                        break;
                }
            }

            debounce(function (code) {
                self.options[zipCodeField] = [];
                self.loading[zipCodeField] = true;

                axios.get(endpoint, {
                    params: {
                        locale: self.$store.state.attraction.locale,
                        code: (null !== code && code) ? code + '*' : null,
                        order: 'code ASC',
                        limit: 200
                    }
                }).then(response => {
                    self.options[zipCodeField] = response.data;
                    self.loading[zipCodeField] = false;
                }).catch(() => {
                    self.$message.error(
                        'Az irányítószám lekérdezés sikertelen!'
                    );
                });
            }, code !== null ? 250 : 0)(code)
        },


        /**
         * Auto-complete zip code list from API
         *
         * @param {string} name
         * @param {string} labelsField
         *
         * Throttled: 250ms when code is not empty
         */
        labelCitiesAutoComplete: function (name, labelsField) {
            var self = this;

            let endpoint = '/api/v1/companies/1/attractions/categories/all'

            debounce(function (name) {
                self.options[labelsField] = [];
                self.loading[labelsField] = true;

                axios.get(endpoint, {
                    params: {
                        locale: self.$store.state.attraction.locale,
                        'group/name': 'city',
                        name: (null !== name && name) ? name + '*' : null,
                        order: 'name ASC',
                        limit: 200
                    }
                }).then(response => {
                    self.options[labelsField] = response.data;
                    self.loading[labelsField] = false;
                }).catch(() => {
                    self.$message.error(
                        'A város cimkék lekérdezés sikertelen!'
                    );
                });
            }, name !== null ? 250 : 0)(name)
        },

        /**
         *
         * Load city name from zip code data and fill form field
         *
         * @param {int} zipCodeId
         * @param {string} zipCodeField
         * @param {string} cityIdField
         */
        fillCityOptions: function (zipCodeId, zipCodeField, cityIdField) {
            this.options[zipCodeField].forEach((zipCode) => {
                if (zipCode.id === parseInt(zipCodeId)) {
                    this.options[cityIdField] = zipCode.cities;

                    if (zipCode.cities.length) {
                        this.$nextTick(() => {
                            let populateData = {};

                            populateData[cityIdField] = zipCode.cities[0].id;

                            this.form.setFieldsValue(populateData);
                        });
                    }
                }
            });
        },

        /**
         * @param value
         * @param key
         */
        formatCoordinate(value, key) {
            if (value === null) {
                value = '';
            }

            this.$nextTick(() => {
                this.form.setFieldsValue({
                    [key]: value.replace(/[^0-9\. ]/g, '')
                        .replace(/\./g, (match, nth, string) => string.indexOf(match) === nth ? match : '')
                        .replace(/(?<!,)\s/g, '')
                })
            })
        },

        /**
         * @param value
         * @param key
         */
        formatNumber(value, key) {
            if (value === null) {
                value = '';
            }

            this.$nextTick(() => {
                this.form.setFieldsValue({
                    [key]: value.replace(/[^0-9]/g, '')
                })
            })
        },

        stripTags(text) {
            var dom = new DOMParser().parseFromString(text, 'text/html');

            return dom.body.textContent;
        },

        countCharactersInText(text) {
            return this.stripTags(text).length;
        },

        /**
         * @param {Event} event
         */
        characterCount(event) {
            let field = event.target.id;
            let length = this.countCharactersInText(event.target.value);

            this.updateCharacterCount(field, length);
        },

        /**
         * @param {string} field
         * @param {int} length
         */
        updateCharacterCount(field, length) {
            let valid = true;

            if (typeof this.characterCounts[field].min !== 'undefined') {
                valid = valid && length >= this.characterCounts[field].min;
            } else if (length === 0) {
                valid = null;
            }

            if (typeof this.characterCounts[field].max !== 'undefined') {
                valid = valid && length <= this.characterCounts[field].max;
            }

            this.characterCounts[field].current = length;
            this.characterCounts[field].valid = valid;
        },

        /**
         * @param {string} fieldName
         *
         * @return {boolean}
         */
        isChecked(fieldName) {
            return !!this.form.getFieldValue(fieldName);
        },

        /**
         * @param fieldName
         */
        relevancyCheckboxChange(fieldName) {
            this.$nextTick(() => {
                this.form.validateFields(fieldName ? (typeof fieldName === 'object' ? fieldName : [fieldName]) : []);
            })
        },

        /**
         * @param {string} url
         * @param {string} fieldName
         */
        transformEncodedUrl(url, fieldName) {
            this.$nextTick(() => {
                this.form.setFieldsValue({
                    [fieldName]: decodeURI(url)
                })
            });
        },

        /**
         * @param {string} phoneNumber
         * @param {string} fieldName
         */
        formatPhoneNumber(phoneNumber, fieldName) {
            if (phoneNumber === ''
                || phoneNumber === '+'
                || phoneNumber === '+3'
                || phoneNumber === '+36') {
                return true;
            }

            if (/^06/.test(phoneNumber)) {
                phoneNumber = '+36 ' + phoneNumber.replace(/^06/, '');
                this.$refs[fieldName].$el.value = phoneNumber;
            }

            let cleave = new Cleave(this.$refs[fieldName].$el, {
                phone: true,
                phoneRegionCode: '+36'
            });

            let formattedNumber = cleave.getFormattedValue();

            if (/^\06/.test(formattedNumber)) {
                formattedNumber = '+36 ' + formattedNumber.replace(/^06/, '');
            }

            if (!/^\+36/.test(formattedNumber)) {
                formattedNumber = '+36 ' + formattedNumber;
            }

            formattedNumber = formattedNumber.replace(/[a-zA-Z]/, '')

            this.$refs[fieldName].$el.value = formattedNumber;
        },

        /**
         * Has changed after initial first change
         *
         * @returns {boolean}
         */
        hasChanged() {
            return this.changes > 1;
        },

        /**
         *
         * @param {String} field
         * @param {Moment} time
         * @param {String} timeString
         */
        timePickerChange(field, time, timeString) {
            return;

            let values = this.form.getFieldsValue();

            if (typeof values[field] !== 'undefined') {
                /**
                 * @var {Moment} value
                 */
                let value = values[field];

                if (typeof value === 'object') {
                    let oldHours = value.hours();
                    let newHours = time.hours();

                    if (newHours !== oldHours) {
                        time.minutes(0);
                    }
                } else {
                    return;
                }
            } else {
                return;
            }

            let form = this.form;

            this.$nextTick(() => {
                form.setFieldsValue({
                    [field]: time
                });
            });
        }
    },

    watch: {
        selectedRole(newRole, oldRole) {
            this.$router.push({ name: 'neta-list' });

            // switch (newRole) {
            //     case 'ROLE_OFFICE_EMPLOYEE':
            //     case 'ROLE_OFFICE_LEADER':
            //     case 'ROLE_OFFICE_SPECIALIST':
            //
            //         break;
            // }
        },
    },
}