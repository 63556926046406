import Vue from 'vue';
import Vuex from 'vuex';
import user from '@/store_modules/user.js';
import attraction from '@/store_modules/attraction.js';
import hungarikum from '@/store_modules/hungarikum.js';
import forms from '@/store_modules/forms.js';
import router_state from '@/store_modules/router_state.js';

import {
    SET_BACKEND_VERSION,
    SET_PRICES_VISIBLE,
    SET_USER_COLUMNS,
    SET_LAST_LIST_QUERY_PARAMS,
    CLEAR_LAST_LIST_QUERY_PARAMS,
    SET_LAST_GROUP_TYPE
} from './mutation_types';
import { USER_LOGIN, RESTORE_LOCAL_STATE, UPDATE_VERSION, USER_LOGOUT } from "@/action_types";
import LocalStorage from "@/helpers/localstorage";
import { Message } from "ant-design-vue";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        backendVersion: '',
        pricesVisible: true,
        lastGroupType: null,
        lastListQueryParams: {
            attraction: {},
            event: {},
            multiAttraction: {},
            multiEvent: {},
            hungarikum: {},
        },
        userColumns: {
            attraction: [],
            event: [],
            multiAttraction: {},
            multiEvent: {},
            hungarikum: {},
        }
    },
    mutations: {
        [SET_BACKEND_VERSION](state, payload) {
            state.backendVersion = payload.backendVersion;

            let currentVersion = LocalStorage.getVersion();

            if (currentVersion !== null) {
                let newBackendVersionData = state.backendVersion.split('.');
                let currentBackendVersionData = currentVersion.backend.split('.');

                let newFrontendVersionData = process.env.VUE_APP_VERSION.split('.');
                let currentFrontendVersionData = currentVersion.frontend.split('.');

                if (newBackendVersionData[0] !== currentBackendVersionData[0]
                    || newBackendVersionData[1] !== currentBackendVersionData[1]
                    || newFrontendVersionData[0] !== currentFrontendVersionData[0]
                    || newFrontendVersionData[1] !== currentFrontendVersionData[1]) {
                    this.dispatch(UPDATE_VERSION);
                }
            }

            LocalStorage.setVersion({
                backend: payload.backendVersion,
                frontend: process.env.VUE_APP_VERSION
            });
        },

        [SET_LAST_GROUP_TYPE](state, payload) {
            state.lastGroupType = payload.groupType;
        },

        [SET_PRICES_VISIBLE](state, payload) {
            state.pricesVisible = payload.pricesVisible;
        },

        [SET_USER_COLUMNS](state, payload) {
            let groupType = payload.groupType ? payload.groupType : 'attraction';

            Vue.set(state.userColumns, groupType, payload.userColumns);

            LocalStorage.setUserColumns(payload.userColumns, groupType);
        },

        [SET_LAST_LIST_QUERY_PARAMS](state, payload) {
            let groupType = payload.groupType ? payload.groupType : 'attraction';

            Vue.set(state.lastListQueryParams, groupType, payload.lastListQueryParams);

            LocalStorage.setLastListQueryParams(payload.lastListQueryParams, groupType);
        },

        [CLEAR_LAST_LIST_QUERY_PARAMS](state, payload) {
            let groupType = 'attraction';

            if (payload && payload.groupType) {
                groupType = payload.groupType;
            }

            state.lastListQueryParams[groupType] = null;

            LocalStorage.setLastListQueryParams(null, groupType);
        },
    },

    actions: {
        [UPDATE_VERSION]({ commit }, payload) {
            Message.info('Elérhető az alkalmazás új verziója!');
            Message.loading({ content: 'Az új változat telepítése..', key: UPDATE_VERSION, duration: 4 })
                .then(() => {
                    LocalStorage.setUserColumns(null, null);
                    LocalStorage.setLastListQueryParams(null, null);

                    this.dispatch(USER_LOGOUT);

                    Message.success({
                        content: 'A verzió telepítése sikeres! Kérem jelentkezzen be újra!',
                        key: UPDATE_VERSION
                    }).then(() => {
                        window.location.reload();
                    });
                });
        },
        [RESTORE_LOCAL_STATE]({ commit }, payload) {
            // Restore user columns
            const userColumns = LocalStorage.getUserColumns();

            if (userColumns) {
                for (let groupType in userColumns) {
                    commit({
                        type: SET_USER_COLUMNS,
                        groupType: groupType,
                        userColumns: userColumns[groupType]
                    });
                }
            }

            // Restore last query params
            const lastListQueryParams = LocalStorage.getLastListQueryParams();

            if (lastListQueryParams) {
                for (let groupType in lastListQueryParams) {
                    commit({
                        type: SET_LAST_LIST_QUERY_PARAMS,
                        groupType: groupType,
                        lastListQueryParams: lastListQueryParams[groupType]
                    });
                }
            }

            // Restore user data
            const userData = LocalStorage.getUserData();

            if (userData) {
                this.dispatch({
                    type: USER_LOGIN,
                    userName: userData.userName,
                    office: userData.office,
                    email: userData.email,
                    roles: userData.roles,
                    selectedRole: userData.selectedRole
                });
            }
        }
    },
    modules: {
        attraction,
        hungarikum,
        user,
        router_state,
        forms
    }
});
