var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "router-link",
        { attrs: { to: { name: "neta-list-multi-event" } } },
        [
          _c(
            "a-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.collapsed,
                  expression: "!collapsed"
                }
              ],
              staticClass: "attraction-back-button",
              attrs: { size: "large", icon: "table", type: "default" }
            },
            [_vm._v("Vissza a listához")]
          ),
          _c("a-button", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.collapsed,
                expression: "collapsed"
              }
            ],
            staticClass: "attraction-back-button",
            attrs: { size: "large", icon: "table", type: "default" }
          })
        ],
        1
      ),
      _vm.attraction
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.collapsed,
                  expression: "!collapsed"
                }
              ],
              staticClass: "attraction-data"
            },
            [
              _c("div", { staticClass: "attraction-name" }, [
                _c("strong", [_vm._v(_vm._s(_vm.attraction.name))])
              ]),
              _c("div", { staticClass: "attraction-id" }, [
                _vm._v("\n      Azonosító: "),
                _c("strong", [_vm._v(_vm._s(_vm.attraction.id))])
              ]),
              _vm.attraction.address && _vm.attraction.address.city
                ? _c("div", { staticClass: "attraction-city" }, [
                    _vm._v("\n      Település: "),
                    _c("strong", [
                      _vm._v(_vm._s(_vm.attraction.address.city.name))
                    ])
                  ])
                : _vm._e(),
              _c("div", { staticClass: "attraction-status" }, [
                _vm._v("\n      Státusz: "),
                _c("strong", [
                  _vm._v(_vm._s(_vm.statusNames[_vm.attraction.status]))
                ])
              ]),
              _vm.relatedAttractions.length
                ? _c("div", { staticClass: "attraction-related" }, [
                    _vm._v(
                      "\n      Kapcsolódó vonzerő" +
                        _vm._s(_vm.relatedAttractions.length > 1 ? "k" : "") +
                        ":\n\n      "
                    ),
                    _c(
                      "ul",
                      _vm._l(_vm.relatedAttractions, function(
                        relatedAttraction
                      ) {
                        return _c(
                          "li",
                          [
                            _c("strong", [
                              _vm._v(_vm._s(relatedAttraction.name))
                            ]),
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: _vm.getAttractionEditUrl(
                                    relatedAttraction
                                  )
                                }
                              },
                              [
                                _c("a-button", {
                                  staticClass: "action-button",
                                  attrs: {
                                    size: "small",
                                    icon: "edit",
                                    type: "primary"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ])
                : _vm._e(),
              _vm.relatedEvents.length
                ? _c("div", { staticClass: "attraction-related" }, [
                    _vm._v(
                      "\n      Kapcsolódó esemény" +
                        _vm._s(_vm.relatedEvents.length > 1 ? "ek" : "") +
                        ":\n\n      "
                    ),
                    _c(
                      "ul",
                      _vm._l(_vm.relatedEvents, function(relatedAttraction) {
                        return _c(
                          "li",
                          [
                            _c("strong", [
                              _vm._v(_vm._s(relatedAttraction.name))
                            ]),
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: _vm.getEventEditUrl(relatedAttraction)
                                }
                              },
                              [
                                _c("a-button", {
                                  staticClass: "action-button",
                                  attrs: {
                                    size: "small",
                                    icon: "edit",
                                    type: "primary"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ])
                : _vm._e()
            ]
          )
        : _vm._e(),
      _c(
        "a-menu",
        {
          style: { height: "100%", maxWidth: "300px" },
          attrs: {
            mode: "inline",
            theme: "light",
            defaultSelectedKeys: _vm.selectedMenuItem(),
            defaultOpenKeys: ["neta-basic-multi-event-data"]
          }
        },
        _vm._l(_vm.getCategoryRoutes("neta-basic-multi-event-data"), function(
          subForm
        ) {
          return _c(
            "a-menu-item",
            { key: subForm.name },
            [
              _c(
                "router-link",
                { attrs: { to: { name: subForm.name } } },
                [
                  typeof _vm.formIdMap[subForm.path] !== "undefined" &&
                  _vm.loading[_vm.formIdMap[subForm.path]][_vm.attraction.id]
                    ? _c("a-icon", {
                        style: { color: "black" },
                        attrs: { type: "sync", spin: true }
                      })
                    : typeof _vm.formIdMap[subForm.path] !== "undefined" &&
                      _vm.drafts[_vm.formIdMap[subForm.path]][_vm.attraction.id]
                    ? _c("a-icon", {
                        style: { color: "orange" },
                        attrs: { type: "edit" }
                      })
                    : _vm.getTabIcon(subForm.path)
                    ? _c("a-icon", {
                        style: { color: _vm.getTabIcon(subForm.path).color },
                        attrs: { type: _vm.getTabIcon(subForm.path).icon }
                      })
                    : _vm._e(),
                  _c("span", [
                    _vm._v(
                      "\n            " +
                        _vm._s(subForm.meta.menuName) +
                        "\n          "
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }