var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showForm,
          expression: "showForm"
        }
      ],
      staticClass: "new-attraction-container"
    },
    [
      _c("h1", { staticClass: "main-heading" }, [
        _vm._v("\n        Új Hungarikum felvétele\n    ")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }