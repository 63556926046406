<template>
  <div>
    <a-row
        class="welcome-content-row"
    >
      <a-col :xs="1" :sm="4" :md="7" />
      <a-col :xs="22" :sm="16" :md="10">
        <div class="welcome-card-container">
          <LoginCardComponent />
        </div>
      </a-col>
      <a-col :xs="1" :sm="4" :md="7" />
    </a-row>

    <div class="privacy-policy privacy-policy--extended">
      <a href="/api/v1/companies/1/files/64/view" target="_blank" title="Adatkezelési tájékoztató (felhasználó)">
        Adatkezelési tájékoztató (felhasználó)
      </a>

      <a href="/api/v1/companies/1/files/62/view" target="_blank" title="Adatkezelési tájékoztató (látogató)">
        Adatkezelési tájékoztató (látogató)
      </a>
    </div>
  </div>
</template>

<script>
import LoginCardComponent from '@/components/login/LoginCardComponent.vue';
import store from '@/store.js'

export default {
  components: {
    LoginCardComponent
  },

  beforeRouteEnter(to, from, next) {
    if (!store.getters.isLoggedIn) {
      next()
    } else {
      next({
        name: 'neta-home'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.welcome-content-row {
  margin-top: 10vh;
}

.welcome-card-container {
  background-color: white;
  max-width: 600px;
  text-align: center;
  margin: auto;
  padding-top: 48px;
  padding-bottom: 38px;
  padding-right: 30px;
  padding-left: 30px;
}

.privacy-policy {
  text-align: right;

  a {
    color: white;
    display: block;
  }
}

.privacy-policy--extended {
  position: absolute;
  right: 15px;
  bottom: 30px;
}
</style>
