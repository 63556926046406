var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-form-container" },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.formDataLoading } },
        [
          _c(
            "a-form",
            {
              attrs: { form: _vm.form, layout: "vertical" },
              on: { submit: _vm.submit }
            },
            [
              _c(
                "a-form-item",
                {
                  attrs: { label: "A Tourinform iroda neve", selfUpdate: true }
                },
                [
                  _c("a-textarea", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "name",
                          {
                            rules: [
                              {
                                required: true,
                                preserve: true,
                                message: "Kérjük töltse ki!"
                              },
                              {
                                max: _vm.characterCounts.name.max,
                                message:
                                  "Maximum " +
                                  _vm.characterCounts.name.max +
                                  " karakter engedélyezett."
                              }
                            ],
                            validateTrigger: ["blur", "keyup"]
                          }
                        ],
                        expression:
                          "[\n                        'name',\n                        {\n                            rules: [\n                                {\n                                  required: true,\n                                  preserve: true,\n                                  message: 'Kérjük töltse ki!'\n                                },\n                                {\n                                  max: characterCounts.name.max,\n                                  message: 'Maximum ' + characterCounts.name.max + ' karakter engedélyezett.',\n                                }\n                            ],\n                            validateTrigger: ['blur', 'keyup']\n                        }\n                    ]"
                      }
                    ],
                    attrs: { maxLength: 25000, autoSize: { minRows: 1 } }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: "Tourinform iroda alapításának/nyitásának éve",
                    selfUpdate: true
                  }
                },
                [
                  _c("a-input-number", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "openingYear",
                          {
                            validateTrigger: ["blur", "keyup"],
                            rules: [
                              {
                                required: true,
                                message: "Kérjük töltse ki!"
                              }
                            ]
                          }
                        ],
                        expression:
                          "[\n                    'openingYear',\n                    {\n                        validateTrigger: ['blur', 'keyup'],\n                        rules: [{\n                            required: true,\n                            message: 'Kérjük töltse ki!',\n                        }]\n                    }\n                ]"
                      }
                    ],
                    attrs: { max: 2100, min: 1901 }
                  }),
                  _vm._v("\n                év\n            ")
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    selfUpdate: true,
                    label:
                      "A Tourinform iroda településének bemutatása, rövid leírása, kiemelendő értékei, TOP turisztikai vonzerői (kb. 3 sor, max. 500 karakter)"
                  }
                },
                [
                  _c("a-textarea", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "description",
                          {
                            rules: [
                              {
                                required: true,
                                preserve: true,
                                max: _vm.characterCounts.description.max,
                                message:
                                  "Kérjük töltse ki! A leírás hossza maximum " +
                                  _vm.characterCounts.description.max +
                                  " karakter lehet."
                              }
                            ],
                            validateTrigger: ["blur", "keyup"]
                          }
                        ],
                        expression:
                          "[\n                        'description',\n                        {\n                            rules: [\n                                {\n                                  required: true,\n                                  preserve: true,\n                                  max: characterCounts.description.max,\n                                  message: 'Kérjük töltse ki! A leírás hossza maximum ' + characterCounts.description.max + ' karakter lehet.',\n                                }\n                            ],\n                            validateTrigger: ['blur', 'keyup']\n                        }\n                    ]"
                      }
                    ],
                    attrs: { maxLength: 25000, autoSize: { minRows: 3 } },
                    on: {
                      keyup: _vm.characterCount,
                      change: _vm.characterCount
                    }
                  }),
                  _c("div", { staticClass: "character-count" }, [
                    _c(
                      "strong",
                      {
                        class: {
                          invalid:
                            _vm.characterCounts.description.valid === false,
                          valid: _vm.characterCounts.description.valid === true
                        }
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.characterCounts.description.current) +
                            "\n                    "
                        )
                      ]
                    ),
                    _vm._v(
                      "\n\n                    / " +
                        _vm._s(_vm.characterCounts.description.max) +
                        "\n                "
                    )
                  ])
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "Irányítószám" } },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "officePlace/address/zipCode/id",
                            {
                              rules: [
                                {
                                  required: true,
                                  whitespace: true,
                                  message: "Kérjük töltse ki!",
                                  type: "integer"
                                }
                              ],
                              validateTrigger: ["blur", "keyup"]
                            }
                          ],
                          expression:
                            "[\n                        'officePlace/address/zipCode/id',\n                        {\n                            rules: [\n                                {\n                                required: true,\n                                whitespace: true,\n                                message: 'Kérjük töltse ki!',\n                                type: 'integer'\n                              },\n                            ],\n                            validateTrigger: ['blur','keyup']\n                        }\n                    ]"
                        }
                      ],
                      style: { width: "100%" },
                      attrs: {
                        placeholder: "Kezdjen gépelni",
                        showSearch: "",
                        showArrow: false,
                        defaultActiveFirstOption: false,
                        getPopupContainer: _vm.getParentTriggerNode(),
                        filterOption: false,
                        notFoundContent: _vm.loading[
                          "officePlace/address/zipCode/id"
                        ]
                          ? undefined
                          : null
                      },
                      on: {
                        search: function(value) {
                          return _vm.zipCodeAutoComplete(
                            value,
                            "officePlace/address/zipCode/id"
                          )
                        },
                        change: function(value) {
                          _vm.fillCityOptions(
                            value,
                            "officePlace/address/zipCode/id",
                            "officePlace/address/city/id"
                          )
                        },
                        dropdownVisibleChange: function(open) {
                          return (
                            open &&
                            _vm.zipCodeAutoComplete(
                              null,
                              "officePlace/address/zipCode/id"
                            )
                          )
                        }
                      }
                    },
                    [
                      _vm.loading["officePlace/address/zipCode/id"]
                        ? _c("a-spin", {
                            attrs: { slot: "notFoundContent", size: "small" },
                            slot: "notFoundContent"
                          })
                        : _vm._e(),
                      _vm._l(
                        _vm.options["officePlace/address/zipCode/id"],
                        function(option) {
                          return _c(
                            "a-select-option",
                            { key: option.id, attrs: { value: option.id } },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(option.code) +
                                  "\n                    "
                              )
                            ]
                          )
                        }
                      )
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "Település neve" } },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "officePlace/address/city/id",
                            {
                              rules: [
                                {
                                  required: true,
                                  whitespace: true,
                                  message: "Kérjük töltse ki!",
                                  type: "integer"
                                }
                              ],
                              validateTrigger: ["blur", "keyup"]
                            }
                          ],
                          expression:
                            "[\n                        'officePlace/address/city/id',\n                        {\n                            rules: [\n                                {\n                                required: true,\n                                whitespace: true,\n                                message: 'Kérjük töltse ki!',\n                                type: 'integer'\n                              },\n                            ],\n                            validateTrigger: ['blur','keyup']\n                        }\n                    ]"
                        }
                      ],
                      style: { width: "100%" },
                      attrs: {
                        placeholder: "Töltse ki az irányítószám mezőt!",
                        showArrow: false,
                        disabled: !_vm.options["officePlace/address/city/id"]
                          .length,
                        defaultActiveFirstOption: true,
                        filterOption: false,
                        notFoundContent: _vm.loading[
                          "officePlace/address/zipCode/id"
                        ]
                          ? undefined
                          : null
                      }
                    },
                    [
                      _vm.loading["officePlace/address/zipCode/id"]
                        ? _c("a-spin", {
                            attrs: { slot: "notFoundContent", size: "small" },
                            slot: "notFoundContent"
                          })
                        : _vm._e(),
                      _vm._l(
                        _vm.options["officePlace/address/city/id"],
                        function(option) {
                          return _c(
                            "a-select-option",
                            { key: option.id, attrs: { value: option.id } },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(option.name) +
                                  "\n                    "
                              )
                            ]
                          )
                        }
                      )
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    selfUpdate: true,
                    label:
                      "Közterület (pl.: Váci út, Virág utca, Batthyány tér)"
                  }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "officePlace/address/address",
                          {
                            rules: [
                              {
                                required: true,
                                whitespace: true,
                                message: "Kérjük töltse ki!"
                              },
                              {
                                max:
                                  _vm.characterCounts[
                                    "officePlace/address/address"
                                  ].max,
                                message:
                                  "Maximum " +
                                  _vm.characterCounts[
                                    "officePlace/address/address"
                                  ].max +
                                  " karakter engedélyezett."
                              }
                            ],
                            validateTrigger: ["blur", "keyup"]
                          }
                        ],
                        expression:
                          "[\n                        'officePlace/address/address',\n                        {\n                            rules: [\n                                {\n                                  required: true,\n                                  whitespace: true,\n                                  message: 'Kérjük töltse ki!'\n                                },\n                                {\n                                  max: characterCounts['officePlace/address/address'].max,\n                                  message: 'Maximum ' + characterCounts['officePlace/address/address'].max + ' karakter engedélyezett.',\n                                }\n                            ],\n                            validateTrigger: ['blur','keyup']\n                        }\n                    ]"
                      }
                    ],
                    attrs: { maxLength: 25000 }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    selfUpdate: true,
                    label:
                      "Közterület angol nyelven (pl.: Váci road, Virág street, Batthyány square)"
                  }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "officePlace/address/addressExtra",
                          {
                            rules: [
                              {
                                required: true,
                                whitespace: true,
                                message: "Kérjük töltse ki!"
                              },
                              {
                                max:
                                  _vm.characterCounts[
                                    "officePlace/address/addressExtra"
                                  ].max,
                                message:
                                  "Maximum " +
                                  _vm.characterCounts[
                                    "officePlace/address/addressExtra"
                                  ].max +
                                  " karakter engedélyezett."
                              }
                            ],
                            validateTrigger: ["blur", "keyup"]
                          }
                        ],
                        expression:
                          "[\n                        'officePlace/address/addressExtra',\n                        {\n                            rules: [\n                                {\n                                  required: true,\n                                  whitespace: true,\n                                  message: 'Kérjük töltse ki!'\n                                },\n                                {\n                                  max: characterCounts['officePlace/address/addressExtra'].max,\n                                  message: 'Maximum ' + characterCounts['officePlace/address/addressExtra'].max + ' karakter engedélyezett.',\n                                }\n                            ],\n                            validateTrigger: ['blur','keyup']\n                        }\n                    ]"
                      }
                    ],
                    attrs: { maxLength: 25000 }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { selfUpdate: true, label: "Házszám" } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "officePlace/address/addressNumber",
                          {
                            rules: [
                              {
                                required: true,
                                whitespace: true,
                                message: "Kérjük töltse ki!"
                              },
                              {
                                max:
                                  _vm.characterCounts[
                                    "officePlace/address/addressNumber"
                                  ].max,
                                message:
                                  "Maximum " +
                                  _vm.characterCounts[
                                    "officePlace/address/addressNumber"
                                  ].max +
                                  " karakter engedélyezett."
                              }
                            ],
                            validateTrigger: ["blur", "keyup"]
                          }
                        ],
                        expression:
                          "[\n                        'officePlace/address/addressNumber',\n                        {\n                            rules: [\n                                {\n                                  required: true,\n                                  whitespace: true,\n                                  message: 'Kérjük töltse ki!'\n                                },\n                                {\n                                  max: characterCounts['officePlace/address/addressNumber'].max,\n                                  message: 'Maximum ' + characterCounts['officePlace/address/addressNumber'].max + ' karakter engedélyezett.',\n                                }\n                            ],\n                            validateTrigger: ['blur','keyup']\n                        }\n                    ]"
                      }
                    ],
                    attrs: { maxLength: 25000 }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label:
                      "A Tourinform iroda pontos postázási címe (pl.: 1234 Település, Pf.: 123)",
                    selfUpdate: true
                  }
                },
                [
                  _c("a-textarea", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "officePlace/mailingAddress",
                          {
                            rules: [
                              {
                                required: true,
                                preserve: true,
                                message: "Kérjük töltse ki!"
                              },
                              {
                                max:
                                  _vm.characterCounts[
                                    "officePlace/mailingAddress"
                                  ].max,
                                message:
                                  "Maximum " +
                                  _vm.characterCounts[
                                    "officePlace/mailingAddress"
                                  ].max +
                                  " karakter engedélyezett."
                              }
                            ],
                            validateTrigger: ["blur", "keyup"]
                          }
                        ],
                        expression:
                          "[\n                        'officePlace/mailingAddress',\n                        {\n                            rules: [\n                                {\n                                  required: true,\n                                  preserve: true,\n                                  message: 'Kérjük töltse ki!'\n                                },\n                                {\n                                  max: characterCounts['officePlace/mailingAddress'].max,\n                                  message: 'Maximum ' + characterCounts['officePlace/mailingAddress'].max + ' karakter engedélyezett.',\n                                }\n                            ],\n                            validateTrigger: ['blur', 'keyup']\n                        }\n                    ]"
                      }
                    ],
                    attrs: { maxLength: 25000, autoSize: { minRows: 1 } }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { selfUpdate: true } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v(
                        "\n          A Tourinform iroda GPS koordinátája: szélességi fok\n\n          "
                      ),
                      _c(
                        "a-tooltip",
                        { attrs: { title: _vm.coordinateTooltipLatitude } },
                        [
                          _c("a-icon", { attrs: { type: "question-circle-o" } })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "officePlace/address/lat",
                          {
                            rules: [
                              {
                                required: true,
                                whitespace: true,
                                pattern: /^4([-+]?)([\d]{1})(((\.)(\d+)))$/g,
                                message:
                                  "Kérjük adjon meg helyes GPS koordinátákat!"
                              },
                              {
                                type: "float",
                                message:
                                  "Kérjük adjon meg helyes GPS koordinátákat!",
                                transform: function(value) {
                                  value = parseFloat(value)

                                  return isNaN(value) ? null : value
                                },
                                min: 45.72,
                                max: 48.6
                              }
                            ],
                            validateTrigger: ["blur", "keyup"]
                          }
                        ],
                        expression:
                          "[\n                        'officePlace/address/lat',\n                        {\n                            rules: [\n                                {\n                                  required: true,\n                                  whitespace: true,\n                                  pattern: /^4([-+]?)([\\d]{1})(((\\.)(\\d+)))$/g,\n                                  message: 'Kérjük adjon meg helyes GPS koordinátákat!',\n                                },\n                                {\n                                  type: 'float',\n                                  message: 'Kérjük adjon meg helyes GPS koordinátákat!',\n                                  transform: (value) => {\n                                    value = parseFloat(value);\n\n                                    return isNaN(value) ? null: value;\n                                  },\n                                  min: 45.72,\n                                  max: 48.60\n                                }\n                            ],\n                            validateTrigger: ['blur','keyup']\n                        }\n                    ]"
                      }
                    ],
                    attrs: {
                      maxLength: 25000,
                      placeholder:
                        "Kérjük a koordinátát az alábbi formátumban adja meg: 47.507346"
                    },
                    on: {
                      change: function(event) {
                        return _vm.formatCoordinate(
                          event.target.value,
                          "officePlace/address/lat"
                        )
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { selfUpdate: true } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v(
                        "\n        A Tourinform iroda GPS koordinátája: hosszúsági fok\n\n        "
                      ),
                      _c(
                        "a-tooltip",
                        { attrs: { title: _vm.coordinateTooltipLongitude } },
                        [
                          _c("a-icon", { attrs: { type: "question-circle-o" } })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "officePlace/address/long",
                          {
                            rules: [
                              {
                                required: true,
                                whitespace: true,
                                pattern: /^(1|2)(([-+]?)([\d]{1})((\.)(\d+))?)$/g,
                                message:
                                  "Kérjük adjon meg helyes GPS koordinátákat!"
                              },
                              {
                                type: "float",
                                message:
                                  "Kérjük adjon meg helyes GPS koordinátákat!",
                                transform: function(value) {
                                  value = parseFloat(value)

                                  return isNaN(value) ? null : value
                                },
                                min: 16.1,
                                max: 23
                              }
                            ],
                            validateTrigger: ["blur", "keyup"]
                          }
                        ],
                        expression:
                          "[\n                        'officePlace/address/long',\n                        {\n                            rules: [{\n                                required: true,\n                                whitespace: true,\n                                pattern: /^(1|2)(([-+]?)([\\d]{1})((\\.)(\\d+))?)$/g,\n                                message: 'Kérjük adjon meg helyes GPS koordinátákat!'\n                            },\n                                {\n                                  type: 'float',\n                                  message: 'Kérjük adjon meg helyes GPS koordinátákat!',\n                                  transform: (value) => {\n                                    value = parseFloat(value);\n\n                                    return isNaN(value) ? null: value;\n                                  },\n                                  min: 16.10,\n                                  max: 23\n                                }],\n                            validateTrigger: ['blur','keyup']\n                        }\n                    ]"
                      }
                    ],
                    attrs: {
                      maxLength: 25000,
                      placeholder:
                        "Kérjük a koordinátát az alábbi formátumban adja meg: 19.046610"
                    },
                    on: {
                      change: function(event) {
                        return _vm.formatCoordinate(
                          event.target.value,
                          "officePlace/address/long"
                        )
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label:
                      "A Tourinform iroda vonalas telefonszáma (pl.: +36 1 234 5678)",
                    selfUpdate: true
                  }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "phone",
                          {
                            rules: [
                              {
                                required: !_vm.form.getFieldValue(
                                  "mobilePhone"
                                ),
                                pattern: /^[\/+][36]{1,2}[ ][0-9]{1,2}[ ][0-9]{3}[ ][0-9]{3,4}$/,
                                message: "Hibás telefonszám formátum!"
                              }
                            ],
                            validateTrigger: ["blur"]
                          }
                        ],
                        expression:
                          "[\n                            'phone',\n                            {\n                                rules: [{\n                                    required: !form.getFieldValue('mobilePhone'),\n                                    pattern: /^[\\/+][36]{1,2}[ ][0-9]{1,2}[ ][0-9]{3}[ ][0-9]{3,4}$/,\n                                    message: 'Hibás telefonszám formátum!'\n                                }],\n                               validateTrigger: ['blur']\n                            }\n                        ]"
                      }
                    ],
                    ref: "phone",
                    style: { width: "160px" },
                    attrs: { maxLength: 25000, placeholder: "+36 1 234 5678" },
                    on: {
                      change: function(event) {
                        return _vm.formatPhoneNumber(
                          event.target.value,
                          "phone"
                        )
                      },
                      blur: function(event) {
                        return _vm.form.validateFields()
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label:
                      "A Tourinform iroda mobiltelefonszáma (pl.: +36 20 123 4567)",
                    selfUpdate: true
                  }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "mobilePhone",
                          {
                            rules: [
                              {
                                required: !_vm.form.getFieldValue("phone"),
                                pattern: /^[\/+][36]{1,2}[ ][0-9]{1,2}[ ][0-9]{3}[ ][0-9]{3,4}$/,
                                message: "Hibás telefonszám formátum!"
                              }
                            ],
                            validateTrigger: ["blur"]
                          }
                        ],
                        expression:
                          "[\n                            'mobilePhone',\n                            {\n                                rules: [{\n                                    required: !form.getFieldValue('phone'),\n                                    pattern: /^[\\/+][36]{1,2}[ ][0-9]{1,2}[ ][0-9]{3}[ ][0-9]{3,4}$/,\n                                    message: 'Hibás telefonszám formátum!'\n                                }],\n                               validateTrigger: ['blur']\n                            }\n                        ]"
                      }
                    ],
                    ref: "mobilePhone",
                    style: { width: "160px" },
                    attrs: { maxLength: 25000, placeholder: "+36 20 123 4567" },
                    on: {
                      change: function(event) {
                        return _vm.formatPhoneNumber(
                          event.target.value,
                          "mobilePhone"
                        )
                      },
                      blur: function(event) {
                        return _vm.form.validateFields()
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { selfUpdate: true } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v(
                        "\n          A Tourinform iroda e-mail címe\n          "
                      ),
                      _c(
                        "a-tooltip",
                        {
                          attrs: {
                            title:
                              "Kérjük, az e-mail címet iroda@domain.xy formátumban töltse ki!"
                          }
                        },
                        [
                          _c("a-icon", { attrs: { type: "question-circle-o" } })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "email",
                          {
                            rules: [
                              {
                                required: true,
                                whitespace: true,
                                type: "email",
                                message:
                                  "Kérjük a megfelelő formátumban töltse ki!"
                              },
                              {
                                max: _vm.characterCounts["email"].max,
                                message:
                                  "Maximum " +
                                  _vm.characterCounts["email"].max +
                                  " karakter engedélyezett."
                              }
                            ],
                            validateTrigger: ["blur", "keyup"]
                          }
                        ],
                        expression:
                          "[\n                        'email',\n                        {\n                            rules: [\n                                {\n                                  required: true,\n                                  whitespace: true,\n                                  type: 'email',\n                                  message: 'Kérjük a megfelelő formátumban töltse ki!'\n                                },\n                                {\n                                  max: characterCounts['email'].max,\n                                  message: 'Maximum ' + characterCounts['email'].max + ' karakter engedélyezett.',\n                                }\n                            ],\n                            validateTrigger: ['blur','keyup']\n                        }\n                    ]"
                      }
                    ],
                    attrs: {
                      type: "email",
                      maxLength: 25000,
                      placeholder: "Kérjük az e-mail címet beírni!"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { selfUpdate: true } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v(
                        "\n          A Tourinform iroda weboldalának címe\n\n          "
                      ),
                      _c(
                        "a-tooltip",
                        {
                          attrs: {
                            title:
                              "Kérjük a weboldal címét http(s)://www.weboldal.xy formátumban töltse ki!"
                          }
                        },
                        [
                          _c("a-icon", { attrs: { type: "question-circle-o" } })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "website",
                          {
                            rules: [
                              {
                                required: false,
                                message:
                                  "Kérjük a megfelelő formátumban töltse ki!",
                                pattern: /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
                              },
                              {
                                max: _vm.characterCounts["website"].max,
                                message:
                                  "Maximum " +
                                  _vm.characterCounts["website"].max +
                                  " karakter engedélyezett."
                              }
                            ],
                            validateTrigger: ["blur", "keyup"]
                          }
                        ],
                        expression:
                          "[\n                    'website',\n                    {\n                        rules: [\n                            {\n                              required: false,\n                              message: 'Kérjük a megfelelő formátumban töltse ki!',\n                              pattern: /^https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)/\n                            },\n                            {\n                              max: characterCounts['website'].max,\n                              message: 'Maximum ' + characterCounts['website'].max + ' karakter engedélyezett.',\n                            }\n                        ],\n                        validateTrigger: ['blur', 'keyup']\n                    }\n                ]"
                      }
                    ],
                    attrs: { maxLength: 25000 },
                    on: {
                      change: function(event) {
                        return _vm.transformEncodedUrl(
                          event.target.value,
                          "website"
                        )
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { selfUpdate: true } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v(
                        "\n          A Tourinform iroda angol weboldalának címe\n\n          "
                      ),
                      _c(
                        "a-tooltip",
                        {
                          attrs: {
                            title:
                              "Kérjük a weboldal címét http(s)://www.weboldal.xy formátumban töltse ki!"
                          }
                        },
                        [
                          _c("a-icon", { attrs: { type: "question-circle-o" } })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "websiteExtra",
                          {
                            rules: [
                              {
                                required: false,
                                message:
                                  "Kérjük a megfelelő formátumban töltse ki!",
                                pattern: /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
                              },
                              {
                                max: _vm.characterCounts["websiteExtra"].max,
                                message:
                                  "Maximum " +
                                  _vm.characterCounts["websiteExtra"].max +
                                  " karakter engedélyezett."
                              }
                            ],
                            validateTrigger: ["blur", "keyup"]
                          }
                        ],
                        expression:
                          "[\n                    'websiteExtra',\n                    {\n                        rules: [\n                            {\n                              required: false,\n                              message: 'Kérjük a megfelelő formátumban töltse ki!',\n                              pattern: /^https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)/\n                            },\n                            {\n                              max: characterCounts['websiteExtra'].max,\n                              message: 'Maximum ' + characterCounts['websiteExtra'].max + ' karakter engedélyezett.',\n                            }\n                        ],\n                        validateTrigger: ['blur', 'keyup']\n                    }\n                ]"
                      }
                    ],
                    attrs: { maxLength: 25000 },
                    on: {
                      change: function(event) {
                        return _vm.transformEncodedUrl(
                          event.target.value,
                          "websiteExtra"
                        )
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label:
                      "A Tourinform iroda weboldalának nyelvei (több válasz is adható)."
                  }
                },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "websiteLanguages/id",
                            {
                              rules: [],
                              validateTrigger: ["blur"]
                            }
                          ],
                          expression:
                            "[\n                    'websiteLanguages/id',\n                    {\n                        rules: [],\n                        validateTrigger: ['blur']\n                    }\n                ]"
                        }
                      ],
                      attrs: {
                        getPopupContainer: _vm.getParentTriggerNode(),
                        mode: "multiple",
                        placeholder: "Kérjük válasszon!",
                        filterOption: _vm.filterSelectNames
                      }
                    },
                    _vm._l(_vm.options["websiteLanguages/id"], function(
                      language
                    ) {
                      return _c(
                        "a-select-option",
                        { key: language.id, attrs: { value: language.id } },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(language.name) +
                              "\n                    "
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { selfUpdate: true } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v(
                        "\n          A Tourinform iroda Facebook oldalának címe\n\n          "
                      ),
                      _c(
                        "a-tooltip",
                        {
                          attrs: {
                            title:
                              "Kérjük a Facebook linket az alábbi formátumban adja meg: https://www.facebook.com/tourinformiroda"
                          }
                        },
                        [
                          _c("a-icon", { attrs: { type: "question-circle-o" } })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "facebook",
                          {
                            rules: [
                              {
                                required: false,
                                message:
                                  "Kérjük a megfelelő formátumban töltse ki!",
                                pattern: /^(http|https):\/\/(?:www.)?facebook.com/g
                              },
                              {
                                max: _vm.characterCounts["facebook"].max,
                                message:
                                  "Maximum " +
                                  _vm.characterCounts["facebook"].max +
                                  " karakter engedélyezett."
                              }
                            ],
                            validateTrigger: ["blur", "keyup"]
                          }
                        ],
                        expression:
                          "[\n                    'facebook',\n                    {\n                        rules: [\n                            {\n                                required: false,\n                                message: 'Kérjük a megfelelő formátumban töltse ki!',\n                                pattern: /^(http|https):\\/\\/(?:www.)?facebook.com/g\n                            },\n                            {\n                              max: characterCounts['facebook'].max,\n                              message: 'Maximum ' + characterCounts['facebook'].max + ' karakter engedélyezett.',\n                            }\n                        ],\n                        validateTrigger: ['blur', 'keyup']\n                    }\n                ]"
                      }
                    ],
                    attrs: { maxLength: 25000 }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label:
                      "A Tourinform iroda munkatársai által beszélt nyelvek (Több válasz is adható)."
                  }
                },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "spokenLanguages/id",
                            {
                              rules: [
                                {
                                  required: true,
                                  message: "Kérjük töltse ki!"
                                }
                              ],
                              validateTrigger: ["blur"]
                            }
                          ],
                          expression:
                            "[\n                    'spokenLanguages/id',\n                    {\n                        rules: [{\n                            required: true,\n                            message: 'Kérjük töltse ki!'\n                        }],\n                        validateTrigger: ['blur']\n                    }\n                ]"
                        }
                      ],
                      attrs: {
                        getPopupContainer: _vm.getParentTriggerNode(),
                        mode: "multiple",
                        placeholder: "Kérjük válasszon!",
                        filterOption: _vm.filterSelectNames
                      }
                    },
                    _vm._l(_vm.options["spokenLanguages/id"], function(
                      language
                    ) {
                      return _c(
                        "a-select-option",
                        { key: language.id, attrs: { value: language.id } },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(language.name) +
                              "\n                    "
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label:
                      "Tourinform iroda típusa (ügyfélfogadási időtartam szerint)"
                  }
                },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "seasonal",
                            {
                              rules: [
                                {
                                  required: true,
                                  message: "Kérjük válasszon!"
                                }
                              ]
                            }
                          ],
                          expression:
                            "[\n                    'seasonal',\n                    {\n                        rules: [{\n                            required: true,\n                            message: 'Kérjük válasszon!'\n                        }]\n                    }\n                ]"
                        }
                      ],
                      attrs: { placeholder: "Kérjük válasszon!" }
                    },
                    [
                      _c("a-select-option", { attrs: { value: 1 } }, [
                        _vm._v(
                          "\n                        Szezonálisan nyitva tartó\n                    "
                        )
                      ]),
                      _c("a-select-option", { attrs: { value: 0 } }, [
                        _vm._v(
                          "\n                        Egész évben nyitva tartó\n                    "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.getFieldValue("seasonal") === 1,
                      expression: "form.getFieldValue('seasonal') === 1"
                    }
                  ],
                  attrs: { label: "Nyitás dátuma" }
                },
                [
                  _c("a-date-picker", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "seasonStartDate",
                          {
                            rules: [
                              {
                                required:
                                  _vm.form.getFieldValue("seasonal") === 1,
                                message: "Kérjük töltse ki!"
                              }
                            ]
                          }
                        ],
                        expression:
                          "[\n                    `seasonStartDate`,\n                    {\n                        rules: [{\n                            required: form.getFieldValue('seasonal') === 1,\n                            message: 'Kérjük töltse ki!',\n                        }],\n                    }\n                ]"
                      }
                    ],
                    staticStyle: { width: "85%" },
                    attrs: {
                      "disabled-date": _vm.openingRangeStartDisabled,
                      format: _vm.dateFields["seasonStartDate"]
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.getFieldValue("seasonal") === 1,
                      expression: "form.getFieldValue('seasonal') === 1"
                    }
                  ],
                  attrs: { label: "Zárás dátuma" }
                },
                [
                  _c("a-date-picker", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "seasonEndDate",
                          {
                            rules: [
                              {
                                required:
                                  _vm.form.getFieldValue("seasonal") === 1,
                                message: "Kérjük töltse ki!"
                              }
                            ]
                          }
                        ],
                        expression:
                          "[\n                    'seasonEndDate',\n                    {\n                        rules: [{\n                            required: form.getFieldValue('seasonal') === 1,\n                            message: 'Kérjük töltse ki!',\n                        }],\n                    }\n                ]"
                      }
                    ],
                    staticStyle: { width: "85%" },
                    attrs: {
                      "disabled-date": _vm.openingRangeEndDisabled,
                      format: _vm.dateFields["seasonEndDate"]
                    }
                  })
                ],
                1
              ),
              _c("h2", [
                _c("span", { staticClass: "required-asterisk" }, [_vm._v("*")]),
                _vm._v(
                  "\n                A Tourinform iroda nyitva tartása\n            "
                )
              ]),
              _vm._l(this.days, function(day) {
                return [
                  day.weekdayStart
                    ? _c("h4", [
                        _vm._v(
                          "\n                    Hétköznap\n                "
                        )
                      ])
                    : _vm._e(),
                  day.weekendStart
                    ? _c("h4", [
                        _vm._v(
                          "\n                    Hétvégén\n                "
                        )
                      ])
                    : _vm._e(),
                  _c(
                    "a-form-item",
                    { key: day.fieldPrefix, attrs: { label: day.name } },
                    [
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            { attrs: { md: 8 } },
                            [
                              _c(
                                "a-form-item",
                                [
                                  _c("a-time-picker", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          day.fieldPrefix + "/startTime",
                                          {
                                            rules: [
                                              {
                                                required: !_vm.isChecked(
                                                  day.fieldPrefix + "/closed"
                                                ),
                                                message:
                                                  "Kérjük válassza ki a listából a megfelelő értéket VAGY ZÁRVA"
                                              }
                                            ]
                                          }
                                        ],
                                        expression:
                                          "[\n                                day.fieldPrefix + '/startTime',\n                                {\n                                rules: [\n                                    {\n                                      required: !isChecked(day.fieldPrefix + '/closed'),\n                                      message: 'Kérjük válassza ki a listából a megfelelő értéket VAGY ZÁRVA',\n                                    }\n                                  ]\n                                }\n                                ]"
                                      }
                                    ],
                                    staticStyle: { width: "85%" },
                                    attrs: {
                                      disabled: _vm.isChecked(
                                        day.fieldPrefix + "/closed"
                                      ),
                                      "minute-step": 15,
                                      format:
                                        _vm.dateFields[
                                          day.fieldPrefix + "/startTime"
                                        ]
                                    },
                                    on: {
                                      change: function(time, timeString) {
                                        return _vm.timePickerChange(
                                          day.fieldPrefix + "/startTime",
                                          time,
                                          timeString
                                        )
                                      }
                                    }
                                  }),
                                  _c("span", [_vm._v("-tól")])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { md: 8 } },
                            [
                              _c(
                                "a-form-item",
                                [
                                  _c("a-time-picker", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          day.fieldPrefix + "/endTime",
                                          {
                                            rules: [
                                              {
                                                required: !_vm.isChecked(
                                                  day.fieldPrefix + "/closed"
                                                ),
                                                message:
                                                  "Kérjük válassza ki a listából a megfelelő értéket VAGY ZÁRVA"
                                              }
                                            ]
                                          }
                                        ],
                                        expression:
                                          "[\n                    day.fieldPrefix + '/endTime',\n                      {\n                        rules: [\n                          {\n                            required: !isChecked(day.fieldPrefix + '/closed'),\n                            message: 'Kérjük válassza ki a listából a megfelelő értéket VAGY ZÁRVA',\n                          }\n                        ]\n                      }\n                    ]"
                                      }
                                    ],
                                    staticStyle: { width: "85%" },
                                    attrs: {
                                      disabled: _vm.isChecked(
                                        day.fieldPrefix + "/closed"
                                      ),
                                      "minute-step": 15,
                                      format:
                                        _vm.dateFields[
                                          day.fieldPrefix + "/endTime"
                                        ]
                                    },
                                    on: {
                                      change: function(time, timeString) {
                                        return _vm.timePickerChange(
                                          day.fieldPrefix + "/endTime",
                                          time,
                                          timeString
                                        )
                                      }
                                    }
                                  }),
                                  _c("span", [_vm._v("-ig")])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { md: 8 } },
                            [
                              _c(
                                "a-form-item",
                                [
                                  _c(
                                    "a-checkbox",
                                    {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value: [day.fieldPrefix + "/closed"],
                                          expression:
                                            "[day.fieldPrefix + '/closed']"
                                        }
                                      ],
                                      attrs: {
                                        checked: _vm.isChecked(
                                          day.fieldPrefix + "/closed"
                                        )
                                      },
                                      on: {
                                        change: function() {
                                          return _vm.relevancyCheckboxChange([
                                            day.fieldPrefix + "/startTime",
                                            day.fieldPrefix + "/endTime"
                                          ])
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    ZÁRVA\n                                "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              }),
              _c("a-divider"),
              _c(
                "a-form-item",
                {
                  attrs: {
                    selfUpdate: true,
                    label:
                      "Ha a nyitva tartás hónaponként eltérő kérjük itt jelezze"
                  }
                },
                [
                  _c("a-textarea", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "monthlyOpening",
                          {
                            rules: [
                              {
                                required: false,
                                message: "Kérjük töltse ki!"
                              },
                              {
                                max: _vm.characterCounts["monthlyOpening"].max,
                                message:
                                  "Maximum " +
                                  _vm.characterCounts["monthlyOpening"].max +
                                  " karakter engedélyezett."
                              }
                            ]
                          }
                        ],
                        expression:
                          "[\n                    'monthlyOpening',\n                    {\n                        rules: [\n                            {\n                            required: false,\n                            message: 'Kérjük töltse ki!'\n                            },\n                            {\n                              max: characterCounts['monthlyOpening'].max,\n                              message: 'Maximum ' + characterCounts['monthlyOpening'].max + ' karakter engedélyezett.',\n                            }\n                        ]\n                    }\n                ]"
                      }
                    ],
                    attrs: { maxLength: 25000, autoSize: { minRows: 1 } }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    selfUpdate: true,
                    label:
                      "Speciális nyitva tartás (egyéb a fenti nyitva tartási kategóriákba nem besorolható esetek; pl.: 04.17-én technikai okok miatt zárva)"
                  }
                },
                [
                  _c("a-textarea", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "specialOpening",
                          {
                            rules: [
                              {
                                required: false,
                                message: "Kérjük töltse ki!"
                              },
                              {
                                max: _vm.characterCounts["specialOpening"].max,
                                message:
                                  "Maximum " +
                                  _vm.characterCounts["specialOpening"].max +
                                  " karakter engedélyezett."
                              }
                            ]
                          }
                        ],
                        expression:
                          "[\n                    'specialOpening',\n                    {\n                        rules: [\n                            {\n                            required: false,\n                            message: 'Kérjük töltse ki!'\n                            },\n                            {\n                              max: characterCounts['specialOpening'].max,\n                              message: 'Maximum ' + characterCounts['specialOpening'].max + ' karakter engedélyezett.',\n                            }\n                        ]\n                    }\n                ]"
                      }
                    ],
                    attrs: { maxLength: 25000, autoSize: { minRows: 1 } }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "Az iroda műemléki épületben található?" } },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "officePlace/monument",
                            {
                              rules: [
                                {
                                  required: true,
                                  message: "Kérjük válasszon!"
                                }
                              ]
                            }
                          ],
                          expression:
                            "[\n                    'officePlace/monument',\n                    {\n                        rules: [{\n                            required: true,\n                            message: 'Kérjük válasszon!'\n                        }]\n                    }\n                ]"
                        }
                      ],
                      attrs: { placeholder: "Kérjük válasszon!" }
                    },
                    [
                      _c("a-select-option", { attrs: { value: 1 } }, [
                        _vm._v(
                          "\n                        Igen\n                    "
                        )
                      ]),
                      _c("a-select-option", { attrs: { value: 0 } }, [
                        _vm._v(
                          "\n                        Nem\n                    "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: "Az iroda vendégterének alapterülete",
                    selfUpdate: true
                  }
                },
                [
                  _c("a-input-number", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "officePlace/size",
                          {
                            validateTrigger: ["blur", "keyup"],
                            rules: [
                              {
                                required: true,
                                message: "Kérjük töltse ki!"
                              }
                            ]
                          }
                        ],
                        expression:
                          "[\n                    'officePlace/size',\n                    {\n                        validateTrigger: ['blur', 'keyup'],\n                        rules: [{\n                            required: true,\n                            message: 'Kérjük töltse ki!',\n                        }]\n                    }\n                ]"
                      }
                    ],
                    attrs: { max: 9999 }
                  }),
                  _vm._v("\n                m"),
                  _c("sup", [_vm._v("2")])
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: "Az iroda utolsó felújításának éve",
                    selfUpdate: true
                  }
                },
                [
                  _c("a-input-number", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "officePlace/lastRenovation",
                          {
                            validateTrigger: ["blur", "keyup"],
                            rules: [
                              {
                                required: true,
                                message: "Kérjük töltse ki!"
                              }
                            ]
                          }
                        ],
                        expression:
                          "[\n                    'officePlace/lastRenovation',\n                    {\n                        validateTrigger: ['blur', 'keyup'],\n                        rules: [{\n                            required: true,\n                            message: 'Kérjük töltse ki!',\n                        }]\n                    }\n                ]"
                      }
                    ],
                    attrs: { max: 2100, min: 1901 }
                  }),
                  _vm._v("\n                év\n            ")
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label:
                      "Az iroda fizikai akadálymentesített (kerekesszékkel bejárható)?"
                  }
                },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "officePlace/physicallyAccessible",
                            {
                              rules: [
                                {
                                  required: true,
                                  message: "Kérjük töltse ki!"
                                }
                              ],
                              validateTrigger: "blur"
                            }
                          ],
                          expression:
                            "[\n                    'officePlace/physicallyAccessible',\n                    {\n                        rules: [{\n                            required: true,\n                            message: 'Kérjük töltse ki!'\n                        }],\n                        validateTrigger: 'blur'\n                    }\n                ]"
                        }
                      ],
                      attrs: { placeholder: "Kérjük válasszon!" }
                    },
                    [
                      _c("a-select-option", { attrs: { value: 1 } }, [
                        _vm._v(
                          "\n                        Igen\n                    "
                        )
                      ]),
                      _c("a-select-option", { attrs: { value: 0 } }, [
                        _vm._v(
                          "\n                        Nem\n                    "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: "Az iroda hallássérültek számára akadálymentesített?"
                  }
                },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "officePlace/deafAccessible",
                            {
                              rules: [
                                {
                                  required: true,
                                  message: "Kérjük töltse ki!"
                                }
                              ],
                              validateTrigger: "blur"
                            }
                          ],
                          expression:
                            "[\n                    'officePlace/deafAccessible',\n                    {\n                        rules: [{\n                            required: true,\n                            message: 'Kérjük töltse ki!'\n                        }],\n                        validateTrigger: 'blur'\n                    }\n                ]"
                        }
                      ],
                      attrs: { placeholder: "Kérjük válasszon!" }
                    },
                    [
                      _c("a-select-option", { attrs: { value: 1 } }, [
                        _vm._v(
                          "\n                        Igen\n                    "
                        )
                      ]),
                      _c("a-select-option", { attrs: { value: 0 } }, [
                        _vm._v(
                          "\n                        Nem\n                    "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: "Az iroda látássérültek számára akadálymentesített?"
                  }
                },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "officePlace/blindAccessible",
                            {
                              rules: [
                                {
                                  required: true,
                                  message: "Kérjük töltse ki!"
                                }
                              ],
                              validateTrigger: "blur"
                            }
                          ],
                          expression:
                            "[\n                    'officePlace/blindAccessible',\n                    {\n                        rules: [{\n                            required: true,\n                            message: 'Kérjük töltse ki!'\n                        }],\n                        validateTrigger: 'blur'\n                    }\n                ]"
                        }
                      ],
                      attrs: { placeholder: "Kérjük válasszon!" }
                    },
                    [
                      _c("a-select-option", { attrs: { value: 1 } }, [
                        _vm._v(
                          "\n                        Igen\n                    "
                        )
                      ]),
                      _c("a-select-option", { attrs: { value: 0 } }, [
                        _vm._v(
                          "\n                        Nem\n                    "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label:
                      "A Tourinform iroda környezete akadálymentesített-e? (bejárattól 100 méteres körben)?"
                  }
                },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "officePlace/areaAccessible",
                            {
                              rules: [
                                {
                                  required: true,
                                  message: "Kérjük töltse ki!"
                                }
                              ],
                              validateTrigger: "blur"
                            }
                          ],
                          expression:
                            "[\n                    'officePlace/areaAccessible',\n                    {\n                        rules: [{\n                            required: true,\n                            message: 'Kérjük töltse ki!'\n                        }],\n                        validateTrigger: 'blur'\n                    }\n                ]"
                        }
                      ],
                      attrs: { placeholder: "Kérjük válasszon!" }
                    },
                    [
                      _c("a-select-option", { attrs: { value: 1 } }, [
                        _vm._v(
                          "\n                        Igen\n                    "
                        )
                      ]),
                      _c("a-select-option", { attrs: { value: 0 } }, [
                        _vm._v(
                          "\n                        Nem\n                    "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: { label: "Milyen bankkártya típusokat fogadnak el?" }
                },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "creditCards/id",
                            {
                              rules: [
                                {
                                  required: false,
                                  message: "Kérjük töltse ki!"
                                }
                              ],
                              validateTrigger: "blur"
                            }
                          ],
                          expression:
                            "['creditCards/id', {\n                        rules: [{\n                            required: false,\n                            message: 'Kérjük töltse ki!'\n                        }],\n                        validateTrigger: 'blur'\n                    }]"
                        }
                      ],
                      attrs: {
                        disabled: !_vm.isChecked("creditCardsAccepted"),
                        getPopupContainer: _vm.getParentTriggerNode(),
                        mode: "multiple",
                        loading: _vm.loading["creditCards/id"],
                        placeholder: "Kérjük válasszon!",
                        filterOption: _vm.filterSelectNames
                      },
                      on: {
                        dropdownVisibleChange: function(open) {
                          return open && _vm.loadOptions("creditCards/id")
                        }
                      }
                    },
                    _vm._l(_vm.options["creditCards/id"], function(category) {
                      return _c(
                        "a-select-option",
                        { key: category.id, attrs: { value: category.id } },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(category.label) +
                              "\n                    "
                          )
                        ]
                      )
                    }),
                    1
                  ),
                  _c(
                    "a-checkbox",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "creditCardsAccepted",
                            {
                              rules: [
                                {
                                  required: false
                                }
                              ]
                            }
                          ],
                          expression:
                            "[\n                    'creditCardsAccepted',\n                    {\n                        rules: [{\n                            required: false\n                        }]\n                    }\n                ]"
                        }
                      ],
                      attrs: { checked: _vm.isChecked("creditCardsAccepted") },
                      on: {
                        change: function() {
                          return _vm.relevancyCheckboxChange(
                            "creditCardsAccepted"
                          )
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                    Elfogadnak bankkártyát\n                "
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: "Milyen utalványokat, vouchereket fogadnak el?"
                  }
                },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "vouchers/id",
                            {
                              rules: [
                                {
                                  required: false,
                                  message: "Kérjük töltse ki!"
                                }
                              ],
                              validateTrigger: "blur"
                            }
                          ],
                          expression:
                            "['vouchers/id', {\n                        rules: [{\n                            required: false,\n                            message: 'Kérjük töltse ki!'\n                        }],\n                        validateTrigger: 'blur'\n                    }]"
                        }
                      ],
                      attrs: {
                        disabled: !_vm.isChecked("vouchersAccepted"),
                        getPopupContainer: _vm.getParentTriggerNode(),
                        mode: "multiple",
                        loading: _vm.loading["vouchers/id"],
                        placeholder: "Kérjük válasszon!",
                        filterOption: _vm.filterSelectNames
                      },
                      on: {
                        dropdownVisibleChange: function(open) {
                          return open && _vm.loadOptions("vouchers/id")
                        }
                      }
                    },
                    _vm._l(_vm.options["vouchers/id"], function(category) {
                      return _c(
                        "a-select-option",
                        { key: category.id, attrs: { value: category.id } },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(category.label) +
                              "\n                    "
                          )
                        ]
                      )
                    }),
                    1
                  ),
                  _c(
                    "a-checkbox",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "vouchersAccepted",
                            {
                              rules: [
                                {
                                  required: false
                                }
                              ]
                            }
                          ],
                          expression:
                            "[\n                    'vouchersAccepted',\n                    {\n                        rules: [{\n                            required: false\n                        }]\n                    }\n                ]"
                        }
                      ],
                      attrs: { checked: _vm.isChecked("vouchersAccepted") },
                      on: {
                        change: function() {
                          return _vm.relevancyCheckboxChange("vouchersAccepted")
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                    Elfogadnak utalványokat, vouchereket\n                "
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: "Milyen idegen fizetőeszközt elfogadnak? (valuta)"
                  }
                },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "foreignCurrencies/id",
                            {
                              rules: [
                                {
                                  required: false,
                                  message: "Kérjük töltse ki!"
                                }
                              ],
                              validateTrigger: "blur"
                            }
                          ],
                          expression:
                            "['foreignCurrencies/id', {\n                        rules: [{\n                            required: false,\n                            message: 'Kérjük töltse ki!'\n                        }],\n                        validateTrigger: 'blur'\n                    }]"
                        }
                      ],
                      attrs: {
                        disabled: !_vm.isChecked("foreignCurrencyAccepted"),
                        getPopupContainer: _vm.getParentTriggerNode(),
                        mode: "multiple",
                        loading: _vm.loading["foreignCurrencies/id"],
                        placeholder: "Kérjük válasszon!",
                        filterOption: _vm.filterSelectNames
                      },
                      on: {
                        dropdownVisibleChange: function(open) {
                          return open && _vm.loadOptions("foreignCurrencies/id")
                        }
                      }
                    },
                    _vm._l(_vm.options["foreignCurrencies/id"], function(
                      category
                    ) {
                      return _c(
                        "a-select-option",
                        { key: category.id, attrs: { value: category.id } },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(category.label) +
                              "\n                    "
                          )
                        ]
                      )
                    }),
                    1
                  ),
                  _c(
                    "a-checkbox",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "foreignCurrencyAccepted",
                            {
                              rules: [
                                {
                                  required: false
                                }
                              ]
                            }
                          ],
                          expression:
                            "[\n                    'foreignCurrencyAccepted',\n                    {\n                        rules: [{\n                            required: false\n                        }]\n                    }\n                ]"
                        }
                      ],
                      attrs: {
                        checked: _vm.isChecked("foreignCurrencyAccepted")
                      },
                      on: {
                        change: function() {
                          return _vm.relevancyCheckboxChange(
                            "foreignCurrencyAccepted"
                          )
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                    Elfogadnak idegen fizetőeszközt (valuta)\n                "
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label:
                      "A tourinform iroda szolgáltatásai (Több válasz is adható)."
                  }
                },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "services/id",
                            {
                              rules: [
                                {
                                  required: true,
                                  message: "Kérjük töltse ki!"
                                }
                              ],
                              validateTrigger: "blur"
                            }
                          ],
                          expression:
                            "['services/id', {\n                        rules: [{\n                            required: true,\n                            message: 'Kérjük töltse ki!'\n                        }],\n                        validateTrigger: 'blur'\n                    }]"
                        }
                      ],
                      attrs: {
                        getPopupContainer: _vm.getParentTriggerNode(),
                        mode: "multiple",
                        loading: _vm.loading["services/id"],
                        placeholder: "Kérjük válasszon!",
                        filterOption: _vm.filterSelectNames
                      },
                      on: {
                        dropdownVisibleChange: function(open) {
                          return open && _vm.loadOptions("services/id")
                        }
                      }
                    },
                    _vm._l(_vm.options["services/id"], function(service) {
                      return _c(
                        "a-select-option",
                        { key: service.id, attrs: { value: service.id } },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(service.name) +
                              "\n                    "
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    selfUpdate: true,
                    label: "A Tourinform iroda egyéb szolgáltatásai"
                  }
                },
                [
                  _c("a-textarea", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "otherServices",
                          {
                            rules: [
                              {
                                required: false,
                                message: "Kérjük töltse ki!"
                              },
                              {
                                max: _vm.characterCounts["otherServices"].max,
                                message:
                                  "Maximum " +
                                  _vm.characterCounts["otherServices"].max +
                                  " karakter engedélyezett."
                              }
                            ]
                          }
                        ],
                        expression:
                          "[\n                    'otherServices',\n                    {\n                        rules: [\n                            {\n                            required: false,\n                            message: 'Kérjük töltse ki!'\n                            },\n                            {\n                              max: characterCounts['otherServices'].max,\n                              message: 'Maximum ' + characterCounts['otherServices'].max + ' karakter engedélyezett.',\n                            }\n                        ]\n                    }\n                ]"
                      }
                    ],
                    attrs: { maxLength: 25000, autoSize: { minRows: 1 } }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    selfUpdate: true,
                    label:
                      "A Tourinform iroda egyéb szolgáltatásai angol nyelven"
                  }
                },
                [
                  _c("a-textarea", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "otherServicesExtra",
                          {
                            rules: [
                              {
                                required: false,
                                message: "Kérjük töltse ki!"
                              },
                              {
                                max:
                                  _vm.characterCounts["otherServicesExtra"].max,
                                message:
                                  "Maximum " +
                                  _vm.characterCounts["otherServicesExtra"]
                                    .max +
                                  " karakter engedélyezett."
                              }
                            ]
                          }
                        ],
                        expression:
                          "[\n                    'otherServicesExtra',\n                    {\n                        rules: [\n                            {\n                            required: false,\n                            message: 'Kérjük töltse ki!'\n                            },\n                            {\n                              max: characterCounts['otherServicesExtra'].max,\n                              message: 'Maximum ' + characterCounts['otherServicesExtra'].max + ' karakter engedélyezett.',\n                            }\n                        ]\n                    }\n                ]"
                      }
                    ],
                    attrs: { maxLength: 25000, autoSize: { minRows: 1 } }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label:
                      "Rendelkezik az iroda fix helyszínre telepített Tourinform-ponttal/Infóponttal (pl, épület, pavilon, konténeriroda)?"
                  }
                },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "hasInfopoint",
                            {
                              rules: [
                                {
                                  required: true,
                                  message: "Kérjük töltse ki!"
                                }
                              ],
                              validateTrigger: "blur"
                            }
                          ],
                          expression:
                            "[\n                    'hasInfopoint',\n                    {\n                        rules: [{\n                            required: true,\n                            message: 'Kérjük töltse ki!'\n                        }],\n                        validateTrigger: 'blur'\n                    }\n                ]"
                        }
                      ],
                      attrs: { placeholder: "Kérjük válasszon!" }
                    },
                    [
                      _c("a-select-option", { attrs: { value: 1 } }, [
                        _vm._v(
                          "\n                        Igen\n                    "
                        )
                      ]),
                      _c("a-select-option", { attrs: { value: 0 } }, [
                        _vm._v(
                          "\n                        Nem\n                    "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              [
                _c(
                  "a-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.form.getFieldValue("hasInfopoint"),
                        expression: "form.getFieldValue('hasInfopoint')"
                      }
                    ],
                    attrs: {
                      label:
                        "A Tourinform-pont/Infópont pontos címe (pl.: 1234 Település, Minta utca 56.sz. 7.a.)",
                      selfUpdate: true
                    }
                  },
                  [
                    _c("a-textarea", {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "infopointPlace/mailingAddress",
                            {
                              rules: [
                                {
                                  required: false,
                                  preserve: true,
                                  message: "Kérjük töltse ki!"
                                },
                                {
                                  max:
                                    _vm.characterCounts[
                                      "infopointPlace/mailingAddress"
                                    ].max,
                                  message:
                                    "Maximum " +
                                    _vm.characterCounts[
                                      "infopointPlace/mailingAddress"
                                    ].max +
                                    " karakter engedélyezett."
                                }
                              ],
                              validateTrigger: ["blur", "keyup"]
                            }
                          ],
                          expression:
                            "[\n                        'infopointPlace/mailingAddress',\n                        {\n                            rules: [\n                                {\n                                  required: false,\n                                  preserve: true,\n                                  message: 'Kérjük töltse ki!'\n                                },\n                                {\n                                  max: characterCounts['infopointPlace/mailingAddress'].max,\n                                  message: 'Maximum ' + characterCounts['infopointPlace/mailingAddress'].max + ' karakter engedélyezett.',\n                                }\n                            ],\n                            validateTrigger: ['blur', 'keyup']\n                        }\n                    ]"
                        }
                      ],
                      attrs: {
                        maxLength: 25000,
                        readonly: "readonly",
                        autoSize: { minRows: 1 }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "a-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.form.getFieldValue("hasInfopoint"),
                        expression: "form.getFieldValue('hasInfopoint')"
                      }
                    ],
                    attrs: { label: "A Tourinform-pont/Infópont irányítószáma" }
                  },
                  [
                    _c(
                      "a-select",
                      {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "infopointPlace/address/zipCode/id",
                              {
                                rules: [
                                  {
                                    required: _vm.form.getFieldValue(
                                      "hasInfopoint"
                                    ),
                                    whitespace: true,
                                    message: "Kérjük töltse ki!",
                                    type: "integer"
                                  }
                                ],
                                validateTrigger: ["blur", "keyup"]
                              }
                            ],
                            expression:
                              "[\n                        'infopointPlace/address/zipCode/id',\n                        {\n                            rules: [\n                                {\n                                required: form.getFieldValue('hasInfopoint'),\n                                whitespace: true,\n                                message: 'Kérjük töltse ki!',\n                                type: 'integer'\n                              },\n                            ],\n                            validateTrigger: ['blur','keyup']\n                        }\n                    ]"
                          }
                        ],
                        style: { width: "100%" },
                        attrs: {
                          placeholder: "Kezdjen gépelni",
                          showSearch: "",
                          showArrow: false,
                          defaultActiveFirstOption: false,
                          getPopupContainer: _vm.getParentTriggerNode(),
                          filterOption: false,
                          notFoundContent: _vm.loading[
                            "infopointPlace/address/zipCode/id"
                          ]
                            ? undefined
                            : null
                        },
                        on: {
                          search: function(value) {
                            return _vm.zipCodeAutoComplete(
                              value,
                              "infopointPlace/address/zipCode/id"
                            )
                          },
                          change: function(value) {
                            _vm.fillCityOptions(
                              value,
                              "infopointPlace/address/zipCode/id",
                              "infopointPlace/address/city/id"
                            )
                          },
                          dropdownVisibleChange: function(open) {
                            return (
                              open &&
                              _vm.zipCodeAutoComplete(
                                null,
                                "infopointPlace/address/zipCode/id"
                              )
                            )
                          }
                        }
                      },
                      [
                        _vm.loading["infopointPlace/address/zipCode/id"]
                          ? _c("a-spin", {
                              attrs: { slot: "notFoundContent", size: "small" },
                              slot: "notFoundContent"
                            })
                          : _vm._e(),
                        _vm._l(
                          _vm.options["infopointPlace/address/zipCode/id"],
                          function(option) {
                            return _c(
                              "a-select-option",
                              { key: option.id, attrs: { value: option.id } },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(option.code) +
                                    "\n                        "
                                )
                              ]
                            )
                          }
                        )
                      ],
                      2
                    )
                  ],
                  1
                ),
                _c(
                  "a-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.form.getFieldValue("hasInfopoint"),
                        expression: "form.getFieldValue('hasInfopoint')"
                      }
                    ],
                    attrs: {
                      label: "A Tourinform-pont/Infópont településének neve"
                    }
                  },
                  [
                    _c(
                      "a-select",
                      {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "infopointPlace/address/city/id",
                              {
                                rules: [
                                  {
                                    required: _vm.form.getFieldValue(
                                      "hasInfopoint"
                                    ),
                                    whitespace: true,
                                    message: "Kérjük töltse ki!",
                                    type: "integer"
                                  }
                                ],
                                validateTrigger: ["blur", "keyup"]
                              }
                            ],
                            expression:
                              "[\n                        'infopointPlace/address/city/id',\n                        {\n                            rules: [\n                                {\n                                required: form.getFieldValue('hasInfopoint'),\n                                whitespace: true,\n                                message: 'Kérjük töltse ki!',\n                                type: 'integer'\n                              },\n                            ],\n                            validateTrigger: ['blur','keyup']\n                        }\n                    ]"
                          }
                        ],
                        style: { width: "100%" },
                        attrs: {
                          placeholder: "Töltse ki az irányítószám mezőt!",
                          showArrow: false,
                          disabled: !_vm.options[
                            "infopointPlace/address/city/id"
                          ].length,
                          defaultActiveFirstOption: true,
                          filterOption: false,
                          notFoundContent: _vm.loading[
                            "infopointPlace/address/zipCode/id"
                          ]
                            ? undefined
                            : null
                        }
                      },
                      [
                        _vm.loading["infopointPlace/address/zipCode/id"]
                          ? _c("a-spin", {
                              attrs: { slot: "notFoundContent", size: "small" },
                              slot: "notFoundContent"
                            })
                          : _vm._e(),
                        _vm._l(
                          _vm.options["infopointPlace/address/city/id"],
                          function(option) {
                            return _c(
                              "a-select-option",
                              { key: option.id, attrs: { value: option.id } },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(option.name) +
                                    "\n                        "
                                )
                              ]
                            )
                          }
                        )
                      ],
                      2
                    )
                  ],
                  1
                ),
                _c(
                  "a-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.form.getFieldValue("hasInfopoint"),
                        expression: "form.getFieldValue('hasInfopoint')"
                      }
                    ],
                    attrs: {
                      selfUpdate: true,
                      label:
                        "A Tourinform-pont/Infópont közterületének neve (pl.: Váci út, Virág utca, Batthyány tér)"
                    }
                  },
                  [
                    _c("a-input", {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "infopointPlace/address/address",
                            {
                              rules: [
                                {
                                  required: _vm.form.getFieldValue(
                                    "hasInfopoint"
                                  ),
                                  whitespace: true,
                                  message: "Kérjük töltse ki!"
                                },
                                {
                                  max:
                                    _vm.characterCounts[
                                      "infopointPlace/address/address"
                                    ].max,
                                  message:
                                    "Maximum " +
                                    _vm.characterCounts[
                                      "infopointPlace/address/address"
                                    ].max +
                                    " karakter engedélyezett."
                                }
                              ],
                              validateTrigger: ["blur", "keyup"]
                            }
                          ],
                          expression:
                            "[\n                        'infopointPlace/address/address',\n                        {\n                            rules: [\n                                {\n                                  required: form.getFieldValue('hasInfopoint'),\n                                  whitespace: true,\n                                  message: 'Kérjük töltse ki!'\n                                },\n                                {\n                                  max: characterCounts['infopointPlace/address/address'].max,\n                                  message: 'Maximum ' + characterCounts['infopointPlace/address/address'].max + ' karakter engedélyezett.',\n                                }\n                            ],\n                            validateTrigger: ['blur','keyup']\n                        }\n                    ]"
                        }
                      ],
                      attrs: { maxLength: 25000 }
                    })
                  ],
                  1
                ),
                _c(
                  "a-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.form.getFieldValue("hasInfopoint"),
                        expression: "form.getFieldValue('hasInfopoint')"
                      }
                    ],
                    attrs: {
                      selfUpdate: true,
                      label:
                        "A Tourinform-pont/Infópont közterületének neve angol nyelven (pl.: Váci road, Virág street, Batthyány square)"
                    }
                  },
                  [
                    _c("a-input", {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "infopointPlace/address/addressExtra",
                            {
                              rules: [
                                {
                                  required: _vm.form.getFieldValue(
                                    "hasInfopoint"
                                  ),
                                  whitespace: true,
                                  message: "Kérjük töltse ki!"
                                },
                                {
                                  max:
                                    _vm.characterCounts[
                                      "infopointPlace/address/addressExtra"
                                    ].max,
                                  message:
                                    "Maximum " +
                                    _vm.characterCounts[
                                      "infopointPlace/address/addressExtra"
                                    ].max +
                                    " karakter engedélyezett."
                                }
                              ],
                              validateTrigger: ["blur", "keyup"]
                            }
                          ],
                          expression:
                            "[\n                        'infopointPlace/address/addressExtra',\n                        {\n                            rules: [\n                                {\n                                  required: form.getFieldValue('hasInfopoint'),\n                                  whitespace: true,\n                                  message: 'Kérjük töltse ki!'\n                                },\n                                {\n                                  max: characterCounts['infopointPlace/address/addressExtra'].max,\n                                  message: 'Maximum ' + characterCounts['infopointPlace/address/addressExtra'].max + ' karakter engedélyezett.',\n                                }\n                            ],\n                            validateTrigger: ['blur','keyup']\n                        }\n                    ]"
                        }
                      ],
                      attrs: { maxLength: 25000 }
                    })
                  ],
                  1
                ),
                _c(
                  "a-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.form.getFieldValue("hasInfopoint"),
                        expression: "form.getFieldValue('hasInfopoint')"
                      }
                    ],
                    attrs: {
                      selfUpdate: true,
                      label: "A Tourinform-pont/Infópont házszáma"
                    }
                  },
                  [
                    _c("a-input", {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "infopointPlace/address/addressNumber",
                            {
                              rules: [
                                {
                                  required: _vm.form.getFieldValue(
                                    "hasInfopoint"
                                  ),
                                  whitespace: true,
                                  message: "Kérjük töltse ki!"
                                },
                                {
                                  max:
                                    _vm.characterCounts[
                                      "infopointPlace/address/addressNumber"
                                    ].max,
                                  message:
                                    "Maximum " +
                                    _vm.characterCounts[
                                      "infopointPlace/address/addressNumber"
                                    ].max +
                                    " karakter engedélyezett."
                                }
                              ],
                              validateTrigger: ["blur", "keyup"]
                            }
                          ],
                          expression:
                            "[\n                        'infopointPlace/address/addressNumber',\n                        {\n                            rules: [\n                                {\n                                  required: form.getFieldValue('hasInfopoint'),\n                                  whitespace: true,\n                                  message: 'Kérjük töltse ki!'\n                                },\n                                {\n                                  max: characterCounts['infopointPlace/address/addressNumber'].max,\n                                  message: 'Maximum ' + characterCounts['infopointPlace/address/addressNumber'].max + ' karakter engedélyezett.',\n                                }\n                            ],\n                            validateTrigger: ['blur','keyup']\n                        }\n                    ]"
                        }
                      ],
                      attrs: { maxLength: 25000 }
                    })
                  ],
                  1
                ),
                _c(
                  "a-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.form.getFieldValue("hasInfopoint"),
                        expression: "form.getFieldValue('hasInfopoint')"
                      }
                    ],
                    attrs: { selfUpdate: true }
                  },
                  [
                    _c(
                      "span",
                      { attrs: { slot: "label" }, slot: "label" },
                      [
                        _vm._v(
                          "\n          A Tourinform-pont/Infópont GPS koordinátája: szélességi fok\n\n          "
                        ),
                        _c(
                          "a-tooltip",
                          { attrs: { title: _vm.coordinateTooltipLatitude } },
                          [
                            _c("a-icon", {
                              attrs: { type: "question-circle-o" }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("a-input", {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "infopointPlace/address/lat",
                            {
                              rules: [
                                {
                                  required: _vm.form.getFieldValue(
                                    "hasInfopoint"
                                  ),
                                  whitespace: true,
                                  pattern: /^4([-+]?)([\d]{1})(((\.)(\d+)))$/g,
                                  message:
                                    "Kérjük adjon meg helyes GPS koordinátákat!"
                                },
                                {
                                  type: "float",
                                  message:
                                    "Kérjük adjon meg helyes GPS koordinátákat!",
                                  transform: function(value) {
                                    value = parseFloat(value)

                                    return isNaN(value) ? null : value
                                  },
                                  min: 45.72,
                                  max: 48.6
                                }
                              ],
                              validateTrigger: ["blur", "keyup"]
                            }
                          ],
                          expression:
                            "[\n                        'infopointPlace/address/lat',\n                        {\n                            rules: [{\n                                required: form.getFieldValue('hasInfopoint'),\n                                whitespace: true,\n                                pattern: /^4([-+]?)([\\d]{1})(((\\.)(\\d+)))$/g,\n                                message: 'Kérjük adjon meg helyes GPS koordinátákat!'\n                            },\n                                {\n                                  type: 'float',\n                                  message: 'Kérjük adjon meg helyes GPS koordinátákat!',\n                                  transform: (value) => {\n                                    value = parseFloat(value);\n\n                                    return isNaN(value) ? null: value;\n                                  },\n                                  min: 45.72,\n                                  max: 48.60\n                                }],\n                            validateTrigger: ['blur','keyup']\n                        }\n                    ]"
                        }
                      ],
                      attrs: {
                        maxLength: 25000,
                        placeholder:
                          "Kérjük a koordinátát az alábbi formátumban adja meg: 47.507346"
                      },
                      on: {
                        change: function(event) {
                          return _vm.formatCoordinate(
                            event.target.value,
                            "infopointPlace/address/lat"
                          )
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "a-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.form.getFieldValue("hasInfopoint"),
                        expression: "form.getFieldValue('hasInfopoint')"
                      }
                    ],
                    attrs: { selfUpdate: true }
                  },
                  [
                    _c(
                      "span",
                      { attrs: { slot: "label" }, slot: "label" },
                      [
                        _vm._v(
                          "\n        A Tourinform-pont/Infópont GPS koordinátája: hosszúsági fok\n\n        "
                        ),
                        _c(
                          "a-tooltip",
                          { attrs: { title: _vm.coordinateTooltipLongitude } },
                          [
                            _c("a-icon", {
                              attrs: { type: "question-circle-o" }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("a-input", {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "infopointPlace/address/long",
                            {
                              rules: [
                                {
                                  required: _vm.form.getFieldValue(
                                    "hasInfopoint"
                                  ),
                                  whitespace: true,
                                  pattern: /^(1|2)(([-+]?)([\d]{1})((\.)(\d+))?)$/g,
                                  message:
                                    "Kérjük adjon meg helyes GPS koordinátákat!"
                                },
                                {
                                  type: "float",
                                  message:
                                    "Kérjük adjon meg helyes GPS koordinátákat!",
                                  transform: function(value) {
                                    value = parseFloat(value)

                                    return isNaN(value) ? null : value
                                  },
                                  min: 16.1,
                                  max: 23
                                }
                              ],
                              validateTrigger: ["blur", "keyup"]
                            }
                          ],
                          expression:
                            "[\n                        'infopointPlace/address/long',\n                        {\n                            rules: [{\n                                required: form.getFieldValue('hasInfopoint'),\n                                whitespace: true,\n                                pattern: /^(1|2)(([-+]?)([\\d]{1})((\\.)(\\d+))?)$/g,\n                                message: 'Kérjük adjon meg helyes GPS koordinátákat!'\n                            },\n                                {\n                                  type: 'float',\n                                  message: 'Kérjük adjon meg helyes GPS koordinátákat!',\n                                  transform: (value) => {\n                                    value = parseFloat(value);\n\n                                    return isNaN(value) ? null: value;\n                                  },\n                                  min: 16.10,\n                                  max: 23\n                                }],\n                            validateTrigger: ['blur','keyup']\n                        }\n                    ]"
                        }
                      ],
                      attrs: {
                        maxLength: 25000,
                        placeholder:
                          "Kérjük a koordinátát az alábbi formátumban adja meg: 19.046610"
                      },
                      on: {
                        change: function(event) {
                          return _vm.formatCoordinate(
                            event.target.value,
                            "infopointPlace/address/long"
                          )
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "a-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.form.getFieldValue("hasInfopoint"),
                        expression: "form.getFieldValue('hasInfopoint')"
                      }
                    ],
                    attrs: {
                      label:
                        "A Tourinform-pont/Infópont munkatársai által beszélt nyelvek (Több válasz is adható)."
                    }
                  },
                  [
                    _c(
                      "a-select",
                      {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "infopointPlace/spokenLanguages/id",
                              {
                                rules: [
                                  {
                                    required: _vm.form.getFieldValue(
                                      "hasInfopoint"
                                    ),
                                    message: "Kérjük töltse ki!"
                                  }
                                ],
                                validateTrigger: ["blur"]
                              }
                            ],
                            expression:
                              "[\n                    'infopointPlace/spokenLanguages/id',\n                    {\n                        rules: [{\n                            required: form.getFieldValue('hasInfopoint'),\n                            message: 'Kérjük töltse ki!'\n                        }],\n                        validateTrigger: ['blur']\n                    }\n                ]"
                          }
                        ],
                        attrs: {
                          getPopupContainer: _vm.getParentTriggerNode(),
                          mode: "multiple",
                          placeholder: "Kérjük válasszon!",
                          filterOption: _vm.filterSelectNames
                        }
                      },
                      _vm._l(
                        _vm.options["infopointPlace/spokenLanguages/id"],
                        function(language) {
                          return _c(
                            "a-select-option",
                            { key: language.id, attrs: { value: language.id } },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(language.name) +
                                  "\n                        "
                              )
                            ]
                          )
                        }
                      ),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.form.getFieldValue("hasInfopoint"),
                        expression: "form.getFieldValue('hasInfopoint')"
                      }
                    ],
                    attrs: {
                      label:
                        "Tourinform-pont/Infópont típusa (ügyfélfogadási időtartam szerint)"
                    }
                  },
                  [
                    _c(
                      "a-select",
                      {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "infopointPlace/seasonal",
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: "Kérjük válasszon!"
                                  }
                                ]
                              }
                            ],
                            expression:
                              "[\n                    'infopointPlace/seasonal',\n                    {\n                        rules: [{\n                            required: true,\n                            message: 'Kérjük válasszon!'\n                        }]\n                    }\n                ]"
                          }
                        ],
                        attrs: { placeholder: "Kérjük válasszon!" }
                      },
                      [
                        _c("a-select-option", { attrs: { value: 1 } }, [
                          _vm._v(
                            "\n                            Szezonálisan nyitva tartó\n                        "
                          )
                        ]),
                        _c("a-select-option", { attrs: { value: 0 } }, [
                          _vm._v(
                            "\n                            Egész évben nyitva tartó\n                        "
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.form.getFieldValue("hasInfopoint") &&
                          _vm.form.getFieldValue("infopointPlace/seasonal") ===
                            1,
                        expression:
                          "form.getFieldValue('hasInfopoint') && form.getFieldValue('infopointPlace/seasonal') === 1"
                      }
                    ],
                    attrs: { label: "Nyitás dátuma" }
                  },
                  [
                    _c("a-date-picker", {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "infopointPlace/seasonStartDate",
                            {
                              rules: [
                                {
                                  required:
                                    _vm.form.getFieldValue(
                                      "infopointPlace/seasonal"
                                    ) === 1,
                                  message: "Kérjük töltse ki!"
                                }
                              ]
                            }
                          ],
                          expression:
                            "[\n                    `infopointPlace/seasonStartDate`,\n                    {\n                        rules: [{\n                            required: form.getFieldValue('infopointPlace/seasonal') === 1,\n                            message: 'Kérjük töltse ki!',\n                        }],\n                    }\n                ]"
                        }
                      ],
                      staticStyle: { width: "85%" },
                      attrs: {
                        "disabled-date": _vm.infopointOpeningRangeStartDisabled,
                        format: _vm.dateFields["infopointPlace/seasonStartDate"]
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "a-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.form.getFieldValue("hasInfopoint") &&
                          _vm.form.getFieldValue("infopointPlace/seasonal") ===
                            1,
                        expression:
                          "form.getFieldValue('hasInfopoint') && form.getFieldValue('infopointPlace/seasonal') === 1"
                      }
                    ],
                    attrs: { label: "Zárás dátuma" }
                  },
                  [
                    _c("a-date-picker", {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "infopointPlace/seasonEndDate",
                            {
                              rules: [
                                {
                                  required:
                                    _vm.form.getFieldValue(
                                      "infopointPlace/seasonal"
                                    ) === 1,
                                  message: "Kérjük töltse ki!"
                                }
                              ]
                            }
                          ],
                          expression:
                            "[\n                    'infopointPlace/seasonEndDate',\n                    {\n                        rules: [{\n                            required: form.getFieldValue('infopointPlace/seasonal') === 1,\n                            message: 'Kérjük töltse ki!',\n                        }],\n                    }\n                ]"
                        }
                      ],
                      staticStyle: { width: "85%" },
                      attrs: {
                        "disabled-date": _vm.infopointOpeningRangeEndDisabled,
                        format: _vm.dateFields["infopointPlace/seasonEndDate"]
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "h2",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.form.getFieldValue("hasInfopoint"),
                        expression: "form.getFieldValue('hasInfopoint')"
                      }
                    ]
                  },
                  [
                    _c("span", { staticClass: "required-asterisk" }, [
                      _vm._v("*")
                    ]),
                    _vm._v(
                      "\n                    A Tourinform-pont/Infópont nyitva tartása\n                "
                    )
                  ]
                ),
                _vm._l(this.days, function(day) {
                  return [
                    day.weekdayStart
                      ? _c(
                          "h4",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.form.getFieldValue("hasInfopoint"),
                                expression: "form.getFieldValue('hasInfopoint')"
                              }
                            ]
                          },
                          [
                            _vm._v(
                              "\n                        Hétköznap\n                    "
                            )
                          ]
                        )
                      : _vm._e(),
                    day.weekendStart
                      ? _c(
                          "h4",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.form.getFieldValue("hasInfopoint"),
                                expression: "form.getFieldValue('hasInfopoint')"
                              }
                            ]
                          },
                          [
                            _vm._v(
                              "\n                        Hétvégén\n                    "
                            )
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "a-form-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.form.getFieldValue("hasInfopoint"),
                            expression: "form.getFieldValue('hasInfopoint')"
                          }
                        ],
                        key: "infopointPlace/" + day.fieldPrefix,
                        attrs: { label: day.name }
                      },
                      [
                        _c(
                          "a-row",
                          [
                            _c(
                              "a-col",
                              { attrs: { md: 8 } },
                              [
                                _c(
                                  "a-form-item",
                                  [
                                    _c("a-time-picker", {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value: [
                                            "infopointPlace/" +
                                              day.fieldPrefix +
                                              "/startTime",
                                            {
                                              initialValue: _vm.moment(
                                                "1970-01-01 00:00:00"
                                              ),
                                              rules: [
                                                {
                                                  required:
                                                    _vm.form.getFieldValue(
                                                      "hasInfopoint"
                                                    ) &&
                                                    !_vm.isChecked(
                                                      "infopointPlace/" +
                                                        day.fieldPrefix +
                                                        "/closed"
                                                    ),
                                                  message:
                                                    "Kérjük válassza ki a listából a megfelelő értéket VAGY ZÁRVA"
                                                }
                                              ]
                                            }
                                          ],
                                          expression:
                                            "[\n                                'infopointPlace/' + day.fieldPrefix + '/startTime',\n                                {\n                                initialValue: moment('1970-01-01 00:00:00'),\n                                rules: [\n                                    {\n                                      required: form.getFieldValue('hasInfopoint') && !isChecked('infopointPlace/' + day.fieldPrefix + '/closed'),\n                                      message: 'Kérjük válassza ki a listából a megfelelő értéket VAGY ZÁRVA',\n                                    }\n                                  ]\n                                }\n                                ]"
                                        }
                                      ],
                                      staticStyle: { width: "85%" },
                                      attrs: {
                                        disabled: _vm.isChecked(
                                          "infopointPlace/" +
                                            day.fieldPrefix +
                                            "/closed"
                                        ),
                                        "minute-step": 15,
                                        format:
                                          _vm.dateFields[
                                            "infopointPlace/" +
                                              day.fieldPrefix +
                                              "/startTime"
                                          ]
                                      },
                                      on: {
                                        change: function(time, timeString) {
                                          return _vm.timePickerChange(
                                            "infopointPlace/" +
                                              day.fieldPrefix +
                                              "/startTime",
                                            time,
                                            timeString
                                          )
                                        }
                                      }
                                    }),
                                    _c("span", [_vm._v("-tól")])
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { md: 8 } },
                              [
                                _c(
                                  "a-form-item",
                                  [
                                    _c("a-time-picker", {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value: [
                                            "infopointPlace/" +
                                              day.fieldPrefix +
                                              "/endTime",
                                            {
                                              initialValue: _vm.moment(
                                                "1970-01-01 00:00:00"
                                              ),
                                              rules: [
                                                {
                                                  required:
                                                    _vm.form.getFieldValue(
                                                      "hasInfopoint"
                                                    ) &&
                                                    !_vm.isChecked(
                                                      "infopointPlace/" +
                                                        day.fieldPrefix +
                                                        "/closed"
                                                    ),
                                                  message:
                                                    "Kérjük válassza ki a listából a megfelelő értéket VAGY ZÁRVA"
                                                }
                                              ]
                                            }
                                          ],
                                          expression:
                                            "[\n                    'infopointPlace/' + day.fieldPrefix + '/endTime',\n                      {\n                        initialValue: moment('1970-01-01 00:00:00'),\n                        rules: [\n                          {\n                            required: form.getFieldValue('hasInfopoint') && !isChecked('infopointPlace/' + day.fieldPrefix + '/closed'),\n                            message: 'Kérjük válassza ki a listából a megfelelő értéket VAGY ZÁRVA',\n                          }\n                        ]\n                      }\n                    ]"
                                        }
                                      ],
                                      staticStyle: { width: "85%" },
                                      attrs: {
                                        disabled: _vm.isChecked(
                                          "infopointPlace/" +
                                            day.fieldPrefix +
                                            "/closed"
                                        ),
                                        "minute-step": 15,
                                        format:
                                          _vm.dateFields[
                                            "infopointPlace/" +
                                              day.fieldPrefix +
                                              "/endTime"
                                          ]
                                      },
                                      on: {
                                        change: function(time, timeString) {
                                          return _vm.timePickerChange(
                                            "infopointPlace/" +
                                              day.fieldPrefix +
                                              "/endTime",
                                            time,
                                            timeString
                                          )
                                        }
                                      }
                                    }),
                                    _c("span", [_vm._v("-ig")])
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { md: 8 } },
                              [
                                _c(
                                  "a-form-item",
                                  [
                                    _c(
                                      "a-checkbox",
                                      {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value: [
                                              "infopointPlace/" +
                                                day.fieldPrefix +
                                                "/closed"
                                            ],
                                            expression:
                                              "['infopointPlace/' + day.fieldPrefix + '/closed']"
                                          }
                                        ],
                                        attrs: {
                                          checked: _vm.isChecked(
                                            "infopointPlace/" +
                                              day.fieldPrefix +
                                              "/closed"
                                          )
                                        },
                                        on: {
                                          change: function() {
                                            return _vm.relevancyCheckboxChange([
                                              "infopointPlace/" +
                                                day.fieldPrefix +
                                                "/startTime",
                                              "infopointPlace/" +
                                                day.fieldPrefix +
                                                "/endTime"
                                            ])
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        ZÁRVA\n                                    "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }),
                _c("a-divider"),
                _c(
                  "a-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.form.getFieldValue("hasInfopoint"),
                        expression: "form.getFieldValue('hasInfopoint')"
                      }
                    ],
                    attrs: {
                      selfUpdate: true,
                      label:
                        "Ha a nyitva tartás hónaponként eltérő kérjük itt jelezze"
                    }
                  },
                  [
                    _c("a-textarea", {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "infopointPlace/monthlyOpening",
                            {
                              rules: [
                                {
                                  required: false,
                                  message: "Kérjük töltse ki!"
                                },
                                {
                                  max:
                                    _vm.characterCounts[
                                      "infopointPlace/monthlyOpening"
                                    ].max,
                                  message:
                                    "Maximum " +
                                    _vm.characterCounts[
                                      "infopointPlace/monthlyOpening"
                                    ].max +
                                    " karakter engedélyezett."
                                }
                              ]
                            }
                          ],
                          expression:
                            "[\n                    'infopointPlace/monthlyOpening',\n                    {\n                        rules: [\n                            {\n                            required: false,\n                            message: 'Kérjük töltse ki!'\n                            },\n                            {\n                              max: characterCounts['infopointPlace/monthlyOpening'].max,\n                              message: 'Maximum ' + characterCounts['infopointPlace/monthlyOpening'].max + ' karakter engedélyezett.',\n                            }\n                        ]\n                    }\n                ]"
                        }
                      ],
                      attrs: { maxLength: 25000, autoSize: { minRows: 1 } }
                    })
                  ],
                  1
                ),
                _c(
                  "a-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.form.getFieldValue("hasInfopoint"),
                        expression: "form.getFieldValue('hasInfopoint')"
                      }
                    ],
                    attrs: {
                      selfUpdate: true,
                      label:
                        "Speciális nyitva tartás (egyéb a fenti nyitva tartási kategóriákba nem besorolható esetek; pl.: 04.17-én technikai okok miatt zárva)"
                    }
                  },
                  [
                    _c("a-textarea", {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "infopointPlace/specialOpening",
                            {
                              rules: [
                                {
                                  required: false,
                                  message: "Kérjük töltse ki!"
                                },
                                {
                                  max:
                                    _vm.characterCounts[
                                      "infopointPlace/specialOpening"
                                    ].max,
                                  message:
                                    "Maximum " +
                                    _vm.characterCounts[
                                      "infopointPlace/specialOpening"
                                    ].max +
                                    " karakter engedélyezett."
                                }
                              ]
                            }
                          ],
                          expression:
                            "[\n                    'infopointPlace/specialOpening',\n                    {\n                        rules: [\n                            {\n                            required: false,\n                            message: 'Kérjük töltse ki!'\n                            },\n                            {\n                              max: characterCounts['infopointPlace/specialOpening'].max,\n                              message: 'Maximum ' + characterCounts['infopointPlace/specialOpening'].max + ' karakter engedélyezett.',\n                            }\n                        ]\n                    }\n                ]"
                        }
                      ],
                      attrs: { maxLength: 25000, autoSize: { minRows: 1 } }
                    })
                  ],
                  1
                ),
                _c(
                  "a-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.form.getFieldValue("hasInfopoint"),
                        expression: "form.getFieldValue('hasInfopoint')"
                      }
                    ],
                    attrs: {
                      label:
                        "A Tourinform-pont/Infópont műemléki épületben található?"
                    }
                  },
                  [
                    _c(
                      "a-select",
                      {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "infopointPlace/monument",
                              {
                                rules: [
                                  {
                                    required: _vm.form.getFieldValue(
                                      "hasInfopoint"
                                    ),
                                    message: "Kérjük válasszon!"
                                  }
                                ]
                              }
                            ],
                            expression:
                              "[\n                    'infopointPlace/monument',\n                    {\n                        rules: [{\n                            required: form.getFieldValue('hasInfopoint'),\n                            message: 'Kérjük válasszon!'\n                        }]\n                    }\n                ]"
                          }
                        ],
                        attrs: { placeholder: "Kérjük válasszon!" }
                      },
                      [
                        _c("a-select-option", { attrs: { value: 1 } }, [
                          _vm._v(
                            "\n                            Igen\n                        "
                          )
                        ]),
                        _c("a-select-option", { attrs: { value: 0 } }, [
                          _vm._v(
                            "\n                            Nem\n                        "
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.form.getFieldValue("hasInfopoint"),
                        expression: "form.getFieldValue('hasInfopoint')"
                      }
                    ],
                    attrs: {
                      label:
                        "A Tourinform-pont/Infópont vendégterének alapterülete",
                      selfUpdate: true
                    }
                  },
                  [
                    _c("a-input-number", {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "infopointPlace/size",
                            {
                              validateTrigger: ["blur", "keyup"],
                              rules: [
                                {
                                  required: _vm.form.getFieldValue(
                                    "hasInfopoint"
                                  ),
                                  message: "Kérjük töltse ki!"
                                }
                              ]
                            }
                          ],
                          expression:
                            "[\n                    'infopointPlace/size',\n                    {\n                        validateTrigger: ['blur', 'keyup'],\n                        rules: [{\n                            required: form.getFieldValue('hasInfopoint'),\n                            message: 'Kérjük töltse ki!',\n                        }]\n                    }\n                ]"
                        }
                      ],
                      attrs: { max: 9999 }
                    }),
                    _vm._v("\n                    m"),
                    _c("sup", [_vm._v("2")])
                  ],
                  1
                ),
                _c(
                  "a-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.form.getFieldValue("hasInfopoint"),
                        expression: "form.getFieldValue('hasInfopoint')"
                      }
                    ],
                    attrs: {
                      label:
                        "A Tourinform-pont/Infópont utolsó felújításának éve",
                      selfUpdate: true
                    }
                  },
                  [
                    _c("a-input-number", {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "infopointPlace/lastRenovation",
                            {
                              validateTrigger: ["blur", "keyup"],
                              rules: [
                                {
                                  required: _vm.form.getFieldValue(
                                    "hasInfopoint"
                                  ),
                                  message: "Kérjük töltse ki!"
                                }
                              ]
                            }
                          ],
                          expression:
                            "[\n                    'infopointPlace/lastRenovation',\n                    {\n                        validateTrigger: ['blur', 'keyup'],\n                        rules: [{\n                            required: form.getFieldValue('hasInfopoint'),\n                            message: 'Kérjük töltse ki!',\n                        }]\n                    }\n                ]"
                        }
                      ],
                      attrs: { max: 2100, min: 1901 }
                    }),
                    _vm._v("\n                    év\n                ")
                  ],
                  1
                ),
                _c(
                  "a-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.form.getFieldValue("hasInfopoint"),
                        expression: "form.getFieldValue('hasInfopoint')"
                      }
                    ],
                    attrs: {
                      label:
                        "A Tourinform-pont/Infópont fizikai akadálymentesített (kerekesszékkel bejárható)?"
                    }
                  },
                  [
                    _c(
                      "a-select",
                      {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "infopointPlace/physicallyAccessible",
                              {
                                rules: [
                                  {
                                    required: _vm.form.getFieldValue(
                                      "hasInfopoint"
                                    ),
                                    message: "Kérjük töltse ki!"
                                  }
                                ],
                                validateTrigger: "blur"
                              }
                            ],
                            expression:
                              "[\n                    'infopointPlace/physicallyAccessible',\n                    {\n                        rules: [{\n                            required: form.getFieldValue('hasInfopoint'),\n                            message: 'Kérjük töltse ki!'\n                        }],\n                        validateTrigger: 'blur'\n                    }\n                ]"
                          }
                        ],
                        attrs: { placeholder: "Kérjük válasszon!" }
                      },
                      [
                        _c("a-select-option", { attrs: { value: 1 } }, [
                          _vm._v(
                            "\n                            Igen\n                        "
                          )
                        ]),
                        _c("a-select-option", { attrs: { value: 0 } }, [
                          _vm._v(
                            "\n                            Nem\n                        "
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.form.getFieldValue("hasInfopoint"),
                        expression: "form.getFieldValue('hasInfopoint')"
                      }
                    ],
                    attrs: {
                      label:
                        "A Tourinform-pont/Infópont hallássérültek számára akadálymentesített?"
                    }
                  },
                  [
                    _c(
                      "a-select",
                      {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "infopointPlace/deafAccessible",
                              {
                                rules: [
                                  {
                                    required: _vm.form.getFieldValue(
                                      "hasInfopoint"
                                    ),
                                    message: "Kérjük töltse ki!"
                                  }
                                ],
                                validateTrigger: "blur"
                              }
                            ],
                            expression:
                              "[\n                    'infopointPlace/deafAccessible',\n                    {\n                        rules: [{\n                            required: form.getFieldValue('hasInfopoint'),\n                            message: 'Kérjük töltse ki!'\n                        }],\n                        validateTrigger: 'blur'\n                    }\n                ]"
                          }
                        ],
                        attrs: { placeholder: "Kérjük válasszon!" }
                      },
                      [
                        _c("a-select-option", { attrs: { value: 1 } }, [
                          _vm._v(
                            "\n                            Igen\n                        "
                          )
                        ]),
                        _c("a-select-option", { attrs: { value: 0 } }, [
                          _vm._v(
                            "\n                            Nem\n                        "
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.form.getFieldValue("hasInfopoint"),
                        expression: "form.getFieldValue('hasInfopoint')"
                      }
                    ],
                    attrs: {
                      label:
                        "A Tourinform-pont/Infópont látássérültek számára akadálymentesített?"
                    }
                  },
                  [
                    _c(
                      "a-select",
                      {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "infopointPlace/blindAccessible",
                              {
                                rules: [
                                  {
                                    required: _vm.form.getFieldValue(
                                      "hasInfopoint"
                                    ),
                                    message: "Kérjük töltse ki!"
                                  }
                                ],
                                validateTrigger: "blur"
                              }
                            ],
                            expression:
                              "[\n                    'infopointPlace/blindAccessible',\n                    {\n                        rules: [{\n                            required: form.getFieldValue('hasInfopoint'),\n                            message: 'Kérjük töltse ki!'\n                        }],\n                        validateTrigger: 'blur'\n                    }\n                ]"
                          }
                        ],
                        attrs: { placeholder: "Kérjük válasszon!" }
                      },
                      [
                        _c("a-select-option", { attrs: { value: 1 } }, [
                          _vm._v(
                            "\n                            Igen\n                        "
                          )
                        ]),
                        _c("a-select-option", { attrs: { value: 0 } }, [
                          _vm._v(
                            "\n                            Nem\n                        "
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.form.getFieldValue("hasInfopoint"),
                        expression: "form.getFieldValue('hasInfopoint')"
                      }
                    ],
                    attrs: {
                      label:
                        "A Tourinform-pont/Infópont környezete akadálymentesített-e? (bejárattól 100 méteres körben)?"
                    }
                  },
                  [
                    _c(
                      "a-select",
                      {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "infopointPlace/areaAccessible",
                              {
                                rules: [
                                  {
                                    required: _vm.form.getFieldValue(
                                      "hasInfopoint"
                                    ),
                                    message: "Kérjük töltse ki!"
                                  }
                                ],
                                validateTrigger: "blur"
                              }
                            ],
                            expression:
                              "[\n                    'infopointPlace/areaAccessible',\n                    {\n                        rules: [{\n                            required: form.getFieldValue('hasInfopoint'),\n                            message: 'Kérjük töltse ki!'\n                        }],\n                        validateTrigger: 'blur'\n                    }\n                ]"
                          }
                        ],
                        attrs: { placeholder: "Kérjük válasszon!" }
                      },
                      [
                        _c("a-select-option", { attrs: { value: 1 } }, [
                          _vm._v(
                            "\n                            Igen\n                        "
                          )
                        ]),
                        _c("a-select-option", { attrs: { value: 0 } }, [
                          _vm._v(
                            "\n                            Nem\n                        "
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.form.getFieldValue("hasInfopoint"),
                        expression: "form.getFieldValue('hasInfopoint')"
                      }
                    ],
                    attrs: {
                      label:
                        "A Tourinform-pont/Infópont szolgáltatásai (Több válasz is adható)."
                    }
                  },
                  [
                    _c(
                      "a-select",
                      {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "infopointPlace/services/id",
                              {
                                rules: [
                                  {
                                    required: _vm.form.getFieldValue(
                                      "hasInfopoint"
                                    ),
                                    message: "Kérjük töltse ki!"
                                  }
                                ],
                                validateTrigger: "blur"
                              }
                            ],
                            expression:
                              "['infopointPlace/services/id', {\n                        rules: [{\n                            required: form.getFieldValue('hasInfopoint'),\n                            message: 'Kérjük töltse ki!'\n                        }],\n                        validateTrigger: 'blur'\n                    }]"
                          }
                        ],
                        attrs: {
                          getPopupContainer: _vm.getParentTriggerNode(),
                          mode: "multiple",
                          loading: _vm.loading["infopointPlace/services/id"],
                          placeholder: "Kérjük válasszon!",
                          filterOption: _vm.filterSelectNames
                        },
                        on: {
                          dropdownVisibleChange: function(open) {
                            return (
                              open &&
                              _vm.loadOptions("infopointPlace/services/id")
                            )
                          }
                        }
                      },
                      _vm._l(
                        _vm.options["infopointPlace/services/id"],
                        function(service) {
                          return _c(
                            "a-select-option",
                            { key: service.id, attrs: { value: service.id } },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(service.name) +
                                  "\n                        "
                              )
                            ]
                          )
                        }
                      ),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.form.getFieldValue("hasInfopoint"),
                        expression: "form.getFieldValue('hasInfopoint')"
                      }
                    ],
                    attrs: {
                      label:
                        "A Tourinform-pontban/Infópontban milyen bankkártya típusokat fogadnak el?"
                    }
                  },
                  [
                    _c(
                      "a-select",
                      {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "infopointPlace/creditCards/id",
                              {
                                rules: [
                                  {
                                    required: false,
                                    message: "Kérjük töltse ki!"
                                  }
                                ],
                                validateTrigger: "blur"
                              }
                            ],
                            expression:
                              "['infopointPlace/creditCards/id', {\n                        rules: [{\n                            required: false,\n                            message: 'Kérjük töltse ki!'\n                        }],\n                        validateTrigger: 'blur'\n                    }]"
                          }
                        ],
                        attrs: {
                          disabled: !_vm.isChecked(
                            "infopointPlace/creditCardsAccepted"
                          ),
                          getPopupContainer: _vm.getParentTriggerNode(),
                          mode: "multiple",
                          loading: _vm.loading["infopointPlace/creditCards/id"],
                          placeholder: "Kérjük válasszon!",
                          filterOption: _vm.filterSelectNames
                        },
                        on: {
                          dropdownVisibleChange: function(open) {
                            return (
                              open &&
                              _vm.loadOptions("infopointPlace/creditCards/id")
                            )
                          }
                        }
                      },
                      _vm._l(
                        _vm.options["infopointPlace/creditCards/id"],
                        function(category) {
                          return _c(
                            "a-select-option",
                            { key: category.id, attrs: { value: category.id } },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(category.label) +
                                  "\n                        "
                              )
                            ]
                          )
                        }
                      ),
                      1
                    ),
                    _c(
                      "a-checkbox",
                      {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "infopointPlace/creditCardsAccepted",
                              {
                                rules: [
                                  {
                                    required: false
                                  }
                                ]
                              }
                            ],
                            expression:
                              "[\n                    'infopointPlace/creditCardsAccepted',\n                    {\n                        rules: [{\n                            required: false\n                        }]\n                    }\n                ]"
                          }
                        ],
                        attrs: {
                          checked: _vm.isChecked(
                            "infopointPlace/creditCardsAccepted"
                          )
                        },
                        on: {
                          change: function() {
                            return _vm.relevancyCheckboxChange(
                              "infopointPlace/creditCardsAccepted"
                            )
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                        A Tourinform-pontban/Infópontban elfogadnak bankkártyát\n                    "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "a-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.form.getFieldValue("hasInfopoint"),
                        expression: "form.getFieldValue('hasInfopoint')"
                      }
                    ],
                    attrs: {
                      label:
                        "A Tourinform-pontban/Infópontban milyen utalványokat, vouchereket fogadnak el?"
                    }
                  },
                  [
                    _c(
                      "a-select",
                      {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "infopointPlace/vouchers/id",
                              {
                                rules: [
                                  {
                                    required: false,
                                    message: "Kérjük töltse ki!"
                                  }
                                ],
                                validateTrigger: "blur"
                              }
                            ],
                            expression:
                              "['infopointPlace/vouchers/id', {\n                        rules: [{\n                            required: false,\n                            message: 'Kérjük töltse ki!'\n                        }],\n                        validateTrigger: 'blur'\n                    }]"
                          }
                        ],
                        attrs: {
                          disabled: !_vm.isChecked(
                            "infopointPlace/vouchersAccepted"
                          ),
                          getPopupContainer: _vm.getParentTriggerNode(),
                          mode: "multiple",
                          loading: _vm.loading["infopointPlace/vouchers/id"],
                          placeholder: "Kérjük válasszon!",
                          filterOption: _vm.filterSelectNames
                        },
                        on: {
                          dropdownVisibleChange: function(open) {
                            return (
                              open &&
                              _vm.loadOptions("infopointPlace/vouchers/id")
                            )
                          }
                        }
                      },
                      _vm._l(
                        _vm.options["infopointPlace/vouchers/id"],
                        function(category) {
                          return _c(
                            "a-select-option",
                            { key: category.id, attrs: { value: category.id } },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(category.label) +
                                  "\n                        "
                              )
                            ]
                          )
                        }
                      ),
                      1
                    ),
                    _c(
                      "a-checkbox",
                      {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "infopointPlace/vouchersAccepted",
                              {
                                rules: [
                                  {
                                    required: false
                                  }
                                ]
                              }
                            ],
                            expression:
                              "[\n                    'infopointPlace/vouchersAccepted',\n                    {\n                        rules: [{\n                            required: false\n                        }]\n                    }\n                ]"
                          }
                        ],
                        attrs: {
                          checked: _vm.isChecked(
                            "infopointPlace/vouchersAccepted"
                          )
                        },
                        on: {
                          change: function() {
                            return _vm.relevancyCheckboxChange(
                              "infopointPlace/vouchersAccepted"
                            )
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                        A Tourinform-pontban/Infópontban elfogadnak utalványokat, vouchereket\n                    "
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              _c(
                "a-form-item",
                [
                  _c(
                    "a-row",
                    [
                      _c(
                        "a-col",
                        { attrs: { xs: 24, md: 12 } },
                        [
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: "Irodavezető neve",
                                selfUpdate: true
                              }
                            },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "leader/name",
                                      {
                                        validateTrigger: ["keyup", "blur"],
                                        rules: [
                                          {
                                            required: true,
                                            whitespace: false,
                                            message:
                                              "Kérjük töltse ki a nevet is!"
                                          }
                                        ]
                                      }
                                    ],
                                    expression:
                                      "[\n                              'leader/name',\n                              {\n                                  validateTrigger: ['keyup', 'blur'],\n                                  rules: [{\n                                      required: true,\n                                      whitespace: false,\n                                      message: 'Kérjük töltse ki a nevet is!'\n                                  }]\n                              }\n                          ]"
                                  }
                                ],
                                attrs: { maxLength: 25000 }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { xs: 24, md: 12 } },
                        [
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: "Munkakezdés éve a Tourinform irodában",
                                selfUpdate: true
                              }
                            },
                            [
                              _c("a-input-number", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "leader/startYear",
                                      {
                                        validateTrigger: ["keyup", "blur"],
                                        rules: [
                                          {
                                            required: true,
                                            transform: function(value) {
                                              value = parseInt(value)

                                              return isNaN(value) ? null : value
                                            },
                                            type: "integer",
                                            whitespace: true,
                                            message:
                                              "Kérjük töltse ki az évszámot is!"
                                          }
                                        ]
                                      }
                                    ],
                                    expression:
                                      "[\n                                'leader/startYear',\n                                {\n                                    validateTrigger: ['keyup','blur'],\n                                    rules: [{\n                                        required: true,\n                                        transform: (value) => {\n                                          value = parseInt(value);\n\n                                          return isNaN(value) ? null : value;\n                                        },\n                                        type: 'integer',\n                                        whitespace: true,\n                                        message: 'Kérjük töltse ki az évszámot is!'\n                                    }]\n                                }\n                              ]"
                                  }
                                ],
                                attrs: { max: 9999, min: 0 }
                              }),
                              _vm._v(
                                "\n                            év\n                        "
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._l(_vm.employees, function(employee, index) {
                    return _c(
                      "div",
                      { key: "employee-" + index },
                      [
                        _c(
                          "a-row",
                          [
                            _c(
                              "a-col",
                              { attrs: { xs: 24, md: 12 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      selfUpdate: true,
                                      label: "Munkatárs neve"
                                    }
                                  },
                                  [
                                    _c("a-input", {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value: [
                                            "employees/" + index + "/name",
                                            {
                                              validateTrigger: [
                                                "keyup",
                                                "blur"
                                              ],
                                              rules: [
                                                {
                                                  required: true,
                                                  whitespace: false,
                                                  message: "Kérjük töltse ki!"
                                                }
                                              ]
                                            }
                                          ],
                                          expression:
                                            "[\n                              'employees/' + index + '/name',\n                              {\n                                  validateTrigger: ['keyup', 'blur'],\n                                  rules: [{\n                                      required: true,\n                                      whitespace: false,\n                                      message: 'Kérjük töltse ki!'\n                                  }]\n                              }\n                          ]"
                                        }
                                      ],
                                      attrs: { maxLength: 25000 },
                                      on: {
                                        change: function(event) {
                                          return _vm.updateEmployeeName(
                                            index,
                                            event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { xs: 24, md: 12 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      selfUpdate: true,
                                      label:
                                        "Munkakezdés éve a Tourinform irodában"
                                    }
                                  },
                                  [
                                    _c("a-input-number", {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value: [
                                            "employees/" + index + "/startYear",
                                            {
                                              validateTrigger: [
                                                "keyup",
                                                "blur"
                                              ],
                                              rules: [
                                                {
                                                  required: true,
                                                  transform: function(value) {
                                                    value = parseInt(value)

                                                    return isNaN(value)
                                                      ? null
                                                      : value
                                                  },
                                                  type: "integer",
                                                  whitespace: true,
                                                  message:
                                                    "Kérjük töltse ki az évszámot is!"
                                                }
                                              ]
                                            }
                                          ],
                                          expression:
                                            "[\n                                'employees/' + index + '/startYear',\n                                {\n                                    validateTrigger: ['keyup','blur'],\n                                    rules: [{\n                                        required: true,\n                                        transform: (value) => {\n                                          value = parseInt(value);\n\n                                          return isNaN(value) ? null : value;\n                                        },\n                                        type: 'integer',\n                                        whitespace: true,\n                                        message: 'Kérjük töltse ki az évszámot is!'\n                                    }]\n                                }\n                              ]"
                                        }
                                      ],
                                      attrs: { max: 9999, min: 0 },
                                      on: {
                                        change: function(value) {
                                          return _vm.updateEmployeeStartYear(
                                            index,
                                            value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(
                                      "\n                                év\n\n                                "
                                    ),
                                    _vm.employees.length > 0
                                      ? _c("a-icon", {
                                          staticClass: "dynamic-delete-button",
                                          attrs: {
                                            type: "delete",
                                            theme: "filled",
                                            disabled: _vm.employees.length === 0
                                          },
                                          on: {
                                            click: function() {
                                              return _vm.removeEmployee(index)
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  _c(
                    "a-form-item",
                    [
                      _c(
                        "a-button",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { type: "dashed" },
                          on: { click: _vm.addEmployee }
                        },
                        [
                          _c("a-icon", { attrs: { type: "plus" } }),
                          _vm._v(
                            "\n\n                        Munkatárs hozzáadása\n                    "
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                2
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label:
                      "Fenntartó szervezet/cég hivatalos teljes neve (pl.: Cégneve Nonprofit Kft.)",
                    selfUpdate: true
                  }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "organizationName",
                          {
                            rules: [
                              {
                                required: true,
                                preserve: true,
                                message: "Kérjük töltse ki!"
                              },
                              {
                                max:
                                  _vm.characterCounts["organizationName"].max,
                                message:
                                  "Maximum " +
                                  _vm.characterCounts["organizationName"].max +
                                  " karakter engedélyezett."
                              }
                            ],
                            validateTrigger: ["blur", "keyup"]
                          }
                        ],
                        expression:
                          "['organizationName',\n                        {\n                            rules: [\n                                {\n                                  required: true,\n                                  preserve: true,\n                                  message: 'Kérjük töltse ki!'\n                                },\n                                {\n                                  max: characterCounts['organizationName'].max,\n                                  message: 'Maximum ' + characterCounts['organizationName'].max + ' karakter engedélyezett.',\n                                }\n                            ],\n                            validateTrigger: ['blur', 'keyup']\n                        }\n                    ]"
                      }
                    ],
                    attrs: { maxLength: 25000, autoSize: { minRows: 1 } }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label:
                      "Fenntartó szervezet/cég székhelyének pontos címe (pl.: 1234 Település, Minta utca 56.sz. 7.a.)",
                    selfUpdate: true
                  }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "organizationAddress",
                          {
                            rules: [
                              {
                                required: true,
                                preserve: true,
                                message: "Kérjük töltse ki!"
                              },
                              {
                                max:
                                  _vm.characterCounts["organizationAddress"]
                                    .max,
                                message:
                                  "Maximum " +
                                  _vm.characterCounts["organizationAddress"]
                                    .max +
                                  " karakter engedélyezett."
                              }
                            ],
                            validateTrigger: ["blur", "keyup"]
                          }
                        ],
                        expression:
                          "['organizationAddress',\n                        {\n                            rules: [\n                                {\n                                  required: true,\n                                  preserve: true,\n                                  message: 'Kérjük töltse ki!'\n                                },\n                                {\n                                  max: characterCounts['organizationAddress'].max,\n                                  message: 'Maximum ' + characterCounts['organizationAddress'].max + ' karakter engedélyezett.',\n                                }\n                            ],\n                            validateTrigger: ['blur', 'keyup']\n                        }\n                    ]"
                      }
                    ],
                    attrs: { maxLength: 25000, autoSize: { minRows: 1 } }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label:
                      "Fenntartó szervezet/cég adószáma (pl.: 12345678-1-11)",
                    selfUpdate: true
                  }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "organizationTaxId",
                          {
                            rules: [
                              {
                                required: true,
                                whitespace: true,
                                pattern: /^[0-9]{1,8}-[0-9]{1}-[0-9]{2}$/g,
                                message:
                                  "Kérjük, a megfelelő formátumban töltse ki!",
                                max: 255
                              }
                            ],
                            validateTrigger: ["blur", "keyup"]
                          }
                        ],
                        expression:
                          "['organizationTaxId',\n                        {\n                            rules: [{\n                                required: true,\n                                whitespace: true,\n                                pattern: /^[0-9]{1,8}-[0-9]{1}-[0-9]{2}$/g,\n                                message: 'Kérjük, a megfelelő formátumban töltse ki!',\n                                max: 255\n                            }],\n                            validateTrigger: ['blur', 'keyup']\n                        }\n                    ]"
                      }
                    ],
                    attrs: { maxLength: 25000, autoSize: { minRows: 1 } }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { selfUpdate: true } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v(
                        "\n        Fenntartó szervezet/cég cégjegyzékszáma/nyilvántartási száma/törzskönyvi azonosítója  \n          "
                      ),
                      _c(
                        "a-tooltip",
                        [
                          _c(
                            "div",
                            { attrs: { slot: "title" }, slot: "title" },
                            [
                              _c("div", [_vm._v("Formátum példák:")]),
                              _c("div", [
                                _vm._v("Cégjegyzékszám: 01-09-562739")
                              ]),
                              _c("div", [
                                _vm._v("Nyilvántartási szám: 00003-0012")
                              ]),
                              _c("div", [
                                _vm._v("Törzskönyvi azonosító: 307802")
                              ])
                            ]
                          ),
                          _c("a-icon", { attrs: { type: "question-circle-o" } })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "organizationCompanyId",
                          {
                            rules: [
                              {
                                required: true,
                                whitespace: true,
                                pattern: /[0-9-]/g,
                                message:
                                  "Kérjük, a megfelelő formátumban töltse ki!"
                              },
                              {
                                max:
                                  _vm.characterCounts["organizationCompanyId"]
                                    .max,
                                message:
                                  "Maximum " +
                                  _vm.characterCounts["organizationCompanyId"]
                                    .max +
                                  " karakter engedélyezett."
                              }
                            ],
                            validateTrigger: ["blur", "keyup"]
                          }
                        ],
                        expression:
                          "['organizationCompanyId',\n                        {\n                            rules: [\n                                {\n                                  required: true,\n                                  whitespace: true,\n                                  pattern: /[0-9-]/g,\n                                  message: 'Kérjük, a megfelelő formátumban töltse ki!',\n                                },\n                                {\n                                  max: characterCounts['organizationCompanyId'].max,\n                                  message: 'Maximum ' + characterCounts['organizationCompanyId'].max + ' karakter engedélyezett.',\n                                }\n\n                            ],\n                            validateTrigger: ['blur', 'keyup']\n                        }\n                    ]"
                      }
                    ],
                    attrs: { maxLength: 25000, autoSize: { minRows: 1 } }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label:
                      "Fenntartó szervezet/cég pontos postázási címe (pl.: 1234 Település, Pf.: 123)",
                    selfUpdate: true
                  }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "organizationMailingAddress",
                          {
                            rules: [
                              {
                                required: true,
                                preserve: true,
                                message: "Kérjük töltse ki!"
                              },
                              {
                                max:
                                  _vm.characterCounts[
                                    "organizationMailingAddress"
                                  ].max,
                                message:
                                  "Maximum " +
                                  _vm.characterCounts[
                                    "organizationMailingAddress"
                                  ].max +
                                  " karakter engedélyezett."
                              }
                            ],
                            validateTrigger: ["blur", "keyup"]
                          }
                        ],
                        expression:
                          "['organizationMailingAddress',\n                        {\n                            rules: [\n                                {\n                                  required: true,\n                                  preserve: true,\n                                  message: 'Kérjük töltse ki!'\n                                },\n                                {\n                                  max: characterCounts['organizationMailingAddress'].max,\n                                  message: 'Maximum ' + characterCounts['organizationMailingAddress'].max + ' karakter engedélyezett.',\n                                }\n                            ],\n                            validateTrigger: ['blur', 'keyup']\n                        }\n                    ]"
                      }
                    ],
                    attrs: { maxLength: 25000, autoSize: { minRows: 1 } }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label:
                      "Fenntartó szervezet/cég képviselőjének titulusa (pl.: elnök, ügyvezető igazgató, polgármester)",
                    selfUpdate: true
                  }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "organizationLeaderTitle",
                          {
                            rules: [
                              {
                                required: true,
                                preserve: true,
                                message: "Kérjük töltse ki!"
                              },
                              {
                                max:
                                  _vm.characterCounts["organizationLeaderTitle"]
                                    .max,
                                message:
                                  "Maximum " +
                                  _vm.characterCounts["organizationLeaderTitle"]
                                    .max +
                                  " karakter engedélyezett."
                              }
                            ],
                            validateTrigger: ["blur", "keyup"]
                          }
                        ],
                        expression:
                          "['organizationLeaderTitle',\n                        {\n                            rules: [\n                                {\n                                  required: true,\n                                  preserve: true,\n                                  message: 'Kérjük töltse ki!'\n                                },\n                                {\n                                  max: characterCounts['organizationLeaderTitle'].max,\n                                  message: 'Maximum ' + characterCounts['organizationLeaderTitle'].max + ' karakter engedélyezett.',\n                                }\n                            ],\n                            validateTrigger: ['blur', 'keyup']\n                        }\n                    ]"
                      }
                    ],
                    attrs: { maxLength: 25000, autoSize: { minRows: 1 } }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: "Fenntartó szervezet/cég képviselőjének neve",
                    selfUpdate: true
                  }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "organizationLeaderName",
                          {
                            rules: [
                              {
                                required: true,
                                preserve: true,
                                message: "Kérjük töltse ki!"
                              },
                              {
                                max:
                                  _vm.characterCounts["organizationLeaderName"]
                                    .max,
                                message:
                                  "Maximum " +
                                  _vm.characterCounts["organizationLeaderName"]
                                    .max +
                                  " karakter engedélyezett."
                              }
                            ],
                            validateTrigger: ["blur", "keyup"]
                          }
                        ],
                        expression:
                          "['organizationLeaderName',\n                        {\n                            rules: [\n                                {\n                                  required: true,\n                                  preserve: true,\n                                  message: 'Kérjük töltse ki!'\n                                },\n                                {\n                                  max: characterCounts['organizationLeaderName'].max,\n                                  message: 'Maximum ' + characterCounts['organizationLeaderName'].max + ' karakter engedélyezett.',\n                                }\n                            ],\n                            validateTrigger: ['blur', 'keyup']\n                        }\n                    ]"
                      }
                    ],
                    attrs: { maxLength: 25000, autoSize: { minRows: 1 } }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label:
                      "Fenntartó szervezet/cég képviselőjének telefonszáma (pl.: +36 1 234 5678)",
                    selfUpdate: true
                  }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "organizationLeaderPhone",
                          {
                            rules: [
                              {
                                required: true,
                                pattern: /^[\/+][36]{1,2}[ ][0-9]{1,2}[ ][0-9]{3}[ ][0-9]{3,4}$/,
                                message: "Hibás telefonszám formátum!"
                              }
                            ],
                            validateTrigger: ["blur"]
                          }
                        ],
                        expression:
                          "[\n                            'organizationLeaderPhone',\n                            {\n                                rules: [{\n                                    required: true,\n                                    pattern: /^[\\/+][36]{1,2}[ ][0-9]{1,2}[ ][0-9]{3}[ ][0-9]{3,4}$/,\n                                    message: 'Hibás telefonszám formátum!'\n                                }],\n                               validateTrigger: ['blur']\n                            }\n                        ]"
                      }
                    ],
                    ref: "organizationLeaderPhone",
                    style: { width: "160px" },
                    attrs: { maxLength: 25000, placeholder: "+36 1 234 5678" },
                    on: {
                      change: function(event) {
                        return _vm.formatPhoneNumber(
                          event.target.value,
                          "organizationLeaderPhone"
                        )
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { selfUpdate: true } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v(
                        "\n          Fenntartó szervezet/cég képviselőjének e-mail címe\n\n          "
                      ),
                      _c(
                        "a-tooltip",
                        {
                          attrs: {
                            title:
                              "Kérjük, az e-mail címet iroda@domain.xy formátumban töltse ki!"
                          }
                        },
                        [
                          _c("a-icon", { attrs: { type: "question-circle-o" } })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "organizationLeaderEmail",
                          {
                            rules: [
                              {
                                required: true,
                                whitespace: true,
                                type: "email",
                                message:
                                  "Kérjük a megfelelő formátumban töltse ki!"
                              },
                              {
                                max:
                                  _vm.characterCounts["organizationLeaderEmail"]
                                    .max,
                                message:
                                  "Maximum " +
                                  _vm.characterCounts["organizationLeaderEmail"]
                                    .max +
                                  " karakter engedélyezett."
                              }
                            ],
                            validateTrigger: ["blur", "keyup"]
                          }
                        ],
                        expression:
                          "[\n                        'organizationLeaderEmail',\n                        {\n                            rules: [\n                                {\n                                  required: true,\n                                  whitespace: true,\n                                  type: 'email',\n                                  message: 'Kérjük a megfelelő formátumban töltse ki!'\n                                },\n                                {\n                                  max: characterCounts['organizationLeaderEmail'].max,\n                                  message: 'Maximum ' + characterCounts['organizationLeaderEmail'].max + ' karakter engedélyezett.',\n                                }\n                            ],\n                            validateTrigger: ['blur','keyup']\n                        }\n                    ]"
                      }
                    ],
                    attrs: { type: "email", maxLength: 25000 }
                  })
                ],
                1
              ),
              _c("a-form-item", [
                _c(
                  "div",
                  { staticClass: "gallery-form-item" },
                  [
                    _c("a-input", {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "mainPicture/id",
                            {
                              rules: [
                                {
                                  required: false,
                                  message: "Kérjük Válassza ki a főképet."
                                }
                              ]
                            }
                          ],
                          expression:
                            "[\n                        'mainPicture/id',\n                        {\n                            rules: [{\n                                required: false,\n                                message: 'Kérjük Válassza ki a főképet.'\n                            }]\n                        }\n                    ]"
                        }
                      ],
                      attrs: { hidden: true }
                    }),
                    _c("strong", [_vm._v("Kiválasztott főkép:")]),
                    _vm.form.getFieldValue("mainPicture/id")
                      ? _c("div", { staticClass: "selected-picture-holder" }, [
                          _c(
                            "div",
                            { staticClass: "picture-selected" },
                            [
                              _c("img", {
                                staticClass: "selected-thumbnail",
                                attrs: {
                                  alt: "Kiválasztott főkép",
                                  src: _vm.getImageUrl(
                                    _vm.form.getFieldValue("mainPicture/id")
                                  )
                                }
                              }),
                              _c(
                                "a-tooltip",
                                { attrs: { title: "Törlés" } },
                                [
                                  _c(
                                    "a-button",
                                    { on: { click: _vm.unSetMainPicture } },
                                    [
                                      _c("a-icon", {
                                        attrs: { type: "delete" }
                                      }),
                                      _vm._v(
                                        "\n\n                                    Főkép kiválasztásának törlése\n                                "
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      : _c("div", [
                          _c("span", [_vm._v("Nincs kép kiválasztva")])
                        ])
                  ],
                  1
                )
              ]),
              _c(
                "a-form-item",
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        "html-type": "submit",
                        loading: _vm.submitLoading
                      }
                    },
                    [_vm._v("\n                    Mentés\n                ")]
                  ),
                  _vm.submitResult.show
                    ? _c("a-alert", {
                        style: { marginTop: "10px" },
                        attrs: {
                          message: _vm.submitResult.message,
                          type: _vm.submitResult.type,
                          showIcon: "",
                          closable: "",
                          afterClose: function() {
                            return (_vm.submitResult.show = false)
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm.office && _vm.office.gallery
                ? _c(
                    "a-form-item",
                    { staticClass: "gallery-upload" },
                    [
                      _c("h2", [
                        _vm._v(
                          "\n                    Galéria\n                "
                        )
                      ]),
                      _c("p", [
                        _vm._v(
                          "\n                    A galériában a változtatások automatikusan mentésre kerülnek az űrlaptól függetlenül.\n                "
                        )
                      ]),
                      _c("OfficeGalleryComponent", {
                        attrs: {
                          officeId: _vm.getOfficeId(),
                          selectedImageId: _vm.form.getFieldValue(
                            "mainPicture/id"
                          ),
                          images: _vm.office.gallery.images
                        },
                        on: {
                          pictureSelected: _vm.setMainPicture,
                          pictureDeleted: _vm.pictureDeleted,
                          pictureUploaded: _vm.pictureUploaded,
                          pictureMoved: _vm.pictureUploaded
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "teka-container" }, [
                _c("h2", [
                  _vm._v("\n                    TÉKA\n                ")
                ]),
                _c("iframe", { attrs: { src: _vm.tekaUrl } })
              ])
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }