//root module 
export const SET_BACKEND_VERSION = 'SET_BACKEND_VERSION';
export const SET_PRICES_VISIBLE = 'SET_PRICES_VISIBLE';
export const SET_LAST_GROUP_TYPE = 'SET_LAST_GROUP_TYPE';
export const SET_USER_COLUMNS = 'SET_USER_COLUMNS';
export const SET_LAST_LIST_QUERY_PARAMS = 'SET_LAST_LIST_QUERY_PARAMS';
export const CLEAR_LAST_LIST_QUERY_PARAMS = 'CLEAR_LAST_LIST_QUERY_PARAMS';
export const SET_CURRENT_ATTRACTION = 'SET_CURRENT_ATTRACTION';
export const SET_CURRENT_ATTRACTION_TRANSLATIONS = 'SET_CURRENT_ATTRACTION_TRANSLATIONS';
export const SET_LANGUAGES = 'SET_LANGUAGES';
export const SET_CURRENT_HUNGARIKUM = 'SET_CURRENT_HUNGARIKUM';

// router_state
export const SET_NAVIGATION_PROGRESS_STATE = 'SET_NAVIGATION_PROGRESS_STATE';

// user
export const SET_USER = 'SET_USER';
export const SET_OFFICE = 'SET_OFFICE';
export const SET_LOGGEDIN_STATUS = 'SET_LOGGEDIN_STATUS';
export const SET_SELECTED_ROLE = 'SET_SELECTED_ROLE';

// forms
export const SET_LOADING = 'SET_LOADING';
export const SAVE_DRAFT = 'SAVE_DRAFT';
export const DELETE_DRAFT = 'DELETE_DRAFT';