<template>
  <a-row class="welcome-content-row" justify="center">
    <a-col :xs="1" :sm="4" :md="7" />
    <a-col :xs="22" :sm="16" :md="10">
      <div class="welcome-card-container">
        <h1>
          Neta
        </h1>

        <p>
          Köszöntjük a Nemzeti Turisztikai Adatbázis online felületén.<br>
          Kérjük, hogy belépéshez saját felhasználónevét és jelszavát használja,
          azt másoknak semmilyen esetben se adja ki!<br>

          <span class="info-span">
            Információ és technikai segítség:
            <br>

            <a href="mailto:neta@mtu.gov.hu">neta@mtu.gov.hu</a>
          </span>
        </p>

        <div class="welcome-button-holder">
          <router-link to="login">
            <a-button type="primary">
              Belépés
            </a-button>
          </router-link>
        </div>
      </div>
    </a-col>

    <a-col :xs="1" :sm="4" :md="7" />
  </a-row>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.welcome-content-row {
  margin-top: 10vh;
}

.welcome-card-container {
  background-color: white;
  max-width: 600px;
  text-align: center;
  margin: auto;
  padding-top: 32px;
  padding-bottom: 38px;
  padding-right: 30px;
  padding-left: 30px;

  span.info-span {
    display: inline-block;
    margin-top: 10px;
  }

  h1 {
    font-size: 48px;
    font-weight: bold;
    color: $primary-blue;
    text-transform: uppercase
  }

  p {
    color: $primary-blue;
    font-size: 16px;
    line-height: 152.38%;
    margin-bottom: 25px;
  }

  .welcome-button-holder {
    max-width: 240px;
    margin: auto;

    button {
      width: 100%;
      height: 42px;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
  }

}
</style>
