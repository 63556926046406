<template>
  <div class="header-text">
    <router-link :to="loggedIn ? { name: 'neta-list' } : '/'" :disabled="linkDisabled">
      <span>
          Nemzeti Turisztikai Adatbázis
      </span>
    </router-link>
  </div>
</template>

<script>
export default {
  data() {
    return {
      linkDisabled: false
    }
  },

  mounted() {
    this.checkRoute(this.$router.currentRoute.name);
  },

  computed: {
    loggedIn() {
      return this.$store.getters.isLoggedIn
    },
  },

  methods: {
    checkRoute(routeName) {
      this.linkDisabled = !!routeName.match(/^neta-list.*/);
    },
  },

  watch: {
    '$route'(to, from) {
      this.checkRoute(to.name);
    }
  }
}
</script>

<style lang="scss" scoped>
.header-text {
  display: inline-block;

  span {
    user-select: none;
    max-width: 315px;
    align-self: center;
    display: inline-block;
    margin-top: 16px;
    border-left: 2px solid $secondary-magenta;
    padding-left: 13px;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 20px;
    font-weight: bold;
    text-transform: uppercase;
    color: white;

    @include md {
      margin-top: 20px;
      font-size: 14px;
      line-height: 14px;
    }

    @include sm {
      margin-top: 21px;
      font-size: 14px;
      line-height: 14px;
    }

    @include xs {
      margin-top: 16px;
      font-size: 12px;
      line-height: 12px;
    }
  }
}
</style>
