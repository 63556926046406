<template>
  <div class="neta-lists">
<!--    <div class="list-menu">-->
<!--      <a-menu v-model="currentList" mode="horizontal" :style="{background: 'transparent'}" align="center">-->
<!--        <a-menu-item key="neta-new-attraction">-->
<!--          <a-icon type="book" />-->
<!--          Új vonzerő-->
<!--        </a-menu-item>-->

<!--        <a-menu-item key="neta-new-event">-->
<!--          <a-icon type="calendar" />-->
<!--          Új esemény-->
<!--        </a-menu-item>-->
<!--      </a-menu>-->
<!--    </div>-->

    <div class="list-content">
      <keep-alive>
        <router-view />
      </keep-alive>
    </div>

    <a-button
        v-show="scrolled"
        @click="scrollTop"
        class="scroll-to-top"
        size="large"
        icon="up"
        type="primary"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      scrolled: false
    }
  },

  mounted() {
    window.addEventListener('scroll', this.onScroll);
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  },

  computed: {
    currentList: {
      get() {
        return [this.$route.name]
      },

      set(routeName) {
        this.$router.push({
          name: routeName[0]
        });
      }
    }
  },

  methods: {
    scrollTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },

    onScroll(e) {
      this.scrolled = window.scrollY > 0;
    }
  }
}
</script>

<style lang="scss" scoped>
.scroll-to-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
}

.list-menu {
  margin-bottom: 10px;
}
</style>