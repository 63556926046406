<template xmlns="">
  <div class="app-form-container">
    <a-spin :spinning="formDataLoading">
      <a-form :form="form" v-on:submit="submit" layout="vertical">
        <a-form-item>
          <a-button type="primary" html-type="submit" :loading="submitLoading" v-if="showSaveButton">
            Mentés
          </a-button>

          <a-button type="secondary" html-type="button" v-on:click="resetForm" v-if="hasChanged()">
            <a-icon type="undo"
                    theme="outlined" />

            Piszkozat törlése
          </a-button>

          <span v-if="currentTab" class="last-modification">
            Utolsó mentés: <strong>{{ moment(currentTab.modificationTime).format(timeStampFormat).toString() }}</strong> &nbsp;<span v-if="currentTab.user">({{ currentTab.user.userName }})</span>
          </span>

          <a-alert
              :style="{
                        marginTop: '10px'
                    }"
              v-if="submitResult.show"
              :message="submitResult.message"
              :type="submitResult.type"
              showIcon
              closable
              :afterClose="() => submitResult.show = false"
          />
        </a-form-item>

        <a-divider orientation="left">
          A VONZERŐ BEMUTATÁSA
        </a-divider>

        <h3>
          A vonzerő/létesítmény általános bemutatása, leírása, kiemelendő értékei, története, fontosabb célcsoportjai
          stb.
        </h3>

        <a-form-item
            :selfUpdate="true"
            label="Rövid leírás (200-255 karakter)"
        >
          <Wysiwyg
              v-on:keyup="characterCount"
              v-on:change="characterCount"
              :height="100"
              ref="shortDescription"
              :read-only="isMobileReadonly"
              v-decorator="[
                            'shortDescription',
                            {
                                rules: [
                                    {
                                      required: isNotMobile && !isMobileReadonly,
                                      preserve: true,
                                      transform: (value) => {
                                        return stripTags(value);
                                      },
                                      min: characterCounts.shortDescription.min,
                                      max: characterCounts.shortDescription.max,
                                      message: 'Kérjük töltse ki! A leírás hosszának ' + characterCounts.shortDescription.min
                                      + ' és ' + characterCounts.shortDescription.max + ' között kell lennie.',
                                    }
                                ],
                                validateTrigger: ['blur', 'keyup']
                            }
                        ]">
          </Wysiwyg>

          <div class="character-count">
            <strong
                v-bind:class="{
                    'invalid': characterCounts.shortDescription.valid === false,
                    'valid': characterCounts.shortDescription.valid === true
                  }"
            >
              {{ characterCounts.shortDescription.current }}
            </strong>

            / {{ characterCounts.shortDescription.min }} - {{ characterCounts.shortDescription.max }}
          </div>
        </a-form-item>

        <div v-for="(value, locale) in translatedFormFields['shortDescription']"
             :key="'shortDescription-' + locale">
          <a-row v-if="value !== null">
            <div>
              <a-form-item>
                <div slot="label">
                  Nyelv
                </div>

                <a-select
                    style="width: 90%"
                    :getPopupContainer="getParentTriggerNode()"
                    v-on:change="updateTranslatedFormField('shortDescription')"
                    :ref="getTranslatedLocaleName('shortDescription', locale)"
                    :disabled="isTranslationEditDisabled"
                    :filterOption="filterSelectNames"
                    v-decorator="[
                                getTranslatedLocaleName('shortDescription', locale),
                                {
                                    initialValue: locale,
                                    rules: [{
                                        required: false,
                                    }]
                                }
                            ]"
                >
                  <a-select-option
                      v-for="language in languages"
                      :key="language.name"
                      :value="language.locale"
                      :disabled="!isLanguageAvailable('shortDescription', language.locale)"
                  >
                    {{ language.name.toLowerCase() }}
                  </a-select-option>
                </a-select>

                <a-tooltip title="Mező törlése" v-if="isAdmin">
                  <a-icon
                      class="dynamic-delete-button"
                      type="delete"
                      theme="filled"
                      v-on:click="() => deleteTranslatedFormField('shortDescription', locale)"
                  />
                </a-tooltip>
              </a-form-item>
            </div>

            <div>
              <a-form-item :selfUpdate="true">
                <span slot="label">
                  Rövid leírás {{ getLanguageName(locale) }} nyelven
                </span>

                <Wysiwyg
                    :maxLength="25000"
                    :height="100"
                    :disabled="false"
                    v-on:keyup="characterCount"
                    v-on:change="characterCount"
                    :ref="getTranslatedFieldName('shortDescription', locale)"
                    v-on:blur="updateTranslatedFormField('shortDescription')"
                    :readOnly="isTranslationEditDisabled"
                    v-decorator="[
                                    getTranslatedFieldName('shortDescription', locale),
                                    {
                                      initialValue: value,
                                      rules: [
                                          {
                                            required: false,
                                            preserve: true,
                                            message: 'Kérjük töltse ki!'
                                          },
                                          {
                                            transform: (value) => {
                                              return stripTags(value);
                                            },
                                            min: !isTranslationEditDisabled  ? characterCounts[getTranslatedFieldName('shortDescription', locale)].min : undefined,
                                            max: !isTranslationEditDisabled ? characterCounts[getTranslatedFieldName('shortDescription', locale)].max : undefined,
                                            message: 'Kérjük töltse ki! A leírás hosszának ' + characterCounts[getTranslatedFieldName('shortDescription', locale)].min
                                            + ' és ' + characterCounts[getTranslatedFieldName('shortDescription', locale)].max + ' között kell lennie.',
                                          }
                                      ],
                                      validateTrigger: ['blur', 'keyup']
                                  }
                                ]"
                    :autoSize="{
                            minRows: 3
                        }"
                />

                <div class="character-count">
                  <strong
                      v-bind:class="{
                    'invalid': characterCounts[getTranslatedFieldName('shortDescription', locale)].valid === false,
                    'valid': characterCounts[getTranslatedFieldName('shortDescription', locale)].valid === true
                  }"
                  >
                    {{ characterCounts[getTranslatedFieldName('shortDescription', locale)].current }}
                  </strong>

                  / {{ characterCounts[getTranslatedFieldName('shortDescription', locale)].min }} -
                  {{ characterCounts[getTranslatedFieldName('shortDescription', locale)].max }}
                </div>
              </a-form-item>
            </div>
          </a-row>
        </div>

        <a-form-item>
          <a-button
              type="dashed"
              style="width: 100%"
              :disabled="isOfficeEmployee || !getFirstAvailableLanguage('shortDescription') || isMobileReadonly"
              v-on:click="addTranslatedFormField('shortDescription')"
          >
            <a-icon type="plus" />

            Idegennyelvű rövid leírás hozzáadása
          </a-button>
        </a-form-item>

        <a-form-item
            :selfUpdate="true"
            label="Közepes leírás (700-1000 karakter)"
        >
          <Wysiwyg
              :maxLength="25000"
              :height="250"
              :read-only="isMobileReadonly"
              ref="mediumDescription"
              v-decorator="[
                            'mediumDescription',
                            {
                                rules: [
                                    {
                                      transform: (value) => {
                                        return stripTags(value);
                                      },
                                      required: mediumDescriptionRequired,
                                      preserve: true,
                                      min: characterCounts.mediumDescription.min,
                                      max: characterCounts.mediumDescription.max,
                                      message: 'Kérjük töltse ki! A leírás hosszának ' + characterCounts.mediumDescription.min
                                      + ' és ' + characterCounts.mediumDescription.max + ' között kell lennie.',
                                    }
                                ],
                                validateTrigger: ['blur', 'keyup']
                            }
                        ]"
              v-on:keyup="characterCount"
              v-on:change="characterCount"
              :autoSize="{
                            minRows: 3
                        }"
          />

          <div class="character-count">
            <strong
                v-bind:class="{
                    'invalid': characterCounts.mediumDescription.valid === false,
                    'valid': characterCounts.mediumDescription.valid === true
                  }"
            >
              {{ characterCounts.mediumDescription.current }}
            </strong>

            / {{ characterCounts.mediumDescription.min }} - {{ characterCounts.mediumDescription.max }}
          </div>
        </a-form-item>


        <div v-for="(value, locale) in translatedFormFields['mediumDescription']"
             :key="'mediumDescription-' + locale">
          <a-row v-if="value !== null">
            <div>
              <a-form-item>
                <div slot="label">
                  Nyelv
                </div>

                <a-select
                    style="width: 90%"
                    :getPopupContainer="getParentTriggerNode()"
                    :ref="getTranslatedLocaleName('mediumDescription', locale)"
                    v-on:change="updateTranslatedFormField('mediumDescription')"
                    :disabled="isTranslationEditDisabled"
                    v-decorator="[
                                getTranslatedLocaleName('mediumDescription', locale),
                                {
                                    initialValue: locale,
                                    rules: [{
                                        required: false,
                                    }]
                                }
                            ]"
                >
                  <a-select-option
                      v-for="language in languages"
                      :key="language.locale"
                      :value="language.locale"
                      :disabled="!isLanguageAvailable('mediumDescription', language.locale)"
                  >
                    {{ language.name.toLowerCase() }}
                  </a-select-option>
                </a-select>

                <a-tooltip title="Mező törlése" v-if="isAdmin">
                  <a-icon
                      class="dynamic-delete-button"
                      type="delete"
                      theme="filled"
                      v-on:click="() => deleteTranslatedFormField('mediumDescription', locale)"
                  />
                </a-tooltip>
              </a-form-item>
            </div>

            <div>
              <a-form-item :selfUpdate="true">
                <span slot="label">
                  Közepes leírás {{ getLanguageName(locale) }} nyelven
                </span>

                <Wysiwyg
                    :maxLength="25000"
                    :height="250"
                    :disabled="false"
                    v-on:keyup="characterCount"
                    v-on:change="characterCount"
                    :ref="getTranslatedFieldName('mediumDescription', locale)"
                    v-on:blur="updateTranslatedFormField('mediumDescription')"
                    :readOnly="isTranslationEditDisabled"
                    v-decorator="[
                                    getTranslatedFieldName('mediumDescription', locale),
                                    {
                                      initialValue: value,
                                      rules: [
                                          {
                                            required: false,
                                            preserve: true,
                                            message: 'Kérjük töltse ki!'
                                          },
                                          {
                                            transform: (value) => {
                                              return stripTags(value);
                                            },
                                            min: !isTranslationEditDisabled ? characterCounts[getTranslatedFieldName('mediumDescription', locale)].min : undefined,
                                            max: !isTranslationEditDisabled ? characterCounts[getTranslatedFieldName('mediumDescription', locale)].max : undefined,
                                            message: 'Kérjük töltse ki! A leírás hosszának ' + characterCounts[getTranslatedFieldName('mediumDescription', locale)].min
                                            + ' és ' + characterCounts[getTranslatedFieldName('mediumDescription', locale)].max + ' között kell lennie.',
                                          }
                                      ],
                                      validateTrigger: ['blur', 'keyup']
                                  }
                                ]"
                    :autoSize="{minRows: 2}"
                />

                <div class="character-count">
                  <strong
                      v-bind:class="{
                    'invalid': characterCounts[getTranslatedFieldName('mediumDescription', locale)].valid === false,
                    'valid': characterCounts[getTranslatedFieldName('mediumDescription', locale)].valid === true
                  }"
                  >
                    {{ characterCounts[getTranslatedFieldName('mediumDescription', locale)].current }}
                  </strong>

                  / {{ characterCounts[getTranslatedFieldName('mediumDescription', locale)].min }} -
                  {{ characterCounts[getTranslatedFieldName('mediumDescription', locale)].max }}
                </div>
              </a-form-item>
            </div>
          </a-row>
        </div>

        <a-form-item>
          <a-button
              type="dashed"
              style="width: 100%"
              :disabled="isOfficeEmployee || !getFirstAvailableLanguage('mediumDescription') || isMobileReadonly"
              v-on:click="addTranslatedFormField('mediumDescription')"
          >
            <a-icon type="plus" />

            Idegennyelvű közepes leírás hozzáadása
          </a-button>
        </a-form-item>

        <a-form-item
            v-show="isNotMobile"
            :selfUpdate="true"
            label="Hosszú leírás (1500-4000 karakter)"
        >
          <Wysiwyg
              :maxLength="25000"
              :height="900"
              :read-only="isMobileReadonly"
              ref="longDescription"
              v-decorator="[
                            'longDescription',
                            {
                                rules: [
                                    {
                                      transform: (value) => {
                                        return stripTags(value);
                                      },
                                      required: longDescriptionRequired,
                                      preserve: true,
                                      min: characterCounts.longDescription.min,
                                      max: characterCounts.longDescription.max,
                                      message: 'Kérjük töltse ki! A leírás hosszának ' + characterCounts.longDescription.min
                                      + ' és ' + characterCounts.longDescription.max + ' között kell lennie.',
                                    }
                                ],
                                validateTrigger: ['blur', 'keyup']
                            }
                        ]"
              v-on:keyup="characterCount"
              v-on:change="characterCount"
              :autoSize="{
                            minRows: 3
                        }"
          />

          <div class="character-count">
            <strong
                v-bind:class="{
                    'invalid': characterCounts.longDescription.valid === false,
                    'valid': characterCounts.longDescription.valid === true
                  }"
            >
              {{ characterCounts.longDescription.current }}
            </strong>

            / {{ characterCounts.longDescription.min }} - {{ characterCounts.longDescription.max }}
          </div>
        </a-form-item>

        <div v-for="(value, locale) in translatedFormFields['longDescription']"
             :key="'longDescription-' + locale">
          <a-row v-if="value !== null">
            <div>
              <a-form-item>
                <div slot="label">
                  Nyelv
                </div>

                <a-select
                    style="width: 90%"
                    :getPopupContainer="getParentTriggerNode()"
                    :ref="getTranslatedLocaleName('longDescription', locale)"
                    v-on:change="updateTranslatedFormField('longDescription')"
                    :disabled="isTranslationEditDisabled"
                    v-decorator="[
                                getTranslatedLocaleName('longDescription', locale),
                                {
                                    initialValue: locale,
                                    rules: [{
                                        required: false,
                                    }]
                                }
                            ]"
                >
                  <a-select-option
                      v-for="language in languages"
                      :key="language.locale"
                      :value="language.locale"
                      :disabled="!isLanguageAvailable('longDescription', language.locale)"
                  >
                    {{ language.name.toLowerCase() }}
                  </a-select-option>
                </a-select>

                <a-tooltip title="Mező törlése" v-if="isAdmin">
                  <a-icon
                      class="dynamic-delete-button"
                      type="delete"
                      theme="filled"
                      v-on:click="() => deleteTranslatedFormField('longDescription', locale)"
                  />
                </a-tooltip>
              </a-form-item>
            </div>

            <div>
              <a-form-item :selfUpdate="true">
                <span slot="label">
                  Hosszú leírás {{ getLanguageName(locale) }} nyelven
                </span>

                <Wysiwyg
                    :maxLength="25000"
                    :height="900"
                    :disabled="false"
                    v-on:keyup="characterCount"
                    v-on:change="characterCount"
                    :ref="getTranslatedFieldName('longDescription', locale)"
                    v-on:blur="updateTranslatedFormField('longDescription')"
                    :readOnly="isTranslationEditDisabled"
                    v-decorator="[
                                    getTranslatedFieldName('longDescription', locale),
                                    {
                                      initialValue: value,
                                      rules: [
                                          {
                                            required: false,
                                            preserve: true,
                                            message: 'Kérjük töltse ki!'
                                          },
                                          {
                                            transform: (value) => {
                                              return stripTags(value);
                                            },
                                            min: !isTranslationEditDisabled ? characterCounts[getTranslatedFieldName('longDescription', locale)].min : undefined,
                                            max: !isTranslationEditDisabled ? characterCounts[getTranslatedFieldName('longDescription', locale)].max : undefined,
                                            message: 'Kérjük töltse ki! A leírás hosszának ' + characterCounts[getTranslatedFieldName('longDescription', locale)].min
                                            + ' és ' + characterCounts[getTranslatedFieldName('longDescription', locale)].max + ' között kell lennie.',
                                          }
                                      ],
                                      validateTrigger: ['blur', 'keyup']
                                  }
                                ]"
                    :autoSize="{minRows: 2}"
                />

                <div class="character-count">
                  <strong
                      v-bind:class="{
                    'invalid': characterCounts[getTranslatedFieldName('longDescription', locale)].valid === false,
                    'valid': characterCounts[getTranslatedFieldName('longDescription', locale)].valid === true
                  }"
                  >
                    {{ characterCounts[getTranslatedFieldName('longDescription', locale)].current }}
                  </strong>

                  / {{ characterCounts[getTranslatedFieldName('longDescription', locale)].min }} -
                  {{ characterCounts[getTranslatedFieldName('longDescription', locale)].max }}
                </div>
              </a-form-item>
            </div>
          </a-row>
        </div>

        <a-form-item v-show="isNotMobile">
          <a-button
              type="dashed"
              style="width: 100%"
              :disabled="isOfficeEmployee || !getFirstAvailableLanguage('longDescription') || isMobileReadonly"
              v-on:click="addTranslatedFormField('longDescription')"
          >
            <a-icon type="plus" />

            Idegennyelvű hosszú leírás hozzáadása
          </a-button>
        </a-form-item>

        <a-form-item
                :selfUpdate="true"
                label="Mobil leírás (700-1000 karakter)"
        >
            <Wysiwyg
                    :maxLength="25000"
                    :height="250"
                    ref="mobileDescription"
                    v-decorator="[
                          'mobileDescription',
                          {
                              rules: [
                                  {
                                    transform: (value) => {
                                      return stripTags(value);
                                    },
                                    required: isMobileApp || mobilePublished,
                                    preserve: true,
                                    min: characterCounts.mobileDescription.min,
                                    max: characterCounts.mobileDescription.max,
                                    message: 'Kérjük töltse ki! A leírás hosszának ' + characterCounts.mobileDescription.min
                                    + ' és ' + characterCounts.mobileDescription.max + ' között kell lennie.',
                                  }
                              ],
                              validateTrigger: ['blur', 'keyup']
                          }
                      ]"
                    v-on:keyup="characterCount"
                    v-on:change="characterCount"
                    :autoSize="{
                          minRows: 3
                      }"
            />

            <div class="character-count">
                <strong
                        v-bind:class="{
                  'invalid': characterCounts.mobileDescription.valid === false,
                  'valid': characterCounts.mobileDescription.valid === true
                }"
                >
                    {{ characterCounts.mobileDescription.current }}
                </strong>

                / {{ characterCounts.mobileDescription.min }} - {{ characterCounts.mobileDescription.max }}
            </div>
        </a-form-item>

        <div v-for="(value, locale) in translatedFormFields['mobileDescription']"
             :key="'mobileDescription-' + locale">
          <a-row v-if="value !== null">
            <div>
              <a-form-item>
                <div slot="label">
                  Nyelv
                </div>

                <a-select
                    style="width: 90%"
                    :getPopupContainer="getParentTriggerNode()"
                    :ref="getTranslatedLocaleName('mobileDescription', locale)"
                    v-on:change="updateTranslatedFormField('mobileDescription')"
                    :disabled="isTranslationEditDisabled && !isMobileApp"
                    v-decorator="[
                                getTranslatedLocaleName('mobileDescription', locale),
                                {
                                    initialValue: locale,
                                    rules: [{
                                        required: false,
                                    }]
                                }
                            ]"
                >
                  <a-select-option
                      v-for="language in languages"
                      :key="language.locale"
                      :value="language.locale"
                      :disabled="!isLanguageAvailable('mobileDescription', language.locale)"
                  >
                    {{ language.name.toLowerCase() }}
                  </a-select-option>
                </a-select>

                <a-tooltip title="Mező törlése" v-if="isAdmin">
                  <a-icon
                      class="dynamic-delete-button"
                      type="delete"
                      theme="filled"
                      v-on:click="() => deleteTranslatedFormField('mobileDescription', locale)"
                  />
                </a-tooltip>
              </a-form-item>
            </div>

            <div>
              <a-form-item :selfUpdate="true">
                <span slot="label">
                  Mobil leírás {{ getLanguageName(locale) }} nyelven
                </span>

                <Wysiwyg
                    :maxLength="25000"
                    :height="900"
                    :disabled="false"
                    v-on:keyup="characterCount"
                    v-on:change="characterCount"
                    :ref="getTranslatedFieldName('mobileDescription', locale)"
                    v-on:blur="updateTranslatedFormField('mobileDescription')"
                    :readOnly="isTranslationEditDisabled && !isMobileApp"
                    v-decorator="[
                                    getTranslatedFieldName('mobileDescription', locale),
                                    {
                                      initialValue: value,
                                      rules: [
                                          {
                                            required: false,
                                            preserve: true,
                                            message: 'Kérjük töltse ki!'
                                          },
                                          {
                                            transform: (value) => {
                                              return stripTags(value);
                                            },
                                            min: !isTranslationEditDisabled ? characterCounts[getTranslatedFieldName('mobileDescription', locale)].min : undefined,
                                            max: !isTranslationEditDisabled ? characterCounts[getTranslatedFieldName('mobileDescription', locale)].max : undefined,
                                            message: 'Kérjük töltse ki! A leírás hosszának ' + characterCounts[getTranslatedFieldName('mobileDescription', locale)].min
                                            + ' és ' + characterCounts[getTranslatedFieldName('mobileDescription', locale)].max + ' között kell lennie.',
                                          }
                                      ],
                                      validateTrigger: ['blur', 'keyup']
                                  }
                                ]"
                    :autoSize="{minRows: 2}"
                />

                <div class="character-count">
                  <strong
                      v-bind:class="{
                    'invalid': characterCounts[getTranslatedFieldName('mobileDescription', locale)].valid === false,
                    'valid': characterCounts[getTranslatedFieldName('mobileDescription', locale)].valid === true
                  }"
                  >
                    {{ characterCounts[getTranslatedFieldName('mobileDescription', locale)].current }}
                  </strong>

                  / {{ characterCounts[getTranslatedFieldName('mobileDescription', locale)].min }} -
                  {{ characterCounts[getTranslatedFieldName('mobileDescription', locale)].max }}
                </div>
              </a-form-item>
            </div>
          </a-row>
        </div>

        <a-form-item>
          <a-button
              type="dashed"
              style="width: 100%"
              :disabled="isOfficeEmployee || !getFirstAvailableLanguage('mobileDescription')"
              v-on:click="addTranslatedFormField('mobileDescription')"
          >
            <a-icon type="plus" />

            Idegennyelvű mobil leírás hozzáadása
          </a-button>
        </a-form-item>

        <a-form-item
          v-if="isNotMobile"
          label="Régi leírás (csak forrásanyagnak, ha elérhető)"
        >
            <a-textarea
                    :maxLength="25000"
                    :readOnly="true"
                    v-decorator="['legacyDescription']"
                    :autoSize="{
                          minRows: 2
                      }"
            />
        </a-form-item>

        <a-form-item>
          <a-button type="primary" html-type="submit" :loading="submitLoading" v-if="showSaveButton">
            Mentés
          </a-button>

          <a-button type="secondary" html-type="button" v-on:click="resetForm" v-if="hasChanged()">
            <a-icon
                type="undo"
                theme="outlined"
            />

            Piszkozat törlése
          </a-button>

          <span v-if="currentTab" class="last-modification">
            Utolsó mentés: <strong>{{ moment(currentTab.modificationTime).format(timeStampFormat).toString() }}</strong> &nbsp;<span v-if="currentTab.user">({{ currentTab.user.userName }})</span>
          </span>

          <a-alert
              :style="{
                        marginTop: '10px'
                    }"
              v-if="submitResult.show"
              :message="submitResult.message"
              :type="submitResult.type"
              showIcon
              closable
              :afterClose="() => submitResult.show = false"
          />
        </a-form-item>
      </a-form>
    </a-spin>
  </div>
</template>

<script>
import { EditFormComponentMixin } from "@/mixins/EditFormComponentMixin";
import Wysiwyg from '@/components/shared/Wysiwyg.vue';

export default {
  menuName: 'Leírások',
  name: 'description-form',
  components: { Wysiwyg },
  mixins: [EditFormComponentMixin],
  data() {
    return {
      formId: 'DESCRIPTION',

      formFields: {
        'shortDescription': '',
        'mediumDescription': '',
        'longDescription': '',
        'mobileDescription': '',
        'legacyDescription': '',
      },

      translatedFormFields: {
        shortDescription: {},
        mediumDescription: {},
        longDescription: {},
        mobileDescription: {},
      },

      characterCounts: {
        'shortDescription': {
          valid: null,
          current: 0,
          min: 200,
          max: 255,
        },
        'mediumDescription': {
          valid: null,
          current: 0,
          min: 700,
          max: 1000,
        },
        'longDescription': {
          valid: null,
          current: 0,
          min: 1500,
          max: 4000,
        },
        'mobileDescription': {
          valid: null,
          current: 0,
          min: 700,
          max: 1000,
        },
      },

      modals: {}
    }
  },

  computed: {
    longDescriptionRequired() {
      return this.getAttraction().classification >= 5 && !this.isMobileReadonly && this.isNotMobile;
    },

    mediumDescriptionRequired() {
      return this.getAttraction().classification >= 3 && !this.isMobileReadonly && this.isNotMobile;
    }
  },

  methods: {}
}
</script>

<style>
.dynamic-delete-button {
  margin-left: 10px;
}
</style>
