// root
export const RESTORE_LOCAL_STATE = 'RESTORE_LOCAL_STATE';
export const UPDATE_VERSION = 'UPDATE_VERSION';

// attraction_module
export const REFRESH_ATTRACTION = 'REFRESH_ATTRACTION';

// hungarikum_module
export const REFRESH_HUNGARIKUM = 'REFRESH_HUNGARIKUM';

// user_module
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';