var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-form-container" },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.formDataLoading } },
        [
          _c(
            "a-form",
            {
              attrs: { form: _vm.form, layout: "vertical" },
              on: { submit: _vm.submit }
            },
            [
              _c(
                "a-form-item",
                [
                  _vm.showSaveButton
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            "html-type": "submit",
                            loading: _vm.submitLoading
                          }
                        },
                        [
                          _vm._v(
                            "\n                    Mentés\n                "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.hasChanged()
                    ? _c(
                        "a-button",
                        {
                          attrs: { type: "secondary", "html-type": "button" },
                          on: { click: _vm.resetForm }
                        },
                        [
                          _c("a-icon", {
                            attrs: { type: "undo", theme: "outlined" }
                          }),
                          _vm._v(
                            "\n\n                    Piszkozat törlése\n                "
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.submitResult.show
                    ? _c("a-alert", {
                        style: {
                          marginTop: "10px"
                        },
                        attrs: {
                          message: _vm.submitResult.message,
                          type: _vm.submitResult.type,
                          showIcon: "",
                          closable: "",
                          afterClose: function() {
                            return (_vm.submitResult.show = false)
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c("a-divider", { attrs: { orientation: "left" } }, [
                _vm._v("\n                HUNGARIKUM ÁLLAPOTA\n            ")
              ]),
              _c(
                "a-form-item",
                { attrs: { label: "Státusz" } },
                [
                  _c(
                    "a-radio-group",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: ["status"],
                          expression:
                            "[\n                    'status'\n                ]"
                        }
                      ]
                    },
                    [
                      _c("a-radio-button", { attrs: { value: 1 } }, [
                        _vm._v(_vm._s(_vm.statusNames[1]))
                      ]),
                      _c("a-radio-button", { attrs: { value: 0 } }, [
                        _vm._v(_vm._s(_vm.statusNames[0]))
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c("a-divider", { attrs: { orientation: "left" } }, [
                _vm._v("\n                ALAPADATOK\n            ")
              ]),
              _c(
                "a-form-item",
                { attrs: { label: "Hungarikum neve", selfUpdate: true } },
                [
                  _c("a-textarea", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "label",
                          {
                            rules: [
                              {
                                required: true,
                                preserve: true,
                                message: "Kérjük töltse ki!"
                              },
                              {
                                max: _vm.characterCounts.label.max,
                                message:
                                  "Maximum " +
                                  _vm.characterCounts.label.max +
                                  " karakter engedélyezett."
                              }
                            ],
                            validateTrigger: ["blur", "keyup"]
                          }
                        ],
                        expression:
                          "[\n                        'label',\n                        {\n                            rules: [\n                                {\n                                  required: true,\n                                  preserve: true,\n                                  message: 'Kérjük töltse ki!'\n                                },\n                                {\n                                  max: characterCounts.label.max,\n                                  message: 'Maximum ' + characterCounts.label.max + ' karakter engedélyezett.',\n                                }\n                            ],\n                            validateTrigger: ['blur', 'keyup']\n                        }\n                    ]"
                      }
                    ],
                    attrs: {
                      maxLength: 25000,
                      autoSize: { minRows: 2 },
                      placeholder: "Hungarikum neve"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "Hungarikum leírása", selfUpdate: true } },
                [
                  _c("a-textarea", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "description",
                          {
                            rules: [
                              {
                                required: true,
                                preserve: true,
                                message: "Kérjük töltse ki!"
                              },
                              {
                                max: _vm.characterCounts.description.max,
                                message:
                                  "Maximum " +
                                  _vm.characterCounts.description.max +
                                  " karakter engedélyezett."
                              }
                            ],
                            validateTrigger: ["blur", "keyup"]
                          }
                        ],
                        expression:
                          "[\n                        'description',\n                        {\n                            rules: [\n                                {\n                                  required: true,\n                                  preserve: true,\n                                  message: 'Kérjük töltse ki!'\n                                },\n                                {\n                                  max: characterCounts.description.max,\n                                  message: 'Maximum ' + characterCounts.description.max + ' karakter engedélyezett.',\n                                }\n                            ],\n                            validateTrigger: ['blur', 'keyup']\n                        }\n                    ]"
                      }
                    ],
                    attrs: {
                      maxLength: 25000,
                      autoSize: { minRows: 6 },
                      placeholder: "Hungarikum leírása"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "Kategória" } },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "category",
                            {
                              rules: [
                                {
                                  required: true,
                                  message: "Kérjük töltse ki!"
                                }
                              ],
                              validateTrigger: "blur"
                            }
                          ],
                          expression:
                            "[\n                    'category',\n                    {\n                        rules: [{\n                            required: true,\n                            message: 'Kérjük töltse ki!'\n                        }],\n                        validateTrigger: 'blur'\n                    }\n                ]"
                        }
                      ],
                      attrs: { placeholder: "Kérjük válasszon!" }
                    },
                    [
                      _c(
                        "a-select-option",
                        { attrs: { value: "Agrár és élelmiszergazdaság" } },
                        [
                          _vm._v(
                            "\n                        Agrár és élelmiszergazdaság\n                    "
                          )
                        ]
                      ),
                      _c(
                        "a-select-option",
                        { attrs: { value: "Egészség és életmód" } },
                        [
                          _vm._v(
                            "\n                        Egészség és életmód\n                    "
                          )
                        ]
                      ),
                      _c(
                        "a-select-option",
                        { attrs: { value: "Épített környezet" } },
                        [
                          _vm._v(
                            "\n                        Épített környezet\n                    "
                          )
                        ]
                      ),
                      _c(
                        "a-select-option",
                        { attrs: { value: "Ipari és műszaki megoldások" } },
                        [
                          _vm._v(
                            "\n                        Ipari és műszaki megoldások\n                    "
                          )
                        ]
                      ),
                      _c(
                        "a-select-option",
                        { attrs: { value: "Kulturális örökség" } },
                        [
                          _vm._v(
                            "\n                        Kulturális örökség\n                    "
                          )
                        ]
                      ),
                      _c("a-select-option", { attrs: { value: "Sport" } }, [
                        _vm._v(
                          "\n                        Sport\n                    "
                        )
                      ]),
                      _c(
                        "a-select-option",
                        { attrs: { value: "Természeti környezet" } },
                        [
                          _vm._v(
                            "\n                        Természeti környezet\n                    "
                          )
                        ]
                      ),
                      _c(
                        "a-select-option",
                        { attrs: { value: "Turizmus és vendéglátás" } },
                        [
                          _vm._v(
                            "\n                        Turizmus és vendéglátás\n                    "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("a-divider", { attrs: { orientation: "left" } }, [
                _vm._v("\n                ÉRTÉKESÍTÉS\n            ")
              ]),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: "Értékesítési hely/hivatalos viszonteladó neve",
                    selfUpdate: true
                  }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "posLabel",
                          {
                            rules: [
                              {
                                required: false,
                                preserve: true,
                                message: "Kérjük töltse ki!"
                              },
                              {
                                max: _vm.characterCounts.posLabel.max,
                                message:
                                  "Maximum " +
                                  _vm.characterCounts.posLabel.max +
                                  " karakter engedélyezett."
                              }
                            ],
                            validateTrigger: ["blur", "keyup"]
                          }
                        ],
                        expression:
                          "[\n                        'posLabel',\n                        {\n                            rules: [\n                                {\n                                  required: false,\n                                  preserve: true,\n                                  message: 'Kérjük töltse ki!'\n                                },\n                                {\n                                  max: characterCounts.posLabel.max,\n                                  message: 'Maximum ' + characterCounts.posLabel.max + ' karakter engedélyezett.',\n                                }\n                            ],\n                            validateTrigger: ['blur', 'keyup']\n                        }\n                    ]"
                      }
                    ],
                    attrs: {
                      maxLength: 25000,
                      autoSize: { minRows: 2 },
                      placeholder: "Név"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { selfUpdate: true } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v(
                        "\n                  Értékesítési hely/hivatalos viszonteladó e-mail címe\n\n                  "
                      ),
                      _c(
                        "a-tooltip",
                        {
                          attrs: {
                            title:
                              "Kérjük az email címet cimzett@domain.xy formátumban töltse ki!"
                          }
                        },
                        [
                          _c("a-icon", { attrs: { type: "question-circle-o" } })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "posEmail",
                          {
                            rules: [
                              {
                                required: false,
                                whitespace: true,
                                type: "email",
                                message:
                                  "Kérjük a megfelelő formátumban töltse ki!"
                              },
                              {
                                max: _vm.characterCounts.posEmail.max,
                                message:
                                  "Maximum " +
                                  _vm.characterCounts.posEmail.max +
                                  " karakter engedélyezett."
                              }
                            ],
                            validateTrigger: ["blur", "keyup"]
                          }
                        ],
                        expression:
                          "[\n                        'posEmail',\n                        {\n                            rules: [\n                                {\n                                  required: false,\n                                  whitespace: true,\n                                  type: 'email',\n                                  message: 'Kérjük a megfelelő formátumban töltse ki!'\n                                },\n                                {\n                                  max: characterCounts.posEmail.max,\n                                  message: 'Maximum ' + characterCounts.posEmail.max + ' karakter engedélyezett.',\n                                }\n                            ],\n                            validateTrigger: ['blur', 'keyup']\n                        }\n                    ]"
                      }
                    ],
                    attrs: {
                      maxLength: 25000,
                      autoSize: { minRows: 2 },
                      placeholder: "E-mail cím"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { selfUpdate: true } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v(
                        "\n                  Értékesítési hely/hivatalos viszonteladó weboldala\n\n                  "
                      ),
                      _c(
                        "a-tooltip",
                        {
                          attrs: {
                            title:
                              "Kérjük a weboldal címét http(s)://www.weboldal.xy formátumban töltse ki!"
                          }
                        },
                        [
                          _c("a-icon", { attrs: { type: "question-circle-o" } })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "posWebsite",
                          {
                            rules: [
                              {
                                required: false,
                                message:
                                  "Kérjük a megfelelő formátumban töltse ki!",
                                pattern: /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
                              },
                              {
                                max: _vm.characterCounts.posWebsite.max,
                                message:
                                  "Maximum " +
                                  _vm.characterCounts.posWebsite.max +
                                  " karakter engedélyezett."
                              }
                            ],
                            validateTrigger: ["blur", "keyup"]
                          }
                        ],
                        expression:
                          "[\n                        'posWebsite',\n                        {\n                            rules: [\n                              {\n                                required: false,\n                                message: 'Kérjük a megfelelő formátumban töltse ki!',\n                                pattern: /^https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)/\n                              },\n                                {\n                                  max: characterCounts.posWebsite.max,\n                                  message: 'Maximum ' + characterCounts.posWebsite.max + ' karakter engedélyezett.',\n                                }\n                            ],\n                            validateTrigger: ['blur', 'keyup']\n                        }\n                    ]"
                      }
                    ],
                    attrs: {
                      maxLength: 25000,
                      autoSize: { minRows: 2 },
                      placeholder: "Weboldal"
                    },
                    on: {
                      change: function(event) {
                        return _vm.transformEncodedUrl(
                          event.target.value,
                          "posWebsite"
                        )
                      }
                    }
                  })
                ],
                1
              ),
              _c("a-divider", { attrs: { orientation: "left" } }, [
                _vm._v("\n                ELHELYEZKEDÉS\n            ")
              ]),
              _c(
                "a-form-item",
                { attrs: { label: "Értékelhető" } },
                [
                  _c(
                    "a-radio-group",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: ["rateable"],
                          expression:
                            "[\n                    'rateable'\n                ]"
                        }
                      ]
                    },
                    [
                      _c("a-radio-button", { attrs: { value: 1 } }, [
                        _vm._v("Igen")
                      ]),
                      _c("a-radio-button", { attrs: { value: 0 } }, [
                        _vm._v("Nem")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "Cimkék" } },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "labels/id",
                            {
                              rules: [
                                {
                                  required: _vm.isChecked("rateable"),
                                  message: "Kérjük töltse ki!"
                                }
                              ],
                              validateTrigger: ["blur", "keyup"]
                            }
                          ],
                          expression:
                            "[\n                        'labels/id',\n                        {\n                            rules: [\n                                {\n                                required: isChecked('rateable'),\n                                message: 'Kérjük töltse ki!',\n                              },\n                            ],\n                            validateTrigger: ['blur','keyup']\n                        }\n                    ]"
                        }
                      ],
                      style: { width: "100%" },
                      attrs: {
                        placeholder: "Kezdjen gépelni",
                        showSearch: "",
                        mode: "multiple",
                        showArrow: false,
                        defaultActiveFirstOption: false,
                        getPopupContainer: _vm.getParentTriggerNode(),
                        filterOption: false,
                        notFoundContent: _vm.loading["labels/id"]
                          ? undefined
                          : null
                      },
                      on: {
                        search: function(value) {
                          return _vm.labelCitiesAutoComplete(value, "labels/id")
                        },
                        dropdownVisibleChange: function(open) {
                          return (
                            open &&
                            _vm.labelCitiesAutoComplete(null, "labels/id")
                          )
                        }
                      }
                    },
                    [
                      _vm.loading["labels/id"]
                        ? _c("a-spin", {
                            attrs: { slot: "notFoundContent", size: "small" },
                            slot: "notFoundContent"
                          })
                        : _vm._e(),
                      _vm._l(_vm.options["labels/id"], function(option) {
                        return _c(
                          "a-select-option",
                          { key: option.id, attrs: { value: option.id } },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(option.name) +
                                "\n                    "
                            )
                          ]
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "POI elhelyezése", selfUpdate: true } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "poiPlace",
                          {
                            rules: [
                              {
                                required: _vm.isChecked("rateable"),
                                preserve: true,
                                message: "Kérjük töltse ki!"
                              },
                              {
                                max: _vm.characterCounts.poiPlace.max,
                                message:
                                  "Maximum " +
                                  _vm.characterCounts.poiPlace.max +
                                  " karakter engedélyezett."
                              }
                            ],
                            validateTrigger: ["blur", "keyup"]
                          }
                        ],
                        expression:
                          "[\n                        'poiPlace',\n                        {\n                            rules: [\n                                {\n                                  required: isChecked('rateable'),\n                                  preserve: true,\n                                  message: 'Kérjük töltse ki!'\n                                },\n                                {\n                                  max: characterCounts.poiPlace.max,\n                                  message: 'Maximum ' + characterCounts.poiPlace.max + ' karakter engedélyezett.',\n                                }\n                            ],\n                            validateTrigger: ['blur', 'keyup']\n                        }\n                    ]"
                      }
                    ],
                    attrs: {
                      maxLength: 25000,
                      autoSize: { minRows: 2 },
                      placeholder: "Elhelyezés"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "Térkép, hely", selfUpdate: true } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "addressPlace",
                          {
                            rules: [
                              {
                                required: _vm.isChecked("rateable"),
                                preserve: true,
                                message: "Kérjük töltse ki!"
                              },
                              {
                                max: _vm.characterCounts.addressPlace.max,
                                message:
                                  "Maximum " +
                                  _vm.characterCounts.addressPlace.max +
                                  " karakter engedélyezett."
                              }
                            ],
                            validateTrigger: ["blur", "keyup"]
                          }
                        ],
                        expression:
                          "[\n                        'addressPlace',\n                        {\n                            rules: [\n                                {\n                                  required: isChecked('rateable'),\n                                  preserve: true,\n                                  message: 'Kérjük töltse ki!'\n                                },\n                                {\n                                  max: characterCounts.addressPlace.max,\n                                  message: 'Maximum ' + characterCounts.addressPlace.max + ' karakter engedélyezett.',\n                                }\n                            ],\n                            validateTrigger: ['blur', 'keyup']\n                        }\n                    ]"
                      }
                    ],
                    attrs: {
                      maxLength: 25000,
                      autoSize: { minRows: 2 },
                      placeholder: "Helyszín"
                    }
                  })
                ],
                1
              ),
              _c("p", [
                _vm._v(
                  "\n                Az alábbi sorokban kérjük a hungarikum pontos elhelyezkedésére vonatkozó adatok opcionális\n                megadását.\n            "
                )
              ]),
              _c(
                "a-form-item",
                { attrs: { label: "Irányítószám" } },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "address/zipCode/id",
                            {
                              rules: [
                                {
                                  required: false,
                                  whitespace: true,
                                  message: "Kérjük töltse ki!",
                                  type: "integer"
                                }
                              ],
                              validateTrigger: ["blur", "keyup"]
                            }
                          ],
                          expression:
                            "[\n                        'address/zipCode/id',\n                        {\n                            rules: [\n                                {\n                                required: false,\n                                whitespace: true,\n                                message: 'Kérjük töltse ki!',\n                                type: 'integer'\n                              },\n                            ],\n                            validateTrigger: ['blur','keyup']\n                        }\n                    ]"
                        }
                      ],
                      style: { width: "100%" },
                      attrs: {
                        placeholder: "Kezdjen gépelni",
                        showSearch: "",
                        showArrow: false,
                        defaultActiveFirstOption: false,
                        getPopupContainer: _vm.getParentTriggerNode(),
                        filterOption: false,
                        notFoundContent: _vm.loading["address/zipCode/id"]
                          ? undefined
                          : null
                      },
                      on: {
                        search: function(value) {
                          return _vm.zipCodeAutoComplete(
                            value,
                            "address/zipCode/id",
                            true
                          )
                        },
                        change: function(value) {
                          _vm.fillCityOptions(
                            value,
                            "address/zipCode/id",
                            "address/city/id"
                          )
                        },
                        dropdownVisibleChange: function(open) {
                          return (
                            open &&
                            _vm.zipCodeAutoComplete(null, "address/zipCode/id")
                          )
                        }
                      }
                    },
                    [
                      _vm.loading["address/zipCode/id"]
                        ? _c("a-spin", {
                            attrs: { slot: "notFoundContent", size: "small" },
                            slot: "notFoundContent"
                          })
                        : _vm._e(),
                      _vm._l(_vm.options["address/zipCode/id"], function(
                        option
                      ) {
                        return _c(
                          "a-select-option",
                          { key: option.code, attrs: { value: option.id } },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(option.code) +
                                "\n                    "
                            )
                          ]
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "Település neve" } },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "address/city/id",
                            {
                              rules: [
                                {
                                  required: false,
                                  whitespace: true,
                                  message: "Kérjük töltse ki!",
                                  type: "integer"
                                }
                              ],
                              validateTrigger: ["blur", "keyup"]
                            }
                          ],
                          expression:
                            "[\n                        'address/city/id',\n                        {\n                            rules: [\n                                {\n                                required: false,\n                                whitespace: true,\n                                message: 'Kérjük töltse ki!',\n                                type: 'integer'\n                              },\n                            ],\n                            validateTrigger: ['blur','keyup']\n                        }\n                    ]"
                        }
                      ],
                      style: { width: "100%" },
                      attrs: {
                        placeholder: "Töltse ki az irányítószám mezőt!",
                        showArrow: false,
                        disabled: !_vm.options["address/city/id"].length,
                        defaultActiveFirstOption: true,
                        filterOption: false,
                        notFoundContent: _vm.loading["address/zipCode/id"]
                          ? undefined
                          : null
                      }
                    },
                    [
                      _vm.loading["address/zipCode/id"]
                        ? _c("a-spin", {
                            attrs: { slot: "notFoundContent", size: "small" },
                            slot: "notFoundContent"
                          })
                        : _vm._e(),
                      _vm._l(_vm.options["address/city/id"], function(option) {
                        return _c(
                          "a-select-option",
                          { key: option.id, attrs: { value: option.id } },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(option.name) +
                                "\n                    "
                            )
                          ]
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { selfUpdate: true, label: "Közterület neve" } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "address/address",
                          {
                            rules: [
                              {
                                required:
                                  false &&
                                  !_vm.isChecked("address/addressNotRelevant"),
                                whitespace: true,
                                message: "Kérjük töltse ki!"
                              },
                              {
                                max: _vm.characterCounts["address/address"].max,
                                message:
                                  "Maximum " +
                                  _vm.characterCounts["address/address"].max +
                                  " karakter engedélyezett."
                              }
                            ],
                            validateTrigger: ["blur", "keyup"]
                          }
                        ],
                        expression:
                          "[\n                        'address/address',\n                        {\n                            rules: [\n                                {\n                                  required: false && !isChecked('address/addressNotRelevant'),\n                                  whitespace: true,\n                                  message: 'Kérjük töltse ki!'\n                                },\n                                {\n                                  max: characterCounts['address/address'].max,\n                                  message: 'Maximum ' + characterCounts['address/address'].max + ' karakter engedélyezett.',\n                                }\n                            ],\n                            validateTrigger: ['blur','keyup']\n                        }\n                    ]"
                      }
                    ],
                    attrs: {
                      maxLength: 25000,
                      disabled: _vm.isChecked("address/addressNotRelevant"),
                      placeholder: "Kérjük beírni (betűkkel)"
                    }
                  }),
                  _c(
                    "a-checkbox",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "address/addressNotRelevant",
                            {
                              rules: [
                                {
                                  required: false
                                }
                              ]
                            }
                          ],
                          expression:
                            "[\n                    'address/addressNotRelevant',\n                    {\n                        rules: [{\n                            required: false\n                        }]\n                    }\n                ]"
                        }
                      ],
                      attrs: {
                        checked: _vm.isChecked("address/addressNotRelevant")
                      },
                      on: {
                        change: function() {
                          return _vm.relevancyCheckboxChange("address/address")
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                    Nem releváns\n                "
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    selfUpdate: true,
                    label: "Közterület típusa (utca, tér, út, dűlő stb.)"
                  }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "address/addressType",
                          {
                            rules: [
                              {
                                required:
                                  false &&
                                  !_vm.isChecked(
                                    "address/addressTypeNotRelevant"
                                  ),
                                whitespace: true,
                                message: "Kérjük töltse ki!"
                              },
                              {
                                max:
                                  _vm.characterCounts["address/addressType"]
                                    .max,
                                message:
                                  "Maximum " +
                                  _vm.characterCounts["address/addressType"]
                                    .max +
                                  " karakter engedélyezett."
                              }
                            ],
                            validateTrigger: ["blur", "keyup"]
                          }
                        ],
                        expression:
                          "[\n                        'address/addressType',\n                        {\n                            rules: [\n                                {\n                                  required: false && !isChecked('address/addressTypeNotRelevant'),\n                                  whitespace: true,\n                                  message: 'Kérjük töltse ki!'\n                                },\n                                {\n                                  max: characterCounts['address/addressType'].max,\n                                  message: 'Maximum ' + characterCounts['address/addressType'].max + ' karakter engedélyezett.',\n                                }\n                            ],\n                            validateTrigger: ['blur','keyup']\n                        }\n                    ]"
                      }
                    ],
                    attrs: {
                      maxLength: 25000,
                      disabled: _vm.isChecked("address/addressTypeNotRelevant"),
                      placeholder: "Kérjük beírni (betűkkel)"
                    }
                  }),
                  _c(
                    "a-checkbox",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "address/addressTypeNotRelevant",
                            {
                              rules: [
                                {
                                  required: false
                                }
                              ]
                            }
                          ],
                          expression:
                            "[\n                    'address/addressTypeNotRelevant',\n                    {\n                        rules: [{\n                            required: false\n                        }]\n                    }\n                ]"
                        }
                      ],
                      attrs: {
                        checked: _vm.isChecked("address/addressTypeNotRelevant")
                      },
                      on: {
                        change: function() {
                          return _vm.relevancyCheckboxChange(
                            "address/addressType"
                          )
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                    Nem releváns\n                "
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v("\n          Házszám/helyrajzi szám\n          "),
                      _c(
                        "a-tooltip",
                        {
                          attrs: {
                            title:
                              "Itt a házszámon kívül más is beírható (pl. helyrajzi szám vagy más egyéb, pontos azonosító, ami az adott ingatlan helyét pontosan meghatározza). Ha nincs ilyen pontos azonosító szám a címhez, akkor kérjük a mezőt üresen hagyni."
                          }
                        },
                        [
                          _c("a-icon", { attrs: { type: "question-circle-o" } })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "address/addressNumber",
                          {
                            rules: [
                              {
                                required:
                                  false &&
                                  !_vm.isChecked(
                                    "address/addressNumberNotRelevant"
                                  ),
                                whitespace: true,
                                message: "Kérjük töltse ki!"
                              },
                              {
                                max:
                                  _vm.characterCounts["address/addressNumber"]
                                    .max,
                                message:
                                  "Maximum " +
                                  _vm.characterCounts["address/addressNumber"]
                                    .max +
                                  " karakter engedélyezett."
                              }
                            ],
                            validateTrigger: ["blur", "keyup"]
                          }
                        ],
                        expression:
                          "[\n                        'address/addressNumber',\n                        {\n                            rules: [\n                                {\n                                  required: false && !isChecked('address/addressNumberNotRelevant'),\n                                  whitespace: true,\n                                  message: 'Kérjük töltse ki!'\n                                },\n                                {\n                                  max: characterCounts['address/addressNumber'].max,\n                                  message: 'Maximum ' + characterCounts['address/addressNumber'].max + ' karakter engedélyezett.',\n                                }\n                            ],\n                            validateTrigger: ['blur','keyup']\n                        }\n                    ]"
                      }
                    ],
                    attrs: {
                      maxLength: 25000,
                      disabled: _vm.isChecked(
                        "address/addressNumberNotRelevant"
                      ),
                      placeholder: "Kérjük beírni (számokkal, betűkkel)"
                    }
                  }),
                  _c(
                    "a-checkbox",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "address/addressNumberNotRelevant",
                            {
                              rules: [
                                {
                                  required: false
                                }
                              ]
                            }
                          ],
                          expression:
                            "[\n                    'address/addressNumberNotRelevant',\n                    {\n                        rules: [{\n                            required: false\n                        }]\n                    }\n                ]"
                        }
                      ],
                      attrs: {
                        checked: _vm.isChecked(
                          "address/addressNumberNotRelevant"
                        )
                      },
                      on: {
                        change: function() {
                          return _vm.relevancyCheckboxChange(
                            "address/addressNumber"
                          )
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                    Nem releváns\n                "
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { selfUpdate: true } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v(
                        "\n          A hungarikum GPS koordinátája: szélességi fok\n\n          "
                      ),
                      _c(
                        "a-tooltip",
                        { attrs: { title: _vm.coordinateTooltipLatitude } },
                        [
                          _c("a-icon", { attrs: { type: "question-circle-o" } })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "address/lat",
                          {
                            rules: [
                              {
                                required: _vm.isChecked("rateable"),
                                whitespace: true,
                                pattern: /^4([-+]?)([\d]{1})(((\.)(\d+)))$/g,
                                message: "A koordináták formátuma helytelen!"
                              },
                              {
                                type: "float",
                                message:
                                  "Kérjük adjon meg valós GPS koordinátákat!",
                                transform: function(value) {
                                  value = parseFloat(value)

                                  return isNaN(value) ? null : value
                                },
                                min: 45.72,
                                max: 48.6
                              }
                            ],
                            validateTrigger: ["blur", "keyup"]
                          }
                        ],
                        expression:
                          "[\n                        'address/lat',\n                        {\n                            rules: [\n                                {\n                                  required: isChecked('rateable'),\n                                  whitespace: true,\n                                  pattern: /^4([-+]?)([\\d]{1})(((\\.)(\\d+)))$/g,\n                                  message: 'A koordináták formátuma helytelen!'\n                                },\n                                {\n                                  type: 'float',\n                                  message: 'Kérjük adjon meg valós GPS koordinátákat!',\n                                  transform: (value) => {\n                                    value = parseFloat(value);\n\n                                    return isNaN(value) ? null: value;\n                                  },\n                                  min: 45.72,\n                                  max: 48.60\n                                }\n                                ],\n                            validateTrigger: ['blur','keyup']\n                        }\n                    ]"
                      }
                    ],
                    attrs: {
                      maxLength: 25000,
                      placeholder:
                        "Kérjük a koordinátát az alábbi formátumban adja meg: 47.507346"
                    },
                    on: {
                      change: function(event) {
                        return _vm.formatCoordinate(
                          event.target.value,
                          "address/lat"
                        )
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { selfUpdate: true } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v(
                        "\n        A hungarikum GPS koordinátája: hosszúsági fok\n\n        "
                      ),
                      _c(
                        "a-tooltip",
                        { attrs: { title: _vm.coordinateTooltipLongitude } },
                        [
                          _c("a-icon", { attrs: { type: "question-circle-o" } })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "address/long",
                          {
                            rules: [
                              {
                                required: _vm.isChecked("rateable"),
                                whitespace: true,
                                pattern: /^(1|2)(([-+]?)([\d]{1})((\.)(\d+))?)$/g,
                                message: "A koordináták formátuma helytelen!"
                              },
                              {
                                type: "float",
                                message:
                                  "Kérjük adjon meg valós GPS koordinátákat!",
                                transform: function(value) {
                                  value = parseFloat(value)

                                  return isNaN(value) ? null : value
                                },
                                min: 16.1,
                                max: 23
                              }
                            ],
                            validateTrigger: ["blur", "keyup"]
                          }
                        ],
                        expression:
                          "[\n                        'address/long',\n                        {\n                            rules: [\n                                {\n                                  required: isChecked('rateable'),\n                                  whitespace: true,\n                                  pattern: /^(1|2)(([-+]?)([\\d]{1})((\\.)(\\d+))?)$/g,\n                                  message: 'A koordináták formátuma helytelen!'\n                                },\n                                {\n                                  type: 'float',\n                                  message: 'Kérjük adjon meg valós GPS koordinátákat!',\n                                  transform: (value) => {\n                                    value = parseFloat(value);\n\n                                    return isNaN(value) ? null: value;\n                                  },\n                                  min: 16.10,\n                                  max: 23\n                                }],\n                            validateTrigger: ['blur','keyup']\n                        }\n                    ]"
                      }
                    ],
                    attrs: {
                      maxLength: 25000,
                      placeholder:
                        "Kérjük a koordinátát az alábbi formátumban adja meg: 19.046610"
                    },
                    on: {
                      change: function(event) {
                        return _vm.formatCoordinate(
                          event.target.value,
                          "address/long"
                        )
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "Megjegyzés", selfUpdate: true } },
                [
                  _c("a-textarea", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "note",
                          {
                            rules: [
                              {
                                required: false,
                                preserve: true,
                                message: "Kérjük töltse ki!"
                              },
                              {
                                max: _vm.characterCounts.note.max,
                                message:
                                  "Maximum " +
                                  _vm.characterCounts.note.max +
                                  " karakter engedélyezett."
                              }
                            ],
                            validateTrigger: ["blur", "keyup"]
                          }
                        ],
                        expression:
                          "[\n                        'note',\n                        {\n                            rules: [\n                                {\n                                  required: false,\n                                  preserve: true,\n                                  message: 'Kérjük töltse ki!'\n                                },\n                                {\n                                  max: characterCounts.note.max,\n                                  message: 'Maximum ' + characterCounts.note.max + ' karakter engedélyezett.',\n                                }\n                            ],\n                            validateTrigger: ['blur', 'keyup']\n                        }\n                    ]"
                      }
                    ],
                    attrs: {
                      maxLength: 25000,
                      autoSize: { minRows: 6 },
                      placeholder: "Megjegyzés"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                [
                  _vm.showSaveButton
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            "html-type": "submit",
                            loading: _vm.submitLoading
                          }
                        },
                        [
                          _vm._v(
                            "\n                    Mentés\n                "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.hasChanged()
                    ? _c(
                        "a-button",
                        {
                          attrs: { type: "secondary", "html-type": "button" },
                          on: { click: _vm.resetForm }
                        },
                        [
                          _c("a-icon", {
                            attrs: { type: "undo", theme: "outlined" }
                          }),
                          _vm._v(
                            "\n\n                    Piszkozat törlése\n                "
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.submitResult.show
                    ? _c("a-alert", {
                        style: {
                          marginTop: "10px"
                        },
                        attrs: {
                          message: _vm.submitResult.message,
                          type: _vm.submitResult.type,
                          showIcon: "",
                          closable: "",
                          afterClose: function() {
                            return (_vm.submitResult.show = false)
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }