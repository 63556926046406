<template>

  <a-layout ref="layout-container" :style="{height: 'calc(100vh - 64px)'}">
    <a-layout-header class="layout-content-sub-header" v-if="attraction" v-show="collapsed">
      <div class="attraction-data">
      <span class="attraction-name">
        <strong>{{ attraction.name }}</strong>
      </span>

        <span class="attraction-id">
        Azonosító: <strong>{{ attraction.id }}</strong>
      </span>

        <span class="attraction-city" v-if="attraction.address && attraction.address.city">
        Település: <strong>{{ attraction.address.city.name }}</strong>
      </span>

        <span class="attraction-status">
        Státusz: <strong>{{ statusNames[attraction.status] }}</strong>
        </span>

        <br/>
          
        <span class="attraction-parent" v-if="multiEventParents.length">
            Multi esemény{{ multiEventParents.length > 1 ? 'ek' : '' }}:

            <ul>
                <li v-for="parentAttraction in multiEventParents">
                    <strong>{{ parentAttraction.name }}</strong>

                    <router-link :to="getMultiEventEditUrl(parentAttraction)">
                        <a-button
                                size="small"
                                icon="edit"
                                type="primary"
                                class="action-button"
                        >
                        </a-button>
                    </router-link>
                </li>
            </ul>
        </span>

        <span class="attraction-related" v-if="relatedAttractions.length">
            Kapcsolódó vonzerő{{ relatedAttractions.length > 1 ? 'k' : '' }}:

            <ul>
                <li v-for="relatedAttraction in relatedAttractions">
                    <strong>{{ relatedAttraction.name }}</strong>

                    <router-link :to="getAttractionEditUrl(relatedAttraction)">
                        <a-button
                                size="small"
                                icon="edit"
                                type="primary"
                                class="action-button"
                        >
                        </a-button>
                    </router-link>
                </li>
            </ul>
        </span>
      </div>
    </a-layout-header>

    <a-layout-sider :width="300" :style="{background: 'transparent'}" :collapsible="true" v-model="collapsed">
      <NetaEventDetailsMenuComponent
          class="attraction-menu-holder"
          :attraction="attraction"
          :collapsed="collapsed"
          :status-names="statusNames" />
    </a-layout-sider>

    <a-layout-content ref="layout-content-container" class="app-details-content">
      <transition name="slide-fade-vertical" mode="out-in">
        <router-view ref="app-layout-content" />
      </transition>
    </a-layout-content>

    <a-button
        v-show="scrolled"
        @click="scrollTop"
        class="scroll-to-top"
        size="large"
        icon="up"
        type="primary"
    />
  </a-layout>
</template>

<script>
import NetaEventDetailsMenuComponent from '@/components/neta/neta-event/NetaEventDetailsMenuComponent.vue';
import store from '@/store.js';
import { REFRESH_ATTRACTION } from "@/action_types";
import { Message } from "ant-design-vue";
import { SET_LAST_GROUP_TYPE } from "@/mutation_types";

const checkAttraction = (to, from, next) => {
  let storedAttraction = store.state.attraction.current;

  if (storedAttraction && storedAttraction.id == to.params.id) {
    next();

    return;
  }

  store.dispatch({
    type: REFRESH_ATTRACTION,
    attractionId: to.params.id,
    groupType: 'event'
  }).then(() => {
    next();
  }).catch(() => {
    next({
      name: 'neta-home'
    });

    Message.error('Az esemény nem elérhető!');
  })
}

export default {
  components: {
    NetaEventDetailsMenuComponent
  },

  data() {
    return {
      scrolled: false
    }
  },

  mounted() {
    window.addEventListener('scroll', this.onScroll);

    if (this.smallScreen) {
      this.collapsed = true;
    }

    this.$store.commit({
      type: SET_LAST_GROUP_TYPE,
      groupType: 'event'
    });
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  },

  computed: {
    attraction() {
      return this.$store.state.attraction.current;
    },

    smallScreen() {
      return window.innerWidth <= 768;
    },

    statusNames() {
      return this.$store.state.attraction.statusNames;
    },

    multiEventParents() {
        const parentAttractions = this.attraction.parentAttractions;
        const multiEventParents = [];

        parentAttractions.forEach((attraction) => {
            if (attraction.multi && attraction.status !== 6) {
                multiEventParents.push(attraction);
            }
        });

        return multiEventParents;
    },

    relatedAttractions() {
        const relatedAttractions = this.attraction.relatedAttractions;
        const related = [];

        relatedAttractions.forEach((attraction) => {
            if (attraction.group.groupType === 'attraction' && attraction.status !== 6) {
                related.push(attraction);
            }
        });

        return related;
    },

    collapsed: {
      get() {
        return this.$store.state.attraction.sidebarCollapsed;
      },

      set(collapsed) {
        this.$store.state.attraction.sidebarCollapsed = collapsed;
      },
    }
  },

  beforeRouteEnter(to, from, next) {
    checkAttraction(to, from, next);
  },

  beforeRouteUpdate(to, from, next) {
    this.$refs['layout-content-container'].$el.scrollTop = 0;

    checkAttraction(to, from, next);
  },

  methods: {
    scrollTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },

    onScroll(e) {
      this.scrolled = window.scrollY > 0;
    },

    /**
     * @param attraction
     * @returns {string}
     */
    getAttractionEditUrl(attraction) {
        return {
            name: 'neta-basic-attraction-attraction-data-form',
            params: {
                id: attraction.id
            }
        }
    },

    /**
     * @param multiTob
     * @returns {string}
     */
    getMultiEventEditUrl(multiTob) {
        return {
            name: 'neta-basic-multi-event-attraction-data-form',
            params: {
                id: multiTob.id
            }
        }
    },
  }
}
</script>


<style lang="scss" scoped>
.scroll-to-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
}

.layout-content-sub-header {
  background: transparent;
  width: 100%;
  height: auto;
  line-height: 1;
  padding: 15px;

  .attraction-data {
    max-width: calc(100% - 150px);

    span {
      display: inline-block;
      margin: 5px 30px 5px 0;

      &.attraction-id,
      &.attraction-status {
        white-space: nowrap;
      }
    }
  }
}

.attraction-menu-holder {
  position: sticky;
  top: 0;
}

.app-details-content {
  max-width: calc(100% - 300px);
}

@include md {
  .ant-layout-sider {
    position: fixed;
    bottom: 0;
    background: white;
    z-index: 100;
  }

  .app-details-content {
    max-width: calc(100% - 80px);
    margin-left: 80px;
  }
}

.ant-layout {
  flex-wrap: wrap;
  overscroll-behavior-y: contain;
}

.ant-layout-content {
  padding: 15px;
}

.slide-fade-vertical-enter-active {
  transition: all 0.2s ease-in-out;
}

.slide-fade-vertical-leave-active {
  transition: all 0.2s ease-in-out;
}

.slide-fade-vertical-enter {
  transform: translateY(-50vw);
  opacity: 0
}

.slide-fade-vertical-enter-to {
  transform: translateY(0);
  opacity: 1
}

.slide-fade-vertical-leave-to {
  transform: translateY(-50vw);
  opacity: 0;
}
</style>

<style lang="scss">
label .anticon {
  margin-left: 5px;
}

.last-modification {
  margin-left: 5px;
}
</style>