<template>
  <div class="office-container">
    <h1 class="office-title">
      Tourinform iroda adatai
    </h1>

    <div class="tourinform-form-container">
      <OfficeFormComponent />
    </div>

    <a-button
        v-show="scrolled"
        @click="scrollTop"
        class="scroll-to-top"
        size="large"
        icon="up"
        type="primary"
    />
  </div>
</template>

<script>
import OfficeFormComponent from './OfficeFormComponent.vue';

export default {
  data() {
    return {
      scrolled: false
    }
  },

  mounted() {
    window.addEventListener('scroll', this.onScroll);
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  },

  components: {
    OfficeFormComponent
  },

  methods: {
    scrollTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },

    onScroll(e) {
      this.scrolled = window.scrollY > 0;
    }
  }
}
</script>

<style lang="scss" scoped>
.scroll-to-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
}

h1 {
  font-size: 2em;
}

.office-container {
  padding: 25px;
  max-width: 1024px;
  margin: auto;

}
</style>