<template>
    <a-row class="not-found-row">
        <a-col :xs="2" :sm="4" :md="6" :lg="8" />
        <a-col :xs="20" :sm="16" :md="12" :lg="8" >
            <a-icon 
                type="frown"
                :theme="'twoTone'"
                twoToneColor="red"
                :style="{
                    fontSize: '88px'
                }"
            />
            <h1>
                404
            </h1>
            <h3>
                Az oldal nem található
            </h3> 
            <router-link
                to="/"
                replace
            >
                <a-button
                    type="primary"
                    ghost
                    icon="home"
                >
                </a-button>
            </router-link>
        </a-col>
        <a-col :xs="2" :sm="4" :md="6" :lg="8" />
    </a-row>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
    .not-found-row {
        margin-top: 10vh;
        text-align: center;
    }
</style>
