import { render, staticRenderFns } from "./NetaListAttractionComponent.vue?vue&type=template&id=4eb8199e&scoped=true&"
import script from "./NetaListAttractionComponent.vue?vue&type=script&lang=js&"
export * from "./NetaListAttractionComponent.vue?vue&type=script&lang=js&"
import style0 from "./NetaListAttractionComponent.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./NetaListAttractionComponent.vue?vue&type=style&index=1&id=4eb8199e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4eb8199e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/neta-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4eb8199e')) {
      api.createRecord('4eb8199e', component.options)
    } else {
      api.reload('4eb8199e', component.options)
    }
    module.hot.accept("./NetaListAttractionComponent.vue?vue&type=template&id=4eb8199e&scoped=true&", function () {
      api.rerender('4eb8199e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/neta/neta-list/NetaListAttractionComponent.vue"
export default component.exports