var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-form-container" },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.formDataLoading } },
        [
          _c(
            "a-form",
            {
              attrs: { form: _vm.form, layout: "vertical" },
              on: { submit: _vm.submit }
            },
            [
              _c(
                "a-form-item",
                [
                  _vm.showSaveButton && !_vm.isMobileReadonly
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            "html-type": "submit",
                            loading: _vm.submitLoading
                          }
                        },
                        [_vm._v("\n          Mentés\n        ")]
                      )
                    : _vm._e(),
                  _vm.hasChanged()
                    ? _c(
                        "a-button",
                        {
                          attrs: { type: "secondary", "html-type": "button" },
                          on: { click: _vm.resetForm }
                        },
                        [
                          _c("a-icon", {
                            attrs: { type: "undo", theme: "outlined" }
                          }),
                          _vm._v("\n\n          Piszkozat törlése\n        ")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.currentTab
                    ? _c("span", { staticClass: "last-modification" }, [
                        _vm._v("\n          Utolsó mentés: "),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm
                                .moment(_vm.currentTab.modificationTime)
                                .format(_vm.timeStampFormat)
                                .toString()
                            )
                          )
                        ]),
                        _vm._v("  "),
                        _vm.currentTab.user
                          ? _c("span", [
                              _vm._v(
                                "(" + _vm._s(_vm.currentTab.user.userName) + ")"
                              )
                            ])
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm.submitResult.show
                    ? _c("a-alert", {
                        style: {
                          marginTop: "10px"
                        },
                        attrs: {
                          message: _vm.submitResult.message,
                          type: _vm.submitResult.type,
                          showIcon: "",
                          closable: "",
                          afterClose: function() {
                            return (_vm.submitResult.show = false)
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              [
                _vm._l(_vm.prices, function(price, index) {
                  return _c(
                    "a-form-item",
                    { key: "prices-" + index, attrs: { required: false } },
                    [
                      index === 0
                        ? _c(
                            "span",
                            { attrs: { slot: "label" }, slot: "label" },
                            [
                              _vm._v(
                                "\n              Jegyárak\n              "
                              ),
                              _c(
                                "a-tooltip",
                                {
                                  attrs: {
                                    title:
                                      'Az A oszlopban levő meghatározásokat kérjük felülírni. (pl."típus1" helyett "felnőtt" vagy "diák" stb.)'
                                  }
                                },
                                [
                                  _c("a-icon", {
                                    attrs: { type: "question-circle-o" }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 16 } },
                            [
                              _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    selfUpdate: true,
                                    help: "Kérjük adja meg a jegy típúsát!"
                                  }
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "prices/" + index + "/name",
                                          {
                                            validateTrigger: ["keyup", "blur"],
                                            rules: [
                                              {
                                                required:
                                                  _vm.prices[index].price,
                                                whitespace: false,
                                                message:
                                                  "Kérjük töltse ki, vagy törölje a sort."
                                              }
                                            ]
                                          }
                                        ],
                                        expression:
                                          "[\n                                'prices/' + index + '/name',\n                                {\n                                    validateTrigger: ['keyup', 'blur'],\n                                    rules: [{\n                                        required: prices[index].price,\n                                        whitespace: false,\n                                        message: 'Kérjük töltse ki, vagy törölje a sort.'\n                                    }]\n                                }\n                            ]"
                                      }
                                    ],
                                    attrs: {
                                      disabled: _vm.isMobileReadonly,
                                      maxLength: 25000
                                    },
                                    on: {
                                      change: function(event) {
                                        return _vm.updateName(
                                          index,
                                          event.target.value
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    selfUpdate: true,
                                    help:
                                      "Kérjük a jegyárat beírni forintban (csak számot írjon a mezőbe)"
                                  }
                                },
                                [
                                  _c("a-input-number", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "prices/" + index + "/price",
                                          {
                                            validateTrigger: ["keyup", "blur"],
                                            rules: [
                                              {
                                                required:
                                                  _vm.prices[index].name
                                                    .length > 0,
                                                whitespace: true,
                                                transform: function(value) {
                                                  value = parseInt(value)

                                                  return isNaN(value)
                                                    ? null
                                                    : value
                                                },
                                                type: "integer",
                                                message:
                                                  "Kérjük töltse ki, vagy törölje a sort."
                                              }
                                            ]
                                          }
                                        ],
                                        expression:
                                          "[\n                                  'prices/' + index + '/price',\n                                  {\n                                      validateTrigger: ['keyup','blur'],\n                                      rules: [{\n                                          required: prices[index].name.length > 0,\n                                          whitespace: true,\n                                          transform: (value) => {\n                                            value = parseInt(value);\n\n                                            return isNaN(value) ? null : value;\n                                          },\n                                          type: 'integer',\n                                          message: 'Kérjük töltse ki, vagy törölje a sort.'\n                                      }]\n                                  }\n                                ]"
                                      }
                                    ],
                                    attrs: {
                                      disabled: _vm.isMobileReadonly,
                                      precision: 0,
                                      min: 1,
                                      step: 1
                                    },
                                    on: {
                                      change: function(value) {
                                        return _vm.updatePrice(index, value)
                                      }
                                    }
                                  }),
                                  _vm._v(
                                    "\n                Ft\n\n                "
                                  ),
                                  _vm.prices.length > 1
                                    ? _c("a-icon", {
                                        staticClass: "dynamic-delete-button",
                                        attrs: {
                                          type: "delete",
                                          theme: "filled",
                                          disabled:
                                            _vm.isMobileReadonly ||
                                            _vm.prices.length === 1
                                        },
                                        on: {
                                          click: function() {
                                            return _vm.removePrice(index)
                                          }
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                _c(
                  "a-form-item",
                  [
                    _c(
                      "a-button",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          disabled: _vm.isMobileReadonly,
                          type: "dashed"
                        },
                        on: { click: _vm.addPrice }
                      },
                      [
                        _c("a-icon", { attrs: { type: "plus" } }),
                        _vm._v(
                          "\n\n            Jegytípus hozzáadása\n          "
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              _c("a-divider"),
              _c(
                "a-form-item",
                { attrs: { selfUpdate: true } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v("\n            Megjegyzés\n            "),
                      _c(
                        "a-tooltip",
                        {
                          attrs: {
                            title:
                              "Kérjük ezt a cellát akkor töltse ki, ha a témához kapcsolódóan egyéb megjegyzése van."
                          }
                        },
                        [
                          _c("a-icon", { attrs: { type: "question-circle-o" } })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-textarea", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "pricesComment",
                          {
                            rules: [
                              {
                                required: false,
                                message: "Kérjük töltse ki!"
                              }
                            ]
                          }
                        ],
                        expression:
                          "[\n                      'pricesComment',\n                      {\n                          rules: [{\n                              required: false,\n                              message: 'Kérjük töltse ki!'\n                          }]\n                      }\n                  ]"
                      }
                    ],
                    attrs: {
                      maxLength: 25000,
                      disabled: _vm.isMobileReadonly,
                      autoSize: { minRows: 2 }
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                [
                  _vm.showSaveButton && !_vm.isMobileReadonly
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            "html-type": "submit",
                            loading: _vm.submitLoading
                          }
                        },
                        [_vm._v("\n          Mentés\n        ")]
                      )
                    : _vm._e(),
                  _vm.hasChanged()
                    ? _c(
                        "a-button",
                        {
                          attrs: { type: "secondary", "html-type": "button" },
                          on: { click: _vm.resetForm }
                        },
                        [
                          _c("a-icon", {
                            attrs: { type: "undo", theme: "outlined" }
                          }),
                          _vm._v("\n\n          Piszkozat törlése\n        ")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.currentTab
                    ? _c("span", { staticClass: "last-modification" }, [
                        _vm._v("\n          Utolsó mentés: "),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm
                                .moment(_vm.currentTab.modificationTime)
                                .format(_vm.timeStampFormat)
                                .toString()
                            )
                          )
                        ]),
                        _vm._v("  "),
                        _vm.currentTab.user
                          ? _c("span", [
                              _vm._v(
                                "(" + _vm._s(_vm.currentTab.user.userName) + ")"
                              )
                            ])
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm.submitResult.show
                    ? _c("a-alert", {
                        style: {
                          marginTop: "10px"
                        },
                        attrs: {
                          message: _vm.submitResult.message,
                          type: _vm.submitResult.type,
                          showIcon: "",
                          closable: "",
                          afterClose: function() {
                            return (_vm.submitResult.show = false)
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }