var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header-text" },
    [
      _c(
        "router-link",
        {
          attrs: {
            to: _vm.loggedIn ? { name: "neta-list" } : "/",
            disabled: _vm.linkDisabled
          }
        },
        [_c("span", [_vm._v("\n        Nemzeti Turisztikai Adatbázis\n    ")])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }