var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-form-container" },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.formDataLoading } },
        [
          _c(
            "a-form",
            {
              attrs: { form: _vm.form, layout: "vertical" },
              on: { submit: _vm.submit }
            },
            [
              _c(
                "a-form-item",
                [
                  _vm.showSaveButton && !_vm.isMobileReadonly
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            "html-type": "submit",
                            loading: _vm.submitLoading
                          }
                        },
                        [_vm._v("\n          Mentés\n        ")]
                      )
                    : _vm._e(),
                  _vm.hasChanged()
                    ? _c(
                        "a-button",
                        {
                          attrs: { type: "secondary", "html-type": "button" },
                          on: { click: _vm.resetForm }
                        },
                        [
                          _c("a-icon", {
                            attrs: { type: "undo", theme: "outlined" }
                          }),
                          _vm._v("\n\n          Piszkozat törlése\n        ")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.currentTab
                    ? _c("span", { staticClass: "last-modification" }, [
                        _vm._v("\n          Utolsó mentés: "),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm
                                .moment(_vm.currentTab.modificationTime)
                                .format(_vm.timeStampFormat)
                                .toString()
                            )
                          )
                        ]),
                        _vm._v("  "),
                        _vm.currentTab.user
                          ? _c("span", [
                              _vm._v(
                                "(" + _vm._s(_vm.currentTab.user.userName) + ")"
                              )
                            ])
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm.submitResult.show
                    ? _c("a-alert", {
                        style: {
                          marginTop: "10px"
                        },
                        attrs: {
                          message: _vm.submitResult.message,
                          type: _vm.submitResult.type,
                          showIcon: "",
                          closable: "",
                          afterClose: function() {
                            return (_vm.submitResult.show = false)
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm.isNotMobile
                ? _c("a-divider", { attrs: { orientation: "left" } }, [
                    _vm._v(
                      "\n        A VONZERŐHÖZ KAPCSOLÓDÓ FIZETÉSI ÉS FOGLALÁSI LEHETŐSÉGEK\n      "
                    )
                  ])
                : _vm._e(),
              _vm.isNotMobile
                ? _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: "A vonzerőnél van belépőjegy értékesítés?"
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "payment/ticketSales/id",
                                {
                                  rules: [
                                    {
                                      required: _vm.isNotMobile,
                                      message: "Kérjük töltse ki!"
                                    }
                                  ],
                                  validateTrigger: "blur"
                                }
                              ],
                              expression:
                                "['payment/ticketSales/id', {\n                          rules: [{\n                              required: isNotMobile,\n                              message: 'Kérjük töltse ki!'\n                          }],\n                          validateTrigger: 'blur'\n                      }]"
                            }
                          ],
                          attrs: {
                            disabled: _vm.isMobileReadonly,
                            getPopupContainer: _vm.getParentTriggerNode(),
                            loading: _vm.loading["payment/ticketSales/id"],
                            placeholder: "Kérjük válasszon!",
                            filterOption: _vm.filterSelectNames
                          },
                          on: {
                            dropdownVisibleChange: function(open) {
                              return (
                                open &&
                                _vm.loadOptions("payment/ticketSales/id")
                              )
                            },
                            change: function() {
                              return _vm.relevancyCheckboxChange([
                                "payment/creditCards/id",
                                "payment/vouchers/id",
                                "payment/foreignCurrencies/id"
                              ])
                            }
                          }
                        },
                        _vm._l(_vm.options["payment/ticketSales/id"], function(
                          category
                        ) {
                          return _c(
                            "a-select-option",
                            {
                              key: category.name,
                              attrs: { value: category.id }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(category.name) +
                                  "\n          "
                              )
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c("a-divider", { attrs: { orientation: "left" } }, [
                _vm._v("\n        HELYI BELÉPŐJEGY ÉRTÉKESÍTÉS\n      ")
              ]),
              _c(
                "a-form-item",
                {
                  attrs: { label: "Milyen bankkártya típusokat fogadnak el?" }
                },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "payment/creditCards/id",
                            {
                              rules: [
                                {
                                  required:
                                    _vm.isNotMobile &&
                                    !_vm.isChecked("payment/noCreditCards") &&
                                    !_vm.isFree("payment/ticketSales/id"),
                                  message: "Kérjük töltse ki!"
                                }
                              ],
                              validateTrigger: "blur"
                            }
                          ],
                          expression:
                            "['payment/creditCards/id', {\n                          rules: [{\n                              required: isNotMobile && !isChecked('payment/noCreditCards') && !isFree('payment/ticketSales/id'),\n                              message: 'Kérjük töltse ki!'\n                          }],\n                          validateTrigger: 'blur'\n                      }]"
                        }
                      ],
                      attrs: {
                        disabled:
                          _vm.isMobileReadonly ||
                          _vm.isChecked("payment/noCreditCards") ||
                          _vm.isFree("payment/ticketSales/id"),
                        getPopupContainer: _vm.getParentTriggerNode(),
                        mode: "multiple",
                        loading: _vm.loading["payment/creditCards/id"],
                        placeholder: "Kérjük válasszon!",
                        filterOption: _vm.filterSelectNames
                      },
                      on: {
                        dropdownVisibleChange: function(open) {
                          return (
                            open && _vm.loadOptions("payment/creditCards/id")
                          )
                        }
                      }
                    },
                    _vm._l(_vm.options["payment/creditCards/id"], function(
                      category
                    ) {
                      return _c(
                        "a-select-option",
                        { key: category.label, attrs: { value: category.id } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(category.label) +
                              "\n          "
                          )
                        ]
                      )
                    }),
                    1
                  ),
                  _c(
                    "a-checkbox",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "payment/noCreditCards",
                            {
                              rules: [
                                {
                                  required: false
                                }
                              ]
                            }
                          ],
                          expression:
                            "[\n                      'payment/noCreditCards',\n                      {\n                          rules: [{\n                              required: false\n                          }]\n                      }\n                  ]"
                        }
                      ],
                      attrs: {
                        disabled:
                          _vm.isMobileReadonly ||
                          _vm.isFree("payment/ticketSales/id"),
                        checked: _vm.isChecked("payment/noCreditCards")
                      },
                      on: {
                        change: function() {
                          return _vm.relevancyCheckboxChange(
                            "payment/creditCards/id"
                          )
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          Nem fogadnak el bankkártyát\n        "
                      )
                    ]
                  )
                ],
                1
              ),
              _vm.isNotMobile
                ? _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: "Milyen utalvány/voucher típusokat fogadnak el?"
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "payment/vouchers/id",
                                {
                                  rules: [
                                    {
                                      required:
                                        !_vm.isChecked("payment/noVouchers") &&
                                        !_vm.isFree("payment/ticketSales/id"),
                                      message: "Kérjük töltse ki!"
                                    }
                                  ],
                                  validateTrigger: "blur"
                                }
                              ],
                              expression:
                                "['payment/vouchers/id', {\n                          rules: [{\n                              required: !isChecked('payment/noVouchers') && !isFree('payment/ticketSales/id'),\n                              message: 'Kérjük töltse ki!'\n                          }],\n                          validateTrigger: 'blur'\n                      }]"
                            }
                          ],
                          attrs: {
                            disabled:
                              _vm.isMobileReadonly ||
                              _vm.isChecked("payment/noVouchers") ||
                              _vm.isFree("payment/ticketSales/id"),
                            getPopupContainer: _vm.getParentTriggerNode(),
                            mode: "multiple",
                            loading: _vm.loading["payment/vouchers/id"],
                            placeholder: "Kérjük válasszon!",
                            filterOption: _vm.filterSelectNames
                          },
                          on: {
                            dropdownVisibleChange: function(open) {
                              return (
                                open && _vm.loadOptions("payment/vouchers/id")
                              )
                            }
                          }
                        },
                        _vm._l(_vm.options["payment/vouchers/id"], function(
                          category
                        ) {
                          return _c(
                            "a-select-option",
                            {
                              key: category.label,
                              attrs: { value: category.id }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(category.label) +
                                  "\n          "
                              )
                            ]
                          )
                        }),
                        1
                      ),
                      _c(
                        "a-checkbox",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "payment/noVouchers",
                                {
                                  rules: [
                                    {
                                      required: false
                                    }
                                  ]
                                }
                              ],
                              expression:
                                "[\n                      'payment/noVouchers',\n                      {\n                          rules: [{\n                              required: false\n                          }]\n                      }\n                  ]"
                            }
                          ],
                          attrs: {
                            disabled:
                              _vm.isMobileReadonly ||
                              _vm.isFree("payment/ticketSales/id"),
                            checked: _vm.isChecked("payment/noVouchers")
                          },
                          on: {
                            change: function() {
                              return _vm.relevancyCheckboxChange(
                                "payment/vouchers/id"
                              )
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n          Nem fogadnak el utalványt/vouchert\n        "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label:
                      "Idegen fizetőeszközt elfogadnak a pénztárban? (valuta)"
                  }
                },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "payment/foreignCurrencies/id",
                            {
                              rules: [
                                {
                                  required:
                                    _vm.isNotMobile &&
                                    !_vm.isChecked(
                                      "payment/noForeignCurrency"
                                    ) &&
                                    !_vm.isFree("payment/ticketSales/id"),
                                  message: "Kérjük töltse ki!"
                                }
                              ],
                              validateTrigger: "blur"
                            }
                          ],
                          expression:
                            "['payment/foreignCurrencies/id', {\n                          rules: [{\n                              required: isNotMobile && !isChecked('payment/noForeignCurrency') && !isFree('payment/ticketSales/id'),\n                              message: 'Kérjük töltse ki!'\n                          }],\n                          validateTrigger: 'blur'\n                      }]"
                        }
                      ],
                      attrs: {
                        disabled:
                          _vm.isMobileReadonly ||
                          _vm.isChecked("payment/noForeignCurrency") ||
                          _vm.isFree("payment/ticketSales/id"),
                        getPopupContainer: _vm.getParentTriggerNode(),
                        mode: "multiple",
                        loading: _vm.loading["payment/foreignCurrencies/id"],
                        placeholder: "Kérjük válasszon!",
                        filterOption: _vm.filterSelectNames
                      },
                      on: {
                        dropdownVisibleChange: function(open) {
                          return (
                            open &&
                            _vm.loadOptions("payment/foreignCurrencies/id")
                          )
                        }
                      }
                    },
                    _vm._l(
                      _vm.options["payment/foreignCurrencies/id"],
                      function(category) {
                        return _c(
                          "a-select-option",
                          {
                            key: category.label,
                            attrs: { value: category.id }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(category.label) +
                                "\n          "
                            )
                          ]
                        )
                      }
                    ),
                    1
                  ),
                  _c(
                    "a-checkbox",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "payment/noForeignCurrency",
                            {
                              rules: [
                                {
                                  required: false
                                }
                              ]
                            }
                          ],
                          expression:
                            "[\n                      'payment/noForeignCurrency',\n                      {\n                          rules: [{\n                              required: false\n                          }]\n                      }\n                  ]"
                        }
                      ],
                      attrs: {
                        disabled:
                          _vm.isMobileReadonly ||
                          _vm.isFree("payment/ticketSales/id"),
                        checked: _vm.isChecked("payment/noForeignCurrency")
                      },
                      on: {
                        change: function() {
                          return _vm.relevancyCheckboxChange(
                            "payment/foreignCurrencies/id"
                          )
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          Nem fogadnak el idegen fizetőeszközt\n        "
                      )
                    ]
                  )
                ],
                1
              ),
              _vm.isNotMobile
                ? [
                    _c("a-divider", { attrs: { orientation: "left" } }, [
                      _vm._v(
                        "\n          ONLINE BELÉPŐJEGY ÉRTÉKESÍTÉS\n        "
                      )
                    ]),
                    _c(
                      "a-form-item",
                      {
                        attrs: {
                          label:
                            "Online belépőjegy/szolgáltatás vásárlás lehetséges?"
                        }
                      },
                      [
                        _c(
                          "a-select",
                          {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "payment/onlineSales",
                                  {
                                    rules: [
                                      {
                                        required: false,
                                        message: "Kérjük töltse ki!"
                                      }
                                    ]
                                  }
                                ],
                                expression:
                                  "[\n                        'payment/onlineSales',\n                        {\n                            rules: [{\n                                required: false,\n                                message: 'Kérjük töltse ki!'\n                            }]\n                        }\n                    ]"
                              }
                            ],
                            attrs: {
                              disabled:
                                _vm.isMobileReadonly ||
                                _vm.isFree("payment/ticketSales/id"),
                              placeholder: "Kérjük válasszon!"
                            }
                          },
                          [
                            _c("a-select-option", { attrs: { value: 1 } }, [
                              _vm._v("Igen")
                            ]),
                            _c("a-select-option", { attrs: { value: 0 } }, [
                              _vm._v("Nem")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "a-form-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.form.getFieldValue("payment/onlineSales") ===
                              1,
                            expression:
                              "form.getFieldValue('payment/onlineSales') === 1"
                          }
                        ]
                      },
                      [
                        _c(
                          "span",
                          { attrs: { slot: "label" }, slot: "label" },
                          [
                            _vm._v(
                              "\n              Ha IGEN, kérjük adja meg a jegyértékesítés weboldalának elérhetőségét!\n\n              "
                            ),
                            _c(
                              "a-tooltip",
                              {
                                attrs: {
                                  title:
                                    "Kérjük a weboldal címét http(s)://www.weboldal.xy formátumban töltse ki!"
                                }
                              },
                              [
                                _c("a-icon", {
                                  attrs: { type: "question-circle-o" }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "payment/onlineSalesWebsite",
                                {
                                  rules: [
                                    {
                                      required: false,
                                      message:
                                        "Kérjük a megfelelő formátumban töltse ki!",
                                      pattern: /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
                                    },
                                    {
                                      max:
                                        _vm.characterCounts[
                                          "payment/onlineSalesWebsite"
                                        ].max,
                                      message:
                                        "Maximum " +
                                        _vm.characterCounts[
                                          "payment/onlineSalesWebsite"
                                        ].max +
                                        " karakter engedélyezett."
                                    }
                                  ],
                                  validateTrigger: ["blur", "keyup"]
                                }
                              ],
                              expression:
                                "[\n                        'payment/onlineSalesWebsite',\n                          {\n                              rules: [\n                                  {\n                                    required: false,\n                                    message: 'Kérjük a megfelelő formátumban töltse ki!',\n                                    pattern: /^https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)/\n                                  },\n                                  {\n                                    max: characterCounts['payment/onlineSalesWebsite'].max,\n                                    message: 'Maximum ' + characterCounts['payment/onlineSalesWebsite'].max + ' karakter engedélyezett.',\n                                  }\n                              ],\n                              validateTrigger: ['blur', 'keyup']\n                          }\n                    ]"
                            }
                          ],
                          attrs: {
                            maxLength: 25000,
                            disabled:
                              _vm.isMobileReadonly ||
                              _vm.isFree("payment/ticketSales/id"),
                            autosize: ""
                          },
                          on: {
                            change: function(event) {
                              return _vm.transformEncodedUrl(
                                event.target.value,
                                "payment/onlineSalesWebsite"
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-form-item",
                      {
                        attrs: {
                          label: "Minden jegytípus elérhető online fizetéssel?"
                        }
                      },
                      [
                        _c(
                          "a-select",
                          {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "payment/completeOnlineSales",
                                  {
                                    rules: [
                                      {
                                        required: false,
                                        message: "Kérjük töltse ki!"
                                      }
                                    ]
                                  }
                                ],
                                expression:
                                  "[\n                        'payment/completeOnlineSales',\n                        {\n                            rules: [{\n                                required: false,\n                                message: 'Kérjük töltse ki!'\n                            }]\n                        }\n                    ]"
                              }
                            ],
                            attrs: {
                              disabled:
                                _vm.isMobileReadonly ||
                                _vm.isFree("payment/ticketSales/id"),
                              placeholder: "Kérjük válasszon!"
                            }
                          },
                          [
                            _c("a-select-option", { attrs: { value: 1 } }, [
                              _vm._v("Igen")
                            ]),
                            _c("a-select-option", { attrs: { value: 0 } }, [
                              _vm._v("Nem")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "a-form-item",
                      {
                        attrs: {
                          label:
                            "Folytatnak viszonteladókon keresztüli értékesítést?"
                        }
                      },
                      [
                        _c(
                          "a-select",
                          {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "payment/resellerSales",
                                  {
                                    rules: [
                                      {
                                        required: false,
                                        message: "Kérjük töltse ki!"
                                      }
                                    ]
                                  }
                                ],
                                expression:
                                  "[\n                        'payment/resellerSales',\n                        {\n                            rules: [{\n                                required: false,\n                                message: 'Kérjük töltse ki!'\n                            }]\n                        }\n                    ]"
                              }
                            ],
                            attrs: {
                              disabled:
                                _vm.isMobileReadonly ||
                                _vm.isFree("payment/ticketSales/id"),
                              placeholder: "Kérjük válasszon!"
                            }
                          },
                          [
                            _c("a-select-option", { attrs: { value: 1 } }, [
                              _vm._v("Igen")
                            ]),
                            _c("a-select-option", { attrs: { value: 0 } }, [
                              _vm._v("Nem")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "a-form-item",
                      {
                        attrs: {
                          label:
                            "Utazási irodák számára jutalékos fizetési rendszer működik a létesítménynél?"
                        }
                      },
                      [
                        _c(
                          "a-select",
                          {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "payment/commissionSales",
                                  {
                                    rules: [
                                      {
                                        required: false,
                                        message: "Kérjük töltse ki!"
                                      }
                                    ]
                                  }
                                ],
                                expression:
                                  "[\n                        'payment/commissionSales',\n                        {\n                            rules: [{\n                                required: false,\n                                message: 'Kérjük töltse ki!'\n                            }]\n                        }\n                    ]"
                              }
                            ],
                            attrs: {
                              disabled:
                                _vm.isMobileReadonly ||
                                _vm.isFree("payment/ticketSales/id"),
                              placeholder: "Kérjük válasszon!"
                            }
                          },
                          [
                            _c("a-select-option", { attrs: { value: 1 } }, [
                              _vm._v("Igen")
                            ]),
                            _c("a-select-option", { attrs: { value: 0 } }, [
                              _vm._v("Nem")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("a-divider", { attrs: { orientation: "left" } }, [
                      _vm._v("\n          FOGLALÁSI LEHETŐSÉGEK\n        ")
                    ]),
                    _c(
                      "a-form-item",
                      {
                        attrs: {
                          label: "Milyen előzetes foglalásra van lehetőség?"
                        }
                      },
                      [
                        _c(
                          "a-select",
                          {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "payment/reservations/id",
                                  {
                                    rules: [
                                      {
                                        required: false,
                                        message: "Kérjük töltse ki!"
                                      }
                                    ],
                                    validateTrigger: "blur"
                                  }
                                ],
                                expression:
                                  "['payment/reservations/id', {\n                            rules: [{\n                                required: false,\n                                message: 'Kérjük töltse ki!'\n                            }],\n                            validateTrigger: 'blur'\n                        }]"
                              }
                            ],
                            attrs: {
                              disabled:
                                _vm.isMobileReadonly ||
                                _vm.isChecked("payment/noReservations"),
                              getPopupContainer: _vm.getParentTriggerNode(),
                              mode: "multiple",
                              loading: _vm.loading["payment/reservations/id"],
                              placeholder: "Kérjük válasszon!",
                              filterOption: _vm.filterSelectNames
                            },
                            on: {
                              dropdownVisibleChange: function(open) {
                                return (
                                  open &&
                                  _vm.loadOptions("payment/reservations/id")
                                )
                              }
                            }
                          },
                          _vm._l(
                            _vm.options["payment/reservations/id"],
                            function(category) {
                              return _c(
                                "a-select-option",
                                {
                                  key: category.label,
                                  attrs: { value: category.id }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(category.label) +
                                      "\n            "
                                  )
                                ]
                              )
                            }
                          ),
                          1
                        ),
                        _c(
                          "a-checkbox",
                          {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "payment/noReservations",
                                  {
                                    rules: [
                                      {
                                        required: false
                                      }
                                    ]
                                  }
                                ],
                                expression:
                                  "[\n                        'payment/noReservations',\n                        {\n                            rules: [{\n                                required: false\n                            }]\n                        }\n                    ]"
                              }
                            ],
                            attrs: {
                              disabled: _vm.isMobileReadonly,
                              checked: _vm.isChecked("payment/noReservations")
                            },
                            on: {
                              change: function() {
                                return _vm.relevancyCheckboxChange(
                                  "payment/reservations/id"
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            Nincs lehetőség előzetes foglalásra\n          "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c("a-divider"),
                    _c(
                      "a-form-item",
                      { attrs: { selfUpdate: true } },
                      [
                        _c(
                          "span",
                          { attrs: { slot: "label" }, slot: "label" },
                          [
                            _vm._v(
                              "\n              Megjegyzés\n              "
                            ),
                            _c(
                              "a-tooltip",
                              {
                                attrs: {
                                  title:
                                    "Kérjük ezt a cellát akkor töltse ki, ha a témához kapcsolódóan egyéb megjegyzése van."
                                }
                              },
                              [
                                _c("a-icon", {
                                  attrs: { type: "question-circle-o" }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("a-textarea", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "payment/comment",
                                {
                                  rules: [
                                    {
                                      required: false,
                                      message: "Kérjük töltse ki!"
                                    }
                                  ]
                                }
                              ],
                              expression:
                                "[\n                        'payment/comment',\n                        {\n                            rules: [{\n                                required: false,\n                                message: 'Kérjük töltse ki!'\n                            }]\n                        }\n                    ]"
                            }
                          ],
                          attrs: {
                            disabled: _vm.isMobileReadonly,
                            maxLength: 25000,
                            autosize: { minRows: 2 }
                          }
                        })
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _c(
                "a-form-item",
                [
                  _vm.showSaveButton && !_vm.isMobileReadonly
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            "html-type": "submit",
                            loading: _vm.submitLoading
                          }
                        },
                        [_vm._v("\n          Mentés\n        ")]
                      )
                    : _vm._e(),
                  _vm.hasChanged()
                    ? _c(
                        "a-button",
                        {
                          attrs: { type: "secondary", "html-type": "button" },
                          on: { click: _vm.resetForm }
                        },
                        [
                          _c("a-icon", {
                            attrs: { type: "undo", theme: "outlined" }
                          }),
                          _vm._v("\n\n          Piszkozat törlése\n        ")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.currentTab
                    ? _c("span", { staticClass: "last-modification" }, [
                        _vm._v("\n          Utolsó mentés: "),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm
                                .moment(_vm.currentTab.modificationTime)
                                .format(_vm.timeStampFormat)
                                .toString()
                            )
                          )
                        ]),
                        _vm._v("  "),
                        _vm.currentTab.user
                          ? _c("span", [
                              _vm._v(
                                "(" + _vm._s(_vm.currentTab.user.userName) + ")"
                              )
                            ])
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm.submitResult.show
                    ? _c("a-alert", {
                        style: {
                          marginTop: "10px"
                        },
                        attrs: {
                          message: _vm.submitResult.message,
                          type: _vm.submitResult.type,
                          showIcon: "",
                          closable: "",
                          afterClose: function() {
                            return (_vm.submitResult.show = false)
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }