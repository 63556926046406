import PricesFormComponent from '@/components/neta/neta-attraction/basic-data/forms/prices-form/PricesFormComponent.vue';

import AccessibilityFormComponent
    from '@/components/neta/neta-attraction/basic-data/forms/accessibility-form/AccessibilityFormComponent.vue';

import OpeningFormComponent from '@/components/neta/neta-attraction/basic-data/forms/opening-form/OpeningFormComponent.vue';

import PaymentReservationFormComponent
    from '@/components/neta/neta-attraction/basic-data/forms/payment-reservation-form/PaymentReservationFormComponent.vue';

import ForeignLanguagesFormComponent
    from '@/components/neta/neta-attraction/basic-data/forms/foreign-languages-form/ForeignLanguagesFormComponent.vue';

import DescriptionFormComponent from '@/components/neta/neta-attraction/basic-data/forms/description-form/DescriptionFormComponent.vue';

import AttractionDataFormComponent
    from '@/components/neta/neta-attraction/basic-data/forms/attraction-data-form/AttractionDataFormComponent.vue';

import AttractionLocationFormComponent
    from '@/components/neta/neta-attraction/basic-data/forms/attraction-location-form/AttractionLocationFormComponent.vue';

import TekaComponent from "@/components/neta/neta-attraction/basic-data/forms/teka/TekaComponent";

let usedComponents = [
    AttractionDataFormComponent,
    DescriptionFormComponent,
    AccessibilityFormComponent,
    OpeningFormComponent,
    PaymentReservationFormComponent,
    PricesFormComponent,
    ForeignLanguagesFormComponent,
    AttractionLocationFormComponent,
    TekaComponent
];

const routePrefix = 'neta-basic-attraction';

export default {
    routePrefix,
    usedComponents
};