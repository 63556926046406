var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "neta-lists" }, [
    _c(
      "div",
      { staticClass: "list-menu" },
      [
        _c(
          "a-menu",
          {
            style: { background: "transparent" },
            attrs: { mode: "horizontal", align: "center" },
            model: {
              value: _vm.currentList,
              callback: function($$v) {
                _vm.currentList = $$v
              },
              expression: "currentList"
            }
          },
          [
            _vm.canAddAttraction || _vm.isViewer || _vm.isMobileApp
              ? _c(
                  "a-menu-item",
                  { key: "neta-list-attraction" },
                  [
                    _c("a-icon", { attrs: { type: "tag" } }),
                    _vm._v("\n                    Vonzerők\n                ")
                  ],
                  1
                )
              : _vm._e(),
            _vm.canAddAttraction || _vm.isViewer || _vm.isMobileApp
              ? _c(
                  "a-menu-item",
                  { key: "neta-list-event", attrs: { disabled: false } },
                  [
                    _c("a-icon", { attrs: { type: "calendar" } }),
                    _vm._v("\n                    Események\n                ")
                  ],
                  1
                )
              : _vm._e(),
            _vm.canAddAttraction || _vm.isViewer
              ? _c(
                  "a-menu-item",
                  {
                    key: "neta-list-multi-attraction",
                    attrs: { disabled: false }
                  },
                  [
                    _c("a-icon", { attrs: { type: "tags" } }),
                    _vm._v(
                      "\n                    Multi TOBok\n                "
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.canAddAttraction || _vm.isViewer
              ? _c(
                  "a-menu-item",
                  { key: "neta-list-multi-event", attrs: { disabled: false } },
                  [
                    _c("a-icon", { attrs: { type: "calendar" } }),
                    _vm._v(
                      "\n                    Multi események\n                "
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ],
      1
    ),
    _c("div", { staticClass: "list-content" }, [_c("router-view")], 1)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }