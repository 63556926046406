<template xmlns="">
    <div class="app-form-container">
        <a-spin :spinning="formDataLoading">
            <a-form :form="form" v-on:submit="submit" layout="vertical">
                <a-form-item>
                    <a-button type="primary" html-type="submit" :loading="submitLoading" v-if="showSaveButton && !isMobileReadonly">
                        Mentés
                    </a-button>

                    <a-button type="secondary" html-type="button" v-on:click="resetForm" v-if="hasChanged()">
                        <a-icon type="undo"
                                theme="outlined" />

                        Piszkozat törlése
                    </a-button>

                    <span v-if="currentTab" class="last-modification">
            Utolsó mentés: <strong>{{ moment(currentTab.modificationTime).format(timeStampFormat).toString() }}</strong> &nbsp;<span
                            v-if="currentTab.user">({{ currentTab.user.userName }})</span>
          </span>

                    <a-alert
                            :style="{
                        marginTop: '10px'
                    }"
                            v-if="submitResult.show"
                            :message="submitResult.message"
                            :type="submitResult.type"
                            showIcon
                            closable
                            :afterClose="() => submitResult.show = false"
                    />
                </a-form-item>

                <a-form-item :selfUpdate="true">
          <span slot="label">
              A létesítményhez legközelebbi tömegközlekedési állomás / megállóhely távolsága méterben

              <a-tooltip
                      title="A lent megadottak közüli legközelebbi gyalogos távolsága méterben megadva."
              >
                  <a-icon type="question-circle-o" />
              </a-tooltip>
          </span>

                    <a-input-number
                            :disabled="isMobileReadonly"
                            :min="0"
                            v-decorator="[
                        'travelling/closestTrainStationDistance',
                        {
                            rules: [{
                                required: false,
                                whitespace: true,
                                type: 'integer',
                                message: 'Kérjük töltse ki!',
                                transform: (value) => {
                                  value = parseInt(value);

                                  return isNaN(value) ? null: value;
                                },
                            }],
                            validateTrigger: ['blur', 'change'],
                        }
                    ]"
                    >
                    </a-input-number>
                    m
                </a-form-item>

                <a-form-item :selfUpdate="true">
          <span slot="label">
              Legközelebbi vasútállomás / vasúti megálló távolsága

              <a-tooltip
                      title="Gyalogos távolság méterben megadva."
              >
                  <a-icon type="question-circle-o" />
              </a-tooltip>
          </span>

                    <a-input-number
                            :disabled="isMobileReadonly"
                            :min="0"
                            v-decorator="[
                        'travelling/closestStopDistance',
                        {
                            rules: [{
                                required: false,
                                whitespace: true,
                                type: 'integer',
                                message: 'Kérjük töltse ki!',
                                transform: (value) => {
                                  value = parseInt(value);

                                  return isNaN(value) ? null: value;
                                },
                            }],
                            validateTrigger: ['blur', 'change'],
                        }
                    ]"
                    >
                    </a-input-number>
                    m
                </a-form-item>

                <a-form-item :selfUpdate="true">
          <span slot="label">
              Legközelebbi buszállomás / buszmegálló távolsága

              <a-tooltip
                      title="Gyalogos távolság méterben megadva. Helyi, helyközi busz és trolibusz is ehhez a mezőhöz tartozik."
              >
                  <a-icon type="question-circle-o" />
              </a-tooltip>
          </span>

                    <a-input-number
                            :disabled="isMobileReadonly"
                            :min="0"
                            v-decorator="[
                        'travelling/closestBusStationDistance',
                        {
                            rules: [{
                                required: false,
                                whitespace: true,
                                type: 'integer',
                                message: 'Kérjük töltse ki!',
                                transform: (value) => {
                                  value = parseInt(value);

                                  return isNaN(value) ? null: value;
                                },
                            }],
                            validateTrigger: ['blur', 'change'],
                        }
                    ]"
                    >
                    </a-input-number>
                    m
                </a-form-item>

                <a-form-item :selfUpdate="true">
          <span slot="label">
              Legközelebbi működő repülőtér településének neve

              <a-tooltip
                      title="Személyforgalom bonyolítására alkalmas, működő, engedéllyel rendelkező közforgalmi repülőtér, amely külföldi repülőtér is lehet."
              >
                  <a-icon type="question-circle-o" />
              </a-tooltip>
          </span>

                    <a-textarea
                            :disabled="isMobileReadonly"
                            :maxLength="25000"
                            v-decorator="[
                        'travelling/closestAirportSettlement',
                        {
                            rules: [
                                {
                                  required: false,
                                  message: 'Kérjük töltse ki!',
                                },
                                {
                                  max: characterCounts['travelling/closestAirportSettlement'].max,
                                  message: 'Maximum ' + characterCounts['travelling/closestAirportSettlement'].max + ' karakter engedélyezett.',
                                }
                              ]
                        }
                    ]"
                            autoSize
                    />
                </a-form-item>

                <a-form-item :selfUpdate="true">
          <span slot="label">
              Legközelebbi működő repülőtér távolsága

              <a-tooltip
                      title="Autós távolság kilométerben megadva."
              >
                  <a-icon type="question-circle-o" />
              </a-tooltip>
          </span>

                    <a-input-number
                            :disabled="isMobileReadonly"
                            :min="0"
                            v-decorator="[
                        'travelling/closestAirportDistance',
                        {
                            rules: [{
                                required: false,
                                whitespace: true,
                                type: 'integer',
                                message: 'Kérjük töltse ki!',
                                transform: (value) => {
                                  value = parseInt(value);

                                  return isNaN(value) ? null: value;
                                },
                            }],
                            validateTrigger: ['blur', 'change'],
                        }
                    ]"
                    >
                    </a-input-number>
                    km
                </a-form-item>

                <a-form-item :selfUpdate="true">
                    <span slot="label">
                        200 m-en belüli egyéb tömegközlekedési kapcsolat

                        <a-tooltip
                                title='Kérjük felsorolásszerűen megadni!
                                      (Az előzőekhez nem sorolható egyéb közlekedési eszköz közül az adott vonzerőhöz legközelebb eső megállóhely távolsága méterben /pl. villamos, metró, hév, …/. Amennyiben ilyen nincs, kérjük a mezőbe „nincs" megjegyzést beírni.)'
                        >
                            <a-icon type="question-circle-o" />
                        </a-tooltip>
                    </span>

                    <a-textarea
                            :disabled="isMobileReadonly"
                            :maxLength="25000"
                            v-decorator="[
                        'travelling/otherPublicTransport',
                        {
                            rules: [
                                {
                                  required: false,
                                  message: 'Kérjük töltse ki!',
                                },
                                {
                                  max: characterCounts['travelling/otherPublicTransport'].max,
                                  message: 'Maximum ' + characterCounts['travelling/otherPublicTransport'].max + ' karakter engedélyezett.',
                                }
                              ]
                        }
                    ]"
                            placeholder="Kérjük felsorolásszerűen megadni!"
                            autoSize
                    />
                </a-form-item>

                <p>
                    200 m-en belüli tömegközlekedési kapcsolat rendszeressége (járat mennyisége)
                </p>

                <a-form-item :selfUpdate="true">
                    <span slot="label">
                        A vonzerő számára szezont jelentő időszakban

                        <a-tooltip
                                title='(db járat/nap számszerűen. Több egyéb közlekedési forma esetén a legsűrűbben járó adatát kérjük megadni. Pontos adat hiányában becsült érték is elegendő. Amennyiben nincs 200 m-en belüli tömegközlekedési kapcsolat, kérjük a mezőt üresen hagyni.)'
                        >
                            <a-icon type="question-circle-o" />
                        </a-tooltip>
                    </span>

                    <a-input-number
                            :disabled="isMobileReadonly"
                            :min="0"
                            v-decorator="[
                        'travelling/dailyServicesInSeason',
                        {
                            rules: [{
                                required: false,
                                whitespace: true,
                                type: 'integer',
                                message: 'Kérjük töltse ki!',
                                transform: (value) => {
                                  value = parseInt(value);

                                  return isNaN(value) ? null: value;
                                },
                            }],
                            validateTrigger: ['blur', 'change'],
                        }
                    ]"
                    >
                    </a-input-number>
                    db járat/nap
                </a-form-item>

                <a-form-item :selfUpdate="true">
                    <span slot="label">
                        A vonzerő számára szezont jelentő időszakon kívül

                        <a-tooltip
                                title='(db járat/nap számszerűen. Több egyéb közlekedési forma esetén a legsűrűbben járó adatát kérjük megadni. Amennyiben nincs 200 m-en belüli tömegközlekedési kapcsolat, kérjük a mezőt üresen hagyni.)'
                        >
                            <a-icon type="question-circle-o" />
                        </a-tooltip>
                    </span>

                    <a-input-number
                            :disabled="isMobileReadonly"
                            :min="0"
                            v-decorator="[
                        'travelling/dailyServicesNotInSeason',
                        {
                            rules: [{
                                required: false,
                                whitespace: true,
                                type: 'integer',
                                message: 'Kérjük töltse ki!',
                                transform: (value) => {
                                  value = parseInt(value);

                                  return isNaN(value) ? null: value;
                                },
                            }],
                            validateTrigger: ['blur', 'change'],
                        }
                    ]"
                    >
                    </a-input-number>
                    db járat/nap
                </a-form-item>

                <a-form-item :selfUpdate="true" label="Kerékpárúton / kerékpársávon a létesítmény megközelíthető?">
                    <a-select
                            :disabled="isMobileReadonly"
                            v-decorator="[
                        'travelling/bicycleRoadAccess',
                        {
                            rules: [{
                                required: false,
                                message: 'Kérjük töltse ki!'
                            }],
                            validateTrigger: 'blur'
                        }
                    ]"
                            placeholder="Kérjük válasszon!"
                    >
                        <a-select-option :value="1">
                            Igen
                        </a-select-option>

                        <a-select-option :value="0">
                            Nem
                        </a-select-option>
                    </a-select>
                </a-form-item>

                <a-form-item :selfUpdate="true"
                             label="Kerékpárúton / kerékpársávon közvetlenül a bejáratig megközelíthető?">
                    <a-select
                            :disabled="isMobileReadonly"
                            v-decorator="[
                        'travelling/directBicycleRoadAccess',
                        {
                            rules: [{
                                required: false,
                                message: 'Kérjük töltse ki!'
                            }],
                            validateTrigger: 'blur'
                        }
                    ]"
                            placeholder="Kérjük válasszon!"
                    >
                        <a-select-option :value="1">
                            Igen
                        </a-select-option>

                        <a-select-option :value="0">
                            Nem
                        </a-select-option>
                    </a-select>
                </a-form-item>

                <a-form-item
                        :selfUpdate="true"
                        v-show="form.getFieldValue('travelling/directBicycleRoadAccess') === 0"
                >
                    <span slot="label">
                        Ha kerékpárúton / kerékpársávon nem közvetlenül a bejáratig megközelíthető, akkor a legközelebbi kerékpárút távolsága

                        <a-tooltip
                                title='(Gyalogos távolság méterben megadva.)'
                        >
                            <a-icon type="question-circle-o" />
                        </a-tooltip>
                    </span>

                    <a-input-number
                            :disabled="isMobileReadonly"
                            :min="0"
                            v-decorator="[
                        'travelling/distanceFromBicycleRoad',
                        {
                            rules: [{
                                required: false,
                                whitespace: true,
                                type: 'integer',
                                message: 'Kérjük töltse ki!',
                                transform: (value) => {
                                  value = parseInt(value);

                                  return isNaN(value) ? null: value;
                                },
                            }],
                            validateTrigger: ['blur', 'change'],
                        }
                    ]"
                    >
                    </a-input-number>
                    m
                </a-form-item>

                <a-form-item :selfUpdate="true">
          <span slot="label">
              A vonzerő elérhetősége Budapestről, gépkocsival

              <a-tooltip
                      title="Autós távolság kilométerben megadva."
              >
                  <a-icon type="question-circle-o" />
              </a-tooltip>
          </span>

                    <a-input-number
                            :disabled="isMobileReadonly"
                            :min="0"
                            v-decorator="[
                        'travelling/distanceFromCapital',
                        {
                            rules: [{
                                required: false,
                                whitespace: true,
                                type: 'integer',
                                message: 'Kérjük töltse ki!',
                                transform: (value) => {
                                  value = parseInt(value);

                                  return isNaN(value) ? null: value;
                                },
                            }],
                            validateTrigger: ['blur', 'change'],
                        }
                    ]"
                    >
                    </a-input-number>
                    km
                </a-form-item>

                <a-form-item :selfUpdate="true">
          <span slot="label">
              A vonzerő elérhetősége Budapestről, gépkocsival

              <a-tooltip
                      title="Autós utazási idő percben megadva."
              >
                  <a-icon type="question-circle-o" />
              </a-tooltip>
          </span>

                    <a-input-number
                            :disabled="isMobileReadonly"
                            :min="0"
                            v-decorator="[
                        'travelling/timeFromCapital',
                        {
                            rules: [{
                                required: false,
                                whitespace: true,
                                type: 'integer',
                                message: 'Kérjük töltse ki!',
                                transform: (value) => {
                                  value = parseInt(value);

                                  return isNaN(value) ? null: value;
                                },
                            }],
                            validateTrigger: ['blur', 'change'],
                        }
                    ]"
                    >
                    </a-input-number>
                    perc
                </a-form-item>

                <a-form-item :selfUpdate="true">
                    <span slot="label">
                        A vonzerőhöz legközelebb (közlekedési távolság szempontjából) eső határállomás neve

                        <a-tooltip
                                title='(Közúti határállomás, vagy a településének neve. Az országhatár átlépési pontjainak vizsgálatához szükséges adat, így az "elméleti" (nem Schengen) határállomás is.)'
                        >
                            <a-icon type="question-circle-o" />
                        </a-tooltip>
                    </span>

                    <a-textarea
                            :disabled="isMobileReadonly"
                            :maxLength="25000"
                            v-decorator="[
                        'travelling/closestBorderCrossing',
                        {
                            rules: [
                                {
                                  required: false,
                                  message: 'Kérjük töltse ki!',
                                },
                                {
                                  max: characterCounts['travelling/closestAirportSettlement'].max,
                                  message: 'Maximum ' + characterCounts['travelling/closestAirportSettlement'].max + ' karakter engedélyezett.',
                                }
                              ]
                        }
                    ]"
                            :autoSize="{minRows: 1}"
                    />
                </a-form-item>

                <a-form-item :selfUpdate="true">
                    <span slot="label">
                        A vonzerő elérhetősége a vonzerőhöz legközelebb (közlekedési távolság szempontjából) eső határállomástól

                        <a-tooltip
                                title="Autós távolság kilométerben megadva."
                        >
                            <a-icon type="question-circle-o" />
                        </a-tooltip>
                    </span>

                    <a-input-number
                            :disabled="isMobileReadonly"
                            :min="0"
                            v-decorator="[
                        'travelling/distanceFromClosestBorderCrossing',
                        {
                            rules: [{
                                required: false,
                                whitespace: true,
                                type: 'integer',
                                message: 'Kérjük töltse ki!',
                                transform: (value) => {
                                  value = parseInt(value);

                                  return isNaN(value) ? null: value;
                                },
                            }],
                            validateTrigger: ['blur', 'change'],
                        }
                    ]"
                    >
                    </a-input-number>
                    km
                </a-form-item>

                <a-form-item :selfUpdate="true">
          <span slot="label">
              A vonzerő elérhetősége a vonzerőhöz legközelebb (közlekedési távolság szempontjából) eső határállomástól

              <a-tooltip
                      title="Autós utazási idő percben megadva."
              >
                  <a-icon type="question-circle-o" />
              </a-tooltip>
          </span>

                    <a-input-number
                            :disabled="isMobileReadonly"
                            :min="0"
                            v-decorator="[
                        'travelling/timeFromClosestBorderCrossing',
                        {
                            rules: [{
                                required: false,
                                whitespace: true,
                                type: 'integer',
                                message: 'Kérjük töltse ki!',
                                transform: (value) => {
                                  value = parseInt(value);

                                  return isNaN(value) ? null: value;
                                },
                            }],
                            validateTrigger: ['blur', 'change'],
                        }
                    ]"
                    >
                    </a-input-number>
                    perc
                </a-form-item>

                <a-form-item :selfUpdate="true">
          <span slot="label">
              Megjegyzés

              <a-tooltip
                      title="Kérjük ezt a cellát akkor töltse ki, ha a témához kapcsolódóan egyéb megjegyzése van."
              >
                  <a-icon type="question-circle-o" />
              </a-tooltip>
          </span>

                    <a-textarea
                            :disabled="isMobileReadonly"
                            :maxLength="25000"
                            v-decorator="[
                        'travelling/comment',
                        {
                            rules: [{
                                required: false,
                                message: 'Kérjük töltse ki!'
                            }]
                        }
                    ]"
                            :autoSize="{minRows: 2}"
                    />
                </a-form-item>

                <a-form-item>
                    <a-button type="primary" html-type="submit" :loading="submitLoading" v-if="showSaveButton && !isMobileReadonly">
                        Mentés
                    </a-button>

                    <a-button type="secondary" html-type="button" v-on:click="resetForm" v-if="hasChanged()">
                        <a-icon
                                type="undo"
                                theme="outlined"
                        />

                        Piszkozat törlése
                    </a-button>

                    <span v-if="currentTab" class="last-modification">
            Utolsó mentés: <strong>{{ moment(currentTab.modificationTime).format(timeStampFormat).toString() }}</strong> &nbsp;<span
                            v-if="currentTab.user">({{ currentTab.user.userName }})</span>
          </span>

                    <a-alert
                            :style="{
                        marginTop: '10px'
                    }"
                            v-if="submitResult.show"
                            :message="submitResult.message"
                            :type="submitResult.type"
                            showIcon
                            closable
                            :afterClose="() => submitResult.show = false"
                    />
                </a-form-item>
            </a-form>
        </a-spin>
    </div>
</template>

<script>
import { EditFormComponentMixin } from "@/mixins/EditFormComponentMixin";

export default {
    menuName: 'Vonzerő megközelíthetősége',
    name: 'attraction-location-form',
    mixins: [EditFormComponentMixin],

    data() {
        return {
            formId: 'ATTRACTION_LOCATION',

            formFields: {
                'travelling/closestStopDistance': null,
                'travelling/closestTrainStationDistance': null,
                'travelling/closestBusStationDistance': null,
                'travelling/closestAirportSettlement': '',
                'travelling/closestAirportDistance': null,
                'travelling/otherPublicTransport': '',
                'travelling/dailyServicesInSeason': null,
                'travelling/dailyServicesNotInSeason': null,
                'travelling/bicycleRoadAccess': null,
                'travelling/directBicycleRoadAccess': null,
                'travelling/distanceFromBicycleRoad': null,
                'travelling/distanceFromCapital': null,
                'travelling/timeFromCapital': null,
                'travelling/closestBorderCrossing': '',
                'travelling/distanceFromClosestBorderCrossing': null,
                'travelling/timeFromClosestBorderCrossing': null,
                'travelling/comment': '',
            },

            casts: {
                'travelling/closestStopDistance': '?int',
                'travelling/closestTrainStationDistance': '?int',
                'travelling/closestBusStationDistance': '?int',
                'travelling/closestAirportDistance': '?int',
                'travelling/dailyServicesInSeason': '?int',
                'travelling/dailyServicesNotInSeason': '?int',
                'travelling/bicycleRoadAccess': '?int',
                'travelling/directBicycleRoadAccess': '?int',
                'travelling/distanceFromBicycleRoad': '?int',
                'travelling/distanceFromCapital': '?int',
                'travelling/timeFromCapital': '?int',
                'travelling/distanceFromClosestBorderCrossing': '?int',
                'travelling/timeFromClosestBorderCrossing': '?int',
            },

            characterCounts: {
                'travelling/closestAirportSettlement': {
                    max: 200,
                },
                'travelling/otherPublicTransport': {
                    max: 500,
                },
                'travelling/closestBorderCrossing': {
                    max: 200,
                },
            },
        }
    },

    methods: {}
}
</script>

<style>
</style>
