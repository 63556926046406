class LocalStorage {
    constructor() {
        if (window.localStorage) {
            this.setJwt = this.setLocalstorageJwt;
            this.getJwt = this.getLocalstorageJwt;
            this.deleteJwt = this.deleteLocalstorageJwt;
        } else {
            // cookie implementation if required            
            // this.setJwt = this.setCookieJwt;
            // this.getJwt = this.getCookieJwt;
            // this.deleteJwt = this.deleteCookieJwt;
        }
    }

    /**
     * @param token
     */
    setLocalstorageJwt(token) {
        window.localStorage.setItem('jwt', `${token}`)
    }

    /**
     * @returns {string}
     */
    getLocalstorageJwt() {
        return window.localStorage.getItem('jwt');
    }

    /**
     * Delete token
     */
    deleteLocalstorageJwt() {
        window.localStorage.removeItem('jwt');
    }

    /**
     * @param userData
     */
    setUserData(userData) {
        window.localStorage.setItem('userData', JSON.stringify(userData));
    }

    /**
     * @returns {null|any}
     */
    getUserData() {
        const data = window.localStorage.getItem('userData');

        if (!data) {
            return null;
        }

        return JSON.parse(data);
    }

    /**
     * Delete data
     */
    deleteUserData() {
        window.localStorage.removeItem('userData');
    }

    /**
     *
     * @param {*} queryParams
     * @param {string|null} groupType
     */
    setLastListQueryParams(queryParams, groupType) {
        if(!queryParams && !groupType) {
            window.localStorage.removeItem('lastListQueryParams');

            return;
        }

        let params = this.getLastListQueryParams();

        if (typeof params !== 'object' || !params) {
            params = {};
        }

        params[groupType] = queryParams;

        window.localStorage.setItem('lastListQueryParams', JSON.stringify(params));
    }

    /**
     * @returns {any}
     */
    getLastListQueryParams() {
        const data = window.localStorage.getItem('lastListQueryParams');

        if (!data) {
            return {};
        }

        return JSON.parse(data);
    }

    /**
     * @param userColumns
     * @param groupType
     */
    setUserColumns(userColumns, groupType) {
        if(!userColumns && !groupType) {
            window.localStorage.removeItem('userColumns');

            return;
        }

        let columns = this.getUserColumns();

        columns[groupType] = userColumns;

        window.localStorage.setItem('userColumns', JSON.stringify(columns));
    }

    /**
     * @returns {any}
     */
    getUserColumns() {
        const data = window.localStorage.getItem('userColumns');

        if (!data) {
            return {};
        }

        return JSON.parse(data);
    }

    /**
     * @param {object} version
     */
    setVersion(version) {
        window.localStorage.setItem('version', JSON.stringify(version));
    }

    /**
     * @returns {object|null}
     */
    getVersion() {
        const data = window.localStorage.getItem('version');

        if (!data) {
            return null;
        }

        return JSON.parse(data);
    }
}

export default new LocalStorage();