import HungarikumDataFormComponent
    from "@/components/neta/neta-hungarikum/basic-data/forms/data-form/HungarikumDataFormComponent.vue";

let usedComponents = [
    HungarikumDataFormComponent,
];

const routePrefix = 'neta-basic-hungarikum';

export default {
    routePrefix,
    usedComponents
};