import { render, staticRenderFns } from "./NotFoundView.vue?vue&type=template&id=1aa55a58&scoped=true&"
import script from "./NotFoundView.vue?vue&type=script&lang=js&"
export * from "./NotFoundView.vue?vue&type=script&lang=js&"
import style0 from "./NotFoundView.vue?vue&type=style&index=0&id=1aa55a58&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1aa55a58",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/neta-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1aa55a58')) {
      api.createRecord('1aa55a58', component.options)
    } else {
      api.reload('1aa55a58', component.options)
    }
    module.hot.accept("./NotFoundView.vue?vue&type=template&id=1aa55a58&scoped=true&", function () {
      api.rerender('1aa55a58', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/error-views/NotFoundView.vue"
export default component.exports